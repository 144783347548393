// @flow

import React, { PureComponent } from 'react';
import { pipeClassNames, mapPropToClassName, concatStylebyCond } from 'utils/styles';
/**
 * @prop {*} color Color of the pill element.
 * @prop {*} onClose Callback for when pill is closed.
 */

type PillProps = {
  children?: React$Node,
  color?: 'default' | 'gray' | 'yellow' | 'light-green' | 'green' | 'violet' | 'light-blue' | 'blue' | 'light-red' | 'pink' | 'red',
  onClose?: (event?: Event) => void,
  className?: string,
  onClick?: () => void,
  stretch?: boolean,
  'data-e2e-id'?: string,
}

export default class PillCommonHelper extends PureComponent<PillProps> {

  static defaultProps = {
    color: 'default',
    stretch: false,
  };

  render() {
    const {
      color,
      onClose,
      children,
      className = '',
      stretch,
      onClick,
    } = this.props;

    const pillClassName = pipeClassNames(
      'pill',
      className,
      concatStylebyCond('clickable', !!onClick),
      mapPropToClassName('', color),
    );

    return (
      <div
        className={ pillClassName }
        onClick={ onClick }
        style={{ width: stretch ? '100%' : 'auto' }}
        data-e2e-id={ this.props['data-e2e-id'] }
      >
        <div className="pill-text">{ children }</div>
        <If condition={ typeof onClose === 'function' } >
          <span className="pill-close" onClick={ onClose }>&times;</span>
        </If>
      </div>
    );
  }
}
