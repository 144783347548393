// @flow

import React from 'react';
import './InputHelper.scss';

type InputDragPreviewProps = {
  name: string
};

// $FlowFixMe waiting for update Flow
export const InputDragPreview = React.memo(({ name }: InputDragPreviewProps) : React$Node => <div styleName="dnd-drag-preview">{ name }</div>);


