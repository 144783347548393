import * as R from 'ramda';

const getFunctionInfoId = (functionInfo) => {
  const applicationId = R.path(['application', 'id'], functionInfo);

  if (applicationId) {
    return `${applicationId}-${functionInfo.name}`;
  }

  return functionInfo.name;
};

export const makeResolvers = () => ({
  FunctionResolverInfo: {
    id: getFunctionInfoId,
  },
  FunctionWebhookInfo: {
    id: getFunctionInfoId,
  },
  FunctionTaskInfo: {
    id: getFunctionInfoId,
  },
  FunctionTriggerInfo: {
    id: getFunctionInfoId,
  },
});
