// @flow
import * as R from 'ramda';
import { useMutation } from 'react-apollo';
import { updateWorkspaceCachedData } from 'utils/apollo/updateCache';

import { CI_INSTALL_MUTATION } from '../mutations';

const getCiData = (data) => {
  return R.pathOr({}, ['system', 'ciInstall'], data);
};

export const useCiInstall = (options: { workspaceId?: string } = {}, graphqlOptions: Object = {}) => {
  const { workspaceId } = options;

  const [ciInstall, { data, ...rest }] = useMutation(CI_INSTALL_MUTATION, {
    update: (cache, { data }) => {
      const { success } = getCiData(data);

      if (!success || !workspaceId) {
        return;
      }

      updateWorkspaceCachedData({ workspaceId, newData: { isCiCdEnabled: true }, cache });
    },

    ...graphqlOptions,
    onCompleted: (data) => {
      graphqlOptions.onCompleted && graphqlOptions.onCompleted(getCiData(data));
    },
  });

  return [ciInstall, { workspaceUpdate: getCiData(data), ...rest }];
};
