// @flow

import { UserFlowButton } from './UserFlowButton';
import { UserFlowCloseIcon } from './UserFlowCloseIcon';
import { UserFlowHeader } from './UserFlowHeader';
import { UserFlowHeaderWhite } from './UserFlowHeaderWhite';
import { UserFlowIcon } from './UserFlowIcon';
import { UserFlowLink } from './UserFlowLink';
import { UserFlowLogo } from './UserFlowLogo';
import { UserFlowLogoHeader } from './UserFlowLogoHeader';
import { UserFlowPage } from './UserFlowPage';
import { UserFlowPageContent } from './UserFlowPageContent';
import { UserFlowPaper } from './UserFlowPaper';
import { UserFlowFormContent } from './UserFlowFormContent';
import { UserFlowText, UserFlowTextWhite } from './UserFlowText';
import { UserFlowHeading, UserFlowHeadingWhite } from './UserFlowHeading';
import { UserFlowPageTitle } from './UserFlowPageTitle';
import { UserFlowErrorBar } from './UserFlowErrorBar';
import { UserFlowUserDropdown } from './UserFlowUserDropdown';
import { UserFlowLearningCenter } from './UserFlowLearningCenter';
import { UserFlowPrivacyPolicy } from './UserFlowPrivacyPolicy';


const UserFlow = {
  Button: UserFlowButton,
  CloseIcon: UserFlowCloseIcon,
  Header: UserFlowHeader,
  HeaderWhite: UserFlowHeaderWhite,
  Heading: UserFlowHeading,
  HeadingWhite: UserFlowHeadingWhite,
  Icon: UserFlowIcon,
  Link: UserFlowLink,
  Logo: UserFlowLogo,
  LogoHeader: UserFlowLogoHeader,
  Page: UserFlowPage,
  PageContent: UserFlowPageContent,
  Paper: UserFlowPaper,
  Text: UserFlowText,
  TextWhite: UserFlowTextWhite,
  FormContent: UserFlowFormContent,
  PageTitle: UserFlowPageTitle,
  ErrorBar: UserFlowErrorBar,
  UserDropdown: UserFlowUserDropdown,
  LearningCenter: UserFlowLearningCenter,
  PrivacyPolicy: UserFlowPrivacyPolicy,
};

export { UserFlow };
