// @flow
// $FlowFixMe
import React, { useCallback, useMemo } from 'react';
import { Button, Grid, Text, Checkbox, Icon } from '@8base/boost';
import { ORGANIZATION_ROLE } from 'common/constants/organization';
import { WorkspaceCard, WorkspaceEnvironmentsSelectGroup } from 'common/components';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

type WorkspaceCheckboxLabelProps = {
  role: string,
  workspaceImage?: any,
  workspaceKind: string,
  workspaceName: string,
  workspaceId: string,
  workspaceDescription?: string,
  isWorkspaceEnvironmentsExpanded: boolean,
  formValues: any,
  form: any,
  onOpenRow: any,
  failedValidationRows: [string],
}

type WorkspaceCheckboxProps = {
  workspaceImage?: any,
  workspaceKind: string,
  workspaceName: string,
  workspaceApiHost: string,
  workspaceId: string,
  workspaceDescription?: string,
  formValues: any,
  form: any,
  submitting: boolean,
  expandedRows: [string],
  onOpenRow: any,
  failedValidationRows: [string],
  setFailedValidationRows: any
}

const SmallIcon = styled(Icon)`
  width: 12px !important;
  height: 12px !important;
`;

const StyledCheckbox = styled(Checkbox)`
  background: ${props => props.checked && !props.isAdmin ? props.theme.COLORS.GRAY_10 : 'none'};
  margin-bottom: ${props => props.isExpanded ? '0 !important' : '8px'};
  border-radius: ${props => props.isExpanded ? '5px 5px 0 0' : '5px'};
  width: 100%;
  padding: 8px;
`;

const isWorkspaceIncluded = (workspaceId:string, workspaceRoles): boolean => {
  const index = workspaceRoles.findIndex((el) => el.workspaceId === workspaceId);

  return index !== -1;
};

const getWorkspaceIndex = (workspaceId: string, workspaceRoles):number => workspaceRoles.findIndex((el) => el.workspaceId === workspaceId);

const onWorkspaceChoose = ({ workspaceId, workspaceRoles, form }): void => {
  if (isWorkspaceIncluded(workspaceId, workspaceRoles)) {
    const filteredWorkspaceRole = workspaceRoles.filter((el) => el.workspaceId !== workspaceId);

    form && form.change('workspaceRoles', filteredWorkspaceRole);
  } else {
    const workspaceRole = { workspaceId, environmentRoles: [] };
    const newWorkspaceRoles = [workspaceRole, ...workspaceRoles];

    form && form.change('workspaceRoles', newWorkspaceRoles);
  }
};

const checkIsEnvironmentsExpanded = ({ role, workspaceId, expandedRows }): boolean =>
  role === ORGANIZATION_ROLE.developer && expandedRows.includes(workspaceId);

const WorkspaceCheckboxLabel = ({
  role,
  workspaceImage,
  workspaceKind,
  workspaceName,
  workspaceId,
  workspaceDescription,
  isWorkspaceEnvironmentsExpanded,
  formValues,
  form,
  onOpenRow,
  failedValidationRows,
}: WorkspaceCheckboxLabelProps) => {
  const { t } = useTranslation();

  return (
    <Grid.Layout
      columns="repeat(2, 252px) 180px"
      alignItems="center"
      gap="md"
    >
      <Grid.Box>
        <WorkspaceCard
          image={ workspaceImage }
          kind={ workspaceKind }
          workspaceName={ workspaceName }
          workspaceId={ workspaceId }
        />
      </Grid.Box>
      <Grid.Box>
        <Text ellipsis>{ workspaceDescription }</Text>
      </Grid.Box>
      <Grid.Box>
        <Button
          color={ failedValidationRows.includes(workspaceId) ? 'danger' : 'primary' }
          variant="link"
          css={ css`font-weight: normal;` }
          onClick={ () => {
            onOpenRow(workspaceId);
          } }
          disabled={ role !== ORGANIZATION_ROLE.developer }
        >
          <span>
            { t('shared.environments') }
          </span>
          <SmallIcon
            name={ isWorkspaceEnvironmentsExpanded ? 'ChevronTop' : 'ChevronDown' }
          />
        </Button>
      </Grid.Box>
    </Grid.Layout>
  );
};

export const WorkspaceCheckbox = ({
  workspaceImage,
  workspaceKind,
  workspaceName,
  workspaceApiHost,
  workspaceId,
  workspaceDescription,
  formValues,
  form,
  submitting,
  expandedRows,
  onOpenRow,
  failedValidationRows,
  setFailedValidationRows,
}: WorkspaceCheckboxProps) => {
  const isAdmin = useMemo(() => formValues.role === ORGANIZATION_ROLE.admin, [formValues.role]);

  const isWorkspaceEnvironmentsExpanded = useMemo(() =>
    checkIsEnvironmentsExpanded({
      workspaceId,
      role: formValues.role,
      expandedRows,
    })
  , [expandedRows, formValues.role, workspaceId]);

  const onChange = useCallback(() => {
    onWorkspaceChoose({
      workspaceId,
      workspaceRoles: formValues.workspaceRoles,
      form,
    });

    if (!isWorkspaceIncluded(workspaceId, formValues.workspaceRoles) && !isWorkspaceEnvironmentsExpanded) {
      onOpenRow(workspaceId);
    }
  }, [form, formValues.workspaceRoles, isWorkspaceEnvironmentsExpanded, onOpenRow, workspaceId]);

  return (
    <>
      <StyledCheckbox
        onChange={ onChange }
        checked={ isAdmin || isWorkspaceIncluded(workspaceId, formValues.workspaceRoles) }
        isAdmin={ isAdmin }
        disabled={ isAdmin || submitting }
        isExpanded={ isWorkspaceEnvironmentsExpanded }
        label={ (
          <WorkspaceCheckboxLabel
            formValues={ formValues }
            form={ form }
            workspaceId={ workspaceId }
            role={ formValues.role }
            workspaceImage={ workspaceImage }
            workspaceKind={ workspaceKind }
            workspaceName={ workspaceName }
            workspaceDescription={ workspaceDescription }
            isWorkspaceEnvironmentsExpanded={ isWorkspaceEnvironmentsExpanded }
            onOpenRow={ onOpenRow }
            failedValidationRows={ failedValidationRows }
          />
        ) }
      />
      <If condition={ isWorkspaceEnvironmentsExpanded }>
        <WorkspaceEnvironmentsSelectGroup
          workspaceId={ workspaceId }
          workspaceApiHost={ workspaceApiHost }
          workspaceIndex={ getWorkspaceIndex(workspaceId, formValues.workspaceRoles) }
          values={ formValues }
          form={ form }
          submitting={ submitting }
          failedValidationRows={ failedValidationRows }
          setFailedValidationRows={ setFailedValidationRows }
        />
      </If>
    </>
  );
};
