import React from 'react';
import { Icon } from '@8base/boost';
import { css } from '@emotion/core';
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import { Portal } from 'react-portal';
import 'react-toastify/dist/ReactToastify.css';

const Slide = cssTransition({
  enter: 'Toastify__slide-enter',
  exit: 'Toastify__slide-exit',
  duration: [350, 550],
  appendPosition: true,
});

const closeButtonClassName = css`
  margin-left: 24px;
`;

export default class ToastNotification extends React.PureComponent {
  render() {
    window.toast = toast;
    return (
      <Portal isOpen>
        <div { ...E2E.$props('toastNotification') }>
          <ToastContainer
            autoClose={ 3000 }
            closeButton={ <Icon css={ closeButtonClassName } name="Delete" /> }
            position={ toast.POSITION.BOTTOM_LEFT }
            transition={ Slide }
            draggable={ false }
            hideProgressBar
            newestOnTop
            pauseOnHover
          />
        </div>
      </Portal>
    );
  }
}
