import { TRANSFER_OWNERSHIP_DETAILS_QUERY } from 'graphql/queries';

export const clearWorkspaceTransferInfo = (transferId: string) => (cache) => {
  cache.writeQuery({
    query: TRANSFER_OWNERSHIP_DETAILS_QUERY,
    variables: { transferId },
    data: { system: {
      workspaceTransferInfo: null,
    }},
  });
};
