import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class TitleHeadCommonNavigationLeftCommonHelper extends Component {

  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const { children, ...rest } = this.props;
    return <div { ...rest } className="nav-heading">{ children }</div>;
  }
}
