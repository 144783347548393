import React from 'react';
import { useModal } from '@8base/boost';

export const DialogWrapper = ({ id, component, ...props }) => {
  const { isOpen } = useModal(id);

  if (!isOpen || !component || !id) {
    return null;
  }

  return React.createElement(component, { id, ...props });
};
