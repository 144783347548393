// @flow

import React from 'react';

import { withDragSource } from '../draggableLogic';

import './TableWorker.scss';


const getCellStyles = (width: number) => ({
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: `${width}px`,
  maxWidth: `${width}px`,
});

type TableHeadCellProps = {
  width?: number,
  onEndDrag?: (columnName: string, width: number) => void,
  children: React$Node,
}

export const TableHeadCell: React$ComponentType<TableHeadCellProps> = withDragSource(
  ({ children, width, connectDragSource, connectDragPreview, isDragging }) => (
    <div
      style={ getCellStyles(width) }
      styleName="head-cell-container"
    >
      { connectDragPreview(<div styleName="draggable-preview" />) }
      <div styleName="head-cell-wrapper" >
        <div styleName="head-cell">{ children }</div>
        { connectDragSource(
          <div styleName="draggable-separate-wrapper">
            <div styleName="draggable-separate" />
          </div>,
        ) }
      </div>
    </div>
  ),
);
TableHeadCell.displayName = 'TableHeadCell';
