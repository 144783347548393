// @flow
// $FlowFixMe waiting for update Flow
import React, { useCallback, useState } from 'react';
import * as R from 'ramda';
import { css } from '@emotion/core';
import { Dialog, Button, Grid, Column, Text, useModal, AsyncContent } from '@8base/boost';
import styled from '@emotion/styled';

import { i18n } from 'i18n';

import { PlusButton, BankCardLastFour } from 'common/components';

const buttonCss = (props) => css`
  cursor: pointer;
  padding: 20px;
  border: 1px solid ${props.theme.COLORS.GRAY_20};
  border-radius: 8px;
  min-height: 96px;
`;

const StyledPlusButton = styled(PlusButton)`
  ${buttonCss}
`;

const StyledColumn = styled(Column)`
  ${buttonCss}
`;

const BILLING_CHOOSE_CARD_DIALOG_ID = 'BILLING_CHOOSE_CARD_DIALOG_ID';

export const BillingChooseCardDialog = () => {
  const { closeModal, args } = useModal(BILLING_CHOOSE_CARD_DIALOG_ID);
  const [loading, setLoading] = useState(false);

  const onClose = useCallback(() => {
    if (loading) {
      return;
    }
    closeModal(BILLING_CHOOSE_CARD_DIALOG_ID);
  }, [closeModal, loading]);


  const onChooseDefaultCardClick = async () => {
    setLoading(true);
    const onChooseDefaultCard = R.prop('onChooseDefaultCard', args);
    try {
      if (onChooseDefaultCard) {
        await onChooseDefaultCard();
      }
    } finally {
      setLoading(false);
    }
  };

  const organizationId = R.prop('organizationId', args);
  const onChooseCard = R.prop('onChooseCard', args);
  const defaultCard = R.prop('defaultCard', args);
  const defaultCardDescription = R.prop('defaultCardDescription', args);

  return (
    <Dialog
      id={ BILLING_CHOOSE_CARD_DIALOG_ID }
      onClose={ onClose }
      size="lg"
      shouldCloseOnOverlayClick={ false }
      shouldCloseOnEscPress={ false }
    >
      <Dialog.Header
        title={ i18n.t('billingChooseCardDialog.Title', { defaultValue: 'Change Payment Method' }) }
        onClose={ onClose }
      />
      <Dialog.Body>
        <Column stretch>
          <AsyncContent loading={ loading } stretch >
            <Text weight="bold">
              Choose default { organizationId && 'organization' } payment method or set up new:
            </Text>
            <Grid.Layout gap="md" columns="1fr 1fr" stretch>
              <Grid.Box>
                <StyledColumn onClick={ onChooseDefaultCardClick }>
                  <BankCardLastFour card={ defaultCard } borderless />
                  <If condition={ defaultCardDescription }>
                    <Text kind="overline-1">{ defaultCardDescription }</Text>
                  </If>
                </StyledColumn>
              </Grid.Box>

              <Grid.Box>
                <StyledPlusButton onClick={ onChooseCard } >Add New Credit Card</StyledPlusButton>
              </Grid.Box>
            </Grid.Layout>
          </AsyncContent>

        </Column>
      </Dialog.Body>
      <If condition={ !loading }>
        <Dialog.Footer>
          <Button
            type="button"
            color="neutral"
            onClick={ onClose }
          >
            { i18n.t('shared.close') }
          </Button>

        </Dialog.Footer>
      </If>

    </Dialog>
  );
};

BillingChooseCardDialog.ID = BILLING_CHOOSE_CARD_DIALOG_ID;
