// @flow

import React from 'react';
import * as R from 'ramda';
import { css } from '@emotion/core';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { Form as FormLogic, Field } from '@8base-react/forms';
import { withRouter } from 'react-router-dom';
import { Dialog, Button, InputField, Text, Column, useModal, Row } from '@8base/boost';
import { TABLES_SCHEMA_QUERY, TABLE_FRAGMENT } from '@8base-react/table-schema-provider';
import { formatToTableName } from 'utils/NameConvertor';

import { useBuildUrl, APP_URL } from 'common/routing';
import { required } from 'utils/formValidations';
import { CodeMirrorField } from 'common/CodeMirrorField';
import { i18n } from 'i18n';
import { Trans } from 'utils/translate';

type ViewCreateDialogProps = {
  viewCreate: (Object) => Promise<{data: Object}>,
  history: Object,
};

const DEFAULT_QUERY = `-- MySQL version: 5.5.1
-- You don’t need to add “CREATE VIEW view_name AS”
-- See example SQL statement below:
-- SELECT column1, column2, ...
-- FROM table_name
-- WHERE condition;`;

const VIEW_CREATE_MUTATION = gql`
  mutation ViewCreate($data: ViewCreateInput!) {
    viewCreate(data: $data) {
      ...TableFragment
    }
  }
  ${TABLE_FRAGMENT}
`;

const VIEW_CREATE_DIALOG_ID = 'VIEW_CREATE_DIALOG_ID';

let ViewCreateDialog = ({ viewCreate, history }: ViewCreateDialogProps) => {
  const { closeModal } = useModal();
  const buildUrl = useBuildUrl();

  const onClose = () => closeModal(VIEW_CREATE_DIALOG_ID);

  const onSubmit = async (args, data) => {
    const nextData = R.assoc('name', formatToTableName(data.displayName), data);

    let res = null;

    try {
      res = await viewCreate({ variables: { data: nextData }});
    } catch (e) {
      if (R.has('graphQLErrors', e)) {
        e.graphQLErrors = e.graphQLErrors.map((err) => {
          if (R.hasPath(['details', 'name'], err)) {
            err = R.assocPath(['details', 'displayName'], R.path(['details', 'name'], err), err);
            err = R.dissocPath(['details', 'name'], err);
          }

          return err;
        });

        throw e;
      }
    }

    onClose();

    if (R.hasPath(['data', 'viewCreate', 'id'], res)) {
      history.push(buildUrl(APP_URL.dataBuilder, { pathParams: { tableId: R.path(['data', 'viewCreate', 'id'], res) }}));
    }
  };

  return (
    <Dialog id={ VIEW_CREATE_DIALOG_ID } onClose={ onClose } size="xxl">
      { ({ args }) => (
        <FormLogic onSubmit={ onSubmit.bind(null, args) } initialValues={{ query: DEFAULT_QUERY }} >
          { ({ handleSubmit, invalid, submitting, pristine }) => (
            <form onSubmit={ handleSubmit } css={ css`height: 100%;` }>
              <Dialog.Header title="Create View" onClose={ onClose } />
              <Dialog.Body scrollable>
                <Column gap="lg">
                  <Row alignItems="center">
                    <Text css={{ fontSize: 12, whiteSpace: 'nowrap', textTransform: 'uppercase' }} weight="semibold">
                      <Trans i18nKey="viewCreateDialog.createView">
                        Create view
                      </Trans>
                    </Text>
                    <Field name="displayName" component={ InputField } validate={ required } />
                    <Text css={{ fontSize: 12, whiteSpace: 'nowrap', textTransform: 'uppercase' }} weight="semibold">
                      <Trans i18nKey="viewCreateDialog.as">
                        as
                      </Trans>
                    </Text>
                  </Row>
                  <Field
                    stretch
                    name="query"
                    label="SQL Statement (MySQL version: 5.5.1)"
                    component={ CodeMirrorField }
                    validate={ required }
                    mode="text/x-sql"
                  />
                </Column>
              </Dialog.Body>
              <Dialog.Footer>
                <Button color="neutral" variant="outlined" disabled={ submitting } onClick={ onClose }>
                  { i18n.t('shared.cancel') }
                </Button>
                <Button type="submit" disabled={ pristine } loading={ submitting }>
                  { i18n.t('shared.create') }
                </Button>
              </Dialog.Footer>
            </form>
          ) }
        </FormLogic>
      ) }
    </Dialog>
  );
};

ViewCreateDialog = R.compose(
  withRouter,
  graphql(VIEW_CREATE_MUTATION, {
    name: 'viewCreate',
    options: () => ({
      update: (cache: Object, { data }: Object) => {
        cache.writeQuery({
          query: TABLES_SCHEMA_QUERY,
          data: R.evolve({
            tablesList: {
              items: R.append(data.viewCreate),
            },
          }, cache.readQuery({ query: TABLES_SCHEMA_QUERY })),
        });
      },
    }),
  }),
)(ViewCreateDialog);

ViewCreateDialog.ID = VIEW_CREATE_DIALOG_ID;

export { ViewCreateDialog };
