// @flow

import { createAction } from 'utils/customReduxAct';
import type { ColumnSettings } from './dataBuilder.reducer';

export const enableTableNameAutoselection = createAction(
  '@DATA_BUILDER/ENABLE_TABLE_NAME_AUTOSELECTION',
);

export const disableTableNameAutoselection = createAction(
  '@DATA_BUILDER/DISABLE_TABLE_NAME_AUTOSELECTION',
);

export const setDataViewerFilter = createAction(
  '@DATA_VIEWER/SET_FILTER',
  (filter: Object) => filter,
);

export const setDataViewerOrderBy = createAction(
  '@DATA_VIEWER/SET_ORDER_BY',
  (orderBy: Object[]) => orderBy,
);

// Update columns settings with saving order elements
export const updateTableViewingColumnSettings = createAction(
  '@DATA_VIEWER/UPDATE_TABLE_VIEWING_COLUMN_SETTINGS',
  (tableId: string, columnSettings: ColumnSettings) => ({ tableId, columnSettings }),
);

export const setTableViewingColumnSettings = createAction(
  '@DATA_VIEWER/SET_TABLE_VIEWING_COLUMN_SETTINGS',
  (tableId: string, columnSettings: ColumnSettings) => ({ tableId, columnSettings }),
);

export const initTableViewingColumns = createAction(
  '@DATA_VIEWER/INIT_TABLE_VIEWING_COLUMNS',
  (tableId: string, columns: Array<{ fieldName: string, label: string, checked?: boolean }>) => ({ tableId, columns }),
);

export const addTableViewingSort = createAction(
  '@DATA_VIEWER/ADD_TABLE_VIEWING_SORT',
  (tableId: string, sortRules: Array<any>) => ({ tableId, sortRules }),
);

export const setTableViewingControlsData = createAction(
  '@DATA_VIEWER/SET_TABLE_VIEWING_RULES',
  (tableId: string, rules: {filter: Object, sort: Object, search: string}) => ({ tableId, rules }),
);

export const clearTableVieweingControlsData = createAction(
  '@DATA_VIEWER/CLEAR_TABLE_VIEWING_RULES',
  (tableId: string) => tableId,
);
