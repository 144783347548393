// @flow
import * as R from 'ramda';
import { type DataProxy } from 'apollo-cache';

import { USER_ACCOUNT_INFO_QUERY } from 'graphql/queries';

type Record = {
  [key: string]: any;
};

/**
 * Update user account info  data at apollo cache with USER_ACCOUNT_INFO_QUERY query
 */
export const updateUserAccountInfoCachedData = ({
  newData,
  cache,
}: {
  newData: Record,
  cache: DataProxy,
}) => {
  const prevData = cache.readQuery({ query: USER_ACCOUNT_INFO_QUERY });

  const userAccountInfoCache = R.pathOr({}, ['system', 'memberAccount'], prevData);

  const userAccountInfoMerged = R.mergeDeepRight(userAccountInfoCache, newData);

  cache.writeQuery({
    query: USER_ACCOUNT_INFO_QUERY,
    data: {
      ...prevData,
      system: {
        ...prevData.system,
        memberAccount: userAccountInfoMerged,
        __typename: 'SystemQuery',
      },
    }});
};
