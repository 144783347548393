// @flow
// $FlowFixMe
import { useCallback } from 'react';
import { useQuery, type QueryHookOptions, type QueryResult } from 'react-apollo';
import { useAuth } from '@8base-react/auth/dist';
import * as R from 'ramda';

import { HIDE_TOAST_ERROR_MESSAGE, IGNORE_WORKSPACE } from 'common/constants/apolloOperationContextOptions';
import { INBOX_LIST_QUERY, UNDONE_INBOX_COUNT_QUERY } from 'graphql/queries';

import type { InboxItemFragment, InboxListQuery, InboxListQueryVariables } from '../__generated__';
import { NetworkStatus } from 'apollo-client';

const PAGE_SIZE = 15;
const ITEMS_PATH = ['system', 'inboxEventsList', 'items'];
const COUNT_PATH = ['system', 'inboxEventsListCount', 'count'];
const UNDONE_COUNT_PATH = ['system', 'undoneInboxCount', 'count'];

const getQueryFilter = R.path(['variables', 'filter']);

export const useInboxList = (options?: QueryHookOptions) => {
  const { isAuthorized, isEmailVerified } = useAuth();

  const {
    data,
    error,
    fetchMore,
    loading,
    networkStatus,
    ...rest
  }: QueryResult<InboxListQuery, InboxListQueryVariables> = useQuery(INBOX_LIST_QUERY, {
    skip: !isAuthorized || !isEmailVerified,
    fetchPolicy: 'cache-first',
    context: {
      [HIDE_TOAST_ERROR_MESSAGE]: true,
      [IGNORE_WORKSPACE]: true,
      noBatch: true,
    },
    ...options,
    variables: {
      first: PAGE_SIZE,
      filter: getQueryFilter(options),
    },
  });

  const loadMore = useCallback(() => {
    if (networkStatus === NetworkStatus.loading) {
      return;
    }

    const currentCount = R.pathOr([], ITEMS_PATH, data).length;
    const totalCount = R.pathOr(0, COUNT_PATH, data);

    if (currentCount >= totalCount) {
      return;
    }

    return fetchMore({
      variables: {
        first: PAGE_SIZE,
        skip: currentCount,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const prevItems: any[] = R.path(ITEMS_PATH, prev) || [];
        const fetchMoreResultItems: any[] = R.path(ITEMS_PATH, fetchMoreResult) || [];

        return R.assocPath(ITEMS_PATH, [...prevItems, ...fetchMoreResultItems], prev);
      },
    }).catch(err => {});
  }, [fetchMore, data, networkStatus]);

  const inboxList: InboxItemFragment[] = R.pathOr([], ITEMS_PATH, data);
  const count: number = R.pathOr(0, COUNT_PATH, data);
  const hasMore = inboxList.length < count;

  return {
    inboxList,
    count,
    hasMore,
    loadMore,
    fetchMore,
    error,
    loading: networkStatus === NetworkStatus.loading && (!data || !error),
    networkStatus,
    ...rest,
  };
};

const POOL_INTERVAL = 30000;

export const useUndoneInboxCount = () => {
  const { isAuthorized, isEmailVerified } = useAuth();

  const { data, networkStatus } = useQuery(UNDONE_INBOX_COUNT_QUERY, {
    skip: !isAuthorized || !isEmailVerified,
    pollInterval: POOL_INTERVAL,
    context: {
      [HIDE_TOAST_ERROR_MESSAGE]: true,
      [IGNORE_WORKSPACE]: true,
      noBatch: true,
    },
  });

  const count = R.pathOr(0, UNDONE_COUNT_PATH, data);

  return { count, loading: networkStatus === NetworkStatus.loading };
};
