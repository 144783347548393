// @flow
import React from 'react';
import styled from '@emotion/styled';

type DeveloperHomePageContentProps = {
  nav: React$Node;
  content: React$Node;
}

export const DeveloperHomeMainPlateTag = styled.div`
  display: flex;
  height: calc(100vh - ${props => props.theme.SIZES.USER_FLOW.headerMdHeight});
`;

export const DeveloperHomeMainPlate = ({ nav, content } :DeveloperHomePageContentProps) => (
  <DeveloperHomeMainPlateTag>
    { nav }
    { content }
  </DeveloperHomeMainPlateTag>
);
