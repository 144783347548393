// @flow

import * as R from 'ramda';
import { createSelector, type Selector } from 'reselect';

import { omitDeep } from 'utils/format';

export type ApolloAuthenticationSettingsConnectionFacebook = {
  enabled: boolean,
  app_id: string,
  app_secret: string,
};

export type ApolloAuthenticationSettingsConnectionGoogle = {
  enabled: boolean,
  client_id: string,
  client_secret: string,
};

export type ApolloAuthenticationSettingsConnectionGithub = {
  enabled: boolean,
  client_id: string,
  client_secret: string,
};

export type ApolloAuthenticationSettingsConnections = $Shape<{
  github: ApolloAuthenticationSettingsConnectionGithub,
  google: ApolloAuthenticationSettingsConnectionGoogle,
  facebook: ApolloAuthenticationSettingsConnectionFacebook,
}>;

export type ApolloAuthenticationSettings = $Shape<{
  allowedCallbacks: string[],
  allowedWebOrigins: string[],
  allowedLogouts: string[],
  connections: ApolloAuthenticationSettingsConnections,
}>;

type ConnectionNames = 'google' | 'github' | 'facebook';
type Connections = (
  ApolloAuthenticationSettingsConnectionFacebook |
  ApolloAuthenticationSettingsConnectionGoogle |
  ApolloAuthenticationSettingsConnectionGithub
);

export const getAuthenticationSettings: Selector<
  ApolloAuthenticationSettings,
  any,
  ApolloAuthenticationSettings,
> = (authenticationSettings = {}) => omitDeep(authenticationSettings, '__typename');

export const getConnections: Selector<
  ApolloAuthenticationSettings,
  any,
  ApolloAuthenticationSettingsConnections,
> = createSelector(
  getAuthenticationSettings,
  R.cond([
    [R.propEq('connections', null), () => ({})],
    [R.T, R.propOr({}, 'connections')],
  ]),
);

export const getConnection: Selector<
  ApolloAuthenticationSettings,
  ConnectionNames,
  Connections,
> = createSelector(
  getConnections,
  (_, connectionName) => connectionName,
  (connections, connectionName) => R.cond([
    [R.propEq(connectionName, null), () => ({ enabled: false })],
    [R.T, R.propOr({ enabled: false }, connectionName)],
  ])(connections),
);


export const getAllowedCallbacks: Selector<
  ApolloAuthenticationSettings,
  any,
  string[],
> = createSelector(
  getAuthenticationSettings,
  R.propOr([], 'allowedCallbacks'),
);

export const getAllowedWebOrigins: Selector<
  ApolloAuthenticationSettings,
  any,
  string[],
> = createSelector(
  getAuthenticationSettings,
  R.propOr([], 'allowedWebOrigins'),
);

export const getAllowedLogouts: Selector<
  ApolloAuthenticationSettings,
  any,
  string[],
> = createSelector(
  getAuthenticationSettings,
  R.propOr([], 'allowedLogouts'),
);

