// @flow

import React from 'react';
import fp from 'lodash/fp';
import { compose, mapProps } from 'recompose';
import cx from 'classnames';

import './withFieldForm.scss';

/** redux-form input object which passed to props */
type FormInputObject = {
  value: string,
  name: string,
  onChange: Function,
  onFocus: Function,
  onBlur: Function,
}

/** redux-form meta object which passed to props */
type FormMetaObject = {
  error: string,
  touched: boolean,
}

/**
 * @prop {*} input redux-form input object
 * @prop {*} meta redux-form meta object
 * @prop {*} label control label
 * @prop {*} required control required rule
 */
type WithFieldFormProps = {
  input: FormInputObject,
  meta: FormMetaObject,
  label?: string,
  required?: boolean,
  disabled?: boolean,
  stretch?: boolean,
  hideErrorIndicator?: boolean,
  hideErrorLabel?: boolean,
}

/** add form mockup to control */
export const withFieldFormMockup: HOC<WithFieldFormProps, any> = (RawFieldComponent) => (props: WithFieldFormProps): * => {
  const { label, hideErrorIndicator = false, hideErrorLabel = false, required, meta: { touched, error } = {}, stretch } = props;
  const hasError = touched && error;

  const validationLabelStyleName = cx(
    'input-validation-label',
    { active: hasError },
  );

  return (
    <div className="form-group" styleName={ hasError && !hideErrorIndicator ? 'error' : '' } css={ stretch ? { flex: 1 } : {} }>
      <label className="input-label">
        { label }
        <If condition={ !!required }>
          <span className="required-indicator" />
        </If>
      </label>
      <div styleName="form-component-wrapper">
        <RawFieldComponent { ...props } />
        <If condition={ !hideErrorIndicator }>
          <div styleName="input-indicator" />
        </If>
      </div>
      <If condition={ !hideErrorLabel }>
        <label className="input-validation-label" styleName={ validationLabelStyleName }>{ error || '&nbsp;' }</label>
      </If>
    </div>
  );
};

type ReduxFormInputMapper = (input: FormInputObject) => Object
type ReduxFormMetaMapper = (input: FormMetaObject) => Object

/**
 * map redux-form input prop to the component
 *
 * @param {*} mapper the predicate that map redux-from input object to component props
 * */
export const mapReduxFormInput = (mapper?: ReduxFormInputMapper = inputObject => inputObject) =>
  mapProps((ownProps: WithFieldFormProps) => fp.compose(
    fp.omit(['input']),
    fp.merge(mapper(ownProps.input)),
  )(ownProps));

/**
 * map redux-form input prop to the component
 *
 * @param {*} mapper the predicate that map redux-from meta object to component props
 * */
export const mapReduxFormMeta = (mapper?: ReduxFormMetaMapper = metaObject => metaObject) =>
  mapProps((ownProps: WithFieldFormProps) => fp.compose(
    fp.omit(['meta']),
    fp.merge(mapper(ownProps.meta)),
  )(ownProps));

/**
 * hoc add mockup to the control and pass redux-form objects to props
 *
 * @param {*} inputMapper the predicate that map redux-from input object to component props
 * @param {*} metaMapper the predicate that map redux-from meta object to component props
 */
export const withReduxFieldForm = (inputMapper?: ReduxFormInputMapper, metaMapper?: ReduxFormMetaMapper) => compose(
  withFieldFormMockup,
  mapReduxFormInput(inputMapper),
  mapReduxFormMeta(metaMapper),
);
