// @flow

import React from 'react';
import { Grid, SecondaryNavigation, Row } from '@8base/boost';
import { css } from '@emotion/core';

type AppFlowSecondaryNavProps = {
  children: ?React$Node,
};

export const AppFlowSecondaryNav = ({ children, ...rest }: AppFlowSecondaryNavProps) => (
  <Grid.Box area="secondaryNav">
    <Row css={ css`border-right: 1px solid #d0d7dd; flex: 1; padding-top: 24px;` } growChildren>
      <SecondaryNavigation { ...rest }>
        { children }
      </SecondaryNavigation>
    </Row>
  </Grid.Box>
);

