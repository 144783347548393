// @flow
// $FlowFixMe
import React from 'react';
import { AsyncContent, Row, Column, Text } from '@8base/boost';
import { css } from '@emotion/core';
import { DeveloperHomeContentPlate } from 'routes/developer-home/components';

import { InboxTabs } from '../components/InboxTabs';

/**
 * Inbox page
 * URL like /developer-home/inbox, also look at APP_URL.developerHomeInbox
 */
export const DeveloperHomeInboxContainer = ({ loading }: *) => {

  return (
    <AsyncContent stretch loading={ loading }>
      <DeveloperHomeContentPlate css={ css`padding-bottom: 0 !important;` } { ...E2E.$props('developerHome.workspaces.root') }>
        <Column gap="lg" css={ css`width: 100%; height: 100%;` } alignItems="stretch">
          <Row justifyContent="between" alignItems="center" >
            <Row alignItems="center">
              <Text kind="h1">Inbox</Text>
            </Row>
          </Row>
          <InboxTabs />
        </Column>
      </DeveloperHomeContentPlate>
    </AsyncContent>
  );
};
