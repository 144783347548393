// @flow

import gql from 'graphql-tag';

import { WORKSPACE_ITEM_FRAGMENT } from '../fragments/workspaces';

export const WORKSPACES_LIST_QUERY = gql`
  query WorkspacesList {
    system {
      workspacesList {
        items {
          ...WorkspaceItem
        }
        count
      }
      workspacesFrontendList {
        items {
          ...WorkspaceItem
        }
        count
      }
    }
  }

  ${WORKSPACE_ITEM_FRAGMENT}
`;

export const CHECK_WORKSPACE_CREATION_QUERY = gql`
  query CheckWorkspaceCreation($workspaceId: String!) {
    asyncSessionStatus(sessionId: $workspaceId) {
      status
      message
    }
  }
`;
