import { REGIONS_ENDPOINTS } from 'common/constants/regions';

import { getServerUrl } from './processEnv';
import { environmentAccessor } from './environmentAccessor';

export const getEndpoint = (workspaceId?: string, { baseUrl = null, ignoreEnvironment = false } = {}) => {
  const serverUrl = !!baseUrl ? baseUrl : getServerUrl();
  const environment = environmentAccessor.getEnvironment(workspaceId);

  if (!workspaceId) {
    return serverUrl;
  }

  return !environment || environmentAccessor.isMasterEnvironment(workspaceId) || ignoreEnvironment
    ? `${serverUrl}/${workspaceId}`
    : `${serverUrl}/${workspaceId}_${environment}`;
};

export const mapRegionToEndPoint = (region) => {
  switch (region) {
    case 'eu-west-2':
      return REGIONS_ENDPOINTS.UK;
    case 'us-east-1':
      return REGIONS_ENDPOINTS.US;
    default:
      return getServerUrl();
  }
};
