// @flow
// $FlowFixMe waiting for update Flow
import React from 'react';
import { Dialog, Text, Button, Card } from '@8base/boost';
// $FlowFixMe waiting for update Flow
import { generatePath } from 'react-router-dom';

import { APP_URL } from 'common/routing';

const Header = ({ title, onClose }) => {
  if (onClose) {
    return (
      <Dialog.Header
        title={ title }
        onClose={ onClose }
      />
    );
  }

  return (
    <Card.Header>{ title }</Card.Header>
  );
};

type PaymentWarningProps = {
  newOwnerType?: string,
  newOwnerIdentity?: string,
  onClose?: (any) => void,
}

export const PaymentWarning = ({ newOwnerType, newOwnerIdentity, onClose }: PaymentWarningProps) => {
  const onUpdateClick = () => {
    const link = newOwnerType === 'organization'
      ? generatePath(APP_URL.organizationSettingsBilling, { organizationId: newOwnerIdentity })
      : APP_URL.billing;

    window.open(link);
  };

  const title = newOwnerType === 'organization' ? 'Organization payment error' : 'Payment error';
  const bodyAdditional = newOwnerType === 'organization' && 'to organization';

  return (
    <React.Fragment>
      <Header
        title={ title }
        onClose={ onClose }
      />
      <Dialog.Body>
        <Text>
          You can't perform this action until you provide valid payment card { bodyAdditional }
        </Text>
      </Dialog.Body>
      <Dialog.Footer>
        <Button onClick={ onUpdateClick }>
          Update Payment Details
        </Button>
      </Dialog.Footer>
    </React.Fragment>
  );
};
