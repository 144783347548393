// @flow
import React from 'react';
import { Avatar, Column, Dropdown, Icon, Link, Row, Switch, Text, Tooltip } from '@8base/boost';

import { Trans } from 'utils/translate';

type UserDropdownProps = {
  firstName: string;
  lastName: string;
  email: string;
  isOwner?: boolean;
  insideWorkspace?: boolean;
  avatarDownloadUrl?: string;
  openMyAccount?: (callbackAfterAction: Function) => any;
  openLogOutModal: (callbackAfterAction: Function) => any;
  onChangeLearningMode: () => any;
  learningMode: boolean;
  hideLearningMode?: boolean;
}

export const UserDropdown = ({
  firstName,
  lastName,
  isOwner,
  insideWorkspace,
  email,
  avatarDownloadUrl,
  openMyAccount,
  openLogOutModal,
  onChangeLearningMode,
  learningMode,
  hideLearningMode,
}: UserDropdownProps) => {

  const tooltipMessage = (
    <span css={{ fontSize: 12 }}>
      <b css={{ fontWeight: 500 }}>Learning Mode</b> shows you how to<br />get started using 8base.
    </span>
  );

  const learningModeLabel = (
    <Row alignItems="center">
      <Text color="WHITE">
        <Trans i18nKey="shared.learningMode">Learning Mode</Trans>
      </Text>
      <Tooltip css={{ display: 'flex' }} placement="bottom-end" message={ tooltipMessage } cursor="default">
        <Icon name="Info" size="xs" />
      </Tooltip>
    </Row>
  );

  return (
    <Dropdown defaultOpen={ false }>
      <Dropdown.Head data-e2e-id={ E2E.$id('root.userMenuToggle') }>
        <Tooltip modifiers={{ offset: { offset: '0, 4' }}} cursor="pointer" message="Account">
          <Avatar size="sm" firstName={ firstName } lastName={ lastName } src={ avatarDownloadUrl } />
        </Tooltip>
      </Dropdown.Head>
      <Dropdown.Body
        background="dark"
        withPortal
        offset="sm"
        pin="right"
        padding="lg"
        data-e2e-id={ E2E.$id('root.userMenuBody') }
      >
        { ({ closeDropdown }) => (
          <Column>
            <Row alignItems="center">
              <Avatar size="lg" firstName={ firstName } lastName={ lastName } src={ avatarDownloadUrl } />
              <Column gap="none">
                <If condition={ insideWorkspace }>
                  <Text kind="small-1" color="LIGHT_TEXT_COLOR">
                    <Choose>
                      <When condition={ isOwner }>
                        <Trans i18nKey="shared.workspaceOwner">Workspace Owner</Trans>
                      </When>
                      <Otherwise>
                        <Trans i18nKey="shared.workspaceMember">Workspace Member</Trans>
                      </Otherwise>
                    </Choose>
                  </Text>
                </If>
                <Text color="WHITE" weight="semibold">{ `${firstName || ''} ${lastName || ''}` }</Text>
                <Text kind="overline-1" color="GRAY_20">{ email }</Text>
              </Column>
            </Row>

            <div css={{ height: 1, width: '100%', background: '#A5AFB6', opacity: 0.2 }} />

            <If condition={ !!openMyAccount }>
              <Link
                css={{ fontSize: 12, '&:active': { color: 'white' }}}
                color="WHITE" onClick={ openMyAccount && openMyAccount(closeDropdown) }
                { ...E2E.$props('root.myAccountLink') }>
                  My Account
              </Link>
            </If>
            <Link
              css={{ fontSize: 12, '&:active': { color: 'white' }}}
              color="WHITE"
              onClick={ openLogOutModal(closeDropdown) }>
              Logout
            </Link>
            <If condition={ !hideLearningMode } >
              <div css={{ height: 1, width: '100%', background: '#A5AFB6', opacity: 0.2 }} />

              <Switch label={ learningModeLabel } value={ learningMode } onChange={ onChangeLearningMode } />
            </If>
          </Column>
        ) }
      </Dropdown.Body>
    </Dropdown>
  );
};
