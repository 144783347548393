// @flow
'no babel-plugin-flow-react-proptypes';
import * as R from 'ramda';
import * as React from 'react';
import { Route, Redirect, withRouter, Switch } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';

import { PrivateRoute } from './index';

const mergePaths = (prefix: string, path: string) => `${prefix}${path}`;

/** replace path to the full path if the field in the props object exist */
const getFullPathSetter = (nestedPath: string) => (pathFied: 'path' | 'from' | 'to') => (props: Object) => {
  const localPath = props[pathFied];

  return R.isNil(localPath)
    ? props
    : R.assoc(pathFied, mergePaths(nestedPath, localPath), props);
};

/** replace path to the full path for the several fields */
export const setFullPathsToProps = (nestedPath: string, nestedUrl: string): * => {
  const setFullPathToPropOrNothing = getFullPathSetter(nestedPath);
  const setFullUrlToPropOrNothing = getFullPathSetter(nestedUrl);

  return R.compose(
    setFullPathToPropOrNothing('path'),
    setFullPathToPropOrNothing('from'),
    setFullUrlToPropOrNothing('to'),
  );
};

/** return the predicate that must add the nested path suffix to the path props */
const cloneElementWithFullPath = (nestedPath: string, nestedUrl: string) => (routeComponent: any) =>
  React.cloneElement(
    routeComponent,
    setFullPathsToProps(nestedPath, nestedUrl)(routeComponent.props),
  );

/** union of the all posible types of the component children */
type PossibleChildren =
  | typeof Route
  | typeof Redirect
  | typeof PrivateRoute
  | typeof Route;

type NestedRoutesOwnProps = {|
  children: React.ChildrenArray<React.Element<PossibleChildren>>
|};

class NestedRoutesRaw extends React.PureComponent<{| ...ContextRouter, ...NestedRoutesOwnProps |}> {
  render() {
    const { children, match: { path: nestedPath, url: nestedUrl }} = this.props; //TODO check from path to url (because of dynamic paths)

    const routesWithPrefixPath = React.Children.map(
      children,
      cloneElementWithFullPath(nestedPath, nestedUrl),
    );

    return <Switch>{ routesWithPrefixPath }</Switch>;
  }
}

/** replace local path to the fullpath at the all children  */
export const NestedRoutes = withRouter(NestedRoutesRaw);
