// @flow

import React from 'react';
import { Dialog, Button, SelectField, useModal } from '@8base/boost';
import { useMutation, useQuery } from 'react-apollo';
import { Form, Field } from '@8base-react/forms';
import * as R from 'ramda';

import { apolloTeamMembersListSelectors } from 'graphql/selectors';
import { TEAM_MEMBERS_LIST_QUERY } from 'graphql/queries';
import { TEAM_MEMBER_UPDATE_MUTATION } from 'graphql/mutations';
import { toast } from 'react-toastify';
import { i18n } from 'i18n';

const ROLE_ADD_TEAM_MEMBER_DIALOG_ID = 'ROLE_ADD_TEAM_MEMBER_DIALOG_ID';

const getTeamMembersData = R.path(['system', 'environmentMembersList']);
const getTeamMembersList = R.path(['system', 'environmentMembersList', 'items']);

const RoleAddTeamMemberDialog = () => {
  const { closeModal, args = {}} = useModal(ROLE_ADD_TEAM_MEMBER_DIALOG_ID);
  const { data, loading } = useQuery(TEAM_MEMBERS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    skip: !args.roleId,
    variables: {
      filter: {
        roleId: { not_equals: args.roleId },
      },
    },
  });

  const [teamMemberUpdate] = useMutation(TEAM_MEMBER_UPDATE_MUTATION, {
    refetchQueries: ['TeamMembersList'],
  });

  const onSubmit = async ({ teamMembers: formTeamMembers }) => {
    if (!args.roleId) {
      return;
    }

    window.trackEvent('Team', 'Update user');

    const membersList = getTeamMembersList(data).filter(member => formTeamMembers.includes(member.email));

    const updatePromiseList = membersList.map(member => {
      const newRoles = [
        ...R.pathOr([], ['items'], member.roles).map(el => el.id),
        args.roleId,
      ];

      return teamMemberUpdate({
        variables: {
          data: {
            roles: R.uniq(newRoles),
          },
          filter: { email: member.email },
        },
      });
    });

    await Promise.all(updatePromiseList);

    toast.success(i18n.t('dialogs.roleAddTeamMember.successMessage'));
  };

  const onSuccess = () => {
    closeModal(ROLE_ADD_TEAM_MEMBER_DIALOG_ID);
  };

  const onClose = () => {
    closeModal(ROLE_ADD_TEAM_MEMBER_DIALOG_ID);
  };


  return (
    <Dialog id={ ROLE_ADD_TEAM_MEMBER_DIALOG_ID } onClose={ onClose }>
      <Form onSubmit={ onSubmit } onSuccess={ onSuccess } >
        { ({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={ handleSubmit }>
            <Dialog.Header title={ i18n.t('dialogs.roleAddTeamMember.title') } onClose={ onClose } />
            <Dialog.Body>
              <Field
                name="teamMembers"
                label={ i18n.t('dialogs.roleAddTeamMember.teamMembersLabel') }
                placeholder={ i18n.t('dialogs.roleAddTeamMember.teamMembersPlaceholder') }
                component={ SelectField }
                options={ apolloTeamMembersListSelectors.getSelectOptions(getTeamMembersData(data)) }
                loading={ loading }
                multiple
              />
            </Dialog.Body>
            <Dialog.Footer>
              <Button
                type="button"
                color="neutral"
                variant="outlined"
                onClick={ onClose }
                disabled={ submitting }
              >
                { i18n.t('shared.cancel') }
              </Button>
              <Button
                type="submit"
                disabled={ pristine }
                loading={ submitting }
                onClick={ handleSubmit }
              >
                { i18n.t('shared.add') }
              </Button>
            </Dialog.Footer>
          </form>
        ) }
      </Form>
    </Dialog>
  );
};

RoleAddTeamMemberDialog.ID = ROLE_ADD_TEAM_MEMBER_DIALOG_ID;

export { RoleAddTeamMemberDialog };

