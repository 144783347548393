import React from 'react';
import * as R from 'ramda';
import { css } from '@emotion/core';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { Form as FormLogic, Field } from '@8base-react/forms';
import { Dialog, Button, InputField, Text, Column, useModal, Row } from '@8base/boost';
import { TABLES_SCHEMA_QUERY, TABLE_FRAGMENT, useTablesList } from '@8base-react/table-schema-provider';
import { tablesListSelectors } from '@8base/utils';

import { ViewDeleteDialog } from 'dialogs/ViewDeleteDialog';
import { formatToTableName } from 'utils/NameConvertor';
import { required } from 'utils/formValidations';
import { CodeMirrorField } from 'common/CodeMirrorField';
import { i18n } from 'i18n';
import { Trans } from 'utils/translate';

const VIEW_UPDATE_MUTATION = gql`
  mutation ViewUpdate($data: ViewUpdateInput!) {
    viewUpdate(data: $data) {
      ...TableFragment
    }
  }
  ${TABLE_FRAGMENT}
`;

const VIEW_UPDATE_DIALOG_ID = 'VIEW_UPDATE_DIALOG_ID';

const ViewUpdateDialog = () => {
  const { closeModal, openModal, args } = useModal(VIEW_UPDATE_DIALOG_ID);

  const { tablesList } = useTablesList();

  const table = tablesListSelectors.getTableById(tablesList, args.id);

  const initialValues = React.useMemo(() => ({
    displayName: R.prop('displayName', table),
    query: R.path(['attributes', 'query'], table),
  }), [table]);

  const [viewUpdate] = useMutation(VIEW_UPDATE_MUTATION, {
    update: (cache, { data }) => {
      try {
        cache.writeQuery({
          query: TABLES_SCHEMA_QUERY,
          data: R.evolve({
            tablesList: {
              items: R.pipe(
                R.reject(R.propEq('id', data.viewUpdate.id)),
                R.append(data.viewUpdate),
              ),
            },
          }, cache.readQuery({ query: TABLES_SCHEMA_QUERY })),
        });

      } catch (e) {

      }
    },
  });

  const onDelete = React.useCallback(() => {
    closeModal(VIEW_UPDATE_DIALOG_ID);

    openModal(ViewDeleteDialog.ID, { id: table.id, name: table.displayName });
  }, [closeModal, openModal, table]);

  const onClose = React.useCallback(() => closeModal(VIEW_UPDATE_DIALOG_ID), [closeModal]);

  const onSubmit = React.useCallback(async (data) => {
    let nextData = R.assoc('name', formatToTableName(data.displayName), data);

    nextData = R.assoc('id', R.prop('id', args), nextData);

    try {
      await viewUpdate({ variables: { data: nextData }});
    } catch (e) {
      if (R.has('graphQLErrors', e)) {
        e.graphQLErrors = e.graphQLErrors.map((err) => {
          if (R.hasPath(['details', 'name'], err)) {
            err = R.assocPath(['details', 'displayName'], R.path(['details', 'name'], err), err);
            err = R.dissocPath(['details', 'name'], err);
          }

          return err;
        });

        throw e;
      }
    }

    onClose();
  }, [viewUpdate, args, onClose]);

  return (
    <Dialog id={ VIEW_UPDATE_DIALOG_ID } onClose={ onClose } size="xxl">
      <FormLogic onSubmit={ onSubmit } initialValues={ initialValues }>
        { ({ handleSubmit, invalid, submitting, pristine }) => (
          <form onSubmit={ handleSubmit } css={ css`height: 100%;` }>
            <Dialog.Header title="Edit View" onClose={ onClose } />
            <Dialog.Body scrollable>
              <Column gap="lg">
                <Row alignItems="center">
                  <Text css={{ fontSize: 12, whiteSpace: 'nowrap', textTransform: 'uppercase' }} weight="semibold">
                    <Trans i18nKey="viewUpdateDialog.createView">
                        Edit view
                    </Trans>
                  </Text>
                  <Field name="displayName" component={ InputField } validate={ required } />
                  <Text css={{ fontSize: 12, whiteSpace: 'nowrap', textTransform: 'uppercase' }} weight="semibold">
                    <Trans i18nKey="viewUpdateDialog.as">
                        as
                    </Trans>
                  </Text>
                </Row>
                <Field
                  stretch
                  name="query"
                  label="SQL Statement (MySQL version: 5.5.1)"
                  component={ CodeMirrorField }
                  validate={ required }
                  mode="text/x-sql"
                />
              </Column>
            </Dialog.Body>
            <Dialog.Footer>
              <Row alignItems="center" stretch>
                <Button type="button" color="danger" variant="link" css={{ marginRight: 'auto !important' }} onClick={ onDelete }>Delete</Button>

                <Button type="button" color="neutral" variant="outlined" disabled={ submitting } onClick={ onClose }>
                  { i18n.t('shared.cancel') }
                </Button>
                <Button type="submit" disabled={ pristine } loading={ submitting }>
                  { i18n.t('shared.update') }
                </Button>
              </Row>
            </Dialog.Footer>
          </form>
        ) }
      </FormLogic>
    </Dialog>
  );
};

ViewUpdateDialog.ID = VIEW_UPDATE_DIALOG_ID;

export { ViewUpdateDialog };
