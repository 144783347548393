// @type

import { transliterate } from 'transliterations';
import fp from 'lodash/fp';

export const VALIDATION_MESSAGES = {
  nameUnique: 'Name should be unique',
  nameLength: 'Name must consist of more than one symbol',
};

const toPascalCase = (str: string) =>
  str.replace(/(?:(^.)|(\s+.))/g, (match) => {
    return match.charAt(match.length - 1).toUpperCase();
  });

const toCamelCase = fp.pipe(
  toPascalCase,
  fp.lowerFirst,
);

const isUniqueName = (currentNames: string[]) => (value: string) => !fp.includes(value, currentNames);
const isNotUniqueName = (currentNames: string[]) => (value: string) => fp.includes(value, currentNames);
const validateLength = (value) => fp.lte(fp.size(value), 1);

export const validateName = fp.curry(
  (currentNames: string[], string: ?string) => fp.cond([
    [isNotUniqueName(currentNames), fp.constant(VALIDATION_MESSAGES.nameUnique)],
    [validateLength, fp.constant(VALIDATION_MESSAGES.nameLength)],
  ])(string),
);

export const formatToDisplayName = fp.pipe(
  fp.toString,
  fp.trim,
  fp.escape,
);

export const formatToName = fp.pipe(
  fp.toString,
  fp.trim,
  transliterate,
  fp.replace(/[^\s_a-zA-Z0-9]*/g, ''),
  toCamelCase,
);

export const formatToTableName = fp.pipe(
  formatToName,
  fp.upperFirst,
);

export const formatToFieldName = fp.pipe(
  formatToName,
  fp.lowerFirst,
);

const generateRegExp = (name: string) => (new RegExp(`${name}(\\d+)$`));
const findIndex = (name: string) => fp.pipe(
  val => val.match(generateRegExp(name)),
  fp.last,
  fp.toNumber,
);

const findMaxIndex = (name: string) => fp.pipe(
  fp.map(findIndex(name)),
  fp.max,
);

export const addIncrementIfNeed = (tableFields: string[]) => (name: string) => {
  const maxIndex = findMaxIndex(name)(tableFields);
  if (fp.isNumber(maxIndex)) return `${name}${maxIndex + 1}`;
  if (isUniqueName(tableFields)(name)) return name;
  return `${name}1`;
};

export const formatToReferenceFieldName = fp.curry(
  (tableFields: string[], name: ?string) => fp.pipe(
    formatToFieldName,
    addIncrementIfNeed(tableFields),
  )(name),
);
