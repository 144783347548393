// @flow

import fp from 'lodash/fp';

export const not = (value: boolean) => !value;

export const notNil: (arg: any) => boolean = fp.pipe(fp.isNil, not);

export const isJson = (value: any) : boolean => {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
};
