import * as R from 'ramda';
import { isRelationField, isAddressField, isPhoneField, isJSONField, isFileField, isListField, tablesListSelectors, tableFieldSelectors } from '@8base/utils';

export const getFieldsOptions = (tableName, tablesList, filterConds = [], depth = 0, path = '', prefix = '') => {
  const table = tablesListSelectors.getTableByName(tablesList, tableName);

  if (!table) {
    return [];
  }

  const fields = R.pipe(
    R.propOr([], 'fields'),
    R.reject(R.anyPass([isAddressField, isPhoneField, isJSONField, isFileField, isListField, ...filterConds])),
  )(table);

  return fields.reduce((result, field) => {
    const value = path ? `${path}.${R.prop('name', field)}` : R.prop('name', field);
    const label = prefix ? `${prefix} → ${R.prop('displayName', field)}` : R.prop('displayName', field);

    if (isRelationField(field)) {
      if (depth < 1) {
        const relationTableName = tableFieldSelectors.getRelationTableName(field);

        return [...result, {
          label: R.prop('displayName', field),
          options: getFieldsOptions(relationTableName, tablesList, filterConds, depth + 1, value, label),
        }];
      }

    } else {

      return [...result, {
        value,
        label,
      }];
    }

    return result;
  }, []);
};
