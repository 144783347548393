import React from 'react';
import * as R from 'ramda';
import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom';

const pickDataProps = R.pipe(
  R.toPairs,
  R.filter(([propName]) => /^data-/.test(propName)),
  R.fromPairs,
);

export const Link = ({
  className,
  to,
  replace,
  children,
  ...rest
}) => (
  <RouterLink
    className={ className }
    to={ to }
    replace={ replace }
    { ...pickDataProps(rest) }
  >
    { children }
  </RouterLink>
);

export const NavLink = ({
  className,
  activeClassName,
  activeStyle,
  exact,
  strict,
  isActive,
  location,
  to,
  replace,
  children,
  ...rest
}) => (
  <RouterNavLink
    className={ className }
    activeClassName={ activeClassName }
    activeStyle={ activeStyle }
    exact={ exact }
    strict={ strict }
    isActive={ isActive }
    location={ location }
    to={ to }
    replace={ replace }
    { ...pickDataProps(rest) }
  >
    { children }
  </RouterNavLink>
);
