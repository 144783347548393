// @flow

import React, { PureComponent } from 'react';
import styled from '@emotion/styled';
import { Form, Field } from '@8base-react/forms';
import { Grid, InputField, Row, Column, Dialog, Button, ModalContext } from '@8base/boost';
import { confirmValue } from 'utils/formValidations';

import { Translation } from 'react-i18next';

import './ConfirmEnter.scss';

type ConfirmEnterDialogProps = {
  children: React$Node | ({ id: string, name: string }) => React$Node,
  placeholder?: string,
  id?: string,
  title: string,

  onDelete: (string) => any,

  'data-e2e-id'?: string,
}

const StyledInput = styled(InputField)(props => ({
  'input, input:focus, input::placeholder': {
    borderColor: props.theme.COLORS.DANGER,
    color: props.theme.COLORS.DANGER,
  },
}));

export class ConfirmEnterDialog extends PureComponent<ConfirmEnterDialogProps> {
  static contextType = ModalContext;

  static defaultProps: $Shape<ConfirmEnterDialogProps> = {
    placeholder: 'Type the name to delete',
    id: 'CONFIRM_ENTER_DIALOG',
  };

  closeDialog = () => {
    const { id } = this.props;

    this.context.closeModal(id);
  };


  renderHead = () => {
    return (
      <div styleName="popup-head">
        <div styleName="popup-title">
          <span className="icon icon-alert" />
          <h3>Warning, Data Loss.</h3>
        </div>
        <div className="card-close" onClick={ this.closeDialog }>&times;</div>
      </div>
    );
  };

  renderContent = ({ args }: *) => {
    const { children, placeholder, title, onDelete } = this.props;

    const renderedChildren = typeof children === 'function'
      ? children(args)
      : children;

    return (
      <Form onSubmit={ async () => {
        await onDelete(args.id);
        this.closeDialog();
      } }>
        { ({ handleSubmit, invalid, submitting, pristine }: *) => (
          <Translation>
            { t => (
              <form onSubmit={ handleSubmit } data-e2e-id={ this.props['data-e2e-id'] }>
                <Dialog.Header title={ title } onClose={ this.closeDialog } />
                <Dialog.Body scrollable>
                  <Grid.Layout stretch columns="80%" justifyContent="center">
                    <Grid.Box>
                      <div styleName="warning-text">
                        { renderedChildren }
                      </div>
                      <Column gap="lg">
                        <Field
                          name="name"
                          placeholder={ placeholder }
                          styleName="input"
                          component={ StyledInput }
                          autoComplete={ false }
                          validate={ value => confirmValue(value, args.name) }
                          { ...E2E.$props('components.confirmEnter.input') }
                        />
                      </Column>
                    </Grid.Box>
                  </Grid.Layout>
                </Dialog.Body>
                <Dialog.Footer>
                  <Row justifyContent="center" stretch>
                    <Button
                      type="button"
                      color="neutral"
                      variant="outlined"
                      disabled={ submitting }
                      onClick={ this.closeDialog }
                      { ...E2E.$props('components.confirmEnter.cancel') }
                    >
                      { t('shared.cancel') }
                    </Button>
                    <Button
                      color="danger"
                      type="submit"
                      disabled={ pristine || invalid || submitting }
                      loading={ submitting }
                      { ...E2E.$props('components.confirmEnter.submit') }
                    >
                      { t('shared.delete') }
                    </Button>
                  </Row>
                </Dialog.Footer>
              </form>
            ) }
          </Translation>
        ) }
      </Form>
    );
  };

  render() {
    const { id } = this.props;

    return (
      <Dialog id={ id } size="md">
        { this.renderContent }
      </Dialog>
    );
  }
}
