import React, { useMemo, useCallback } from 'react';
import { Icon, Row, SelectField, Link, Column, Divider, RadioGroupField, Radio } from '@8base/boost';
import { Form, Field, FieldArray } from '@8base-react/forms';
import { useTablesList } from '@8base-react/table-schema-provider';

import { required } from 'utils/formValidations';

import { getFieldsOptions } from './getFieldsOptions';

const TableSortFieldArray = ({ fields, tableName, onCancel, transformOptions }) => {
  const { tablesList, loading } = useTablesList();

  const fieldOptions = useMemo(() => {
    let options = getFieldsOptions(tableName, tablesList, []);

    if (typeof transformOptions === 'function') {
      options = transformOptions(options);
    }

    return options;
  }, [tableName, tablesList, transformOptions]);

  const onAddField = useCallback(() => {
    fields.push({ name: null, order: 'DESC' });
  }, [fields]);

  const onRemoveField = (index) => () => { fields.remove(index); };

  return (
    <Column>
      <Column gap="lg" stretch>
        { React.Children.toArray(fields.map((name, index) => (
          <Row key={ name } alignItems="center" gap="lg" stretch>
            <Icon name="Delete" size="sm" cursor="pointer" css={{ marginTop: 18 }} onClick={ onRemoveField(index) } />
            <Field name={ `${name}.name` } label="Sort By" validate={ required } component={ SelectField } options={ fieldOptions } withPortal={ false } loading={ loading } />

            <Field name={ `${name}.order` } label="Sort From" component={ RadioGroupField } direction="row" gap="none" stretch>
              <Radio.Button size="sm" label="ASC" value="ASC" />
              <Radio.Button size="sm" label="DESC" value="DESC" />
            </Field>
          </Row>
        ))) }
      </Column>

      <Divider css={{ margin: '0 -16px', width: 'calc(100% + 32px)' }} />

      <Row justifyContent="between" alignItems="center" stretch>
        <Row gap="sm" alignItems="center" cursor="pointer" onClick={ onAddField }>
          <Icon name="CircleAdd" size="sm" color="SECONDARY_TEXT_COLOR" />
          <Link tagName="span" color="SECONDARY_TEXT_COLOR">Add Sort</Link>
        </Row>
        <Row gap="sm" alignItems="center" cursor="pointer">
          <Link tagName="span" color="SECONDARY_TEXT_COLOR" onClick={ onCancel }>Cancel</Link>
          <Link tagName="button" type="submit" color="BLUE">Apply</Link>
        </Row>
      </Row>
    </Column>
  );
};

export const TableSortForm = ({ tableName, initialValues, onChange, onCancel, transformOptions }) => {
  const onSubmit = (data) => {
    onChange(data);
  };

  return (
    <Form onSubmit={ onSubmit } initialValues={ initialValues }>
      { ({ handleSubmit, ...rest }) => (
        <form onSubmit={ handleSubmit } css={{ padding: '16px', minWidth: 500 }}>
          <FieldArray name="sort" component={ TableSortFieldArray } tableName={ tableName } onCancel={ onCancel } transformOptions={ transformOptions } />
        </form>
      ) }
    </Form>
  );
};
