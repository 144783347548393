// @flow
import React from 'react';
import { IfAllowed } from '@8base-react/permissions-provider';
import { css } from '@emotion/core';

import { i18n } from 'i18n';
import { AppFlow } from 'common/AppFlow';
import { APP_URL, useBuildUrl } from 'common/routing';

const PropsProxy = ({ children, ...rest }: { children: Function }) => children(rest);

const AppFlowMainNav = () => {
  const buildUrl = useBuildUrl();

  return (
    <AppFlow.Nav.Plate css={ css`min-height: 660px; max-height: 100vh;` } color="GRAY_60">
      <AppFlow.Nav.Item
        icon="Home"
        to={ buildUrl(APP_URL.workspaceHome) }
        exact
        label={ i18n.t('mainSections.home', { defaultValue: 'Home' }) }
        { ...PT.$props('mainNav.dashboard') }
      />
      <AppFlow.Nav.Item
        icon="Data"
        to={ buildUrl(APP_URL.data) }
        label={ i18n.t('mainSections.dataBuilder', { defaultValue: 'Data' }) }
        { ...PT.$props('mainNav.data') }
      />
      <PropsProxy>
        { ({ parentExpanded }) => (
          <IfAllowed permissions={ [['custom', 'logic', 'view']] }>
            <AppFlow.Nav.Item
              color="PRIMARY_TEXT_COLOR"
              icon="Logic"
              to={ buildUrl(APP_URL.functions) }
              label={ i18n.t('mainSections.functions', { defaultValue: 'Functions' }) }
              { ...PT.$props('mainNav.logic') }
              parentExpanded={ parentExpanded }
            />
          </IfAllowed>
        ) }
      </PropsProxy>
      <AppFlow.Nav.Item
        icon="GraphQL"
        to={ buildUrl(APP_URL.apiExplorer) }
        label={ i18n.t('mainSections.apiExplorer', { defaultValue: 'API Explorer' }) }
        { ...PT.$props('mainNav.apiExplorer') }
      />
      <AppFlow.Nav.Item
        icon="Users"
        to={ buildUrl(APP_URL.users) }
        label={ i18n.t('mainSections.users', { defaultValue: 'Users' }) }
        { ...PT.$props('mainNav.apiUsers') }
      />
      <AppFlow.Nav.Item
        icon="AppServices"
        to={ buildUrl(APP_URL.appServices) }
        label={ i18n.t('mainSections.appServices', { defaultValue: 'App Services' }) }
        { ...PT.$props('mainNav.appServices') }
      />
      <AppFlow.Nav.Item
        icon="Bolt"
        to={ buildUrl(APP_URL.integrations) }
        label={ i18n.t('mainSections.integrations', { defaultValue: 'Integrations' }) }
        { ...PT.$props('mainNav.integrations') }
      />
      <AppFlow.Nav.Item
        icon="Settings"
        to={ buildUrl(APP_URL.settings) }
        label={ i18n.t('mainSections.settings', { defaultValue: 'Settings' }) }
        { ...PT.$props('mainNav.apiSettings') }
      />
      <AppFlow.Nav.ConsoleLogo />
    </AppFlow.Nav.Plate>
  );
};

export { AppFlowMainNav };
