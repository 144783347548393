exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "body.modal-shown {\n  overflow: hidden; }\n  body.modal-shown #root {\n    overflow: hidden;\n    -webkit-filter: blur(3px);\n            filter: blur(3px); }\n\n.Modal__modal-overlay___LPlCh {\n  overflow: hidden;\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(60, 87, 118, 0.6); }\n\n.Modal__modal___jlFVB {\n  position: fixed; }\n", ""]);

// exports
exports.locals = {
	"modal-overlay": "Modal__modal-overlay___LPlCh",
	"modal": "Modal__modal___jlFVB"
};