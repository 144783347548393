// @flow
// $FlowFixMe
import React, { useCallback, useMemo } from 'react';
// $FlowFixMe
import { useHistory } from 'react-router-dom';
import * as R from 'ramda';
import gql from 'graphql-tag';
import { Dialog, useModal, Button, Text, Column, Row, Heading, Icon, InputField } from '@8base/boost';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { Trans } from 'utils/translate';
import { Field, Form } from '@8base-react/forms';
import { css } from '@emotion/core';
import { i18n } from 'i18n';

import { TOAST_SUCCESS_MESSAGE, DIALOG_ID, IGNORE_WORKSPACE } from 'common/constants/apolloOperationContextOptions';
import { ORGANIZATION_QUERY } from 'graphql/queries';
import { buildUrl, APP_URL } from 'common';

import { useUserAccountInfo } from 'graphql/hooks';

const TEAM_MEMBER_REMOVE_FROM_ORGANIZATION_DIALOG_ID = 'TEAM_MEMBER_REMOVE_FROM_ORGANIZATION_DIALOG_ID';

const REMOVE_MEMBER_FROM_ORGANIZATION_MUTATION = gql`
  mutation RemoveMemberFromOrganization(
    $email: String!
    $organizationId: String!
  ) {
    system {
      organizationUserRemove(organizationId: $organizationId, email: $email) {
        success
      }
    }
  }
`;

const Title = ({ label }) => (
  <Row alignItems="center" gap="sm">
    <Icon name="DangerAlert" color="DANGER_DARK" />
    <Heading type="h4">
      { label }
    </Heading>
  </Row>);

const getDetails = (args: any) => {
  if (!args || R.isEmpty(args)) {
    return {
      organizationId: null,
      organizationName: null,
      firstName: null,
      lastName: null,
      email: null,
    };
  }

  const {
    organizationId,
    organizationName,
    firstName,
    lastName,
    email,
  } = args;

  return {
    organizationId,
    organizationName,
    firstName,
    lastName,
    email,
  };
};

/**
 * Dialog where user can remove team member from organization
 * and remove access to organization workspaces
 */
export const TeamMemberRemoveFromOrganizationDialog = () => {
  const { isOpen, args } = useModal(TEAM_MEMBER_REMOVE_FROM_ORGANIZATION_DIALOG_ID);
  const { organizationId, email } = useMemo(() => getDetails(args), [args]);

  if (isOpen && organizationId && email) {
    return <TeamMemberRemoveFromOrganizationDialogContent />;
  }

  return null;
};

const TeamMemberRemoveFromOrganizationDialogContent = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const { closeModal, args } = useModal(TEAM_MEMBER_REMOVE_FROM_ORGANIZATION_DIALOG_ID);
  const {
    organizationId,
    organizationName,
    firstName,
    lastName,
    email,
  } = useMemo(() => getDetails(args), [args]);

  const userFullName = `${firstName} ${lastName}`;

  const { userAccountInfo } = useUserAccountInfo({
    notifyOnNetworkStatusChange: false,
    context: {
      [IGNORE_WORKSPACE]: true,
      noBatch: true,
    },
    fetchPolicy: 'cache-first',
  });

  const [removeUser] = useMutation(REMOVE_MEMBER_FROM_ORGANIZATION_MUTATION, {
    notifyOnNetworkStatusChange: true,
    context: {
      [TOAST_SUCCESS_MESSAGE]: t('removeUserFromOrganizationDialog.success', { defaultValue: 'User removed' }),
      [DIALOG_ID]: TEAM_MEMBER_REMOVE_FROM_ORGANIZATION_DIALOG_ID,
    },
    refetchQueries: [{
      query: ORGANIZATION_QUERY,
      variables: {
        organizationId,
      },
    }],
    awaitRefetchQueries: true,
  });

  const onClose = useCallback(() => {
    closeModal(TEAM_MEMBER_REMOVE_FROM_ORGANIZATION_DIALOG_ID);
  }, [closeModal]);

  const onSubmit = useCallback(async (data) => {
    await removeUser({
      variables: {
        email,
        organizationId,
      },
    });

    window.trackEvent('Organization Remove Team Members', 'Remove member');

    history.push(buildUrl(APP_URL.organizationSettingsTeam, { pathParams: { organizationId }}));

    onClose();
  }, [email, history, onClose, organizationId, removeUser]);

  const validateRole = useCallback((value) => value === userFullName ? null : i18n.t('validation.valueNoMatch'), [userFullName]);
  const isLoggedInUser = userAccountInfo && userAccountInfo.email === email;

  return (
    <Dialog
      id={ TEAM_MEMBER_REMOVE_FROM_ORGANIZATION_DIALOG_ID }
      onClose={ onClose }
    >
      <Form onSubmit={ onSubmit }>{ ({ handleSubmit, invalid, pristine, submitting }) =>
        <>
          <Dialog.Header
            title={ <Title label={ isLoggedInUser
              ? t('organization.leave')
              : t('dialogs.removeUserFromOrganizationDialog.title') }
            /> }
            onClose={ onClose }
          />
          <Dialog.Body>
            <Column css={ css`padding-bottom: 20px;` }>
              <Choose>
                <When condition={ isLoggedInUser } >
                  <Text>
                    <Trans i18nKey="dialogs.removeUserFromOrganizationDialog.leavingText">
                      You are leaving the { organizationName } Organization.
                    </Trans>
                  </Text>
                </When>
                <Otherwise>
                  <Text>
                    <Trans i18nKey="dialogs.removeUserFromOrganizationDialog.removingText">
                      You are removing user <Text weight="bold">{ userFullName }</Text> from the Organization { organizationName }.
                    </Trans>
                  </Text>
                </Otherwise>
              </Choose>
              <Text>
                <Trans i18nKey="dialogs.removeUserFromOrganizationDialog.typeToConfirm">
                  Type <Text weight="bold">{ userFullName }</Text> to confirm.
                </Trans>
              </Text>
            </Column>
            <Field
              name="fullName"
              type="text"
              placeholder={ userFullName }
              validate={ validateRole }
              component={ InputField }
            />
          </Dialog.Body>
          <Dialog.Footer>
            <Button
              color="neutral"
              variant="outlined"
              onClick={ onClose }
              disabled={ submitting }
            >
              <Trans i18nKey="shared.cancel">Cancel</Trans>
            </Button>
            <Button
              type="submit"
              onClick={ handleSubmit }
              disabled={ invalid || pristine }
              loading={ submitting }
            >
              <Choose>
                <When condition={ isLoggedInUser } >
                  <Trans i18nKey="shared.leave">Leave</Trans>
                </When>
                <Otherwise>
                  <Trans i18nKey="dialogs.removeUserFromOrganizationDialog.removeUser">Remove User</Trans>
                </Otherwise>
              </Choose>
            </Button>
          </Dialog.Footer>
        </> }
      </Form>
    </Dialog>
  );
};

TeamMemberRemoveFromOrganizationDialog.ID = TEAM_MEMBER_REMOVE_FROM_ORGANIZATION_DIALOG_ID;

