// @flow
// $FlowFixMe
import React from 'react';
import { css } from '@emotion/core';
import { Field } from '@8base-react/forms';
import { InputField, Row, Column } from '@8base/boost';
import { i18n } from 'i18n';
import { useTranslation } from 'react-i18next';

import { CopyInput } from 'common/components/CopyInput';
import { getEndpoint } from 'utils';
import { FS, FULLSTORY_EVENT_TYPES } from 'utils/fullStory';

type AdditionalInformationProps = {
  workspaceId: string,
  apiHost: any,
  webSocketUri: string,
  [string]: any,
};

export const AdditionalInformation = ({
  workspaceId,
  apiHost,
  webSocketUri,
  e2eWorkspaceIdInputProps,
  e2eWorkspaceIdButtonProps,
  e2eCopyEndpointInputProps,
  e2eCopyEndpointBtnProps,
}: AdditionalInformationProps) => {
  const { t } = useTranslation();

  return (
    <Row gap="lg" >
      <Column stretch gap="xl" >
        <Column stretch gap="lg" >
          <CopyInput
            e2eInputProps={ e2eWorkspaceIdInputProps }
            e2eButtonProps={ e2eWorkspaceIdButtonProps }
            value={ workspaceId || '' }
            label="Workspace ID"
            toastMessage={ i18n.t('dataBuilder.workspaceIdSuccess') }
            tooltipMessage={ t('dataBuilder.copyWorkspaceId') }
          />

          <CopyInput
            e2eInputProps={ e2eCopyEndpointInputProps }
            e2eButtonProps={ e2eCopyEndpointBtnProps }
            label="Endpoint URL (copy the endpoint URL and paste it into your app client code)"
            value={ getEndpoint(workspaceId, { ignoreEnvironment: true, baseUrl: apiHost }) }
            toastMessage={ i18n.t('shared.apiEndpointSuccess') }
            tooltipMessage={ t('shared.copyEndpoint') }
            onSuccess={ (value) => {
              FS.event(FULLSTORY_EVENT_TYPES.copyEndpoint, {
                endpoint_url: value,
              });
            } }
          />

          <CopyInput
            e2eInputProps={ e2eCopyEndpointInputProps }
            e2eButtonProps={ e2eCopyEndpointBtnProps }
            label="Websocket Endpoint"
            value={ webSocketUri }
            toastMessage={ i18n.t('shared.webSocketSuccess', { defaultValue: 'Websocket Endpoint successfully copied to the clipboard' }) }
            tooltipMessage={ t('shared.copyWebSocket', { defaultValue: 'Copy Websocket Endpoint' }) }
            onSuccess={ (value) => {
              FS.event(FULLSTORY_EVENT_TYPES.copyEndpoint, {
                endpoint_url: value,
              });
            } }
          />

          <Field
            css={ css`width: calc(100% - 52px);` }
            name="region"
            component={ InputField }
            label="AWS regional endpoint location"
            disabled
          />

        </Column>
      </Column>
    </Row>
  );
};
