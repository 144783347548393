// @flow
// $FlowFixMe waiting for update Flow
import React, { useContext, useState, useCallback } from 'react';

type ReferralProviderProps = {
  children: React$Node,
};

type ReferralContextProps = {
  appbuilderReferral?: string;
  saveAppbuilderReferral: (value: string) => void;
};

export const ReferralContext = React.createContext<ReferralContextProps>({
  appbuilderReferral: undefined,
  saveAppbuilderReferral: () => {},
});

export const ReferralProvider = ({ children }: ReferralProviderProps) => {
  const [appbuilderReferral, setAppbuilderReferral] = useState<string | typeof undefined>(localStorage.getItem('appbuilderReferral'));

  const saveAppbuilderReferral = useCallback((code: string) => {
    localStorage.setItem('appbuilderReferral', code);
    setAppbuilderReferral(code);
  }, []);

  return (
    <ReferralContext.Provider
      value={{
        appbuilderReferral,
        saveAppbuilderReferral,
      }}
    >
      { children }
    </ReferralContext.Provider>
  );
};

export const useReferral = () => useContext(ReferralContext);
