// @flow

import * as R from 'ramda';
import { createSelector } from 'reselect';

export const getTeamMember = (teamMember: Object) => teamMember;

export const getId = createSelector(getTeamMember, R.prop('id'));
export const getCreatedAt = createSelector(getTeamMember, R.prop('createdAt'));
export const getUpdatedAt = createSelector(getTeamMember, R.prop('updatedAt'));
export const getFirstName = createSelector(getTeamMember, R.prop('firstName'));
export const getLastName = createSelector(getTeamMember, R.prop('lastName'));
export const getEmail = createSelector(getTeamMember, R.prop('email'));
export const getAvatar = createSelector(getTeamMember, R.prop('avatar'));
export const getLearningMode = createSelector(getTeamMember, R.prop('learningMode'));

export const getFullName = createSelector(
  getFirstName,
  getLastName,
  (firstName = '', lastName = '') => `${firstName} ${lastName}`,
);

export const getSelectOption = createSelector(
  getId,
  getEmail,
  getFullName,
  (id, email, fullName) => ({ value: email, label: `${fullName} (${email})` }),
);
