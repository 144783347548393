// @flow

import React from 'react';
import { compose } from 'recompose';
import styled from '@emotion/styled';
import { withDropTarget, withDragLayer } from '../draggableLogic';


type DropPlateProps = {
  onChangeColumnWidth: (columnName: string, columnWidth: number) => void,
}

const DropdPlateStyled = styled('div')(props => ({
  display: 'flex',
  flexGrow: 1,
  maxWidth: '100%',

  '&, & *': {
    cursor: props.isDragging ? 'col-resize' : undefined,
  },
}));

const enhancer = compose (
  withDragLayer,
  withDropTarget,
);

export const DropPlate: React$ComponentType<DropPlateProps> = enhancer(
  ({ children, connectDropTarget, isDragging }) => connectDropTarget(
    <div style={{ display: 'flex', flexGrow: 1, maxWidth: '100%' }}>
      <DropdPlateStyled isDragging={ isDragging }>
        { children }
      </DropdPlateStyled>
    </div>,
  ),
);

DropPlate.displayName = 'DropPlate';
