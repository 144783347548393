// @flow

import * as R from 'ramda';
import { createSelector } from 'reselect';
import { DateTime } from 'luxon';
import type { Selector } from 'reselect';

import { WORKSPACE_KIND } from 'common/constants/workspaceKind';

import type { SystemWorkspaceListResponse, SystemWorkspaceItem, WorkspacesListQuery } from '../__generated__';
import { PLANS } from '../../graphql/constants/billing';

export const getWorkspacesListResponse = (workspacesListResponse: SystemWorkspaceListResponse = { items: [], count: 0 }) => workspacesListResponse;

export const getWorkspacesList: Selector<
  SystemWorkspaceListResponse,
  any,
  SystemWorkspaceItem[],
> = createSelector(
  getWorkspacesListResponse,
  (_, options) => ({ withAlphabetSorting: (options && options.withAlphabetSorting) || false }),
  (workspacesListResponse, { withAlphabetSorting }) => R.pipe(
    R.propOr([], 'items'),
    R.when(
      () => withAlphabetSorting,
      R.sort(({ name: a }, { name: b }) => a && a.localeCompare(b)),
    ),
  )(workspacesListResponse),
);

export const hasWorkspaces: Selector<
  SystemWorkspaceListResponse,
  any,
  boolean,
  > = createSelector(
    getWorkspacesList,
    workspacesList => workspacesList.length > 0,
  );

export const getMyWorkspacesList: Selector<
  SystemWorkspaceListResponse,
  { withAlphabetSorting: boolean } | void,
  SystemWorkspaceItem[],
> = createSelector(
  getWorkspacesList,
  R.filter(R.propEq('isOwner', true)),
);


export const getMyWorkspacesOnFreePlan: Selector<
  SystemWorkspaceListResponse,
  any,
  SystemWorkspaceItem[],
  > = createSelector(
    getMyWorkspacesList,
    (workspacesList: SystemWorkspaceItem[]) => workspacesList.filter(workspace => workspace.plan && workspace.plan.name === PLANS.freeTier),
  );

export const getSharedWorkspacesList: Selector<
  SystemWorkspaceListResponse,
  { withAlphabetSorting: boolean } | void,
  SystemWorkspaceItem[],
> = createSelector(
  getWorkspacesList,
  R.filter(R.propEq('isOwner', false)),
);

export const getWorkspacesCount: Selector<
  SystemWorkspaceListResponse,
  any,
  number,
> = createSelector(
  getWorkspacesListResponse,
  R.prop('count'),
);

export const getWorkspace: Selector<
  SystemWorkspaceListResponse,
  any,
  SystemWorkspaceItem,
> = createSelector(
  getWorkspacesList,
  (_, id: string) => id,
  (workspacesList, id) => R.find(R.propEq('id', id), workspacesList),
);

export const hasOnlyOneWorkspace: Selector<
  SystemWorkspaceListResponse,
  any,
  boolean,
> = createSelector(
  getWorkspacesList,
  R.propEq('length', 1),
);

export const hasWorkspaceWithId: Selector<
  SystemWorkspaceListResponse,
  any,
  boolean,
> = createSelector(
  getWorkspacesList,
  (_, workspaceId) => workspaceId,
  (workspacesList, workspaceId) => R.any(R.propEq('id', workspaceId), workspacesList),
);

export const doesntHaveWorkspaces: Selector<
  SystemWorkspaceListResponse,
  any,
  boolean,
> = createSelector(
  getWorkspacesList,
  R.propEq('length', 0),
);

export const getWorkspacesListSortedByLastAccess: Selector<
  SystemWorkspaceListResponse,
  any,
  SystemWorkspaceItem[],
  > = createSelector(
    getWorkspacesList,
    workspacesList => workspacesList.sort(
      (workspaceA, workspaceB) => {
        const zeroDate = '1980-01-01T01:01:01.000Z';

        return DateTime.fromISO(workspaceB.lastAccess || zeroDate).diff(DateTime.fromISO(workspaceA.lastAccess || zeroDate)).milliseconds || 0;
      },
    ),
  );

export const getWorkspaceName: Selector<
  SystemWorkspaceListResponse,
  any,
  string,
> = createSelector(
  getWorkspace,
  R.propOr(null, 'name'),
);

export const isMyWorkspace: Selector<
  SystemWorkspaceListResponse,
  any,
  boolean,
> = createSelector(
  getWorkspace,
  R.ifElse(
    R.isNil,
    R.always(R.identity(false)),
    R.propEq('isOwner', true),
  ),
);

/**
 * return merged object of `data.system.workspacesList` and `data.system.workspacesFrontendList` if kind is `undefined`
 */
export const getWorkspacesByKind: Selector<
  WorkspacesListQuery | null | typeof undefined,
  any,
  SystemWorkspaceListResponse,
> = createSelector(
  [
    (data) => {
      const workspacesList = R.pathOr({ items: [], count: 0 }, ['system', 'workspacesList'], data);
      return workspacesList;
    },
    (data) => {
      const workspacesFrontendList = R.pathOr({ items: [], count: 0 }, ['system', 'workspacesFrontendList'], data);
      return workspacesFrontendList;
    },
    (_, kind) => kind,
  ],
  (workspacesList, workspacesFrontendList, kind) => {
    if (kind === WORKSPACE_KIND.general) {
      return workspacesList;
    }

    if (kind === WORKSPACE_KIND.frontend) {
      return workspacesFrontendList;
    }

    return {
      items: [...workspacesList.items, ...workspacesFrontendList.items],
      count: workspacesList.count + workspacesFrontendList.count,
    };
  },
);
