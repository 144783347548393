// @flow
import React from 'react';
import styled from '@emotion/styled';
import { Paper } from '@8base/boost';

type UserFlowPaperProps = {
  width?: string;
}

const UserFlowPaperTag = styled(Paper)`
  box-shadow: none;
  position: relative;
  min-width: ${props => props.width};
  padding: 48px;
  background: #23242D;

  @media (max-width: 767px) {
    min-width: initial;
    max-width: 100%;
    padding: 48px 24px;
  }
`;

export const UserFlowPaper = ({ width = '500px', ...rest } :UserFlowPaperProps) => (
  <UserFlowPaperTag width={ width } { ...rest } />
);
