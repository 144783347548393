// @flow
import React from 'react';
import { Column, Text, Link } from '@8base/boost';
import { css } from '@emotion/core';
import { Link as RouterLink } from 'common/router';

import { Trans } from 'utils/translate';
import { ORGANIZATION_ROLE } from 'common/constants/organization';
import { APP_URL, useBuildUrl } from 'common';

type DeveloperHomeOrganizationWorkspacesEmptyProps = {
  createButton?: React$Node;
  permissionRole: string;
}

export const DeveloperHomeOrganizationWorkspacesEmpty = ({
  createButton,
  permissionRole,
}: DeveloperHomeOrganizationWorkspacesEmptyProps) => {
  const buildUrl = useBuildUrl();

  return (
    <div css={ css`overflow: auto; width: 100%; padding: 0 32px; ` }>
      <Column alignItems="center" justifyContent="center" gap="xl" >
        <Column gap="none" alignItems="center" css={ css` max-width: 835px; ` }>
          { (permissionRole === ORGANIZATION_ROLE.admin || permissionRole === ORGANIZATION_ROLE.owner) && (
            <>
              <Text kind="subtitle" color="GRAY_50" align="center" >
                <Trans i18nKey="developerHome.organizationPage.noWorkspaceFound.emptyAdmin">
                  There are no any workspaces. Continue below to create a new workspace.
                </Trans>
              </Text>
              <Text kind="subtitle" color="GRAY_50" align="center" >
                <Trans i18nKey="developerHome.organizationPage.noWorkspaceFound.admin">
                  You may also transfer an existing workspace that you own from
                  <Link
                    tagName={ RouterLink }
                    to={ buildUrl(APP_URL.developerHomeWorkspaces) }
                  >
                    My Workspaces
                  </Link> and add it to your organization.
                </Trans>
              </Text>
            </>
          ) }
          { (permissionRole !== ORGANIZATION_ROLE.admin && permissionRole !== ORGANIZATION_ROLE.owner) && (
            <>
              <Text kind="subtitle" color="GRAY_50" align="center" >
                <Trans i18nKey="developerHome.organizationPage.noWorkspaceFound.emptyManager">
                There are no any workspaces.
                </Trans>
              </Text>
              <Text kind="subtitle" color="GRAY_50" align="center" >
                <Trans i18nKey="developerHome.organizationPage.noWorkspaceFound.manager">
                  You can request a transfer of an existing workspace that you own from
                  <Link
                    tagName={ RouterLink }
                    to={ buildUrl(APP_URL.developerHomeWorkspaces) }
                  >
                    My Workspaces
                  </Link>
                </Trans>
              </Text>
            </>
          ) }
        </Column>
        { createButton }
      </Column>
    </div>
  );
};
