// @flow

import React from 'react';
import { Form, Field } from '@8base-react/forms';
import { Dialog, Button, InputField, SelectField, Column, useModal } from '@8base/boost';
import { Mutation, Query } from 'react-apollo';
import * as R from 'ramda';

import { apolloSelectors } from 'graphql/selectors';
import { i18n } from 'i18n';
import { TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';
import { API_TOKENS_LIST_QUERY, ROLES_LIST_QUERY } from 'graphql/queries';
import { API_TOKEN_UPDATE_MUTATION } from 'graphql/mutations';

export const API_TOKEN_EDIT_DIALOG_ID = 'API_TOKEN_UPDATE_DIALOG';

const replaceUpdatedAuthToken = (cache, { data: { apiTokenUpdate }}) => {
  const apiTokensListData = cache.readQuery({ query: API_TOKENS_LIST_QUERY });
  const apiTokensListResponse = apolloSelectors.apiTokensList.getApiTokensListResponse(apiTokensListData);
  const apiTokensList = apolloSelectors.apiTokensList.getApiTokensList(apiTokensListData);

  const index = R.findIndex(R.propEq('id', apiTokenUpdate.id), apiTokensList);

  const nextApiTokensList = R.update(
    index,
    apiTokenUpdate,
    apiTokensList,
  );

  //TODO: REPLACE `data: {}` with dataComposers (aka selectors vice verse)
  cache.writeQuery({
    query: API_TOKENS_LIST_QUERY,
    data: {
      apiTokensList: {
        ...apiTokensListResponse,
        items: nextApiTokensList,
      },
    },
  });
};

const APITokenEditDialog = () => {
  const { closeModal } = useModal();

  return (
    <Dialog
      id={ API_TOKEN_EDIT_DIALOG_ID }
      size="sm"
      onClose={ () => closeModal(API_TOKEN_EDIT_DIALOG_ID) }
      { ...E2E.$props('settings.apiTokens.editDialog.body') }
    >
      { ({ args }) => (
        <Mutation
          mutation={ API_TOKEN_UPDATE_MUTATION }
          refetchQueries={ ['ApiTokensList'] }
          context={{
            [TOAST_SUCCESS_MESSAGE]: i18n.t('settings.apiTokens.updateSuccessfull'),
          }}
          update={ replaceUpdatedAuthToken }
        >
          { (apiTokenUpdate) => (
            <Query query={ ROLES_LIST_QUERY } fetchPolicy="network-only">
              { ({ data, loading }) => (
                <Form
                  type="UPDATE"
                  tableSchemaName="ApiTokens"
                  initialValues={ args.initialValues }
                  formatRelationToIds
                  onSubmit={ async (data) => {
                    await apiTokenUpdate({ variables: { data: { ...data, id: args.initialValues.id }}});

                    closeModal(API_TOKEN_EDIT_DIALOG_ID);

                    window.trackEvent('API Tokens', 'Update token');
                  } }
                >
                  { ({ handleSubmit, invalid, submitting }) => (
                    <form onSubmit={ handleSubmit }>
                      <Dialog.Header title={ i18n.t('settings.apiTokens.editDialog.title') } onClose={ () => closeModal(API_TOKEN_EDIT_DIALOG_ID) } />
                      <Dialog.Body>
                        <Column gap="md">
                          <Field
                            name="name"
                            component={ InputField }
                            label="Name"
                            { ...E2E.$props('settings.apiTokens.editDialog.nameInput') }
                          />
                          <Field
                            name="roles"
                            component={ SelectField }
                            label="Roles"
                            options={ apolloSelectors.rolesList.getRolesListSelectOptions(data) }
                            loading={ loading }
                            multiple
                            { ...E2E.$props('settings.apiTokens.editDialog.rolesSelect') }
                          />
                        </Column>
                      </Dialog.Body>
                      <Dialog.Footer>
                        <Button
                          type="button"
                          color="neutral"
                          variant="outlined"
                          disabled={ submitting }
                          onClick={ () => closeModal(API_TOKEN_EDIT_DIALOG_ID) }
                          { ...E2E.$props('settings.apiTokens.editDialog.cancelButton') }
                        >
                          { i18n.t('shared.cancel') }
                        </Button>
                        <Button
                          type="submit"
                          disabled={ invalid || loading }
                          loading={ submitting }
                          { ...E2E.$props('settings.apiTokens.editDialog.submitButton') }
                        >
                          { i18n.t('shared.update') }
                        </Button>
                      </Dialog.Footer>
                    </form>
                  ) }
                </Form>
              ) }
            </Query>
          ) }
        </Mutation>
      ) }
    </Dialog>
  );
};

APITokenEditDialog.ID = API_TOKEN_EDIT_DIALOG_ID;

export { APITokenEditDialog };
