import React, { useCallback } from 'react';
import { css } from '@emotion/core';
import { Dialog, Button, Column, Text, Paragraph, InputField, useModal } from '@8base/boost';
import { Form, Field } from '@8base-react/forms';
import { useMutation } from 'react-apollo';
import { TABLES_SCHEMA_QUERY } from '@8base-react/table-schema-provider';
import { FIELD_TYPE } from '@8base/utils';
import * as R from 'ramda';

import { i18n } from 'i18n';
import { Trans } from 'utils/translate';
import { TABLE_FIELD_DELETE_MUTATION } from 'graphql/mutations';
import { TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';

const TABLE_FIELD_DELETE_DIALOG_ID = 'TABLE_FIELD_DELETE_DIALOG_ID';

const deleteFieldById = (tableSchema, tableId, fieldId) => R.over(
  R.lensPath(['tablesList', 'items', R.findIndex(R.propEq('id', tableId), tableSchema.tablesList.items), 'fields']),
  R.reject(
    R.propEq('id', fieldId),
  ),
)(tableSchema);

const getFieldById = (tableSchema, tableId, fieldId) => R.pipe(
  R.path(['tablesList', 'items', R.findIndex(R.propEq('id', tableId), tableSchema.tablesList.items), 'fields']),
  R.find(
    R.propEq('id', fieldId),
  ),
)(tableSchema);

const deleteFieldFromState = (cache, fieldId, tableId) => {
  let data = cache.readQuery({ query: TABLES_SCHEMA_QUERY });

  const field = getFieldById(data, tableId, fieldId);

  data = deleteFieldById(data, tableId, fieldId);

  if (field.fieldType === FIELD_TYPE.RELATION) {
    data = deleteFieldById(data, field.relation.refTable.id, field.relation.refField.id);
  }

  cache.writeQuery({ query: TABLES_SCHEMA_QUERY, data });
};

const TableFieldDeleteDialog = () => {
  const { closeModal, args: { id, name, tableId }} = useModal(TABLE_FIELD_DELETE_DIALOG_ID);

  const [tableFieldDelete] = useMutation(TABLE_FIELD_DELETE_MUTATION, {
    update: (cache) => {
      deleteFieldFromState(cache, id, tableId);
    },
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('dataBuilder.fieldDeleteSuccessful'),
    },
  });

  const onClose = useCallback(() => {
    closeModal(TABLE_FIELD_DELETE_DIALOG_ID);
  }, [closeModal]);

  const onSubmit = useCallback(async () => {
    await tableFieldDelete({ variables: { data: { id }}});

    window.trackEvent('Data Builder', 'Delete field');

    onClose();
  }, [tableFieldDelete, onClose, id]);

  const validate = useCallback((value) => value === name ? null : i18n.t('validation.valueNoMatch'), [name]);

  return (
    <Dialog id={ TABLE_FIELD_DELETE_DIALOG_ID } size="md" onClose={ onClose } { ...E2E.$props('dialogs.tableFieldDelete.body') }>
      <Form onSubmit={ onSubmit }>
        { ({ handleSubmit, invalid, pristine, submitting }) => (
          <form onSubmit={ handleSubmit }>
            <Dialog.Header title={ i18n.t('dialogs.tableFieldDelete.title') } onClose={ onClose } />
            <Dialog.Body>
              <Column gap="lg">
                <Paragraph css={ css`width: 100%;` } align="center">
                  <Trans i18nKey="dialogs.tableFieldDelete.note">
                      You are deleting the field <Text weight="bold">{ name }</Text>.<br />
                      Continuing this action will result in data loss.
                  </Trans>
                </Paragraph>
                <Field
                  name="name"
                  type="text"
                  placeholder="Type the field's name to delete it"
                  validate={ validate }
                  component={ InputField }
                  { ...E2E.$props('dialogs.tableFieldDelete.nameInput') }
                />
              </Column>
            </Dialog.Body>
            <Dialog.Footer>
              <Button type="button" color="neutral" variant="outlined" disabled={ submitting } onClick={ onClose }>Cancel</Button>
              <Button
                color="danger"
                type="submit"
                disabled={ invalid || pristine }
                loading={ submitting }
                { ...E2E.$props('dialogs.tableFieldDelete.submitBtn') }
              >Delete
              </Button>
            </Dialog.Footer>
          </form>
        ) }
      </Form>
    </Dialog>
  );
};

TableFieldDeleteDialog.ID = TABLE_FIELD_DELETE_DIALOG_ID;

export { TableFieldDeleteDialog };
