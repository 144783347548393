// @flow

import React from 'react';
import * as R from 'ramda';
import { css } from '@emotion/core';
import { Form as FormLogic, Field } from '@8base-react/forms';
import { Dialog, Button, InputField, Form, useModal } from '@8base/boost';
import { graphql } from 'react-apollo';

import { i18n } from 'i18n';
import { withTranslation } from 'react-i18next';
import { Trans } from 'utils/translate';
import { TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';
import { ROLE_CREATE_MUTATION } from 'graphql/mutations';

type RoleCreateDialogProps = {
  t: (string) => React$Node,
  roleCreate: (Object) => Promise<Object>,
};

const ROLE_CREATE_DIALOG_ID = 'ROLE_CREATE_DIALOG_ID';

let RoleCreateDialog = ({ t, roleCreate }: RoleCreateDialogProps) => {
  const { closeModal } = useModal();

  const onClose = () => closeModal(ROLE_CREATE_DIALOG_ID);

  const onSubmit = async (data) => {
    await roleCreate({ variables: { data }});

    window.trackEvent('Roles', 'Create role');

    onClose();
  };

  return (
    <Dialog
      id={ ROLE_CREATE_DIALOG_ID }
      onClose={ onClose }
      size="lg"
      { ...E2E.$props('settings.roles.createDialog.body') }
    >
      <FormLogic type="CREATE" tableSchemaName="Roles" onSubmit={ onSubmit }>
        { ({ handleSubmit, invalid, submitting, pristine }) => (
          <form onSubmit={ handleSubmit } css={ css`height: 100%;` } { ...E2E.$props('workspaceSelector.workspaceCreateForm') }>
            <Dialog.Header title={ t('settings.roles.newRole') } onClose={ onClose } />
            <Dialog.Body>
              <Form component="div">
                <Field
                  name="name"
                  label="Name"
                  placeholder="Name"
                  component={ InputField }
                  { ...E2E.$props('settings.roles.createDialog.nameInput') }
                  required
                />
                <Field
                  name="description"
                  label="Description"
                  placeholder="Description"
                  component={ InputField }
                  { ...E2E.$props('settings.roles.createDialog.descriptionInput') }
                />
              </Form>
            </Dialog.Body>
            <Dialog.Footer>
              <Button type="button" color="neutral" variant="outlined" onClick={ onClose }>
                <Trans i18nKey="shared.cancel">Cancel</Trans>
              </Button>
              <Button
                { ...E2E.$props('settings.roles.createDialog.submitButton') }
                type="submit"
                disabled={ pristine }
                loading={ submitting }
                onClick={ handleSubmit }
              >
                <Trans i18nKey="settings.roles.addRole">Add Role</Trans>
              </Button>
            </Dialog.Footer>
          </form>
        ) }
      </FormLogic>
    </Dialog>
  );
};

RoleCreateDialog = R.compose(
  withTranslation(),
  graphql(ROLE_CREATE_MUTATION, {
    name: 'roleCreate',
    options: () => ({
      context: {
        [TOAST_SUCCESS_MESSAGE]: i18n.t('settings.roles.newRoleSuccessfulyCreated'),
      },
      refetchQueries: () => [
        'RolesListWithUsers',
      ],
    }),
  }),
)(RoleCreateDialog);

RoleCreateDialog.ID = ROLE_CREATE_DIALOG_ID;

export { RoleCreateDialog };
