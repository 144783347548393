// @flow

export const FULLSTORY_EVENT_TYPES = {
  javascriptError: 'JavaScript Error',
  graphqlError: 'GraphQL Error',
  getStarted: 'Get Started',
  authorization: 'Authorization',
  createWorkspace: 'Create Workspace',
  changePricingPlan: 'Change Pricing Workspace',
  enterCreditCard: 'Enter Credit Card',
  connectSalesforce: 'Connect Salesforce',
  addTable: 'Add Table',
  createAuthProfile: 'Create Auth Profile',
  copyEndpoint: 'Copy EndPoint',
  consumeAPI: 'Consume API first time',
};

export const FS = {
  identify: (uuid: string, userVars?: Object) => {
    if (window.FS && typeof window.FS.identify === 'function') {
      window.FS.identify(uuid, userVars);
    }
  },
  setUserVars: (userVars: Object) => {
    if (window.FS && typeof window.FS.setUserVars === 'function') {
      window.FS.setUserVars(userVars);
    }
  },
  event: (eventName: string, eventProperties?: Object) => {
    if (window.FS && typeof window.FS.event === 'function') {
      window.FS.event(eventName, eventProperties);
    }
  },
};
