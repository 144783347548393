// @flow

import React from 'react';
import * as R from 'ramda';
import { css } from '@emotion/core';
import { Dropdown, Column, Heading, Row, Paper, Text, Icon } from '@8base/boost';
import { DateTime } from 'luxon';

import { APP_URL, useBuildUrl } from 'common/routing';
import type { WorkspaceItem } from 'graphql/__generated__';
import { Trans } from 'utils/translate';
import { getClientUrl } from 'utils/processEnv';

import * as WorkspaceDropdown from './WorkspaceDropdown';

type WorkspaceSelectorProps = {
  selectedWorkspace: string,
  selectedWorkspaceId: string,
  myWorkspacesList: Array<WorkspaceItem>,
  sharedWorkspacesList: Array<WorkspaceItem>,
  isOpenWorkspaceSelector: boolean,
  openWorkspaceCreateDialog: () => void,
  openWorkspaceSelector: () => void,
  closeWorkspaceSelector: () => void,
  changeWorkspace: (string) => Promise<void>,
};

const workspaceCounterClassName = css`
  border-radius: 20px;
  border: 1px solid #D0D7DD;
  font-size: 9px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

const getRemainingTrialDays = (workspace) => {
  const trialEnd = R.pathOr(null, ['plan', 'trialEnd'], workspace);

  if (R.isNil(trialEnd)) {
    return -1;
  }

  const { days } = DateTime.fromISO(trialEnd).diffNow('days').toObject();

  return Math.ceil(days);
};

const WorkspaceSelector = ({
  selectedWorkspace,
  selectedWorkspaceId,
  myWorkspacesList,
  sharedWorkspacesList,
  openWorkspaceCreateDialog,
  openWorkspaceSelector,
  closeWorkspaceSelector,
  isOpenWorkspaceSelector,
  // TODO: check if it possible to use in the task https://8base-dev.atlassian.net/browse/CW-4288
  changeWorkspace,
}: WorkspaceSelectorProps) => {
  const buildUrl = useBuildUrl();

  return (
    <WorkspaceDropdown.Wrapper { ...PT.$props('workspaceDropdown') }>
      <WorkspaceDropdown.Title
        { ...E2E.$props('workspaceSelector.closedTitle') }
        isOpen={ false }
        selectedWorkspace={ selectedWorkspace }
        onClick={ openWorkspaceSelector }
      />
      <WorkspaceDropdown.AbsolutePosition>
        <Dropdown
          stretch
          isOpen={ isOpenWorkspaceSelector }
          onCloseDropdown={ closeWorkspaceSelector }
        >
          <Dropdown.Head stretch>
            <WorkspaceDropdown.DummyHead />
          </Dropdown.Head>
          <Dropdown.Body stretch>
            <WorkspaceDropdown.Body { ...E2E.$props('workspaceSelector.dropdownBody') }>
              <Paper padding="none">
                <WorkspaceDropdown.Title
                  { ...E2E.$props('workspaceSelector.openedTitle') }
                  isOpen
                  selectedWorkspace={ selectedWorkspace }
                  onClick={ closeWorkspaceSelector }
                />
                <Column stretch gap="none">
                  <WorkspaceDropdown.ItemPaddings>
                    <Row justifyContent="between" alignItems="center">
                      <Text color="SECONDARY_TEXT_COLOR">
                        <Trans i18nKey="workspaces.myWorkspaces">MY WORKSPACES</Trans>
                      </Text>
                      <Text color="PRIMARY_TEXT_COLOR" css={ workspaceCounterClassName }>{ myWorkspacesList.length }</Text>
                    </Row>
                    <WorkspaceDropdown.Divider />
                  </WorkspaceDropdown.ItemPaddings>
                  <WorkspaceDropdown.WorkspacesList { ...E2E.$props('workspaceSelector.myWorkspacesList') }>
                    { myWorkspacesList.map(({ name, id, ...workspace }) => {
                      const remainingTrialDays = getRemainingTrialDays(workspace);
                      const trialEndDt = DateTime.fromISO(R.pathOr(null, ['plan', 'trialEnd'], workspace)).toFormat('MM/dd/yyyy');
                      const nextPlantDt = DateTime.local().plus({ months: 1 }).set({ day: 1 }).toFormat('MM/dd/yyyy');

                      const planDisplayName = R.path(['plan', 'displayName'], workspace);
                      const nextPlan = R.path(['nextPlan'], workspace);
                      const clientUrl = getClientUrl();

                      return (
                        <WorkspaceDropdown.WorkspaceItem
                          key={ id }
                          href={ `${clientUrl}${buildUrl(APP_URL.workspaceHome, { pathParams: { workspaceId: id }})}` }
                        >
                          <Row css={{ maxWidth: 'calc(100% - 26px)' }} justifyContent="between" alignItems="center" stretch>
                            <Column gap="none" css={{ width: '100%' }}>
                              <Text kind="subtitle" css={{ maxWidth: '100%' }} ellipsis>{ name }</Text>
                              <Choose>
                                <When condition={ remainingTrialDays > 0 }>
                                  <Text color={ remainingTrialDays > 15 ? 'SECONDARY_TEXT_COLOR' : 'RED' } css={ css`line-height: 13px; font-size: 9px; letter-spacing: 0.3px; text-transform: uppercase;` }>
                                    <Trans i18nKey="workspaces.trial" trialEndDt={ trialEndDt }>
                                        Free Trial Ends on {{ trialEndDt }}
                                    </Trans>
                                  </Text>
                                </When>
                                <Otherwise>
                                  <Text color="SECONDARY_TEXT_COLOR" css={ css`line-height: 13px; font-size: 9px; letter-spacing: 0.3px; text-transform: uppercase;` }>
                                    { planDisplayName }<If condition={ nextPlan }> → { R.prop('displayName', nextPlan) } from { nextPlantDt }</If>
                                  </Text>
                                </Otherwise>
                              </Choose>
                            </Column>
                            <If condition={ selectedWorkspaceId === id }>
                              <Icon name="Check" size="sm" color="BLUE_10" />
                            </If>
                          </Row>
                        </WorkspaceDropdown.WorkspaceItem>
                      );
                    }) }
                  </WorkspaceDropdown.WorkspacesList>
                  <If condition={ sharedWorkspacesList.length > 0 }>
                    <WorkspaceDropdown.ItemPaddings>
                      <Row justifyContent="between" alignItems="center">
                        <Text color="SECONDARY_TEXT_COLOR">
                          <Trans i18nKey="workspaces.sharedWorkspaces">SHARED WORKSPACES</Trans>
                        </Text>
                        <Text color="PRIMARY_TEXT_COLOR" css={ workspaceCounterClassName }>{ sharedWorkspacesList.length }</Text>
                      </Row>
                      <WorkspaceDropdown.Divider />
                    </WorkspaceDropdown.ItemPaddings>
                    <Column stretch gap="none">
                      <WorkspaceDropdown.WorkspacesList { ...E2E.$props('workspaceSelector.sharedWorkspacesList') }>
                        { sharedWorkspacesList
                          .sort(({ name: a }, { name: b }) => a.localeCompare(b))
                          .map(({ name, id, region }) => {
                            const clientUrl = getClientUrl();

                            return (
                              <WorkspaceDropdown.WorkspaceItem
                                key={ id }
                                href={ `${clientUrl}${buildUrl(APP_URL.workspaceHome, { pathParams: { workspaceId: id }})}` }
                              >
                                <Row justifyContent="between" alignItems="center" stretch>
                                  <Text kind="subtitle" css={{ maxWidth: '100%' }}ellipsis>{ name }</Text>
                                  <If condition={ selectedWorkspaceId === id }>
                                    <Icon name="Check" size="sm" color="BLUE_10" />
                                  </If>
                                </Row>
                              </WorkspaceDropdown.WorkspaceItem>
                            );
                          }) }
                      </WorkspaceDropdown.WorkspacesList>
                    </Column>
                  </If>
                  <WorkspaceDropdown.ItemPaddings>
                    <Heading type="h2" ellipsis>
                      <Row
                        onClick={ openWorkspaceCreateDialog }
                        gap="sm"
                        offsetY="md"
                        alignItems="center"
                        cursor="pointer"
                      >
                        <Icon name="CircleAdd" size="sm" color="PRIMARY" />
                        <Text color="PRIMARY" css={ css`font-size: 12px;` } { ...E2E.$props('workspaceSelector.newWorkspaceButton') }>
                          <Trans i18nKey="workspaces.newWorkspace">New Workspace</Trans>
                        </Text>
                      </Row>
                    </Heading>
                  </WorkspaceDropdown.ItemPaddings>
                </Column>
              </Paper>
            </WorkspaceDropdown.Body>
          </Dropdown.Body>
        </Dropdown>
      </WorkspaceDropdown.AbsolutePosition>
    </WorkspaceDropdown.Wrapper>
  );
};

export { WorkspaceSelector };
