//@flow

import React from 'react';
import { Heading, Text } from '@8base/boost';
import * as R from 'ramda';
import { DateTime } from 'luxon';

import { Trans } from 'utils/translate';
import { COLOR_BY_PLAN_NAME } from 'graphql/constants/billing';

type PaymentSubmitSuccessProps = {
  type?: 'downgrading' | 'upgrading' | 'simple';
  plan?: *;
};

export const PaymentSubmitSuccess = ({ type = 'simple', plan = {}}: PaymentSubmitSuccessProps) => {

  const planDisplayName = R.prop('displayName', plan);
  const nextPaymentDt = DateTime.local().plus({ months: 1 }).set({ day: 1 }).toFormat('MM/dd/yyyy');

  switch (type) {
    case 'downgrading':
      return (
        <Heading type="h3">
          <Choose>
            <When condition={ !planDisplayName } >
              <Trans
                i18nKey="billingUpdateDialog.downgradeSuccess.title"
                planDisplayName={ planDisplayName }
                nextPaymentDt={ nextPaymentDt }
              >
              All set! Your workspace will move<br />to the {{ planDisplayName }} on {{ nextPaymentDt }}
              </Trans>
            </When>
            <Otherwise>
              <Trans i18nKey="billingUpdateDialog.cardUpdateSuccess.title">
                Your request was successful
              </Trans>
            </Otherwise>
          </Choose>
        </Heading>
      );


    case 'upgrading':
      return (
        <React.Fragment>
          <Heading type="h3">
            <Trans i18nKey="billingUpdateDialog.paymentSuccess.title">
              Your payment was successful
            </Trans>
          </Heading>
          <Text color="LIGHT_TEXT_COLOR">
            <Trans i18nKey="billingUpdateDialog.paymentSuccess.note" planDisplayName={ planDisplayName }>
              You’ve successfully switched to the <Text color={ COLOR_BY_PLAN_NAME[plan.name] } weight="semibold" css={{ textTransform: 'uppercase' }}>{ planDisplayName }</Text> plan.<br />You can now close this dialog below.
            </Trans>
          </Text>
        </React.Fragment>
      );

    case 'simple':
    default:
      return (
        <Heading type="h3">
          <Trans i18nKey="billingUpdateDialog.cardUpdateSuccess.title">
            Your request was successful
          </Trans>
        </Heading>
      );
  }
};

