// @flow
import React from 'react';
import { compose } from 'recompose';

import { withWorkspace } from 'providers/WorkspaceProvider';

import { buildUrlWithWorkspace } from './utils';

const withBuildUrlBase =
  WrappedComponent => props => {
    const buildUrl = buildUrlWithWorkspace(props.workspace.id);

    return <WrappedComponent { ...props } buildUrl={ buildUrl } />;
  };


export const withBuildUrl = compose(
  withWorkspace,
  withBuildUrlBase,
);
