// @flow

import React from 'react';
import './Icon.scss';

type IconProps = {
  type: 'plus',
  color?: 'gray' | 'base' | 'green',
}

export const Icon = ({ type, color = 'base' }: IconProps) => (
  <span className={ `icon icon-${type}` } styleName={ `icon-color-${color}` } />
);
