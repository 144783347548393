import gql from 'graphql-tag';

export const WORKSPACE_ITEM_FRAGMENT = gql`
  fragment WorkspaceItem on SystemWorkspaceItem {
    id
    name
    description
    createdAt
    kind
    isOwner
    lastAccess
    teamMemberCount
    isCiCdEnabled
    region
    apiHost
    webSocket
    organization {
      id
      name
      image {
        downloadUrl
      }
    }
    owner {
      avatar {
        id
        downloadUrl
      }
      firstName
      lastName
    }
    plan {
      id
      name
      displayName
      trialEnd
      status
      price
    }
    nextPlan {
      id
      name
      displayName
      price
    }
    image {
      id
      downloadUrl
    }
  }
`;
