// @flow

import * as apolloApiTokenSelectors from './apiToken.selectors';
import * as apolloApiTokensListSelectors from './apiTokensList.selectors';
import * as apolloApplicationsListSelectors from './applicationsList.selectors';
import * as apolloAuthenticationProfileSelectors from './authenticationProfile.selectors';
import * as apolloAuthenticationProfilesListSelectors from './authenticationProfilesList.selectors';
import * as apolloFunctionsListSelectors from './functionsList.selectors';
import * as apolloEnvironmentVaribleSelectors from './environmentVariable.selectors';
import * as apolloEnvironmentVariblesListSelectors from './environmentVariablesList.selectors';
import * as apolloTeamInvitationDetailsSelectors from './teamInvitationDetails.selectors';
import * as apolloRoleSelectors from './role.selectors';
import * as apolloRolesListSelectors from './rolesList.selectors';
import * as apolloTableFieldSelectors from './tableField.selectors';
import * as apolloTableSelectors from './table.selectors';
import * as apolloTablesListSelectors from './tablesList.selectors';
import * as apolloUserSelectors from './user.selectors';
import * as apolloTeamMemberSelectors from './teamMember.selectors';
import * as apolloTeamMembersListSelectors from './teamMembersList.selectors';
import * as apolloUsersListSelectors from './usersList.selectors';
import * as apolloWorkspacesListSelectors from './workspacesList.selectors';
import * as apolloFileSelectors from './file.selectors';
import * as apolloFunctionInfoSelectors from './functionInfo.selectors';
import * as apolloApplicationSelectors from './application.selectors';
import * as apolloUserAccountInfoSelectors from './userAccountInfo.selectors';
import * as commonApolloData from './commonApoloData.selectors';
import * as apolloBillingDataSelectors from './billing.selectors';
import { specifySelectorPath } from './helpers/specifySelectorPath';
import {
  apolloPermissionSelectors,
  apolloDataPermissionSelectors,
  apolloDataPermissionOperationSelectors,
  apolloPermissionsListSelectors,
} from './role';
import * as apolloAuthenticationSettingsSelectors from './authenticationSettings.selectors';

import type { QueryResult, MutateResult } from './helpers/specifySelectorPath';
import type { ApolloTable } from './table.selectors';
import type { ApolloTableField } from './tableField.selectors';
import type { ApolloUser } from './user.selectors';
import type { ApolloApplication } from './application.selectors';

const apiToken = specifySelectorPath(apolloApiTokenSelectors, 'apiToken');
const apiTokensList = specifySelectorPath(apolloApiTokensListSelectors, 'apiTokensList');
const authenticationProfile = specifySelectorPath(
  apolloAuthenticationProfileSelectors,
  'authenticationProfile',
);
const authenticationProfilesList = specifySelectorPath(
  apolloAuthenticationProfilesListSelectors,
  'authenticationProfilesList',
);

const functionsList = specifySelectorPath(apolloFunctionsListSelectors, 'functionsList');
const applicationsList = specifySelectorPath(apolloApplicationsListSelectors, 'applicationsList');
const environmentVariable = specifySelectorPath(apolloEnvironmentVaribleSelectors, 'environmentVariable');
const environmentVariablesList = specifySelectorPath(apolloEnvironmentVariblesListSelectors, 'environmentVariablesList');
const file = specifySelectorPath(apolloFileSelectors, 'file');
const teamInvitationDetails = specifySelectorPath(apolloTeamInvitationDetailsSelectors, 'teamInvitationDetails');
const role = specifySelectorPath(apolloRoleSelectors, 'role');
const rolesList = specifySelectorPath(apolloRolesListSelectors, 'rolesList');
const table = specifySelectorPath(apolloTableSelectors, 'table');
const tableField = specifySelectorPath(apolloTableFieldSelectors, 'tableField');
const tablesList = specifySelectorPath(apolloTablesListSelectors, 'tablesList');
const user = specifySelectorPath(apolloUserSelectors, 'user');
const teamMember = specifySelectorPath(apolloTeamMemberSelectors, 'teamMember');
const teamMembersList = specifySelectorPath(apolloTeamMembersListSelectors, 'teamMembersList');
const usersList = specifySelectorPath(apolloUsersListSelectors, 'usersList');
const workspacesList = specifySelectorPath(apolloWorkspacesListSelectors, 'workspacesList');
const authenticationSettings = specifySelectorPath(
  apolloAuthenticationSettingsSelectors,
  'authenticationSettings',
);
const functionInfo = specifySelectorPath(
  apolloFunctionInfoSelectors,
  'functionInfo',
);
const application = specifySelectorPath(
  apolloApplicationSelectors,
  'application',
);
const billing = specifySelectorPath(apolloBillingDataSelectors, 'billing');

const apolloSelectors = {
  ...commonApolloData,
  apiToken,
  apiTokensList,
  applicationsList,
  authenticationProfile,
  authenticationProfilesList,
  functionsList,
  environmentVariable,
  environmentVariablesList,
  file,
  teamInvitationDetails,
  role,
  rolesList,
  table,
  tableField,
  tablesList,
  user,
  teamMember,
  teamMembersList,
  usersList,
  workspacesList,
  authenticationSettings,
  functionInfo,
  application,
  billing,
};

export {
  apolloSelectors,
  apolloApiTokenSelectors,
  apolloApiTokensListSelectors,
  apolloApplicationsListSelectors,
  apolloAuthenticationProfileSelectors,
  apolloAuthenticationProfilesListSelectors,
  apolloDataPermissionOperationSelectors,
  apolloDataPermissionSelectors,
  apolloFunctionsListSelectors,
  apolloEnvironmentVaribleSelectors,
  apolloEnvironmentVariblesListSelectors,
  apolloFileSelectors,
  apolloTeamInvitationDetailsSelectors,
  apolloPermissionSelectors,
  apolloPermissionsListSelectors,
  apolloRoleSelectors,
  apolloRolesListSelectors,
  apolloTableFieldSelectors,
  apolloTableSelectors,
  apolloTablesListSelectors,
  apolloUserSelectors,
  apolloTeamMemberSelectors,
  apolloTeamMembersListSelectors,
  apolloUsersListSelectors,
  apolloWorkspacesListSelectors,
  apolloAuthenticationSettingsSelectors,
  apolloFunctionInfoSelectors,
  apolloApplicationSelectors,
  apolloUserAccountInfoSelectors,
  apolloBillingDataSelectors,
};

export type {
  ApolloApplication,
  ApolloTable,
  ApolloTableField,
  ApolloUser,
  MutateResult,
  QueryResult,
};

