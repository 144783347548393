// @flow
import { ItemTypes } from 'config/dnd';
import { DragSource, DropTarget, DragLayer } from 'react-dnd';

import { MINIMAL_COLUMN_WIDTH } from './TableWorker.constants';


export const dragItemSource = {
  beginDrag(props: *) {
    return {
      originalWidth: props.width,
      columnName: props.columnName,
    };
  },

  endDrag(props: *) {
    props.onEndDrag && props.onEndDrag(props.columnName, props.width);
  },
};

/** logic of the column resize */
export const dropItemTarget: any = {
  hover(props: *, monitor: *) {
    const { originalWidth, columnName } = monitor.getItem();
    const { x: offsetX } = monitor.getDifferenceFromInitialOffset();
    const newWidth = originalWidth + offsetX;

    if (this.previouslyOffsetX !== offsetX) {
      if (newWidth > MINIMAL_COLUMN_WIDTH) {
        props.onChangeColumnWidth(columnName, newWidth);
      }
    }

    this.previouslyOffsetX = offsetX;
  },
};

const collectSource = (connect: *, monitor: *) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
});

const collectTarget = (connect: *) => ({
  connectDropTarget: connect.dropTarget(),
});

const collectDragLayer = (monitor: *) => ({
  isDragging: monitor.getItemType() === ItemTypes.TABLE_WORKER_COLUMN_SIZE && monitor.isDragging(),
});

export const withDragSource: any = DragSource(ItemTypes.TABLE_WORKER_COLUMN_SIZE, dragItemSource, collectSource);
export const withDropTarget: any = DropTarget(ItemTypes.TABLE_WORKER_COLUMN_SIZE, dropItemTarget, collectTarget);
export const withDragLayer: any = DragLayer(collectDragLayer);
