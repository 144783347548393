import gql from 'graphql-tag';

import { UserFragment } from 'graphql/fragments';
import { UserAccountInfoFragment } from '../fragments';

export const USERS_LIST_QUERY = gql`
  query UsersList($first: Int, $skip: Int, $sort: [UserSort!], $filter: UserFilter) {
    usersList(first: $first, skip: $skip, sort: $sort, filter: $filter) {
      items {
        ...UserFragment
      }
      count
    }
  }
  ${UserFragment}
`;

export const USER_EDIT_QUERY = gql`
  query UsersEditForm($id: ID!) {
    user(id: $id) {
      ...UserFragment
      roles {
        items {
          id
        }
      }
    }
  }
  ${UserFragment}
`;

export const USERS_STATISTICS_QUERY = gql`
  query UsersStatistics($newUsersDt: DateTime!, $newUsersWeekDt: DateTime!) {
    totalAppUsers: usersList {
      count
    }
    appUsersActive: usersList(filter: { status: { equals: "active" }}) {
      count
    }
    appUsersInactive: usersList(filter: { status: { equals: "inactive" }}) {
      count
    }
    newAppUsers: usersList(filter: { createdAt: { gt: $newUsersDt }}) {
      count
    }
    newWeekAppUsers: usersList(filter: { createdAt: { gt: $newUsersWeekDt }}) {
      count
    }
  }
`;

export const USER_ACCOUNT_INFO_QUERY = gql`
  query UserAccountInfo {
    system {
      memberAccount {
        ...UserAccountInfoFragment
      }
    }
  }

  ${UserAccountInfoFragment}
`;
