// @flow
// $FlowFixMe
import { useContext } from 'react';
import { BillingContext } from './BillingProvider';
import type { BillingCurrentPlanResponse } from '../../graphql/__generated__';

type BillingContextProps = {
  workspaceName: string;
  isFreePlanAvailable: boolean;
  plan: BillingCurrentPlanResponse | null;
  plans:Array<{
    description: string;
    displayName: string;
    id: string;
    name: string;
    price: number;
  }>;
  refetchPlansData: Function;
  loading: boolean;
  planDataLoading: Boolean;
  setRegionEndpoint: (regionEndpoint: string) => void;
  regionEndpoint: string;
  remainTrialDays: number;
};

/**
 * Return information about billing plans
 */
export const useBillingInfo = (): BillingContextProps => {
  return useContext(BillingContext);
};
