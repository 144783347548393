import React from 'react';
import { Form } from '@8base-react/forms';
import { compose } from 'recompose';
import { Dialog, Button, ModalContext, Paragraph } from '@8base/boost';

import { withTranslation, Trans } from 'react-i18next';

const FORCIBLE_ERROR_DIALOG = 'FORCIBLE_ERROR_DIALOG';

class ForcibleErrorDialog extends React.Component {
  static contextType = ModalContext;

  onSubmit = async (data) => {
    try {
      await this.args.onSubmit();

      if (typeof this.args.onSuccess === 'function') {
        this.args.onSuccess();
      }
    } catch (e) {}

    this.context.closeModal(FORCIBLE_ERROR_DIALOG);
  };

  onClose = () => {
    this.args.onCancel && this.args.onCancel();
    this.context.closeModal(FORCIBLE_ERROR_DIALOG);
  };

  renderFormContent = ({ handleSubmit, invalid, pristine, submitting }) => {
    const { t } = this.props;

    return (
      <form onSubmit={ handleSubmit }>
        <Dialog.Header title={ this.args.title || t('forcibleErrorDialog.title') } onClose={ this.onClose } />
        <Dialog.Body scrollable>
          <Paragraph>
            { this.args.message ||
            <Trans i18nKey="forcibleErrorDialog.note">
              Object(s) you are trying to delete are referenced by a required relationship.
              Please confirm you'd like to delete child objects together with the parent.
            </Trans> }
          </Paragraph>
        </Dialog.Body>
        <Dialog.Footer>
          <Button
            color="neutral"
            variant="outlined"
            onClick={ this.onClose }
            { ...E2E.$props('forcibleErrorDialog.cancelBtn') }
          >
            { t('shared.cancel') }
          </Button>
          <Button
            color="danger"
            type="submit"
            loading={ submitting }
            { ...E2E.$props('forcibleErrorDialog.submitBtn') }
          >
            { this.args.submitButtonMessage || t('shared.delete') }
          </Button>
        </Dialog.Footer>
      </form>
    );
  }

  renderForm = ({ args }) => {
    this.args = args;

    return (
      <Form onSubmit={ this.onSubmit } >
        { this.renderFormContent }
      </Form>
    );
  };

  render() {
    return (
      <Dialog id={ FORCIBLE_ERROR_DIALOG } size="sm">
        { this.renderForm }
      </Dialog>
    );
  }
}

ForcibleErrorDialog = compose(
  withTranslation(),
)(ForcibleErrorDialog);

ForcibleErrorDialog.ID = FORCIBLE_ERROR_DIALOG;

export { ForcibleErrorDialog };
