//@flow

import React from 'react';
import { Dialog, Button, Text, Heading, Column, useModal } from '@8base/boost';
import { css } from '@emotion/core';

import { i18n } from 'i18n';
import { UserPaymentDetailsUpdateDialog } from './BillingUpdateDialog/UserPaymentDetailsUpdateDialog';
import { OrganizationPaymentDetailsUpdateDialog } from './BillingUpdateDialog/OrganizationPaymentDetailsUpdateDialog';
import upgradeImage from 'images/upgrade.svg';

type RequireCCFeatureDialogProps = {};

const REQUIRE_CC_FEATURE_DIALOG_ID = 'REQUIRE_CC_FEATURE_DIALOG_ID';

const RequireCCFeatureDialog = (props: RequireCCFeatureDialogProps) => {
  const { closeModal, openModal, args = {}} = useModal(REQUIRE_CC_FEATURE_DIALOG_ID);

  const onClose = () => closeModal(REQUIRE_CC_FEATURE_DIALOG_ID);

  const openUpdatePaymentDetailsDialog = () => {
    if (args.organizationId) {
      openModal(OrganizationPaymentDetailsUpdateDialog.ID, { organizationId: args.organizationId });
    } else {
      openModal(UserPaymentDetailsUpdateDialog.ID);
    }
    onClose();
  };

  const dialogTitle = args.dialogTitle || i18n.t('dialogs.updatePaymentDetails.dialogTitle');
  const title = args.title || i18n.t('dialogs.updatePaymentDetails.title');
  const note = args.note || i18n.t('dialogs.updatePaymentDetails.note');
  const cta = args.cta || i18n.t('dialogs.updatePaymentDetails.cta');

  return (
    <Dialog id={ REQUIRE_CC_FEATURE_DIALOG_ID } onClose={ onClose } { ...E2E.$props('requireCCFeatureDialog.body') }>
      <Dialog.Header title={ dialogTitle } onClose={ onClose } />
      <Dialog.Body>
        <Column alignItems="center" css={ css`text-align: center;` }>
          <img src={ upgradeImage } alt="" />
          <Heading type="h3">
            { title }
          </Heading>
          <Text color="LIGHT_TEXT_COLOR">
            { note }
          </Text>
        </Column>
      </Dialog.Body>
      <Dialog.Footer>
        <Button onClick={ openUpdatePaymentDetailsDialog } stretch { ...E2E.$props('requireCCFeatureDialog.submitButton') }>
          { cta }
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

RequireCCFeatureDialog.ID = REQUIRE_CC_FEATURE_DIALOG_ID;

export { RequireCCFeatureDialog };
