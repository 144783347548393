// @flow

import React from 'react';
import { Dialog, Button, useModal } from '@8base/boost';
import { useMutation } from 'react-apollo';
import * as R from 'ramda';
import { i18n } from 'i18n';
import { TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';
import { TEAM_MEMBER_UPDATE_MUTATION } from 'graphql/mutations';
import { Trans } from 'utils/translate';

const TEAM_MEMBER_REMOVE_FROM_ROLE_DIALOG_ID = 'TEAM_MEMBER_REMOVE_FROM_ROLE_DIALOG_ID';

const TeamMemberRemoveFromRoleDialog = () => {
  const { closeModal } = useModal();

  const onClose = () => closeModal(TEAM_MEMBER_REMOVE_FROM_ROLE_DIALOG_ID);

  const [teamMemberUpdate, { loading: submitting }] = useMutation(TEAM_MEMBER_UPDATE_MUTATION, {
    refetchQueries: ['TeamMembersList'],
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('dialogs.teamMemberRemoveFromRole.successMessage'),
    },
  });

  const createOnRemove = ({ email, roleId, roles }) => async () => {
    window.trackEvent('Roles', 'Remove team member from role');
    const newRoles = R.pathOr([], ['items'], roles).map(el => el.id).filter(el => el !== roleId);

    await teamMemberUpdate({
      variables: {
        data: {
          roles: newRoles,
        },
        filter: { email },
      },
    });

    onClose();
  };

  return (
    <Dialog id={ TEAM_MEMBER_REMOVE_FROM_ROLE_DIALOG_ID } size="sm">
      { ({ args }) => (
        <React.Fragment>
          <Dialog.Header title={ i18n.t('dialogs.teamMemberRemoveFromRole.title') } onClose={ onClose } />
          <Dialog.Body>
            <Trans i18nKey="dialogs.teamMemberRemoveFromRole.message">
              Are you sure you want to remove this team member from the role?
            </Trans>
          </Dialog.Body>
          <Dialog.Footer>
            <Button color="neutral" variant="outlined" onClick={ onClose }>
              <Trans i18nKey="shared.cancel">Cancel</Trans>
            </Button>
            <Button
              type="submit"
              loading={ submitting }
              onClick={ createOnRemove(args) }
              color="danger"
            >
              <Trans i18nKey="shared.remove">Remove</Trans>
            </Button>
          </Dialog.Footer>
        </React.Fragment>
      ) }
    </Dialog>
  );
};

TeamMemberRemoveFromRoleDialog.ID = TEAM_MEMBER_REMOVE_FROM_ROLE_DIALOG_ID;

export { TeamMemberRemoveFromRoleDialog };
