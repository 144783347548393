// @flow
import React from 'react';
import styled from '@emotion/styled';

import { Trans } from 'utils/translate';

import { UserFlowText } from './UserFlowText';

const UserFlowPageContentWrapperTag = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  height: 100%;
  width: 100%;
  z-index: 2;
  overflow: auto;
`;

const UserFlowPageContentTag = styled('div')`
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.alignItems};
  align-items: center;
  flex: 1;
  max-width: 900px;
  z-index: 2;

  @media (max-width: 992px) {
    max-width: 100%;
    padding: 2.4rem;
  }
`;

const UserFlowPageRightsTag = styled('div')`
  width: 100%;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
`;


type UserFlowPageContentProps = {
  children: React$Node,
  withAllRightsReserved?: boolean;
  alignItems?: string;
};

const UserFlowPageContent = ({ children, withAllRightsReserved = false, alignItems = 'flex-start' }: UserFlowPageContentProps) => (
  <UserFlowPageContentWrapperTag>
    <UserFlowPageContentTag alignItems={ alignItems }>
      { children }
    </UserFlowPageContentTag>
    <If condition={ withAllRightsReserved }>
      <UserFlowPageRightsTag>
        <UserFlowText align="center" kind="small-1">
          <Trans i18nKey="shared.allRightsReserved">8base, All rights reserved.</Trans>
        </UserFlowText>
      </UserFlowPageRightsTag>
    </If>
  </UserFlowPageContentWrapperTag>
);

export { UserFlowPageContent };
