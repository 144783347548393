import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default class CardCommonHelper extends Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    showClose: PropTypes.bool.isRequired,
    popup: PropTypes.bool,
    scrollable: PropTypes.bool,

    head: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.element,
      PropTypes.func,
    ]),

    footer: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.element,
      PropTypes.func,
    ]),

    children: PropTypes.any.isRequired,
    className: PropTypes.string,

    onClose: PropTypes.func,
  };

  static defaultProps = {
    title: '',
    showClose: false,
    popup: false,
  };

  renderHead = () => {
    const { title, head, showClose, onClose } = this.props;
    if (!title && !head) return null;
    if (head) return (
      <div className="card-header" { ...E2E.$props('app.dataBuilder.fieldFrom.fieldName') }>
        { head }
      </div>
    );
    return (
      <div className="card-header" { ...E2E.$props('app.dataBuilder.fieldFrom.fieldName') }>
        <h5>{ title }</h5>
        <If condition={ showClose }>
          <div className="card-close" onClick={ onClose }>&times;</div>
        </If>
      </div>
    );
  };

  renderFooter = () => {
    const { footer } = this.props;
    if (!footer) return null;
    return (
      <div className="card-footer">
        { footer }
      </div>
    );
  };

  render() {
    const { children, popup, scrollable, className } = this.props;
    const cardClassNames = cx(
      'card',
      {
        popup,
        'card--scrollable': scrollable,
      },
    );

    return (
      <div className={ className }>
        <div className={ cardClassNames } >
          { this.renderHead() }
          <div className="card-body">
            { children }
          </div>
          { this.renderFooter() }
        </div>
      </div>
    );
  }
}
