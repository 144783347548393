// @flow
import styled from '@emotion/styled';

export const DeveloperHomeNav = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
  height: 100%;
  flex-shrink: 0;

  background-color: ${props => props.theme.COLORS.WHITE};
`;
