import React from 'react';
import * as R from 'ramda';
import { css } from '@emotion/core';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { Form as FormLogic, Field } from '@8base-react/forms';
import { Dialog, Button, InputField, Text, useModal, CheckboxField, Form, Row } from '@8base/boost';
import { TABLES_SCHEMA_QUERY, useTablesList } from '@8base-react/table-schema-provider';
import { tablesListSelectors } from '@8base/utils';

import { IndexColumnsField } from 'pro';
import { required, requiredArray } from 'utils/formValidations';
import { i18n } from 'i18n';
import { IndexDeleteDialog } from 'dialogs/IndexDeleteDialog';
import { INDEXES_TYPES } from 'common/constants/indexes';

const INDEX_UPDATE_MUTATION = gql`
  mutation IndexUpdate($data: IndexUpdateInput!) {
    indexUpdate(data: $data) {
      id
    }
  }
`;

const INDEX_UPDATE_DIALOG_ID = 'INDEX_UPDATE_DIALOG_ID';

const IndexUpdateDialog = () => {
  const { closeModal, openModal, args } = useModal(INDEX_UPDATE_DIALOG_ID);

  const { tablesList } = useTablesList();

  const table = tablesListSelectors.getTableById(tablesList, args.tableId);

  const index = R.pipe(
    R.propOr([], 'indexes'),
    R.find(R.propEq('id', args.id)),
  )(table);

  const initialValues = React.useMemo(() => {
    if (!index) {
      return {};
    }

    const columns = R.pipe(
      R.propOr([], 'columns'),
      R.map(R.omit(['__typename'])),
      R.reject(R.propEq('name', 'deletedAt')),
    )(index);

    return {
      name: index.name,
      columns,
      unique: index.type === INDEXES_TYPES.UNIQUE,
    };
  }, [index]);

  const [indexUpdate] = useMutation(INDEX_UPDATE_MUTATION, {
    refetchQueries: [{ query: TABLES_SCHEMA_QUERY }],
    awaitRefetchQueries: true,
  });

  const onClose = React.useCallback(() => closeModal(INDEX_UPDATE_DIALOG_ID), [closeModal]);

  const onSubmit = React.useCallback(async (data) => {
    try {

      await indexUpdate({
        variables: {
          data: {
            id: args.id,
            name: data.name,
            columns: data.columns,
          },
        },
      });
    } catch (e) {
      if (R.has('graphQLErrors', e)) {
        e.graphQLErrors = e.graphQLErrors.map((err) => {
          if (R.hasPath(['details', 'columns'], err)) {
            const message = R.path(['details', 'columns'], err).map(R.prop('name')).join(', ');

            err = R.assocPath(['details', 'columns'], message, err);
          }

          return err;
        });

        throw e;
      }
    }

    onClose();
  }, [indexUpdate, args.id, onClose]);

  const openDeleteIndexDialog = React.useCallback(() => {
    onClose();

    openModal(IndexDeleteDialog.ID, { id: args.id, name: index.name });
  }, [onClose, openModal, args.id, index]);

  return (
    <Dialog id={ INDEX_UPDATE_DIALOG_ID } onClose={ onClose } size="xl">
      <FormLogic onSubmit={ onSubmit } initialValues={ initialValues }>
        { ({ handleSubmit, invalid, submitting, pristine }) => (
          <form onSubmit={ handleSubmit } css={ css`height: 100%;` }>
            <Dialog.Header title={ i18n.t('dialogs.indexUpdate.title') } onClose={ onClose } />
            <Dialog.Body scrollable>
              <Form>
                <Field name="name" label={ i18n.t('shared.name') } component={ InputField } validate={ required } />
                <Field name="columns" label={ i18n.t('shared.fields') } component={ IndexColumnsField } tableId={ args.tableId } validate={ requiredArray } />
                <Text color="SECONDARY_TEXT_COLOR" css={{ marginBottom: '4px !important' }}>Advanced</Text>
                <Field name="unique" label={ i18n.t('dialogs.indexUpdate.uniqueIndex') } component={ CheckboxField } />
              </Form>
            </Dialog.Body>
            <Dialog.Footer>

              <Row alignItems="center" stretch>
                <Button type="button" color="danger" variant="link" css={{ marginRight: 'auto !important' }} onClick={ openDeleteIndexDialog }>{ i18n.t('shared.delete') }</Button>

                <Button color="neutral" variant="outlined" disabled={ submitting } onClick={ onClose }>
                  { i18n.t('shared.cancel') }
                </Button>
                <Button type="submit" disabled={ pristine } loading={ submitting }>
                  { i18n.t('shared.update') }
                </Button>
              </Row>
            </Dialog.Footer>
          </form>
        ) }
      </FormLogic>
    </Dialog>
  );
};

IndexUpdateDialog.ID = INDEX_UPDATE_DIALOG_ID;

export { IndexUpdateDialog };
