// @flow
// $FlowFixMe waiting for update Flow
import React, { useState, useCallback, useMemo } from 'react';
import * as R from 'ramda';
import { useQuery, useMutation } from 'react-apollo';
import { Form, Field, FormSpy } from '@8base-react/forms';
import { Dialog, Button, InputField, SelectField, useModal, Column, Row, Text, Link, AsyncContent } from '@8base/boost';
import { useAuth } from '@8base-react/auth';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { toast } from 'react-toastify';

import { RequireCCFeatureDialog } from 'dialogs/RequireCCFeatureDialog';
import { PaymentDetailsUpdateDialog } from 'dialogs/BillingUpdateDialog/PaymentDetailsUpdateDialog';
import { BillingChooseCardDialog } from 'dialogs/BillingChooseCardDialog';
import { WORKSPACE_CREATE_MUTATION } from 'graphql/mutations';
import { CHECK_WORKSPACE_CREATION_QUERY, FRONTEND_PLAN_QUERY, BILLING_PLANS_QUERY } from 'graphql/queries';
import { BillingPlanRadioGroupField } from 'common/BillingPlanRadioGroupField';
import { ENDPOINT_URI, HIDE_TOAST_ERROR_MESSAGE, IGNORE_GRAPHQL_ERRORS, IGNORE_WORKSPACE } from 'common/constants/apolloOperationContextOptions';
import { PlanName } from 'common/PlanName';
import { PlanCard } from 'common/PlanCard';
import { composeValidators, required } from 'utils/formValidations';
import { Trans } from 'utils/translate';
import * as LINKS from 'config/links';
import { REGIONS_ENDPOINTS, getRegionNameByEndpoint } from 'common/constants/regions';
import { useBillingInfo } from 'providers/BillingProvider/useBillingInfo';
import { ArrowLink, BankCardLastFour } from 'common/components';
import { FS, FULLSTORY_EVENT_TYPES } from 'utils/fullStory';
import { WorkspaceLoader } from 'common/WorkspaceLoader';

import { useWorkspacesList, useOrganizationPaymentDetails, useUserPaymentDetails } from '../graphql/hooks';
import { requiredWithSpaces } from '../utils/formValidations';
import { getServerUrl } from 'utils/processEnv';
import { checkIs8baseUser } from 'utils/help';
import { WORKSPACE_KIND } from 'common/constants/workspaceKind';

import { OrganizationPaymentDetailsUpdateDialog } from './BillingUpdateDialog/OrganizationPaymentDetailsUpdateDialog';
import { UserPaymentDetailsUpdateDialog } from './BillingUpdateDialog/UserPaymentDetailsUpdateDialog';

const WORKSPACE_CREATE_DIALOG_ID = 'WORKSPACE_CREATE_DIALOG_ID';

const POLL_INTERVAL = 5000;

const CREATE_WORKSPACE_STATUS_SUCCESS = 'complete_success';
const CREATE_WORKSPACE_STATUS_ERROR = 'complete_error';

const REGION_OPTIONS = [
  {
    value: REGIONS_ENDPOINTS.US,
    label: 'us-east-1 (N. Virginia)',
  },
  {
    value: REGIONS_ENDPOINTS.UK,
    label: 'eu-west-2 (London)',
  },
].filter(el => !!el.value);

const getCurrentCard = ({
  newCard,
  organizationCard,
  userCard,
  isOrganization,
}) => {
  if (isOrganization) {
    if (newCard) {
      return newCard.card;
    }

    if (organizationCard) {
      return organizationCard;
    }
    return null;
  }

  return userCard;
};

const getBillingPlansVars = ({ organizationId, regionEndpoint }) => {
  return {
    organizationId,
    region: getRegionNameByEndpoint(regionEndpoint),
  };
};

const WorkspaceCreateDialog = () => {
  const [newCardToken, setNewCardToken] = useState(null);
  const [step, setStep] = useState('form');
  const { t } = useTranslation();
  const { authState } = useAuth();
  const { closeModal, openModal, args } = useModal(WORKSPACE_CREATE_DIALOG_ID);
  const {
    isFreePlanAvailable,
    planDataLoading,
    regionEndpoint,
    setRegionEndpoint,
  } = useBillingInfo();
  const { allWorkspacesList, refetch } = useWorkspacesList();

  const { card: userCard, loading: userCardLoading } = useUserPaymentDetails();
  const { card: organizationCard, loading: organizationCardLoading } = useOrganizationPaymentDetails(args.organizationId);

  const {
    data: plansData,
    loading: plansLoading,
  } = useQuery(BILLING_PLANS_QUERY, {
    variables: getBillingPlansVars({ organizationId: args.organizationId, regionEndpoint }),
    fetchPolicy: 'cache-and-network',
    context: {
      [IGNORE_WORKSPACE]: true,
      [ENDPOINT_URI]: regionEndpoint,
    },
  });

  const plans = R.pathOr([], ['system', 'userBillingConfiguration', 'availablePlans'], plansData);

  const uniqWorkspace = useCallback(
    value => allWorkspacesList.items.some(({ name }) => name === value) ? `Workspace with name '${value}' already exists.` : null,
    [allWorkspacesList.items],
  );

  const [workspaceCreate, { loading: workspaceLoading }] = useMutation(WORKSPACE_CREATE_MUTATION);

  const onClose = useCallback(() => {
    const region = args && args.region;
    setRegionEndpoint(region || getServerUrl());
    closeModal(WORKSPACE_CREATE_DIALOG_ID);

    setStep('form');
  }, [args, closeModal, setRegionEndpoint]);


  const [awaitWorkspaceId, setAwaitWorkspaceId] = useState(null);
  const [awaitWorkspaceEndpoint, setWorkspaceEndpoint] = useState(null);
  const onCheckWorkspaceCreation = useCallback((completedData) => {
    if (!completedData) {
      setAwaitWorkspaceId(null);
    }

    const status = R.path(['asyncSessionStatus', 'status'], completedData);
    const message = R.path(['asyncSessionStatus', 'message'], completedData);

    switch (status) {
      case CREATE_WORKSPACE_STATUS_SUCCESS: {
        const onFinish = () => {
          setAwaitWorkspaceId(null);

          onClose();

          const onSuccess = R.prop('onSuccess', args);

          if (typeof onSuccess === 'function') {
            onSuccess(awaitWorkspaceId);
          }
        };

        if (refetch) {
          // $FlowFixMe
          refetch().then(() => onFinish());
        } else {
          onFinish();
        }

        break;
      }

      case CREATE_WORKSPACE_STATUS_ERROR: {
        setAwaitWorkspaceId(null);

        onClose();

        if (message) {
          toast.error(message);
        }

        break;
      }

      default: {
        break;
      }
    }
  }, [args, awaitWorkspaceId, onClose, refetch]);

  useQuery(CHECK_WORKSPACE_CREATION_QUERY, {
    variables: {
      workspaceId: awaitWorkspaceId,
    },
    context: {
      [ENDPOINT_URI]: awaitWorkspaceEndpoint,
      [IGNORE_GRAPHQL_ERRORS]: true,
      [HIDE_TOAST_ERROR_MESSAGE]: true,
      [IGNORE_WORKSPACE]: true,
    },
    skip: !awaitWorkspaceId || !awaitWorkspaceEndpoint,
    fetchPolicy: 'network-only',
    pollInterval: awaitWorkspaceEndpoint && awaitWorkspaceId ? POLL_INTERVAL : 0,
    onCompleted: onCheckWorkspaceCreation,
  });

  const { data: frontedPlanData, loading: frontedPlanLoading } = useQuery(FRONTEND_PLAN_QUERY);

  const onSubmit = useCallback(async (data) => {
    let id = null;

    const chosenPlan = plans.find(({ id }) => data.billingPlanId === id) || {};

    if (data.billingPlanId === '8base') {
      data = {
        ...data,
        billingPlanId: null,
      };
    }

    if (args && args.organizationId) {
      data = {
        ...data,
        organizationId: args.organizationId,
      };
    }

    if (args && args.kind) {
      data = {
        ...data,
        kind: args.kind,
      };
    }

    if (args && args.kind && args.kind === WORKSPACE_KIND.frontend) {
      const billingPlanId = R.path(['system', 'getFrontendPlanId', 'id'], frontedPlanData);
      data = {
        ...data,
        billingPlanId,
      };
    }

    const cardToken = args.organizationId ? R.prop('id', newCardToken) : undefined;

    if (cardToken) {
      data = {
        ...data,
        cardToken,
      };
    }

    try {
      ({ data: { workspaceCreate: { id }}} = await workspaceCreate({
        variables: { data: R.omit(['region'], data) },
        context: {
          [ENDPOINT_URI]: data.region,
          [IGNORE_WORKSPACE]: true,
        },
      }));

      FS.event(FULLSTORY_EVENT_TYPES.createWorkspace, {
        pricing_plan: chosenPlan && chosenPlan.displayName,
        workspace_name: data.name,
        workspace_id: id,
      });
    } catch (e) {
      setStep('form');

      throw e;
    }

    setAwaitWorkspaceId(id);
    setWorkspaceEndpoint(data.region);
  }, [plans, args, newCardToken, frontedPlanData, workspaceCreate]);

  const openPaymentModal = useCallback(() => {
    if (args.organizationId) {
      if (!organizationCard) {
        openModal(OrganizationPaymentDetailsUpdateDialog.ID, { organizationId: args.organizationId });

        return;
      }

      const updateCard = (token) => {
        setNewCardToken(token);
        closeModal(PaymentDetailsUpdateDialog.ID);
      };

      openModal(BillingChooseCardDialog.ID, {
        defaultCard: organizationCard,
        defaultCardDescription: 'Default organization payment method',
        onChooseCard: () => {
          openModal(PaymentDetailsUpdateDialog.ID, {
            onCardTokenChange: updateCard,
          });
          closeModal(BillingChooseCardDialog.ID);
        },
        onChooseDefaultCard: () => {
          setNewCardToken(null);
          closeModal(BillingChooseCardDialog.ID);
        },
      });
    } else {
      openModal(UserPaymentDetailsUpdateDialog.ID);
    }
  }, [args.organizationId, closeModal, openModal, organizationCard]);

  const onCreateWorkspace = useCallback((planId) => () => {
    const plan = R.find(R.propEq('id', planId), plans);

    if (args && args.kind && args.kind === WORKSPACE_KIND.frontend) {
      return;
    }

    if (args.organizationId && !organizationCard) {
      openModal(RequireCCFeatureDialog.ID, {
        dialogTitle: t('dialogs.workspaceCreate.title'),
        note: (
          <React.Fragment>
            In order to upgrade to <PlanName plan={ plan } /> you’ll need to update your payment details.
          </React.Fragment>
        ),
        organizationId: args.organizationId,
      });
    } else if (!userCard) {
      openModal(RequireCCFeatureDialog.ID, {
        dialogTitle: t('dialogs.workspaceCreate.title'),
        note: (
          <React.Fragment>
            In order to upgrade to <PlanName plan={ plan } /> you’ll need to update your payment details.
          </React.Fragment>
        ),
      });
    } else {
      setStep('payment');
    }
  }, [plans, args, organizationCard, userCard, openModal, t]);

  const onChangePlan = useCallback(() => {
    setStep('form');
  }, [setStep]);

  const initialValues = useMemo(() => {
    if (args && args.kind && args.kind === WORKSPACE_KIND.frontend) {
      return {
        region: REGIONS_ENDPOINTS.US,
      };
    }

    if (args && args.region) {
      return {
        region: args.region,
      };
    }

    return {
      region: getServerUrl(),
    };

  }, [args]);

  const currentCard = getCurrentCard({
    isOrganization: !!args.organizationId,
    newCard: newCardToken,
    organizationCard,
    userCard,
  });

  return (
    <Dialog
      id={ WORKSPACE_CREATE_DIALOG_ID }
      onClose={ onClose }
      shouldCloseOnOverlayClick={ false }
      shouldCloseOnEscPress={ false }
      size={ workspaceLoading || awaitWorkspaceId ? 'xxl' : 'xl' }
      { ...E2E.$props('dialogs.workspaceCreate.body') }
    >
      <Form onSubmit={ onSubmit } initialValues={ initialValues } >
        { ({ handleSubmit, invalid, submitting, pristine, values, dirtySinceLastSubmit, form }) => {
          const plan = R.find(R.propEq('id', values.billingPlanId), plans);

          if (workspaceLoading || awaitWorkspaceId) {
            return (
              <div css={ css`padding: 120px 0;` }>
                <WorkspaceLoader />
              </div>
            );
          }

          return (
            <form onSubmit={ handleSubmit } css={{ overflow: 'auto' }} { ...E2E.$props('dialogs.workspaceCreate.form') }>
              <FormSpy
                onChange={ (args) => {
                  const newRegion = R.path(['values', 'region'], args);
                  const oldRegion = values.region;
                  if (newRegion !== oldRegion) {
                    setRegionEndpoint(newRegion);
                    form.change('billingPlanId', null);
                  }
                } }
              />
              <Dialog.Header title={ t('dialogs.workspaceCreate.title') } onClose={ onClose } />
              <Choose>
                <When condition={ args && args.kind && args.kind === WORKSPACE_KIND.frontend } >
                  <Dialog.Body>
                    <AsyncContent loading={ frontedPlanLoading } stretch >
                      <Column css={ step !== 'form' ? { display: 'none' } : {} } gap="md">
                        <Row stretch>
                          <Field
                            { ...E2E.$props('dialogs.workspaceCreate.workspaceNameInput') }
                            stretch
                            name="name"
                            label="Name"
                            placeholder={ t('dialogs.workspaceCreate.namePlaceholder') }
                            component={ InputField }
                            validate={ composeValidators(requiredWithSpaces, uniqWorkspace) }
                            autoFocus
                          />
                        </Row>
                      </Column>
                    </AsyncContent>
                  </Dialog.Body>
                </When>

                <Otherwise>
                  <Dialog.Body scrollable css={ css`min-height: 200px;` }>
                    <AsyncContent loading={ organizationCardLoading || userCardLoading || planDataLoading || plansLoading } stretch >
                      <Column css={ step !== 'form' ? { display: 'none' } : {} } gap="md">
                        <Row stretch>
                          <Column stretch>
                            <Field
                              { ...E2E.$props('dialogs.workspaceCreate.workspaceNameInput') }
                              stretch
                              name="name"
                              label="Name"
                              placeholder={ t('dialogs.workspaceCreate.namePlaceholder') }
                              component={ InputField }
                              validate={ composeValidators(requiredWithSpaces, uniqWorkspace) }
                              autoFocus
                            />
                          </Column>
                          <Column stretch>
                            <Field
                              { ...E2E.$props('dialogs.workspaceCreate.regionInput') }
                              stretch
                              name="region"
                              label="Select your AWS regional endpoint location"
                              component={ SelectField }
                              options={ REGION_OPTIONS }
                              validate={ required }
                            />
                          </Column>
                        </Row>

                        <Row justifyContent="between" alignItems="center" stretch>
                          <Text>
                            <Trans i18nKey="dialogs.workspaceCreate.chooseYourPlan">Choose your workspace plan:</Trans>
                          </Text>
                          <ArrowLink
                            href={ LINKS.PRICING_URL }
                            rel="noopener noreferrer"
                            target="_blank"
                            title={ (
                              <Link href={ LINKS.PRICING_URL } target="_blank" rel="noopener noreferrer">
                                <Trans i18nKey="dialogs.workspaceCreate.seeFullDetails">See full details</Trans>
                              </Link>
                            ) }
                          />
                        </Row>
                        <Field
                          { ...E2E.$props('dialogs.workspaceCreate.billingPlanRadio') }
                          name="billingPlanId"
                          validate={ required }
                          component={ BillingPlanRadioGroupField }
                          isFreePlanAvailable={ args.organizationId ? false : isFreePlanAvailable }
                          card={ currentCard }
                          plans={ plans }
                          with8base={ checkIs8baseUser(authState) }
                        />

                        <If condition={ !currentCard }>
                          <Text color="LIGHT_TEXT_COLOR">
                            <Text weight="bold">No card on file.</Text>&nbsp;
                              In order to upgrade to a paid plan, you’ll need to&nbsp;
                            <Link onClick={ openPaymentModal }>update { args.organizationId ? 'organization' : 'your' }  payment details.</Link>
                          </Text>
                        </If>

                        <If condition={ !!currentCard }>
                          <Row alignItems="center">
                            <BankCardLastFour card={ currentCard } />
                            <Link onClick={ openPaymentModal }>update { args.organizationId ? 'workspace' : 'your' }  payment details</Link>
                          </Row>
                        </If>

                      </Column>
                      <Column css={ step !== 'payment' ? { display: 'none' } : {} } stretch>
                        <Text color="SECONDARY_TEXT_COLOR">Workspace Plan:</Text>
                        <PlanCard plan={ plan } workspaceName={ values.name } />
                        <If condition={ !!currentCard }>
                          <Text>A { R.prop('brand', currentCard) } card ending in <Text weight="semibold">{ R.prop('last4', currentCard) }</Text> will be charged for an amount of <Text weight="semibold">${ R.prop('price', plan) }</Text>.</Text>
                        </If>
                      </Column>
                    </AsyncContent>
                  </Dialog.Body>
                </Otherwise>
              </Choose>

              <Dialog.Footer>
                <Row alignItems="center" justifyContent={ step === 'form' ? 'end' : 'between' } stretch>
                  <If condition={ step !== 'form' }>
                    <Button type="button" color="primary" variant="link" onClick={ onChangePlan } disabled={ submitting }>Change Plan</Button>
                  </If>
                  <Row>
                    <Button
                      type="button"
                      color="neutral"
                      variant="outlined"
                      onClick={ onClose }
                      disabled={ submitting }
                    >
                      { t('shared.cancel') }
                    </Button>
                    <If condition={ step === 'form' }>
                      <Button
                        type={ R.prop('price', plan) > 0 ? 'button' : 'submit' }
                        disabled={ pristine || (invalid && !dirtySinceLastSubmit) || userCardLoading || organizationCardLoading }
                        loading={ submitting }
                        onClick={ R.prop('price', plan) > 0 ? onCreateWorkspace(values.billingPlanId) : undefined }
                        { ...E2E.$props('dialogs.workspaceCreate.createBtn') }
                      >
                        { t('shared.create') }
                      </Button>
                    </If>
                    <If condition={ step === 'payment' }>
                      <Button
                        type="submit"
                        disabled={ submitting }
                        loading={ submitting }
                        { ...E2E.$props('dialogs.workspaceCreate.createBtn') }
                      >
                        { t('shared.processPayment') }
                      </Button>
                    </If>
                  </Row>
                </Row>
              </Dialog.Footer>
            </form>
          );
        } }
      </Form>
    </Dialog>
  );
};

WorkspaceCreateDialog.ID = WORKSPACE_CREATE_DIALOG_ID;

export { WorkspaceCreateDialog };
