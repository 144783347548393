import React from 'react';
import { Form } from '@8base/boost';
import { Controlled as CodeMirror } from 'react-codemirror2';

import { isInvalid } from 'utils/forms/isInvalid';

require('codemirror/mode/sql/sql');

export const CodeMirrorField = ({ input, meta, label, mode, ...rest }) => {
  const invalid = isInvalid(meta);

  const onBeforeChange = (editor, data, value) => {
    input.onChange(value);
  };

  const onChange = (editor, data, value) => {
    input.onChange(value);
  };

  return (
    <Form.Field label={ label } input={ input } meta={ meta } { ...rest }>
      <CodeMirror
        css={{
          width: '100%',
          borderColor: invalid ? '#ff6d4a' : '#d0d7dd',
          borderWidth: 1,
          borderRadius: 5,
          overflow: 'hidden',
          borderStyle: 'solid',
          '& .CodeMirror': {
            fontSize: 13,
            fontFamily: 'Courier',
          },
        }}
        value={ input.value }
        options={{
          mode,
          theme: 'material',
          lineNumbers: true,
        }}
        onBeforeChange={ onBeforeChange }
        onChange={ onChange }
      />
    </Form.Field>
  );
};
