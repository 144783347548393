// @flow

import * as R from 'ramda';
import { createSelector, type Selector } from 'reselect';

import type { ApolloUsersListResponse } from './usersList.selectors';
import type { ApolloPermissionsListResponse } from './role/permissionsList.selectors';
import type { ApolloPermission } from './role/permission.selectors';

export type ApolloRole = $Shape<{|
  id: string,
  createdAt: Date,
  updatedAt: Date,
  name: string,
  description: string,
  permissions: ApolloPermissionsListResponse,
  users: ApolloUsersListResponse,
  invitations: *,
|}>

export const getRole = (role: ApolloRole): ApolloRole => role;

export const getName = createSelector(getRole, R.propOr(null, 'name'));

export const getUsersListResponse = createSelector(
  getRole,
  R.prop('users'),
);

export const getId: Selector<
  ApolloRole,
  any,
  string,
> = createSelector(
  getRole,
  R.prop('id'),
);

export const getPermissionsListResponse: Selector<
  ApolloRole,
  any,
  ApolloPermissionsListResponse,
> = createSelector(
  getRole,
  R.prop('permissions'),
);

export const getPermissionsList: Selector<
  ApolloRole,
  any,
  ApolloPermission[],
> = createSelector(
  getPermissionsListResponse,
  R.propOr([], 'items'),
);

export const getRoleSelectOption: Selector<
  ApolloRole,
  any,
  { value: string, label: string },
> = createSelector(
  getName,
  getId,
  (name, id) => ({
    value: id,
    label: name,
  }),
);

export const getPermissionsByResource: Selector<
  ApolloRole,
  any,
  { [string]: Array<ApolloPermission> },
> = R.pipe(
  getPermissionsListResponse,
  R.propOr([], 'items'),
  R.indexBy(R.prop('resource')),
);
