// @flow

import React from 'react';
// $FlowFixMe waiting for update Flows
import { useQuery } from 'react-apollo';
import * as R from 'ramda';

import { USER_ACCOUNT_INFO_QUERY } from 'graphql/queries';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { LearningCenter } from '../../pro';


export const AppFlowLearningCenter = () => {
  const { id: workspaceId } = useWorkspace();

  const { data } = useQuery(
    USER_ACCOUNT_INFO_QUERY, {
      skip: !workspaceId,
    },
  );

  const learningMode = R.path(['system', 'memberAccount', 'learningMode'], data);

  return (
    <LearningCenter withLearningMode={ learningMode } />
  );
};

