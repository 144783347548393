import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class CountHeadCommonNavigationLeftCommonHelper extends Component {

  static propTypes = {
    count: PropTypes.number.isRequired,
  };

  static defaultProps = {
    count: 0,
  };

  render() {
    const { count } = this.props;
    return <div className="nav-count">{ count }</div>;
  }
}
