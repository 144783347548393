// @flow
// $FlowFixMe waiting for update Flow
import React from 'react';
import {
  Column,
  Text,
} from '@8base/boost';
// $FlowFixMe
import { useParams, Redirect } from 'react-router-dom';

import { APP_URL, useBuildUrl } from 'common/routing';
import { LicenseCost, BillingHistory, PaymentsDetails } from './components';

export const OrganizationBilling = ({ shouldRedirect }: *) => {
  const buildUrl = useBuildUrl();
  const { organizationId } = useParams();

  if (shouldRedirect) {
    const fallbackUrl = buildUrl(APP_URL.organizationSettingsGeneral, {
      pathParams: {
        organizationId,
      },
      ignoreWorkspaceId: true,
    });

    return <Redirect to={ fallbackUrl } />;
  }

  return (
    <Column alignItems="stretch" gap="lg" >
      <Text kind="h1">Billing</Text>
      <LicenseCost />
      <PaymentsDetails />
      <BillingHistory />
    </Column>
  );
};
