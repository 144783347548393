// @flow

import React from 'react';
import qs from 'qs';
import { Loader } from '@8base/boost';
import { Redirect } from 'react-router-dom';
import { withAuth, type AuthContextProps } from '@8base-react/auth';
import * as Sentry from '@sentry/browser';
import { compose } from 'recompose';

import { withBuildUrl, buildUrl, APP_URL as URLRoutes } from 'common/routing';
import { logger } from 'utils/logger';
import { getServerUrl } from 'utils/processEnv';

type CallbackContainerProps = {
  location: Object,
  buildUrl: typeof buildUrl,
  auth: AuthContextProps,
};

type CallbackContainerState = {
  error?: boolean,
  success?: boolean,
};

const LOGIN_SESSION_SET_URL = new URL('/loginSessionSet', getServerUrl()).toString();

const enhancer = compose(
  withBuildUrl,
  withAuth,
);

export const CallbackContainer = enhancer(
  class CallbackContainer extends React.Component<CallbackContainerProps, CallbackContainerState> {
    state = {};

    async componentDidMount() {
      const { auth, location } = this.props;

      const { state, email } = await auth.authClient.getAuthorizedData();

      Sentry.configureScope((scope) => {
        scope.setUser({
          email,
        });
      });

      let guid = null;

      if (state) {
        try {
          ({ guid } = JSON.parse(state));
        } catch (error) {
          Sentry.captureException(error);
          logger.error(error);

          this.setState({ error: true });
          return;
        }
      }

      // Get 'code' directly from the window.hash
      let hashStr = location.hash || '';
      hashStr = hashStr.replace(/^#?\/?/, '');
      const { code } = qs.parse(hashStr);

      if (guid && code) {
        window.trackEvent('System', 'Login to CLI');

        fetch(LOGIN_SESSION_SET_URL, {
          method: 'POST',
          body: JSON.stringify({ guid, code }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`CLI Auth: loginSessionSet request returns ${response.status} ${response.statusText}`);
            }
          })
          .then(() => {
            this.setState({ success: true });
          })
          .catch((error) => {
            Sentry.captureException(error);
            logger.error(error);

            this.setState({ error: true });
          });
      } else {
        const error = new Error('CLI Auth: guid or code wasn\'t provided for cli authorization');

        Sentry.captureException(error);
        logger.error(error);

        this.setState({ error: true });
      }
    }

    render() {
      const { location } = this.props;
      const { success, error } = this.state;

      if (!location.hash) {
        return <Redirect to={ this.props.buildUrl(URLRoutes.cliError) } />;
      }

      if (error) {
        return <Redirect to={ this.props.buildUrl(URLRoutes.cliError) } />;
      }

      if (success) {
        return <Redirect to={ this.props.buildUrl(URLRoutes.cliSuccess) } />;
      }

      return <Loader stretch />;
    }
  },
);
