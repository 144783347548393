exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Icon__icon-color-base___Xl1_z {\n  color: #444; }\n\n.Icon__icon-color-gray___PYj78 {\n  color: gray; }\n\n.Icon__icon-color-green___3RkDS {\n  color: #00bb6e; }\n", ""]);

// exports
exports.locals = {
	"icon-color-base": "Icon__icon-color-base___Xl1_z",
	"icon-color-gray": "Icon__icon-color-gray___PYj78",
	"icon-color-green": "Icon__icon-color-green___3RkDS"
};