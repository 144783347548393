// @flow

// $FlowIgnore
import React, { useRef } from 'react';
import { InputField, Button, Row, Icon, Tooltip } from '@8base/boost';
import { toast } from 'react-toastify';
import { css } from '@emotion/core';

type CopyInputProps = {
  className?: string,
  value: string,
  label?: string,
  tooltipMessage?: string,
  toastMessage: string,
  e2eInputProps?: Object,
  e2eButtonProps?: Object,
  onSuccess?: (value: string) => any,
};

const CopyInput = ({
  className,
  value,
  label,
  onSuccess,
  tooltipMessage,
  toastMessage,
  e2eInputProps,
  e2eButtonProps,
}: CopyInputProps) => {
  const inputRef = useRef(null);
  const handleClick = () => {
    inputRef.current.focus();
    inputRef.current.select();

    document.execCommand('Copy');

    toast.success(toastMessage);

    onSuccess && onSuccess(inputRef.current.value);
  };

  const handleFocus = () => {
    inputRef.current.select();
  };

  return (
    <Row stretch gap="md" css={ className } alignItems="end">
      <InputField
        label={ label }
        input={{ value }}
        insideRef={ inputRef }
        onFocus={ handleFocus }
        readOnly
        stretch
        { ...e2eInputProps }
      />
      <Tooltip
        modifiers={{ offset: { offset: '0, 4' }}}
        message={ tooltipMessage }
      >
        <Button
          onClick={ handleClick }
          type="button"
          size="sm"
          color="neutral"
          variant="outlined"
          squared
          css={ css`flex-shrink: 0 ` }
          { ...e2eButtonProps }
        >
          <Icon name="Copy" size="sm" />
        </Button>
      </Tooltip>
    </Row>
  );
};

CopyInput.defaultProps = {
  e2eInputProps: {},
  e2eButtonProps: {},
};

export { CopyInput };

