// @flow
import React from 'react';
import { Card, Button, Text, styled, Grid } from '@8base/boost';

import { Trans } from 'utils/translate';
import { MEETING_LINK } from 'common/constants/links';


const MeetingTitle = styled.div`
    font-size: 1.8rem;
    line-height: 2.8rem;
    font-weight: 400;
    letter-spacing: 0.5px;
`;

export const Meeting = () => {

  return (
    <Card>
      <Card.Body>
        <Grid.Layout style={{ gap: '20px' }} rows="auto auto">
          <Grid.Box>
            <MeetingTitle>
              <Trans i18nKey="settings.billing.meeting.title">
                Take advantage of our Organization Plans
              </Trans>
            </MeetingTitle>
          </Grid.Box>
          <Grid.Layout style={{ gap: '20px' }} columns="minmax(300px, 580px) minmax(auto, 156px)" >
            <Text kind="subtitle">
              <Trans i18nKey="settings.billing.meeting.note">
                Organization Plans work well for companies and digital agencies that need access to multiple workspaces across a pool of developers.
              </Trans>
            </Text>
            <Button
              stretch
              tagName="a"
              href={ MEETING_LINK }
              target="_blank"
              rel="noopener noreferrer"
              color="success"
            >
              <Trans i18nKey="settings.billing.meeting.buttonLabel">
                Talk to Sales
              </Trans>
            </Button>
          </Grid.Layout>
        </Grid.Layout>
      </Card.Body>
    </Card>
  );
};

