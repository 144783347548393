// @flow

import React from 'react';

import { pipeClassNames, mapPropToClassName } from 'utils/styles';
import './Layout.scss';

/**
 * @prop {*} grow set flex-grow: 1 to all children
 */
type LayoutCommonProps = {|
  children: React$Node,
  justifyContent?: 'start' | 'end' | 'between' | 'around' | 'center' | 'stretch',
  alignContent?: 'start' | 'end' | 'between' | 'around' | 'center',
  alignItems?: 'start' | 'end' | 'baseline' | 'stretch' | 'center',
  marginBetween?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'none',
  offsetX?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'none',
  offsetY?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'none',
  cursor?: 'pointer' | 'default',
  noMargin?: boolean,
  stretch?: boolean,
  narrow?: boolean,
  noGutters?: boolean,
  grow?: boolean,
  growChildren?: boolean,
  onClick?: (event?: MouseEvent) => void,
|}

type LayoutProps = {
  ...LayoutCommonProps,
  direction?: 'row' | 'column',
}

/** component provides interface to render flex layout */
export const Layout = ({
  children,
  justifyContent,
  alignContent,
  alignItems,
  grow = false,
  growChildren = false,
  direction = 'row',
  marginBetween = 'md',
  offsetX = 'none',
  offsetY = 'none',
  cursor,
  stretch = false,
  narrow = false,
  noMargin = false,
  noGutters = false,
  onClick,
}: LayoutProps) => {
  const marginPrefix = direction === 'row'
    ? 'margin-between'
    : 'margin-between-cl';

  const className = pipeClassNames(
    mapPropToClassName('justify-content', justifyContent),
    mapPropToClassName('align-content', alignContent),
    mapPropToClassName('align-items', alignItems),
    mapPropToClassName('flex', direction),
    {
      stretch,
      narrow,
      'no-gutters': noGutters,
    },
  );

  const marginClass = noMargin ? '' : mapPropToClassName(marginPrefix, marginBetween);

  const styleName = pipeClassNames(
    marginClass,
    mapPropToClassName('offset-x', offsetX),
    mapPropToClassName('offset-y', offsetY),
    {
      'flex-grow': grow,
      'flex-grow-children': growChildren,
    },
  );

  return <div styleName={ styleName } className={ className } onClick={ onClick } style={{ cursor }}>{ children }</div>;
};

/** component provides interface to render flex row */
export const Row = (props: LayoutCommonProps) => <Layout { ...props } direction="row" />;

/** component provides interface to render flex column */
export const Column = (props: LayoutCommonProps) => <Layout { ...props } direction="column" />;
