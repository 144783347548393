// @flow
import React from 'react';
import { Tooltip, Text, Link } from '@8base/boost';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { USER_STATUSES } from 'common/constants/organization';
import { i18n } from 'i18n';

import workspaceDefaultLogo from 'images/workspace-default-logo.svg';

type SquareAvatarsProps = {
  data: Array<{srcUrl: string, tooltipMessage: string}>;
  onClick?: () => void;
  status: string;
  isAllowToInvite: boolean,
  onInviteToWorkspace: ()=> void;
}

const Wrapper = styled('div')`
  display: flex;
`;

const imgBaseCss = css`
  border-radius: 3px;
  background: #FFFFFF;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  object-fit: cover;
`;

const Img = styled('img')`
  ${imgBaseCss}
`;

const CounterTag = styled('div')`
  ${imgBaseCss}

  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.COLORS.GRAY_40};
  color: ${props => props.theme.COLORS.WHITE};
  white-space: nowrap;
`;


const offsetCss = css`
  &:not(:first-of-type) {
    margin-left: 4px;
  }
`;

export const SquareAvatars = ({ data, status, onClick, onInviteToWorkspace, isAllowToInvite }: SquareAvatarsProps) => {
  if (status === USER_STATUSES.invitationPending) return null;

  return (
    <Wrapper onClick={ onClick }>
      <Choose>
        <When condition={ !!data.length }>
          { data.slice(0, 3).map(({ srcUrl, tooltipMessage }, index) => (
            <Tooltip key={ index } css={ offsetCss } message={ tooltipMessage }>
              <Img src={ srcUrl || workspaceDefaultLogo } />
            </Tooltip>
          )) }

          <If condition={ data.length > 3 }>
            <CounterTag css={ offsetCss }>+ { data.length - 3 }</CounterTag>
          </If>
        </When>
        <Otherwise>
          <Text color="GRAY_40">
            { i18n.t('shared.noWorkspaces', { defaultValue: 'No workspaces' }) }.&nbsp;
          </Text>
          { isAllowToInvite && <Link onClick={ () => onInviteToWorkspace() } css={ css`line-height: 2.2rem;` }>{ i18n.t('shared.invite') }</Link> }
        </Otherwise>
      </Choose>
    </Wrapper>
  );
};
