// @flow

import { prop, is } from 'ramda';
import { createSelector, type Selector } from 'reselect';

import type {
  ApolloDataPermissionOperationTypes,
  ApolloDataPermissionOperation,
} from './dataPermissionOperation.selectors';

export type ApolloDataPermission = $Shape<{
  [ApolloDataPermissionOperationTypes]: ApolloDataPermissionOperation,
}>;

export const getDataPermission = <T: ApolloDataPermission>(dataPermission: T): T => dataPermission;

export const getDataPermissionOperation: Selector<
  ApolloDataPermission,
  ApolloDataPermissionOperationTypes,
  ApolloDataPermissionOperation,
> = createSelector(
  getDataPermission,
  (_, operationType) => operationType,
  (dataPermission, operationType) => prop(operationType)(dataPermission),
);

export const getReadFieldsPermission: Selector<
  ApolloDataPermission,
  any,
  ApolloDataPermissionOperation,
> = createSelector(
  (dataPermission: ApolloDataPermission) => getDataPermissionOperation(dataPermission, 'read'),
  ({ fields }) => fields,
);

export const getUpdateFieldsPermission: Selector<
  ApolloDataPermission,
  any,
  ApolloDataPermissionOperation,
> = createSelector(
  (dataPermission: ApolloDataPermission) => getDataPermissionOperation(dataPermission, 'update'),
  ({ fields }) => fields,
);

export const hasReadPermission: Selector<
  ApolloDataPermission,
  string,
  boolean,
> = createSelector(
  getReadFieldsPermission,
  (_, fieldName:string) => fieldName,
  (readFieldsPermission, fieldName) => is(Boolean, readFieldsPermission[fieldName]),
);


export const hasUpdatePermission: Selector<
    ApolloDataPermission,
  string,
  boolean,
> = createSelector(
  getUpdateFieldsPermission,
  (_, fieldName:string) => fieldName,
  (readFieldsPermission, fieldName) => is(Boolean, readFieldsPermission[fieldName]),
);
