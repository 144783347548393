import * as R from 'ramda';
import { getUserAccountInfo } from 'graphql/selectors/userAccountInfo.selectors';

export const getUserAccountInfoInitialValues = (userAccountInfo) => R.pipe(
  getUserAccountInfo,
  R.pick(
    [
      'firstName',
      'lastName',
      'avatar',
      'timezone',
      'email',
      'companyName',
      'country',
      'addressLine1',
      'addressLine2',
      'city',
      'state',
      'zipCode',
      'website',
      'githubUsername',
      'linkedInUsername',
      'twitterUsername',
      'aboutMe',
    ]),
)(userAccountInfo);
