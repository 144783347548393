// @flow
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

type UserFlowHeadingProps = {
  type?: 'h1' | 'h2' | 'h3';
}

const UserFlowHeadingH1Tag = styled('h1')`
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 58px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 40px;
  }
`;


const UserFlowHeadingH2Tag = styled('h2')`
  font-family: Raleway;
  font-size: 32px;
  line-height: 37px;
  font-weight: 600;
  text-align: center;

`;


const UserFlowHeadingH3Tag = styled('h2')`
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  text-align: center;
`;


export const UserFlowHeading = ({ type = 'h1', ...rest }: UserFlowHeadingProps) => {
  switch (type) {
    case 'h1': return <UserFlowHeadingH1Tag { ...rest } />;
    case 'h2': return <UserFlowHeadingH2Tag { ...rest } />;
    case 'h3': return <UserFlowHeadingH3Tag { ...rest } />;

    default: return null;
  }
};

export const UserFlowHeadingWhite = (props: UserFlowHeadingProps) => {
  return <UserFlowHeading { ...props } css={ css`color: #FFFFFF;` } />;
};
