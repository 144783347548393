import { AppFlowContent } from './AppFlowContent';
import { AppFlowNav } from './AppFlowNav';
import { AppFlowNavItem } from './AppFlowNavItem';
import { AppFlowPlate } from './AppFlowPlate';
import { AppFlowBreadcrumbs } from './AppFlowBreadcrumbs';
import { AppFlowSecondaryNav } from './AppFlowSecondaryNav';
import { AppFlowSecondaryNavItem } from './AppFlowSecondaryNavItem';
import { AppFlowNavConsoleLogo } from './AppFlowNavConsoleLogo';
import { AppFlowSecondaryNavDivider } from './AppFlowSecondaryNavDivider';

const AppFlow = {
  Content: AppFlowContent,
  Breadcrumbs: AppFlowBreadcrumbs,
  Nav: {
    Plate: AppFlowNav,
    Item: AppFlowNavItem,
    ConsoleLogo: AppFlowNavConsoleLogo,
  },
  SecondaryNav: {
    Plate: AppFlowSecondaryNav,
    Item: AppFlowSecondaryNavItem,
    Divider: AppFlowSecondaryNavDivider,
  },
  Plate: AppFlowPlate,
};

export { AppFlow };
