// @flow
// $FlowFixMe
import React, { useCallback, useMemo } from 'react';
import { Text, Radio, RadioGroupField, Column, Row, Tooltip } from '@8base/boost';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { COLOR_BY_PLAN_NAME, PLANS } from 'graphql/constants/billing';

const RadioGroupScrollableTag = styled.div`
  position: relative;
  overflow: auto;
  width: 100%;
  padding-top: 10px;
  top: -10px;
`;

const PlanRadioItemTag = styled.div`
  position: relative;
  padding: 24px;
  border: 1px solid;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  border-radius: 5px;
  border-color: ${(props) => props.active ? props.theme.COLORS.PRIMARY : props.theme.COLORS.PRIMARY_BORDER_COLOR};
  background-color: ${(props) => props.disabled ? props.theme.COLORS.DISABLED_COLOR : props.theme.COLORS.TRANSPARENT};
`;

const PlanRadioItemPopularBannerWrapper = styled.div`
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlanRadioItemPopularBanner = styled.div`
  height: 16px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props => props.theme.SIZES.MAIN_BORDER_RADIUS};
  background-color: ${props => props.theme.COLORS.PRIMARY};
`;

const PlanRadioItem = ({ active, disabled, disabledTooltipText, children }) => {
  const itemTag = <PlanRadioItemTag active={ active } disabled={ disabled }>{ children }</PlanRadioItemTag>;

  if (disabled) {
    return (
      <Tooltip
        placement="right"
        css={ css`height: 100%; flex: 1 1 0` }
        message={ (
          <div css={ css`width: 150px;` }>
            { disabledTooltipText }
          </div>
        ) }
        cursor="default"
      >
        { itemTag }
      </Tooltip>
    );
  }

  return itemTag;
};

const PlanRadioItemLabel = ({ name, displayName, description, price }) => (
  <Column css={{ marginTop: -2, position: 'relative' }}>
    <Column>
      <Row>
        <Text weight="semibold">${ price }</Text>
        <Text color="SECONDARY_TEXT_COLOR">/ mo.</Text>
      </Row>
      <Text color={ COLOR_BY_PLAN_NAME[name] } weight="semibold" css={{ textTransform: 'uppercase' }}>{ displayName }</Text>
    </Column>
    <Text color="SECONDARY_TEXT_COLOR">{ description }</Text>
  </Column>
);

const getAvailablePlans = (plans, with8base, isFreePlanAvailable) => {
  let availablePlans = plans;

  if (with8base) {
    availablePlans = [...availablePlans, {
      name: '8base',
      displayName: '8base',
      price: 0,
      id: '8base',
      description: 'Unlimited plan for the 8base team.',
    }];
  }

  if (!isFreePlanAvailable) {
    availablePlans = availablePlans.filter(({ name }) => name !== PLANS.freeTier);
  }

  return availablePlans;
};

export const BillingPlanRadioGroupField = ({ plans, isFreePlanAvailable, card, with8base, input, ...props }: *) => {

  const availablePlans = useMemo(() => {
    return getAvailablePlans(plans, with8base, isFreePlanAvailable);
  }, [isFreePlanAvailable, plans, with8base]);

  const getIsDisabled = useCallback((planName: string) => {
    return planName !== PLANS.freeTier && planName !== '8base' && !card;
  }, [card]);

  const getDisabledTooltipText = useCallback((planName: string) => {
    if (planName !== PLANS.freeTier) {
      return 'Update your payment details by clicking on the link below.';
    }
  }, []);

  const onChange = useCallback(value => {
    const plan = availablePlans.find(({ id }) => id === value) || {};
    const isPlanDisabled = getIsDisabled(plan.name);

    if (!isPlanDisabled) {
      input && input.onChange(value);
    }

  }, [availablePlans, getIsDisabled, input]);

  return (
    <RadioGroupScrollableTag>
      <RadioGroupField
        { ...props }
        input={{ ...input, onChange }}
        direction="row"
        gap="md"
        alignItems="stretch"
        stretch
      >
        { availablePlans.map(({ id, name, displayName, description, price }) => (
          <Radio.Item
            key={ id }
            value={ id }
            css={ css`flex: 1 1 0; min-width: 200px; height: 100%;` }
            { ...E2E.$props(`dialogs.workspaceCreate.plan[${name}]`) }
          >
            { ({ checked }) => (
              <PlanRadioItem
                active={ checked }
                disabled={ getIsDisabled(name) }
                disabledTooltipText={ getDisabledTooltipText(name) }
              >
                <PlanRadioItemLabel
                  name={ name }
                  displayName={ displayName }
                  description={ description }
                  price={ price }
                />
                <If condition={ name === PLANS.hobbyist }>
                  <PlanRadioItemPopularBannerWrapper>
                    <PlanRadioItemPopularBanner>
                      <Text kind="overline-2" color="WHITE">Popular</Text>
                    </PlanRadioItemPopularBanner>
                  </PlanRadioItemPopularBannerWrapper>
                </If>
              </PlanRadioItem>
            ) }
          </Radio.Item>
        )) }
      </RadioGroupField>
    </RadioGroupScrollableTag>
  );
};
