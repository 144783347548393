// @flow

import * as R from 'ramda';
import fp from 'lodash/fp';
import uniqBy from 'lodash/uniqBy';
import { createReducer } from 'utils/customReduxAct';
import * as actions from './dataBuilder.actions';

type ColumnSettings = Array<{
  fieldName: string,
  label: string,
  checked?: boolean,
  defaultWidth?: number,
}>;

type DataViewerRules = {
  filter: { list: Array<{ fieldId: string, rule: string, cond: string }>},
  sort: { list: Array<{ fieldId: string, orderBy: 'ASC' | 'DESC' }>},
  columnSettings: ColumnSettings,
  search: string,
};

type DataBuilderState = {|
  showDataLossWarning: boolean,
  shouldAutoselectTableName: boolean,
  dataViewer: { [key: string]: DataViewerRules }
|};

const defaultState: DataBuilderState = {
  showDataLossWarning: false,
  shouldAutoselectTableName: false,
  dataViewer: {},
};

const DEFAULT_COLUMN_WIDTH = 250;

const dataBuilderReducer = createReducer({}, defaultState)
  .onc(
    actions.enableTableNameAutoselection,
    () => fp.set('shouldAutoselectTableName', true),
  )
  .onc(
    actions.disableTableNameAutoselection,
    () => fp.set('shouldAutoselectTableName', false),
  )
  .onc(
    actions.setTableViewingControlsData,
    ({ tableId, rules }) => fp.pipe(
      fp.set(['dataViewer', tableId, 'filter'], rules.filter),
      fp.set(['dataViewer', tableId, 'sort'], rules.sort),
      fp.set(['dataViewer', tableId, 'search'], rules.search),
    ),
  )
  .onc(
    actions.addTableViewingSort,
    ({ tableId, sortRules = [] }) => fp.pipe(
      fp.update(['dataViewer', tableId, 'sort', 'list'], (value = []) =>
        uniqBy([...sortRules, ...value], 'fieldId'),
      ),
    ),
  )
  .onc(
    actions.updateTableViewingColumnSettings,
    ({ tableId, columnSettings }) => state => {
      const currentColumnSettings = R.path(['dataViewer', tableId, 'columnSettings'], state);

      const updatedColumnSettings = R.map(
        (currentColumn) => {
          const newColumn = R.find(({ fieldName }) => fieldName === currentColumn.fieldName, columnSettings) || {};

          return {
            ...currentColumn,
            ...newColumn,
          };
        },
        currentColumnSettings,
      );

      return fp.set(['dataViewer', tableId, 'columnSettings'], updatedColumnSettings, state);
    },
  )
  .onc(
    actions.setTableViewingColumnSettings,
    ({ tableId, columnSettings }) => state => {
      const currentColumnSettings = R.path(['dataViewer', tableId, 'columnSettings'], state);

      const updatedColumnSettings = R.map(
        (newColumn) => {
          const currentColumn = R.find(({ fieldName }) => fieldName === newColumn.fieldName, currentColumnSettings) || {};

          return {
            ...currentColumn,
            ...newColumn,
          };
        },
        columnSettings,
      );

      return fp.set(['dataViewer', tableId, 'columnSettings'], updatedColumnSettings, state);
    },
  )
  .onc(
    actions.initTableViewingColumns,
    ({ tableId, columns }) => state => {
      const columnSettingsPath = ['dataViewer', tableId, 'columnSettings'];
      const columnSortPath = ['dataViewer', tableId, 'sort', 'list'];
      const columnFilterPath = ['dataViewer', tableId, 'filter', 'list'];
      const disabledColumnsByDefault = ['createdAt', 'updatedAt', 'createdBy'];

      const columnSettings = columns.map(({ fieldName, label, checked }) => {
        const getExistCheckedOrTrue = fp.pipe(
          fp.get(columnSettingsPath),
          fp.find(fp.propEq('fieldName', fieldName)),
          fp.get('checked'),
          (checked) => fp.isNil(checked) && fp.includes(fieldName, disabledColumnsByDefault) ? false : checked,
          (checked) => fp.isNil(checked) ? true : checked,
        );

        const newChecked = typeof checked === 'boolean' ? checked : getExistCheckedOrTrue(state);

        return { fieldName, label, checked: newChecked, defaultWidth: DEFAULT_COLUMN_WIDTH };
      });

      const columnSortList = fp.pipe(
        fp.get(columnSortPath),
        fp.filter(({ fieldId }) => columnSettings.find(fp.propEq('id', fieldId))),
      )(state);

      const columnFilterList = fp.pipe(
        fp.get(columnFilterPath),
        fp.filter(({ fieldId }) => columnSettings.find(fp.propEq('id', fieldId))),
      )(state);

      return fp.pipe(
        fp.set(columnSettingsPath, columnSettings),
        fp.set(columnSortPath, columnSortList),
        fp.set(columnFilterPath, columnFilterList),
      )(state);
    },
  )
  .onc(
    actions.clearTableVieweingControlsData,
    (tableId) => fp.pipe(
      fp.set(['dataViewer', tableId], {}),
    ),
  );

export { dataBuilderReducer, defaultState };
export type { DataBuilderState, ColumnSettings, DataViewerRules };
