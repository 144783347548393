import fp from 'lodash/fp';
import { createSelector, type Selector } from 'reselect';

import fpUtils from 'utils/fp';

export type ApolloInvitationDetails = $Shape<{|
  uuid: string,
  firstName: string,
  lastName: string,
  email: string,
  isRegistered: boolean,
  apiHost?: string,
  invitedBy: $Shape<{|
    firstName: string,
    lastName: string,
    workspaceName: string,
  |}>
|}>

export const getInvitationDetails = (invitationDetails: ApolloInvitationDetails): ApolloInvitationDetails => invitationDetails;

export const getInviteId: Selector<*, void, string> = createSelector(
  getInvitationDetails,
  fp.get('id'),
);

export const getInvitedBy: Selector<*, void, $PropertyType<ApolloInvitationDetails, 'invitedBy'>> = createSelector(
  getInvitationDetails,
  fp.getOr({}, 'invitedBy'),
);

export const getInvitationDetailsFirstName: Selector<*, void, string> = createSelector(
  getInvitationDetails,
  fpUtils.safeGet('firstName'),
);

export const getInvitationDetailsLastName: Selector<*, void, string> = createSelector(
  getInvitationDetails,
  fpUtils.safeGet('lastName'),
);

export const getInvitationDetailsFullName: Selector<*, void, string> = createSelector(
  getInvitationDetailsFirstName,
  getInvitationDetailsLastName,
  (firstName, lastName) => `${firstName} ${lastName}`,
);

export const getInvitedByFullName: Selector<*, void, string> = createSelector(
  getInvitedBy,
  ({ firstName, lastName }) => `${firstName || ''} ${lastName || ''}`.trim(),
);

export const getInvitedByEmail: Selector<*, void, string> = createSelector(
  getInvitedBy,
  ({ email }) => email,
);

export const getWorkspaceName = createSelector(
  getInvitedBy,
  fp.get('workspaceName'),
);

export const getInvitationDetailsEmail: Selector<*, void, string> = createSelector(
  getInvitationDetails,
  fpUtils.safeGet('email'),
);

export const isInvitedUserRegistered: Selector<*, void, boolean> = createSelector(
  getInvitationDetails,
  fpUtils.safeGet('isRegistered'),
);

export const getInviteApiHost = createSelector(
  getInvitationDetails,
  fpUtils.safeGet('apiHost'),
);
