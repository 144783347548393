import React from 'react';
import { compose } from 'recompose';
import { Dialog, Button, Input, ModalContext, Row, Column, Text } from '@8base/boost';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import { Trans } from 'utils/translate';
import { withWorkspace, type WorkspaceContextProps } from 'providers/WorkspaceProvider';
import { FS, FULLSTORY_EVENT_TYPES } from 'utils/fullStory';
import { getEndpoint } from 'utils';

const ENDPOINT_DIALOG_ID = 'ENDPOINT_DIALOG_ID';

type EndpointDialogProps = {
  workspace: WorkspaceContextProps,
  t: Function,
};

class EndpointDialog extends React.Component<EndpointDialogProps> {
  static contextType = ModalContext;

  endpointInput: HTMLInputElement;

  setInputRef = (ref: any) => {
    this.endpointInput = ref;
  };

  copyUrl = () => {
    const { t } = this.props;

    this.endpointInput.focus();
    this.endpointInput.select();

    document.execCommand('Copy');

    toast.success(t('dataBuilder.apiEndpointSuccess'));

    window.trackEvent('Data Builder', 'Copy endpoint');

    FS.event(FULLSTORY_EVENT_TYPES.copyEndpoint, {
      endpoint_url: this.endpointInput.value,
    });
  };

  onFocus = () => {
    this.endpointInput.select();
  };

  onClose = () => {
    this.context.closeModal(ENDPOINT_DIALOG_ID);
  };

  render() {
    const { t, workspace } = this.props;

    return (
      <Dialog id={ ENDPOINT_DIALOG_ID } size="md" onClose={ this.onClose }>
        <Dialog.Header title={ t('dataBuilder.apiEndpoint') } onClose={ this.onClose } />
        <Dialog.Body>
          <Column>
            <Row>
              <Text align="center">
                <Trans i18nKey="dataBuilder.pleaseCopyEndpoint">
                  Please copy the endpoint URL and paste it into your app client code.
                </Trans>
              </Text>
            </Row>
            <Row stretch>
              <Input
                onFocus={ this.onFocus }
                value={ getEndpoint(workspace.id, { baseUrl: workspace.apiHost }) }
                insideRef={ this.setInputRef }
                readOnly
                stretch
              />
              <Button onClick={ this.copyUrl } size="sm">
                <Trans i18nKey="shared.copy">Copy</Trans>
              </Button>
            </Row>
          </Column>
        </Dialog.Body>
      </Dialog>
    );
  }
}

EndpointDialog = compose(
  withTranslation(),
  withWorkspace,
)(EndpointDialog);

EndpointDialog.ID = ENDPOINT_DIALOG_ID;

export { EndpointDialog };
