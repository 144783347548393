// @flow

import React from 'react';
import { Icon, Checkbox, Tooltip } from '@8base/boost';

import './TableWorker.scss';
import { useTranslation } from 'react-i18next';


type TableIndexCellProps = {|
  withEditRow?: boolean,
  withSelection?: boolean,
  width: number,
  style: Object,
  index: number,
  isSelected: boolean,
  isHovered: boolean,
  onSelectChange: (selected: boolean) => void,
  onEditClick: () => void,
  onMouseEnter: () => void,
  onMouseLeave: () => void,
|};

export const RowIndexCell =
  ({
    index, width, isSelected, onSelectChange, onEditClick,
    style, isHovered, onMouseEnter, onMouseLeave,
    withEditRow = false, withSelection = false,
  }: TableIndexCellProps) => {
    const { t } = useTranslation();

    return (
      <div>
        <div
          styleName="row-index-cell"
          style={{ ...style, width }}
          onMouseEnter={ onMouseEnter }
          onMouseLeave={ onMouseLeave }
        >
          <div styleName="row-index">
            <Choose>
              <When condition={ (isHovered || isSelected) && withSelection }>
                <Checkbox
                  name={ `row-${index}-checbox` }
                  checked={ isSelected }
                  onChange={ onSelectChange }
                  { ...E2E.$props('table.row.select') }
                />
              </When>
              <Otherwise>
                <div styleName="row-index-text">{ index }</div>
              </Otherwise>
            </Choose>
          </div>
          <div styleName="row-edit">
            <If condition={ isHovered && withEditRow }>
              <Tooltip modifiers={{ offset: { offset: '0, 4' }}} message={ t('shared.edit') }>
                <div styleName="row-edit-image" onClick={ onEditClick } { ...E2E.$props('table.row.edit') }>
                  <Icon name="EditPencil" color="LIGHT_TEXT_COLOR">
                    { (Glyph) => (
                      <Glyph title="Edit row" />
                    ) }
                  </Icon>
                </div>
              </Tooltip>
            </If>
          </div>
        </div>
      </div>
    );
  };

