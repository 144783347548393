// @flow

import React from 'react';
import { Icon, Tooltip } from '@8base/boost';

import './TableWorker.scss';
import { useTranslation } from 'react-i18next';

type AddRowProps = {
  onAddRow?: () => void,
  width?: number,
};

export const AddRow = ({ onAddRow, width }: AddRowProps) => {
  const { t } = useTranslation();

  return (
    <div styleName="add-row" style={{ minWidth: width }}>
      <div styleName="add-row-icon-wrapper" { ...PT.$props('dataViewer.table.addBtn') }>
        <Tooltip
          modifiers={{ offset: { offset: '0, 4' }}}
          message={ t('data.viewer.addRecord') }
        >
          <Icon cursor="pointer" name="CircleAdd" size="sm" color="PRIMARY" onClick={ onAddRow } />
        </Tooltip>
      </div>
    </div>
  );
};
