import React from 'react';
import { css } from '@emotion/core';
import { register } from 'serviceWorker';

const className = css`
  align-items: center;
  background-color: #1C252E;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1), 0 2px 4px 0 rgba(0,0,0,0.5);
  color: #F4F7F9;
  display: flex;
  flex-direction: row;
  font-family: Poppins;
  font-size: 12px;
  height: 4rem;
  justify-content: center;
  left: 50%;
  line-height: 19px;
  padding: 0 2rem;
  position: fixed;
  top: 0;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 2147483647;
`;

const noteClassName = css`
  margin-right: 2rem;
`;

const actionClassName = css`
  color: #4DA1FF;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.7px;
  line-height: 19px;
  text-decoration: underline;
  text-transform: uppercase;
`;


const MAX_SHOW_REFRESH_NOTIFICATION_ATTEMPTS = 100;
const SHOW_REFRESH_NOTIFICATION_TIMEOUT = 200;

function showRefreshNotification(registration, attempt) {
  attempt = attempt || 1;

  if (typeof window.showRefreshNotification === 'function') {
    window.showRefreshNotification(registration);
  } else if (attempt < MAX_SHOW_REFRESH_NOTIFICATION_ATTEMPTS) {
    setTimeout(() => {
      showRefreshNotification(registration, ++attempt);
    }, attempt ? attempt * SHOW_REFRESH_NOTIFICATION_TIMEOUT : SHOW_REFRESH_NOTIFICATION_TIMEOUT);
  }
}

let refreshing = false;

class RefreshNotification extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isShowing: false };
  }

  componentDidMount() {
    window.showRefreshNotification = this.show;

    const updateApp = (registration) => {
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          if (refreshing) {
            return;
          }

          refreshing = true;

          window.location.reload();
        });

        showRefreshNotification(registration);
      }
    };

    if (navigator && navigator.serviceWorker) {
      navigator.serviceWorker
        .getRegistration()
        .then((registration) => {
          const waitingWorker = registration && registration.waiting;
          if (!waitingWorker) {
            return;
          }

          if (waitingWorker.state === 'installed') {
            updateApp(registration);
            return;
          }

          waitingWorker.onstatechange = () => {
            if (waitingWorker.state === 'installed') {
              updateApp(registration);
            }
          };
        });


      register({
        onUpdate: registration => {
          updateApp(registration);
        },
      });
    }
  }

  show = (registration) => {
    this.registration = registration || this.registration;

    this.setState({ isShowing: true });
  };

  onReload = () => {
    if (this.registration && this.registration.waiting) {
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
  }

  render() {
    const { isShowing } = this.state;

    let rendered = null;

    if (isShowing) {
      rendered = (
        <div css={ className }>
          <div css={ noteClassName }>
            A new version of 8base is available. Make sure to save changes before reloading the page.
          </div>
          <div>
            <button css={ actionClassName } onClick={ this.onReload }>Reload</button>
          </div>
        </div>
      );
    }

    return rendered;
  }
}

export { RefreshNotification };
