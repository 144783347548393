import React from 'react';
import { Navigation, Tooltip } from '@8base/boost';
import { css } from '@emotion/core';

import { NavLink } from 'common/router';

type AppFlowNavItemProps = {
  parentExpanded: boolean,
  label: React$Node,
};

const AppFlowNavItem = ({ parentExpanded, label, ...rest }: AppFlowNavItemProps) => {
  let item = (
    <Navigation.Item
      css={ css`&.active:before { content: ""; width: 4px; height: 100%; background: #FFF; position: absolute; left: 0; top: 0; border-radius: 2px; }` }
      tagName={ NavLink }
      parentExpanded={ parentExpanded }
      label={ label }
      { ...rest }
    />
  );

  if (!parentExpanded) {
    item = (
      <Tooltip placement="right" message={ label } cursor="default">
        { item }
      </Tooltip>
    );
  }

  return item;
};

export { AppFlowNavItem };
