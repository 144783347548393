import * as R from 'ramda';

export const trackEvent = (category: string, action: string, label?: string, value?: number) => {
  const { dataLayer } = window;

  let event = {
    event: 'trackEvent',
    eventCategory: category,
    eventAction: action,
  };

  if (!R.isNil(label)) {
    event = R.assoc('eventLabel', label, event);
  }

  if (!R.isNil(value)) {
    event = R.assoc('eventValue', value, event);
  }

  if (Array.isArray(dataLayer)) {
    dataLayer.push(event);
  }
};
