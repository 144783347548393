import React from 'react';
import * as R from 'ramda';
import { css } from '@emotion/core';
import { Text, Row, Column, Tag } from '@8base/boost';

import { BILLING_PLAN_STATUS } from 'common/constants/billing';

import { PlanName } from './PlanName';

const COLOR_BY_STATUS = {
  [BILLING_PLAN_STATUS.ACTIVE]: 'BLUE',
  [BILLING_PLAN_STATUS.CANCELING]: 'YELLOW',
  [BILLING_PLAN_STATUS.SUSPENDED]: 'YELLOW',
  [BILLING_PLAN_STATUS.CANCELED]: 'RED',
  [BILLING_PLAN_STATUS.BLOCKED]: 'RED',
  [BILLING_PLAN_STATUS.PENDING]: 'BLUE',
  [BILLING_PLAN_STATUS.UPCOMING]: 'GREEN',
};

export const PlanCard = ({ plan, status, workspaceName, ...rest }) => {

  return (
    <Row css={ css`border: 1px solid #D0D7DD; border-radius: 5px; padding: 24px; width: 100%;` } justifyContent="between" { ...rest }>
      <Column gap="none">
        <PlanName plan={ plan } />
        <Text weight="semibold" css={{ fontSize: 16 }} { ...E2E.$props('planCard.workspaceName') }>
          { workspaceName }
        </Text>
      </Column>
      <Row alignItems="center">
        <If condition={ status }>
          <Tag color={ COLOR_BY_STATUS[status] } condensed>
            <Choose>
              <When condition={ status === BILLING_PLAN_STATUS.PENDING || status === BILLING_PLAN_STATUS.ACTIVE }>Current Plan</When>
              <When condition={ status === BILLING_PLAN_STATUS.UPCOMING }>Upcoming Plan</When>
              <Otherwise>{ status }</Otherwise>
            </Choose>
          </Tag>
        </If>
        <Text weight="semibold">${ R.prop('price', plan) }</Text>
        <If condition={ R.prop('price', plan) > 0 }>
          <Text color="SECONDARY_TEXT_COLOR">/ mo.</Text>
        </If>
      </Row>
    </Row>
  );

};
