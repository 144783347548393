import gql from 'graphql-tag';
import { ORGANIZATION_SHORT_INFO_FRAGMENT } from '../fragments/organizations';

export const ORGANIZATION_UPDATE_MUTATION = gql`
  mutation OrganizationUpdate($id: String!, $name: String, $description: String, $image: GraphQLCreateFileItemInput) {
    system {
      organizationUpdate(id: $id, name: $name description: $description, image: $image) {
        ...OrganizationItemShortInfo
      }
    }
  }

  ${ORGANIZATION_SHORT_INFO_FRAGMENT}
`;

export const ORGANIZATION_USER_UPDATE_MUTATION = gql`
  mutation OrganizationUserUpdate($organizationId: ID!, $email: String!, $role: String!) {
    system {
      organizationUserUpdate(organizationId: $organizationId, email: $email, role: $role) {
        id
        email
        firstName
        lastName
        role
        status
        avatar {
          id
          downloadUrl
        }
      }
    }
  }
`;
