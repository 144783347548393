// @flow
// $FlowFixMe waiting for update Flow
import React, { useCallback, useMemo } from 'react';
import {
  Form,
  Button,
  InputField,
  Row,
  Column,
  Card,
  Text,
  TextAreaField,
  SelectField,
  Avatar,
  Heading,
} from '@8base/boost';
import { css } from '@emotion/core';
import { useMutation } from 'react-apollo';
// $FlowFixMe
import { useParams } from 'react-router-dom';
import { Form as FormLogic, Field } from '@8base-react/forms';
import * as R from 'ramda';
import { i18n } from 'i18n';

import { ORGANIZATION_ROLE, ORGANIZATION_TYPES } from 'common/constants/organization';
import { IGNORE_WORKSPACE, TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';
import { CopyInput } from 'common/components/CopyInput';
import { WorkspaceImageInput as OrganizationImageInput } from 'common/components/WorkspaceImageInput';
import { useOrganization, useUserAccountInfo } from 'graphql/hooks';
import { ORGANIZATION_UPDATE_MUTATION } from 'graphql/mutations/organization';
import { Trans } from 'utils/translate';
import { maxLength, composeValidators, requiredWithSpaces } from 'utils/formValidations';
import { getUserRoleAtOrganization } from 'utils/organization';

export const OrganizationGeneral = () => {

  const { organizationId } = useParams();
  const { organization } = useOrganization(organizationId);

  const onCancel = useCallback((form) => () => {
    if (!form) {
      return;
    }
    form.reset();
  }, []);

  const [update] = useMutation(ORGANIZATION_UPDATE_MUTATION, {
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('shared.dataUpdateSuccessful'),
    },
  });

  const onSubmit = useCallback(async(data) => {
    const description = (data.description || '').trim();

    const { image } = data;

    const getNewImage = () => {
      if (image && R.pathOr('', ['image'], organization)) {
        if (image.downloadUrl === R.pathOr('', ['image', 'downloadUrl'], organization)) {
          return undefined;
        }
      }

      if (image && image.fileId) {
        return {
          create: {
            fileId: image.fileId,
            filename: image.filename,
            public: true,
          },
        };
      }

      return undefined;
    };

    const imageData = getNewImage();

    await update({
      variables: {
        id: organizationId,
        name: data.name,
        description,
        image: imageData,
      },
    });
  }, [organization, organizationId, update]);

  const { userAccountInfo } = useUserAccountInfo({
    notifyOnNetworkStatusChange: false,
    context: {
      [IGNORE_WORKSPACE]: true,
      noBatch: true,
    },
    fetchPolicy: 'cache-first',
  });

  const permissionRole = useMemo(() =>
    getUserRoleAtOrganization(userAccountInfo, organization),
  [organization, userAccountInfo]);

  const readOnly = useMemo(() => {
    return (permissionRole !== ORGANIZATION_ROLE.admin && permissionRole !== ORGANIZATION_ROLE.owner);
  }, [permissionRole]);

  const categoryOptions = R.values(ORGANIZATION_TYPES).map((role) => ({ value: role, label: role }));

  const organizationUsers = R.pathOr([], ['users'], organization);
  const organizationType = R.pathOr('', ['type'], organization);
  const selectedOrganizationType = R.pathOr({}, [0], R.filter(R.pathEq(['value'], ORGANIZATION_TYPES[organizationType]), categoryOptions));

  const organizationOwner = R.find(R.propEq('role', ORGANIZATION_ROLE.owner))(organizationUsers);

  const userAvatarDownloadUrl = R.pathOr('', ['avatar', 'downloadUrl'], organizationOwner);
  const userFirstName = R.pathOr('', ['firstName'], organizationOwner);
  const userLastName = R.pathOr('', ['lastName'], organizationOwner);

  return (
    <FormLogic
      onSubmit={ onSubmit }
      initialValues={{
        name: R.pathOr('', ['name'], organization),
        description: R.pathOr('', ['description'], organization),
        image: R.pathOr('', ['image'], organization),
      }}
    >
      { ({ handleSubmit, invalid, pristine, submitting, form }) => (
        <Column gap="md" css={ css`height: 100%;` }>
          <Text kind="h1">General</Text>
          <Card stretch tagName="form" onSubmit={ handleSubmit }>
            <Card.Header>
              <Heading type="h2">
                <Trans i18nKey="organization.general.basicInfo">
                  Basic Information
                </Trans>
              </Heading>
            </Card.Header>
            <Card.Body css={ css`height: 100%;` }>
              <Row stretch gap="lg" >
                <Column>
                  <Field
                    name="image"
                    disabled={ readOnly }
                    component={ OrganizationImageInput }
                    size="xl"
                  />
                </Column>
                <Form css={ css` width: 100%; max-width: 600px;` } >
                  <Column stretch gap="lg">
                    <Field
                      name="name"
                      label="Organization Name"
                      placeholder="Organization Name"
                      component={ InputField }
                      disabled={ readOnly }
                      validate={ composeValidators(requiredWithSpaces, maxLength(101)) }
                      stretch
                    />
                    <Field
                      name="description"
                      label="Description"
                      placeholder="Please tell us about your Organization"
                      component={ TextAreaField }
                      disabled={ readOnly }
                      validate={ maxLength(1000) }
                      stretch
                      rows="6"
                      css={ css`resize: vertical;` }
                    />
                    <Field
                      name="category"
                      label="Category"
                      placeholder={ i18n.t('shared.select') }
                      options={ categoryOptions }
                      input={ selectedOrganizationType }
                      disabled
                      component={ SelectField }
                    />
                    <CopyInput
                      value={ organizationId }
                      label="Organization ID"
                      toastMessage="Organization ID successfully copied to the clipboard"
                      tooltipMessage="Copy Organization ID"
                    />


                    <Row alignItems="center">
                      <Column gap="sm">
                        <Text kind="overline-1">
                          <Trans i18nKey="shared.organizationOwner">Organization Owner</Trans>
                        </Text>
                        <Row alignItems="center">
                          <Avatar css={ css`width: 40px; height: 40px;` } firstName={ userFirstName } lastName={ userLastName } src={ userAvatarDownloadUrl } />
                          <Text color="BLACK">{ `${userFirstName} ${userLastName}` }</Text>
                        </Row>
                      </Column>
                    </Row>
                  </Column>
                </Form>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Row justifyContent="end" >
                <Column>
                  <Button
                    type="button"
                    color="neutral"
                    variant="outlined"
                    disabled={ submitting || readOnly || pristine }
                    onClick={ onCancel(form) }
                  >
                    { i18n.t('shared.cancel', { defaultValue: 'Cancel' }) }
                  </Button>
                </Column>

                <Column>
                  <Button
                    type="submit"
                    disabled={ invalid || pristine || readOnly }
                    loading={ submitting }
                    { ...E2E.$props('dialogs.workspaceUpdate.saveChangesBtn') }
                  >
                    { i18n.t('shared.saveChanges') }
                  </Button>
                </Column>
              </Row>
            </Card.Footer>
          </Card>
        </Column>
      ) }
    </FormLogic>
  );
};
