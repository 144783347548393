// @flow
import _ from 'lodash';
import fp from 'lodash/fp';

import { type CommonApolloData } from '../commonApoloData.selectors';

type ChangeArg =
  <ArgState, ArgProps, Result>((arg: ArgState, props: ArgProps) => Result) => ((CommonApolloData | void, props: ArgProps) => Result)

type SpecifiedSelectors<S> = $ObjMap<S, ChangeArg>;

type QueryResult<QueryName: string, SelectorData: {}> = {
  data?: {
    loading: boolean,
    [QueryName]: SelectorData,
  }
}

type MutateResult<MutateName: string> = {
  [MutateName]: ((data: Object) => void) | (data: Object) => Promise<void>,
}

const specifySelectorPath = <S: Object>(selectors: S, name: string): SpecifiedSelectors<S> => {
  const specifiedSelectors: any = _.reduce(
    selectors,
    (accum, selector, selectorName) => {
      accum[selectorName] = (apolloData, props) => selector(fp.get([name], apolloData), props);
      return accum;
    },
    {},
  );

  return specifiedSelectors;
};

export type {
  QueryResult,
  MutateResult,
};

export {
  specifySelectorPath,
};
