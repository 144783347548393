// @flow
// $FlowFixMe
import React, { useCallback, useMemo } from 'react';
// $FlowFixMe
import { AsyncContent, useModal } from '@8base/boost';
// $FlowFixMe
import { Redirect, useParams, useHistory, useLocation } from 'react-router-dom';
import { APP_URL, useBuildUrl } from 'common';
import * as R from 'ramda';

import { useWorkspacesList, useOrganization, useUserAccountInfo, useOrganizationPaymentDetails } from 'graphql/hooks';
import { DeveloperHomeContentPlate } from 'routes/developer-home/components';
import { TeamMemberInviteToOrganizationDialog } from 'dialogs/TeamMemberInviteToOrganizationDialog';
import { TeamMemberRemoveFromOrganizationDialog } from 'dialogs/TeamMemberRemoveFromOrganizationDialog';
import { WorkspaceCreateDialog } from 'dialogs/WorkspaceCreateDialog';
import { getUserRoleAtOrganization, getUserListFromOrganization } from 'utils/organization';
import { IGNORE_WORKSPACE } from 'common/constants/apolloOperationContextOptions';
import { useUserWorkspacesOfOrganization } from '../utils';

import { DeveloperHomeOrganization } from '../components/DeveloperHomeOrganization';

// Types Definitions
import type { OpenWorkspaceHandler } from 'utils/hooks/useOnOpenWorkspace';

type DeveloperHomeOrganizationContainerProps = {
  onOpenWorkspaceClick: OpenWorkspaceHandler
}

/**
 * Organization details page
 * URL like /developer-home/organizations/:organizationId, also look at APP_URL.developerHomeOrganization
 */
export const DeveloperHomeOrganizationContainer = ({ onOpenWorkspaceClick }: DeveloperHomeOrganizationContainerProps) => {
  const { organizationId } = useParams();
  const { openModal } = useModal();
  const history = useHistory();
  const buildUrl = useBuildUrl();
  const location = useLocation();

  //Preload organization payment details(card)
  useOrganizationPaymentDetails(organizationId);

  const { workspacesList, workspacesFrontendList, loading } = useWorkspacesList();
  const { organization, loading: organizationLoading } = useOrganization(organizationId, {
    fetchPolicy: 'cache-and-network',
  });
  const { userAccountInfo } = useUserAccountInfo({
    notifyOnNetworkStatusChange: false,
    context: {
      [IGNORE_WORKSPACE]: true,
      noBatch: true,
    },
    fetchPolicy: 'cache-first',
  });

  const userRoleAtOrganization = useMemo(() =>
    getUserRoleAtOrganization(userAccountInfo, organization),
  [organization, userAccountInfo]);

  const userWorkspaceList = useUserWorkspacesOfOrganization({ organization, workspacesList, userAccountInfo });

  const onRemoveFromOrganization = useCallback(() => {
    openModal(TeamMemberRemoveFromOrganizationDialog.ID, {
      organizationName: R.pathOr('', ['name'], organization),
      organizationId,
      userWorkspaceList,
      firstName: userAccountInfo.firstName,
      lastName: userAccountInfo.lastName,
      email: userAccountInfo.email,
    });
  }, [userAccountInfo, openModal, organizationId, organization, userWorkspaceList]);


  const onUserClick = useCallback(() => {
    history.push(buildUrl(APP_URL.organizationSettingsTeam, { pathParams: { organizationId }}), { prevLocation: location });
  }, [buildUrl, history, organizationId, location]);

  const onAddUserClick = useCallback(() => {
    if (!organization || !organization.id) {
      return null;
    }

    openModal(TeamMemberInviteToOrganizationDialog.ID, { organizationId: organization.id, permissionRole: userRoleAtOrganization });
  }, [openModal, organization, userRoleAtOrganization]);


  const onClickSettings = useCallback(
    () => {
      history.push(buildUrl(APP_URL.organizationSettingsGeneral, { pathParams: { organizationId }}), { prevLocation: location });
    },
    [buildUrl, history, organizationId, location],
  );

  const onCreateWorkspaceClick = useCallback((kind?: string) => {
    if (!organizationId) {
      return null;
    }

    openModal(WorkspaceCreateDialog.ID, { organizationId, kind });
  }, [openModal, organizationId]);


  const userList = useMemo(() => getUserListFromOrganization(organization), [organization]);

  return (
    <AsyncContent
      stretch
      loading={ (
        loading ||
        (!organization && organizationLoading) ||
        (organization && organization.id !== organizationId && organizationLoading)
      ) }
    >
      <Choose>
        <When condition={ organizationId && !!organization }>
          { organizationId && !!organization && (
            <DeveloperHomeContentPlate { ...E2E.$props('developerHome.workspaces.root') } >
              <DeveloperHomeOrganization
                name={ organization.name }
                type={ organization.type }
                image={ organization.image && organization.image.downloadUrl }
                description={ organization.description }
                onOpenWorkspaceClick={ onOpenWorkspaceClick }
                onUserClick={ onUserClick }
                onAddUserClick={ onAddUserClick }
                onClickSettings={ onClickSettings }
                onCreateWorkspaceClick={ onCreateWorkspaceClick }
                userList={ userList }
                backendWorkspacesList={ workspacesList }
                frontendWorkspacesList={ workspacesFrontendList }
                permissionRole={ userRoleAtOrganization }
                onRemoveFromOrganization={ onRemoveFromOrganization }
              />
            </DeveloperHomeContentPlate>
          ) }

        </When>
        <Otherwise>
          <Redirect to={ APP_URL.developerHome } />
        </Otherwise>
      </Choose>
    </AsyncContent>
  );
};
