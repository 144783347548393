// @flow
// $FlowFixMe waiting for update Flow
import React, { useContext } from 'react';
import {
  Button,
  Row,
  Card,
  Text,
  AsyncContent,
  ModalContext,
  Table,
  Heading,
} from '@8base/boost';
// $FlowFixMe
import * as R from 'ramda';
// $FlowFixMe
import { useParams } from 'react-router-dom';
import { css } from '@emotion/core';

import { Trans } from 'utils/translate';
import { BankCardRow } from 'common/components';
import { useOrganizationPaymentDetails } from 'graphql/hooks';
import { OrganizationPaymentDetailsUpdateDialog } from 'dialogs/BillingUpdateDialog/OrganizationPaymentDetailsUpdateDialog';

/**
 * Organization payment method
 */
export const PaymentsDetails = () => {
  const { openModal } = useContext(ModalContext);
  const { organizationId } = useParams();

  const {
    card,
    loading,
  } = useOrganizationPaymentDetails(organizationId);

  // TODO: add shared component for this section (account-billing, workspace-billing, organization-billing)
  return (
    <Card>
      <Card.Header>
        <Row justifyContent="between" alignItems="center" stretch>
          <Heading type="h2" padding="none">
            <Trans i18nKey="settings.billing.paymentDetails.title">Payment Details</Trans>
          </Heading>
          <Button
            color="neutral"
            variant="outlined"
            onClick={ () => openModal(OrganizationPaymentDetailsUpdateDialog.ID, { organizationId }) }
            { ...E2E.$props('settings.billing.updatePaymentDetailsBtn') }
          >
            <Trans i18nKey="settings.billing.paymentDetails.buttonLabel">Update Payment Details</Trans>
          </Button>
        </Row>
      </Card.Header>
      <Card.Body padding="none">
        <AsyncContent loading={ loading } stretch>
          <Choose>
            <When condition={ card && card.last4 }>
              <Table>
                <Table.Body>
                  <Table.BodyRow columns="200px 1fr 2fr">
                    <Table.BodyCell>
                      <BankCardRow card={ card } />
                    </Table.BodyCell>
                    <Table.BodyCell { ...E2E.$props('settings.billing.cardNumber') }>
                        •••• •••• •••• { R.prop('last4', card) }
                    </Table.BodyCell>
                  </Table.BodyRow>
                </Table.Body>
              </Table>
            </When>
            <Otherwise>
              <Text color="LIGHT_TEXT_COLOR" css={ css`display: flex; justify-content: center; padding: 40px 0;` }>
                <Trans i18nKey="organization.billing.paymentDetails.emptyNote">
                  Organization doesn’t have a saved payment method, continue above to update your payment details
                </Trans>
              </Text>
            </Otherwise>
          </Choose>
        </AsyncContent>
      </Card.Body>
    </Card>
  );
};
