// @flow
// $FlowFixMe
import React, { useEffect, useState } from 'react';
// $FlowFixMe
import { Redirect, Route, Switch } from 'react-router-dom';
// $FlowFixMe
import { Row } from '@8base/boost';

import { APP_URL } from 'common/routing';
import { UserFlow } from 'common/UserFlow';
import { useOrganizationsList, useUserAccountInfo, useInboxList, useUndoneInboxCount } from 'graphql/hooks';
import { CommonFlowBars } from 'common/CommonFlow/CommonFlowBars';
import { useOnOpenWorkspace } from 'utils/hooks';

import { DeveloperHomeNav, DeveloperHomeNavContent, DeveloperHomeNavUserInfo, DeveloperHomePage, DeveloperHomeMainPlate } from './components';
import { DeveloperHomeHeaderSearchContainer } from './containers/DeveloperHomeHeaderSearch.container';

// Routes
import { DeveloperHomeWorkspacesContainer } from './routes/workspaces/containers/DeveloperHomeWorkspaces.container';
import { DeveloperHomeOrganizationContainer } from './routes/organizations/containers/DeveloperHomeOrganization.container';
import { DeveloperHomeInboxContainer } from './routes/inbox/containers/DeveloperHomeInbox.container';

const DeveloperHome = () => {
  const [isCompletedUserAccountInfo, setCompletedUserAccountInfo] = useState(false);
  const [isCompletedOrganizationList, setCompletedOrganizationList] = useState(false);

  useUserAccountInfo({
    onCompleted: () => {
      setCompletedUserAccountInfo(true);
    },
  });

  useOrganizationsList({
    onCompleted: () => {
      setCompletedOrganizationList(true);
    },
  });

  useEffect(() => {
    if (isCompletedUserAccountInfo && isCompletedOrganizationList) {
      requestAnimationFrame(window.htmlLoader.hide);
    }
  }, [isCompletedOrganizationList, isCompletedUserAccountInfo]);

  const onOpenWorkspaceClick = useOnOpenWorkspace();

  // need for refetch new undone inbox items
  const [oldUnDoneInboxCount, setOldInboxCount] = useState(0);
  const { count: undoneInboxCount, loading: undoneInboxCountLoading } = useUndoneInboxCount();

  const { refetch: refetchDoneList, loading: doneListLoading } = useInboxList({
    variables: {
      filter: { isCompleted: { equals: 1 },
      }},
  });
  const { refetch: refetchUndoneList, loading: undoneListLoading } = useInboxList({
    variables: {
      filter: { isCompleted: { equals: 0 },
      }},
  });

  useEffect(() => {
    if (undoneInboxCountLoading) {
      return;
    }

    // start background refresh
    if (undoneInboxCount !== oldUnDoneInboxCount) {
      setOldInboxCount(undoneInboxCount);
      refetchDoneList();
      refetchUndoneList();
      return;
    }
  }, [oldUnDoneInboxCount, refetchDoneList, refetchUndoneList, undoneInboxCountLoading, undoneInboxCount]);


  const developerHomeMainPlateNav = (
    <DeveloperHomeNav>
      <DeveloperHomeNavUserInfo />
      <DeveloperHomeNavContent
        onOpenWorkspaceClick={ onOpenWorkspaceClick }
      />
    </DeveloperHomeNav>
  );

  return (
    <DeveloperHomePage { ...E2E.$props('developerHome.root') }>
      <CommonFlowBars />
      <UserFlow.HeaderWhite
        centerContent={ (
          <DeveloperHomeHeaderSearchContainer onOpenWorkspaceClick={ onOpenWorkspaceClick } />
        ) }
        rightContent={ (
          <Row gap="lg" alignItems="center">
            <UserFlow.LearningCenter />
            <UserFlow.UserDropdown />
          </Row>
        ) }
      />
      <Switch>
        <Route path={ APP_URL.developerHomeWorkspaces } render={ () => (
          <DeveloperHomeMainPlate
            nav={ developerHomeMainPlateNav }
            content={ (
              <DeveloperHomeWorkspacesContainer
                onOpenWorkspaceClick={ onOpenWorkspaceClick }
              />
            ) } />
        ) } />
        <Route path={ APP_URL.developerHomeOrganization } exact render={ () => (
          <DeveloperHomeMainPlate
            nav={ developerHomeMainPlateNav }
            content={ (
              <DeveloperHomeOrganizationContainer
                onOpenWorkspaceClick={ onOpenWorkspaceClick }
              />
            ) } />
        ) } />
        <Route path={ APP_URL.developerHomeInbox } render={ () => (
          <DeveloperHomeMainPlate
            nav={ developerHomeMainPlateNav }
            content={ (
              <DeveloperHomeInboxContainer loading={ doneListLoading || undoneListLoading } />
            ) } />
        ) } />
        <Redirect exact from={ APP_URL.developerHome } to={ APP_URL.developerHomeWorkspaces } />
      </Switch>

    </DeveloperHomePage>
  );
};

export default DeveloperHome;
