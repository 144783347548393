import gql from 'graphql-tag';
import { PrefineryUserFragment } from 'graphql/fragments';

// provided by learning-center
export const CREATE_PREFINERY_USER_MUTATION = gql`
  mutation CreatePrefineryUser($email: String!, $referrerCode: String) {
    createPrefineryUser(email: $email, referrerCode: $referrerCode) {
      ...PrefineryUserFragment
    }
  }

  ${PrefineryUserFragment}
`;
