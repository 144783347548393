import React from 'react';
import styled from '@emotion/styled';
import { ClassNames } from '@emotion/core';
import cx from 'classnames';

import verticalConsoleLogo from 'images/8base-console-vertical.svg';
import horizontalConsoleLogo from 'images/8base-console-horizontal.svg';

type AppFlowNavConsoleLogoProps = {
  parentExpanded: boolean,
};

const OuterContainer = styled('div')`
  flex-grow: 2;
  position: relative;
  z-index: 1;
`;

const LogoContainer = styled('div')`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  margin-bottom: 24px;
`;

const VerticalLogoContainer = styled(LogoContainer)`
  opacity: 1;
  transition: opacity 0.1s;

  @media (max-height: 767px) {
    display: none;
  }
`;

const HorizontalLogoContainer = styled(LogoContainer)`
  opacity: 0;
  transition: opacity 0.1s;
  visibility: hidden;
`;

const AppFlowNavConsoleLogo = ({ parentExpanded }: AppFlowNavConsoleLogoProps) => (
  <ClassNames>
    { ({ css }) => {
      const fadeOutClassName = css`
          opacity: 0;
        `;

      const fadeInClassName = css`
          opacity: 1;
          visibility: visible;
        `;

      const delayClassName = css`
          transition-delay: 0.2s;
        `;

      const verticalLogoClassNames = cx({
        [fadeOutClassName]: parentExpanded,
        [delayClassName]: !parentExpanded,
      });

      const horizontalLogoClassNames = cx({
        [fadeInClassName]: parentExpanded,
        [delayClassName]: parentExpanded,
      });

      return (
        <OuterContainer>
          <VerticalLogoContainer className={ verticalLogoClassNames }>
            <img src={ verticalConsoleLogo } alt="" />
          </VerticalLogoContainer>
          <HorizontalLogoContainer className={ horizontalLogoClassNames }>
            <img src={ horizontalConsoleLogo } alt="" css={{ minWidth: 135 }} />
          </HorizontalLogoContainer>
        </OuterContainer>
      );
    } }
  </ClassNames>
);

export { AppFlowNavConsoleLogo };

