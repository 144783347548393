// @flow

import { prop } from 'ramda';
import { createSelector, type Selector } from 'reselect';

export type ApolloDataPermissionOperationTypes = 'create' | 'read' | 'update' | 'delete';

export type ApolloDataPermissionOperationFilter = Object;

export type ApolloDataPermissionOperationFields = {
  [string]: boolean,
};

export type ApolloDataPermissionOperation = $Shape<{
  allow: boolean,
  filter: ApolloDataPermissionOperationFilter,
  fields: ApolloDataPermissionOperationFields,
}>;

export const getDataPermissionOperation = <T: ApolloDataPermissionOperation>(
  dataPermissionOperation: T,
): T => dataPermissionOperation;

export const getDataPermissionOperationAllow: Selector<
  ApolloDataPermissionOperation,
  any,
  boolean,
> = createSelector(
  getDataPermissionOperation,
  prop('allow'),
);

export const getDataPermissionOperationFilter: Selector<
  ApolloDataPermissionOperation,
  any,
  ?ApolloDataPermissionOperationFilter,
> = createSelector(
  getDataPermissionOperation,
  prop('filter'),
);

export const getDataPermissionOperationFields: Selector<
  ApolloDataPermissionOperation,
  any,
  ?ApolloDataPermissionOperationFields,
> = createSelector(
  getDataPermissionOperation,
  prop('fields'),
);

export const getDataPermissionOperationField: Selector<
  ApolloDataPermissionOperation,
  string,
  ?boolean,
> = createSelector(
  getDataPermissionOperationFields,
  (_, tableFieldName: string): string => tableFieldName,
  (
    fieldsPermission: ?ApolloDataPermissionOperationFields,
    tableFieldName: string,
  ): ?boolean => prop(tableFieldName)(fieldsPermission),
);

