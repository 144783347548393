// @flow
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon, Link } from '@8base/boost';

type ArrowLinkProps = {
  title: React$Node;
}

export const ArrowLink = ({ title, ...rest }: ArrowLinkProps) => {
  return (
    <Link tagName={ RouterLink } css={{ display: 'inline-flex', alignItems: 'center' }} { ...rest }>
      { title }
      <Icon css={{ marginLeft: 8 }} name="ArrowRight" />
    </Link>
  );
};
