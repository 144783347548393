// @flow

import React from 'react';
import { Button } from '@8base/boost';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

const innerCss = css`
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  background: #000;
  padding: 0 20px;
  border-radius: 32px;
`;

const uppercaseCss = (props) => {
  if (props.uppercase) {
    return css`text-transform: uppercase;`;
  }
};

const StyledButton = styled(Button)`
  background-color: #000;
  background-image: linear-gradient(135deg, #ce4854, #5d93ff);
  border-width: 0px;
  padding: 1px;
  display: block;
  ${uppercaseCss}
`;

export const FancyButton = ({ children, ...rest }: *) => {
  return (
    <StyledButton { ...rest } >
      <div css={ innerCss }>
        { children }
      </div>
    </StyledButton>
  );
};
