import React from 'react';
import ReactDOM from 'react-dom';
import { Settings } from 'luxon';
import moment from 'moment';
import 'moment-timezone';
import * as Sentry from '@sentry/browser';
import qs from 'query-string';

import { trackEvent } from 'utils/trackEvent';

import App from './App';
import { E2E } from './e2e';
import {
  getAppEnvironment,
  isSentryEnabled,
  getSentryDSN,
  getSalesforceClientId,
} from 'utils/processEnv';

Sentry.init({
  dsn: getSentryDSN(),
  debug: !isSentryEnabled(),
  environment: getAppEnvironment(),
});

window.trackEvent = trackEvent;
window.E2E = E2E;

Settings.defaultZoneName = 'local';
moment.tz.setDefault(); // needs only for the react-datepicker calendar.

document.addEventListener('DOMContentLoaded', () => {
  if (window.location.pathname === '/integrations/salesforce-connect') {
    const { state, sandbox } = qs.parse(window.location.search);

    const domain = sandbox === 'true' ? 'test.salesforce.com' : 'login.salesforce.com';

    window.location.replace(`https://${domain}/services/oauth2/authorize?${qs.stringify({
      response_type: 'code',
      client_id: getSalesforceClientId(),
      redirect_uri: `${window.location.origin}/integrations/`,
      state,
    })}`);
  }
});


ReactDOM.render(<App />, document.getElementById('root'));
