// @flow

import * as R from 'ramda';
import { createSelector } from 'reselect';

import * as userSelectors from './user.selectors';

import type { Selector } from 'reselect';

import type { ApolloUser } from './user.selectors';

export type ApolloUsersListResponse = {
  items: ApolloUser[],
  count: number,
};

export const getUsersListResponse: Selector<
  ApolloUsersListResponse,
  any,
  ApolloUsersListResponse,
> = (usersListResponse: ApolloUsersListResponse = { items: [], count: 0 }) => usersListResponse;

export const getUsersList: Selector<
  ApolloUsersListResponse,
  any,
  ApolloUser[],
> = createSelector(
  getUsersListResponse,
  R.prop('items'),
);

export const getUsersCount: Selector<
  ApolloUsersListResponse,
  any,
  number,
> = createSelector(
  getUsersListResponse,
  R.prop('count'),
);

export const getSelectOptions: Selector<
  ApolloUsersListResponse,
  any,
  Array<{ value: string, label: string }>,
> = createSelector(
  getUsersList,
  R.map(userSelectors.getUserSelectOption),
);
