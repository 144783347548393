// @flow

import * as R from 'ramda';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '@8base-react/auth';

import { APP_URL } from './url';

type PrivateRouteProps = {
  key?: string,
  path?: string,
  exact?: boolean,
  strict?: boolean,

  component?: React$ComponentType<*>,
  render?: (Object) => React$Node,
}

export const PrivateRoute = ({ component: Component, render, ...rest }: PrivateRouteProps) => {
  const { isAuthorized, isEmailVerified } = useAuth();

  return (
    <Route { ...rest }
      render={ props => {
        const componentToRender = R.isNil(render)
          ? Component && <Component { ...props } />
          : render && render(props);

        if (isAuthorized && isEmailVerified) {
          return componentToRender;
        } else if (isAuthorized && !isEmailVerified) {
          return (
            <Redirect
              to={{
                pathname: APP_URL.authConfirm,
                state: { from: props.location },
              }}
            />
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: APP_URL.authLogin,
                state: { from: props.location },
              }}
            />
          );
        }
      } }
    />
  );
};

