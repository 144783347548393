// @flow

import React from 'react';
import {
  Dialog,
  Button,
  InputField,
  SelectField,
  Row,
  Text,
  AsyncContent,
  Column,
  useModal, Heading,
} from '@8base/boost';
import * as R from 'ramda';
import { Query, useMutation } from 'react-apollo';
import { Form, Field } from '@8base-react/forms';
import { css } from '@emotion/core';
import { DateTime } from 'luxon';

import { apolloSelectors } from 'graphql/selectors';
import { Trans } from 'utils/translate';
import { USER_EDIT_QUERY, ROLES_LIST_QUERY } from 'graphql/queries';
import { USER_UPDATE_MUTATION } from 'graphql/mutations';
import { TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';
import { i18n } from 'i18n';
import { AvatarField } from 'pro';

type UserEditDialogProps = {
  onSubmit: (data: Object, id: string) => Promise<void>,
  onSuccess: () => void,
  onClose: () => void,
};

type UserEditFormProps = UserEditDialogProps & {
  user: Object,
  userLoading: boolean,
  id: string,
};

const USERS_EDIT_DIALOG_ID = 'USERS_EDIT_DIALOG_ID';

const UserEditForm = ({
  onSubmit,
  onSuccess,
  onClose,
  user,
  userLoading,
  id,
}: UserEditFormProps) => {
  const initialValues = R.pipe(
    apolloSelectors.user.getUser,
    R.defaultTo({}),
    R.pick(['firstName', 'lastName', 'roles', 'avatar', 'email']),
  )(user);

  const createdAt = DateTime.fromISO(apolloSelectors.user.getCreatedAt(user)).toFormat('MM/dd/yyyy');

  return (
    <Form
      type="UPDATE"
      tableSchemaName="Users"
      onSubmit={ (data) => onSubmit(data, id) }
      onSuccess={ onSuccess }
      initialValues={ initialValues }
      formatRelationToIds
    >
      { ({ handleSubmit, submitting, pristine, values }) => (
        <form onSubmit={ handleSubmit }>
          <Dialog.Header
            title={ i18n.t('settings.users.editUser') }
            onClose={ onClose }
          />
          <Dialog.Body padding="none">
            <AsyncContent stretch loading={ userLoading }>
              <Row gap="none" alignItems="stretch">
                <Column gap="none" alignItems="center" css={ css`padding: 20px; width: 200px;` }>
                  <Field name="avatar" component={ AvatarField } firstName={ values.firstName } lastName={ values.lastName } size="lg" />
                  <Heading type="h4" align="center" css={ css`margin-top: 12px; word-break: break-all;` }>
                    { values.firstName } { values.lastName }
                  </Heading>
                  <Text color="SECONDARY_TEXT_COLOR">Joined { createdAt }</Text>
                </Column>
                <Column css={ css`padding: 20px; width: 100%; border-left: 1px solid #e9eff4;` }>
                  <Row css={{ height: 'auto' }} stretch>
                    <Field
                      name="firstName"
                      label={ i18n.t('shared.firstName') }
                      placeholder={ i18n.t('shared.firstName') }
                      component={ InputField }
                    />
                    <Field
                      name="lastName"
                      label={ i18n.t('shared.lastName') }
                      placeholder={ i18n.t('shared.lastName') }
                      component={ InputField }
                    />
                  </Row>
                  <Field
                    name="email"
                    label={ i18n.t('shared.email') }
                    placeholder={ i18n.t('shared.email') }
                    component={ InputField }
                  />
                  <Query query={ ROLES_LIST_QUERY }>
                    { ({ data, loading }) => (
                      <Field
                        name="roles"
                        label={ i18n.t('settings.users.roles') }
                        placeholder={ i18n.t('settings.users.roles') }
                        component={ SelectField }
                        options={ apolloSelectors.rolesList.getRolesListSelectOptions(data) }
                        loading={ loading }
                        multiple
                      />
                    ) }
                  </Query>
                </Column>
              </Row>
            </AsyncContent>
          </Dialog.Body>
          <Dialog.Footer>
            <Button
              type="button"
              color="neutral"
              variant="outlined"
              onClick={ onClose }
              disabled={ submitting }
            >
              <Trans i18nKey="shared.cancel" />
            </Button>
            <Button
              type="submit"
              disabled={ pristine || userLoading }
              loading={ submitting }
              onClick={ handleSubmit }
            >
              <Trans i18nKey="shared.update" />
            </Button>
          </Dialog.Footer>
        </form>
      ) }
    </Form>
  );
};

const UserEditDialog = () => {
  const { closeModal } = useModal();

  const [userUpdate] = useMutation(USER_UPDATE_MUTATION, {
    refetchQueries: ['UsersList'],
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('shared.dataUpdateSuccessful'),
    },
  });

  const onSubmit = async (data, id) => {
    window.trackEvent('Users', 'Update user');

    await userUpdate({
      variables: {
        data: {
          id,
          ...data,
        },
      },
    });
  };

  const onSuccess = () => {
    closeModal(USERS_EDIT_DIALOG_ID);
  };

  const onClose = () => {
    closeModal(USERS_EDIT_DIALOG_ID);
  };

  return (
    <Dialog id={ USERS_EDIT_DIALOG_ID } onClose={ onClose } size="xl">
      { ({ args }) => (
        <Query
          query={ USER_EDIT_QUERY }
          fetchPolicy="network-only"
          variables={{ id: args.id }}
        >
          { ({ data: user, loading: userLoading }) => (
            <UserEditForm
              onSubmit={ onSubmit }
              onSuccess={ onSuccess }
              onClose={ onClose }
              user={ user }
              userLoading={ userLoading }
              id={ args.id }
            />
          ) }
        </Query>
      ) }
    </Dialog>
  );
};

UserEditDialog.ID = USERS_EDIT_DIALOG_ID;

export { UserEditDialog };

