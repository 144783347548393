// @flow
// $FlowFixMe
import React, { useCallback } from 'react';
import { Dialog, useModal, Button, Icon, Row, Heading, Text } from '@8base/boost';
import { i18n } from 'i18n';
import { Trans } from 'utils/translate';
import { useMutation } from 'react-apollo';
import { ORGANIZATION_QUERY } from 'graphql/queries';
import { WORKSPACE_ORGANIZATION_USER_REMOVE } from 'graphql/mutations';

const Title = () => (
  <Row alignItems="center" gap="sm">
    <Icon name="RemoveAccessWarning" color="WARNING" />
    <Heading type="h4">
      <Trans i18nKey="dialogs.organizationWorkspaceRemoveAccess.title">
        Access removing
      </Trans>
    </Heading>
  </Row>);


const ORGANIZATION_REMOVE_ACCESS_TO_WORKSPACES_DIALOG_ID = 'ORGANIZATION_REMOVE_ACCESS_TO_WORKSPACES_DIALOG_ID';

const OrganizationRemoveAccessToWorkspacesDialog = () => {
  const { closeModal, args } = useModal(ORGANIZATION_REMOVE_ACCESS_TO_WORKSPACES_DIALOG_ID);
  const { organizationId, workspaceId, workspaceName, profileFullName, profileEmail } = args;

  const [removeTeamMemberWorkspaceAccess, { loading: removing }] = useMutation(WORKSPACE_ORGANIZATION_USER_REMOVE);

  const onClose = useCallback(() => {
    closeModal(ORGANIZATION_REMOVE_ACCESS_TO_WORKSPACES_DIALOG_ID);
  }, [closeModal]);

  const onRemove = useCallback(async() => {
    await removeTeamMemberWorkspaceAccess({
      variables: {
        email: profileEmail,
        workspaceId,
      },
      refetchQueries: [{
        query: ORGANIZATION_QUERY,
        variables: { organizationId },
      }],
      awaitRefetchQueries: true,
    });

    onClose();
  }, [onClose, organizationId, profileEmail, removeTeamMemberWorkspaceAccess, workspaceId]);

  return (
    <Dialog id={ ORGANIZATION_REMOVE_ACCESS_TO_WORKSPACES_DIALOG_ID } size="sm">
      <Dialog.Header
        title={ <Title /> }
        onClose={ onClose }
      />
      <Dialog.Body>
        <Text>
          <Trans i18nKey="dialogs.organizationWorkspaceRemoveAccess.modalText">
                You are removing access to the workspace <Text weight="bold">{ workspaceName }</Text> for { profileFullName }.
          </Trans>
        </Text>
      </Dialog.Body>
      <Dialog.Footer>
        <Button
          color="neutral"
          onClick={ onClose }
          disabled={ removing }
        >
          { i18n.t('shared.cancel') }
        </Button>
        <Button
          onClick={ onRemove }
          loading={ removing }
        >
          { i18n.t('organization.team.removeAccess') }
        </Button>
      </Dialog.Footer>
    </Dialog>);
};

OrganizationRemoveAccessToWorkspacesDialog.ID = ORGANIZATION_REMOVE_ACCESS_TO_WORKSPACES_DIALOG_ID;

export { OrganizationRemoveAccessToWorkspacesDialog };
