// @flow
import fp from 'lodash/fp';
import { createSelector } from 'reselect';

import type { DataBuilderState } from './dataBuilder.reducer';

export const getDataBuilderState = (state: Object): DataBuilderState => state.dataBuilder;

export const getDataViewer = (state: *, _: any) => getDataBuilderState(state).dataViewer;

export const shouldAutoselectTableName = (state: *) => getDataBuilderState(state).shouldAutoselectTableName;

const initialDataViewerControls = {
  filter: { list: [] },
  sort: { list: [] },
};

export const getDataViewerTableControlsData = (state: *, tableId: string) => createSelector(
  getDataViewer,
  (dataViewer) => !tableId
    ? initialDataViewerControls
    : fp.getOr(initialDataViewerControls, tableId, dataViewer),
)(state);
