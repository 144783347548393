// @flow
import { useQuery, type QueryResult } from 'react-apollo';
import { useAuth } from '@8base-react/auth';

import type { UserAccountInfoQuery, UserAccountInfoQueryVariables } from 'graphql/__generated__';

import { USER_ACCOUNT_INFO_QUERY } from '../queries';

export type UserAccountInfoResponse = {
  email: string;
  createdAt: string;
  firstName: string;
  lastName: string;
  timezone: string;
  learningMode: boolean;
  permissions: any;
  role: string;
  isDeveloper: boolean;
  type: string;
  companyName: string;
  projectDescription: string;
  avatar: {
    id: string;
    downloadUrl: string;
  }
}

const getUserAccountInfoData = (data: any, tokenData) => {
  // $FlowIgnore
  const userAccountInfo: UserAccountInfoResponse = (data && data.system && data.system.memberAccount) || tokenData;

  return userAccountInfo;
};

export const useUserAccountInfo = (graphqlOptions: Object = {}) => {
  const auth = useAuth();
  const tokenInfo = auth.authClient.getTokenInfo() || {};

  const tokenPredefinedResponseData = {
    email: tokenInfo.email,
    firstName: tokenInfo.given_name,
    lastName: tokenInfo.family_name,
    avatar: {
      id: '',
      downloadUrl: tokenInfo.picture,
      __typename: 'UserInfoAvatar',
    },
    companyName: '',
    createdAt: null,
    isDeveloper: null,
    learningMode: true,
    permissions: null,
    projectDescription: '',
    role: '',
    timezone: '',
    type: null,
    __typename: 'SystemUserAccountInfo',
  };

  const { data, ...rest }: QueryResult<UserAccountInfoQuery, UserAccountInfoQueryVariables> = useQuery(USER_ACCOUNT_INFO_QUERY, {
    skip: !auth.isAuthorized || !auth.isEmailVerified,
    ...graphqlOptions,
    onCompleted: data => {
      graphqlOptions.onCompleted && graphqlOptions.onCompleted(getUserAccountInfoData(data, tokenPredefinedResponseData));
    },
  });

  const userAccountInfo = getUserAccountInfoData(data, tokenPredefinedResponseData);

  return {
    userAccountInfo,
    ...rest,
  };
};
