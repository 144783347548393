// @flow
// $FlowFixMe
import React from 'react';
// $FlowFixMe
import { Route, Switch, Link as RouterLink, Redirect, useLocation, matchPath } from 'react-router-dom';
import styled from '@emotion/styled';
import { Row, Column } from '@8base/boost';
import { ButtonToggleTab } from 'common/components';
import { Trans } from 'utils/translate';
import { APP_URL } from 'common/routing';
import { useInboxList } from 'graphql/hooks';
import { InboxList } from './InboxList';

const RowTabs = styled(Row)`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_30};

  &:not(:last-child) {
    margin-bottom: 0;
  }
`;

export const InboxTabs = () => {
  const doneInboxList = useInboxList({
    variables: {
      filter: { isCompleted: { equals: 1 },
      }},
  });
  const undoneInboxList = useInboxList({
    variables: {
      filter: { isCompleted: { equals: 0 },
      }},
  });

  const doneCount = doneInboxList.count || 0;
  const undoneCount = undoneInboxList.count || 0;

  const { pathname } = useLocation();

  return (
    <Column gap="xl" css={{ height: '100%' }}>
      <RowTabs gap="xl">
        <RouterLink to={ APP_URL.developerHomeInboxUndone }>
          <ButtonToggleTab
            isActive={ !!matchPath(pathname, { path: APP_URL.developerHomeInboxUndone }) }
            count={ undoneCount }
            isRedCountTag={ undoneCount > 0 }
            pending
          >
            <Trans i18nKey="inbox.tab.pending">
            Pending
            </Trans>
          </ButtonToggleTab>
        </RouterLink>
        <RouterLink to={ APP_URL.developerHomeInboxDone }>
          <ButtonToggleTab
            isActive={ !!matchPath(pathname, { path: APP_URL.developerHomeInboxDone }) }
            count={ doneCount }
          >
            <Trans i18nKey="inbox.tab.completed">
              Completed
            </Trans>
          </ButtonToggleTab>
        </RouterLink>
      </RowTabs>

      <Switch>
        <Route path={ APP_URL.developerHomeInboxUndone } exact >
          <InboxList
            hasMore={ undoneInboxList.hasMore }
            inboxList={ undoneInboxList.inboxList }
            loadMore={ undoneInboxList.loadMore }
            loading={ undoneInboxList.loading }
          />
        </Route>
        <Route path={ APP_URL.developerHomeInboxDone } exact >
          <InboxList
            hasMore={ doneInboxList.hasMore }
            inboxList={ doneInboxList.inboxList }
            loadMore={ doneInboxList.loadMore }
            loading={ doneInboxList.loading }
          />
        </Route>
        <Redirect exact from={ APP_URL.developerHomeInbox } to={ APP_URL.developerHomeInboxUndone } />
      </Switch>
    </Column>
  );
};
