// @flow
// $FlowFixMe
import { useEffect } from 'react';
// $FlowFixMe
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import { useAuth } from '@8base-react/auth';

import { apolloUserAccountInfoSelectors } from 'graphql/selectors';
import { useTeamMember, useUserAccountInfo } from 'graphql/hooks';
import { APP_URL } from 'common/routing';
import { IGNORE_WORKSPACE } from 'common/constants/apolloOperationContextOptions';


export const RootContainer = ({ children }: *) => {
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();

  useTeamMember({
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: false,
    onCompleted: (teamMember) => {
      if (!!teamMember) {
        auth.authClient.setState({
          email: teamMember.email,
        });
      }
    },
  });

  const { userAccountInfo, loading } = useUserAccountInfo({
    context: {
      [IGNORE_WORKSPACE]: true,
      noBatch: true,
    },
  });

  useEffect(() => {
    const isOnboarding = !!matchPath(location.pathname, { path: APP_URL.onboarding });

    if (!loading && !apolloUserAccountInfoSelectors.getIsAllOnboardingStepsCompleted(userAccountInfo) && !isOnboarding && auth.isAuthorized) {
      history.push(APP_URL.onboardingUserName);
    }
  }, [auth.isAuthorized, history, loading, location.pathname, userAccountInfo]);

  return children;
};
