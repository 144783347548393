//@flow

// $FlowFixMe waiting for update Flow
import React, { useCallback } from 'react';
// $FlowFixMe
import { useMutation, useApolloClient } from 'react-apollo';
import { Dialog, Button, Text, useModal } from '@8base/boost';
// $FlowFixMe
import { useHistory } from 'react-router-dom';
import { i18n } from 'i18n';

import { WORKSPACE_ID } from 'common/constants/apolloOperationContextOptions';
import { APP_URL } from 'common/routing';
import { ForcibleErrorDialog } from 'dialogs/ForcibleErrorDialog';
import { WORKSPACE_LEAVE_MUTATION } from 'graphql/mutations';
import { environmentAccessor } from 'utils';
import { hasForcibleError } from 'utils/hasForcibleError';
import { Trans } from 'utils/translate';
import { deleteWorkspaceFromCache } from 'utils/apollo/updateCache';

const LEAVE_WORKSPACE_DIALOG = 'LEAVE_WORKSPACE_DIALOG';

const WorkspaceLeaveDialog = () => {
  const { closeModal, openModal, args: { workspaceId, workspaceName, withReload }} = useModal(LEAVE_WORKSPACE_DIALOG);
  const history = useHistory();

  const client = useApolloClient();

  const [workspaceLeave, { loading: leaving }] = useMutation(WORKSPACE_LEAVE_MUTATION, {
    context: {
      [WORKSPACE_ID]: workspaceId,
    },
    update: (cache) => {
      deleteWorkspaceFromCache({ workspaceId, cache });
    },
    onCompleted: () => {
      closeModal(LEAVE_WORKSPACE_DIALOG);
    },
  });

  const onClose = useCallback(() => closeModal(LEAVE_WORKSPACE_DIALOG), [closeModal]);

  const moveToRoot = useCallback(async () => {
    history.push(APP_URL.root);

    environmentAccessor.removeEnvironment(workspaceId);
    await client.clearStore();
    await client.reFetchObservableQueries();
  }, [client, history, workspaceId]);

  const onLeave = useCallback(async () => {
    try {
      await workspaceLeave();

      onClose();

      withReload && moveToRoot();
    } catch (err) {
      if (hasForcibleError(err)) {
        openModal(ForcibleErrorDialog.ID, {
          onSubmit: async () => {
            await workspaceLeave({ variables: { force: true }});

            onClose();

            withReload && moveToRoot();
          },
          title: i18n.t('leaveWorkspaceDialog.forcible.title'),
          message: i18n.t('leaveWorkspaceDialog.forcible.message'),
          submitButtonMessage: i18n.t('leaveWorkspaceDialog.forcible.buttonText'),
        });
      }
    }
  }, [moveToRoot, onClose, openModal, withReload, workspaceLeave]);

  return (
    <Dialog id={ LEAVE_WORKSPACE_DIALOG } onClose={ onClose } size="sm">
      <React.Fragment>
        <Dialog.Header title={ i18n.t('leaveWorkspaceDialog.title') } onClose={ onClose } />
        <Dialog.Body>
          <Text color="LIGHT_TEXT_COLOR">
            <Trans i18nKey="leaveWorkspaceDialog.note">
                  You are leaving the workspace <Text weight="bold">{ workspaceName }</Text>. Are you sure you want to leave this workspace?
            </Trans>
          </Text>
        </Dialog.Body>
        <Dialog.Footer>
          <Button color="neutral" variant="outlined" onClick={ onClose }>
            { i18n.t('shared.cancel') }
          </Button>
          <Button color="danger" onClick={ onLeave } loading={ leaving }>
            { i18n.t('leaveWorkspaceDialog.leave') }
          </Button>
        </Dialog.Footer>
      </React.Fragment>
    </Dialog>
  );
};

WorkspaceLeaveDialog.ID = LEAVE_WORKSPACE_DIALOG;

export { WorkspaceLeaveDialog };
