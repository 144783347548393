// @flow

import React from 'react';
// $FlowFixMe waiting for update Flows

import { LearningCenter } from '../../pro';
import { useUserAccountInfo } from '../../graphql/hooks';

export const UserFlowLearningCenter = () => {
  const { userAccountInfo } = useUserAccountInfo();

  return (
    <LearningCenter withLearningMode={ userAccountInfo.learningMode } />
  );
};

