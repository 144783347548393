// @flow

import React from 'react';

export type WorkspaceProxyContextProps = {
  apiUri?: string;
  setApiUri: (apiUri?: string) => void;
  webSocketUri?: string;
  setWebSocketUri: (webSocketUri?: string) => void;
  workspaceListLoaded: boolean,
  setListLoaded: (loaded: boolean) => void,
};

export const WorkspaceProxyContext = React.createContext<WorkspaceProxyContextProps>({
  apiUri: undefined,
  setApiUri: () => {},
  webSocketUri: undefined,
  setWebSocketUri: () => {},
  workspaceListLoaded: false,
  setListLoaded: () => {},
});
