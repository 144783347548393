//@flow

import * as R from 'ramda';
import { createSelector } from 'reselect';

import type { Selector } from 'reselect';

import type { ApolloApiToken } from './apiToken.selectors';

export type ApolloApiTokensListResponse = {
  items: ApolloApiToken[],
  count: number,
};

export const getApiTokensListResponse: Selector<
  ApolloApiTokensListResponse,
  any,
  ApolloApiTokensListResponse,
> = (apiTokensListResponse: ApolloApiTokensListResponse = { items: [], count: 0 }) =>
  apiTokensListResponse;

export const getApiTokensList: Selector<
  ApolloApiTokensListResponse,
  any,
  ApolloApiToken[],
> = createSelector(
  getApiTokensListResponse,
  R.prop('items'),
);

export const getApiTokensCount: Selector<
  ApolloApiTokensListResponse,
  any,
  number,
> = createSelector(
  getApiTokensListResponse,
  R.prop('count'),
);

