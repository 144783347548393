// @flow

import React from 'react';
import { Form } from '@8base-react/forms';
import { Dialog, Button, Paragraph, useModal } from '@8base/boost';
import { Mutation } from 'react-apollo';

import { i18n } from 'i18n';
import { TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';
import { API_TOKEN_DELETE_MUTATION } from 'graphql/mutations';

export const API_TOKEN_DELETE_DIALOG_ID = 'API_TOKEN_DELETE_DIALOG';

const APITokenDeleteDialog = () => {
  const { closeModal } = useModal();

  return (
    <Mutation
      mutation={ API_TOKEN_DELETE_MUTATION }
      refetchQueries={ ['ApiTokensList'] }
      context={{
        [TOAST_SUCCESS_MESSAGE]: i18n.t('settings.apiTokens.deleteSuccessfull'),
      }}
    >
      { (apiTokenDelete) => (
        <Dialog
          id={ API_TOKEN_DELETE_DIALOG_ID }
          size="sm"
          onClose={ () => closeModal(API_TOKEN_DELETE_DIALOG_ID) }
          { ...E2E.$props('settings.apiTokens.deleteDialog.body') }
        >
          { ({ args }) => (
            <Form onSubmit={ async () => {
              await apiTokenDelete({ variables: { id: args.id }});

              closeModal(API_TOKEN_DELETE_DIALOG_ID);

              window.trackEvent('API Tokens', 'Delete token');
            } }>
              { ({ handleSubmit, invalid, submitting }) => (
                <form onSubmit={ handleSubmit }>
                  <Dialog.Header title={ i18n.t('settings.apiTokens.deleteDialog.title') } onClose={ () => closeModal(API_TOKEN_DELETE_DIALOG_ID) } />
                  <Dialog.Body>
                    <Paragraph>Are you really want to delete this API token?</Paragraph>
                  </Dialog.Body>
                  <Dialog.Footer>
                    <Button
                      color="neutral"
                      variant="outlined"
                      disabled={ submitting }
                      onClick={ () => closeModal(API_TOKEN_DELETE_DIALOG_ID) }
                      { ...E2E.$props('settings.apiTokens.deleteDialog.cancelButton') }
                    >
                      { i18n.t('shared.cancel') }
                    </Button>
                    <Button
                      color="danger"
                      type="submit"
                      disabled={ invalid } loading={ submitting }
                      { ...E2E.$props('settings.apiTokens.deleteDialog.submitButton') }
                    >
                      { i18n.t('shared.delete') }
                    </Button>
                  </Dialog.Footer>
                </form>
              ) }
            </Form>
          ) }
        </Dialog>
      ) }
    </Mutation>
  );
};

APITokenDeleteDialog.ID = API_TOKEN_DELETE_DIALOG_ID;

export { APITokenDeleteDialog };
