import React from 'react';
import {
  Card,
  AsyncContent, Row, Column, Icon, Text, Progress, Grid,
} from '@8base/boost';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import * as R from 'ramda';
import { DateTime } from 'luxon';

import {
  BillingPlanDowngradeProgress,
} from '../../routes/settings/routes/billing/components/BillingPlanDowngradeProgress';
import { PlanName } from '../PlanName';
import { Trans } from '../../utils/translate';

import { BILLING_PLAN_STATUS, PAYED_PLAN_STATUSES } from '../constants/billing';
import { PRICING_URL } from 'config/links';
import { COLOR_BY_PLAN_NAME } from '../../graphql/constants/billing';

import { BillingCurrentPlanResponse, BillingDetailsResponse } from '../../graphql/__generated__';

const SHOULD_SHOW_UNIT = {
  gb: true,
  member: false,
  request: false,
  row: false,
};

const numberFormatter = new Intl.NumberFormat('en-En', { useGrouping: true });

const getValueText = (value, limit, unit, showUnit) => {
  return (
    <Text kind="overline-1" color="GRAY_60">
      { numberFormatter.format(value) } / { numberFormatter.format(limit) }
      <If condition={ showUnit }>
        { ' ' } { unit.toUpperCase() }
      </If>
    </Text>
  );
};

const StyledColumn = styled(Column)`
  border-right: 1px solid ${props => props.theme.COLORS.GRAY_20};
  padding-right: 32px;
  min-width: 730px;
`;

type BillingCardBodyProps = {
  loading: boolean,
  plan: BillingCurrentPlanResponse,
  card?: BillingDetailsResponse,
  remainTrialDays?: number,
  quotas: Object[],
  metrics: Object,
  overages: Object,
  totalOverages?: number;
  workspaceName?: string;
  showPlanDetails?: boolean;
}

export const BillingCardBody = ({
  loading,
  plan,
  card,
  remainTrialDays,
  quotas,
  metrics,
  overages,
  overagesPrice = 0,
  workspaceName,
  showPlanDetails = false,
  ...rest
}: BillingCardBodyProps) => {
  const planName = R.prop('name', plan);
  const nextPaymentDt = DateTime.local().plus({ months: 1 }).set({ day: 1 }).toFormat('LLL d, yyyy');
  const nextPlan = R.path(['nextPlan'], plan);
  const planPrice = R.prop('price', plan);
  const trialEndDt = DateTime.fromISO(R.prop('trialEnd', plan)).toFormat('LLL d, yyyy');
  const planStatus = R.prop('status', plan);
  const hasNextPayment = PAYED_PLAN_STATUSES.indexOf(planStatus) !== -1;
  const planColor = COLOR_BY_PLAN_NAME[planName] || 'GREEN';

  return (
    <Card.Body { ...rest }>
      <AsyncContent loading={ loading } stretch>
        <Row alignItems="center" >
          <StyledColumn
            gap="lg"
            stretch
          >
            <Choose>
              <When condition={ !!nextPlan }>
                <BillingPlanDowngradeProgress
                  currentPlan={ plan }
                  nextPlan={ nextPlan }
                  nextPaymentDt={ nextPaymentDt }
                  workspaceName={ workspaceName }
                />
              </When>

              <Otherwise>
                <Row alignItem="center">
                  <Icon name="Logo8base" size="sm" color={ planColor } />
                  <PlanName plan={ plan } />
                </Row>
              </Otherwise>
            </Choose>

            <Grid.Layout columns="1fr 1fr" gap="md" stretch>
              { quotas.map(({ displayName, name, value, unit, description }) => {
                const metric = R.propOr(0, name, metrics);
                const hasOverflow = metric > value;
                let valueToDisplay = Math.min(100, 100 * metric / value);

                if (hasOverflow) {
                  valueToDisplay = Math.max(1, 100 * value / metric);
                }

                return (
                  <Row key={ name }>
                    <Progress
                      size="xs"
                      color="GREEN"
                      valueWidth="calc(100% - 136px)"
                      description={ description && <Text kind="small-1"> { description } </Text> }
                      label={ <Text kind="overline-1" color="BLACK"> { displayName } </Text> }
                      valueText={ getValueText(metric, value, unit, SHOULD_SHOW_UNIT[unit]) }
                      value={ valueToDisplay }
                      backgroundColor={ hasOverflow ? 'RED_20' : 'GRAY_20' }
                    />
                  </Row>
                );
              }) }
            </Grid.Layout>
            <If condition={ showPlanDetails }>
              <a href={ PRICING_URL } target="_blank" rel="noopener noreferrer">
                <Text kind="overline-2" color="PRIMARY" weight="semibold" >
                  show plan details
                </Text>
              </a>
            </If>
          </StyledColumn>

          <Column
            css={ css` min-width: 220px; max-width: 220px;` }
            gap="sm"
            offsetX="md"
            justifyContent="center"
            stretch
            { ...E2E.$props('settings.billing.note') }
          >
            <Choose>
              <When condition={ Number.isInteger(remainTrialDays) && remainTrialDays < 1 && R.isNil(card) }>
                <Text color="LIGHT_TEXT_COLOR" >
                  <Trans i18nKey="settings.billing.plan.trialEnd">
                    Your FREE TRIAL has ended, please update your payment details.
                  </Trans>
                </Text>
              </When>
              <When condition={ Number.isInteger(remainTrialDays) && R.isNil(card) }>
                <Text color="LIGHT_TEXT_COLOR" >
                  <Trans i18nKey="settings.billing.plan.trial" trialEnd={ trialEndDt }>
                    Your trial will end on {{ trialEndDt }}, please update your payment details.
                  </Trans>
                </Text>
              </When>
              <When condition={ Number.isInteger(remainTrialDays) }>
                <Text color="LIGHT_TEXT_COLOR" >
                  <Trans i18nKey="settings.billing.plan.trialRemain" trialEnd={ trialEndDt }>
                    Your trial will end on {{ trialEndDt }}.
                  </Trans>
                </Text>
              </When>
              <When condition={ hasNextPayment || nextPlan }>
                <Text kind="h2" color="GRAY_60" weight="semibold">
                  ${ planPrice + overagesPrice }
                </Text>
                <Text kind="overline-1" color="GRAY_60" weight="semibold">
                  Total based <br /> on current usage
                </Text>
                <Text kind="overline-1" color="GRAY_60">
                  ${ planPrice } Subscription
                </Text>
                { quotas.map(({ name, displayName }) => {
                  const overageValue = R.propOr(0, name, overages);

                  if (!overageValue) {
                    return null;
                  }

                  return (
                    <Text kind="overline-1" color="GRAY_60" key={ `overage-${name}` }>
                      ${ overageValue } { displayName }
                    </Text>
                  );
                }) }
                <Text kind="overline-1" color="GRAY_60" weight="semibold">
                  Next payment:
                </Text>
                <Text kind="overline-1" color="GRAY_60">
                  { nextPaymentDt }
                </Text>
              </When>
              <When condition={ planStatus === BILLING_PLAN_STATUS.CANCELING }>
                <Text color="LIGHT_TEXT_COLOR">
                  <Trans i18nKey="settings.billing.plan.subscriptionWillExpire" nextPaymentDt={ nextPaymentDt }>
                    Your subscription will expire on <Text weight="semibold">{{ nextPaymentDt }}.</Text>
                  </Trans>
                </Text>
              </When>
              <When condition={ planStatus === BILLING_PLAN_STATUS.CANCELED }>
                <Text color="LIGHT_TEXT_COLOR" >
                  <Trans i18nKey="settings.billing.plan.subscriptionExpired">
                    You don’t have any upcoming payments.
                  </Trans>
                </Text>
              </When>
            </Choose>
          </Column>
        </Row>
      </AsyncContent>
    </Card.Body>
  );
};
