import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class ButtonsHeadNavigationLeftCommonHelper extends Component {

  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.object,
  };

  preventParentClick = event => event.stopPropagation();

  render() {
    const { children, className } = this.props;
    return (
      <div className={ className || 'nav-buttons' } onClick={ this.preventParentClick }>
        { children }
      </div>
    );
  }
}
