// @flow
import React from 'react';
import styled from '@emotion/styled';

import logoWhite from 'images/8base-logo-white.svg';
import logoRed from 'images/8base-logo-red-white.svg';

type LogoProps = {
  size?: 'lg' | 'md';
  logoVariant?: 'white' | 'black';
}

const Logo = styled('img') `
  display: flex;
  align-items: center;
  height: ${props => props.size === 'lg' ? '45px' : '32px'};

  @media (max-width: 992px) {
    height: 3.5rem
  }
`;

const LOGO_MAP = {
  white: logoWhite,
  black: logoRed,
};

const UserFlowLogo = ({ size = 'lg', logoVariant = 'white', ...rest }: LogoProps) => {
  const logo = LOGO_MAP[logoVariant];

  return (
    <Logo src={ logo } size={ size } alt="8base logo" { ...rest } />
  );
};

export { UserFlowLogo };
