//@flow

// $FlowFixMe waiting for update Flow
import React, { useCallback } from 'react';
import * as R from 'ramda';
import { useModal } from '@8base/boost';

import { useOrganizationPaymentDetailsUpdate } from 'graphql/hooks';

import { BaseBillingUpdateDialog } from './components/BaseBillingUpdateDialog';

const ORGANIZATION_PAYMENT_DETAILS_UPDATE_ID = 'ORGANIZATION_PAYMENT_DETAILS_UPDATE_ID';

const getOrganizationId = R.propOr('', 'organizationId');

/**
 * Update organization payment method
 */
const OrganizationPaymentDetailsUpdateDialog = () => {
  const { args } = useModal(ORGANIZATION_PAYMENT_DETAILS_UPDATE_ID);

  const [updateCardMutation] = useOrganizationPaymentDetailsUpdate(getOrganizationId(args));

  const onSubmitSuccess = useCallback(async (token) => {
    const cardToken = R.prop('id', token);
    const organizationId = getOrganizationId(args);
    if (!args) {
      return;
    }

    const variables = { data: { cardToken, organizationId }};
    await updateCardMutation({ variables });

  }, [args, updateCardMutation]);

  return (
    <BaseBillingUpdateDialog
      dialogId={ ORGANIZATION_PAYMENT_DETAILS_UPDATE_ID }
      onSubmitSuccess={ onSubmitSuccess }
    />
  );
};

OrganizationPaymentDetailsUpdateDialog.ID = ORGANIZATION_PAYMENT_DETAILS_UPDATE_ID;

export { OrganizationPaymentDetailsUpdateDialog };
