// @flow
// $FlowFixMe waiting for update Flow
import { useCallback } from 'react';
// $FlowFixMe waiting for update Flow
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from 'react-apollo';

import { WORKSPACES_LIST_QUERY, UNDONE_INBOX_COUNT_QUERY } from 'graphql/queries';
import { TEAM_INVITATION_ACCEPT_MUTATION } from 'graphql/mutations';
import { i18n } from 'i18n';
import { ENDPOINT_URI, IGNORE_LIMIT_METRIC_ERROR, TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';

export const useAcceptInvite = () => {
  const history = useHistory();
  const location = useLocation();

  const [accept, { loading: acceptLoading, error }] = useMutation(TEAM_INVITATION_ACCEPT_MUTATION, {
    context: {
      [IGNORE_LIMIT_METRIC_ERROR]: true,
      [TOAST_SUCCESS_MESSAGE]: i18n.t('home.invite.acceptSuccessfull'),
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: WORKSPACES_LIST_QUERY,
      },
      {
        query: UNDONE_INBOX_COUNT_QUERY,
      },
    ],
  });

  const [decline, { loading: declineLoading }] = useMutation(TEAM_INVITATION_ACCEPT_MUTATION, {
    context: { [TOAST_SUCCESS_MESSAGE]: i18n.t('home.invite.declineSuccessfull') },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: UNDONE_INBOX_COUNT_QUERY,
      },
    ],
  });

  const onJoin = useCallback((id, endpoint) => async () => {
    if (!id) {
      return;
    }

    try {
      await accept({
        variables: {
          data: { id, accepted: true },
        },
        context: {
          [ENDPOINT_URI]: endpoint,
        },
      });
    } finally {
      history.replace(location.pathname);
    }
  }, [accept, history, location.pathname]);

  const onDecline = useCallback((id, endpoint) => async () => {
    if (!id) {
      return;
    }

    try {
      await decline({
        variables: {
          data: { id, accepted: false },
        },
        context: {
          [ENDPOINT_URI]: endpoint,
        },
      });
    } finally {
      history.replace(location.pathname);
    }
  }, [decline, history, location.pathname]);

  return {
    error,
    acceptLoading,
    declineLoading,
    onJoin,
    onDecline,
  };
};
