//@flow

// $FlowFixMe waiting for update Flow
import React, { useCallback } from 'react';
import * as R from 'ramda';
import { Dialog, Grid, Button, Text, Heading, Column, useModal } from '@8base/boost';
import { css } from '@emotion/core';

import { LIMITS_NAMES } from 'graphql/constants/billing';
import { Trans } from 'utils/translate';
import upgradeImage from 'images/upgrade.svg';
import { Form } from '@8base-react/forms';
import { useTranslation } from 'react-i18next';

import { useBillingInfo } from 'providers/BillingProvider/useBillingInfo';

const WARNING_LIMITS_DIALOG_ID = 'WARNING_LIMITS_DIALOG_ID';


const WarningLimitsDialog = () => {
  const { t } = useTranslation();
  const { plan: currentPlan } = useBillingInfo();
  const { closeModal, args: { limitMetricName, dialogId, onContinue }} = useModal(WARNING_LIMITS_DIALOG_ID);

  const limitMetrics = R.propOr([], 'limitMetrics', currentPlan);
  const limitMetricInfo = limitMetrics.find(({ name }) => name === limitMetricName) || {};

  const onClose = useCallback(() => {
    closeModal(WARNING_LIMITS_DIALOG_ID);
  }, [closeModal]);

  const onSubmit = useCallback(async () => {
    await onContinue();
    onClose();
    closeModal(dialogId);
  }, [closeModal, dialogId, onClose, onContinue]);


  let header = 'Premium feature';
  let title = 'This feature is not available to you.';
  let note = '';

  if (limitMetricName === LIMITS_NAMES.teamMembers) {
    header = t('dialogs.warningLimitsDialogDialog.teamMember.header', { defaultValue: 'Share Workspace' });
    title = t('dialogs.warningLimitsDialogDialog.teamMember.title', { defaultValue: 'Need an additional team member?' });
    note = (
      <Trans i18nKey="dialogs.warningLimitsDialogDialog.teamMember.note">
        You will be charged ${ limitMetricInfo.overragePrice || 0 } per month for an additional team member.
      </Trans>
    );
  }

  return (
    <Form onSubmit={ onSubmit }>
      { ({ handleSubmit, submitting }) => (
        <Dialog id={ WARNING_LIMITS_DIALOG_ID } onClose={ onClose } { ...E2E.$props('dialogs.warningLimitsDialog.body') }>
          <Dialog.Header title={ header } onClose={ onClose } />
          <Dialog.Body>
            <Column alignItems="center" css={ css`text-align: center;` }>
              <img src={ upgradeImage } alt="" />
              <Heading type="h3">
                { title }
              </Heading>
              <Text color="LIGHT_TEXT_COLOR">
                { note }
              </Text>
            </Column>
          </Dialog.Body>
          <Dialog.Footer>
            <Grid.Layout columns="1fr 1fr" stretch gap="md">
              <Grid.Box>
                <Button color="neutral" onClick={ onClose }>
                  <Trans i18nKey="shared.cancel" >
                    Cancel
                  </Trans>
                </Button>
              </Grid.Box>
              <Grid.Box>
                <Button
                  loading={ submitting }
                  onClick={ handleSubmit }
                  { ...E2E.$props('dialogs.warningLimitsDialogDialog.submitButton') }
                >
                  <Trans i18nKey="dialogs.warningLimitsDialog.upgrade">
                    Continue
                  </Trans>
                </Button>
              </Grid.Box>
            </Grid.Layout>

          </Dialog.Footer>
        </Dialog>
      ) }
    </Form>
  );
};

WarningLimitsDialog.ID = WARNING_LIMITS_DIALOG_ID;

export { WarningLimitsDialog };
