// @flow

import React from 'react';
import './ValueComponentPill.scss';
import { Pill } from 'common';

type ValueComponentProps = {
  children: React$Node,
  onRemove?: (string) => void,
  value: string,
}

// use this pill for valueComponent select prop
export const SelectValueComponentPill = ({ children, onRemove, value, ...restProps }: ValueComponentProps) => {
  const onClose = onRemove && (() => onRemove(value));

  return (
    <div styleName="value-component-margin">
      <Pill onClose={ onClose } { ...restProps } >{ children }</Pill>
    </div>
  );
};

