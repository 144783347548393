import gql from 'graphql-tag';

export const WORKSPACE_LEAVE_MUTATION = gql`
  mutation WorkspaceLeave($force: Boolean) {
    workspaceLeave(force: $force) {
      success
    }
  }
`;

export const WORKSPACE_CREATE_MUTATION = gql`
  mutation CreateWorkspace($data: WorkspaceCreateMutationInput!) {
    workspaceCreate(data: $data) {
      id
      name
    }
  }
`;

export const WORKSPACE_DELETE_MUTATION = gql`
  mutation WorkspaceDelete($data: WorkspaceDeleteMutationInput!) {
    workspaceDelete(data: $data) {
      success
    }
  }
`;


export const WORKSPACE_UPDATE_MUTATION = gql`
  mutation WorkspaceUpdate($data: SystemWorkspaceUpdateMutationInput!) {
    system {
      workspaceUpdate(data: $data) {
        id
        name
        description
        image {
          id
          downloadUrl
        }
      }
    }
  }
`;


export const WORKSPACE_CHANGE_OWNERSHIP = gql`
  mutation WorkspaceChangeOwnerShip($owner: String! $workspaceId: ID!) {
    system {
      workspaceTransferRegister(to: $owner, workspaceId: $workspaceId) {
        success
      }
    }
  }
`;

export const WORKSPACE_SHARE_WITH_ENVS_MUTATION = gql`
  mutation WorkspaceShareWithEnvs($email: String! $workspaceId: ID! $environmentRoles: [SystemOrganizationWorkspaceEnvironmentRolesInput]) {
    system {
      organizationWorkspaceUserShare(email: $email, workspaceId: $workspaceId environmentRoles: $environmentRoles) {
        success
      }
    }
  }
`;

export const WORKSPACE_ORGANIZATION_USER_REMOVE = gql`
  mutation WorkspaceOrganizationUserRemove($email: String! $workspaceId: String!) {
    system {
      organizationWorkspaceUserRemove(email: $email, workspaceId: $workspaceId) {
        success
      }
    }
  }
`;
