// @flow

import React from 'react';
import { Form, Field } from '@8base-react/forms';
import { Dialog, Button, InputField, Grid, SelectField, useModal } from '@8base/boost';
import { Mutation, Query } from 'react-apollo';

import { apolloSelectors } from 'graphql/selectors';
import { i18n } from 'i18n';
import { TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';
import { API_TOKEN_CREATE_MUTATION } from 'graphql/mutations';
import { ROLES_LIST_QUERY } from 'graphql/queries';
import { APITokenShowDialog } from 'dialogs/APITokenShowDialog';

const API_TOKEN_CREATE_DIALOG_ID = 'API_TOKEN_CREATE_DIALOG';

const APITokenCreateDialog = () => {
  const { openModal, closeModal } = useModal();

  return (
    <Dialog
      id={ API_TOKEN_CREATE_DIALOG_ID }
      size="sm"
      onClose={ () => closeModal(API_TOKEN_CREATE_DIALOG_ID) }
      { ...E2E.$props('settings.apiTokens.createDialog.body') }
    >
      { ({ args }) => (
        <Mutation
          mutation={ API_TOKEN_CREATE_MUTATION }
          refetchQueries={ ['ApiTokensList'] }
          context={{
            [TOAST_SUCCESS_MESSAGE]: i18n.t('settings.apiTokens.createSuccessfull'),
          }}
        >
          { (apiTokenCreate) => (
            <Query query={ ROLES_LIST_QUERY } fetchPolicy="network-only">
              { ({ data, loading }) => (
                <Form
                  type="CREATE"
                  tableSchemaName="ApiTokens"
                  onSubmit={ (data) => apiTokenCreate({ variables: { data }}) }
                  onSuccess={ ({ data: { apiTokenCreate }}) => {
                    closeModal(API_TOKEN_CREATE_DIALOG_ID);

                    openModal(APITokenShowDialog.ID, apiTokenCreate);

                    window.trackEvent('API Tokens', 'Create token');
                  } }
                >
                  { ({ handleSubmit, invalid, submitting, pristine }) => (
                    <form onSubmit={ handleSubmit }>
                      <Dialog.Header
                        title={ i18n.t('settings.apiTokens.createDialog.title') }
                        onClose={ () => closeModal(API_TOKEN_CREATE_DIALOG_ID) }
                      />
                      <Dialog.Body>
                        <Grid.Layout gap="md">
                          <Grid.Box>
                            <Field
                              name="name"
                              component={ InputField }
                              label="Name"
                              { ...E2E.$props('settings.apiTokens.createDialog.nameInput') }
                            />
                          </Grid.Box>
                          <Grid.Box>
                            <Field
                              name="roles"
                              component={ SelectField }
                              label="Roles"
                              options={ apolloSelectors.rolesList.getRolesListSelectOptions(data) }
                              loading={ loading }
                              multiple
                              { ...E2E.$props('settings.apiTokens.createDialog.rolesSelect') }
                            />
                          </Grid.Box>
                        </Grid.Layout>
                      </Dialog.Body>
                      <Dialog.Footer>
                        <Button
                          type="button"
                          color="neutral"
                          variant="outlined"
                          disabled={ submitting }
                          onClick={ () => closeModal(API_TOKEN_CREATE_DIALOG_ID) }
                          { ...E2E.$props('settings.apiTokens.createDialog.cancelButton') }
                        >
                          { i18n.t('shared.cancel') }
                        </Button>
                        <Button
                          type="submit"
                          disabled={ submitting || pristine || loading }
                          loading={ submitting }
                          { ...E2E.$props('settings.apiTokens.createDialog.submitButton') }
                        >
                          { i18n.t('shared.create') }
                        </Button>
                      </Dialog.Footer>
                    </form>
                  ) }
                </Form>
              ) }
            </Query>
          ) }
        </Mutation>
      ) }
    </Dialog>
  );


};

APITokenCreateDialog.ID = API_TOKEN_CREATE_DIALOG_ID;

export { APITokenCreateDialog };
