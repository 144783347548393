import gql from 'graphql-tag';

export const NOTIFICATION_UPDATE_MUTATION = gql`
  mutation NotificationUpdate($id: String!, $status: NotificationStatusType!) {
    system {
      notificationUpdate(id: $id, status: $status) {
        success
      }
    }
  }
`;
