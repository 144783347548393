// @flow

import fp from 'lodash/fp';
import classNames from 'classnames';

/** concat new stylename with stylenames string*/
export const concatStyle = (newStyle: string) => newStyle;

export const withPrefix = (newStyle: string, prefix?: string = '') =>
  fp.isEmpty(prefix)
    ? newStyle
    : `${prefix}-${newStyle}`;

/** concat style with string by the condition */
export const concatStylebyCond = (newStyle: string, cond?: boolean) =>
  cond ? concatStyle(newStyle) : '';

/** concat style with string by the condition */
export const switchStyleByCond = (styleTruthly: string, styleFalsy: string, cond?: boolean) =>
  cond ? concatStyle(styleTruthly) : concatStyle(styleFalsy);

/** map props value to classname */
export const mapPropToClassName = (prefix?: string, propValue?: ?string) =>
  propValue === undefined || propValue === null
    ? ''
    : concatStyle(withPrefix(propValue, prefix));

export const pipeClassNames = (...args: Array<string | { [ key:string ]: boolean | void }>) => classNames(...args);
