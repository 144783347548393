// @flow

import * as React from 'react';
import fp from 'lodash/fp';


type ElementType = any;

const isHtmlElement = (type: string) => fp.contains(type, ['div', 'span', 'a']);

type AddPropsToElementType = {
  <T: ElementType>(props: Object): (Element: T) => ?T,
  <T: ElementType>(props: Object, Element: T): ?T,
}

export const addPropsToElement: AddPropsToElementType = fp.curry(
  <T: ElementType>(props: Object, Element: T) => {
    if (!Element) return null;
    if (isHtmlElement(Element.type)) return Element;
    if (React.isValidElement(Element)) return React.cloneElement(Element, props);
    if (typeof Element === 'function') return <Element { ...props } />;
    return Element;
  },
);

type IsValidCurrentTypeElementType = {
  <T: ElementType>(TypeElement: T): (child: T) => T,
  <T: ElementType>(TypeElement: T, child: T): T,
}

export const isValidCurrentTypeElement: IsValidCurrentTypeElementType = fp.curry(
  <T: ElementType>(TypeElement: T, child: T) =>
    child.type.prototype instanceof TypeElement || child.type === TypeElement,
);

type IsValidElementForCurrentTypesType = {
  <T: ElementType>(typeElements: T[]): (child: T) => T,
  <T: ElementType>(typeElements: T[], child: T): T,
}

export const isValidElementForCurrentTypes : IsValidElementForCurrentTypesType = fp.curry(
  <T: ElementType>(typeElements: T[], child: T) =>
    fp.any(isValidCurrentTypeElement(fp.__, child))(typeElements),
);

type ExceptedChildrenTypeElement = {
  <T: ElementType>(props: Object): ((typeElements: T | T[]) => (child: T) => T) & ((typeElements: T | T[], child: T) => T);
  <T: ElementType>(props: Object, typeElements: T | T[]): (child: T) => T,
  <T: ElementType>(props: Object, typeElements: T | T[], child: T): T,
}

const isValidElementForCurrentTypeOrTypes = fp.cond([
  [fp.isArray, isValidElementForCurrentTypes],
  [fp.T, isValidCurrentTypeElement],
]);

export const renderExceptedChildrenTypeElement: ExceptedChildrenTypeElement = fp.curry(
  <T: ElementType>(props: Object, typeElements: T | React.ChildrenArray<T>, child: T) => fp.cond([
    [isValidElementForCurrentTypeOrTypes(typeElements), addPropsToElement(props)],
    [fp.T, () => null],
  ])(child),
);
