// @flow

import React from 'react';
import { pure } from 'recompose';
import { Checkbox } from '@8base/boost';

import './TableWorker.scss';


const getCellStyles = (width: number) => ({
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: `${width}px`,
  maxWidth: `${width}px`,
});


type HeadIndexCellProps = {|
  width: number,
  onSelectChange: (selected: boolean) => void,
  isSelected: boolean,
  indeterminate: boolean,
  withSelection?: boolean,
|}

export const HeadIndexCell: React$ComponentType<HeadIndexCellProps> = pure(
  ({ width, onSelectChange, isSelected, withSelection, indeterminate }) => (
    <div
      style={ getCellStyles(width) }
    >
      <If condition={ withSelection }>

        <div styleName="head-index-cell">
          <Checkbox
            name="table-head-checkbox"
            checked={ isSelected }
            indeterminate={ indeterminate }
            onChange={ onSelectChange }
          />
        </div>
      </If>
    </div>
  ),
);
HeadIndexCell.displayName = 'HeadIndexCell';

