// @flow

import * as R from 'ramda';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '@8base-react/auth';
import { APP_URL } from './url';


export type GuestRouteProps ={
  key?: string,
  path?: string,
  exact?: boolean,
  strict?: boolean,

  component?: React$ComponentType<*>,
  render?: (Object) => React$Node,
}

export const GuestRoute = ({ component: Component, render, children, ...rest }: GuestRouteProps) => {
  const { isAuthorized, isEmailVerified } = useAuth();

  return (
    <Route { ...rest }
      render={ props => {
        const componentToRender = R.isNil(render)
          ? Component && <Component { ...props } />
          : render && render(props);

        if (isAuthorized && isEmailVerified) {
          return (
            <Redirect
              to={{
                pathname: APP_URL.root,
                state: { from: props.location },
              }}
            />
          );
        } else if (isAuthorized && !isEmailVerified) {
          return (
            <Redirect
              to={{
                pathname: APP_URL.authConfirm,
                state: { from: props.location },
              }}
            />
          );
        } else {
          return componentToRender;
        }
      } }
    />
  );
};
