//@flow

import * as R from 'ramda';
import { createSelector } from 'reselect';

import type { Selector } from 'reselect';

import type { ApolloRolesListResponse } from './rolesList.selectors';

export type ApolloApiToken = $Shape<{
  id: string,
  name: string,
  token: string,
  roles: ApolloRolesListResponse,
}>;

export const getApiToken: Selector<
  ApolloApiToken,
  any,
  ApolloApiToken,
> = (authToken = {}) => authToken;

export const getId: Selector<
  ApolloApiToken,
  any,
  string,
> = createSelector(
  getApiToken,
  R.prop('id'),
);

export const getName: Selector<
  ApolloApiToken,
  any,
  string,
> = createSelector(
  getApiToken,
  R.prop('name'),
);

export const getRoles: Selector<
  ApolloApiToken,
  any,
  ApolloRolesListResponse,
> = createSelector(
  getApiToken,
  R.prop('roles'),
);

export const getToken: Selector<
  ApolloApiToken,
  any,
  string,
> = createSelector(
  getApiToken,
  R.prop('token'),
);

