// @flow
// $FlowFixMe waiting for update Flow
import React from 'react';
import * as R from 'ramda';

import { Dialog, useModal } from '@8base/boost';

import { PaymentWarning } from 'common/components';

export const PaymentWarningDialog = () => {
  const { closeModal, args = {}} = useModal(PaymentWarningDialog.ID);

  const newOwnerType = R.prop('newOwnerType', args);
  const newOwnerIdentity = R.prop('newOwnerIdentity', args);
  const isClosable = R.prop('isClosable', args);

  const isOpen = !!newOwnerType && !!newOwnerIdentity;

  return (
    <Dialog isOpen={ isOpen } size="sm" padding="xl" shouldCloseOnOverlayClick={ false }>
      <Choose>
        <When condition={ isClosable }>
          <PaymentWarning
            newOwnerType={ newOwnerType }
            newOwnerIdentity={ newOwnerIdentity }
            onClose={ () => closeModal(PaymentWarningDialog.ID) }
          />
        </When>
        <Otherwise>
          <PaymentWarning
            newOwnerType={ newOwnerType }
            newOwnerIdentity={ newOwnerIdentity }
          />
        </Otherwise>
      </Choose>
    </Dialog>
  );
};

PaymentWarningDialog.ID = 'PaymentWarningDialog';
