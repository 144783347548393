// @flow

import { SelectValueComponentPill } from './components';

const SelectComponents = {
  ValueComponentPill: SelectValueComponentPill,
};

export {
  SelectComponents,
};
