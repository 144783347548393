import amex from 'images/credit-cards/american-express-dark.svg';
import cartesBancaires from 'images/credit-cards/cartes-bancaires.svg';
import diners from 'images/credit-cards/diners-club-dark.svg';
import discover from 'images/credit-cards/discover-dark.svg';
import jcb from 'images/credit-cards/jcb-dark.svg';
import masterCard from 'images/credit-cards/master-card.svg';
import visa from 'images/credit-cards/visa.svg';
import unionPay from 'images/credit-cards/union-pay-dark.svg';

export const BRANDS_IMAGES = [
  {
    name: 'American Express',
    image: amex,
  },
  {
    name: 'Cartes Bancaires',
    image: cartesBancaires,
  }, {
    name: 'Diners Club',
    image: diners,
  }, {
    name: 'Discover',
    image: discover,
  }, {
    name: 'JCB',
    image: jcb,
  }, {
    name: 'MasterCard',
    image: masterCard,
  }, {
    name: 'Visa',
    image: visa,
  }, {
    name: 'UnionPay',
    image: unionPay,
  },
];
