// @flow
// $FlowFixMe
import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
// $FlowFixMe
import { useHistory, useParams, Redirect } from 'react-router-dom';
import styled from '@emotion/styled';

import { Heading, AsyncContent, useModal } from '@8base/boost';

import { TransferAcceptForm } from 'common/TransferAcceptForm';

import { useTransferOwnershipDetails, useAcceptTransfer } from 'graphql/hooks';

import { APP_URL } from 'common/routing';
import { PaymentWarningDialog } from 'dialogs/PaymentWarningDialog';
import { TransferDeclineWorkspaceDialog } from 'dialogs/TransferDeclineWorkspaceDialog';
import { WORKSPACE_KIND } from 'common/constants/workspaceKind';

const FormWrapper = styled.div`
  width: 700px;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 40px;
`;

const FormContainer = styled.div`
  border-radius: 5px;
  background-color: #FFFFFF;
`;

export const TransferAcceptContainer = () => {
  const { openModal } = useModal();
  const { id: transferId } = useParams();
  const history = useHistory();

  const {
    formattedData: {
      newOwnerName,
      newOwnerType,
      newOwnerIdentity,
      transferByName,
      workspaceName,
      workspaceKind,
      workspaceId,
      status,
      plans,
      card,
      region,
    },
    dataLoading,
  } = useTransferOwnershipDetails({
    transferId,
  });
  const { accept, acceptLoading } = useAcceptTransfer();

  useEffect(() => {
    if (!card) {
      openModal(PaymentWarningDialog.ID, {
        newOwnerType,
        newOwnerIdentity,
      });
    }
  }, [card, openModal, newOwnerType, newOwnerIdentity]);

  const initialValues = useMemo(() => {
    return {
      workspaceId,
      planId: '',
      cardToken: '',
      workspaceName,
      region,
      agreement: true,
    };
  }, [region, workspaceId, workspaceName]);

  const onAccept = useCallback(async ({ workspaceId, planId, workspaceName, cardToken }) => {
    try {
      await accept({
        variables: {
          workspaceId,
          planId,
          workspaceName,
          cardToken,
        },
      });

      const openAsBackend = workspaceKind === WORKSPACE_KIND;

      history.push(APP_URL.transferCompleted, {
        workspaceName,
        workspaceId,
        openAsBackend,
      });
    } catch (e) {}
  }, [accept, history, workspaceKind]);

  const handleSubmit = useCallback((values) => onAccept(values), [onAccept]);

  const onDecline = useCallback(async () => {
    const additionalText = newOwnerType === 'organization'
      ? `“${workspaceName}” to ${newOwnerName}`
      : `“${workspaceName}”`;

    openModal(TransferDeclineWorkspaceDialog.ID, {
      transferId,
      additionalText,
      handleDeclineCompleted: () => history.push(APP_URL.developerHome),
    });
  }, [newOwnerType, workspaceName, newOwnerName, openModal, transferId, history]);

  useLayoutEffect(() => {
    if (transferId && !dataLoading) {
      requestAnimationFrame(window.htmlLoader.hide);
    }
  });

  const isIncorrectLink = !transferId || (!dataLoading && !status);

  if (isIncorrectLink) {
    return <Redirect to={ APP_URL.transferIncorrect } />;
  }

  return (
    <AsyncContent loading={ dataLoading }>
      <FormWrapper>
        <StyledHeading type="h1">Workspace Transfer</StyledHeading>
        <FormContainer>
          <TransferAcceptForm
            initialValues={ initialValues }
            acceptLoading={ acceptLoading }
            handleSubmit={ handleSubmit }
            transferByName={ transferByName }
            workspaceName={ workspaceName }
            newOwnerName={ newOwnerName }
            newOwnerType={ newOwnerType }
            status={ status }
            onDecline={ onDecline }
            card={ card }
            plans={ plans }
          />
        </FormContainer>
      </FormWrapper>
    </AsyncContent>
  );
};
