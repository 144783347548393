// @flow
// $FlowFixMe
import React, { useMemo } from 'react';
import { Row, Column, Text, Link } from '@8base/boost';
import styled from '@emotion/styled';
import { APP_URL, useBuildUrl } from 'common/routing';
import { getClientUrl, getAppBuilderUrl } from 'utils/processEnv';
import workspaceDefaultLogo from 'images/workspace-default-logo.svg';
import { getDisplayWorkspaceKind } from 'utils';
import { useOnOpenWorkspace } from 'utils/hooks';
import { WORKSPACE_KIND } from 'common/constants/workspaceKind';

const WorkspaceImage = styled.img`
  flex-shrink: 0;
  object-fit: cover;

  width: 32px;
  height: 32px;
  margin-right: 18px;
  border: 1px solid ${props => props.theme.COLORS.SECONDARY_BORDER_COLOR};
  border-radius: 5px;
`;

export const WorkspaceCard = ({ image, kind, workspaceName, workspaceId }:*) => {
  const buildUrl = useBuildUrl();
  const url = useMemo(() => buildUrl(APP_URL.workspaceHome, { pathParams: { workspaceId }}), [buildUrl, workspaceId]);
  const clientUrl = getClientUrl();
  const appBuilderUrl = getAppBuilderUrl();
  const workspacePath = kind === WORKSPACE_KIND.frontend ? `${appBuilderUrl}${url}` : `${clientUrl}${url}`;

  const workspaceKind = useMemo(() => getDisplayWorkspaceKind(kind), [kind]);

  const onOpenWorkspaceClick = useOnOpenWorkspace();

  return (
    <Row alignItems="center">
      <WorkspaceImage src={ (image && image.downloadUrl) || workspaceDefaultLogo } />
      <Column gap="none" css={{ overflow: 'hidden' }}>
        <Link
          href={ workspacePath }
          component="span"
          onClick={ (event) => {
            event.preventDefault();
            onOpenWorkspaceClick({ workspaceId });
          } }
          css={{ maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{ workspaceName }
        </Link>
        <Text kind="small-1" color="GRAY_50">
          { workspaceKind }
        </Text>
      </Column>
    </Row>);
};
