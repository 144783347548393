// @flow
// $FlowFixMe waiting for update Flow
import React, { useCallback, useState } from 'react';
import * as R from 'ramda';
import { Button, Icon, InputField, Dialog, Text, useModal } from '@8base/boost';
import { css } from '@emotion/core';

import { useAbortTransfer } from 'graphql/hooks';

const CODE_WORD = 'Decline';

const HeaderTitle = () => {
  return (
    <div css={ css`
      display: flex;
      align-items: center;
      gap: 8px;
    ` }>
      <Icon
        name="DangerAlert"
        color="DANGER"
        size="custom"
        customSize="18px" />
      Transfer workspace declining
    </div>
  );
};

export const TransferDeclineWorkspaceDialog = () => {
  const { closeModal, args = {}} = useModal(TransferDeclineWorkspaceDialog.ID);

  const [input, setInput] = useState('');

  const transferId = R.prop('transferId', args);
  const additionalText = R.prop('additionalText', args);
  const handleDeclineCompleted = R.prop('handleDeclineCompleted', args);
  const cacheUpdate = R.prop('cacheUpdate', args);

  const { decline, declineLoading } = useAbortTransfer({ update: cacheUpdate });

  const handleClose = useCallback(() => {
    closeModal(TransferDeclineWorkspaceDialog.ID);
  }, [closeModal]);

  const handleDecline = useCallback(async () => {
    try {
      await decline({
        variables: {
          transferId,
        },
      });

      handleClose();
      handleDeclineCompleted && handleDeclineCompleted();
    } catch {}
  }, [decline, transferId, handleClose, handleDeclineCompleted]);

  return (
    <Dialog isOpen={ !!transferId } size="sm" padding="xl" shouldCloseOnOverlayClick={ false }>
      <Dialog.Header
        title={ <HeaderTitle /> }
        onClose={ handleClose }
      />
      <Dialog.Body css={ css` gap: 20px; ` }>
        <Text>
          You are declining transfer of workspace { additionalText }.
        </Text>
        <Text>
          Type “<Text weight="bold">Decline</Text>” to confirm declining.
        </Text>
        <InputField
          placeholder="Decline"
          input={{ name: 'input', onChange: setInput }}
        />
      </Dialog.Body>
      <Dialog.Footer>
        <Button
          color="neutral"
          onClick={ handleClose }
        >
          Cancel
        </Button>
        <Button
          loading={ declineLoading }
          disabled={ input !== CODE_WORD }
          onClick={ handleDecline }
        >
          Transfer Declining
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

TransferDeclineWorkspaceDialog.ID = 'TransferDeclineWorkspaceDialog';
