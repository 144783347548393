//@flow

import React from 'react';
import { css } from '@emotion/core';
import { Field } from '@8base-react/forms';
import { InputField, Grid, Form, Row } from '@8base/boost';

import { required } from 'utils/formValidations';
import { CardNumberElement, CardExpiryElement, CardCVCElement } from 'common/stripe';

export const StripeCardFormFields = () => {
  return (
    <React.Fragment>
      <Field
        name="cardHolder"
        type="text"
        label="Cardholder Name"
        placeholder="John Smith"
        component={ InputField }
        validate={ required }
        { ...E2E.$props('dialogs.billingUpdate.cardholderNameInput') }
      />
      <Form.Field label="Card Number" { ...E2E.$props('dialogs.billingUpdate.cardNumberField') }>
        <CardNumberElement />
      </Form.Field>
      <Row>
        <Form.Field label="Expiration Date" { ...E2E.$props('dialogs.billingUpdate.expirationDateField') }>
          <CardExpiryElement />
        </Form.Field>
        <Form.Field label="CVC" { ...E2E.$props('dialogs.billingUpdate.cvcField') }>
          <CardCVCElement />
        </Form.Field>
      </Row>
      <Form.Field label="Billing Address">
        <Grid.Layout
          css={ css`grid-gap: 12px; width: 100%;` }
          columns="1fr 1fr 1fr"
          autoRows="auto"
          areas={ [
            ['country', 'country', 'country'],
            ['street1', 'street1', 'street1'],
            ['street2', 'street2', 'street2'],
            ['city', 'state', 'zip'],
          ] }
        >
          <Grid.Box area="country">
            <Field name="country" component={ InputField } placeholder="Country" { ...E2E.$props('dialogs.billingUpdate.countryInput') } />
          </Grid.Box>
          <Grid.Box area="street1">
            <Field name="street1" component={ InputField } placeholder="Address Line 1" { ...E2E.$props('dialogs.billingUpdate.street1Input') } />
          </Grid.Box>
          <Grid.Box area="street2">
            <Field name="street2" component={ InputField } placeholder="Address Line 2" { ...E2E.$props('dialogs.billingUpdate.street2Input') } />
          </Grid.Box>
          <Grid.Box area="city">
            <Field name="city" component={ InputField } placeholder="City" { ...E2E.$props('dialogs.billingUpdate.cityInput') } />
          </Grid.Box>
          <Grid.Box area="state">
            <Field name="state" component={ InputField } placeholder="State" { ...E2E.$props('dialogs.billingUpdate.stateInput') } />
          </Grid.Box>
          <Grid.Box area="zip">
            <Field name="zip" component={ InputField } placeholder="Postal / ZIP" { ...E2E.$props('dialogs.billingUpdate.zipInput') } />
          </Grid.Box>
        </Grid.Layout>
      </Form.Field>
    </React.Fragment>
  );
};
