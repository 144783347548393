// @flow
// $FlowFixMe
import React, { useState } from 'react';

import { WorkspaceProxyContext } from './WorkspaceProxyContext';

type WorkspaceProxyProviderProps = {
  children: React$Node;
}

export const WorkspaceProxyProvider = ({ children } : WorkspaceProxyProviderProps) => {
  const [apiUri, setApiUri] = useState();
  const [webSocketUri, setWebSocketUri] = useState();
  const [workspaceListLoaded, setListLoaded] = useState(false);

  return (
    <WorkspaceProxyContext.Provider
      value={{
        apiUri,
        setApiUri,
        webSocketUri,
        setWebSocketUri,
        workspaceListLoaded,
        setListLoaded,
      }}
    >
      { children }
    </WorkspaceProxyContext.Provider>
  );
};

