// @flow
import styled from '@emotion/styled';

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xlg' | 'lgW';

export const HideOn = styled.div<{ breakpoints: Breakpoint[] }>(({ breakpoints = [] } = {}) => {
  let styles = '';

  if (breakpoints.includes('xs')) {
    styles +=
      `
  @media (max-width: 575px) {
    display: none;
  }
`;
  }

  if (breakpoints.includes('sm')) {
    styles +=
      `
  @media (min-width: 576px) and (max-width: 767px) {
    display: none;
  }
`;
  }

  if (breakpoints.includes('md')) {
    styles +=
      `
  @media (min-width: 768px) and (max-width: 991px) {
    display: none;
  }
`;
  }

  if (breakpoints.includes('lg')) {
    styles +=
      `
  @media (min-width: 992px) {
    display: none;
  }
`;
  }

  if (breakpoints.includes('lgW')) {
    styles +=
      `
  @media (min-width: 992px) and (max-width: 1199px) {
    display: none;
  }
`;
  }

  if (breakpoints.includes('xlg')) {
    styles +=
      `
  @media (min-width: 1200px) {
    display: none;
  }
`;
  }

  return styles;
});

export const Responsive = {
  HideOn,
};
