// @flow

import React from 'react';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import { css } from '@emotion/core';
import { Dialog, Button, ModalContext, Column, Text, Paragraph, InputField } from '@8base/boost';
import { Form, Field } from '@8base-react/forms';
import { graphql } from 'react-apollo';
import { TABLES_SCHEMA_QUERY } from '@8base-react/table-schema-provider';

import { withTranslation } from 'react-i18next';
import { Trans } from 'utils/translate';
import { TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';

const VIEW_DELETE_DIALOG_ID = 'VIEW_DELETE_DIALOG_ID';

const TABLE_DELETE_MUTATION = gql`
  mutation TableDelete($id: ID!) {
    tableDelete(data: {id: $id}) {
      success
    }
  }
`;

type ViewDeleteDialogProps = {
  t: Function,
  deleteTable: Function,
};

class ViewDeleteDialog extends React.Component<ViewDeleteDialogProps> {
  static contextType = ModalContext;

  args: Object;

  onSubmit = async () => {
    const { id } = this.args;

    await this.props.deleteTable({ variables: { id }});

    window.trackEvent('Data Builder', 'Delete table');

    this.context.closeModal(VIEW_DELETE_DIALOG_ID);
  };

  onClose = () => {
    this.context.closeModal(VIEW_DELETE_DIALOG_ID);
  };

  validate = (value) => value === this.args.name ? null : this.props.t('validation.valueNoMatch');

  renderFormContent = ({ handleSubmit, invalid, submitting, pristine }) => {
    const { t } = this.props;
    const { name } = this.args;

    return (
      <form onSubmit={ handleSubmit }>
        { /* Hidden submit button for submit by Enter */ }
        <input type="submit" style={{ display: 'none' }} />
        <Dialog.Header title={ t('dataBuilder.viewDeleteDialog.title') } onClose={ this.onClose } />
        <Dialog.Body>
          <Column gap="lg">
            <Paragraph css={ css`width: 100%;` } align="center">
              <Trans i18nKey="dataBuilder.viewDeleteDialog.note">
                You are deleting the view <Text weight="bold">{ name }</Text>.<br />
                Continuing this action will result in data loss.
              </Trans>
            </Paragraph>
            <Field
              name="name"
              type="text"
              placeholder="Type the view's name to delete it"
              validate={ this.validate }
              component={ InputField }
              { ...E2E.$props('app.dataBuilder.viewDeleteDialog.nameInput') }
              { ...PT.$props('viewDeleteDialog.cancelBtn') }
            />
          </Column>
        </Dialog.Body>
        <Dialog.Footer>
          <Button color="neutral" variant="outlined" disabled={ submitting } onClick={ this.onClose } { ...PT.$props('viewDeleteDialog.cancelBtn') }>Cancel</Button>
          <Button
            color="danger"
            type="submit"
            disabled={ invalid || pristine }
            loading={ submitting }
            { ...E2E.$props('app.dataBuilder.viewDeleteDialog.submitBtn') }
            { ...PT.$props('viewDeleteDialog.submitBtn') }
          >Delete
          </Button>
        </Dialog.Footer>
      </form>
    );
  };

  renderForm = ({ args }) => {
    this.args = args;

    return (
      <Form onSubmit={ this.onSubmit } >
        { this.renderFormContent }
      </Form>
    );
  };

  render() {
    return (
      <Dialog id={ VIEW_DELETE_DIALOG_ID } size="md" onClose={ this.onClose }>
        { this.renderForm }
      </Dialog>
    );
  }
}

ViewDeleteDialog = compose(
  withTranslation(),
  graphql(TABLE_DELETE_MUTATION, {
    name: 'deleteTable',
    options: ({ t }) => ({
      refetchQueries: [
        { query: TABLES_SCHEMA_QUERY },
      ],
      context: {
        [TOAST_SUCCESS_MESSAGE]: t('dataBuilder.viewDeleteDialog.success'),
      },
    }),
  }),
)(ViewDeleteDialog);

// $FlowFixMe
ViewDeleteDialog.ID = VIEW_DELETE_DIALOG_ID;

export { ViewDeleteDialog };
