// @flow

import * as R from 'ramda';
import { createSelector } from 'reselect';
import type { Selector } from 'reselect';

import { PROFILE_TYPE } from 'graphql/constants';

import type { ApolloAuthenticationProfile } from './authenticationProfile.selectors';

export type ApolloAuthenticationProfilesListResponse = {
  items: ApolloAuthenticationProfile[],
  count: number,
};

export const getAuthenticationProfilesListResponse: Selector<
  ApolloAuthenticationProfilesListResponse,
  any,
  ApolloAuthenticationProfilesListResponse,
> = (authenticationProfilesListResponse = { items: [], count: 0 }) =>
  authenticationProfilesListResponse;

export const getAuthenticationProfilesList: Selector<
  ApolloAuthenticationProfilesListResponse,
  any,
  ApolloAuthenticationProfile[],
> = createSelector(
  getAuthenticationProfilesListResponse,
  R.prop('items'),
);


export const getLegacyDefaultProfile: Selector<
  ApolloAuthenticationProfilesListResponse,
  any,
  ApolloAuthenticationProfile | void,
  > = createSelector(
    getAuthenticationProfilesList,
    (profilesList: ApolloAuthenticationProfile[]) => profilesList.find(profile => profile.type === PROFILE_TYPE['8BASE']),
  );

export const getCognitoDefaultProfile: Selector<
  ApolloAuthenticationProfilesListResponse,
  any,
  ApolloAuthenticationProfile | void,
  > = createSelector(
    getAuthenticationProfilesList,
    (profilesList: ApolloAuthenticationProfile[]) => profilesList.find(profile => profile.type === PROFILE_TYPE['8BASE_COGNITO']),
  );

export const getDefaultProfile: Selector<
  ApolloAuthenticationProfilesListResponse,
  any,
  ApolloAuthenticationProfile | void
  > = createSelector(
    getLegacyDefaultProfile,
    getCognitoDefaultProfile,
    (legacyDefaultProfile, cognitoDefaultProfile) => cognitoDefaultProfile || legacyDefaultProfile,
  );

export const hasLegacyDefaultProfile: Selector<
  ApolloAuthenticationProfilesListResponse,
  any,
  boolean,
> = createSelector(
  getLegacyDefaultProfile,
  Boolean,
);

export const hasCognitoDefaultProfile: Selector<
  ApolloAuthenticationProfilesListResponse,
  any,
  boolean,
  > = createSelector(
    getCognitoDefaultProfile,
    Boolean,
  );

export const getAuthenticationProfilesCount: Selector<
  ApolloAuthenticationProfilesListResponse,
  any,
  number
> = createSelector(
  getAuthenticationProfilesListResponse,
  R.prop('count'),
);

export const getAuthenticationProfileById: Selector<
  ApolloAuthenticationProfilesListResponse,
  string,
  ApolloAuthenticationProfile | void,
> = createSelector(
  getAuthenticationProfilesList,
  (_, id) => id,
  (authenticationProfilesList, id) => R.find(
    R.propEq('id', id),
  )(authenticationProfilesList),
);

