// @flow

import fp from 'lodash/fp';
import { logger } from 'utils';
import { isDevelopment } from 'utils/processEnv';

export const propEq = (fieldName: string, checkValue: any): * => fp.pipe(fp.get(fieldName), fp.equals(checkValue));

type SafeGet = {
  <T: Object, K: $Keys<T>>(path: K): (object?: T) => $ElementType<T, K>,
  <T: Object, K: $Keys<T>>(path: K, object?: T): $ElementType<T, K>,
}

/** should throw error to the concole if the field not found. needs for the apollo selectors */
export const safeGet: SafeGet = fp.curry(
  (fieldKey: *, object: *) => {
    const field = fp.get(fieldKey, object);

    if (fp.isUndefined(field) && !fp.isNil(object) && isDevelopment()) {
      logger.error(`Field ${fieldKey} is undefined`);
    }

    return field;
  },
);

type RenameKeyType = string | string[];

type RenameKey = {
  <T: RenameKeyType>(key: T): ((newKey:T) => (obj: Object) => Object) & ((newKey: T, obj: Object) => Object);
  <T: RenameKeyType>(key: T, newKey: T): (obj: Object) => Object,
  <T: RenameKeyType>(key: T, newKey: T, obj: Object): Object,
}

export const renameKey: RenameKey = fp.curry(
  <T: RenameKeyType>(key: T, newKey: T, obj: Object): Object => {
    if (!fp.has(key)) return obj;
    const value = fp.get(key, obj);

    // $FlowFixMe
    return fp.pipe(
      fp.set(newKey, value),
      fp.unset(key),
    )(obj);
  },
);


export const mapValuesDeep = fp.curry(
  (callbackFunction: Function, object: Object) => fp.cond([
    [fp.isArray, fp.map(mapValuesDeep(callbackFunction))],
    [fp.isObject, fp.mapValues(mapValuesDeep(callbackFunction))],
    [fp.T, callbackFunction],
  ])(object),
);

export const forOwnDeep = fp.curry(
  (callbackFunction: Function, object: Object) => fp.cond([
    [fp.isArray, fp.map(forOwnDeep(callbackFunction))],
    [fp.isObject, () => {
      callbackFunction(object);
      fp.forOwn(forOwnDeep(callbackFunction), object);
    }],
    [fp.T, callbackFunction],
  ])(object),
);

export const hasInDeep = fp.curry(
  (predicate: Function, collection: Object) => {
    const values = [];
    const callbackFunction = (value, key, object) =>
      predicate(value, key, object)
        ? values.push(value)
        : null;

    forOwnDeep(callbackFunction, collection);
    return !fp.isEmpty(values);
  },
);
