/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Button, Column, Text, Row, Divider, Link } from '@8base/boost';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { Trans } from 'utils/translate';
// $FlowFixMe
import { ReactComponent as Logo } from 'images/8base-appbuilder-logo.svg';
import { ReactComponent as FacebookIcon } from 'images/logos/facebook-rounded.svg';
import { ReactComponent as MessengerIcon } from 'images/logos/facebook-messenger.svg';
import { ReactComponent as WhatsappIcon } from 'images/logos/whatsapp.svg';
import { ReactComponent as TwitterIcon } from 'images/logos/twitter.svg';
import { ReactComponent as LinkedinIcon } from 'images/logos/linkedin.svg';
import { ReactComponent as RedditIcon } from 'images/logos/reddit-rounded.svg';
import { ReactComponent as MailIcon } from 'images/icons/icon-envelope.svg';

import PROMO_IMG from 'images/8base-appbuilder-promo.png';

// type DeveloperHomeWorkspacesBetaProps = {
//   onSingUpClick: () => any;
//   user: {
//     id: string;
//     email: string;
//     status: string;
//     shareLink: string;
//     shareSignupsCount: number;
//     waitlistPosition: number;
//   };
//   addUserLoading?: boolean;
// }

const RedText = styled.span`
  color: ${({ theme }) => theme.COLORS.RED_30};
`;

const IMG_CSS = css`
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  @media (max-height: 850px) {
    display: none;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const WRAPPER_CSS = css`
  z-index: 2;
  overflow: auto;
  width: 100%;
  padding: 48px 0;
`;

const DIVIDER_CSS = css`
  max-width: 400px;
`;

const SMALL_SHARE_WRAPPER_CSS = css`
  @media (min-width: 1180px) {
    display: none;
  }
`;

const SHARE_WRAPPER_CSS = css`
  @media (max-width: 1181px) {
    display: none;
  }
`;

const SHARE_BTN_CSS = css`
  color: #fff;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  & svg {
    height: 100%;
  }
`;

const getLink = (link) => {
  return link.replace('://', '%3A%2F%2F').replace('/', '%2F').replace('?r=', '%3Fr%3D');
};

const ShareIcons = ({ shareLink }) => {
  return (
    <Row gap="md">
      <a
        href={ `https://www.facebook.com/sharer.php?u=${getLink(shareLink)}` }
        target="_blank"
        css={ css`
          ${SHARE_BTN_CSS}
          background: #425993;
        ` }
      >
        <span
          css={ css`
            display: flex;
            height: 20px;
            width: 20px;
          ` }
        >
          <FacebookIcon />
        </span>
        <span css={ css`margin-left: 8px;` }>Share</span>
      </a>
      <a
        href={ `https://i.prefinery.com/projects/zadpxuhg/share/redirect/messenger?message=${getLink(shareLink)}%26utm_source%3Dmessenger%26utm_medium%3Dreferral` }
        target="_blank"
        css={ css`
          ${SHARE_BTN_CSS}
          background: #3883F7;
        ` }
      >
        <span
          css={ css`
            display: flex;
            height: 20px;
            width: 20px;
          ` }
        >
          <MessengerIcon />
        </span>
        <span css={ css`margin-left: 8px;` }>Message</span>
      </a>
      <a
        href={ `https://i.prefinery.com/projects/zadpxuhg/share/redirect/whatsapp?message=Sign%20up%20for%20App%20Builder%21%20-%20${getLink(shareLink)}%26utm_source%3Dwhatsapp%26utm_medium%3Dreferral` }
        target="_blank"
        css={ css`
          ${SHARE_BTN_CSS}
          background: #64D173;
        ` }
      >
        <span
          css={ css`
            display: flex;
            height: 20px;
            width: 20px;
          ` }
        >
          <WhatsappIcon />
        </span>
        <span css={ css`margin-left: 8px;` }>Send</span>
      </a>
      <a
        href={ `https://twitter.com/intent/tweet?url=${getLink(shareLink)}&text=Sign%20up%20for%20App%20Builder%21` }
        target="_blank"
        css={ css`
          ${SHARE_BTN_CSS}
          background: #6AAAE8;
          ` }
      >
        <span
          css={ css`
            display: flex;
            height: 20px;
            width: 20px;
          ` }
        >
          <TwitterIcon />
        </span>
        <span css={ css`margin-left: 8px;` }>Tweet</span>
      </a>
      <a
        href={ `https://www.linkedin.com/shareArticle?mini=true&url=${getLink(shareLink)}&title=Sign%20up%20for%20App%20Builder%21` }
        target="_blank"
        css={ css`
          ${SHARE_BTN_CSS}
          background: #3275B0;
        ` }
      >
        <span
          css={ css`
            display: flex;
            height: 20px;
            width: 20px;
          ` }
        >
          <LinkedinIcon />
        </span>
        <span css={ css`margin-left: 8px;` }>Share</span>
      </a>
      <a
        href={ `https://www.reddit.com/submit?url=${getLink(shareLink)}` }
        target="_blank"
        css={ css`
          ${SHARE_BTN_CSS}
          background: #EC5529;
        ` }
      >
        <span
          css={ css`
            display: flex;
            height: 20px;
            width: 20px;
          ` }
        >
          <RedditIcon />
        </span>
        <span css={ css`margin-left: 8px;` }>Share</span>
      </a>
      <a
        className="btn btn-lg btn-social btn-email"
        href={ `mailto:?subject=Check%20out%20App%20Builder%21&body=I%20just%20signed%20up%20for%20App%20Builder%20and%20thought%20you%20might%20be%20interested%2C%20too%21%0A%0A${getLink(shareLink)}%26utm_source%3Demail%26utm_medium%3Dreferral` }
        target="_top"
        css={ css`
          ${SHARE_BTN_CSS}
          background: #7D7D7E;
        ` }
      >
        <span
          css={ css`
            display: flex;
            height: 20px;
            width: 20px;
          ` }
        >
          <MailIcon />
        </span>
        <span css={ css`margin-left: 8px;` }>Email</span>
      </a>
    </Row>
  );
};


const ShareIconsSmall = ({ shareLink }) => {
  return (
    <Row gap="md">
      <a
        href={ `https://www.facebook.com/sharer.php?u=${getLink(shareLink)}` }
        target="_blank"
        css={ css`
          width: 30px;
          height: 30px;
          color: #3d5b97;
        ` }
      >
        <FacebookIcon />
      </a>
      <a
        href={ `https://i.prefinery.com/projects/zadpxuhg/share/redirect/messenger?message=${getLink(shareLink)}%26utm_source%3Dmessenger%26utm_medium%3Dreferral` }
        target="_blank"
        css={ css`
            width: 30px;
            height: 30px;
            background: #2ab0e3;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            & svg {
              width: 100%;
            }
        ` }
      >
        <span
          css={ css`
            width: 16px;
            display: flex;
            color: #fff;
          ` }
        >
          <MessengerIcon />
        </span>
      </a>
      <a
        href={ `https://i.prefinery.com/projects/zadpxuhg/share/redirect/whatsapp?message=Sign%20up%20for%20App%20Builder%21%20-%20${getLink(shareLink)}%26utm_source%3Dwhatsapp%26utm_medium%3Dreferral` }
        target="_blank"
        css={ css`
            width: 30px;
            height: 30px;
            background: #5bba67;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            & svg {
              width: 100%;
            }
        ` }
      >
        <span
          css={ css`
            width: 16px;
            display: flex;
            color: #fff;
          ` }
        >
          <WhatsappIcon />
        </span>
      </a>
      <a
        href={ `https://twitter.com/intent/tweet?url=${getLink(shareLink)}&text=Sign%20up%20for%20App%20Builder%21` }
        target="_blank"
        css={ css`
            width: 30px;
            height: 30px;
            background: #5bacec;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            & svg {
              width: 100%;
            }
        ` }
      >
        <span
          css={ css`
            width: 18px;
            display: flex;
            color: #fff;
          ` }
        >
          <TwitterIcon />
        </span>
      </a>
      <a
        href={ `https://www.linkedin.com/shareArticle?mini=true&url=${getLink(shareLink)}&title=Sign%20up%20for%20App%20Builder%21` }
        target="_blank"
        css={ css`
            width: 30px;
            height: 30px;
            background: #2a8ebe;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            & svg {
              width: 100%;
            }
        ` }
      >
        <span
          css={ css`
            width: 16px;
            display: flex;
            color: #fff;
          ` }
        >
          <LinkedinIcon />
        </span>
      </a>
      <a
        href={ `https://www.reddit.com/submit?url=${getLink(shareLink)}` }
        target="_blank"
        css={ css`
          width: 30px;
          height: 30px;
          color: #f34c1d;
        ` }
      >
        <RedditIcon />
      </a>
      <a
        className="btn btn-lg btn-social btn-email"
        href={ `mailto:?subject=Check%20out%20App%20Builder%21&body=I%20just%20signed%20up%20for%20App%20Builder%20and%20thought%20you%20might%20be%20interested%2C%20too%21%0A%0A${getLink(shareLink)}%26utm_source%3Demail%26utm_medium%3Dreferral` }
        target="_top"
        css={ css`
            width: 30px;
            height: 30px;
            background: #444444;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            & svg {
              width: 100%;
            }
        ` }
      >
        <span
          css={ css`
            width: 16px;
            display: flex;
            color: #fff;
          ` }
        >
          <MailIcon />
        </span>
      </a>
    </Row>
  );
};

export const DeveloperHomeWorkspacesBeta = ({
  onSingUpClick,
  user,
  addUserLoading = false,
}) => {

  return (
    <>
      <Choose>
        <When condition={ !!user }>
          <div css={ css`width: 100%; z-index: 2;` } >
            <Column alignItems="center" justifyContent="center" gap="md">
              <Text kind="h2" align="center">
                You're Registered for Beta!
              </Text>
              <Text align="center">
                We have added your account to the App Builder waiting list and your currently position in line is: <br />
                <Text weight="bold" kind="subtitle" >{ user ? user.waitlistPosition : 0 }</Text>
              </Text>
              <Divider noOffset css={ DIVIDER_CSS } />
              <Text kind="h2" align="center">
                Want to cut the line and get early access?
              </Text>
              <Text align="center">
                Get 5 of your friends to signup with this unique link:
              </Text>
              <Link href={ user ? user.shareLink : '' } target="_blank" rel="noopener noreferrer" > { user ? user.shareLink : '' } </Link>
              <div css={ SHARE_WRAPPER_CSS }>
                <ShareIcons shareLink={ user.shareLink } />
              </div>
              <div css={ SMALL_SHARE_WRAPPER_CSS }>
                <ShareIconsSmall shareLink={ user.shareLink } />
              </div>
              <Divider noOffset css={ DIVIDER_CSS } />
              <Text align="center" kind="h2">
                So far you have { user ? user.shareSignupsCount : 0 } referrals
              </Text>
              <Text align="center">
                Every time a friend clicks your link, and then signs up, you get even closer to receiving early access.
              </Text>
            </Column>
          </div>
        </When>

        <Otherwise>
          <div css={ WRAPPER_CSS }>
            <Column alignItems="center" justifyContent="center" gap="md" >
              <Logo />
              <Text kind="h1" align="center">
                <Trans i18nKey="developerHome.frontendBeta.title">
                  <RedText>
                App Builder { ' ' }
                  </RedText>
                beta is coming...
                </Trans>
              </Text>
              <Text align="center">
                <Trans i18nKey="developerHome.frontendBeta.description">
                8base App Builder greatly accelerates frontend development through <br /> a powerful in-browser low-code IDE.
                </Trans>
              </Text>
              <Button loading={ addUserLoading } onClick={ onSingUpClick } css={ css`flex: 40px 0 0;` }>
                <Trans i18nKey="developerHome.frontendBeta.btn.register">
                Register for Beta
                </Trans>
              </Button>
            </Column>
          </div>
          <img
            css={ IMG_CSS }
            src={ PROMO_IMG }
            alt="8base appbuilder"
          />
        </Otherwise>
      </Choose>
      </>
  );
};
