// @flow

import styled from '@emotion/styled';
import { Link } from '@8base/boost';

const UserFlowLink = styled(Link)({
  fontWeight: '600',
  color: '#0874F9',
});

export { UserFlowLink };
