// @flow

import gql from 'graphql-tag';

export const Avatar = gql`
  fragment Avatar on User {
    id
    firstName
    avatar {
      id
      fileId
      public
      filename
      downloadUrl
    }
  }
`;

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    createdAt
    email
    firstName
    lastName
    status
    origin
    avatar {
      id
      fileId
      public
      filename
      downloadUrl
    }
    roles {
      items {
        id
        name
      }
    }
  }
`;

export const UserAccountInfoFragment = gql`
  fragment UserAccountInfoFragment on SystemMemberAccountInfo {
    email
    createdAt
    firstName
    lastName
    timezone
    learningMode
    role
    type
    isDeveloper
    companyName
    projectDescription
    website
    githubUsername
    linkedInUsername
    twitterUsername
    aboutMe

    country
    addressLine1
    addressLine2
    city
    state
    zipCode

    avatar {
      id
      downloadUrl
    }
  }
`;

export const PrefineryUserFragment = gql`
  fragment PrefineryUserFragment on PrefineryUser {
    id
    email
    status
    shareLink
    shareSignupsCount
    waitlistPosition
  }
`;
