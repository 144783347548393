//@flow

import React, { PureComponent } from 'react';
import { compose, setDisplayName } from 'recompose';

import { withFieldFormMockup, mapReduxFormInput } from 'utils/hocs/forms';
import { pipeClassNames, mapPropToClassName, concatStylebyCond } from 'utils/styles';

import './SwitchDirection.scss';

type OrderOptions = 'ASC' | 'DESC';

const consvertBooltoOrder = (checked: boolean) => checked ? 'ASC' : 'DESC';
const isAscOrder = (order: OrderOptions) => order === 'ASC';

/**
 * @prop {*} type possible types of the control
 */
type SwitchDirectionProps = {
  name: string,
  value: OrderOptions,
  required?: boolean,
  disabled?: boolean,
  error?: boolean,
  onChange?: (value: OrderOptions, event?: Event) => void,

  type: ?'alphabet' | 'numeric' | 'date',
}

/** component that shows direction control */
export class SwitchDirection extends PureComponent<SwitchDirectionProps> {
  preventClick = (event: Event) => event.stopPropagation();

  onChange = (event: any) => {
    const { onChange } = this.props;
    onChange && onChange(consvertBooltoOrder(event.target.checked), event);
  };

  render() {
    const { required, disabled, error, type, name, value } = this.props;

    const switchDirectionStyleName = pipeClassNames(
      'switch-direction',
      mapPropToClassName('switch-direction-', type),
      concatStylebyCond('error', error),
    );

    const toggleSwitchStyleName = pipeClassNames(
      concatStylebyCond('toggle-switch--checked', isAscOrder(value)),
      'toggle-switch',
    );

    return (
      <div styleName={ switchDirectionStyleName } onClick={ this.preventClick }>
        <input type="checkbox"
          id={ name }
          checked={ isAscOrder(value) }
          onChange={ this.onChange }
          required={ required }
          disabled={ disabled } />
        <label htmlFor={ name } styleName={ toggleSwitchStyleName }>
          <div styleName="asc" />
          <div styleName="desc" />
        </label>
      </div>
    );
  }
}


export const SwitchDirectionField = compose(
  setDisplayName('SelectTabSearchField'),
  withFieldFormMockup,
  mapReduxFormInput(),
)(SwitchDirection);
