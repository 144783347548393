// @flow
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Row, Text } from '@8base/boost';

import type { BillingDetailsResponse } from 'graphql/__generated__';

import { BRANDS_IMAGES } from 'common/constants/brands';

type BankCardRowProps = {
  card: BillingDetailsResponse | null
}

const BankCardImageTag = styled.img`
  height: 24px;
`;

export const BankCardRow = ({ card }: BankCardRowProps) => {
  const bankCardBrandImage = !!card ? BRANDS_IMAGES.find(({ name }) => name === card.brand) || {} : {};

  return (
    <Row alignItems="center" gap="sm">
      <BankCardImageTag src={ bankCardBrandImage.image } alt={ bankCardBrandImage.name } />
      <Text css={ css`white-space: nowrap;` }>{ bankCardBrandImage.name }</Text>
    </Row>
  );
};

