// @flow

import React from 'react';
import { css } from '@emotion/core';

const dividerClassName = css`
  margin-top: 10px;
  height: 1px;
  background-color: #d0d7dd;
  width: 100%;
  margin-bottom: 10px;
`;

export const AppFlowSecondaryNavDivider = () => (
  <div css={ dividerClassName } />
);

