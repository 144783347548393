import { WORKSPACE_KIND } from 'common/constants/workspaceKind';
import { APP_URL } from 'common/routing';
import { getAppBuilderUrl, getClientUrl } from 'utils/processEnv';

export const getWorkspaceUrl = ({ workspace, buildUrl }) => {
  if (!workspace) {
    return;
  }

  const url = buildUrl(APP_URL.workspaceHome, { pathParams: { workspaceId: workspace.id }});

  if (workspace.kind === WORKSPACE_KIND.frontend) {
    const appBuilderUrl = getAppBuilderUrl();

    return `${appBuilderUrl}${url}`;
  }

  const clientUrl = getClientUrl();

  return `${clientUrl}${url}`;
};
