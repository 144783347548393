// @flow

import { TEXT_FORMATS } from '@8base/utils';
import htmlToDraft from 'html-to-draftjs';
// $FlowFixMe
import { ContentState, convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import draftToHtml from 'draftjs-to-html';


export const mapFormat = (format: string) => {
  switch (format) {
    case TEXT_FORMATS.MARKDOWN:
      return 'markdown';
    case TEXT_FORMATS.HTML:
      return 'html';
    default:
      return 'markdown';
  }
};

export const getInitialEditorState = (content: string | null | typeof undefined, format: string) => {
  const editorFormat = mapFormat(format);

  const formatContent = () => {
    if (editorFormat === 'html') {
      const formatted = htmlToDraft(content);
      if (formatted && formatted.contentBlocks) {
        return ContentState.createFromBlockArray(formatted.contentBlocks);
      }
      return null;
    } else if (editorFormat === 'markdown') {
      const formatted = markdownToDraft(content);
      const rawObject = convertFromRaw(formatted);

      return rawObject;
    }

    return null;
  };

  const contentState = formatContent();

  if (contentState) {
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }

  const editorState = EditorState.createEmpty();
  return editorState;
};

export const getFormattedValue = (format: string, value: EditorState) => {
  if (!value) {
    return null;
  }

  const editorFormat = mapFormat(format);

  const getStringValue = () => {
    if (editorFormat === 'html') {
      return draftToHtml(convertToRaw(value.getCurrentContent()));
    }

    return draftToMarkdown(convertToRaw(value.getCurrentContent()));
  };

  return getStringValue();
};

export const TOOLBAR_CONFIG = {
  options: ['inline', 'blockType', 'list', 'link', 'image', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
  },
  list: {
    options: ['unordered', 'ordered'],
  },
};
