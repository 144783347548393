import styled from '@emotion/styled';
import { Tag } from '@8base/boost';

export const CountTag = styled(Tag)`
  font-size: 10px;
  padding: 2px 3px;
  height: 16px;
  line-height: 14px;
  border: 0;
  min-width: 16px;
  background: ${({ color, theme }) => {
    if (color === 'RED') return theme.COLORS.RED_30;
    if (color === 'BLUE') return theme.COLORS.BLUE_10;
    return theme.COLORS.GRAY_20;
  }};
  color: ${({ color = 'GRAY', theme }) => {
    if (color !== 'GRAY') return theme.COLORS.WHITE;
    return theme.COLORS.GRAY_50;
  }};

  & > span {
    width: 100%;
    text-align: center;
  }
`;
