import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class ImageHeadCommonNavigationLeftCommonHelper extends Component {

  static propTypes = {
    image: PropTypes.string,
    icon: PropTypes.string,
  };

  render() {
    const { icon, image } = this.props;
    return (
      <Choose>
        <When condition={ image }>
          <img className="nav-icon" src={ image } alt="Image" />
        </When>
        <When condition={ icon }>
          <span css={ `nav-icon icon-${icon}` } />
        </When>
      </Choose>
    );
  }
}
