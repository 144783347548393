// @flow

import * as R from 'ramda';
import { createSelector } from 'reselect';
import { DateTime } from 'luxon';

import type { UserAccountInfoResponse } from '../hooks';

export const getUserAccountInfo = (userAccountInfo: UserAccountInfoResponse) => userAccountInfo || {};

export const getId = createSelector(getUserAccountInfo, R.prop('id'));
export const getUserCreatedAt = createSelector(getUserAccountInfo, R.prop('createdAt'));
export const getFirstName = createSelector(getUserAccountInfo, R.prop('firstName'));
export const getLastName = createSelector(getUserAccountInfo, R.prop('lastName'));
export const getEmail = createSelector(getUserAccountInfo, R.prop('email'));
export const getAvatar = createSelector(getUserAccountInfo, R.prop('avatar'));
export const getLearningMode = createSelector(getUserAccountInfo, R.prop('learningMode'));
export const getPermissions = createSelector(getUserAccountInfo, R.prop('permissions'));
export const getRole = createSelector(getUserAccountInfo, R.prop('role'));
export const getIsDeveloper = createSelector(getUserAccountInfo, R.prop('isDeveloper'));
export const getType = createSelector(getUserAccountInfo, R.prop('type'));
export const getCompanyName = createSelector(getUserAccountInfo, R.prop('companyName'));
export const getProjectDescription = createSelector(getUserAccountInfo, R.prop('projectDescription'));


export const getFullName = createSelector(
  getFirstName,
  getLastName,
  (firstName = '', lastName = '') => `${firstName} ${lastName}`,
);


export const getAvatarUrl = createSelector(
  getAvatar,
  R.prop('downloadUrl'),
);


export const getIsFirstOnboardingStepCompleted = createSelector(
  getUserAccountInfo,
  userAccountInfo =>
    !!userAccountInfo.firstName &&
    !!userAccountInfo.lastName &&
    !R.isNil(userAccountInfo.isDeveloper),
);

export const getIsDeveloperOnboardingStepCompleted = createSelector(
  getUserAccountInfo,
  getIsFirstOnboardingStepCompleted,
  (userAccountInfo, isFirstStepCompleted) =>
    isFirstStepCompleted &&
    userAccountInfo.isDeveloper === true &&
    !!userAccountInfo.type &&
    !!userAccountInfo.role,
);

export const getIsNonDeveloperOnboardingStepCompleted = createSelector(
  getUserAccountInfo,
  getIsFirstOnboardingStepCompleted,
  (userAccountInfo, isFirstStepCompleted) =>
    isFirstStepCompleted &&
    userAccountInfo.isDeveloper === false &&
    !!userAccountInfo.type &&
    !!userAccountInfo.role &&
    !!userAccountInfo.companyName,
);

export const getIsSecondOnboardingStepCompleted = createSelector(
  getIsDeveloperOnboardingStepCompleted,
  getIsNonDeveloperOnboardingStepCompleted,
  (isDeveloperStepCompleted, isNonDeveloperStepCompleted) => isDeveloperStepCompleted || isNonDeveloperStepCompleted,
);

export const getIsAllOnboardingStepsCompleted = createSelector(
  getIsFirstOnboardingStepCompleted,
  getIsSecondOnboardingStepCompleted,
  getUserCreatedAt,
  (isFirstStepCompeted, isSecondStepCompeted, createdAt) => {
    if (!createdAt) {
      return false;
    }

    const shouldShowOnboarding = DateTime.fromISO(createdAt).diff(DateTime.fromISO('2020-09-21')).as('days') > 0;

    return !shouldShowOnboarding || (isFirstStepCompeted && isSecondStepCompeted);
  },
);

export const getIsNoOneOnboardingStepCompleted = createSelector(
  getIsFirstOnboardingStepCompleted,
  getIsSecondOnboardingStepCompleted,
  (isFirstStepCompeted, isSecondStepCompeted) => !isFirstStepCompeted && !isSecondStepCompeted,
);
