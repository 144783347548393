// @flow
import React from 'react';

import { Card, Heading, Text, TableBuilder, Link, Tag } from '@8base/boost';
import { css } from '@emotion/core';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import { useQuery } from 'react-apollo';
import { Trans } from 'utils/translate';

const BILLING_INVOICES_LIST_QUERY = gql`
  query BillingInvoicesList {
    billingInvoicesList(by: CUSTOMER) {
      items {
        periodStart
        periodEnd
        paid
        invoicePdf
        amountDue
        amountPaid
        amountRemaining
        endingBalance
        number
        status
        total
        description
        plan {
          displayName
        }
        workspace {
          name
        }
      }
    }
  }
`;

const BILLING_HISTORY_COLUMNS = [
  { name: 'date', title: 'Date', width: 'minmax(100px, 1fr)' },
  { name: 'workspaceName', title: 'Workspace Name', width: 'minmax(180px, 1fr)' },
  { name: 'plan', title: 'Plan', width: 'minmax(160px, 1fr)' },
  { name: 'invoiceId', title: 'Invoice ID', width: 'minmax(160px, 1fr)' },
  { name: 'description', title: 'Description', width: 'minmax(160px, 1fr)' },
  { name: 'status', title: 'Status', width: 'minmax(200px, 1fr)' },
  { name: 'total', title: 'Amount', width: 'minmax(120px, 1fr)' },
  { name: 'invoicePdf', width: 'minmax(120px, 1fr)' },
];

const COLOR_BY_STATUS = {
  open: 'GRAY_40',
  draft: 'GRAY_40',
  void: 'GRAY_40',
  paid: 'GREEN',
  uncollectible: 'YELLOW',
};

const renameKeys = R.curry((keysMap, obj) =>
  R.reduce((acc, key) => R.assoc(keysMap[key] || key, obj[key], acc), {}, R.keys(obj)),
);

const getInvoices = R.pipe(
  R.pathOr([], ['billingInvoicesList', 'items']),
  R.map(
    renameKeys({
      periodStart: 'date',
      invoicePdf: 'invoicePdf',
      total: 'total',
      number: 'invoiceId',
    }),
  ),
);

export const BillingHistory = () => {
  const { data, loading } = useQuery(BILLING_INVOICES_LIST_QUERY);

  return (
    <Card>
      <Card.Header>
        <Heading type="h2" padding="none">
          <Trans i18nKey="settings.billing.billingHistory.title">Billing History</Trans>
        </Heading>
      </Card.Header>
      <Card.Body padding="none">
        <TableBuilder
          columns={ BILLING_HISTORY_COLUMNS }
          data={ getInvoices(data) }
          loading={ loading }
          renderCell={ ({ name }, data) => {
            if (name === 'invoicePdf') {
              return (
                <Link
                  css={ css`
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  ` }
                  href={ data[name] }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans i18nKey="settings.billing.billingHistory.viewPdf">View PDF</Trans>
                </Link>
              );
            }

            if (name === 'date') {
              return (
                <Text
                  css={ css`
                    white-space: nowrap;
                  ` }
                >
                  { DateTime.fromISO(data[name]).toFormat('MM/dd/yyyy') }
                </Text>
              );
            }

            if (name === 'total') {
              return (
                <Text
                  css={ css`
                    white-space: nowrap;
                    width: 100%;
                  ` }
                  weight="semibold"
                >
                  ${ data[name].toFixed(2) }
                </Text>
              );
            }

            if (name === 'status') {
              return (
                <Tag
                  css={ css`
                    text-transform: capitalize;
                  ` }
                  color={ COLOR_BY_STATUS[data[name]] }
                  bordered
                >
                  { data[name] }
                </Tag>
              );
            }

            if (name === 'workspaceName') {
              const workspaceName = R.path(['workspace', 'name'], data);
              return (
                <Text
                  css={ css`
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  ` }
                >
                  { workspaceName }
                </Text>
              );
            }

            if (name === 'plan') {
              const plan = R.path(['plan', 'displayName'], data);
              return (
                <Text
                  css={ css`
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  ` }
                >
                  { plan }
                </Text>
              );
            }

            return (
              <Text
                css={ css`
                  width: 100%;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                ` }
                weight="semibold"
              >
                { data[name] }
              </Text>
            );
          } }
        />
      </Card.Body>
    </Card>
  );
};
