// @flow
import * as React from 'react';
import { Trans as RawTrans } from 'react-i18next';

type TransProps = {
  i18nKey: string,
  children?: any,
};

// $FlowFixMe waiting for update Flow
export const Trans = React.memo(({ i18nKey, children, ...rest }: TransProps) => {
  const defaultValue = children && children.toString();
  const keyWithDefaultValue = i18nKey || defaultValue;

  return <RawTrans { ...rest } i18nKey={ keyWithDefaultValue }>{ children }</RawTrans>;
});
