// @flow
import React from 'react';
import { withRouter, type RouterHistory } from 'react-router-dom';
import qs from 'qs';

const withQueryParams: HOCProps<{ queryParams: Object, history: RouterHistory }> = (BaseComponent: *) => {
  class QueryParamsContainer extends React.Component<*> {
    collectProps() {
      const { location: { search }, history } = this.props;

      const queryParams = qs.parse(search.slice(1));

      return {
        ...this.props,
        queryParams,
        history,
      };
    }

    render() {
      const collectedProps = this.collectProps();

      return <BaseComponent { ...collectedProps } />;
    }
  }

  return withRouter(QueryParamsContainer);
};

export { withQueryParams };
