// @flow

import { createSelector, type Selector } from 'reselect';
import { prop } from 'ramda';

import type { ApolloDataPermission } from './dataPermission.selectors';

export type ApolloPermission = $Shape<{
  appId: ?string,
  resourceType: 'data',
  resource: string,
  permission: ApolloDataPermission,
}>;

export const getPermission = <T: ApolloPermission>(permission: T): T => permission;

export const getPermissionResource: Selector<
  ApolloPermission,
  any,
  string
> = createSelector(
  getPermission,
  prop('resource'),
);

export const getPermissionApp: Selector<
  ApolloPermission,
  any,
  string
> = createSelector(
  getPermission,
  prop('appId'),
);

export const getDataPermission: Selector<
  ApolloPermission,
  any,
  ApolloDataPermission
> = createSelector(
  getPermission,
  prop('permission'),
);

export const isIntegrationPermission: Selector<
  ApolloPermission,
  any,
  boolean
> = createSelector(
  getPermission,
  ({ appId }) => appId !== null,
);


