// @flow

import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getRoleSelectOption } from './role.selectors';

import type { Selector } from 'reselect';

import type { ApolloRole } from './role.selectors.js';

export type ApolloRolesListResponse = {
  items: ApolloRole[],
  count: number,
};

export const getRolesListResponse = (rolesListResponse: ApolloRolesListResponse = { items: [], count: 0 }) => rolesListResponse;

export const getRolesList: Selector<
  ApolloRolesListResponse,
  any,
  ApolloRole[],
> = createSelector(
  getRolesListResponse,
  R.prop('items'),
);

export const getRolesCount: Selector<
  ApolloRolesListResponse,
  any,
  number,
> = createSelector(
  getRolesListResponse,
  R.prop('count'),
);

export const getRolesListSelectOptions: Selector<
  ApolloRolesListResponse,
  any,
  Array<{ value: string, label: string }>,
> = createSelector(
  getRolesList,
  R.map(getRoleSelectOption),
);
