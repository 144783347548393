// @flow

import gql from 'graphql-tag';

import { ORGANIZATION_SHORT_INFO_FRAGMENT, ORGANIZATION_FULL_INFO_FRAGMENT } from '../fragments/organizations';

export const ORGANIZATIONS_LIST_QUERY = gql`
  query OrganizationList {
    system {
      organizationsListByUser {
        items {
          ...OrganizationItemShortInfo
        }
        count
      }
    }
  }

  ${ORGANIZATION_SHORT_INFO_FRAGMENT}
`;

export const ORGANIZATION_QUERY = gql`
  query Organization($organizationId: String!) {
    system {
      organizationById(organizationId: $organizationId) {
        ...OrganizationItemFullInfo
      }
    }
  }

  ${ORGANIZATION_FULL_INFO_FRAGMENT}
`;

export const ORGANIZATION_INVITATIONS_DETAILS_QUERY = gql`
  query OrganizationInvitationDetails($invitationId: String!) {
    system {
      organizationInvitationById(invitationId: $invitationId) {
        id
        emailFrom
        firstNameFrom
        lastNameFrom
        organization {
          id
          name
        }
      }
    }
  }
`;
