// @flow
import * as R from 'ramda';
import { SYSTEM_TABLES, FIELD_TYPE } from '@8base/utils';
import { createSelector } from 'reselect';

import * as apolloTableFieldSelectors from './tableField.selectors';

import type { Selector } from 'reselect';

import type { ApolloApplication } from './application.selectors';
import type { ApolloTableField } from './tableField.selectors';
import type { FieldTypes } from 'store/dataBuilder/dataBuilder.constants';

export type ApolloTable = $Shape<{|
  id: string,
  name: string,
  displayName: string,
  isSystem: boolean,
  isExternal: boolean,
  application: ApolloApplication,
  fields: ApolloTableField[],
|}>

const ENABLED_SYSTEM_TABLES_IN_DATA_BUILDER = [
  SYSTEM_TABLES.USERS,
  'CiCdMigrations',
];

export const getTable = (table?: ApolloTable): ApolloTable => table;

export const getTableFields: Selector<*, any, ApolloTableField[]> = createSelector(
  getTable, R.propOr([], 'fields'),
);

export const getTableId = createSelector(getTable, R.prop('id'));
export const getTableIsSystem = createSelector(getTable, R.prop('isSystem'));
export const getTableName = createSelector(getTable, R.prop('name'));
export const getTableDisplayNameRaw = createSelector(getTable, R.prop('displayName'));

export const getTableDisplayName = createSelector(
  getTableName,
  getTableDisplayNameRaw,
  (name, displayName) => displayName || name,
);

export const getTableFieldsWithOmitTypes: Selector<*, FieldTypes[], ApolloTableField[]> = createSelector(
  getTableFields,
  (_, omitedTypes: FieldTypes[]) => omitedTypes,
  (fields, omitedTypes) => R.filter(
    ({ fieldType }) => !R.contains(fieldType, omitedTypes),
    fields,
  ),
);

export const getTableFieldById = createSelector(
  getTableFields,
  (_, fieldId: string) => fieldId,
  (fields, fieldId) => R.find(
    R.propEq('id', fieldId),
    fields,
  ),
);

export const getTableFieldByName: Selector<*, string, $Shape<ApolloTableField>> = createSelector(
  getTableFields,
  (_, fieldName: string) => fieldName,
  (fields, fieldName) => R.pipe(
    R.find(
      R.propEq('name', fieldName),
    ),
    field => field === undefined ? {} : field,
  )(fields),
);

export const getFieldType = createSelector(getTableFieldById, R.prop('fieldType'));
export const getFieldIsList = createSelector(getTableFieldById, R.prop('isList'));
export const getFieldListOptions = createSelector(getTableFieldById, R.path(['fieldTypeAttributes', 'listOptions']));
export const getFieldFormat = createSelector(getTableFieldById, R.path(['fieldTypeAttributes', 'format']));

export const getFieldById: Selector<ApolloTable, string, $Shape<ApolloTableField>> =
  ({ fields } : ApolloTable, fieldId: string) =>
    R.find(
      R.propEq('id', fieldId),
      fields,
    ) || {};

export const getFieldTypeById = createSelector(
  getFieldById,
  R.prop('fieldType'),
);

export const isRelationField = createSelector(
  getFieldTypeById,
  R.equals(FIELD_TYPE.RELATION),
);

export const isFileField = createSelector(
  getFieldTypeById,
  R.equals(FIELD_TYPE.FILE),
);

export const isMetaField = createSelector(
  getFieldById,
  apolloTableFieldSelectors.isMetaField,
);

export const isIntegrationTable = createSelector(
  getTable,
  ({ application }) => !!application,
);


export const getMetaFields = createSelector(
  getTableFields,
  R.filter(apolloTableFieldSelectors.isMetaField),
);

export const getTableWithoutMetaFields = createSelector(
  getTable,
  R.evolve({
    fields: R.filter(R.complement(apolloTableFieldSelectors.isMetaField)),
  }),
);

export const getFieldNameById = createSelector(
  getFieldById,
  R.prop('name'),
);

export const isSystem: Selector<ApolloTable, any, boolean> = createSelector(
  getTable,
  R.propEq('isSystem', true),
);

export const isExternal: Selector<ApolloTable, any, boolean> = createSelector(
  getTable,
  R.propEq('isExternal', true),
);

export const isDisabledInDataBuilder: Selector<
  ApolloTable,
  any,
  boolean,
> = createSelector(
  getTableName,
  isSystem,
  (tableName, isSystem) => isSystem && !R.contains(tableName, ENABLED_SYSTEM_TABLES_IN_DATA_BUILDER),
);

