// @flow

import * as R from 'ramda';
import { createSelector, type Selector } from 'reselect';

export type ApolloApplication = $Shape<{
  id: string,
  name: string,
  displayName: string,
  status: string,
  tableCount: number,
  resolverCount: number,
}>;

export const getApplication: Selector<ApolloApplication, any, ApolloApplication> =
  application => application;

export const getId: Selector<ApolloApplication, any, string > =
  createSelector(getApplication, R.prop('id'));

export const getName: Selector<ApolloApplication, any, string> =
  createSelector(getApplication, R.prop('name'));

export const getDisplayName: Selector<ApolloApplication, any, string> =
  createSelector(getApplication, R.prop('displayName'));
