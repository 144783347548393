// @flow
// $FlowFixMe
import React from 'react';
import { Column, Grid, Row, Text } from '@8base/boost';

import { DeveloperHomeWorkspaceItem } from './DeveloperHomeWorkspaceItem';
import { CountTag } from 'common/components';

// Type Definitions
import type { OpenWorkspaceHandler } from 'utils/hooks/useOnOpenWorkspace';

type DeveloperHomeWorkspacesProps = {
  sharedWorkspacesList?: Object[];
  myWorkspacesList: Object[];
  organizationsWorkspaces?: Object;
  onOpenWorkspaceClick: OpenWorkspaceHandler;
  createButton: React$Node;
}

export const DeveloperHomeWorkspaces = ({
  myWorkspacesList = [],
  sharedWorkspacesList = [],
  organizationsWorkspaces = {},
  onOpenWorkspaceClick,
  createButton,
}: DeveloperHomeWorkspacesProps) => {

  return (
    <Column gap="xl" stretch alignItems="stretch">
      <Column gap="lg" stretch alignItems="stretch">

        <Grid.Layout columns="repeat(auto-fill, minmax(315px, 1fr))" gap="md" alignItems="stretch">
          { createButton }

          { myWorkspacesList.map(workspace => (
            <Grid.Box
              key={ workspace.id }
            >
              <DeveloperHomeWorkspaceItem
                workspace={ workspace }
                onOpenWorkspaceClick={ onOpenWorkspaceClick }
              />
            </Grid.Box>
          )) }
        </Grid.Layout>
      </Column>

      <If condition={ sharedWorkspacesList.length > 0 }>
        <Column gap="lg" alignItems="stretch">
          <Row alignItems="center">
            <Text kind="overline-2">Shared Workspaces</Text>
            <CountTag color="GRAY">
              { sharedWorkspacesList.length }
            </CountTag>
          </Row>
          <Grid.Layout columns="repeat(auto-fill, minmax(315px, 1fr))" gap="md" alignItems="stretch">
            { sharedWorkspacesList.map(workspace => (
              <Grid.Box key={ workspace.id }>
                <DeveloperHomeWorkspaceItem
                  workspace={ workspace }
                  onOpenWorkspaceClick={ onOpenWorkspaceClick }
                />
              </Grid.Box>
            )) }
          </Grid.Layout>
        </Column>
      </If>
      { organizationsWorkspaces && Object.keys(organizationsWorkspaces).map(organizationKey => (
        <React.Fragment key={ organizationKey }>
          { organizationsWorkspaces[organizationKey].length > 0 && (
            <Column gap="lg" alignItems="stretch" >
              <Row alignItems="center">
                <Text kind="overline-2">Organization Workspaces - { organizationKey }</Text>
                <CountTag color="GRAY">
                  { organizationsWorkspaces[organizationKey].length }
                </CountTag>
              </Row>
              <Grid.Layout columns="repeat(auto-fill, minmax(315px, 1fr))" gap="md" alignItems="stretch">
                { organizationsWorkspaces[organizationKey].map(workspace => (
                  <Grid.Box key={ workspace.id }>
                    <DeveloperHomeWorkspaceItem
                      workspace={ workspace }
                      onOpenWorkspaceClick={ onOpenWorkspaceClick }
                    />
                  </Grid.Box>
                )) }
              </Grid.Layout>
            </Column>
          ) }
        </React.Fragment>
      )) }
    </Column>
  );
};
