// @flow

import * as R from 'ramda';
import { createSelector } from 'reselect';

import * as teamMember from './teamMember.selectors';

export const getTeamMembersResponse = (teamMember: Object) => teamMember;

export const getTeamMembers = createSelector(getTeamMembersResponse, R.prop('items'));

export const getSelectOptions = createSelector(
  getTeamMembers,
  R.pipe(
    R.defaultTo([]),
    R.map(teamMember.getSelectOption),
  ),
);
