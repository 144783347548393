import { getUSServerUrl, getUKServerUrl } from 'utils/processEnv';

export const REGIONS_ENDPOINTS = {
  US: getUSServerUrl(),
  UK: getUKServerUrl(),
};

export const REGIONS_NAMES = {
  US: 'us-east-1',
  UK: 'eu-west-2',
};

export const getRegionNameByEndpoint = (endpoint: string) => {
  switch (endpoint) {
    case REGIONS_ENDPOINTS.US: {
      return REGIONS_NAMES.US;
    }

    case REGIONS_ENDPOINTS.UK: {
      return REGIONS_NAMES.UK;
    }

    default: {
      return undefined;
    }
  }
};
