// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import type { Location, RouterHistory } from 'react-router-dom';

import { withBuildUrl, buildUrl, APP_URL } from 'common/routing';


type NavigationPromptProps = {
  children: (data: {
    isActive: bool,
    onCancel: () => void,
    onConfirm: () => void,
  }) => React$Element<*>,
  history: RouterHistory,
  location: Object,
  forceRender: bool,
  when: bool | (Location, ?Location) => bool,
  buildUrl: typeof buildUrl,
};

type NavigationPromptState = {
  nextLocation: ?Location,
  isActive: bool,
  unblock: Function
};

const initState = {
  nextLocation: null,
  isActive: false,
};


class NavigationPromptCommonComponent extends React.Component<NavigationPromptProps, NavigationPromptState> {
  constructor(props) {
    super(props);

    this.state = {
      ...initState,
      unblock: props.history.block(this.blockLocationChange),
    };
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onBeforeUnload);
  }

  componentWillUnmount() {
    this.state.unblock();
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  }

  ifShouldBlock = (nextLocation?: Location) => {
    const { when, location } = this.props;

    if (typeof when === 'function') return when(location, nextLocation);

    return when;
  };

  onBeforeUnload = (e) => {
    if (!this.ifShouldBlock()) return;

    const response = 'Do you want to leave this page? \n Changes will not be saved.';
    e.returnValue = response;
    return response;
  };

  blockLocationChange = (nextLocation) => {
    if (this.ifShouldBlock(nextLocation)) {
      this.setState({
        nextLocation,
        isActive: true,
      });
    }

    return !this.ifShouldBlock(nextLocation);
  };

  onConfirm = () => {
    const { history } = this.props;
    const { nextLocation, unblock } = this.state;

    unblock();
    history.push((nextLocation && nextLocation.pathname) || this.props.buildUrl(APP_URL.workspaceHome));

    this.setState({
      ...initState,
      // $FlowIgnore
      unblock: history.block(this.blockLocationChange),
    });
  };

  onCancel = () => {
    this.setState({ ...initState });
  };

  render() {
    const { forceRender, children } = this.props;
    const { isActive } = this.state;

    if (!isActive && !forceRender) return null;
    return (
      <div>
        { children({
          isActive,
          onConfirm: this.onConfirm,
          onCancel: this.onCancel,
        }) }
      </div>
    );
  }
}

const enhancer = compose(
  withBuildUrl,
  withRouter,
);

export const NavigationPrompt = enhancer(NavigationPromptCommonComponent);
