// @flow
import React from 'react';
import {
  Dialog,
  Row,
  Button,
  InputField,
  Text,
  Column,
} from '@8base/boost';
import { Field, Form } from '@8base-react/forms';

import { Trans } from 'utils/translate';
import { TRANSFER_STATUSES } from 'common/constants/organization';
import { composeValidators, confirmValue, required } from 'utils/formValidations';
import { TransferWorkspaceAbort } from './TransferWorkspace.Abort';

const confirmValueFn = (confirmVal) => (val) => confirmValue(val, confirmVal);

export const TransferWorkspaceToUserDialogForm = ({
  onSubmit,
  onClose,
  workspaceName,
  transferInfo,
  abortLoading,
  onAbort,
}: *) => {
  if (transferInfo && transferInfo.status !== TRANSFER_STATUSES.aborted) {
    return (
      <TransferWorkspaceAbort
        workspaceName={ workspaceName }
        transferStatus={ transferInfo.transferStatus }
        newOwnerName={ transferInfo.newOwnerName }
        onClose={ onClose }
        onAbort={ onAbort }
        abortLoading={ abortLoading }
      />
    );
  }

  return (
    <Form onSubmit={ onSubmit }>
      { ({ handleSubmit, invalid, submitting }) => {
        return (
          <React.Fragment>
            <Dialog.Body >
              <Column>
                <Row>
                  <Text>
                    { /* Can not create first translation at this file, this comment needs to fix it
                    <Trans i18nKey="transferWorkspaceDialog.owner.label">
                      Who do you want to transfer the <Text weight="bold">{ workspaceName }</Text> workspace to?
                    </Trans>
                    */ }
                    <Trans i18nKey="transferWorkspaceDialog.owner.label">
                      Whom do you want to transfer the <Text weight="bold">{ workspaceName }</Text> workspace?
                    </Trans>
                  </Text>
                </Row>
                <Row stretch>
                  <Field
                    name="owner"
                    placeholder="User email or Organization ID"
                    validate={ required }
                    component={ InputField }
                    note="Organization ID can be found in Organization Settings &rarr; General"
                  />
                </Row>
                <Row offsetTop="sm">
                  <Text>
                    <Trans i18nKey="transferWorkspaceDialog.owner.help">
                    The workspace will be transferred as soon as the User/Organization accepts your transfer request.
                    </Trans>
                  </Text>
                </Row>
                <Row>
                  <Text>
                    <Trans i18nKey="transferWorkspaceDialog.workspaceNameConfirm">
                      Type <Text weight="bold">{ workspaceName }</Text> to initiate transfer process.
                    </Trans>
                  </Text>
                </Row>
                <Row stretch>
                  <Field
                    name="workspaceName"
                    placeholder={ workspaceName }
                    component={ InputField }
                    validate={ composeValidators(required, confirmValueFn(workspaceName)) }
                  />
                </Row>
              </Column>

            </Dialog.Body>
            <Dialog.Footer>
              <Button
                color="neutral"
                variant="outlined"
                onClick={ onClose }
              >
                <Trans i18nKey="shared.cancel" >
                  Cancel
                </Trans>
              </Button>
              <Button
                disabled={ invalid }
                loading={ submitting }
                onClick={ handleSubmit }
              >
                <Trans i18nKey="shared.transfer" >
                  Transfer
                </Trans>
              </Button>
            </Dialog.Footer>
          </React.Fragment>
        );
      } }
    </Form>
  );
};
