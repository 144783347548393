// @flow

import * as R from 'ramda';
import { createSelector } from 'reselect';

import { type ApolloFunctionInfo } from './functionInfo.selectors';

export type ApolloFunctionsListResponse = {
  items: ApolloFunctionInfo[],
  count: number,
};

export const getFunctionsListResponse =
  (functionsListResponse: ApolloFunctionsListResponse) => functionsListResponse;

export const getFunctionsList = createSelector(
  getFunctionsListResponse,
  R.propOr([], 'items'),
);

export const getFunctionsCount = createSelector(
  getFunctionsListResponse,
  R.propOr(0, 'count'),
);
