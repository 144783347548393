import gql from 'graphql-tag';
import { INBOX_ITEM_FRAGMENT } from 'graphql/fragments';

export const INBOX_LIST_QUERY = gql`
  query InboxList($first: Int, $skip: Int, $filter: InboxEventsListFilter) {
    system {
      inboxEventsList(first: $first, skip: $skip, filter: $filter) {
        items {
          ...InboxItem
        }
        count
      }
      inboxEventsListCount: inboxEventsList(filter: $filter) {
        count
      }
    }
  }

  ${INBOX_ITEM_FRAGMENT}
`;

export const UNDONE_INBOX_COUNT_QUERY = gql`
  query UndoneInboxCount {
    system {
      undoneInboxCount: inboxEventsList(filter: { isCompleted: { equals: 0 } }) {
        count
      }
    }
  }
`;
