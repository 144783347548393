import React, { useCallback } from 'react';
import { Dialog, useModal } from '@8base/boost';
import { i18n } from 'i18n';
import { useMutation } from 'react-apollo';

import { ABORT_TRANSFER_OWNERSHIP_MUTATION } from 'graphql/mutations';
import { TOAST_SUCCESS_MESSAGE, ENDPOINT_URI } from 'common/constants/apolloOperationContextOptions';
import { clearWorkspaceTransferInfo } from 'utils/apollo/updateCache';

import { TransferWorkspaceAbort } from './TransferWorkspace.Abort';

export const TransferWorkspaceAbortDialog = () => {
  const { closeModal, args } = useModal(TransferWorkspaceAbortDialog.ID);

  // TODO:[refactor] replace with hook useAbortTransfer
  const [abort, { loading: abortLoading }] = useMutation(ABORT_TRANSFER_OWNERSHIP_MUTATION, {
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('transferWorkspaceDialog.abortSuccessful', { defaultValue: 'Transfer aborted' }),
      [ENDPOINT_URI]: args.apiHost,
    },
    update: clearWorkspaceTransferInfo(args && args.workspaceId),
  });

  const onAbort = useCallback(async() => {
    if (!args || !args.workspaceId) {
      return;
    }

    await abort({
      variables: {
        transferId: args && args.workspaceId,
      },
    });
    closeModal(TransferWorkspaceAbortDialog.ID);
  }, [abort, args, closeModal]);

  const onClose = useCallback(() => {
    closeModal(TransferWorkspaceAbortDialog.ID);
  }, [closeModal]);

  return (
    <Dialog id={ TransferWorkspaceAbortDialog.ID } onClose={ onClose }>
      <TransferWorkspaceAbort
        workspaceName={ args.workspaceName }
        onClose={ onClose }
        onAbort={ onAbort }
        abortLoading={ abortLoading }
        newOwnerName={ args.newOwnerName }
      />
    </Dialog>
  );
};

TransferWorkspaceAbortDialog.ID = 'TRANSFER_WORKSPACE_ABORT_DIALOG';
