import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';

export class AccordionNavNavigationLeftCommonHelper extends Component {

  static propTypes = {
    children: PropTypes.node.isRequired,
    activeNavName: PropTypes.string,

    hasNestedCollapse: PropTypes.bool,
    isOpen: PropTypes.bool,

  };

  static defaultProps = {
    hasNestedCollapse: false,
    isOpen: true,
  };


  render() {
    const { hasNestedCollapse, isOpen, children, activeNavName } = this.props;
    return (
      <Collapse isOpened={ isOpen } hasNestedCollapse={ hasNestedCollapse }>
        { React.Children.map(children, child => React.cloneElement(child, { activeNavName, isSub: true, ...child.props })) }
      </Collapse>
    );
  }
}
