import React from 'react';
import styled from '@emotion/styled';
import { Grid } from '@8base/boost';

const PADDINGS = {
  default: '24px',
  right: '0 24px 0 0',
  none: '0',
};

type AppFlowContentProps = {
  children: ?React$Node,
  padding?: $Keys<typeof PADDINGS>,
};

const AppFlowContentTag = styled(Grid.Box)(({ padding }) => ({
  padding: PADDINGS[padding],
  maxHeight: '100%',
  minHeight: 0,
  minWidth: 0,
  maxWidth: '100%',
  overflow: 'auto',
}));

const AppFlowContent = ({ children, padding, ...rest }: AppFlowContentProps) => (
  <AppFlowContentTag { ...rest } area="content" padding={ padding }>
    { children }
  </AppFlowContentTag>
);

AppFlowContent.defaultProps = {
  padding: 'default',
};

export { AppFlowContent };

