// @flow
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Text } from '@8base/boost';


type TextProps = {
  /** possible types of the css cursor property */
  cursor?: 'pointer' | 'default' | 'auto',
  /** modern text kinds */
  kind?: 'overline-2' | 'overline-1' | 'small-2' | 'small-1' | 'body' | 'subtitle' | 'button',
}

const TextTag = styled(Text)((props: TextProps) => {
  if (props.kind === 'button') {
    return css`
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        color: #1A252F;
        letter-spacing: 0.1px;
      `;
  }

  if (props.kind === 'body') {
    return css`
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #596389;
    `;
  }

  if (props.kind === 'overline-1') {
    return css`
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #7D828C;
    `;
  }

  if (props.kind === 'overline-2') {
    return css`
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #7D828C;
    `;
  }

  if (props.kind === 'small-1') {
    return css`
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1px;
      color: #A3AFB7;
    `;
  }

  if (props.kind === 'subtitle') {
    return css`
      font-size: 24px;
      line-height: 34px;
      letter-spacing: 0.1px;
      color: #7D828C;
    `;
  }
});

export const UserFlowText = ({ kind = 'body', ...rest }: TextProps) => {
  return <TextTag kind={ kind }{ ...rest } />;
};

export const UserFlowTextWhite = ({ kind = 'body', ...rest }: TextProps) => {
  return <TextTag kind={ kind } css={ css`color: #FFFFFF;` } { ...rest } />;
};
