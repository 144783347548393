// @flow
// $FlowFixMe waiting for update Flow
import React from 'react';
import { Card, Row, Heading, TableBuilder, Link, Icon, useModal, Text, Dropdown, Menu, Tooltip } from '@8base/boost';
import { Query } from 'react-apollo';
import * as R from 'ramda';
import { DateTime } from 'luxon';
import { css } from '@emotion/core';
import { Translation, useTranslation } from 'react-i18next';
import { i18n } from 'i18n';

import { useBuildUrl, APP_URL } from 'common/routing';
import { LAST_WORKSPACE_ID } from 'common/constants/localStorageKeys';
import { ButtonPlus } from 'pro';
import { Trans } from 'utils/translate';
import { getClientUrl } from 'utils/processEnv';
import { WORKSPACES_LIST_QUERY } from 'graphql/queries';

import { WorkspaceCreateDialog } from 'dialogs/WorkspaceCreateDialog';
import { WorkspaceUpdateDialog } from 'dialogs/WorkspaceUpdateDialog';
import { useBillingInfo } from 'providers/BillingProvider/useBillingInfo';

const getWorkspaceColumns = () => [
  {
    name: 'name',
    title: i18n.t('account.workspaces.name'),
  },
  {
    name: 'id',
    title: i18n.t('account.workspaces.id'),
  },
  {
    name: 'plan',
    title: i18n.t('account.workspaces.plan'),
  },
  {
    name: 'nextCharge',
    title: i18n.t('account.workspaces.nextCharge'),
  },
  {
    name: 'amount',
    title: i18n.t('account.workspaces.amount'),
  },
  {
    name: 'actions',
    width: '70px',
  },
];

const renderCell = (plans, changeWorkspace, openModal) => ({ name }, data) => {
  const trialEnd = R.prop('trialEnd', data.plan);

  if (name === 'plan') {
    return <Text css={ css`white-space: nowrap;` }>{ R.prop('displayName', data[name]) }</Text>;
  } else if (name === 'nextCharge') {
    return trialEnd ? '-' : DateTime.local().plus({ months: 1 }).set({ day: 1 }).toFormat('MM/dd/yyyy');
  } else if (name === 'amount') {
    const plan = R.find(R.propEq('id', R.prop('id', data.plan)), plans);

    if (plan && !trialEnd) {
      return <Text weight="semibold">${ plan.price.toFixed(2) }</Text>;
    }

    return '-';
  } else if (name === 'name') {
    return <Link onClick={ () => changeWorkspace(data.id) }>{ data[name] }</Link>;
  } else if (name === 'actions') {
    return (
      <Translation>
        { t => (
          <Dropdown defaultOpen={ false }>
            <Dropdown.Head >
              <Icon name="More" color="GRAY_30" { ...E2E.$props('tableEditDropdownToggle') } />
            </Dropdown.Head>
            <Dropdown.Body withPortal preventOverflow pin="right">
              { ({ closeDropdown }) => (
                <Menu>
                  <Menu.Item onClick={ () => {
                    openModal(WorkspaceUpdateDialog.ID, {
                      workspaceId: data.id,
                      workspaceName: data.name,
                      workspaceImage: data.image,
                      workspaceDescription: data.description,
                      apiHost: data.apiHost,
                      webSocketUri: data.webSocket,
                      isOwner: data.isOwner,
                    });
                    closeDropdown();
                  } }>
                    <Trans i18nKey="shared.edit" />
                  </Menu.Item>
                </Menu>
              ) }
            </Dropdown.Body>
          </Dropdown>
        ) }
      </Translation>
    );
  }

  return data[name];
};

const Workspaces = () => {
  const buildUrl = useBuildUrl();

  const changeWorkspace = async (workspaceId) => {
    const clientUrl = getClientUrl();

    const url = buildUrl(APP_URL.workspaceHome, { pathParams: { workspaceId }, ignoreWorkspaceId: true });

    if (window.localStorage) {
      localStorage.setItem(LAST_WORKSPACE_ID, workspaceId);
    }

    window.location.assign(`${clientUrl}${url}`);
  };

  const { openModal } = useModal();
  const { plans = [] } = useBillingInfo();
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Header>
        <Row alignItems="center" gap="md">
          <Heading type="h2" padding="none">
            <Trans i18nKey="account.workspaces.title">
              Workspaces
            </Trans>
          </Heading>
          <Tooltip modifiers={{ offset: { offset: '0, 4' }}} message={ t('account.workspaces.addWorkspace') }>
            <ButtonPlus
              onClick={ () => openModal(WorkspaceCreateDialog.ID) }
              { ...E2E.$props('settings.roles.addButton') }
            />
          </Tooltip>
        </Row>
      </Card.Header>

      <Card.Body padding="none">
        { /* TODO: add similar page for fronted workspaces ? */ }
        <Query query={ WORKSPACES_LIST_QUERY }>
          { ({ data, loading }) => (
            <TableBuilder
              columns={ getWorkspaceColumns() }
              data={ R.pathOr([], ['system', 'workspacesList', 'items'], data) }
              loading={ loading }
              renderCell={ renderCell(plans, changeWorkspace, openModal) }
            />
          ) }
        </Query>
      </Card.Body>
    </Card>
  );
};

export { Workspaces };

