// @flow

import React, { PureComponent } from 'react';
import { compose, pure, type HOC } from 'recompose';
import { withApollo, graphql } from 'react-apollo';
import { withRouter, matchPath } from 'react-router-dom';
import { withModal } from '@8base/boost';
import * as R from 'ramda';

import { withWorkspace } from 'providers/WorkspaceProvider';
import { withBuildUrl, APP_URL } from 'common/routing';
import { WORKSPACE_KIND } from 'common/constants/workspaceKind';
import { WorkspaceCreateDialog } from 'dialogs/WorkspaceCreateDialog';
import { apolloWorkspacesListSelectors as workspacesListSelectors, type QueryResult } from 'graphql/selectors';
import { WORKSPACES_LIST_QUERY } from 'graphql/queries';
import type { WorkspaceItem } from 'graphql/__generated__';
import { createStringComparator } from 'utils/createStringComparator';

import { WorkspaceSelector } from '../components/WorkspaceSelector';

type WorkspaceSelectorContainerState = {
  isOpenWorkspaceSelector: boolean,
};

const workspacesComparator = createStringComparator(R.propOr('', 'name'));

const workspacesListQuery: HOCProps<QueryResult<'workspacesList', WorkspaceItem>> = graphql(WORKSPACES_LIST_QUERY);

const enhancer: HOC<*, {}> = compose(
  pure,
  workspacesListQuery,
  withApollo,
  withRouter,
  withModal,
  withWorkspace,
  withBuildUrl,
);

type WorkspaceSelectorContainerBaseProps = HOCBase<typeof enhancer>;

const WorkspaceSelectorContainer = enhancer(
  class WorkspaceSelectorContainerBase extends PureComponent<WorkspaceSelectorContainerBaseProps, WorkspaceSelectorContainerState> {
    state = {
      isOpenWorkspaceSelector: false,
    };

    openWorkspaceSelector = () => this.setState({ isOpenWorkspaceSelector: true });
    closeWorkspaceSelector = () => this.setState({ isOpenWorkspaceSelector: false });

    changeWorkspace = async (newWorkspaceId: string) => {
      const {
        history,
        location,
        client,
        workspace,
      } = this.props;

      if (newWorkspaceId !== workspace.id) {
        this.closeWorkspaceSelector();

        const pathParams = { workspaceId: newWorkspaceId };

        if (matchPath(location.pathname, { path: APP_URL.data })) {
          window.location.assign(this.props.buildUrl(APP_URL.data, { pathParams }));
        } else if (matchPath(location.pathname, { path: APP_URL.apiExplorer })) {
          window.location.assign(this.props.buildUrl(APP_URL.apiExplorer, { pathParams }));
        } else {
          await client.clearStore();

          if (matchPath(location.pathname, { path: APP_URL.appServicesRoles })) {
            history.push(this.props.buildUrl(APP_URL.appServicesRoles, { pathParams }));
          } else {
            const newUrl = history.location.pathname
              .replace(/^\/workspace\/.+?(\/|$)/, `/workspace/${newWorkspaceId}/`)
              .replace(/\/$/, '');

            history.push(newUrl);
          }

          await client.reFetchObservableQueries();
        }
      }
    }

    openWorkspaceDialog = () => {
      const { openModal } = this.props;

      openModal(WorkspaceCreateDialog.ID, {
        region: this.props.workspace.apiHost,
        onSuccess: (id) => { this.changeWorkspace(id); },
      });

      this.closeWorkspaceSelector();
    }

    render() {
      const { isOpenWorkspaceSelector } = this.state;
      const { data, workspace } = this.props;

      const workspaceList = workspacesListSelectors.getWorkspacesByKind(data, WORKSPACE_KIND.general);

      const myWorkspacesList = R.sort(
        workspacesComparator,
        workspacesListSelectors.getMyWorkspacesList(workspaceList),
      );

      const sharedWorkspacesList = workspacesListSelectors.getSharedWorkspacesList(workspaceList);
      const selectedWorkspaceName = workspace.name || '...';

      return (
        <WorkspaceSelector
          selectedWorkspace={ selectedWorkspaceName }
          selectedWorkspaceId={ workspace.id }
          myWorkspacesList={ myWorkspacesList }
          sharedWorkspacesList={ sharedWorkspacesList }
          changeWorkspace={ this.changeWorkspace }
          openWorkspaceSelector={ this.openWorkspaceSelector }
          closeWorkspaceSelector={ this.closeWorkspaceSelector }
          isOpenWorkspaceSelector={ isOpenWorkspaceSelector }
          openWorkspaceCreateDialog={ this.openWorkspaceDialog }
        />
      );
    }
  },
);


export {
  WorkspaceSelectorContainer,
};
