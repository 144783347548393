// $FlowFixMe waiting for update Flow
import React, { useLayoutEffect } from 'react';
import { Grid } from '@8base/boost';

import { Trans } from 'utils/translate';
import { UserFlow } from 'common/UserFlow';

const InviteExpired = () => {
  useLayoutEffect(() => {
    requestAnimationFrame(window.htmlLoader.hide);
  });

  return (
    <UserFlow.Page>
      <UserFlow.Header position="absolute" transparent />
      <UserFlow.PageContent alignItems="center">
        <UserFlow.Paper>
          <Grid.Layout gap="xl">
            <Grid.Box alignItems="center" textAlign="center">
              <UserFlow.HeadingWhite type="h2">
                <Trans i18nKey="invite.linkExpired2">Invite Link Expired</Trans>
              </UserFlow.HeadingWhite>
            </Grid.Box>
            <Grid.Box alignItems="center" textAlign="center">
              <UserFlow.TextWhite>
                <Trans i18nKey="invite.createFaster">Create a free 8base account and start building software faster and better.</Trans>
              </UserFlow.TextWhite>
            </Grid.Box>
          </Grid.Layout>
        </UserFlow.Paper>
      </UserFlow.PageContent>
    </UserFlow.Page>
  );
};

export { InviteExpired };

