// @flow
// $FlowFixMe
import React, { useMemo, useCallback } from 'react';
import { Dialog, useModal, Grid, Text, AsyncContent, SelectField, Column, Button } from '@8base/boost';
import { Form, Field } from '@8base-react/forms';
import { GET_WORKSPACE_ENVIRONMENTS_ROLES_QUERY, ORGANIZATION_QUERY } from 'graphql/queries';
import { ORGANIZATION_ROLE, FULL_ACCESS_ROLE_OPTION } from 'common/constants/organization';
import { useQuery } from 'react-apollo';
import * as R from 'ramda';
import { i18n } from 'i18n';
import { WorkspaceCard } from 'common/components';
import { getEnvironmentRolesOptions } from 'utils';
import { checkManagerRole } from 'utils/organization';

type EnvsDataProps = Array<{
  environmentId: string,
  assignedRoles: Array<{id: string}>,
  roles: Array<{id: string}>
}>

type EnvironmentRolesList = Array<{
  environmentId: string,
  roles: Array<string>
}>

const ORGANIZATION_TEAM_MEMBER_EDIT_ACCESS_DIALOG_ID = 'ORGANIZATION_TEAM_MEMBER_EDIT_ACCESS_DIALOG_ID';

const getEnvironmentRolesList = (envsData: EnvsDataProps): EnvironmentRolesList =>
  (envsData.map(el => ({
    environmentId: el.environmentId,
    roles: el.assignedRoles && el.assignedRoles.map(({ id }) => id),
  })));

const getManagerEnvironmentRolesList = (envsData: EnvsDataProps): EnvironmentRolesList =>
  (envsData.map(el => ({
    environmentId: el.environmentId,
    roles: FULL_ACCESS_ROLE_OPTION[0].value,
  })));

const OrganizationTeamMemberEditAccessDialog = () => {
  const { closeModal, args } = useModal(ORGANIZATION_TEAM_MEMBER_EDIT_ACCESS_DIALOG_ID);

  const {
    workspaceId,
    email,
    role,
    workspaceName,
    kind,
    image,
    updateTeamMemberWorkspaceEnvironments,
    organizationId,
    handleSubmit,
  } = args;

  const { data, loading } = useQuery(GET_WORKSPACE_ENVIRONMENTS_ROLES_QUERY, {
    skip: !workspaceId || !email,
    variables: {
      workspaceId,
      email,
    },
  });

  const environmentsList = R.pathOr([], ['system', 'getEnvironmentRoles'], data);

  const isManager = useMemo(() => checkManagerRole(role), [role]);

  const initialValues = useMemo(() => {
    if (isManager) return { workspaceId, email, environmentRoles: getManagerEnvironmentRolesList(environmentsList) };

    return { workspaceId, email, environmentRoles: getEnvironmentRolesList(environmentsList) };
  }, [email, environmentsList, isManager, workspaceId]);

  const onClose = useCallback(() => {
    closeModal(ORGANIZATION_TEAM_MEMBER_EDIT_ACCESS_DIALOG_ID);
  }, [closeModal]);

  const onSubmit = useCallback(async (values) => {
    await updateTeamMemberWorkspaceEnvironments({
      variables: values,
      refetchQueries: [{
        query: GET_WORKSPACE_ENVIRONMENTS_ROLES_QUERY,
        variables: {
          workspaceId,
          email,
        },
      },
      {
        query: ORGANIZATION_QUERY,
        variables: { organizationId },
      }],
      awaitRefetchQueries: true,
    });

    if (handleSubmit) {
      handleSubmit();
    }

    onClose();
  }, [email, handleSubmit, onClose, organizationId, updateTeamMemberWorkspaceEnvironments, workspaceId]);

  return (
    <Dialog id={ ORGANIZATION_TEAM_MEMBER_EDIT_ACCESS_DIALOG_ID } size="xl">
      <Form initialValues={ initialValues } onSubmit={ onSubmit }>{ ({ handleSubmit, invalid, submitting, pristine }: *) => (
        <>
          <Dialog.Header title={ i18n.t('dialogs.organizationWorkspaceShareWithEnvs.title') } onClose={ onClose } />
          <Dialog.Body>
            <Column gap="md" stretch>
              <WorkspaceCard
                image={ image }
                kind={ kind }
                workspaceName={ workspaceName }
                workspaceId={ workspaceId }
              />
              <AsyncContent stretch loading={ loading }>
                <Column stretch >
                  { environmentsList.map(({ environmentId, environmentName, roles }, index) => {
                    const roleOptions = getEnvironmentRolesOptions(roles);

                    return (
                      <Grid.Layout key={ environmentId } columns="160px 1fr" stretch>
                        <Grid.Box justifyContent="center">
                          <Text kind="subtitle" color="GRAY_50">{ environmentName }</Text>
                        </Grid.Box>
                        <Grid.Box>
                          <Field
                            name={ `environmentRoles[${index}].roles` }
                            component={ SelectField }
                            options={ isManager ? FULL_ACCESS_ROLE_OPTION : roleOptions }
                            disabled={ isManager || submitting }
                            multiple={ !isManager }
                          />
                        </Grid.Box>
                      </Grid.Layout>);
                  }) }
                </Column>
              </AsyncContent>
            </Column>
          </Dialog.Body>
          <Dialog.Footer>
            <Button
              type="button"
              color="neutral"
              variant="outlined"
              disabled={ submitting }
              onClick={ onClose }
            >
              { i18n.t('shared.cancel') }
            </Button>
            <Button
              disabled={ role !== ORGANIZATION_ROLE.manager && (invalid || pristine) }
              loading={ submitting }
              onClick={ handleSubmit }
            >
              { i18n.t('shared.save') }
            </Button>
          </Dialog.Footer>
        </>) }
      </Form>
    </Dialog>);
};


OrganizationTeamMemberEditAccessDialog.ID = ORGANIZATION_TEAM_MEMBER_EDIT_ACCESS_DIALOG_ID;

export { OrganizationTeamMemberEditAccessDialog };
