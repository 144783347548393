import React, { useCallback, useMemo } from 'react';
import {
  Dialog,
  useModal,
  AsyncContent,
} from '@8base/boost';
import { useMutation, useQuery } from 'react-apollo';
import { i18n } from 'i18n';

import { ENDPOINT_URI, TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';
import { ABORT_TRANSFER_OWNERSHIP_MUTATION, WORKSPACE_CHANGE_OWNERSHIP } from 'graphql/mutations';
import { TRANSFER_OWNERSHIP_DETAILS_QUERY } from 'graphql/queries';
import { clearWorkspaceTransferInfo } from 'utils/apollo/updateCache';

import { TransferWorkspaceToUserDialogForm } from './TransferWorkspaceDialog.Form';

const getTransferInfo = (data: any) => {
  const transfer = data && data.system && data.system.workspaceTransferInfo;

  if (!transfer) {
    return null;
  }

  const {
    status,
    to,
  } = transfer;

  return {
    newOwnerName: to.name || '',
    status,
  };
};

/**
 * Dialog where user can transfer workspace ownership
 */
export const TransferWorkspaceDialog = () => {
  const { closeModal, args } = useModal(TransferWorkspaceDialog.ID);

  const [transferOwnership] = useMutation(WORKSPACE_CHANGE_OWNERSHIP, {
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('transferWorkspaceDialog.transferSuccessful', { defaultValue: 'Transfer request sent' }),
      [ENDPOINT_URI]: args.apiHost,
    },
    awaitRefetchQueries: true,
    refetchQueries: [{
      query: TRANSFER_OWNERSHIP_DETAILS_QUERY,
      variables: { transferId: args && args.workspaceId },
    }],
  });

  // TODO:[refactor] replace with hook useTransferOwnershipDetails
  const { data, loading } = useQuery(TRANSFER_OWNERSHIP_DETAILS_QUERY, {
    skip: !args || !args.workspaceId,
    variables: {
      transferId: args && args.workspaceId,
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
    context: {
      [ENDPOINT_URI]: args.apiHost,
    },
  });

  // TODO:[refactor] replace with hook useAbortTransfer
  const [abort, { loading: abortLoading }] = useMutation(ABORT_TRANSFER_OWNERSHIP_MUTATION, {
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('transferWorkspaceDialog.abortSuccessful', { defaultValue: 'Transfer aborted' }),
      [ENDPOINT_URI]: args.apiHost,
    },
    update: clearWorkspaceTransferInfo(args && args.workspaceId),
  });

  const onClose = useCallback(() => {
    closeModal(TransferWorkspaceDialog.ID);
  }, [closeModal]);

  const onAbort = useCallback(async() => {
    if (!args || !args.workspaceId) {
      return;
    }

    await abort({
      variables: {
        transferId: args.workspaceId,
      },
    });
    closeModal(TransferWorkspaceDialog.ID);
  }, [abort, args, closeModal]);

  const onSubmit = useCallback(async(data, form) => {
    if (!args || !args.workspaceId) {
      return;
    }
    try {
      await transferOwnership({
        variables: {
          owner: data.owner,
          workspaceId: args.workspaceId,
        },
      });
      closeModal(TransferWorkspaceDialog.ID);
    } catch {
      form.initialize(data);
    }
  }, [args, transferOwnership, closeModal]);

  const workspaceName = useMemo(() => args && args.workspaceName, [args]);

  const transferInfo = useMemo(() => getTransferInfo(data), [data]);

  return (
    <Dialog id={ TransferWorkspaceDialog.ID } onClose={ onClose }>
      <Dialog.Header title="Transfer ownership" onClose={ onClose } />
      <AsyncContent loading={ loading } stretch>
        <TransferWorkspaceToUserDialogForm
          onSubmit={ onSubmit }
          onClose={ onClose }
          workspaceName={ workspaceName }
          transferInfo={ transferInfo }
          abortLoading={ abortLoading }
          onAbort={ onAbort }
        />
      </AsyncContent>
    </Dialog>
  );
};

TransferWorkspaceDialog.ID = 'TRANSFER_WORKSPACE_DIALOG';
