//@flow

import * as R from 'ramda';
import { createSelector } from 'reselect';

import type { Selector } from 'reselect';

import type { ApolloEnvironmentVariable } from './environmentVariable.selectors';

export type ApolloEnvironmentVariablesListResponse = {
  items: ApolloEnvironmentVariable[],
  count: number,
};

export const getEnvironmentVariablesListResponse: Selector<
  ApolloEnvironmentVariablesListResponse,
  any,
  ApolloEnvironmentVariablesListResponse,
> = (environmentVariablesListResponse = { items: [], count: 0 }) =>
  environmentVariablesListResponse;

export const getEnvironmentVariablesList: Selector<
  ApolloEnvironmentVariablesListResponse,
  any,
  ApolloEnvironmentVariable[],
> = createSelector(
  getEnvironmentVariablesListResponse,
  R.prop('items'),
);

export const getEnvironmentVariablesCount: Selector<
  ApolloEnvironmentVariablesListResponse,
  any,
  number,
> = createSelector(
  getEnvironmentVariablesListResponse,
  R.prop('count'),
);

