// @flow
// $FlowFixMe
import { useContext } from 'react';

import { WorkspaceProxyContext, type WorkspaceProxyContextProps } from './WorkspaceProxyContext';

export const useWorkspaceProxy = (): WorkspaceProxyContextProps => {
  const {
    apiUri,
    setApiUri,
    webSocketUri,
    setWebSocketUri,
    workspaceListLoaded,
    setListLoaded,
  } = useContext(WorkspaceProxyContext);

  return {
    apiUri,
    setApiUri,
    webSocketUri,
    setWebSocketUri,
    workspaceListLoaded,
    setListLoaded,
  };
};
