// @flow
// $FlowFixMe waiting for update Flow
import React, { useCallback } from 'react';

import { Dialog, Button, useModal, Text, Icon, Column } from '@8base/boost';
import { Trans } from 'utils/translate';
import { i18n } from 'i18n';
import { css } from '@emotion/core';
import { useOnOpenWorkspace } from 'utils/hooks';

export const TransferAcceptWorkspaceOwnershipSuccessDialog = () => {
  const { closeModal, args } = useModal(TransferAcceptWorkspaceOwnershipSuccessDialog.ID);
  const { workspaceName, newOwnerName, workspaceId, openAsBackend } = args;
  const onOpenWorkspaceClick = useOnOpenWorkspace();

  const onClose = useCallback(() => {
    closeModal(TransferAcceptWorkspaceOwnershipSuccessDialog.ID);
  }, [closeModal]);

  return (
    <Dialog id={ TransferAcceptWorkspaceOwnershipSuccessDialog.ID }>
      <Dialog.Header
        title={ i18n.t('home.transferWorkspace.ownership.acceptSuccessful.title', { defaultValue: 'Workspace transfer successfully completed' }) }
        onClose={ onClose } />
      <Dialog.Body css={ css`padding-top: 45px;` }>
        <Column alignItems="center">
          <Icon name="LongCheck" size="custom" customSize="70px" color="GREEN" />
          <Text align="center" css={ css`padding-top: 20px;` }>
            <Trans i18nKey="home.transferWorkspace.ownership.acceptSuccessful.message">
            Workspace “{ workspaceName }” successfully transferred to { newOwnerName }
            </Trans>
          </Text>
        </Column>
      </Dialog.Body>
      <Dialog.Footer>
        <Button type="button" color="neutral" variant="outlined" onClick={ onClose }>
          <Trans i18nKey="shared.close">Close</Trans>
        </Button>
        <Button color="primary" onClick={ () => onOpenWorkspaceClick({ workspaceId, openAsBackend }) }>
          <Trans i18nKey="home.transferWorkspace.ownership.acceptSuccessful.goToWorkspace">
            Go to Workspace
          </Trans>
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

TransferAcceptWorkspaceOwnershipSuccessDialog.ID = 'TransferAcceptWorkspaceOwnershipSuccessDialog';
