// @flow

import React from 'react';
import { Dialog, Button, SelectField, useModal } from '@8base/boost';
import { Query, useMutation } from 'react-apollo';
import { Form, Field } from '@8base-react/forms';

import { apolloSelectors } from 'graphql/selectors';
import { Trans } from 'utils/translate';
import { USERS_LIST_QUERY } from 'graphql/queries';
import { ROLE_UPDATE_MUTATION } from 'graphql/mutations';
import { TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';
import { i18n } from 'i18n';

const ROLE_ADD_USER_DIALOG_ID = 'ROLE_ADD_USER_DIALOG_ID';

const RoleAddUserDialog = () => {
  const { closeModal } = useModal();

  const [roleUpdate] = useMutation(ROLE_UPDATE_MUTATION, {
    refetchQueries: ['UsersList'],
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('dialogs.roleAddUser.successMessage'),
    },
  });

  const onSubmit = async (data, roleId) => {
    window.trackEvent('Team', 'Update user');

    await roleUpdate({
      variables: {
        data: {
          users: {
            connect: data.users.map((id) => ({ id })),
          },
        },
        filter: {
          id: roleId,
        },
      },
    });
  };

  const onSuccess = () => {
    closeModal(ROLE_ADD_USER_DIALOG_ID);
  };

  const onClose = () => {
    closeModal(ROLE_ADD_USER_DIALOG_ID);
  };

  return (
    <Dialog id={ ROLE_ADD_USER_DIALOG_ID } onClose={ onClose }>
      { ({ args: { roleId }}) => (
        <Form onSubmit={ (data) => onSubmit(data, roleId) } onSuccess={ onSuccess }>
          { ({ handleSubmit, submitting, pristine }) => (
            <form onSubmit={ handleSubmit }>
              <Dialog.Header title={ i18n.t('dialogs.roleAddUser.title') } onClose={ onClose } />
              <Dialog.Body>
                <Query query={ USERS_LIST_QUERY } fetchPolicy="network-only" variables={{ filter: { roles: { every: { id: { not_equals: roleId }}}}}}>
                  { ({ data, loading }) => (
                    <Field
                      name="users"
                      label={ i18n.t('dialogs.roleAddUser.usersLabel') }
                      placeholder={ i18n.t('dialogs.roleAddUser.usersPlaceholder') }
                      component={ SelectField }
                      options={ apolloSelectors.usersList.getSelectOptions(data) }
                      loading={ loading }
                      multiple
                    />
                  ) }
                </Query>
              </Dialog.Body>
              <Dialog.Footer>
                <Button
                  type="button"
                  color="neutral"
                  variant="outlined"
                  onClick={ onClose }
                  disabled={ submitting }
                >
                  <Trans i18nKey="shared.cancel" />
                </Button>
                <Button
                  type="submit"
                  disabled={ pristine }
                  loading={ submitting }
                  onClick={ handleSubmit }
                >
                  <Trans i18nKey="shared.add" />
                </Button>
              </Dialog.Footer>
            </form>
          ) }
        </Form>
      ) }
    </Dialog>
  );
};

RoleAddUserDialog.ID = ROLE_ADD_USER_DIALOG_ID;

export { RoleAddUserDialog };

