// @flow
// $FlowFixMe waiting for update Flows
import React, { Suspense } from 'react';
import { Loader } from '@8base/boost';

export const WaitingComponent = ({ children }: { children: React$Node }) => {
  return (
    <Suspense fallback={ <Loader stretch /> }>
      { children }
    </Suspense>
  );
};
