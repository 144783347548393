import { createReducer, createAction } from 'redux-act';

const customCreateReducer = (handlers, defaultState) => {
  const customReducer = createReducer(handlers, defaultState);

  /** wrapper, which allows to use curried function */
  customReducer.onc = (typeOrActionCreator, handler) =>
    customReducer.on(typeOrActionCreator, (state, payload) => handler(payload)(state));

  /** wrapper, which allows to pass handling action to other reducer */
  customReducer.passToReducer = (fieldName, actionCreators, otherReducer) => {
    const actionCreatorArray = Array.isArray(actionCreators) ? actionCreators : [actionCreators];

    actionCreatorArray.forEach(actionCreator => {
      customReducer.on(
        actionCreator,
        (state, payload) => ({ ...state, [fieldName]: otherReducer(state[fieldName], actionCreator(payload)) }),
      );
    });

    return customReducer;
  };


  return customReducer;
};

export {
  customCreateReducer as createReducer,
  customCreateReducer as createReducerNoObject, // hack for the typings. todo -> find a normal solution
  createAction,
};
