// @flow
// $FlowFixMe
import React, { memo } from 'react';

import { Icon, Avatar, Text, Column, Row, Paper, Divider, styled } from '@8base/boost';

// $FlowFixMe
import { ReactComponent as LogoImage } from 'images/8base-emblem.svg';
import { Responsive, DetailImg } from 'common/components';

import { INBOX_TYPE } from 'common/constants/inbox';

import type { InboxItemFragment } from 'graphql/__generated__';
import { InboxActionManager } from './InboxActionManager';
import {
  ownerType,
  transformInviteInfo,
  transformNotificationInfo,
  transformTransferInfo,
  newOwnerNameStringFunc,
  newOwnerAvatar,
  ownerAvatar,
} from './InboxList.helpers';

// -- styled components --

const InboxItemWrapper = styled(Paper)`
  width: 100%;
  overflow: visible;
  padding-left: 80px;
  min-width: 330px;
  margin-bottom: 16px;

  @media screen and (max-width: 991px) {
    padding-left: 24px;
  }
`;

const StyledAvatarWrapper = styled.div`
  position: absolute;
  left: 24px;
  margin-top: 6px;
`;

const StyledMessage = styled(Text)`
  font-weight: 500;
`;

const DetailsText = styled(Text)`
  color: ${({ theme }) => theme.COLORS.GRAY_50};
`;

const LesserDetailsText = styled(DetailsText)`
  font-size: 0.8em;
`;

const ActionWrapper = styled.div`
  margin-left: auto;
`;

const StyledDivider = styled(Divider)`
  height: 1px;
`;

// -- main --

const InboxListItem = ({ item }: { item: InboxItemFragment }) => {
  switch (item.type) {
    case INBOX_TYPE.orgInvite:
    case INBOX_TYPE.envInvite: {
      const inboxData = transformInviteInfo(item);

      return (
        <InboxItemWrapper padding="lg">
          <Responsive.HideOn breakpoints={ ['xs', 'sm', 'md'] }>
            <StyledAvatarWrapper>
              <Avatar
                src={ inboxData.userImg }
                firstName={ inboxData.firstName }
                lastName={ inboxData.lastName }
                size="sm"
              />
            </StyledAvatarWrapper>
          </Responsive.HideOn>
          <Column gap="md">
            <Row stretch>
              <Column gap="md">
                <StyledMessage kind="h4">
                  { inboxData.message }
                </StyledMessage>
                <Row alignItems="center" >
                  { inboxData.infoImg && (
                    <DetailImg src={ inboxData.infoImg } />
                  ) }
                  <Text>
                    { inboxData.infoText }
                  </Text>
                  { inboxData.infoDetails && (
                    <>
                      <DetailsText> | </DetailsText>
                      <DetailsText>
                        { inboxData.infoDetails }
                      </DetailsText>
                    </>
                  ) }
                </Row>
              </Column>
              <ActionWrapper>
                <Responsive.HideOn breakpoints={ ['xs', 'sm', 'md'] }>
                  <InboxActionManager inbox={ item } />
                </Responsive.HideOn>
              </ActionWrapper>
            </Row>
            <Responsive.HideOn breakpoints={ ['lg'] }>
              <InboxActionManager inbox={ item } />
            </Responsive.HideOn>
            <StyledDivider noOffset />
            <Text kind="overline-1">
              { inboxData.date }
            </Text>
          </Column>
        </InboxItemWrapper>
      );
    }

    case INBOX_TYPE.notification: {
      const inboxData = transformNotificationInfo(item);

      return (
        <InboxItemWrapper padding="lg">
          <Responsive.HideOn breakpoints={ ['xs', 'sm', 'md'] }>
            <StyledAvatarWrapper>
              <LogoImage />
            </StyledAvatarWrapper>
          </Responsive.HideOn>
          <Column gap="md">
            <Row stretch>
              <Column gap="md">
                <StyledMessage kind="h4">
                  { inboxData.header }
                </StyledMessage>
                { inboxData.body && (
                  <Text>
                    { inboxData.body }
                  </Text>
                ) }
                { inboxData.image && (
                  // eslint-disable-next-line jsx-a11y/alt-text
                  <img src={ inboxData.image.src } />
                ) }
              </Column>
              <ActionWrapper>
                <Responsive.HideOn breakpoints={ ['xs', 'sm', 'md'] }>
                  <InboxActionManager inbox={ item } />
                </Responsive.HideOn>
              </ActionWrapper>
            </Row>
            <Responsive.HideOn breakpoints={ ['lg'] }>
              <InboxActionManager inbox={ item } />
            </Responsive.HideOn>
            <StyledDivider noOffset />
            <Text kind="overline-1">
              { inboxData.date }
            </Text>
          </Column>
        </InboxItemWrapper>
      );
    }

    case INBOX_TYPE.workspaceTransfer: {
      const {
        header,
        workspace,
        owner,
        newOwner,
        date,
      } = transformTransferInfo(item);

      const OwnerAvatar = ownerAvatar[owner.type];
      const NewOwnerAvatar = newOwnerAvatar[newOwner.type];
      const getNewOwnerNameString = newOwnerNameStringFunc[newOwner.type];

      const renderAdditionalInfo = () => (
        <Row alignItems="center" gap="md" stretch>
          <Column>
            <Row alignItems="center">
              <DetailImg src={ workspace.image.src } />
              <Column gap="none">
                <Text>{ workspace.name }</Text>
                <LesserDetailsText>Workspace</LesserDetailsText>
              </Column>
            </Row>
          </Column>
          <Column>
            <Icon name="ArrowRight" color="GRAY_40" />
          </Column>
          <Column>
            <Row alignItems="center">
              <NewOwnerAvatar newOwner={ newOwner } />
              <Column gap="none">
                <Text>{ getNewOwnerNameString(newOwner) }</Text>
                <LesserDetailsText>{ ownerType[newOwner.type] }</LesserDetailsText>
              </Column>
            </Row>
          </Column>
        </Row>
      );

      return (
        <InboxItemWrapper padding="lg">
          <Responsive.HideOn breakpoints={ ['xs', 'sm', 'md'] }>
            <StyledAvatarWrapper>
              <OwnerAvatar owner={ owner } />
            </StyledAvatarWrapper>
          </Responsive.HideOn>
          <Column gap="md">

            <Row stretch>
              <Column gap="md">
                <StyledMessage kind="h4">
                  { header }
                </StyledMessage>

                <Responsive.HideOn breakpoints={ ['xs', 'sm', 'md', 'lgW'] }>
                  { renderAdditionalInfo() }
                </Responsive.HideOn>

              </Column>

              <ActionWrapper>
                <Responsive.HideOn breakpoints={ ['xs', 'sm', 'md'] }>
                  <InboxActionManager inbox={ item } />
                </Responsive.HideOn>
              </ActionWrapper>
            </Row>

            <Responsive.HideOn breakpoints={ ['xlg'] }>
              { renderAdditionalInfo() }
            </Responsive.HideOn>

            <Responsive.HideOn breakpoints={ ['lg'] }>
              <InboxActionManager inbox={ item } />
            </Responsive.HideOn>

            <StyledDivider noOffset />

            <Text kind="overline-1">
              { date }
            </Text>
          </Column>
        </InboxItemWrapper>
      );
    }

    default: {
      return null;
    }
  }
};

const memoizedInboxListItem = memo(InboxListItem);

export { memoizedInboxListItem as InboxListItem };
