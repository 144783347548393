exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DraggableSelectItem__draggable-item___2Wt64 {\n  display: block;\n  padding: 0.4rem 0.8rem; }\n  .DraggableSelectItem__draggable-item___2Wt64 .formCheck {\n    margin: 0; }\n  .DraggableSelectItem__draggable-item___2Wt64:hover {\n    /*box-shadow: 0 2px 8px 0 rgba(0,0,0,0.08);*/ }\n\n.DraggableSelectItem__draggable-item--dragging___1Z2Zh {\n  opacity: 0; }\n\n.DraggableSelectItem__draggable-source___2qQf- {\n  cursor: move;\n  font-size: 1.6rem;\n  color: #d0d7dd;\n  padding: 0.4rem;\n  padding-left: 1.6rem; }\n", ""]);

// exports
exports.locals = {
	"draggable-item": "DraggableSelectItem__draggable-item___2Wt64",
	"draggable-item--dragging": "DraggableSelectItem__draggable-item--dragging___1Z2Zh",
	"draggable-source": "DraggableSelectItem__draggable-source___2qQf-"
};