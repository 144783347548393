// @flow

import React, { PureComponent } from 'react';
import * as R from 'ramda';
import InputMask from 'react-input-mask';
import { compose, setDisplayName } from 'recompose';

import { pipeClassNames, concatStylebyCond } from 'utils/styles';
import { withReduxFieldForm } from 'utils/hocs/forms';
import { addPropsToElement } from 'utils/addProps';

import styles from './Input.scss';

/**
 * @prop {*} innerRef used to pass ref callback
 * @prop {*} inputRef legacy alias innerRef
 */
type InputMainCommonHelperProps = {
  value: any,
  name?: string,
  onChange?: Function,
  onFocus?: Function,
  onBlur?: Function,
  onKeyDown?: Function,
  onKeyUp?: Function,
  onKeyPress?: Function,
  onMouseEnter?: Function,
  onMouseLeave?: Function,

  error?: string,
  touched?: boolean,

  placeholder?: string,
  showControls?: boolean,
  autoFocus?: boolean,
  autoComplete?: 'on' | 'off',

  required?: boolean,
  disabled?: boolean,
  type?: string,
  mask?: string,
  maxLength?: number,
  min?: number,
  max?: number,

  showPreviewComponent?: (mixed, mixed) => void | boolean,
  previewComponent?: React$Element<*>,
  leftIcon?: React$Node,
  leftComponent?: React$Element<*>,
  rightComponent?: React$Element<*>,

  showClearButton: boolean,
  onClear?: ({}) => void,

  className?: string,

  inputRef?: Function,
  innerRef?: Function,

  deleteIconProps?: Object,
  'data-e2e-id'?: string,
}

export class InputMainCommonHelper extends PureComponent<InputMainCommonHelperProps> {

  static defaultProps: $Shape<InputMainCommonHelperProps> = {
    placeholder: '',
    required: false,
    disabled: false,
    type: 'text',
    showControls: false,
    showClearButton: false,
  };

  onChangeNumber = (val: number) => {
    const { onChange, max, min, maxLength } = this.props;
    if (!onChange) return;
    if (max && val > max) return onChange(max);
    if (min && val < min) return onChange(min);
    if (maxLength && String(val).length > maxLength) return onChange(Number(String(val).slice(0, maxLength)));
    onChange(val);
  };

  onChange = ({ target: { value }}: InputEvent) => {
    const { type, onChange } = this.props;
    if (!onChange) return;
    // if (!value) return onChange(null);
    if (type === 'number' && value !== '') return this.onChangeNumber(Number(value)); //TODO deprecated!!! move validation logic to normalize param in redux form
    onChange(value);
  };

  ifShowPreviewComponent = (): boolean => {
    const { value, name, showPreviewComponent } = this.props;
    if (typeof showPreviewComponent === 'function') return !!showPreviewComponent(value, name);
    return !!showPreviewComponent;
  };

  findComponent = () => {
    const { mask, previewComponent } = this.props;
    if (this.ifShowPreviewComponent() && previewComponent) return previewComponent;
    if (mask) return InputMask;
    return <input />;
  };

  render() {
    const {
      autoFocus, autoComplete, placeholder, type, required, mask, maxLength, showControls, disabled, min, max, leftIcon, leftComponent,
      rightComponent, inputRef, innerRef, value, name, touched, error, showClearButton, onClear, className = '',
      onFocus, onBlur, onKeyDown, onKeyPress, onKeyUp, onMouseEnter, onMouseLeave, deleteIconProps,
    } = this.props;
    const hasError = !!touched && !!error;
    const hasLeftIcon = !R.isNil(leftIcon);
    const Component = this.findComponent();

    const inputClassName = pipeClassNames(
      className,
      styles.input,
      concatStylebyCond('show-controls', showControls),
      concatStylebyCond(styles.error, hasError),
    );

    const wrapperStyleName = pipeClassNames(
      'input-wrapper',
      concatStylebyCond('input-wrapper--left-icon', hasLeftIcon || !!leftComponent),
    );

    return (
      <div styleName={ wrapperStyleName } data-e2e-id={ this.props['data-e2e-id'] }>
        <If condition={ !!leftComponent }>
          <div styleName="left-component">
            { addPropsToElement({ value }, leftComponent) }
          </div>
        </If>
        <If condition={ hasLeftIcon }>
          <div styleName="left-icon">{ leftIcon }</div>
        </If>
        { addPropsToElement({
          className: inputClassName,
          autoFocus,
          type,
          mask,
          maxLength,
          min,
          max,
          name,
          value,
          placeholder,
          onChange: this.onChange,
          required,
          disabled,
          onFocus,
          onBlur,
          onKeyDown,
          autoComplete,
          onKeyUp,
          onKeyPress,
          onMouseEnter,
          onMouseLeave,
          ref: innerRef || inputRef,
        }, Component) }
        <If condition={ !!rightComponent }>
          <div styleName="right-component">
            { addPropsToElement({ value }, rightComponent) }
          </div>
        </If>
        <If condition={ showClearButton }>
          <div { ...deleteIconProps } styleName="delete-field" onClick={ onClear && onClear({ value, name }) }>
            <span className="icon icon-cross" />
          </div>
        </If>
      </div>
    );
  }
}

const InputField = compose(
  setDisplayName('InputField'),
  withReduxFieldForm(),
)(InputMainCommonHelper);

export default InputField;

