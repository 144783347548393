// @flow
// $FlowFixMe
import React, { useCallback, useState, useRef } from 'react';
import { Dropdown, Column, Row, Icon, Text, Input } from '@8base/boost';
import { css } from '@emotion/core';
import * as R from 'ramda';
import styled from '@emotion/styled';

import { useTranslation } from 'react-i18next';
import { useWorkspacesList, useOrganizationsList } from 'graphql/hooks';
import { apolloWorkspacesListSelectors as workspacesListSelectors } from 'graphql/selectors';
import type { WorkspaceItem } from 'graphql/__generated__';

import { DeveloperHomeWorkspaceSearchItem } from '../components';
import workspaceDefaultLogo from 'images/workspace-default-logo.svg';

// Type Definitions
import type { OpenWorkspaceHandler } from 'utils/hooks/useOnOpenWorkspace';

const OrganizationImage = styled.img`
  flex-shrink: 0;
  object-fit: cover;
  width: 16px;
  height: 16px;
  margin-right: 12px;
  border-radius: ${props => props.theme.SIZES.MAIN_BORDER_RADIUS};
`;

const StyledSearchTab = styled(Column)`
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.COLORS.GRAY_20};
    margin-bottom: 16px;
    padding-bottom: 12px;
  }
`;

const hasNotWorkspaceInOrganization = (workspace) => {
  return !Boolean(workspace.organization);
};

const hasWorkspaceInOrganization = (workspace) => {
  return Boolean(workspace.organization);
};

const groupByOrganization = (workspaceList) => R.groupBy(workspace => workspace.organization.name)(workspaceList);

type DeveloperHomeHeaderSearchContainerProps = {
  onOpenWorkspaceClick: OpenWorkspaceHandler
}

export const DeveloperHomeHeaderSearchContainer = ({ onOpenWorkspaceClick }: DeveloperHomeHeaderSearchContainerProps) => {
  const searchInput = useRef(null);
  const [search, setSearch] = useState('');
  const { t } = useTranslation();
  const { organizationsList } = useOrganizationsList();
  const { allWorkspacesList } = useWorkspacesList();

  const sortedWorkspaceList = workspacesListSelectors.getWorkspacesListSortedByLastAccess(allWorkspacesList);
  const recentWorkspaces = sortedWorkspaceList.slice(0, 3);

  const filterByQuerySearch = useCallback((workspace: WorkspaceItem) => {
    if (!search || !workspace.name) {
      return true;
    }

    const workspaceName: string = workspace.name.toLowerCase();
    const searchStr: string = search.toLowerCase();

    return workspaceName.includes(searchStr);
  }, [search]);

  const myWorkspacesList = workspacesListSelectors.getMyWorkspacesList(allWorkspacesList, { withAlphabetSorting: true })
    .filter(filterByQuerySearch)
    .filter(hasNotWorkspaceInOrganization);

  const sharedWorkspacesList = workspacesListSelectors.getSharedWorkspacesList(allWorkspacesList, { withAlphabetSorting: true })
    .filter(filterByQuerySearch)
    .filter(hasNotWorkspaceInOrganization);

  const organizationsWorkspaces = groupByOrganization(
    workspacesListSelectors
      .getWorkspacesList(allWorkspacesList, { withAlphabetSorting: true })
      .filter(hasWorkspaceInOrganization)
      .filter(filterByQuerySearch),
  );

  const onQueryChange = useCallback((value) => {
    setSearch(value);
  }, [setSearch]);

  const onFocusSearch = useCallback(() => {
    if (!searchInput.current) {
      return;
    }
    searchInput.current.focus();
  }, [searchInput]);

  const onClearSearch = useCallback(() => {
    setSearch('');
    onFocusSearch();
  }, [setSearch, onFocusSearch]);

  const isEmptySearchQuery = R.isEmpty(search);
  const isEmptySearchResults = R.isEmpty(myWorkspacesList) && R.isEmpty(sharedWorkspacesList) && R.isEmpty(organizationsWorkspaces);

  const isEmptyOrganizationsWorkspaces = R.isEmpty(organizationsWorkspaces);
  const isEmptySharedWorkspaces = sharedWorkspacesList.length === 0;
  const isEmptyMyWorkspaces = myWorkspacesList.length === 0;
  const isEmptyAllWorkspaces = R.pathEq(['items', 'length'], 0, allWorkspacesList);

  const isVisibleMyWorkspaces = !isEmptySearchQuery && !isEmptyMyWorkspaces;
  const isVisibleSharedWorkspaces = !isEmptySearchQuery && !isEmptySharedWorkspaces;

  const getOrganizationImageByName = (name) => {
    const organization = R.filter(R.propEq('name', name), organizationsList.items);
    return R.pathOr(workspaceDefaultLogo, [0, 'image', 'downloadUrl'], organization);
  };

  return (
    <div css={ css`width: 400px; position: relative;` }>
      <Dropdown
        stretch
        defaultOpen={ false }
      >
        <Dropdown.Head stopClickPropagation >
          { ({ isOpen, toggleDropdown }) => (
            <Input
              placeholder={ t('shared.searchWorkspaces') }
              insideRef={ searchInput }
              rightIcon={ <>
                <If condition={ isEmptySearchQuery }>
                  <Icon name="TopSearch" color="GRAY_40" css={ css`width: 16px; height: 16px;` } onClick={ onFocusSearch } />
                </If>
                <If condition={ !isEmptySearchQuery }>
                  <Icon name="Cancel" color="GRAY_40" css={ css`width: 16px; height: 16px;` } onClick={ onClearSearch } />
                </If>
                </> }
              value={ search }
              onChange={ onQueryChange }
              onClick={ () => !isOpen && toggleDropdown() }
            />
          ) }
        </Dropdown.Head>
        <Dropdown.Body pin="left" background="white" css={ css`width: 100%; padding: 20px; overflow: auto; max-height: 500px;` }>
          <If condition={ !isEmptySearchQuery && isEmptySearchResults }>
            <Text color="GRAY_40">No matches found for "{ search }"</Text>
          </If>
          <If condition={ isEmptySearchQuery }>
            <If condition={ isEmptyAllWorkspaces }>
              <Text color="GRAY_40">Create workspaces to see them in search results</Text>
            </If>
            <If condition={ !isEmptyAllWorkspaces }>
              <StyledSearchTab gap="sm">
                <Text kind="overline-2">Recent workspaces</Text>
                <Column gap="sm" grow={ 1 } stretch >
                  { recentWorkspaces.map((workspace) => (
                    <DeveloperHomeWorkspaceSearchItem key={ workspace.id } workspace={ workspace } onOpenWorkspaceClick={ onOpenWorkspaceClick } />
                  )) }
                </Column>
              </StyledSearchTab>
            </If>
          </If>
          <If condition={ isVisibleMyWorkspaces }>
            <StyledSearchTab gap="sm">
              <Text kind="overline-2">My Workspaces</Text>
              <Column gap="sm" grow={ 1 } stretch >
                { myWorkspacesList.map((workspace) => (
                  <DeveloperHomeWorkspaceSearchItem key={ workspace.id } workspace={ workspace } onOpenWorkspaceClick={ onOpenWorkspaceClick } />
                )) }
              </Column>
            </StyledSearchTab>
          </If>
          <If condition={ isVisibleSharedWorkspaces }>
            <StyledSearchTab gap="sm">
              <Text kind="overline-2">Shared Workspaces</Text>
              <Column gap="sm" grow={ 1 } stretch >
                { sharedWorkspacesList.map((workspace) => (
                  <DeveloperHomeWorkspaceSearchItem key={ workspace.id } workspace={ workspace } onOpenWorkspaceClick={ onOpenWorkspaceClick } />
                )) }
              </Column>
            </StyledSearchTab>
          </If>
          <If condition={ !isEmptySearchQuery && !isEmptyOrganizationsWorkspaces }>
            { Object.keys(organizationsWorkspaces).map(organizationKey => (
              <StyledSearchTab key={ organizationKey } gap="sm">
                <Row>
                  <OrganizationImage
                    src={ getOrganizationImageByName(organizationKey) }
                  />
                  <Text kind="overline-2"> { organizationKey } </Text>
                </Row>
                <Column gap="sm" grow={ 1 } stretch>
                  { organizationsWorkspaces[organizationKey].map(workspace => (
                    <DeveloperHomeWorkspaceSearchItem key={ workspace.id } workspace={ workspace } onOpenWorkspaceClick={ onOpenWorkspaceClick } />
                  )) }
                </Column>
              </StyledSearchTab>
            )) }
          </If>
        </Dropdown.Body>
      </Dropdown>
    </div>
  );
};
