//@flow

import * as R from 'ramda';
import { createSelector } from 'reselect';

import type { Selector } from 'reselect';

export type ApolloEnvironmentVariable = $Shape<{
  id: string,
  name: string,
  value: string,
}>;

export const getEnvironmentVariable: Selector<
  ApolloEnvironmentVariable,
  any,
  ApolloEnvironmentVariable,
> = (environmentVariable) => environmentVariable;

export const getId: Selector<
  ApolloEnvironmentVariable,
  any,
  string,
> = createSelector(
  getEnvironmentVariable,
  R.prop('id'),
);

export const getName: Selector<
  ApolloEnvironmentVariable,
  any,
  string,
> = createSelector(
  getEnvironmentVariable,
  R.prop('name'),
);

export const getValue: Selector<
  ApolloEnvironmentVariable,
  any,
  string,
> = createSelector(
  getEnvironmentVariable,
  R.prop('value'),
);

