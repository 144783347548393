// @flow

import Navigation from './Navigation';
import Nav from './Nav';
import { AccordionNavNavigationLeftCommonHelper } from './Accordion';
import { CommonChildNavigationLeftCommonHelper } from './Child';
import {
  ButtonHeadCommonNavigationLeftCommonHelper,
  ButtonsHeadNavigationLeftCommonHelper,
  CountHeadCommonNavigationLeftCommonHelper,
  HeadNavigationLeftCommonHelper,
  ImageHeadCommonNavigationLeftCommonHelper,
  TitleHeadCommonNavigationLeftCommonHelper,
} from './head';

Nav.Accordion = AccordionNavNavigationLeftCommonHelper;
Nav.Child = CommonChildNavigationLeftCommonHelper;
Nav.Button = ButtonHeadCommonNavigationLeftCommonHelper;
Nav.Buttons = ButtonsHeadNavigationLeftCommonHelper;
Nav.Count = CountHeadCommonNavigationLeftCommonHelper;
Nav.Head = HeadNavigationLeftCommonHelper;
Nav.Image = ImageHeadCommonNavigationLeftCommonHelper;
Nav.Title = TitleHeadCommonNavigationLeftCommonHelper;

export { Navigation, Nav };
