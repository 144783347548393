// @flow
import { createStore } from 'redux';
import { processEnv } from 'utils';
import reducer from 'store/core/store/core.reducer';

export const getStore = () => {
  const reduxDevTools = (!processEnv.isProduction() && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined')
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f;

  const store = createStore(
    (reducer: Function),
    {},
    reduxDevTools,
  );

  // $FlowIgnore
  if (module.hot) {
    (module.hot: any).accept(() => {
      const nextRootReducer: Function = require('store/core/store/core.reducer').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};
