// @flow
// $FlowFixMe waiting for update Flow
import React, { useEffect, useState } from 'react';
// $FlowFixMe waiting for update Flow
import { Route, Redirect, Switch, useLocation, useHistory } from 'react-router-dom';
import { Row, Button, Icon } from '@8base/boost';
import css from '@emotion/css';

import { i18n } from 'i18n';
import { AppFlow } from 'common/AppFlow';
import { APP_URL, useBuildUrl } from 'common/routing';
import { CommonFlowBars } from 'common/CommonFlow/CommonFlowBars';
import { UserFlow } from 'common/UserFlow';
import { useUserAccountInfo } from 'graphql/hooks';

import { AccountPage, AccountPlate } from './components';
import { SignInAndSecurity } from './routes/sign-in-and-security';
import { Workspaces } from './routes/workspaces';
import { Billing } from './routes/billing';
import { Profile } from './routes/profile';

const Account = () => {
  const buildUrl = useBuildUrl();
  const location = useLocation();
  const history = useHistory();
  const [prevLocation, setPrevLocation] = useState();

  const [isCompletedUserAccountInfo, setCompletedUserAccountInfo] = useState(false);

  useUserAccountInfo({
    onCompleted: () => {
      setCompletedUserAccountInfo(true);
    },
  });

  useEffect(() => {
    if (isCompletedUserAccountInfo) {
      requestAnimationFrame(window.htmlLoader.hide);
    }
  }, [isCompletedUserAccountInfo]);

  useEffect(() => {
    // need to show back button
    if (location.pathname === APP_URL.account) {
      if (location.state && location.state.prevLocation) {
        setPrevLocation(location.state.prevLocation);
      } else {
        setPrevLocation(undefined);
      }
    }

  }, [location]);

  return (
    <AccountPage>
      <CommonFlowBars />
      <UserFlow.HeaderWhite
        leftContent={ (
          <If condition={ prevLocation } >
            <Button
              size="sm"
              color="neutral"
              css={ css`margin-left: 94px;` }
              onClick={ () => history.push(prevLocation) }
            >
              <Icon name="ArrowLeft" />
                Back
            </Button>
          </If>
        ) }
        rightContent={ (
          <Row gap="lg" alignItems="center">
            <UserFlow.LearningCenter />
            <UserFlow.UserDropdown prevLocation={ prevLocation } />
          </Row>
        ) }
      />
      <AccountPlate
        nav={ (
          <AppFlow.SecondaryNav.Plate>
            <AppFlow.SecondaryNav.Item
              label={ i18n.t('developerHome.account.profile.title', { defaultValue: 'My Profile' }) }
              to={ buildUrl(APP_URL.accountProfile) }
            />
            <AppFlow.SecondaryNav.Item
              label={ i18n.t('developerHome.account.signInAndSecurity.title', { defaultValue: 'Sign-In & Security' }) }
              to={ buildUrl(APP_URL.signInAndSecurity) }
            />
            <AppFlow.SecondaryNav.Item
              label={ i18n.t('developerHome.account.workspaces.title', { defaultValue: 'Workspaces' }) }
              to={ buildUrl(APP_URL.accountWorkspaces) }
            />
            <AppFlow.SecondaryNav.Item
              label={ i18n.t('developerHome.account.billing.title', { defaultValue: 'Billing' }) }
              to={ buildUrl(APP_URL.billing) }
            />
          </AppFlow.SecondaryNav.Plate>
        ) }
        content={ (
          <AppFlow.Content>
            <Switch>
              <Route exact path={ APP_URL.accountProfile } component={ Profile } />
              <Route exact path={ APP_URL.signInAndSecurity } component={ SignInAndSecurity } />
              <Route exact path={ APP_URL.billing } component={ Billing } />
              <Route exact path={ APP_URL.accountWorkspaces } component={ Workspaces } />
              <Redirect from={ APP_URL.account } to={ APP_URL.accountProfile } />
            </Switch>
          </AppFlow.Content>
        ) }
      />
    </AccountPage>
  );
};

export default Account;
