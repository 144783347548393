//@flow

// $FlowFixMe waiting for update Flow
import React, { useCallback } from 'react';
import { Text, Heading, Link } from '@8base/boost';

import { Trans } from 'utils/translate';

type PaymentProcessingProps = {
  type: 'default' | 'downgrading' | 'simple';
};

export const PaymentSubmitFailed = ({ type = 'default' }:PaymentProcessingProps) => {

  const onContactSupport = useCallback((e) => {
    e.preventDefault();

    if (window.HubSpotConversations && typeof window.HubSpotConversations.widget.open === 'function') {
      window.document.body.classList.remove('hide-hubspot');

      window.HubSpotConversations.widget.open();
    }
  }, []);

  switch (type) {
    case 'simple': {
      return (
        <Heading type="h3">
          <Trans i18nKey="billingUpdateDialog.cardUpdateError.title">
            There was a problem processing your card
          </Trans>
        </Heading>
      );
    }

    case 'downgrading': {
      return (
        <Heading type="h3">
          <Trans i18nKey="billingUpdateDialog.downgradeError.title">
            There was a problem downgrading your plan
          </Trans>
        </Heading>
      );
    }

    case 'default':
    default: {
      return (
        <React.Fragment>
          <Heading type="h3">
            <Trans i18nKey="billingUpdateDialog.cardUpdateError.title">
              There was a problem processing your payment
            </Trans>
          </Heading>
          <Text color="LIGHT_TEXT_COLOR">
            <Trans i18nKey="billingUpdateDialog.paymentError.note">
              Please update your payment details<br />and try again or <Link onClick={ onContactSupport }>contact support</Link>.
            </Trans>
          </Text>
        </React.Fragment>
      );
    }
  }
};
