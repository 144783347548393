// @flow
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Row, Text } from '@8base/boost';

import type { BillingDetailsResponse } from 'graphql/__generated__';

import { BRANDS_IMAGES } from 'common/constants/brands';

type BanCardLastFourProps = {
  card: BillingDetailsResponse | null;
  borderless?: boolean;
}

const BankCardWrapperTag = styled('div')(props =>
  !props.borderless &&
    ({
      borderRadius: '4px',
      padding: '8px 16px',
      background: props.theme.COLORS.GRAY_10,
      border: `1px solid ${props.theme.COLORS.PRIMARY_BORDER_COLOR}`,
    }),
);

const BankCardImageTag = styled.img`
  height: 20px;
`;

const BankCardMaskDotsTag = styled(Text)`
  position: relative;
  top: 2px;
  line-height: 1;
`;

export const BankCardLastFour = ({ card, borderless = false, ...rest }: BanCardLastFourProps) => {
  const bankCardBrandImage = !!card ? BRANDS_IMAGES.find(({ name }) => name === card.brand) || {} : {};

  return (
    <BankCardWrapperTag borderless={ borderless } { ...rest }>
      <Row alignItems="center">
        <BankCardImageTag src={ bankCardBrandImage.image } alt={ bankCardBrandImage.name } />
        <Text css={ css`white-space: nowrap;` }><BankCardMaskDotsTag>**** </BankCardMaskDotsTag>{ card && card.last4 }</Text>
      </Row>
    </BankCardWrapperTag>
  );
};

