import gql from 'graphql-tag';

import { RoleFragment } from 'graphql/fragments';

export const ROLE_UPDATE_MUTATION = gql`
  mutation RoleUpdate($data: RoleUpdateInput!, $filter: RoleKeyFilter) {
    roleUpdate(data: $data, filter: $filter) {
      ...RoleFragment
    }
  }
  ${RoleFragment}
`;

export const ROLE_DELETE_MUTATION = gql`
  mutation RoleDelete($data: RoleDeleteInput, $filter: RoleKeyFilter, $force: Boolean) {
    roleDelete(data: $data, filter: $filter, force: $force) {
      success
    }
  }
`;

export const ROLE_CREATE_MUTATION = gql`
  mutation RoleCreate($data: RoleCreateInput!) {
    roleCreate(data: $data) {
      id
      name
      description
    }
  }
`;
