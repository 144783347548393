// @flow
// $FlowFixMe
import React, { useCallback } from 'react';
// $FlowFixMe
import { useParams } from 'react-router-dom';
import { Column, useModal, Row } from '@8base/boost';
import { css } from '@emotion/core';
import * as R from 'ramda';

import { Trans } from 'utils/translate';
import type { WorkspaceItem, SystemWorkspaceListResponse } from 'graphql/__generated__';

import { WORKSPACE_KIND } from 'common/constants/workspaceKind';
import { ORGANIZATION_ROLE } from 'common/constants/organization';
import { PlusButton } from 'common/components';
import { apolloWorkspacesListSelectors } from 'graphql/selectors';
import { WorkspaceCreateDialog } from 'dialogs/WorkspaceCreateDialog';

import { DeveloperHomeOrganizationHeader } from './DeveloperHomeOrganizationHeader';
import { DeveloperHomeOrganizationWorkspacesEmpty } from './DeveloperHomeOrganizationWorkspacesEmpty';
import { DeveloperHomeOrganizationEmptyWorkspaces } from './DeveloperHomeOrganizationEmptyWorkspaces';
import { DeveloperHomeWorkspacesTabs, DeveloperHomeWorkspaces } from '../../../components';

// Types Definitions
import type { OpenWorkspaceHandler } from 'utils/hooks/useOnOpenWorkspace';

type DeveloperHomeOrganizationProps = {
  name: string;
  type?: string | null;
  description?: string | null;
  image?: string | null;
  backendWorkspacesList: SystemWorkspaceListResponse;
  frontendWorkspacesList: SystemWorkspaceListResponse;
  userList: Object[];
  onOpenWorkspaceClick: OpenWorkspaceHandler;
  onUserClick: () => void;
  onAddUserClick: () => void;
  onRemoveFromOrganization: () => void;
  onClickSettings: () => void;
  permissionRole: string;
}

const hasWorkspaceInOrganization = (organizationId: string) => (workspace: WorkspaceItem) => {
  return (workspace && workspace.organization && workspace.organization.id && workspace.organization.id === organizationId);
};

/**
 * View for organization details page
 */
export const DeveloperHomeOrganization = ({
  name,
  type,
  description,
  userList,
  backendWorkspacesList,
  frontendWorkspacesList,
  image,
  onOpenWorkspaceClick,
  onUserClick,
  onRemoveFromOrganization,
  onAddUserClick,
  onClickSettings,
  permissionRole,
}: DeveloperHomeOrganizationProps) => {
  const { organizationId } = useParams();
  const { openModal } = useModal();

  const onCreateWorkspaceClick = useCallback((kind?: string) => () => {
    openModal(WorkspaceCreateDialog.ID, { organizationId, kind });
  }, [openModal, organizationId]);

  const organizationBackendWorkspacesList = apolloWorkspacesListSelectors
    .getWorkspacesList(backendWorkspacesList, { withAlphabetSorting: true })
    .filter(hasWorkspaceInOrganization(organizationId));

  const organizationFrontendWorkspacesList = apolloWorkspacesListSelectors
    .getWorkspacesList(frontendWorkspacesList, { withAlphabetSorting: true })
    .filter(hasWorkspaceInOrganization(organizationId));

  const countBackendWorkspaces = R.pathOr(0, ['length'], organizationBackendWorkspacesList);
  const countFrontendWorkspaces = R.pathOr(0, ['length'], organizationFrontendWorkspacesList);

  const isShowCreateButton = permissionRole === ORGANIZATION_ROLE.admin || permissionRole === ORGANIZATION_ROLE.owner;

  const isBackendWorkspacesEmpty = countBackendWorkspaces === 0;
  const isCreatePermissionDenied = !isShowCreateButton;

  return (
    <Column gap="xl" css={ css`width: 100%;` } alignItems="stretch">
      <Column gap="md" css={ css`width: 100%;` } alignItems="stretch">
        <Row>
          <DeveloperHomeOrganizationHeader
            name={ name }
            type={ type }
            description={ description }
            image={ image }
            onClickSettings={ onClickSettings }
            onUserClick={ onUserClick }
            onAddUserClick={ onAddUserClick }
            userList={ userList }
            permissionRole={ permissionRole }
            onRemoveFromOrganization={ onRemoveFromOrganization }
          />
        </Row>
      </Column>
      <DeveloperHomeWorkspacesTabs
        countBackendWorkspaces={ countBackendWorkspaces }
        countFrontendWorkspaces={ countFrontendWorkspaces }
        backendWorkspaces={ (
          <Choose>
            <When condition={ isBackendWorkspacesEmpty && isCreatePermissionDenied }>
              <DeveloperHomeOrganizationEmptyWorkspaces />
            </When>
            <Otherwise>
              <DeveloperHomeWorkspaces
                myWorkspacesList={ organizationBackendWorkspacesList }
                onOpenWorkspaceClick={ onOpenWorkspaceClick }
                createButton={ isShowCreateButton && (
                  <PlusButton
                    onClick={ onCreateWorkspaceClick(WORKSPACE_KIND.general) }
                    { ...E2E.$props('developerHome.workspaces.createNewWorkspace') }
                  >
                    <Trans i18nKey="developerHome.workspaces.createNewBackendWorkspace">
                      Create Backend Workspace
                    </Trans>
                  </PlusButton>
                ) }
              />
            </Otherwise>
          </Choose>
        ) }
        frontendWorkspaces={ (
          <Choose>
            <When condition={ countBackendWorkspaces > 0 }>
              <DeveloperHomeWorkspaces
                myWorkspacesList={ organizationFrontendWorkspacesList }
                onOpenWorkspaceClick={ onOpenWorkspaceClick }
                createButton={ (
                  <PlusButton
                    onClick={ onCreateWorkspaceClick(WORKSPACE_KIND.frontend) }
                    { ...E2E.$props('developerHome.workspaces.createNewWorkspace') }
                  >
                    <Trans i18nKey="developerHome.workspaces.createNewFrontedWorkspace">
                      Create Frontend Workspace
                    </Trans>
                  </PlusButton>
                ) }
              />
            </When>
            <Otherwise>
              <DeveloperHomeOrganizationWorkspacesEmpty
                permissionRole={ permissionRole }
                createButton={ isShowCreateButton && (
                  <PlusButton
                    onClick={ onCreateWorkspaceClick(WORKSPACE_KIND.frontend) }
                    { ...E2E.$props('developerHome.workspaces.createNewWorkspace') }
                  >
                    <Trans i18nKey="developerHome.workspaces.createNewFrontedWorkspace">
                      Create Frontend Workspace
                    </Trans>
                  </PlusButton>
                ) }
              />
            </Otherwise>
          </Choose>
        ) }
      />
    </Column>
  );
};
