// @flow

import React from 'react';

import './TableWorker.scss';

type RowOverflowProps = {|
  style: Object,
  isSelected: boolean,
  isHovered: boolean,
|}

export const RowOverflow = ({ isHovered, isSelected, style }: RowOverflowProps) => (
  <div
    style={{ ...style }}
    styleName={ `row-overflow ${isHovered ? 'row-overflow--hover' : ''} ${isSelected ? 'row-overflow--selected' : ''}` }
  />
);
