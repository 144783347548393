// @flow
// $FlowFixMe waiting for update Flow
import React, { useState, useContext } from 'react';
import { AuthContext } from '@8base-react/auth';
import { Card, Button, Row, Heading, Column, Text } from '@8base/boost';
import { css } from '@emotion/core';

import { i18n } from 'i18n';
import { toast } from 'react-toastify';
import { Trans } from 'utils/translate';

export const SignInAndSecurity = () => {
  const { authClient } = useContext(AuthContext);

  const [submitting, setSubmitting] = useState(false);

  const resetPassword = async () => {
    setSubmitting(true);

    let email = null;

    try {
      ({ email } = await authClient.changePassword());
    } catch (e) {}

    if (email) {
      toast.success(i18n.t('root.myAccountDialog.resetPassword', { email }));
    } else {
      toast.error(i18n.t('error.explanation'));
    }

    setSubmitting(false);
  };

  return (
    <Card padding="xl">
      <Card.Header>
        <Heading type="h2" text={ i18n.t('developerHome.account.signInAndSecurity.title', { defaultValue: 'Sign-In & Security' }) } />
      </Card.Header>

      <Card.Body>
        <Column css={ css`height: 300px;` } gap="lg" alignItems="center" justifyContent="center" stretch>
          <svg css={ css`width: 90px;` } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
            <path fill="#D0D7DD" d="M93.7543655,42.4979081 L86.994013,42.4979081 L86.994013,29.3650157 C86.994013,14.5456044 75.1796108,2.22603329 60.3617163,2.00149675 C53.1448352,1.92566542 46.1962362,4.73399905 41.0583079,9.80312861 C35.9203796,14.8722582 33.0182142,21.7827836 32.9960086,29.0007675 L32.9960086,42.4979081 L26.2506236,42.4979081 C20.0450354,42.5170843 15.0191743,47.5434598 15,53.7496833 L15,107.748225 C15.0191743,113.954448 20.0450354,118.980824 26.2506236,119 L93.7543655,119 C99.9591388,118.98081 104.983564,113.953641 105,107.748225 L105,53.7496833 C104.983564,47.5442671 99.9591388,42.5170982 93.7543655,42.4979081 L93.7543655,42.4979081 Z M63.9439548,77.6004529 L63.9439548,96.940533 C63.9574897,99.0655935 62.3212932,100.83723 60.2020622,100.99217 C59.1246623,101.042627 58.0735591,100.649872 57.2931907,99.9052415 C56.5128224,99.1606109 56.0711957,98.1289973 56.0710128,97.0503064 L56.0710128,77.5904735 C52.3125401,75.7573519 50.3165006,71.5724797 51.2570698,67.4976187 C52.1976391,63.4227577 55.8258855,60.5363625 60.0074838,60.5363625 C64.1890821,60.5363625 67.8173285,63.4227577 68.7578977,67.4976187 C69.698467,71.5724797 67.7024275,75.7573519 63.9439548,77.5904735 L63.9439548,77.6004529 Z M79.1310494,42.4979081 L40.8689506,42.4979081 L40.8689506,29.0007675 C40.8689506,18.4380328 49.4308626,9.87524454 59.9925162,9.87524454 C70.5541699,9.87524454 79.1160818,18.4380328 79.1160818,29.0007675 L79.1310494,42.4979081 Z" />
          </svg>
          <Text>
            <Trans i18nKey="developerHome.account.signInAndSecurity.note">
              Continue below to send a “Reset Password” link to the email address associated  with your account.
            </Trans>
          </Text>
        </Column>
      </Card.Body>

      <Card.Footer>
        <Row justifyContent="end">
          <Button type="submit" loading={ submitting } onClick={ resetPassword }>
            <Trans i18nKey="shared.resetPassword">Reset Password</Trans>
          </Button>
        </Row>
      </Card.Footer>
    </Card>
  );
};
