import { ORGANIZATION_ROLE } from 'common/constants/organization';

export const getUserListFromOrganization = (organization: any) => {
  const list = (organization && organization.users) || [];

  return list.map(user => {
    return {
      ...user,
      avatarUrl: user.avatar && user.avatar.downloadUrl,
    };
  });
};

export const getUserRoleAtOrganization = (userAccountInfo, organization: any) => {
  const { email } = userAccountInfo;
  const userList = getUserListFromOrganization(organization);

  const userIndex = userList.findIndex(el => el.email === email);

  if (userIndex === -1) {
    return ORGANIZATION_ROLE.developer;
  }

  return userList[userIndex].role;
};

export const getOrganizationUserWorkspaces = (
  userEmail: string,
  organizationUsersList) => {
  const userIndex = organizationUsersList.findIndex(el => el.email === userEmail);

  if (userIndex === -1) return [];

  return organizationUsersList[userIndex].workspaces;
};

export const checkManagerRole = (role: string):boolean => role === ORGANIZATION_ROLE.manager;

export const checkAddAccessOpportunity = ({ userEmail, profileEmail, userPermissionRole, profileRole }):boolean => {
  if (userPermissionRole === ORGANIZATION_ROLE.owner) {
    return profileRole !== ORGANIZATION_ROLE.admin && userEmail !== profileEmail;
  }

  if (userPermissionRole === ORGANIZATION_ROLE.admin) {
    return profileRole !== ORGANIZATION_ROLE.owner && profileRole !== ORGANIZATION_ROLE.admin;
  }

  if (userPermissionRole === ORGANIZATION_ROLE.manager) {
    return profileRole === ORGANIZATION_ROLE.developer && userEmail !== profileEmail;
  }

  return false;
};

export const checkRemoveFromOrganizationOpportunity = ({ userEmail, profileEmail, userPermissionRole, profileRole }):boolean => {
  if (userPermissionRole === ORGANIZATION_ROLE.owner) {
    return userEmail !== profileEmail;
  }

  if (userPermissionRole === ORGANIZATION_ROLE.admin) {
    return profileRole !== ORGANIZATION_ROLE.owner;
  }

  if (userPermissionRole === ORGANIZATION_ROLE.manager) {
    return profileRole !== ORGANIZATION_ROLE.owner && profileRole !== ORGANIZATION_ROLE.admin;
  }

  return false;
};
