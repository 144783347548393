// @flow
import React from 'react';

import { WorkspaceProxyContext } from './WorkspaceProxyContext';

export const withWorkspaceProxy =
  (WrappedComponent: React$ComponentType<any>) => (props: any) => {

    return (
      <WorkspaceProxyContext.Consumer>
        { ({ apiUri, webSocketUri, workspaceListLoaded }) => (
          <WrappedComponent
            { ...props }
            apiUri={ apiUri }
            webSocketUri={ webSocketUri }
            workspaceListLoaded={ workspaceListLoaded }
          />
        ) }
      </WorkspaceProxyContext.Consumer>
    );
  };

