// @flow
// $FlowFixMe
import React from 'react';
import { Avatar } from '@8base/boost';
import styled from '@emotion/styled';

// $FlowFixMe
import { DateTime } from 'luxon';
import { i18n } from 'i18n';
import * as R from 'ramda';

import { DetailImg } from 'common/components';

import type { InboxItemFragment } from 'graphql/__generated__';

import { INBOX_TYPE, INBOX_NOTIFICATION_KIND } from 'common/constants/inbox';
import { OWNER_TYPE } from 'common/constants/owner';
import DEFAULT_LOGO from 'images/workspace-default-logo.svg';
import APPBUILDER_LOGO from 'images/8base-appbuilder-logo.svg';

const StyledAvatar = styled(Avatar)`
  height: 20px;
  min-height: 20px;
  width: 20px;
  min-width: 20px;
`;

const StyledImg = styled.img`
  height: 34px;
  min-height: 34px;
  width: 34px;
  min-width: 34px;
`;

const formatDate = (date) => {
  if (date) {
    return DateTime.fromISO(date).setLocale('en').toLocaleString(DateTime.DATETIME_MED);
  }
  return '';
};

export const transformInviteInfo = (data: InboxItemFragment) => {
  const firstName = R.pathOr('', ['details', 'invitedBy', 'firstName'], data);
  const lastName = R.pathOr('', ['details', 'invitedBy', 'lastName'], data);

  const transformEnvInviteMessage = (firstName = '', lastName = '', workspaceName) =>
    i18n.t('inbox.item.message.invite.env', {
      defaultValue: '{{userName}} has invited you to join their workspace: {{workspaceName}}',
      userName: `${firstName} ${lastName}`,
      workspaceName,
    });

  const transformOgrInviteMessage = (firstName = '', lastName = '', organizationName) =>
    i18n.t('inbox.item.message.invite.org', {
      defaultValue: '{{userName}} has invited you to join their organization: {{organizationName}}',
      userName: `${firstName} ${lastName}`,
      organizationName,
    });

  const getTransformedMsg = (inboxInfo: InboxItemFragment) => {
    switch (inboxInfo.type) {
      case INBOX_TYPE.envInvite: {

        const workspaceName = R.pathOr('', ['details', 'workspace', 'name'], inboxInfo);
        return transformEnvInviteMessage(firstName, lastName, workspaceName);
      }

      case INBOX_TYPE.orgInvite: {
        const organizationName = R.pathOr('', ['details', 'organization', 'name'], inboxInfo);
        return transformOgrInviteMessage(firstName, lastName, organizationName);
      }

      default: {
        return '';
      }
    }
  };

  const getEntityImage = (inboxInfo: InboxItemFragment) => {
    switch (inboxInfo.type) {
      case INBOX_TYPE.envInvite: {
        const img = R.pathOr(DEFAULT_LOGO, ['details', 'workspace', 'avatar', 'downloadUrl'], inboxInfo);
        return img;
      }

      case INBOX_TYPE.orgInvite: {
        const img = R.pathOr(DEFAULT_LOGO, ['details', 'organization', 'avatar', 'downloadUrl'], inboxInfo);
        return img;
      }

      default: {
        return null;
      }
    }
  };

  const getEntityName = (inboxInfo: InboxItemFragment) => {
    switch (inboxInfo.type) {
      case INBOX_TYPE.envInvite: {
        const name = R.pathOr('', ['details', 'workspace', 'name'], inboxInfo);
        return name;
      }

      case INBOX_TYPE.orgInvite: {
        const name = R.pathOr('', ['details', 'organization', 'name'], inboxInfo);
        return name;
      }

      default: {
        return null;
      }
    }
  };

  const getEntityDetails = (inboxInfo: InboxItemFragment) => {
    switch (inboxInfo.type) {
      case INBOX_TYPE.envInvite: {
        const environmentName = R.pathOr('', ['details', 'environmentName'], inboxInfo);
        return environmentName;
      }

      default: {
        return null;
      }
    }
  };


  const userImg = R.path(['details', 'invitedBy', 'avatar', 'downloadUrl'], data);
  const infoImg = getEntityImage(data);
  const infoText = getEntityName(data);
  const infoDetails = getEntityDetails(data);

  return {
    message: getTransformedMsg(data),
    date: formatDate(data.createdAt),
    userImg,
    infoImg,
    infoText,
    infoDetails,
    firstName,
    lastName,
  };
};

export const transformNotificationInfo = (
  (data: InboxItemFragment) => {
    const kind = R.path(['details', 'kind'], data);
    const getDefaultValues = () => {
      switch (kind) {
        case INBOX_NOTIFICATION_KIND.appBuilderBeta:
        {
          return {
            header: '8base invites you to join App Builder beta',
            body: 'Work in a powerful, frontend development environment through an in-browser low-code IDE',
            image: { src: APPBUILDER_LOGO },
          };
        }

        default:
        {
          return { header: '', body: '', image: null };
        }

      }
    };

    const defaultValues = getDefaultValues();

    const header = R.pathOr(
      defaultValues.header,
      ['details', 'details', 'header'],
      data,
    );
    const body = R.pathOr(
      defaultValues.body,
      ['details', 'details', 'body'],
      data,
    );
    const image = R.pathOr(
      defaultValues.image,
      ['details', 'details', 'image'],
      data,
    );

    const date = formatDate(data.createdAt);

    return { header, body, image, date };
  }
);

// TODO:[refactor]: please review and replace me  (+newOwnerAvatar, newOwnerNameDataFunc)

type FullName = {
  firstName: string,
  lastName: string,
};

const getSplitedName = (name: string): FullName => {
  const splitedName = name.split(' ');
  return {
    firstName: splitedName[0],
    lastName: splitedName[1],
  };
};

const withQuotes = (str: string, condition: boolean): string => {
  return condition
    ? `"${str}"`
    : str;
};

type OwnerFuncByType = {
  [$Values<typeof OWNER_TYPE>]: any,
}

export const ownerType = {
  [OWNER_TYPE.account]: 'Personal Account',
  [OWNER_TYPE.organization]: 'Organization',
};

export const newOwnerNameDataFunc: OwnerFuncByType = {
  [OWNER_TYPE.account]: (name): FullName => ({
    ...getSplitedName(name),
  }),
  [OWNER_TYPE.organization]: (name) => ({
    name,
  }),
};

export const ownerAvatarSrcFunc: OwnerFuncByType = {
  [OWNER_TYPE.account]: ({ path, data }): string => R.path(path, data),
  [OWNER_TYPE.organization]: ({ path, data }): string => R.pathOr(DEFAULT_LOGO, path, data),
};

export const ownerAvatar: OwnerFuncByType = {
  [OWNER_TYPE.account]: ({ owner }): React$Element<any> => (
    <Avatar
      src={ owner.image.src }
      firstName={ owner.firstName }
      lastName={ owner.lastName }
      size="sm"
    />
  ),
  [OWNER_TYPE.organization]: ({ owner }): React$Element<any> => {
    if (owner.image.src === DEFAULT_LOGO) {
      return <StyledImg src={ owner.image.src } alt="Default 8base logo" />;
    }

    return (
      <Avatar
        src={ owner.image.src }
        size="sm"
      />
    );
  },
};

export const newOwnerAvatar: OwnerFuncByType = {
  [OWNER_TYPE.account]: ({ newOwner }): React$Element<any> => (
    <StyledAvatar
      src={ newOwner.image.src }
      size="xs"
      variant="rounded"
      firstName={ newOwner.firstName }
      lastName={ newOwner.lastName }
    />
  ),
  [OWNER_TYPE.organization]: ({ newOwner }): React$Element<any> => (
    <DetailImg src={ newOwner.image.src } />
  ),
};

export const newOwnerNameStringFunc: OwnerFuncByType = {
  [OWNER_TYPE.account]: (newOwner): string => `${newOwner.firstName} ${newOwner.lastName}`,
  [OWNER_TYPE.organization]: (newOwner): string => newOwner.name,
};

export const transformTransferInfo = (data: *) => {
  const getHeaderMessage = ({ workspaceName, fromName, fromType, toName, toType }: *): string => {
    const formattedFromName = withQuotes(fromName, fromType === OWNER_TYPE.organization);
    const formattedToName = withQuotes(toName, toType === OWNER_TYPE.organization);

    const addition = fromType === OWNER_TYPE.organization
      ? ` ${ownerType[fromType]}`
      : '';

    const additionTo = toType === OWNER_TYPE.organization
      ? ` ${ownerType[toType]}`
      : '';

    return `${formattedFromName}${addition} wants to transfer “${workspaceName}” workspace to ${formattedToName}${additionTo}`;
  };

  const workspaceName = R.pathOr('', ['details', 'workspace', 'name'], data);

  const fromName = R.pathOr('', ['details', 'from', 'name'], data);
  const fromType = R.pathOr('', ['details', 'from', 'type'], data);

  const toName = R.pathOr('', ['details', 'to', 'name'], data);
  const toType = R.pathOr('', ['details', 'to', 'type'], data);

  const date = formatDate(data.createdAt);

  const getNewOwnerNameData = newOwnerNameDataFunc[toType];

  const getOwnerAvatarSrc = ownerAvatarSrcFunc[fromType];
  const getNewOwnerAvatarSrc = ownerAvatarSrcFunc[toType];

  return {
    header: getHeaderMessage({ workspaceName, fromName, fromType, toName, toType }),
    workspace: {
      name: workspaceName,
      image: { src: R.pathOr(DEFAULT_LOGO, ['details', 'workspace', 'avatar', 'downloadUrl'], data) },
    },
    owner: {
      ...getSplitedName(fromName),
      type: fromType,
      image: { src: getOwnerAvatarSrc({ path: ['details', 'from', 'avatar', 'downloadUrl'], data }) },
    },
    newOwner: {
      ...getNewOwnerNameData(toName),
      type: toType,
      image: { src: getNewOwnerAvatarSrc({ path: ['details', 'to', 'avatar', 'downloadUrl'], data }) },
    },
    date,
  };
};
