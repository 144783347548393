export const LANDING_URL = 'https://8base.com';
export const DOCS_URL = 'https://docs.8base.com';
export const DOCS_URL_CUSTOM_FUNCTIONS = 'https://docs.8base.com/docs/8base-console/custom-functions';
export const DOCS_QUICK_START_URL = 'https://docs.8base.com/docs/getting-started/quick-start';
export const HELP_URL = 'https://help.8base.com/';
export const DISCOURSE_URL = 'https://community.8base.com/';
export const PRICING_URL = 'https://www.8base.com/pricing';
export const USER_AGREEMENT_URL = 'https://www.8base.com/user-agreement';
export const SUPPORT_MAILTO_URL = 'mailto:support@8base.com';
export const SUPPORT_URL = 'https://8base-dev.atlassian.net/servicedesk';
