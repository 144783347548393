// @flow

// $FlowFixMe
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog, Button, useModal, AsyncContent, Text } from '@8base/boost';
import { RecordUpdate } from '@8base-react/crud';
import {
  formatDataForMutation,
  tableFieldSelectors,
  tablesListSelectors,
  MUTATION_TYPE,
} from '@8base/utils';
import { useTablesList } from '@8base-react/table-schema-provider';
import { css } from '@emotion/core';
import { apolloTableFieldSelectors } from 'graphql/selectors';
import { i18n } from 'i18n';

import { Trans } from 'utils/translate';
import { ViewReachTextFieldDialogBody } from './ViewReachTextFieldDialogBody';
import { getFormattedValue, getInitialEditorState } from './utils';


const VIEW_REACH_TEXT_FIELD_DIALOG_ID = 'VIEW_REACH_TEXT_FIELD_DIALOG_ID';

const getInfo = (args) => {
  const recordId = args && args.recordId;
  const fieldSchema = args && args.fieldSchema;
  const saveCallback = args && args.saveCallback;
  const shouldSaveWithMutation = args && args.shouldSaveWithMutation;
  const initialValue = args && args.initialValue;
  const validators = args && args.validators;

  const tableId = tableFieldSelectors.getTableId(fieldSchema);
  const tableName = tableFieldSelectors.getTableName(fieldSchema);
  const fieldId = tableFieldSelectors.getFieldId(fieldSchema);
  const fieldName = tableFieldSelectors.getFieldName(fieldSchema);
  const fieldDisplayName = tableFieldSelectors.getFieldDisplayName(fieldSchema);
  const format = apolloTableFieldSelectors.getFieldFormat(fieldSchema);

  return {
    recordId,
    saveCallback,
    shouldSaveWithMutation,
    tableId,
    tableName,
    fieldId,
    fieldName,
    fieldDisplayName,
    format,
    initialValue,
    validators,
  };
};

export const ViewReachTextFieldDialog = () => {
  const { isOpen, closeModal, args } = useModal(VIEW_REACH_TEXT_FIELD_DIALOG_ID);
  const [value, setValue] = useState();
  const [oldValue, setOldValue] = useState('');
  const [validationError, setValidationError] = useState(null);
  const [isRawMode, setRawMode] = useState(false);
  const [rawValue, setRawValue] = useState();

  const { loading, tablesList } = useTablesList();

  useEffect(() => {
    if (isOpen) {
      setValidationError(null);
      setRawMode(false);
    }
  }, [isOpen]);

  const {
    recordId,
    saveCallback,
    shouldSaveWithMutation,
    tableId,
    tableName,
    fieldId,
    fieldName,
    fieldDisplayName,
    format,
    initialValue,
    validators,
  } = useMemo(() => getInfo(args), [args]);

  useEffect(() => {
    if (args.initialValue) {
      const content = initialValue;
      const editorState = getInitialEditorState(content, format);
      setValue(editorState);
      setOldValue(content);
    }
  }, [initialValue, fieldName, format, tableName, args.initialValue]);


  const onClose = useCallback(() => closeModal(VIEW_REACH_TEXT_FIELD_DIALOG_ID), [closeModal]);
  const onSave = useCallback((updateRow) => () => {
    const valueToSave = isRawMode ? rawValue : getFormattedValue(format, value);

    const invalidError = validators.map((validator) => validator(valueToSave)).filter(Boolean);

    if (invalidError && invalidError.length) {
      setValidationError(invalidError[0]);
      return;
    }
    setValidationError(null);

    // skip mutation without changes and when data is loading ( valueToSave === '' && oldValue === '')
    if (valueToSave === oldValue) {
      closeModal(VIEW_REACH_TEXT_FIELD_DIALOG_ID);
      return;
    }

    if (saveCallback) {
      saveCallback(valueToSave);
    }

    if (shouldSaveWithMutation) {
      const requestData = {
        [fieldName]: valueToSave,
      };
      const appName = tablesListSelectors.getTableApplicationName(tablesList, tableId);

      const formattedData = formatDataForMutation(MUTATION_TYPE.UPDATE, requestData, {
        tableName,
        appName,
        schema: tablesList,
      });

      updateRow({
        variables: { data: formattedData, filter: { id: recordId }},
      })
        .then(() => {
          closeModal(VIEW_REACH_TEXT_FIELD_DIALOG_ID);
        });
    } else {
      closeModal(VIEW_REACH_TEXT_FIELD_DIALOG_ID);
    }

  }, [
    isRawMode,
    rawValue,
    format,
    value,
    validators,
    oldValue,
    saveCallback,
    shouldSaveWithMutation,
    closeModal,
    fieldName,
    tablesList,
    tableId,
    tableName,
    recordId,
  ]);

  return (
    <Dialog id={ VIEW_REACH_TEXT_FIELD_DIALOG_ID } size="xl">
      <Dialog.Header
        title={ i18n.t('dialogs.viewReachTextField.title', { fieldDisplayName, defaultValue: 'Reach Text View - {{- fieldDisplayName }}' }) }
        onClose={ onClose }
      />
      <RecordUpdate
        tableId={ tableId }
        recordId={ recordId }
        includeColumns={ [fieldName] }
      >
        { (updateRow, { mutateResult }) => {
          return (
            <React.Fragment>
              <Dialog.Body css={{ overflow: 'hidden' }} padding="noSet" >
                <AsyncContent loading={ loading } stretch>
                  <ViewReachTextFieldDialogBody
                    tableId={ tableId }
                    tableName={ tableName }
                    fieldId={ fieldId }
                    recordId={ recordId }
                    fieldName={ fieldName }
                    format={ format }
                    value={ value }
                    setValue={ setValue }
                    setOldValue={ setOldValue }
                    rawValue={ rawValue }
                    setRawValue={ setRawValue }
                    isRawMode={ isRawMode }
                    setRawMode={ setRawMode }
                  />
                </AsyncContent>
              </Dialog.Body>
              <Dialog.Footer>
                { validationError && <Text color="DANGER" css={ css`margin: auto 8px;` } >{ validationError }</Text> }
                <Button onClick={ onClose } disabled={ mutateResult.loading } >
                  <Trans i18nKey="shared.close">Close</Trans>
                </Button>
                <Button onClick={ onSave(updateRow) } loading={ mutateResult.loading } >
                  <Trans i18nKey="shared.save">Save</Trans>
                </Button>
              </Dialog.Footer>
            </React.Fragment>
          );
        } }
      </RecordUpdate>
    </Dialog>
  );
};

ViewReachTextFieldDialog.ID = VIEW_REACH_TEXT_FIELD_DIALOG_ID;
