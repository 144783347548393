// @flow

import { createSelector } from 'reselect';
import * as R from 'ramda';

import type { Selector } from 'reselect';

type BillingMetricUsageQuotas = {
  value: number,
  displayName: string,
  name: string,
  description: string,
  showPriority: number,
  unit: string,
}

interface SystemBillingData {
  billingMetricUsageQuotasList?: {
    items: []
  },
  billingMetricUsagesList?: {
    items: []
  },
}

interface ApolloBillingData extends SystemBillingData {
  system?: SystemBillingData,
}

export const getData = (data: ApolloBillingData): ApolloBillingData => data;

export const getSystemField: Selector<
  ApolloBillingData,
  any,
  ApolloBillingData | SystemBillingData
> = createSelector(
  getData,
  (data: ApolloBillingData) => R.pathOr({ ...data }, ['system'], data),
);

export const getQuotas: Selector<
  ApolloBillingData,
  any,
  BillingMetricUsageQuotas
> = createSelector(
  getSystemField,
  (data: ApolloBillingData | SystemBillingData) => R.pipe(
    R.pathOr([], ['billingMetricUsageQuotasList', 'items']),
    R.map(({ limitMetric, value }) => ({ value, ...limitMetric })),
    R.sortBy(R.prop('showPriority')),
  )(data),
);

export const getMetrics: Selector<
  ApolloBillingData,
  any,
  Object
> = createSelector(
  getSystemField,
  (data: ApolloBillingData | SystemBillingData) => R.pipe(
    R.pathOr([], ['billingMetricUsagesList', 'items']),
    R.reduce((result, metric) => R.assoc(R.path(['limitMetric', 'name'], metric), R.prop('value', metric), result), {}),
  )(data),
);

export const getOverages: Selector<
ApolloBillingData,
any,
Object
> = createSelector(
  getSystemField,
  (data: ApolloBillingData | SystemBillingData) => R.pipe(
    R.pathOr([], ['billingMetricUsagesList', 'items']),
    R.reduce((result, metric) => R.assoc(R.path(['limitMetric', 'name'], metric), R.prop(['overage'], metric), result), {}),
  )(data),
);


export const getOveragesValues: Selector<
ApolloBillingData,
any,
Object
> = createSelector(
  getSystemField,
  (data: ApolloBillingData | SystemBillingData) => R.pipe(
    R.pathOr([], ['billingMetricUsagesList', 'items']),
    R.reduce((result, metric) => R.assoc(R.path(['limitMetric', 'name'], metric), R.path(['overage', 'value'], metric), result), {}),
  )(data),
);

export const getTotalOverages: Selector<
ApolloBillingData,
any,
Number
> = createSelector(
  getSystemField,
  (data: ApolloBillingData | SystemBillingData) => R.pipe(
    R.pathOr([], ['billingMetricUsagesList', 'items']),
    R.reduce((result, metric) => result + R.pathOr(0, ['overage', 'value'], metric), 0),
  )(data),
);
