// @flow
// $FlowFixMe
import React from 'react';

// $FlowFixMe
import { APP_URL } from 'common/routing';
// $FlowFixMe
import { Route, Switch } from 'react-router-dom';
import { OrganizationTeamTable, OrganizationTeamMemberProfile } from './components';

export const OrganizationTeam = () => {
  return (
    <Switch>
      <Route exact path={ APP_URL.organizationSettingsTeam } >
        <OrganizationTeamTable />
      </Route>
      <Route exact path={ APP_URL.organizationSettingsTeamUser }>
        <OrganizationTeamMemberProfile />
      </Route>
    </Switch>
  );
};
