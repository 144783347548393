/* eslint-disable react/jsx-key */

import React from 'react';
import { Column, Heading, Icon, Row } from '@8base/boost';
import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';

import { i18n } from 'i18n';

const Message = styled('p')({
  color: '#596389',
  fontSize: '1.7rem',
  lineHeight: '2.9rem',
});

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: initial;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
`;

const borderColorFadeIn = keyframes`
  0% {
    border-color: #0080FF; 
  }
  100% {
    border-color: #0080FF;
  }
`;

const widthFadeIn = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

const marginAnimate = keyframes`
  to {
    margin-left: 0;
  }
`;

const Circle = styled('div')`
  color: #fff;
  border: 2px solid #CFD7DD;
  border-radius: 100%;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translate(-50%);
  left: 50%;

  animation: ${borderColorFadeIn} 2s ease-in ${({ delay }) => delay}s normal forwards;

  & > * {
    position: absolute;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background-color: #0080FF;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: ${fadeIn} 1s ease-in ${({ delay }) => delay}s normal forwards;
    opacity: 0;
  }
`;

const Rectangle = styled('div')`
  width: 80px;
  height: 2px;
  background-color: #CFD7DD;
  position: absolute;
  transform: translate(-50%);
  left: 50%;

  & > * {
    width: 0;
    height: 2px;
    background-color: #0080FF;
    animation: ${widthFadeIn} 2s ease-in ${({ delay }) => delay}s normal forwards;
  }
`;

export const WorkspaceLoader = () => (
  <Column alignItems="center" css={{ textAlign: 'center' }}>
    <Row alignItems="center" css={ css`height: 80px;` }>
      <Circle size={ 58 } delay={ 8 } css={ css`visibility: hidden; position: absolute; left: 50%; transform: translate(-50%);` }>
        <Icon name="Check" />
      </Circle>
      <Row alignItems="center" css={ css`animation: ${fadeOut} 1s ease-out 8s normal forwards;` }>
        <Circle css={ css`margin-left: -192px; animation: ${marginAnimate} 500ms ease-out 8s normal forwards;` } size={ 32 } delay={ 2 }>
          <Icon name="Check" size="xs" />
        </Circle>
        <Rectangle css={ css`margin-left: -128px; animation: ${fadeOut} 250ms ease-out 8s normal forwards;` } delay={ 2 }>
          <div />
        </Rectangle>
        <Circle css={ css`margin-left: -64px; animation: ${marginAnimate} 500ms ease-out 8s normal forwards;` } size={ 32 } delay={ 4 }>
          <Icon name="Check" size="xs" />
        </Circle>
        <Rectangle css={ css`margin-left: 0; animation: ${fadeOut} 250ms ease-out 8s normal forwards;` } delay={ 4 }>
          <div />
        </Rectangle>
        <Circle css={ css`margin-left: 64px; animation: ${marginAnimate} 500ms ease-out 8s normal forwards;` } size={ 32 } delay={ 6 }>
          <Icon name="Check" size="xs" />
        </Circle>
        <Rectangle css={ css`margin-left: 128px; animation: ${fadeOut} 250ms ease-out 8s normal forwards;` } delay={ 6 }>
          <div />
        </Rectangle>
        <Circle css={ css`margin-left: 192px; animation: ${marginAnimate} 500ms ease-out 8s normal forwards;` } size={ 32 } delay={ 8 }>
          <Icon name="Check" size="xs" />
        </Circle>
      </Row>
    </Row>
    <Column alignItems="center" gap="none" css={ css`& > *:not(:first-of-type) { position: absolute; }` }>
      <Heading type="h1" css={ css`font-size: 3rem; animation: ${fadeOut} 200ms ease-out 1.8s normal forwards;` }>
        { i18n.t('workspaceLoader.title1', { defaultValue: 'Powering up your compute and storage environments' }) }
      </Heading>
      <Heading type="h1" css={ css`font-size: 3rem; visibility: hidden; animation: ${fadeIn} 2s ease-in 2s;` }>
        { i18n.t('workspaceLoader.title2', { defaultValue: 'Initializing your database instance' }) }
      </Heading>
      <Heading type="h1" css={ css`font-size: 3rem; visibility: hidden; animation: ${fadeIn} 2s ease-in 4s;` }>
        { i18n.t('workspaceLoader.title3', { defaultValue: 'Migrating your system tables' }) }
      </Heading>
      <Heading type="h1" css={ css`font-size: 3rem; visibility: hidden; animation: ${fadeIn} 2s ease-in 6s;` }>
        { i18n.t('workspaceLoader.title4', { defaultValue: 'Setting up your GraphQL API' }) }
      </Heading>
      <Heading type="h1" css={ css`font-size: 3rem; visibility: hidden; animation: ${fadeIn} 1s ease-in 8s normal forwards;` }>
        { i18n.t('workspaceLoader.done', { defaultValue: 'All done!' }) }
      </Heading>
    </Column>
    <Column alignItems="center" css={ css`& > *:not(:first-of-type) { position: absolute; }` }>
      <Message css={ css`animation: ${fadeOut} 1s ease-out 8s normal forwards;` }>
        { i18n.t('workspaceLoader.subtitle1', { defaultValue: 'We are setting up your workspace, this should only take a moment.' }) }
      </Message>
      <Message css={ css`visibility: hidden; animation: ${fadeIn} 1s ease-in 8s normal forwards;` }>
        { i18n.t('workspaceLoader.subtitle2', { defaultValue: 'One sec while we load your workspace.' }) }
      </Message>
    </Column>
  </Column>
);
