// @flow
// $FlowFixMe
import React, { useCallback } from 'react';
import * as R from 'ramda';
import { Avatar, Card, Column, Dropdown, Icon, Menu, Row, Tag, Text, useModal } from '@8base/boost';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { useAuth } from '@8base-react/auth';

import { COLOR_BY_PLAN_NAME } from 'graphql/constants/billing';
import type { WorkspaceItem } from 'graphql/__generated__';
import { Trans } from 'utils/translate';
import { BillingChangePlanDialog } from 'dialogs/BillingChangePlanDialog';
import { WorkspaceUpdateDialog } from 'dialogs/WorkspaceUpdateDialog';
import { WorkspaceDeleteDialog } from 'dialogs/WorkspaceDeleteDialog';
import { BillingCancelDowngradeDialog } from 'dialogs/BillingCancelDowngradeDialog';
import { ShareWorkspaceDialog } from 'dialogs/ShareWorkspaceDialog';
import { TransferWorkspaceDialog } from 'dialogs/TransferWorkspaceDialog';
import { WorkspaceLeaveDialog } from 'dialogs/WorkspaceLeaveDialog';
import { useBuildUrl } from 'common/routing';
import { WORKSPACE_KIND } from 'common/constants/workspaceKind';
import { checkIs8baseUser } from 'utils/help';
import { getWorkspaceUrl } from '../utils';

import workspaceDefaultLogo from 'images/workspace-default-logo.svg';

import type { OpenWorkspaceHandler } from 'utils/hooks/useOnOpenWorkspace';

type DeveloperHomeWorkspaceItemProps = {
  workspace: WorkspaceItem;
  onOpenWorkspaceClick: OpenWorkspaceHandler;
}

const WorkspaceImage = styled.img`
  flex-shrink: 0;
  object-fit: cover;
  width: 56px;
  margin-right: 20px;
  height: 56px;
  border-radius: ${props => props.theme.SIZES.MAIN_BORDER_RADIUS};
  border: 1px solid ${props => props.theme.COLORS.SECONDARY_BORDER_COLOR};
`;

const MoreWrapper = styled.div`
  position: absolute;
  padding: 10px;
  top: -13px;
  right: -15px;
`;

const MoreIcon = styled(Icon)`

  box-sizing: content-box;
  transform: rotate(90deg);
  transition: color .3s ease;

  &:hover {
    color: ${({ theme }) => theme.COLORS.BLUE_20}
  }
`;

const StyledCard = styled(Card)`
  height: 100%;
  cursor: pointer;
  transition: all .3s ease-in-out;
  padding: 20px;
  &:hover {
    box-shadow: 0 2px 40px 0 #323C471A;
  }
`;

type ColoredTextContentProps = {
  children: React$Node,
}

const withColoredTextContent = (Component: React$ComponentType<any>) => (props: ColoredTextContentProps) => {
  const { children } = props;

  return (
    <Component { ...props }>
      <Text color="BLACK" >
        { children }
      </Text>
    </Component>
  );
};

const BlackMenuItem = withColoredTextContent(Menu.Item);

const ContextMenu = ({ workspace, onOpenWorkspaceClick }) => {
  const { authState } = useAuth();
  const { openModal } = useModal();

  const plan = R.propOr({}, 'plan', workspace);
  const nextPlan = R.prop('nextPlan', workspace);

  const copyWorkspaceIdToClipboard = useCallback(() => {
    const el = document.createElement('textarea');

    el.value = workspace.id;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';

    if (!!document.body) {
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      // $FlowFixMe
      document.body.removeChild(el);

      toast.success('Workspace ID successfully copied to the clipboard');
    }
  }, [workspace.id]);

  return (
    <Dropdown defaultOpen={ false }>
      <Dropdown.Head stopClickPropagation >
        <MoreWrapper { ...E2E.$props(`developerHome.workspaces.item.menu[${workspace.name}]`) }>
          <MoreIcon name="More" color="GRAY_30" />
        </MoreWrapper>
      </Dropdown.Head>
      <Dropdown.Body withPortal pin="left">
        { ({ closeDropdown }) => (
          <Menu>
            <BlackMenuItem onClick={ (event) => {
              onOpenWorkspaceClick({ workspaceId: workspace.id });

              event.stopPropagation();
            } }
            >
                            Open
            </BlackMenuItem>
            <If condition={ workspace.kind === WORKSPACE_KIND.frontend && checkIs8baseUser(authState) } >
              <BlackMenuItem onClick={ (event) => {
                onOpenWorkspaceClick({ workspaceId: workspace.id, openAsBackend: true });

                event.stopPropagation();
              } }
              >
                              Open as backend workspace
              </BlackMenuItem>
            </If>

            <If condition={ workspace.kind === WORKSPACE_KIND.general || checkIs8baseUser(authState) }>
              <BlackMenuItem
                { ...E2E.$props('developerHome.workspaces.item.menu.edit') }
                onClick={ (event) => {
                  event.stopPropagation();

                  openModal(WorkspaceUpdateDialog.ID, {
                    workspaceId: workspace.id,
                    workspaceName: workspace.name,
                    workspaceDescription: workspace.description,
                    apiHost: workspace.apiHost,
                    webSocketUri: workspace.webSocket,
                    workspaceImage: workspace.image,
                    isOwner: workspace.isOwner,
                    region: workspace.region,
                  });

                  closeDropdown();
                } }
              >
                            Workspace Info
              </BlackMenuItem>
            </If>
            <If
              condition={ workspace.organization
                ? false
                : workspace.kind === WORKSPACE_KIND.general || checkIs8baseUser(authState) }
            >
              <Choose>
                <When condition={ plan && nextPlan }>
                  <BlackMenuItem onClick={ (event) => {
                    event.stopPropagation();

                    openModal(BillingCancelDowngradeDialog.ID, {
                      workspaceId: workspace.id,
                    });

                    closeDropdown();
                  } }
                  >
                                  Cancel Downgrade
                  </BlackMenuItem>
                </When>
                <Otherwise>

                  <BlackMenuItem onClick={ (event) => {
                    event.stopPropagation();

                    openModal(BillingChangePlanDialog.ID, {
                      workspaceId: workspace.id,
                    });

                    closeDropdown();
                  } }
                  >
                                  Change Plan
                  </BlackMenuItem>
                </Otherwise>
              </Choose>
            </If>

            <If condition={ workspace.kind === WORKSPACE_KIND.general || checkIs8baseUser(authState) }>
              <BlackMenuItem onClick={ (event) => {
                event.stopPropagation();

                copyWorkspaceIdToClipboard();
                closeDropdown();
              } }>
                            Copy Workspace ID to Clipboard
              </BlackMenuItem>
            </If>

            <If condition={ !!workspace.organization }>
              <BlackMenuItem onClick={ (event) => {
                event.stopPropagation();

                openModal(ShareWorkspaceDialog.ID, {
                  workspaceId: workspace.id,
                  workspaceName: workspace.name,
                  apiHost: workspace.apiHost,
                  organizationId: workspace.organization && workspace.organization.id,
                });

                closeDropdown();
              } }
              >
                <Trans i18nKey="shared.invite">
                                Invite
                </Trans>
              </BlackMenuItem>
            </If>

            <BlackMenuItem onClick={ (event) => {
              onOpenWorkspaceClick({ workspaceId: workspace.id, urlKey: 'settings' });

              event.stopPropagation();
            } }
            >
                            Settings
            </BlackMenuItem>

            <If condition={ workspace.isOwner && (workspace.kind === WORKSPACE_KIND.general || checkIs8baseUser(authState)) }>
              <BlackMenuItem onClick={ (event) => {
                event.stopPropagation();

                openModal(TransferWorkspaceDialog.ID, {
                  workspaceId: workspace.id,
                  workspaceName: workspace.name,
                  apiHost: workspace.apiHost,
                });

                closeDropdown();
              } }
              >
                              Transfer ownership
              </BlackMenuItem>
            </If>

            <If condition={ workspace.isOwner }>
              <BlackMenuItem onClick={ (event) => {
                event.stopPropagation();

                openModal(WorkspaceDeleteDialog.ID, {
                  workspaceId: workspace.id,
                  apiHost: workspace.apiHost,
                  workspaceName: workspace.name,
                });
                closeDropdown();
              } }>
                <Trans i18nKey="shared.delete">Delete</Trans>
              </BlackMenuItem>
            </If>
            <If condition={ !workspace.isOwner }>
              <BlackMenuItem onClick={ (event) => {
                event.stopPropagation();

                openModal(WorkspaceLeaveDialog.ID, {
                  workspaceId: workspace.id,
                  workspaceName: workspace.name,
                });
                closeDropdown();
              } }>
                <Trans i18nKey="shared.leave">Leave</Trans>
              </BlackMenuItem>
            </If>

          </Menu>
        ) }
      </Dropdown.Body>
    </Dropdown>
  );
};

export const DeveloperHomeWorkspaceItem = ({
  workspace,
  onOpenWorkspaceClick,
}: DeveloperHomeWorkspaceItemProps) => {
  const buildUrl = useBuildUrl();

  const plan = R.propOr({}, 'plan', workspace);

  const ownerFirstName = R.pathOr('', ['owner', 'firstName'], workspace);
  const ownerLastName = R.pathOr('', ['owner', 'lastName'], workspace);
  const ownerFullName = `${ownerFirstName} ${ownerLastName}`.trim();

  return (
    <a
      css={ css`height: 100%;` }
      href={ getWorkspaceUrl({ workspace, buildUrl }) }
      onClick={ (event) => {
        if (!event.metaKey && !event.ctrlKey) {
          event.preventDefault();
          onOpenWorkspaceClick({ workspaceId: workspace.id });
        }
      } }
    >
      <StyledCard
        paddingOuter="md"
        { ...E2E.$props(`developerHome.workspaces.item[${workspace.name}]`) }
      >
        <Column justifyContent="between" stretch>
          <Row stretch gap="notSet">
            <WorkspaceImage
              src={ (workspace.image && workspace.image.downloadUrl) || workspaceDefaultLogo }
            />
            <Column gap="none" grow={ 1 } stretch css={ css`overflow: hidden;` }>
              <Row justifyContent="between" css={ css`width: 100%;` }>
                <Choose>
                  <When condition={ !!workspace.plan }>
                    <Text
                      kind="small-2"
                      weight="semibold"
                      color={ COLOR_BY_PLAN_NAME[plan.name] }
                    >
                      { plan.displayName }
                    </Text>
                  </When>
                  <When condition={ !!workspace.owner }>
                    <Row alignItems="center" css={ css`overflow: hidden;` }>
                      <Avatar
                        size="xs"
                        firstName={ workspace.owner && workspace.owner.firstName }
                        lastName={ workspace.owner && workspace.owner.lastName }
                        src={ workspace.owner && workspace.owner.avatar && workspace.owner.avatar.downloadUrl }
                        css={ css`width: 12px; height: 12px; font-size: 6px; flex: 1 0 12px;` }
                      />
                      <Text kind="small-1" ellipsis title={ ownerFullName }>{ ownerFullName }</Text>
                    </Row>
                  </When>
                  <Otherwise>
                    <div />
                  </Otherwise>
                </Choose>
                <Row growChildren css={ css`position: relative; padding-right: 20px;` }>
                  <Tag condensed color="GRAY_60" css={ css`border-radius: 2px; border:none; padding: 4px 8px;` }>
                    <Row alignItems="center">
                      <Icon name="Man" css={ css`width: 8px; height: 8px;` } />
                      <Text kind="small-2">{ workspace.teamMemberCount }</Text>
                    </Row>
                  </Tag>
                  <ContextMenu workspace={ workspace } onOpenWorkspaceClick={ onOpenWorkspaceClick } />
                </Row>
              </Row>
              <Text
                ellipsis
                kind="body"
                color="DARK_BLACK"
                css={ css`padding-right: 12px; line-height: 1.2; margin: 4px 0 !important; max-width: 100%;` }
                title={ workspace.name }
              >
                { workspace.name }
              </Text>
              <Text kind="small-1">Created at { DateTime.fromISO(workspace.createdAt).toFormat('LLL d, yyyy') }</Text>
            </Column>
          </Row>
        </Column>
      </StyledCard>
    </a>
  );
};
