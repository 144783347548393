// @flow

import { useWorkspace } from 'providers/WorkspaceProvider';
import { buildUrlWithWorkspace } from './utils';

export const useBuildUrl = () => {
  const workspace = useWorkspace();

  return buildUrlWithWorkspace(workspace.id);
};
