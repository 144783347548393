import gql from 'graphql-tag';

import { TeamMemberFragment } from 'graphql/fragments';

export const TEAM_MEMBER_UPDATE_MUTATION = gql`
  mutation TeamMemberUpdate($data: SystemEnvironmentMemberUpdateData!, $filter: SystemEnvironmentMembersFilter) {
    system {
      environmentMemberUpdate(data: $data, filter: $filter) {
        ...TeamMemberFragment
      }
    }
  }

  ${TeamMemberFragment}
`;

export const TEAM_MEMBER_DELETE_MUTATION = gql`
  mutation TeamMemberDelete($filter: SystemEnvironmentMembersFilter!) {
    system {
      environmentMemberDelete(filter: $filter) {
        success
      }
    }
  }
`;
