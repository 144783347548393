// @flow
import React from 'react';
import { ItemTypes } from 'config/dnd';
import { DragSource, DropTarget } from 'react-dnd';
import { compose, setDisplayName } from 'recompose';
import { Checkbox } from '@8base/boost';

import { pipeClassNames, concatStylebyCond } from 'utils/styles';
import { Row } from 'common';

import './DraggableSelectItem.scss';

/**
 * interface of the component api
 *
 * @prop {*} id item id
 * @prop {*} label item label
 * @prop {*} checked regualte item checked
 * @prop {*} index element position in the list
 * @prop {*} onChangeChecked callback to change item checked
 * @prop {*} onChangePosition callback to change position by the dnd hover
 * @prop {*} onSuccessDrop callback to set position after drop element
 * @prop {*} onCancelDrop callback to cancel position after drop element(if the user threw an element in the same place from which he took)
 */
export type DraggableSelectItemProps = {|
  id: string,
  label: string,
  checked: boolean,
  index: number,

  onItemClick: (event?: Event) => void,
  onDragTargetHover?: (event?: Event) => void,
  onChangeChecked: (id: string, checked: boolean) => void,
  onChangePosition: (dragId: string, hoverId: string) => void,
  onSuccessDrop: () => void,
  onCancelDrop: () => void,
  onBeginDrag: () => void,
  onEndDrag: () => void,
|}

/**
 * interface of the enhanced stateless componen
 *
 * @prop {*} connectDragSource react-dnd function to connect drag source
 * @prop {*} connectDropTarget react-dnd function to connect drop target
 * @prop {*} isDragging displays the drag state
 */
type DraggableSelectItemBaseProps = {|
  ...DraggableSelectItemProps,
  connectDragSource: Function,
  connectDragPreview: Function,
  connectDropTarget: Function,
  isDragging: boolean,
|}

/**  */
export const DraggableSelectItemBase = ({
  id,
  label,
  checked,
  onChangeChecked,
  onItemClick,
  onDragTargetHover,
  isDragging,
  connectDragSource,
  connectDragPreview,
  connectDropTarget,
}: DraggableSelectItemBaseProps): React$Element<*> => {
  const dropTargetStyleName = pipeClassNames(
    'draggable-item',
    concatStylebyCond('draggable-item--dragging', isDragging),
  );

  const dragSourceStyleName = pipeClassNames(
    'draggable-source',
    concatStylebyCond('draggable-source--dragging', isDragging),
  );

  return connectDropTarget(connectDragPreview(
    <div styleName={ dropTargetStyleName } style={{ opacity: isDragging ? 0.5 : 1 }} onClick={ onItemClick }>
      <Row justifyContent="between" alignItems="center">
        <Checkbox
          noMargin
          name={ `draglist-checkbox-${id}` }
          checked={ checked }
          label={ label }
          onChange={ checked => onChangeChecked(id, checked) }
          css={{ flex: 1 }}
        />
        { connectDragSource(<div styleName={ dragSourceStyleName }><span className="icon icon-dnd-handle" /></div>) }
      </Row>
    </div>,
  ));
};

/**
 * interface of the react-dnd draggable props
 *
 * @prop {*} index hovered item index
 * @prop {*} originalIndex original item index before drag
 */
type DraggableSelectItemDraggableProps = {
  ...DraggableSelectItemProps,
  originalIndex: number,
}

/** drag source behavior */
export const dragItemSource = {
  beginDrag(props: DraggableSelectItemProps) {
    props.onBeginDrag();

    return {
      id: props.id,
      originalIndex: props.index,
    };
  },

  endDrag(props: DraggableSelectItemDraggableProps, monitor: *) {
    const { originalIndex, index } = monitor.getItem();
    const needCancelDnd = originalIndex === index;

    if (needCancelDnd) {
      props.onCancelDrop();
    }
    else {
      props.onSuccessDrop();
    }

    props.onEndDrag();
  },
};

/** drag target behavior */
export const dropItemTarget = {
  hover(props: DraggableSelectItemDraggableProps, monitor: *) {
    const dragId: string = monitor.getItem().id;
    const hoverIndex = props.index;
    const hoverId = props.id;

    if (dragId === hoverId) {
      return;
    }

    props.onChangePosition(dragId, hoverId);
    monitor.getItem().index = hoverIndex;
  },
};


const collectSource = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
});

const collectTarget = (connect) => ({
  connectDropTarget: connect.dropTarget(),
});

const enhancer: any = compose(
  setDisplayName('DraggableSelectItem'),
  DragSource(ItemTypes.SELECT_DRAG_LIST_ITEM, dragItemSource, collectSource),
  DropTarget(ItemTypes.SELECT_DRAG_LIST_ITEM, dropItemTarget, collectTarget),
);

/** component that display draggable list item */
export const DraggableSelectItem: React$ComponentType<DraggableSelectItemProps> = enhancer(DraggableSelectItemBase);
