// @flow
// $FlowFixMe
import React, { useCallback } from 'react';
// $FlowFixMe
import { matchPath, useLocation, useHistory } from 'react-router-dom';
// $FlowFixMe
import { AsyncContent, useModal, Row, Column, Text } from '@8base/boost';
import { useAuth } from '@8base-react/auth';
import * as R from 'ramda';
import { css } from '@emotion/core';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from 'react-apollo';

import { Trans } from 'utils/translate';
import { checkIs8baseUser } from 'utils/help';
import { useWorkspacesList } from 'graphql/hooks';
import { apolloWorkspacesListSelectors } from 'graphql/selectors';
import { WorkspaceCreateDialog } from 'dialogs/WorkspaceCreateDialog';
import { WORKSPACE_KIND } from 'common/constants/workspaceKind';
import { APP_URL } from 'common/routing';
import { ENDPOINT_URI, LEARNING_CENTER } from 'common/constants/apolloOperationContextOptions';
import { PlusButton } from 'common/components';
import { GET_PREFINERY_USER_QUERY } from 'graphql/queries';
import { CREATE_PREFINERY_USER_MUTATION } from 'graphql/mutations';
import { getLearningCenterUrl } from 'utils/processEnv';
import { useReferral } from 'providers/ReferralProvider';

import {
  DeveloperHomeContentPlate,
  DeveloperHomeWorkspacesTabs,
  DeveloperHomeWorkspaces,
} from '../../../components';
import { DeveloperHomeWorkspacesBeta } from '../components/DeveloperHomeWorkspacesBeta';

// Type Definitions
import type { OpenWorkspaceHandler } from 'utils/hooks/useOnOpenWorkspace';

const hasNotWorkspaceInOrganization = (workspace) => {
  return !Boolean(workspace.organization);
};

const hasWorkspaceInOrganization = (workspace) => {
  return Boolean(workspace.organization);
};

const groupByOrganization = (workspaceList) => R.groupBy(workspace => workspace.organization.name)(workspaceList);

type DeveloperHomeWorkspacesContainerProps = {
  onOpenWorkspaceClick: OpenWorkspaceHandler
}

export const DeveloperHomeWorkspacesContainer = ({ onOpenWorkspaceClick }: DeveloperHomeWorkspacesContainerProps) => {
  const { authState } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const { appbuilderReferral } = useReferral();

  const { data, loading: prefineryUserLoading } = useQuery(GET_PREFINERY_USER_QUERY, {
    skip: !authState || !authState.email || checkIs8baseUser(authState),
    context: {
      [ENDPOINT_URI]: getLearningCenterUrl(),
      [LEARNING_CENTER]: true,
      noBatch: true,
    },
    variables: {
      email: authState.email,
    },
  });


  const [addUser, { loading: addUserLoading }] = useMutation(CREATE_PREFINERY_USER_MUTATION, {
    skip: !authState || !authState.email || checkIs8baseUser(authState),
    context: {
      [ENDPOINT_URI]: getLearningCenterUrl(),
      [LEARNING_CENTER]: true,
      noBatch: true,
    },
    update: (cache, { data: resultData }) => {
      cache.writeQuery({
        query: GET_PREFINERY_USER_QUERY,
        variables: {
          email: authState.email,
        },
        data: {
          getPrefineryUser: resultData.createPrefineryUser,
        }});
    },
    variables: {
      email: authState.email,
      referrerCode: appbuilderReferral,
    },
  });

  const { workspacesList, workspacesFrontendList, loading } = useWorkspacesList();

  const { openModal } = useModal();

  const onCreateWorkspaceClick = useCallback((kind?: string) => () => {
    openModal(WorkspaceCreateDialog.ID, { kind });
  }, [openModal]);

  // backend workspaces data
  const myBackendWorkspacesList = apolloWorkspacesListSelectors.getMyWorkspacesList(workspacesList, { withAlphabetSorting: true })
    .filter(hasNotWorkspaceInOrganization);

  const sharedBackendWorkspacesList = apolloWorkspacesListSelectors.getSharedWorkspacesList(workspacesList, { withAlphabetSorting: true })
    .filter(hasNotWorkspaceInOrganization);

  const organizationsBackendWorkspaces = groupByOrganization(
    apolloWorkspacesListSelectors
      .getWorkspacesList(workspacesList, { withAlphabetSorting: true })
      .filter(hasWorkspaceInOrganization),
  );

  // fronted workspaces data
  const myFrontendWorkspacesList = apolloWorkspacesListSelectors.getMyWorkspacesList(workspacesFrontendList, { withAlphabetSorting: true })
    .filter(hasNotWorkspaceInOrganization);

  const sharedFrontendWorkspacesList = apolloWorkspacesListSelectors.getSharedWorkspacesList(workspacesFrontendList, { withAlphabetSorting: true })
    .filter(hasNotWorkspaceInOrganization);

  const organizationsFrontendWorkspaces = groupByOrganization(
    apolloWorkspacesListSelectors
      .getWorkspacesList(workspacesFrontendList, { withAlphabetSorting: true })
      .filter(hasWorkspaceInOrganization),
  );

  const countBackendWorkspaces = apolloWorkspacesListSelectors.getWorkspacesCount(workspacesList);
  const countFrontendWorkspaces = apolloWorkspacesListSelectors.getWorkspacesCount(workspacesFrontendList);
  const activeTab = matchPath(location.pathname, { path: APP_URL.developerHomeWorkspacesFrontend })
    ? WORKSPACE_KIND.frontend
    : WORKSPACE_KIND.general;

  const onToggleTab = (kind) => () => {
    if (kind === WORKSPACE_KIND.frontend) {
      history.push(APP_URL.developerHomeWorkspacesFrontend);
    } else {
      history.push(APP_URL.developerHomeWorkspaces);
    }
  };

  const onSingUpClick = () => {
    addUser()
      .then(() => {
        toast.success('You\'ve been signed up for the App Builder beta! Further instructions are soon to follow.');
      });
  };

  const hasFrontendAccess = R.pathEq(['getPrefineryUser', 'status'], 'active', data);

  return (
    <AsyncContent stretch loading={ loading || prefineryUserLoading }>
      <DeveloperHomeContentPlate { ...E2E.$props('developerHome.workspaces.root') }>
        <Column gap="lg" css={ css`width: 100%; min-width: 350px;` } alignItems="stretch" >
          <Row justifyContent="between" alignItems="center" >
            <Row alignItems="center">
              <Text kind="h1">My Workspaces</Text>
            </Row>
          </Row>

          <DeveloperHomeWorkspacesTabs
            countBackendWorkspaces={ countBackendWorkspaces }
            countFrontendWorkspaces={ checkIs8baseUser(authState) || hasFrontendAccess ? countFrontendWorkspaces : 'Coming  soon' }
            backendWorkspaces={ (
              <DeveloperHomeWorkspaces
                myWorkspacesList={ myBackendWorkspacesList }
                sharedWorkspacesList={ sharedBackendWorkspacesList }
                organizationsWorkspaces={ organizationsBackendWorkspaces }
                onOpenWorkspaceClick={ onOpenWorkspaceClick }
                createButton={ (
                  <PlusButton
                    onClick={ onCreateWorkspaceClick(WORKSPACE_KIND.general) }
                    { ...E2E.$props('developerHome.workspaces.createNewWorkspace') }
                  >
                    <Trans i18nKey="developerHome.workspaces.createNewBackendWorkspace">
                      Create Backend Workspace
                    </Trans>
                  </PlusButton>
                ) }
              />
            ) }
            frontendWorkspaces={ (
              <Choose>
                <When condition={ checkIs8baseUser(authState) || hasFrontendAccess }>
                  <DeveloperHomeWorkspaces
                    myWorkspacesList={ myFrontendWorkspacesList }
                    sharedWorkspacesList={ sharedFrontendWorkspacesList }
                    organizationsWorkspaces={ organizationsFrontendWorkspaces }
                    onOpenWorkspaceClick={ onOpenWorkspaceClick }
                    createButton={ (
                      <PlusButton
                        onClick={ onCreateWorkspaceClick(WORKSPACE_KIND.frontend) }
                        { ...E2E.$props('developerHome.workspaces.createNewWorkspace') }

                      >
                        <Trans i18nKey="developerHome.workspaces.createNewFrontedWorkspace">
                          Create Frontend Workspace
                        </Trans>
                      </PlusButton>
                    ) }
                  />
                </When>
                <Otherwise>
                  <DeveloperHomeWorkspacesBeta
                    onSingUpClick={ onSingUpClick }
                    addUserLoading={ addUserLoading }
                    user={ R.path(['getPrefineryUser'], data) }
                  />
                </Otherwise>
              </Choose>

            ) }
            activeTab={ activeTab }
            onToggleTab={ onToggleTab }
            showFrontendWorkspaces
          />
        </Column>
      </DeveloperHomeContentPlate>
    </AsyncContent>
  );
};
