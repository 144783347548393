import React from 'react';
import { Icon } from '@8base/boost';
import styled from '@emotion/styled';

const FieldAdvancedSettingsTag = styled.div`
  display: flex;
  flex-direction: column;
`;

const FieldAdvancedSettingsTitleTag = styled.div`
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #7D828C;
  display: flex;
  align-items: center;
`;

const FieldAdvancedSettingsContentTag = styled.div`
  display: ${({ opened }) => opened ? 'flex' : 'none'};
  flex-direction: column;
  padding-top: 24px;
`;

export const FieldAdvancedSettings = ({ children, isOpen }) => {
  const [opened, setOpened] = React.useState(isOpen);

  const onToggle = React.useCallback(() => {
    setOpened(!opened);
  }, [opened, setOpened]);

  return (
    <FieldAdvancedSettingsTag>
      <FieldAdvancedSettingsTitleTag onClick={ onToggle }>
      Advanced Settings <Icon css={{ marginLeft: 8 }} name={ opened ? 'ChevronRight' : 'ChevronDown' } size="xs" />
      </FieldAdvancedSettingsTitleTag>
      <FieldAdvancedSettingsContentTag opened={ opened }>
        { children }
      </FieldAdvancedSettingsContentTag>
    </FieldAdvancedSettingsTag>
  );
};
