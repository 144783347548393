// @flow

import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import { WorkspaceContext } from './WorkspaceContext';

const withWorkspaceBase =
  (WrappedComponent: React$ComponentType<any>) => props => {

    return (
      <WorkspaceContext.Consumer>
        { ({ workspace }) => (
          <WrappedComponent { ...props } workspace={ workspace } />
        ) }
      </WorkspaceContext.Consumer>
    );
  };

export const withWorkspace = compose(
  withRouter,
  withWorkspaceBase,
);
