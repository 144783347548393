// @flow
// $FlowFixMe
import React, { useLayoutEffect } from 'react';
// $FlowFixMe
import { useHistory, useLocation } from 'react-router-dom';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import * as R from 'ramda';

import { Icon, Text, Button } from '@8base/boost';

import { APP_URL } from 'common/routing';

import { TransferPageWrapper } from '../../../components';
import { useOnOpenWorkspace } from 'utils/hooks';

const CircledBackground = styled.div`
  width: 56px;
  height: 56px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #0080FF;
  border-radius: 50%;
`;

const titleCss = css`
  font-size: 18px;
  line-height: 28px;
`;

export const TransferCompletedContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const onOpenWorkspaceClick = useOnOpenWorkspace();

  useLayoutEffect(() => {
    requestAnimationFrame(window.htmlLoader.hide);
  });

  const handleGoToWorkspace = () => {
    const workspaceId = R.pathOr('', ['state', 'workspaceId'], location);
    const openAsBackend = R.pathOr('', ['state', 'openAsBackend'], location);

    if (workspaceId) {
      onOpenWorkspaceClick({
        workspaceId,
        openAsBackend,
      });

    } else {
      history.push(APP_URL.developerHomeWorkspaces);
    }
  };
  const handleBackToInbox = () => history.push(APP_URL.developerHomeInbox);


  const workspaceName = R.pathOr('', ['state', 'workspaceName'], location);

  return (
    <TransferPageWrapper
      beforeSlot={ (
        <CircledBackground>
          <Icon
            name="Check"
            color="WHITE"
            size="md"
          />
        </CircledBackground>
      ) }
      header="Workspace transfer completed!"
    >
      <Text css={ titleCss } >
        Workspace “{ workspaceName }” successfully transferred
      </Text>

      <Button
        color="primary"
        onClick={ handleGoToWorkspace }
      >
        Go to Workspace
      </Button>

      <Button
        color="primary"
        variant="link"
        onClick={ handleBackToInbox }
      >
        <Icon name="ArrowLeft" />
        Back to Inbox
      </Button>
    </TransferPageWrapper>
  );
};
