// @flow

export const numberToWord = (num?: number | null): string => {
  if (!num) {
    return '';
  }

  const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine',
    'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen',
    'seventeen', 'eighteen', 'nineteen'];
  const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty',
    'ninety'];

  const numString: any = num.toString();

  if (num < 0) throw new Error('Negative numbers are not supported.');

  if (num === 0) return 'zero';

  //the case of 1 - 20
  if (num < 20) {
    return ones[num] || '';
  }

  if (numString.length === 2) {
    return `${tens[numString[0]]} ${ones[numString[1]]}`;
  }

  //100 and more
  if (numString.length === 3) {
    if (numString[1] === '0' && numString[2] === '0')
      return `${ones[numString[0]]} hundred`;
    else
      return `${ones[numString[0]]} hundred and ${numberToWord(+(numString[1] + numString[2]))}`;
  }

  if (numString.length === 4) {
    const end = +(numString[1] + numString[2] + numString[3]);
    if (end === 0) return `${ones[numString[0]]} thousand`;
    if (end < 100) return `${ones[numString[0]]} thousand and ${numberToWord(end)}`;
    return `${ones[numString[0]]} thousand ${numberToWord(end)}`;
  }

  return '';
};
