import styled from '@emotion/styled';

export const DeveloperHomeContentPlate = styled.div`
  padding: 40px 65px;
  width: 100%;
  overflow-y: auto;
  position: relative;

  @media screen and (max-width: 991px) {
    padding: 24px;
  }
`;
