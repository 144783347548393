import React from 'react';
import { Grid } from '@8base/boost';
import { css } from '@emotion/core';
import * as R from 'ramda';

import { CommonFlowBars } from '../CommonFlow/CommonFlowBars';
import { AppFlowHeader } from './AppFlowHeader';
import { AppFlowMainNav } from './AppFlowMainNav';
import { AppFlowBreadcrumbs } from './AppFlowBreadcrumbs';

type AppFlowPlateProps = {
  children: ?React$Node,
  withoutBreadcrumbs?: boolean,
  withSecondaryNav?: boolean,
};

const NAV_COLUMN = 'auto';
const SECONDARY_NAV_COLUMN = '232px';

const appFlowPlateClassName = css({
  backgroundColor: '#F4F7F9',
  minHeight: '0px',
  height: '100%',
});

const getColumns = ({ withSecondaryNav }) => {
  if (withSecondaryNav) {
    return `${NAV_COLUMN} ${SECONDARY_NAV_COLUMN} 1fr`;
  }

  return `${NAV_COLUMN} 1fr`;
};

const getRows = () => 'auto 56px 32px 1fr';

const getAreas = ({ withSecondaryNav, withoutBreadcrumbs }) => {
  let zeroRow = ['bars', 'bars'];
  let firstRow = ['nav', 'header'];
  let secondRow = ['nav', 'breadcrumbs'];
  let thirdRow = ['nav', 'content'];

  if (withoutBreadcrumbs) {
    secondRow = R.map(
      R.when(
        R.equals('breadcrumbs'),
        R.always('content'),
      ),
    )(secondRow);
  }

  if (withSecondaryNav) {
    zeroRow = R.insert(1, 'bars')(zeroRow);
    firstRow = R.insert(1, 'header')(firstRow);
    secondRow = R.insert(1, R.last(secondRow))(secondRow);
    thirdRow = R.insert(1, 'secondaryNav')(thirdRow);
  }

  return [
    zeroRow,
    firstRow,
    secondRow,
    thirdRow,
  ];
};

const AppFlowPlate = ({
  children,
  withoutBreadcrumbs,
  withSecondaryNav,
}: AppFlowPlateProps) => (
  <Grid.Layout
    columns={ getColumns({ withSecondaryNav }) }
    rows={ getRows() }
    areas={ getAreas({ withSecondaryNav, withoutBreadcrumbs }) }
    css={ appFlowPlateClassName }
    stretch
  >
    <CommonFlowBars />
    <AppFlowHeader />
    <If condition={ !withoutBreadcrumbs }>
      <AppFlowBreadcrumbs />
    </If>
    <AppFlowMainNav />
    { children }
  </Grid.Layout>
);

export { AppFlowPlate };
