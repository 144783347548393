// @flow
import * as R from 'ramda';
import { useMutation } from 'react-apollo';

import { updateWorkspaceCachedData } from 'utils/apollo/updateCache';

import { WORKSPACE_UPDATE_MUTATION } from '../mutations';

const getWorkspaceData = (data) => {
  return R.pathOr({}, ['system', 'workspaceUpdate'], data);
};

export const useWorkspaceUpdate = (graphqlOptions: Object = {}) => {
  const [updateWorkspace, { data, ...rest }] = useMutation(WORKSPACE_UPDATE_MUTATION, {
    update: (cache, { data }) => {
      const workspaceUpdate = getWorkspaceData(data);

      if (!workspaceUpdate.id) {
        return;
      }

      updateWorkspaceCachedData({ workspaceId: workspaceUpdate.id, newData: workspaceUpdate, cache });
    },

    ...graphqlOptions,
    onCompleted: (data) => {
      graphqlOptions.onCompleted && graphqlOptions.onCompleted(getWorkspaceData(data));
    },
  });

  return [updateWorkspace, { workspaceUpdate: getWorkspaceData(data), ...rest }];
};
