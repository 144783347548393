// @flow
import { useMutation } from 'react-apollo';
import * as R from 'ramda';

import { ORGANIZATION_PAYMENT_DETAILS_QUERY } from 'graphql/queries';
import { ORGANIZATION_PAYMENT_DETAILS_UPDATE_MUTATION } from 'graphql/mutations';
import { FS, FULLSTORY_EVENT_TYPES } from 'utils/fullStory';

const getNewOrganizationPaymentDetails = R.pipe(
  R.pathOr({}, ['system', 'organizationPaymentDetailsUpdate']),
  R.pick(['expYear', 'expMonth', 'last4', 'brand']),
);

/**
 * Update organization payment method with apollo cache
 * */
const updateCacheOrganizationCard = ({ organizationId, cache, data }:*) => {

  const newPaymentDetails = getNewOrganizationPaymentDetails(data);

  const newData = {
    system: {
      organizationPaymentDetails: {
        ...newPaymentDetails,
        __typename: 'SystemPaymentDetailsResponse',
      },
    },
  };

  try {
    const prevData = cache.readQuery({
      query: ORGANIZATION_PAYMENT_DETAILS_QUERY,
      variables: {
        organizationId,
      },
    });

    const mergedData = R.mergeDeepRight(prevData, newData);

    cache.writeQuery({
      query: ORGANIZATION_PAYMENT_DETAILS_QUERY,
      variables: {
        organizationId,
      },
      data: mergedData,
    });
  } catch (err) {}
};

export const useOrganizationPaymentDetailsUpdate = (organizationId?: string) => {
  const updateCardMutation = useMutation(ORGANIZATION_PAYMENT_DETAILS_UPDATE_MUTATION, {
    update: (cache, { data }) => {
      updateCacheOrganizationCard({ organizationId, cache, data });
    },
    onCompleted: (data) => {
      if (!!data) {
        FS.event(FULLSTORY_EVENT_TYPES.enterCreditCard);
      }
    },
  });

  return updateCardMutation;
};
