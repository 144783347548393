// @flow

import React, { PureComponent } from 'react';
import fp from 'lodash/fp';
import fpUtils from 'utils/fp';
import { createSelector } from 'reselect';
import { wrapDisplayName, setDisplayName, compose, hoistStatics } from 'recompose';


type OptionType = {
  value: mixed,
  label: string,
}

type FieldWithDefaultValueProps = {
  defaultValue?: mixed,
  options?: OptionType[],
  multiple?: boolean,
  disabled?: boolean,
  input: {
    value: mixed,
    onChange: (mixed) => void,
  },
  meta: {
    initial?: mixed,
  },
}
const getValue = fp.get(['input', 'value']);
const getInitialValue = fp.get(['meta', 'initial']);
const getOnChange = fp.get(['input', 'onChange']);
const getDefaultValue = fp.get('defaultValue');
const getIsDisabled = fp.get('disabled');


const includesValue: { (value: mixed): (options: OptionType[]) => boolean } = (value) =>
  fp.some(['value', value]);

const includesValueArray: { (values: mixed[]): (options: OptionType[]) => boolean } = (values) => (options) =>
  fp.all((value) => includesValue(value)(options), values);

const isOptionsIncludeValue: { (value: mixed | mixed[]): (options: OptionType[]) => boolean } = fp.cond([
  [fp.isArray, includesValueArray],
  [fp.T, includesValue],
]);

const isNotArray = fp.pipe(fp.isArray, fpUtils.not);
const isNotBoolean = fp.pipe(fp.isBoolean, fpUtils.not);

const isHasInvalidValueFormat: { (multiple: ?boolean): (value: mixed | mixed[]) => boolean } = (multiple) => fp.cond([
  [() => isNotBoolean(multiple), fp.F],
  [fp.allPass([fpUtils.V(fp.isEqual(multiple, false)), fp.isArray]), fp.T],
  [fp.allPass([fpUtils.V(fp.isEqual(multiple, true)), isNotArray]), fp.T],
  [fp.T, fp.F],
]);

const findValueFromOptions = createSelector(
  getValue,
  getInitialValue,
  getDefaultValue,
  fp.get('options'),
  fp.getOr(null, 'multiple'),
  (value: mixed, initialValue: mixed, defaultValue: mixed, options: OptionType[], multiple: ?boolean) => fp.cond([
    [() => isHasInvalidValueFormat(multiple)(value), fpUtils.V(defaultValue)],
    [isOptionsIncludeValue(value), fpUtils.V(value)],
    [isOptionsIncludeValue(initialValue), fpUtils.V(initialValue)],
    [fp.T, fpUtils.V(defaultValue)],
  ])(options),
);

const findValueFromDefaultValue = createSelector(
  getValue,
  getDefaultValue,
  (value: mixed, defaultValue: mixed) => fp.cond([
    [fp.isNil, () => defaultValue],
    [fp.isEqual(fp.stubString()), () => defaultValue],
    [fp.T, fp.identity],
  ])(value),
);

const findValidValue = fp.cond([
  [fp.has('options'), findValueFromOptions],
  [fp.T, findValueFromDefaultValue],
]);

const addDefaultValue = createSelector(
  getIsDisabled,
  getOnChange,
  getValue,
  findValidValue,
  (disabled, onChange, currentValue, validValue) => {
    if (!disabled && !fp.isEqual(currentValue, validValue)) {
      onChange && onChange(validValue);
    }
  },
);

const enhancer = (SourceComponent: React$ComponentType<any>) => compose(
  setDisplayName(wrapDisplayName(SourceComponent, 'FieldWithDefaultValue')),
);

/**
 * add default value to redux form field and replace it when component has options that didn't match
 * @prop {*} formName legacy option
 * */
export const withDefaultValueField = <T: Object>() =>
  hoistStatics((SourceComponent: React$ComponentType<T>) => {
    class FieldWithDefaultValue extends PureComponent<*> {
      static defaultProps: $Shape<{ ...T, ...FieldWithDefaultValueProps }> = {
        defaultValue: null,
      };

      componentDidMount = () => {
        addDefaultValue(this.props);
      };

      UNSAFE_componentWillReceiveProps = (nextProps: FieldWithDefaultValueProps) => {
        const { defaultValue, options } = this.props;
        const isChangedDefaultValue = !fp.isEqual(nextProps.defaultValue, defaultValue);
        const isChangedOptions = !fp.isEqual(nextProps.options, options);
        const isChangedDisabled = !fp.isEqual(getIsDisabled(nextProps), getIsDisabled(this.props) && !getIsDisabled(nextProps));

        if (isChangedDefaultValue || isChangedOptions || isChangedDisabled) addDefaultValue(nextProps);
      };

      render() {
        return (
          <SourceComponent { ...this.props } />
        );
      }
    }

    return enhancer(SourceComponent)(FieldWithDefaultValue);
  });

export const withDefaultValueFieldFunctionsForTest = {
  findValueFromOptions, findValueFromDefaultValue,
};
