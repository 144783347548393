import gql from 'graphql-tag';

export const ENVIRONMENTS_LIST_QUERY = gql`
  query EnvironmentsList($email: String, $workspaceId: String!) {
    system {
      getEnvironmentRoles(email: $email, workspaceId: $workspaceId) {
        environmentId
        environmentName
        exists
      }
    }
  }
`;

export const GET_WORKSPACE_ENVIRONMENTS_ROLES_QUERY = gql`
  query GetWorkspaceEnvironmentsRoles($workspaceId: String!, $email: String) {
    system {
      getEnvironmentRoles(workspaceId: $workspaceId, email: $email) {
        environmentId
        environmentName
        exists
        roles {
          id
          name
        }
        assignedRoles {
          id
          name
        }
      }
    }
  }
`;
