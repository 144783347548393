// @flow
// $FlowFixMe waiting for update Flow
import React, { useCallback, useMemo } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { Field } from '@8base-react/forms';
import {
  Button,
  CheckboxField,
  Column,
  Grid, Icon,
  InputField,
  Link,
  Radio,
  RadioGroupField,
  Row,
  Text, useModal,
} from '@8base/boost';

import { Trans } from 'utils/translate';
import { composeValidators, required, requiredTrue } from 'utils/formValidations';
import { BankCardLastFour } from 'common';
import { PaymentDetailsUpdateDialog } from 'dialogs/BillingUpdateDialog/PaymentDetailsUpdateDialog';
import { BillingChooseCardDialog } from 'dialogs/BillingChooseCardDialog';

import { COLOR_BY_PLAN_NAME } from 'graphql/constants/billing';
import * as LINKS from 'config/links';

const getCardDescription = (isOrganization: boolean) => {
  if (!!isOrganization) {
    return 'Default organization payment method';
  }

  return 'Default payment method';
};

const getAdditionalInfo = ({ newOwnerType, newOwnerName }: *) => {
  const isOrganization = newOwnerType === 'organization';
  const direction = isOrganization ? `to ${newOwnerName}` : 'to you';

  return {
    isOrganization,
    direction,
  };
};

const PlanRadioItem = styled(Radio.Item)`
  padding: 16px 0;
  border-bottom: 1px solid ${props => props.theme.COLORS.GRAY_20};
  width: 100%;

  &:not(:last-child) {
    margin: 0;
  };
`;

const PaymentMethodCard = styled(Grid.Box)`
  border: 1px solid ${props => props.error ? props.theme.COLORS.DANGER : props.theme.COLORS.GRAY_20};
  border-radius: 12px;
  padding: 18px 16px;
`;

const WorkspacePlanRadioItemLabel = ({
  displayName,
  price,
  name,
}:*) => {
  const planColor = COLOR_BY_PLAN_NAME[name] || 'GREEN';

  return (
    <Grid.Layout alignItems="center" columns="18px 2fr 1fr" gap="sm" css={ css`padding-left: 12px;` }>
      <Icon name="Logo8base" size="sm" color={ planColor } />
      <Grid.Box>
        <Text
          color={ planColor }
          weight="semibold"
          css={{ textTransform: 'uppercase', fontSize: 14 }}
        >
          { displayName }
        </Text>
      </Grid.Box>
      <Grid.Box>
        <Text align="right" css={{ fontSize: 16 }}>${ price } / mo</Text>
      </Grid.Box>
    </Grid.Layout>
  );
};

export const TransferAcceptContent = ({
  transferByName,
  workspaceName,
  newOwnerName,
  newOwnerType,
  plans,
  paymentMethodError,
  card,
  form,
  newCardToken,
  setNewCardToken,
  disableCardUpdateBtn = false,
}: *) => {
  const { openModal, closeModal } = useModal();

  const userCard = useMemo(() => {
    if (newCardToken) return newCardToken.card;

    return card;
  }, [card, newCardToken]);

  const { isOrganization, direction } = useMemo(() => {
    return getAdditionalInfo({ newOwnerType, newOwnerName });
  },
  [newOwnerType, newOwnerName]);

  const onChangePaymentClick = useCallback((form) => () => {
    const updateCard = (token) => {
      setNewCardToken(token);
      if (form && form.change) {
        form.change('cardToken', token.id);
      }
      closeModal(PaymentDetailsUpdateDialog.ID);
    };

    openModal(BillingChooseCardDialog.ID, {
      defaultCard: card,
      defaultCardDescription: getCardDescription(isOrganization),
      onChooseCard: () => {
        openModal(PaymentDetailsUpdateDialog.ID, {
          onCardTokenChange: updateCard,
        });
        closeModal(BillingChooseCardDialog.ID);
      },
      onChooseDefaultCard: () => {
        setNewCardToken(null);
        closeModal(BillingChooseCardDialog.ID);
      },
    });
  }, [openModal, card, isOrganization, setNewCardToken, closeModal]);

  return (
    <React.Fragment>
      <Grid.Layout gap="md">
        <Grid.Box>
          <Text weight="semibold">
            <Trans i18nKey="home.transferWorkspace.ownership.request">
              { transferByName } wants to transfer { workspaceName } workspace { direction }
            </Trans>
          </Text>
        </Grid.Box>
        <Grid.Layout columns="repeat(2, 1fr)" gap="md">
          <Field
            label="Workspace Name"
            name="workspaceName"
            placeholder="Workspace Name"
            disabled={ disableCardUpdateBtn }
            component={ InputField }
            validate={ required }
          />
          <Field
            label="Region"
            name="region"
            placeholder="Region"
            component={ InputField }
            readOnly
          />
        </Grid.Layout>
        <Grid.Layout gap="sm">
          <Text kind="h4">
            <Trans i18nKey="home.transferWorkspace.ownership.workspacePlan">
              Workspace Plan
            </Trans>
          </Text>
          <If condition={ plans.length === 0 } >
            <Text>
              There are no available plans,{ ' ' }
              <Link href={ LINKS.SUPPORT_URL } target="_blank" rel="noopener noreferrer">
                please contact support.
              </Link>
            </Text>
          </If>
          <Field name="planId" validate={ required }>
            { ({ input, meta }) => (
              <RadioGroupField
                input={ input }
                meta={ meta }
                stretch
              >
                { plans.map((plan) => (
                  <PlanRadioItem
                    key={ plan.id }
                    value={ plan.id }
                    label={ <WorkspacePlanRadioItemLabel
                      displayName={ plan.displayName }
                      description={ plan.description }
                      price={ plan.price }
                      name={ plan.name }
                    /> }
                  />),
                ) }
              </RadioGroupField>
            ) }
          </Field>
        </Grid.Layout>
        <Grid.Layout gap="sm">
          <Text kind="h4">
            <Trans i18nKey="home.transferWorkspace.ownership.paymentMethod">
              Payment Method
            </Trans>
          </Text>
          <PaymentMethodCard error={ paymentMethodError }>
            <If condition={ !userCard }>
              <Row alignItems="center" justifyContent="between">
                <Text weight="bold">No card on file.</Text>
                <Button
                  color="neutral"
                  disabled={ disableCardUpdateBtn }
                  onClick={ onChangePaymentClick(form) }
                >
                  <Trans i18nKey="home.transferWorkspace.ownership.changePaymentMethod">
                    Change Payment Method
                  </Trans>
                </Button>
              </Row>
            </If>

            <If condition={ !!userCard }>
              <Row alignItems="center" justifyContent="between">
                <Column>
                  <BankCardLastFour card={ userCard } borderless />
                  <If condition={ !newCardToken }>
                    <Text kind="overline-1">{ getCardDescription(isOrganization) }</Text>
                  </If>
                </Column>
                <Button
                  disabled={ disableCardUpdateBtn }
                  color="neutral"
                  onClick={ onChangePaymentClick(form) }
                >
                  <Trans i18nKey="home.transferWorkspace.ownership.changePaymentMethod">
                    Change Payment Method
                  </Trans>
                </Button>
              </Row>
            </If>
          </PaymentMethodCard>
        </Grid.Layout>
        <Row>
          <Field
            name="agreement"
            validate={ composeValidators(required, requiredTrue) }
          >
            { ({ input, meta }) =>
              (
                <CheckboxField
                  input={ input }
                  meta={ meta }
                  label="By clicking “Apply Transfer” I accept transfer and understand that all existent Team Members in the workspace will lose access."
                />
              ) }
          </Field>
        </Row>
      </Grid.Layout>
    </React.Fragment>
  );
};
