// @flow

import * as R from 'ramda';
import { createSelector, type Selector } from 'reselect';
import { APP_STATUS } from '@8base/utils';

import type { ApolloApplication } from './application.selectors';

export type ApolloApplicationsListResponse = {
  items: ApolloApplication[],
  count: number,
}

export const getApplicationsListResponse: Selector< ApolloApplicationsListResponse, any, ApolloApplicationsListResponse> =
  (applicationsList) => applicationsList;

export const getApplicationsList: Selector<ApolloApplicationsListResponse, any, ApolloApplication[]> = createSelector(
  getApplicationsListResponse,
  R.propOr([], 'items'),
);

export const getIntegrations: Selector<ApolloApplicationsListResponse, any, ApolloApplication[]> = createSelector(
  getApplicationsList,
  R.filter(({ name }) => name !== null),
);

export const getConnectedIntegrations: Selector<ApolloApplicationsListResponse, any, ApolloApplication[]> = createSelector(
  getIntegrations,
  R.filter(({ status }) => status === APP_STATUS.ACTIVE),
);

export const getApplication: Selector<ApolloApplicationsListResponse, any, ApolloApplication | void> = createSelector(
  getApplicationsList,
  (_, id: string) => id,
  (applications, appId) => applications.find(({ id }) => id === appId),
);
