import gql from 'graphql-tag';

export const BILLING_DETAILS_UPDATE_MUTATION = gql`
  mutation BillingDetailsUpdate($data: BillingDetailsUpdateMutationInput!) {
    billingDetailsUpdate(data: $data) {
      expYear
      expMonth
      last4
      brand
    }
  }
`;

export const USER_PAYMENT_DETAILS_UPDATE_MUTATION = gql`
  mutation UserPaymentDetailsUpdate($data: SystemMemberPaymentDetailsUpdateMutationInput!) {
    system {
      memberPaymentDetailsUpdate(data: $data) {
        expYear
        expMonth
        last4
        brand
      }
    }
  }
`;

export const WORKSPACE_PAYMENT_DETAILS_UPDATE_MUTATION = gql`
  mutation WorkspacePaymentDetailsUpdate($data: SystemWorkspacePaymentDetailsUpdateMutationInput) {
    system {
      workspacePaymentDetailsUpdate(data: $data) {
        expYear
        expMonth
        last4
        brand
        origin
      }
    }
  }
`;


export const ORGANIZATION_PAYMENT_DETAILS_UPDATE_MUTATION = gql`
  mutation organizationPaymentDetailsUpdate($data: SystemOrganizationPaymentDetailsUpdateMutationInput!) {
    system {
      organizationPaymentDetailsUpdate(data: $data) {
        expYear
        expMonth
        last4
        brand
      }
    }
  }
`;

export const BILLING_PLAN_UPDATE_MUTATION = gql`
  mutation BillingPlanUpdate($data: BillingPlanUpdateMutationInput!) {
    billingPlanUpdate(data: $data) {
      id
      name
      displayName
      trialEnd
      status
      price
      nextPlan {
        id
        name
        displayName
        price
      }
    }
  }
`;
