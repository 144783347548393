//@flow

import * as R from 'ramda';
import { createSelector } from 'reselect';

import type { Selector } from 'reselect';

import type { ApolloPermission } from './permission.selectors';

export type ApolloPermissionsListResponse = {
  items: ApolloPermission[],
  count: number,
};

export const getPermissionsListResponse: Selector<
  ApolloPermissionsListResponse,
  any,
  ApolloPermissionsListResponse,
> = (permissionsListResponse = { items: [], count: 0 }) => permissionsListResponse;

export const getPermissionsList: Selector<
  ApolloPermissionsListResponse,
  any,
  ApolloPermission[],
> = createSelector(
  getPermissionsListResponse,
  R.prop('items'),
);

export const getPermissionsCount: Selector<
  ApolloPermissionsListResponse,
  any,
  number,
> = createSelector(
  getPermissionsListResponse,
  R.prop('count'),
);

export const getPermissionByResource: Selector<ApolloPermissionsListResponse, any, any> = createSelector(
  getPermissionsList,
  (_, resource) => resource,
  (_, __, appName) => appName,
  (permissions, resource, appName) => R.pipe(
    R.filter(({ appId, resource }) => appId ? appId === appName : !appName),
    R.find(R.propEq('resource', resource)),
  ) (permissions),
);

