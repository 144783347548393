// @flow

// $FlowFixMe waiting for update Flow
import React, { useCallback } from 'react';
import { FileInput } from '@8base-react/file-input';
import { Icon } from '@8base/boost';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import workspaceDefaultLogo from 'images/workspace-default-logo.svg';

export type WorkspaceImageInputProps = {
    input: any;
    size?: 'md' | 'lg';
    disabled: boolean;
}

const SIZE = {
  md: '60px',
  lg: '84px',
  xl: '120px',
};

const sizeCss = (props) => {
  const size = props.size || SIZE.md;

  return css`
    height: ${SIZE[size]};
    min-height: ${SIZE[size]};
    max-height: ${SIZE[size]};
    width: ${SIZE[size]};
    min-width: ${SIZE[size]};
    max-width: ${SIZE[size]};
  `;
};

const WorkspaceImage = styled.img`
  cursor: pointer;
  border-radius: ${props => props.size === 'xl' ? '20px' : '5px'};
  object-fit: cover;

  ${sizeCss}
`;

const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  alignItems: center;
  position: relative;
`;

const HoverWrapper = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  cursor: pointer;
  color: #FFFFFF;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  transition: all .3s ease-in-out;
  user-select: none;

  &:hover {
    opacity: ${props => props.disabled ? '0' : '1'};
  }

  &:before {
    display: block;
    content: '';
    border-radius: ${props => props.size === 'xl' ? '20px' : '5px'};
    background: rgba(0, 0, 0, .75);
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const CAMERA_ICON_SIZE = {
  md: '18px',
  lg: '18px',
  xl: '24px',
};

const HoverInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`;

export const WorkspaceImageInput = ({
  input,
  size,
  disabled,
}:WorkspaceImageInputProps) => {
  const onUploadDone = useCallback(async (file) => {
    input.onChange(file);
  }, [input]);

  return (
    <FileInput maxFiles={ 1 } onUploadDone={ onUploadDone } value={ input.value } >
      { ({ pick, value }) => (
        <Wrapper onClick={ () => !disabled && pick({ accept: ['image/*'] }) }>
          <HoverWrapper size={ size } disabled={ disabled } >
            <HoverInner>
              <Icon name="Camera" color="WHITE" size="custom" customSize={ CAMERA_ICON_SIZE[size || 'md'] } />
              <div>
                Change
              </div>
            </HoverInner>
          </HoverWrapper>
          <WorkspaceImage
            src={ value ? value.downloadUrl : workspaceDefaultLogo }
            size={ size }
          />
        </Wrapper>
      ) }
    </FileInput>
  );
};
