//@flow

import React from 'react';
import { Text, Heading } from '@8base/boost';

import { Trans } from 'utils/translate';

type PaymentProcessingProps = {
  type: 'withNote' | 'downgrading' | 'simple';
};

export const PaymentSubmitting = ({ type = 'simple' }:PaymentProcessingProps) => {
  switch (type) {
    case 'downgrading': {
      return (
        <Heading type="h3">
          <Trans i18nKey="billingUpdateDialog.downgradeLoading.title">
            Downgrading plan
          </Trans>
        </Heading>
      );
    }

    case 'simple': {
      return (
        <Heading type="h3">
          <Trans i18nKey="billingUpdateDialog.cardUpdateLoading.title">
            Thank you! Your request is processing
          </Trans>
        </Heading>
      );
    }

    case 'withNote':
    default: {
      return (
        <React.Fragment>
          <Heading type="h3">
            <Trans i18nKey="billingUpdateDialog.paymentLoading.title">
              Thank you! Your payment is processing
            </Trans>
          </Heading>
          <Text color="LIGHT_TEXT_COLOR">
            <Trans i18nKey="billingUpdateDialog.paymentLoading.note">
              We will send you a copy of your invoice via email<br />once your payment has been processed.
            </Trans>
          </Text>
        </React.Fragment>
      );
    }
  }
};

