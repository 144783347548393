// @flow

import errorCodes from '@8base/error-codes';
import * as R from 'ramda';
import { SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';

import type { ApolloError } from 'react-apollo';

const ignoreToastCodes = [
  errorCodes.ForcibleErrorCode,
  errorCodes.ValidationErrorCode,
  errorCodes.BillingPlanLimitErrorCode,
  errorCodes.BillingNoCCLimitErrorCode,
];


const needsIgnoreError = (error: {}) => {
  const errorCode = R.prop('code', error) || R.path(['details', '0', 'code'], error);
  const errorMessage = R.prop('message', error) || '';

  // TODO: remove this check during resolve this task https://8base-dev.atlassian.net/browse/CW-4290
  if (errorMessage.includes('Cannot query field "fileUploadInfo" on type "Query"')) {
    return true;
  }

  return !R.isNil(errorCode) && R.any(R.equals(errorCode), ignoreToastCodes);
};

export const throwToastNotification = (error: any, force?: boolean) => {
  if (error && error.message && (!needsIgnoreError(error) || force)) {
    toast.error(error.message, { toastId: error.message + Math.ceil(Date.now() / 3000) });
  }
};

const hasValidationError = R.propEq(
  'code',
  errorCodes.ValidationErrorCode,
);

export const handleValidationError = (error?: ApolloError = {}) => {
  const graphQLErrors = R.propOr([], 'graphQLErrors', error);
  let errors = {};

  if (graphQLErrors.length > 0) {
    errors = graphQLErrors.reduce(
      (acc, graphQLError) => {
        if (hasValidationError(graphQLError)) {
          return {
            ...acc,
            ...R.propOr({}, 'details', graphQLError),
          };
        }

        return acc;
      },
      errors,
    );
  }

  if (!R.isEmpty(errors)) {
    throw new SubmissionError(errors);
  }
};

