// @flow

import React, { PureComponent } from 'react';
import * as R from 'ramda';
import memoize from 'lodash/memoize';
import { compose } from 'recompose';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloClient } from 'apollo-client';
import { defaultDataIdFromObject, InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import { matchPath, withRouter, type RouterHistory } from 'react-router-dom';
import { ApolloLink } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { withModal } from '@8base/boost';
import { withAuth, type AuthContextProps } from '@8base-react/auth';
import { FragmentsSchemaContainer } from '@8base-react/app-provider';
import { RetryLink } from 'apollo-link-retry';
import { onError } from 'apollo-link-error';
import { AuthLink, SuccessLink, SubscriptionLink } from '@8base/apollo-links';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';
import errorCodes from '@8base/error-codes';

import { APP_URL, ROUTES_MATCHES_WITHOUT_WORKSPACE, buildUrl } from 'common/routing';
import { SELECTED_COLUMNS } from 'common/constants/localStorageKeys';
import { logger } from 'utils/logger';
import { trackVisitorId } from 'utils/trackVisitorId';
import {
  DIALOG_ID,
  DISABLE_RETRY,
  DISABLE_TRACK_ERRORS,
  ERROR_PREFIX,
  FORCE_TOAST_ERROR_MESSAGE,
  HEADERS,
  HIDE_TOAST_ERROR_MESSAGE,
  IGNORE_GRAPHQL_ERRORS,
  TOAST_ERROR_MESSAGE_FORMATTER,
  TOAST_SUCCESS_MESSAGE,
  IGNORE_LIMIT_METRIC_ERROR,
  ENDPOINT_URI,
  IGNORE_WORKSPACE, WORKSPACE_ID, LEARNING_CENTER,
} from 'common/constants/apolloOperationContextOptions';
import { throwToastNotification } from 'utils/handleErrors';
import { RequireCCFeatureDialog } from 'dialogs/RequireCCFeatureDialog';
import { RequirePlanUpgradeDialog } from 'dialogs/RequirePlanUpgradeDialog';
import { WarningLimitsDialog } from 'dialogs/WarningLimitsDialog';
import { environmentAccessor } from 'utils';
import { FS, FULLSTORY_EVENT_TYPES } from 'utils/fullStory';

import { getStore } from '../redux';
import { makeResolvers } from '../graphql/resolvers';
import { WorkspaceProvider } from './WorkspaceProvider';
import { hasError, getError } from '../graphql/utils';
import { withWorkspaceProxy } from './WorkspaceProxyProvider';

type AsyncApolloProviderProps = {
  children: React$Node,
  history: RouterHistory,
  auth: AuthContextProps,
  openModal: Function,
  apiUri?: string,
  webSocketUri?: string;
  workspaceListLoaded: boolean,
};

export const isNotFoundWorkspaceError = R.allPass([
  R.propEq('code', errorCodes.EntityNotFoundErrorCode),
  R.anyPass([
    R.propEq('message', 'Workspace not found'),
    R.propEq('subType', 'EnvironmentNotFound'),
  ]),
]);


const store = getStore();

/** component fetch interfaces fragments schema and create apollo client  */
class AsyncApolloProvider extends PureComponent<AsyncApolloProviderProps> {
  client: *;

  handleApolloSuccess = ({ operation }) => {
    const toastSuccessMessage = operation.getContext()[TOAST_SUCCESS_MESSAGE];

    if (toastSuccessMessage) {
      toast.success(toastSuccessMessage);
    }
  };

  handleApolloError = (options) => {
    const { networkError, graphQLErrors, operation, forward } = options;
    const { history, auth: { isAuthorized }} = this.props;

    const hasGraphQLErrors = Array.isArray(graphQLErrors) && graphQLErrors.length > 0;
    const disableTrackErrors = operation.getContext()[DISABLE_TRACK_ERRORS];
    const errorPrefix = operation.getContext()[ERROR_PREFIX];
    const ignoreGraphQLErrors = operation.getContext()[IGNORE_GRAPHQL_ERRORS];
    const hideToastErrorMessage = operation.getContext()[HIDE_TOAST_ERROR_MESSAGE];
    const forceToastErrorMessage = operation.getContext()[FORCE_TOAST_ERROR_MESSAGE];
    const errorToastMessageFormatter = operation.getContext()[TOAST_ERROR_MESSAGE_FORMATTER];
    const ignoreLimitMetricErrors = operation.getContext()[IGNORE_LIMIT_METRIC_ERROR];

    if (networkError) {
      Sentry.withScope(scope => {
        scope.setTag('type', 'Network Error');

        Sentry.captureException(networkError);
      });
    }

    if (hasGraphQLErrors) {
      if (!disableTrackErrors) {
        let errorName = `GraphQL Error: ${R.propOr('Unititled', 'operationName', operation)} (${R.propOr('Unititled Message', 'message', graphQLErrors[0])})`;

        if (errorPrefix) {
          errorName = `${errorPrefix} ${errorName}`;
        }

        const variables = R.propOr({}, 'variables', operation);
        const query = R.pathOr('Unititled', ['query', 'loc', 'source', 'body'], operation);
        const errors = graphQLErrors;

        Sentry.withScope(scope => {
          scope.setExtra('variables', variables);
          scope.setExtra('query', query);
          scope.setExtra('errors', errors);
          scope.setTag('type', 'GraphQL Error');

          Sentry.captureException(new Error(errorName));
        });

        FS.event(FULLSTORY_EVENT_TYPES.graphqlError, {
          operation_name_str: R.propOr('Unititled', 'operationName', operation),
          message_str: R.propOr('Unititled Message', 'message', graphQLErrors[0]),
          code_str: R.propOr('UnititledError', 'code', graphQLErrors[0]),
          query_str: query,
          variables_str: JSON.stringify(variables),
        });
      }

      if (hasError(errorCodes.BillingNoCCLimitErrorCode, graphQLErrors)) {
        this.props.openModal(RequireCCFeatureDialog.ID);

        return;
      }

      if (R.any(isNotFoundWorkspaceError, graphQLErrors) && isAuthorized) {
        const { headers } = operation.getContext();

        if (headers.workspace) {
          environmentAccessor.removeEnvironment(headers.workspace);
          history.push(buildUrl(APP_URL.workspaceHome, { pathParams: { workspaceId: headers.workspace }}));
        } else {
          history.push(headers.workspace);
        }

        return;
      }


      if (hasError(errorCodes.BillingPlanLimitWarningCode, graphQLErrors)) {
        const error = getError(errorCodes.BillingPlanLimitWarningCode, graphQLErrors);
        const limitMetricName = R.path(['details', 'limitMetricName'], error);
        const dialogId = operation.getContext()[DIALOG_ID];

        if (limitMetricName) {
          this.props.openModal(WarningLimitsDialog.ID, { limitMetricName, dialogId, onContinue: () =>
            new Promise((resolve) => {
              operation.variables = {
                ...operation.variables,
                force: true,
              };

              return forward(operation).subscribe({
                next: ({ data, errors }) => {
                  if (data) {
                    this.handleApolloSuccess(options);
                  } else {
                    this.handleApolloError({ ...options, graphQLErrors: errors });
                  }
                },
                complete: () => {
                  resolve();
                },
              });
            }),
          });

          return;
        }
      }


      if (hasError(errorCodes.BillingPlanLimitErrorCode, graphQLErrors) && !ignoreLimitMetricErrors) {
        const error = getError(errorCodes.BillingPlanLimitErrorCode, graphQLErrors);

        const nextPlan = R.path(['details', 'nextPlan'], error);
        const featureName = R.path(['details', 'featureName'], error);
        const limitMetricName = R.path(['details', 'limitMetricName'], error);

        if (nextPlan) {
          this.props.openModal(RequirePlanUpgradeDialog.ID, { nextPlan, limitMetricName, featureName });

          return;
        }
      }

      if (hasError(errorCodes.BillingFeatureAccessErrorCode, graphQLErrors) && !ignoreLimitMetricErrors) {
        const error = getError(errorCodes.BillingFeatureAccessErrorCode, graphQLErrors);

        const nextPlan = R.path(['details', 'nextPlan'], error);
        const featureName = R.path(['details', 'featureName'], error);

        if (nextPlan) {
          this.props.openModal(RequirePlanUpgradeDialog.ID, { nextPlan, featureName });

          return;
        }
      }


      if (!hideToastErrorMessage) {
        graphQLErrors.forEach(error => {
          const message = typeof errorToastMessageFormatter === 'function'
            ? errorToastMessageFormatter(error)
            : error.message;


          throwToastNotification({ ...error, message }, forceToastErrorMessage);
        });
      }

      if (!ignoreGraphQLErrors) {
        graphQLErrors.forEach(error => {
          logger.error(error);
        });
      }
    }
  };

  onIdTokenExpired = async () => {
    const {
      history,
      auth: {
        authClient,
      },
    } = this.props;

    const prevAuthState = authClient.getState();

    const { idToken, idTokenPayload, email } = await authClient.checkSession();

    const nextAuthState = {
      token: idToken,
      email,
    };

    if (email !== prevAuthState.email) {
      history.push(APP_URL.developerHome);
    }

    authClient.setState(nextAuthState);

    const visitorId = R.propOr(null, 'https://8base.com/visitor_id', idTokenPayload);

    trackVisitorId(visitorId);
  };

  onAuthError = (error) => {
    const { auth } = this.props;
    const { authClient } = auth;

    window.htmlLoader.show();

    this.client.clearStore();

    window.localStorage.removeItem(SELECTED_COLUMNS);

    authClient.logout();
  };

  getAuthState = ({ operation } = {}) => {
    const isIgnoreWorkspace = operation && operation.getContext()[IGNORE_WORKSPACE];
    const customWorkspaceId = operation && operation.getContext()[WORKSPACE_ID];

    const {
      auth: {
        authState,
      },
    } = this.props;

    const workspaceId = customWorkspaceId || this.getWorkspaceId();
    const environment = environmentAccessor.getEnvironment(workspaceId);
    const environmentName = !environment || environmentAccessor.isMasterEnvironment(workspaceId)
      ? undefined
      : environment;

    if (authState) {
      if (!this.props.workspaceListLoaded) {
        return {
          token: authState.token,
          environmentName,
        };
      }

      return {
        token: authState.token,
        workspaceId: isIgnoreWorkspace ? undefined : workspaceId,
        environmentName,
      };
    }

    return {};
  };

  getWorkspaceId = () => {
    const { history } = this.props;

    const match = matchPath(history.location.pathname, { path: APP_URL.workspaceHome, exact: false, strict: false });

    const workspaceId = match && match.params && match.params.workspaceId;

    return !!ROUTES_MATCHES_WITHOUT_WORKSPACE.find(routeMatch => routeMatch === workspaceId) ? undefined : workspaceId;
  }

  contextHeadersLink = new ApolloLink((operation, forward) => {
    const extraHeaders = operation.getContext()[HEADERS];
    const isLearningCenter = operation.getContext()[LEARNING_CENTER];
    if (isLearningCenter) {
      operation.setContext(() => ({
        headers: undefined,
      }));
    } else {
      operation.setContext(
        R.over(R.lensProp('headers'), headers => {
          const environment = environmentAccessor.getEnvironment(headers.workspace);

          return {
            ...headers,
            ...extraHeaders,
            environment,
          };
        }),
      );
    }

    return forward(operation);
  });

  prevApiUrl = process.env.REACT_APP_SERVER_URL;
  prevWebSocketUri = process.env.REACT_APP_SERVER_WSS_URL;

  createClient = memoize((newApiUrl, newWebSocketUri, introspectionQueryResultData) => {
    if (this.client && !newApiUrl) {
      return this.client;
    }

    const shouldReturnCurrentClient = () => {
      if (newApiUrl) {
        return newApiUrl === this.prevApiUrl;
      }

      if (newWebSocketUri) {
        return newWebSocketUri === this.prevWebSocketUri;
      }

      return false;
    };

    if (this.client && shouldReturnCurrentClient()) {
      return this.client;
    }

    if (newApiUrl) {
      this.prevApiUrl = newApiUrl;
    }

    if (newWebSocketUri) {
      this.prevWebSocketUri = newWebSocketUri;
    }

    const links = [
      new SuccessLink({ successHandler: this.handleApolloSuccess }),
      onError(this.handleApolloError),
      new RetryLink({
        attempts: {
          max: 30,
          retryIf: (_, operation) => R.pathEq(['query', 'definitions', 0, 'operation'], 'query', operation) && !operation.getContext()[DISABLE_RETRY],
        },
      }),
      new AuthLink({
        getAuthState: this.getAuthState,
        onAuthError: this.onAuthError,
        onIdTokenExpired: this.onIdTokenExpired,
      }),
      this.contextHeadersLink,
      ApolloLink.split(
        ({ query }) => {
          const definition = getMainDefinition(query);

          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        new SubscriptionLink({
          uri: newWebSocketUri || this.prevWebSocketUri || process.env.REACT_APP_SERVER_WSS_URL,
          getAuthState: this.getAuthState,
          onAuthError: this.onAuthError,
          onIdTokenExpired: this.onIdTokenExpired,
        }),
        new BatchHttpLink({
          uri: (operation) => {
            const endpointUrl = operation.getContext()[ENDPOINT_URI];
            if (endpointUrl) {
              return endpointUrl;
            }

            return newApiUrl || this.prevApiUrl || process.env.REACT_APP_SERVER_URL;
          },
          batchKey: operation => {
            const context = operation.getContext();

            let batchKey = 'eager';

            if (context.noBatch) {
              batchKey = String(Math.random());
            } else if (context.batchKey) {
              ({ batchKey } = context);
            }

            return batchKey;
          },
        }),
      ),
    ];

    if (this.client) {
      this.client.stop();
    }

    this.client = new ApolloClient({
      connectToDevTools: true,
      defaultOptions: {
        watchQuery: {
          notifyOnNetworkStatusChange: true,
        },
        query: {
          notifyOnNetworkStatusChange: true,
        },
      },
      link: ApolloLink.from(links),
      cache: new InMemoryCache({
        fragmentMatcher: new IntrospectionFragmentMatcher({
          introspectionQueryResultData,
        }),
        dataIdFromObject: (object, variables) => {
          switch (object.__typename) {
            case 'SystemOrganizationUserInfo':
              return undefined;
            case 'SystemInboxEventItem':
              return `${object.id || ''}-${object.createdAt || ''}`;
            default:
              return defaultDataIdFromObject(object);
          }
        },
      }),
      resolvers: makeResolvers(),
    });

    return this.client;
  });

  renderContent = (workspaceListLoaded, apiUri, webSocketUri) => ({ loading, introspectionQueryResultData }) => {
    if (loading) {
      return null;
    }

    const client = this.createClient(apiUri, webSocketUri, introspectionQueryResultData);

    const { auth: { isAuthorized, isEmailVerified }} = this.props;

    (window.dataLayer = window.dataLayer || []).push({ loggedIn: String(isAuthorized) });

    return (
      <ApolloProvider client={ client }>
        <ReduxProvider store={ store }>
          <WorkspaceProvider>
            { isEmailVerified && isAuthorized && workspaceListLoaded && this.props.children }
            { (!isAuthorized || !isEmailVerified) && this.props.children }
          </WorkspaceProvider>
        </ReduxProvider>
      </ApolloProvider>
    );
  };

  render() {
    return (
      <FragmentsSchemaContainer uri={ process.env.REACT_APP_SERVER_URL }>
        { this.renderContent(this.props.workspaceListLoaded, this.props.apiUri, this.props.webSocketUri) }
      </FragmentsSchemaContainer>
    );
  }
}

AsyncApolloProvider = compose(
  withModal,
  withRouter,
  withAuth,
  withWorkspaceProxy,
)(AsyncApolloProvider);

export { AsyncApolloProvider };
