//@flow

// $FlowFixMe waiting for update Flow
import React, { useCallback } from 'react';
import { useModal } from '@8base/boost';

import { BaseBillingUpdateDialog } from './components/BaseBillingUpdateDialog';

const PAYMENT_DETAILS_UPDATE_ID = 'PAYMENT_DETAILS_UPDATE_ID';

/**
 * Update payment method
 * used only to get token with callback
 */
const PaymentDetailsUpdateDialog = () => {
  const { args } = useModal(PAYMENT_DETAILS_UPDATE_ID);

  const onSubmitSuccess = useCallback(async (token) => {
    if (args && args.onCardTokenChange) {
      await args.onCardTokenChange(token);
    }
  }, [args]);

  return (
    <BaseBillingUpdateDialog
      dialogId={ PAYMENT_DETAILS_UPDATE_ID }
      onSubmitSuccess={ onSubmitSuccess }
    />
  );
};

PaymentDetailsUpdateDialog.ID = PAYMENT_DETAILS_UPDATE_ID;

export { PaymentDetailsUpdateDialog };
