// @flow
// $FlowFixMe
import React from 'react';
import { Text, Column } from '@8base/boost';
import { css } from '@emotion/core';

import INBOX_IMG from 'images/inbox.svg';
import { Trans } from 'utils/translate';

export const InboxListEmpty = () => {
  return (
    <Column alignItems="center" justifyContent="center" stretch gap="lg">
      <img src={ INBOX_IMG } alt="8base man" css={ css`width: 76px;` } />
      <Text kind="subtitle" color="GRAY_40">
        <Trans i18nKey="developerHome.inbox.empty">
          You do not have any Inbox Items
        </Trans>
      </Text>
    </Column>
  );
};
