import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '@8base-react/auth';

import { APP_URL, useBuildUrl } from 'common/routing';
import { useQueryParams } from 'utils/hooks';

export const InviteContainer = () => {
  const [queryParams] = useQueryParams();
  const auth = useAuth();
  const buildUrl = useBuildUrl();

  if (!queryParams.id) {
    return <Redirect to={ buildUrl(APP_URL.inviteIncorrect) } />;
  }

  window.trackEvent('System', 'Accept invite');

  if (auth.isAuthorized) {
    const options = {
      queryParams: {
        inviteUuid: queryParams.id,
        host: queryParams.host,
      },
    };

    return <Redirect to={ buildUrl(APP_URL.developerHomeWorkspaces, options) } />;
  }


  return <Redirect to={ buildUrl(APP_URL.authSignup) } />;
};
