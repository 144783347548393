// @flow
// $FlowFixMe waiting for update Flow
import { useMutation } from 'react-apollo';

import { IGNORE_LIMIT_METRIC_ERROR } from 'common/constants/apolloOperationContextOptions';
import { WORKSPACES_LIST_QUERY } from 'graphql/queries';
import { ACCEPT_TRANSFER_OWNERSHIP_MUTATION } from 'graphql/mutations';

const defaultOptions = {
  context: {
    [IGNORE_LIMIT_METRIC_ERROR]: true,
  },
  awaitRefetchQueries: true,
  refetchQueries: [
    {
      query: WORKSPACES_LIST_QUERY,
    },
  ],
};

export const useAcceptTransfer = ({ update }: * = {}) => {
  let options = { ...defaultOptions };

  if (update) {
    options = { ...options, update };
  }

  const [accept, { loading: acceptLoading }] = useMutation(ACCEPT_TRANSFER_OWNERSHIP_MUTATION, options);

  return {
    accept,
    acceptLoading,
  };
};
