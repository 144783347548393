import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { Dialog, Button, useModal, Column, Paragraph, InputField, Text } from '@8base/boost';
import { Form, Field } from '@8base-react/forms';
import { TABLES_SCHEMA_QUERY } from '@8base-react/table-schema-provider';

import { i18n } from 'i18n';
import { Trans } from 'utils/translate';

const INDEX_DELETE_MUTATION = gql`
 mutation IndexDelete($data: IndexDeleteInput!) {
  indexDelete(data: $data) {
    success
  }
}
`;

const INDEX_DELETE_DIALOG_ID = 'INDEX_DELETE_DIALOG_ID';

const IndexDeleteDialog = () => {
  const { closeModal, args } = useModal(INDEX_DELETE_DIALOG_ID);

  const { name, id } = args;

  const [indexDelete, { loading: submitting }] = useMutation(INDEX_DELETE_MUTATION, {
    refetchQueries: [{ query: TABLES_SCHEMA_QUERY }],
    awaitRefetchQueries: true,
  });

  const onClose = React.useCallback(() => {
    closeModal(INDEX_DELETE_DIALOG_ID);

  }, [closeModal]);

  const onSubmit = React.useCallback(async (data, form) => {
    await indexDelete({ variables: { data: { id }}});

    onClose();

    setTimeout(form.reset);
  }, [indexDelete, id, onClose]);

  const validateName = React.useCallback((value) => value === name ? null : i18n.t('validation.valueNoMatch'), [name]);

  return (
    <Form onSubmit={ onSubmit }>
      { ({ handleSubmit, invalid, pristine, form }) => {
        const handleClose = () => { onClose(); form.reset(); };
        return (
          <Dialog id={ INDEX_DELETE_DIALOG_ID } onClose={ handleClose } size="md">
            <form onSubmit={ handleSubmit }>
              <Dialog.Header title={ i18n.t('dialogs.indexDelete.title') } onClose={ handleClose } />
              <Dialog.Body >

                <Column gap="lg">
                  <Paragraph css={{ width: '100%' }} align="center">
                    <Trans i18nKey="dialogs.indexDelete.message">
                      You are deleting the index <Text weight="bold">{ name }</Text>.<br />
                      Continuing this action will result in data loss.
                    </Trans>
                  </Paragraph>
                  <Field
                    name="name"
                    type="text"
                    placeholder="Type the index's name to delete it"
                    validate={ validateName }
                    component={ InputField }
                  />
                </Column>
              </Dialog.Body>
              <Dialog.Footer>
                <Button type="button" color="neutral" variant="outlined" disabled={ submitting } onClick={ handleClose }>
                  { i18n.t('shared.cancel') }
                </Button>
                <Button color="danger" type="submit" loading={ submitting } disabled={ invalid || pristine } >
                  { i18n.t('shared.delete') }
                </Button>
              </Dialog.Footer>
            </form>
          </Dialog>
        );
      } }
    </Form>
  );
};

IndexDeleteDialog.ID = INDEX_DELETE_DIALOG_ID;

export { IndexDeleteDialog };
