import * as R from 'ramda';
import { matchPath } from 'react-router-dom';
export { AuthState } from '@8base/utils';

const getTagsByPathname = (data: Object, pathname: string) => R.pipe(
  R.pathOr([], ['routesList', 'items']),
  R.filter(({ path, exact, strict }) => matchPath(pathname, { path, exact, strict })),
  R.map(
    R.pathOr([], ['tags', 'items']),
  ),
  R.reduce(R.concat, []),
  R.uniqBy(R.prop('id')),
)(data);

export const getGuidesByPathname = (data: Object, pathname: string) => {
  const tags = getTagsByPathname(data, pathname);

  const guides = R.pipe(
    R.pathOr([], ['guidesList', 'items']),
    R.filter(R.pipe(
      R.pathOr([], ['tags', 'items']),
      R.any(({ id }) => R.any(R.propEq('id', id), tags)),
    )),
    R.sortWith([
      R.descend(R.prop('priority')),
    ]),
  )(data);

  return guides;
};

export const getVideosByPathname = (data: Object, pathname: string) => {
  const tags = getTagsByPathname(data, pathname);

  const videos = R.pipe(
    R.pathOr([], ['videosList', 'items']),
    R.filter(R.pipe(
      R.pathOr([], ['tags', 'items']),
      R.any(({ id }) => R.any(R.propEq('id', id), tags)),
    )),
    R.sortWith([
      R.descend(R.prop('priority')),
    ]),
  )(data);

  return videos;
};

export const checkIs8baseUser = (authState: AuthState) => {
  const email = R.propOr('', 'email', authState).toLowerCase();
  return ['@8base.com', '@8baselabs.com'].some(domain => email.endsWith(domain));
};
