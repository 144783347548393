import * as R from 'ramda';
import { tablesListSelectors, tableSelectors } from '@8base/utils';

export const isExistedField = (tablesList, tableId, fieldName) => {
  const [name, relationFieldName] = fieldName.split('.');

  const table = tablesListSelectors.getTableById(tablesList, tableId);

  if (!table) {
    return false;
  }

  const field = tableSelectors.getFieldByName(table, name);

  if (!field) {
    return false;
  }

  if (relationFieldName) {
    const relationTableId = R.path(['relation', 'refTable', 'id'], field);

    return isExistedField(tablesList, relationTableId, relationFieldName);
  }

  return true;
};
