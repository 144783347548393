// @flow
import { useQuery, type QueryHookOptions } from 'react-apollo';
import { useAuth } from '@8base-react/auth';
import * as R from 'ramda';

import { ORGANIZATION_PAYMENT_DETAILS_QUERY } from 'graphql/queries';
import { HIDE_TOAST_ERROR_MESSAGE, IGNORE_GRAPHQL_ERRORS } from 'common/constants/apolloOperationContextOptions';

// TODO: add types to query: QueryResult<Query, Variables>

const getCard = R.path(['system', 'organizationPaymentDetails']);

export const useOrganizationPaymentDetails = (organizationId?: string, options?: QueryHookOptions = {}) => {
  const { isAuthorized, isEmailVerified } = useAuth();

  const {
    data,
    loading,
  } = useQuery(ORGANIZATION_PAYMENT_DETAILS_QUERY, {
    ...options,
    skip: !isAuthorized || !isEmailVerified || !organizationId,
    variables: {
      ...options.variables,
      organizationId,
    },
    context: {
      ...options.context,
      [HIDE_TOAST_ERROR_MESSAGE]: true,
      [IGNORE_GRAPHQL_ERRORS]: true,
    },
  });

  return {
    loading,
    card: getCard(data),
  };
};
