// @flow

import gql from 'graphql-tag';

export const TeamMemberFragment = gql`
  fragment TeamMemberFragment on SystemEnvironmentMember {
    email
    status
    firstName
    lastName
    createdAt
    permissions {
      items {
        resource
        resourceType
        permission
      }
    }
    avatar {
      id
      downloadUrl
    }
    roles {
      items {
        id
        name
      }
    }
  }
`;
