// @flow

import * as R from 'ramda';
import { createSelector, type Selector } from 'reselect';

import type { ApolloApplication } from './application.selectors';

export type ApolloFunctionType = 'resolver' | 'webhook' | 'trigger' | 'task';

export type ApolloFunctionWebhookInfo = {
  httpMethod: string,
  workspaceRelativePath: string,
  workspaceFullPath: string,
};

export type ApolloFunctionTriggerInfo = {
  operation: string,
  tableName: string,
  type: string,
};

export type ApolloFunctionResolverInfo = {
  gqlType: string,
};

export type ApolloFunctionTaskInfo = {
  scheduleExpression: string,
};

export type ApolloFunctionInfo = {
  id: string,
  name: string,
  functionType: ApolloFunctionType,
  description: string,
  application: ApolloApplication,
} & (
  ApolloFunctionWebhookInfo |
  ApolloFunctionTriggerInfo |
  ApolloFunctionResolverInfo |
  ApolloFunctionTaskInfo
);

export const getFunctionInfo: Selector<
  ApolloFunctionInfo,
  any,
  ApolloFunctionInfo,
> = (functionInfo) => functionInfo;

export const getName: Selector<
  ApolloFunctionInfo,
  any,
  string,
> = createSelector(
  getFunctionInfo,
  R.prop('name'),
);

export const getFunctionType: Selector<
  ApolloFunctionInfo,
  any,
  ApolloFunctionType,
> = createSelector(
  getFunctionInfo,
  R.prop('functionType'),
);

export const getDescription: Selector<
  ApolloFunctionInfo,
  any,
  string,
> = createSelector(
  getFunctionInfo,
  R.prop('description'),
);

export const getWorkspaceRelativePath: Selector<
  ApolloFunctionInfo,
  any,
  string,
> = createSelector(
  getFunctionInfo,
  R.prop('workspaceRelativePath'),
);

export const getWorkspaceFullPath: Selector<
  ApolloFunctionInfo,
  any,
  string,
  > = createSelector(
    getFunctionInfo,
    R.prop('workspaceFullPath'),
  );

export const getHttpMethod: Selector<
  ApolloFunctionInfo,
  any,
  string,
> = createSelector(
  getFunctionInfo,
  R.prop('httpMethod'),
);

export const getScheduleExpression: Selector<
  ApolloFunctionInfo,
  any,
  string,
> = createSelector(
  getFunctionInfo,
  R.prop('scheduleExpression'),
);

export const getOperation: Selector<
  ApolloFunctionInfo,
  any,
  string,
> = createSelector(
  getFunctionInfo,
  R.prop('operation'),
);

export const getTableName: Selector<
  ApolloFunctionInfo,
  any,
  string,
> = createSelector(
  getFunctionInfo,
  R.prop('tableName'),
);

export const getType: Selector<
  ApolloFunctionInfo,
  any,
  string,
> = createSelector(
  getFunctionInfo,
  R.prop('type'),
);

export const getId: Selector<ApolloFunctionInfo, any, string> =
  createSelector(getFunctionInfo, R.prop('id'));

export const getApplication: Selector<ApolloFunctionInfo, any, ApolloApplication> =
  createSelector(getFunctionInfo, R.prop('application'));

