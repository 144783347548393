// @flow

const error = (message?: any, ...optionalParams: any[]) => {
  // eslint-disable-next-line no-console
  console.error(message, ...optionalParams);
};

const warn = (message?: any, ...optionalParams: any[]) => {
  // eslint-disable-next-line no-console
  console.warn(message, ...optionalParams);
};

const log = (message?: any, ...optionalParams: any[]) =>
  // eslint-disable-next-line no-console
  console.log(message, ...optionalParams);

const logger = {
  log,
  warn,
  error,
};

export {
  logger,
};
