// @flow
// $FlowFixMe
import React from 'react';
import styled from '@emotion/styled';
import { Heading } from '@8base/boost';

const WrapperTag = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

export const TransferPageWrapper = ({ beforeSlot, header, children }: *) => {
  return (
    <WrapperTag>
      { beforeSlot }

      <Heading type="h1">
        { header }
      </Heading>

      { children }
    </WrapperTag>
  );
};
