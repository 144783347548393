// @flow

import React from 'react';

import './TableWorker.scss';

type TableCellProps = {
  cellData: string,
  itemId: string,
  style: Object,
  columnWidth: number,
  onMouseEnter: () => void,
  onMouseLeave: () => void,
  children: React$Node,
  columnIndex: string,
  rowIndex: string,
  columnName: string,
}

class TableCellRenderer extends React.Component<*> {

  shouldComponentUpdate(nextProps: TableCellProps) {
    return (
      this.props.columnName !== nextProps.columnName ||
      this.props.cellData !== nextProps.cellData ||
      this.props.columnWidth !== nextProps.columnWidth ||
      this.props.itemId !== nextProps.itemId
    );
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

export class TableCell extends React.Component<TableCellProps> {
  render() {
    const { children, style, onMouseEnter, onMouseLeave, columnIndex, rowIndex } = this.props;

    return (
      <div
        styleName="row-cell"
        style={ style }
        onMouseEnter={ onMouseEnter }
        onMouseLeave={ onMouseLeave }
        { ...E2E.$props(`table.cell[${rowIndex}][${columnIndex}]`) }
      >
        <TableCellRenderer { ...this.props }>
          { children }
        </TableCellRenderer>
      </div>
    );
  }
}
