// @flow
import { useQuery, type QueryResult } from 'react-apollo';
import { useAuth } from '@8base-react/auth';
import * as R from 'ramda';

import { TEAM_MEMBER_QUERY } from 'graphql/queries';
import { useWorkspace } from 'providers/WorkspaceProvider';
import type { TeamMemberQuery, TeamMemberQueryVariables } from 'graphql/__generated__';

export const useTeamMember = (graphqlOptions: Object = {}) => {
  const auth = useAuth();
  const workspace = useWorkspace();

  const { data = {}, ...rest }: QueryResult<TeamMemberQuery, TeamMemberQueryVariables> = useQuery(TEAM_MEMBER_QUERY, {
    skip: !auth.isAuthorized || !auth.isEmailVerified || !workspace.id,
    ...graphqlOptions,
    onCompleted: data => {
      graphqlOptions.onCompleted && graphqlOptions.onCompleted(data && data.teamMember);
    },
  });

  const teamMember = R.pathOr({}, ['system', 'environmentMember'], data);

  return { teamMember, ...rest };
};
