// @flow
// $FlowFixMe
import React, { useCallback, useMemo } from 'react';
import { Dropdown, Icon, Menu, Column, Heading, Avatars, Row, Text } from '@8base/boost';
import styled from '@emotion/styled';
import { Trans } from 'utils/translate';

import workspaceDefaultLogo from 'images/workspace-default-logo.svg';
import { ORGANIZATION_ROLE } from 'common/constants/organization';

type DeveloperHomeOrganizationHeaderProps = {
  name: string;
  type?: string | null;
  description?: string | null;
  image?: string | null;
  userList: Object[];
  onClickSettings: () => void;
  onAddUserClick: () => void;
  onUserClick: () => void;
  onRemoveFromOrganization: () => void;
  permissionRole: string;
}

const organizationImageHeight = 60;

const MoreWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.COLORS.GRAY_30};
  border-radius: 4px;
  background: ${({ theme }) => theme.COLORS.WHITE};
`;

export const OrganizationInfo = styled(Column)`
  @media screen and (min-width: 991px) {
    flex: 1;
  }
`;

const MoreIcon = styled(Icon)`

  box-sizing: content-box;
  transform: rotate(90deg);
  transition: color .3s ease;

  &:hover {
    color: ${({ theme }) => theme.COLORS.BLUE_20}
  }
`;

const OrganizationImage = styled.img`
  flex-shrink: 0;

  object-fit: cover;
  min-width: ${organizationImageHeight}px;
  min-height: ${organizationImageHeight}px;
  max-width: ${organizationImageHeight}px;
  max-height: ${organizationImageHeight}px;
  border: 1px solid ${props => props.theme.COLORS.SECONDARY_BORDER_COLOR};
  border-radius: ${props => props.theme.SIZES.MAIN_BORDER_RADIUS};
`;

const StyledColumn = styled(Column)`
  height: 100%;
  width: 100%;
  min-width: 315px;

  background: #FFFFFF;

  padding: 20px;

  border-radius: 5px;
`;

const checkReadOnly = role => (
  role !== ORGANIZATION_ROLE.admin &&
  role !== ORGANIZATION_ROLE.owner &&
  role !== ORGANIZATION_ROLE.manager
);

const checkIsOwner = role => (
  role === ORGANIZATION_ROLE.owner
);

export const DeveloperHomeOrganizationHeader = ({
  name,
  type,
  description,
  userList,
  permissionRole,
  image,
  onClickSettings,
  onRemoveFromOrganization,
  onAddUserClick,
  onUserClick,
}: DeveloperHomeOrganizationHeaderProps) => {
  const readOnly = useMemo(() => {
    return checkReadOnly(permissionRole);
  }, [permissionRole]);

  const isOwner = useMemo(() => {
    return checkIsOwner(permissionRole);
  }, [permissionRole]);

  const handlerAddUserClick = useCallback(() => {
    if (checkReadOnly(permissionRole)) {
      return;
    }

    onAddUserClick();
  }, [onAddUserClick, permissionRole]);

  const handlerUserClick = useCallback(() => {
    if (checkReadOnly(permissionRole)) {
      return;
    }

    onUserClick();
  }, [onUserClick, permissionRole]);


  return (
    <StyledColumn
      alignItems="stretch"
      // { ...E2E.$props(`developerHome.workspaces.item[${workspace.name}]`) }
    >
      <Row gap="md" alignItems="center">
        <Column>
          <OrganizationImage src={ image || workspaceDefaultLogo } />
        </Column>
        <Column stretch>
          <Row flexWrap="wrap" justifyContent="between" stretch alignItems="center" >
            <OrganizationInfo gap="none">
              <Heading type="h3">
                { name }
              </Heading>
              <Text>
                { description }
              </Text>
            </OrganizationInfo>
            <Row alignItems="center">
              <Avatars
                size="sm"
                users={ userList }
                onAvatarsClick={ handlerUserClick }
                onCounterClick={ handlerUserClick }
                onPlusClick={ handlerAddUserClick }
                withPlusButton={ !readOnly }
              />

              <Dropdown defaultOpen={ false }>
                <Dropdown.Head stopClickPropagation >
                  <MoreWrapper>
                    <MoreIcon name="More" />
                  </MoreWrapper>
                </Dropdown.Head>
                <Dropdown.Body withPortal pin="right">
                  { ({ closeDropdown }) => (
                    <Menu>
                      <If condition={ !readOnly }>
                        <Menu.Item onClick={ () => {
                          closeDropdown();
                          onClickSettings();
                        } }>
                          <Text>
                            <Trans i18nKey="organization.settings">Settings</Trans>
                          </Text>
                        </Menu.Item>
                      </If>
                      <If condition={ !isOwner }>
                        <Menu.Item onClick={ () => {
                          closeDropdown();
                          onRemoveFromOrganization();
                        } }>
                          <Text>
                            <Trans i18nKey="organization.leave">Leave Organization</Trans>
                          </Text>
                        </Menu.Item>
                      </If>
                    </Menu>
                  ) }
                </Dropdown.Body>
              </Dropdown>
            </Row>
          </Row>
        </Column>
      </Row>
    </StyledColumn>
  );
};
