import gql from 'graphql-tag';

export const TEAM_INVITATION_ACCEPT_MUTATION = gql`
  mutation TeamInvitationAccept($data: SystemMemberInvitationAcceptInput!) {
    system {
      memberInvitationAccept(data: $data) {
        success
      }
    }
  }
`;

export const TEAM_INVITATION_CANCEL_MUTATION = gql`
  mutation TeamInvitationCancel($data: SystemMemberInvitationCancelInput!) {
    system {
      memberInvitationCancel(data: $data) {
        success
      }
    }
  }
`;

export const TEAM_INVITATION_RESEND_MUTATION = gql`
  mutation TeamInvitationResend($data: SystemMemberInvitationResendInput!) {
    system {
      memberInvitationResend(data: $data) {
        success
      }
    }
  }
`;
