// @flow
// $FlowFixMe waiting for update Flow
import React from 'react';

import {
  Button,
  Grid,
  Text,
} from '@8base/boost';

import { Trans } from 'utils/translate';

export const TransferAcceptActions = ({
  acceptLoading,
  paymentMethodError,
  onDecline,
  invalid,
  handleSubmit,
}: *) => {
  return (
    <React.Fragment>
      <If condition={ paymentMethodError }>
        <Text
          i18nKey="home.transferWorkspace.ownership.paymentCardErrorMessage"
          color="DANGER"
        >
          Specify payment card
        </Text>
      </If>
      <Grid.Layout stretch columns="140px 171px" justifyContent="end" gap="sm">
        <Grid.Box>
          <Button
            stretch
            color="neutral"
            variant="outlined"
            disabled={ acceptLoading }
            onClick={ onDecline }
          >
            <Trans i18nKey="shared.decline">Decline</Trans>
          </Button>
        </Grid.Box>
        <Grid.Box>
          <Button
            stretch
            color="primary"
            type="submit"
            disabled={ invalid }
            loading={ acceptLoading }
            onClick={ handleSubmit }
          >
            <Trans i18nKey="home.transferWorkspace.ownership.apply">Apply Transfer</Trans>
          </Button>
        </Grid.Box>
      </Grid.Layout>
    </React.Fragment>
  );
};
