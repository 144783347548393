// @flow
import React from 'react';
import styled from '@emotion/styled';

type AccountPlateProps = {
  nav: React$Node;
  content: React$Node;
}

export const AccountPlateTag = styled.div`
  display: grid;
  grid-template-columns: 232px 1fr;
  grid-template-areas: "secondaryNav content";
  height: calc(100vh - ${props => props.theme.SIZES.USER_FLOW.headerMdHeight});
`;

export const AccountPlate = ({ nav, content } :AccountPlateProps) => (
  <AccountPlateTag>
    { nav }
    { content }
  </AccountPlateTag>
);
