// @flow
'no babel-plugin-flow-react-proptypes';

import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { dataBuilderReducer as dataBuilder } from 'store/dataBuilder/store';

import type { DataBuilderState } from 'store/dataBuilder/store/dataBuilder.reducer';

export type CoreState = {
  form: any,
  dataBuilder: DataBuilderState,
};

const coreReducer = combineReducers({
  form: formReducer,
  dataBuilder,
});

export default coreReducer;
