// @flow
// $FlowFixMe
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '@8base-react/auth';

import { APP_URL, useBuildUrl } from 'common/routing';
import { useQueryParams } from 'utils/hooks';

export const TransferOwnershipContainer = () => {
  const [queryParams] = useQueryParams();
  const auth = useAuth();
  const buildUrl = useBuildUrl();

  if (!queryParams.id) {
    return <Redirect to={ buildUrl(APP_URL.transferIncorrect) } />;
  }

  window.trackEvent('System', 'Accept transfer to organization');

  if (auth.isAuthorized) {
    return (
      <Redirect
        to={ buildUrl(APP_URL.transferAccept, {
          pathParams: { id: queryParams.id },
        }) }
      />
    );
  }

  return <Redirect to={ buildUrl(APP_URL.authSignup) } />;
};
