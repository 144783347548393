// @flow

import React from 'react';

import './TableWorker.scss';

type TableHeadProps = {
  children: React$Node,
  width: number,
}

/** component render table head and provide feature to change horizontal scroll position */
export class TableHead extends React.Component<TableHeadProps> {
  render() {
    const { children, width } = this.props;

    return (
      <div styleName="head-wrapper" className="cell-editing-outside-click-ignore" style={{ width: `${width}px` }}>
        <div styleName="head">
          { children }
        </div>
      </div>
    );
  }
}
