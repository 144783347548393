// @flow
import { useQuery, type QueryHookOptions } from 'react-apollo';
import { useAuth } from '@8base-react/auth/dist';
import * as R from 'ramda';
// $FlowFixMe
import { useLocation, matchPath } from 'react-router-dom';

import { WORKSPACE_PAYMENT_DETAILS_QUERY } from 'graphql/queries';
import { APP_URL } from 'common/routing';
import { useWorkspace } from 'providers';

// TODO: add types to query: QueryResult<Query, Variables>

const getCard = R.path(['system', 'workspacePaymentDetails']);

// query should run only in workspace
const shouldSkipByUrl = (pathname: string, workspace) => {
  const match = matchPath(pathname, { path: APP_URL.workspaceHome, exact: false, strict: false });

  const isWorkspaceUrl = Boolean(match);

  if (isWorkspaceUrl) {
    const isOwner = R.propOr(false, 'isOwner', workspace);
    return !isOwner;
  }

  return true;
};

export const useWorkspacePaymentDetails = (options?: QueryHookOptions = {}) => {
  const { isAuthorized, isEmailVerified } = useAuth();
  const { pathname } = useLocation();
  const workspace = useWorkspace();

  const {
    data,
    loading,
  } = useQuery(WORKSPACE_PAYMENT_DETAILS_QUERY, {
    ...options,
    skip: shouldSkipByUrl(pathname, workspace) || !isAuthorized || !isEmailVerified || options.skip,
  });

  return {
    loading,
    card: getCard(data),
  };
};
