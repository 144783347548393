// $FlowFixMe waiting for update Flows
import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { AuthProvider, useAuth } from '@8base-react/auth';
import { Auth, AUTH_STRATEGIES } from '@8base/auth';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContextProvider } from 'react-dnd';
import { BoostProvider, createTheme, Loader } from '@8base/boost';
import { TableSchemaProvider } from '@8base-react/table-schema-provider';
import { PermissionsProvider } from '@8base-react/permissions-provider';
import { StripeProvider } from 'react-stripe-elements';

import { APP_URL } from 'common/routing';
import {
  ErrorBoundary,
  ToastNotification,
  RefreshNotification,
  WaitingComponent,
  AuthSessionChecker,
  ClickjackingProtector,
} from 'common';
import { SentryScope } from 'common/SentryScope';
import { TEAM_MEMBER_QUERY } from 'graphql/queries';
import {
  AsyncApolloProvider,
  AnalyticsProvider,
  BillingProvider,
  ReferralProvider,
  WorkspaceProxyProvider,
  useWorkspace,
} from 'providers';
import { getAuthDomain, getAuthClientID, getStripeApiKey } from 'utils/processEnv';
import { useUserAccountInfo, useWorkspacesList } from 'graphql/hooks';

import { i18n } from './i18n';
import { Routes } from './routes';
import * as icons from './icons';


import './productTour';
import './styles/main.scss';

import CliRoutes from './routes/cli';
import { IGNORE_WORKSPACE } from './common/constants/apolloOperationContextOptions';

const AuthRoute = lazy(() =>
  import(/* webpackChunkName: "auth" */ './routes/auth'),
);

const theme = createTheme({
  COLORS: {
    DARK_BLACK: '#000000',
  },
  SIZES: {
    USER_FLOW: {
      headerLgHeight: '80px',
      headerMdHeight: '56px',
    },
  },
});

const authClient = Auth.createClient({
  strategy: AUTH_STRATEGIES.WEB_AUTH0,
  subscribable: true,
}, {
  domain: getAuthDomain(),
  clientId: getAuthClientID(),
  redirectUri: `${window.location.origin}${APP_URL.authCallback}`,
  logoutRedirectUri: `${window.location.origin}${APP_URL.authLogin}`,
});

const OtherRoutes = () => {
  const { isAuthorized, isEmailVerified } = useAuth();
  const { id: workspaceId } = useWorkspace();
  const { loading: workspacesListLoading } = useWorkspacesList({
    notifyOnNetworkStatusChange: false,
  });
  const { loading: userAccountInfoLoading } = useUserAccountInfo({
    notifyOnNetworkStatusChange: false,
    context: {
      [IGNORE_WORKSPACE]: true,
      noBatch: true,
    },
  });

  return (
    <TableSchemaProvider
      notifyOnNetworkStatusChange={ false }
      skip={ !isAuthorized || !workspaceId || !isEmailVerified }
      context={{ noBatch: true }}
    >
      <PermissionsProvider
        customQuery={ TEAM_MEMBER_QUERY }
        notifyOnNetworkStatusChange={ false }
        skip={ !isAuthorized || !workspaceId || !isEmailVerified }
        context={{ noBatch: true }}
      >
        { ({ loading }) => (
          <If condition={ !loading && !workspacesListLoading && !userAccountInfoLoading }>
            <BillingProvider>
              <AnalyticsProvider>
                <Routes />
              </AnalyticsProvider>
            </BillingProvider>
          </If>
        ) }
      </PermissionsProvider>
    </TableSchemaProvider>
  );
};

const App = () => {
  return (
    <ClickjackingProtector>
      <BoostProvider icons={ icons } theme={ theme }>
        <BrowserRouter>
          <Suspense fallback={ <Loader /> }>
            <ErrorBoundary>
              <DragDropContextProvider backend={ HTML5Backend }>
                <StripeProvider apiKey={ getStripeApiKey() }>
                  <I18nextProvider i18n={ i18n }>
                    <ReferralProvider>
                      <AuthProvider authClient={ authClient }>
                        <AuthSessionChecker />
                        <WorkspaceProxyProvider>
                          <AsyncApolloProvider>
                            <SentryScope>
                              <Switch>
                                <Route path={ APP_URL.auth } render={ () => (
                                  <WaitingComponent>
                                    <AuthRoute />
                                  </WaitingComponent>
                                ) } />
                                <Route path={ APP_URL.cli } render={ () => (
                                  <CliRoutes />
                                ) } />
                                <Route component={ OtherRoutes } />
                              </Switch>
                              <ToastNotification />
                              <RefreshNotification />
                            </SentryScope>
                          </AsyncApolloProvider>
                        </WorkspaceProxyProvider>
                      </AuthProvider>
                    </ReferralProvider>
                  </I18nextProvider>
                </StripeProvider>
              </DragDropContextProvider>
            </ErrorBoundary>
          </Suspense>
        </BrowserRouter>
      </BoostProvider>
    </ClickjackingProtector>
  );
};

export default App;
