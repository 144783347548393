exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ValueComponentPill__value-component-margin___26Z7v {\n  display: inline-flex;\n  margin-top: 7px;\n  margin-left: 3px; }\n", ""]);

// exports
exports.locals = {
	"value-component-margin": "ValueComponentPill__value-component-margin___26Z7v"
};