import React from 'react';
import * as R from 'ramda';
import { Text } from '@8base/boost';

import { COLOR_BY_PLAN_NAME } from 'graphql/constants/billing';

export const PlanName = ({ plan, ...rest }) => (
  <Text
    { ...E2E.$props('planName') }
    color={ COLOR_BY_PLAN_NAME[R.prop('name', plan)] || 'GREEN' }
    weight="semibold"
    css={{ textTransform: 'uppercase', fontSize: 14, lineHeight: '20px' }}
    { ...rest }
  >
    { R.prop('displayName', plan) }
  </Text>
);
