// @flow

import React, { PureComponent, Component } from 'react';
import fp from 'lodash/fp';
import { wrapDisplayName } from 'recompose';
import hoistNonReactStatic from 'hoist-non-react-statics';


/** hoc add focus to redux-form component */
export const withFocus = () => {
  return (SourceComponent: *) => {
    class FocusComponent extends PureComponent<*> {
      inputEl: HTMLInputElement;
      static displayName = wrapDisplayName(SourceComponent, 'withFocus');

      getActiveProp = fp.get(['meta', 'active']);

      componentDidMount() {
        if (this.getActiveProp(this.props)) this.setFocus();
      }

      UNSAFE_componentWillReceiveProps(nextProps: *) {
        const active = this.getActiveProp(this.props);
        const nextActive = this.getActiveProp(nextProps);

        if (!active && nextActive) this.setFocus();
        if (active && !nextActive) this.unsetFocus();
      }

      setFocus() {
        this.inputEl && this.inputEl.focus();
      }

      unsetFocus() {
        this.inputEl && this.inputEl.blur();
      }

      /** hack to set cursor at the last point */
      onFocus = (event: Event) => {
        const { input } = this.props;
        input && input.onFocus && input.onFocus(event);

        const strLength = this.inputEl.value.length;
        this.inputEl.setSelectionRange(strLength, strLength);
      };

      getInputRef = (inputEl: HTMLInputElement) => {
        this.inputEl = inputEl;

        if (typeof this.props.inputRef === 'function') {
          this.props.inputRef(inputEl);
        }

        if (typeof this.props.insideRef === 'function') {
          this.props.insideRef(inputEl);
        }
      }

      render() {
        return (
          <SourceComponent
            { ...this.props }
            insideRef={ this.getInputRef }
            inputRef={ this.getInputRef }
            onFocus={ this.onFocus }
          />
        );
      }
    }

    hoistNonReactStatic(FocusComponent, SourceComponent);

    return FocusComponent;
  };
};

/**
 * interface of the enhanced props
 *
 * @prop {*} forceFocuse if true then reset focus on blur
 * @prop {*} active state of the element focused
 * @prop {*} innerRef callback to pass ref to parent component if it needed
 */
type WithFocuseBaseEnhancer = {
  forceFocus?: boolean,
  active?: boolean,
  innerRef?: (ref: HTMLElement) => void,
  onBlur?: (event?: Event) => void,
}

/** hoc add focus to custom component */
export const withFocusBase: HOC<*, WithFocuseBaseEnhancer> =
  (SourceComponent: *) =>
    class extends Component<*> {
      inputElem: HTMLElement;

      setInputRef = (inputElem: *) => {
        this.inputElem = inputElem;

        if (typeof this.props.inputRef === 'function') {
          this.props.inputRef(inputElem);
        }

        if (typeof this.props.insideRef === 'function') {
          this.props.insideRef(inputElem);
        }
      }

      hasActiveProp = fp.get('active');

      componentDidMount() {
        if (this.hasActiveProp(this.props)) this.setFocus();
      }

      componentDidUpdate(nextProps: WithFocuseBaseEnhancer) {
        const activeChangedToTruthy = !this.hasActiveProp(this.props) && this.hasActiveProp(nextProps);
        const activeChangedToFalsy = this.hasActiveProp(this.props) && !this.hasActiveProp(nextProps);

        if (activeChangedToTruthy) this.setFocus();
        if (activeChangedToFalsy) this.unsetFocus();
      }

      setFocus() {
        this.inputElem && this.inputElem.focus();
      }

      unsetFocus() {
        this.inputElem && this.inputElem.blur();
      }

      resetFocus = (event: Event) => {
        const { forceFocus, active, onBlur } = this.props;
        if (forceFocus && active) {
          this.setFocus();
          // event.preventDefault();
          // event.stopPropagation();
        }

        onBlur && onBlur(event);
      }

      render() {
        return (
          <SourceComponent
            { ...this.props }
            insideRef={ this.setInputRef }
            innerRef={ this.setInputRef }
            onBlur={ this.resetFocus } />
        );
      }
    };

