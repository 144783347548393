// @flow
// $FlowFixMe
import React, { useCallback } from 'react';
import { Row, styled, Loader, AsyncContent } from '@8base/boost';
import { css } from '@emotion/core';

import { Virtuoso } from 'react-virtuoso';

import { DeveloperHomeContentPlate } from '../../../components';
import { InboxListItem } from './InboxList.item';
import { InboxListEmpty } from './InboxList.empty';

const ListItem = styled.div`
  &:first-child {
    padding-top: 32px;
  }

  &:last-child {
    padding-bottom: 32px;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const Plate = styled(DeveloperHomeContentPlate)`
  padding: 0;

  @media screen and (max-width: 991px) {
    padding-top: 0;
  }
`;

export const InboxList = ({ hasMore, inboxList, loadMore, loading }: *) => {

  const renderListItem = useCallback(
    (index) => {
      const item = inboxList[index];
      if (!item || !item.id) {
        return <span />;
      }

      return <InboxListItem item={ item } />;
    },
    [inboxList],
  );

  const renderFooter = useCallback(() => {
    if (hasMore) {
      return (
        <Row stretch alignItems="center" css={ css`padding-bottom: 32px;` }>
          <Loader size="sm" stretch />
        </Row>
      );
    }
    return null;
  }, [hasMore]);

  return (
    <AsyncContent stretch loading={ loading }>
      <ListWrapper>
        <Virtuoso
          style={{ height: '100%', width: '100%' }}
          data={ inboxList }
          itemContent={ renderListItem }
          components={{ Footer: renderFooter, List: Plate, EmptyPlaceholder: InboxListEmpty, Item: ListItem }}
          endReached={ loadMore }
        />
      </ListWrapper>
    </AsyncContent>
  );
};
