// @flow
import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import styled from '@emotion/styled';

import left8 from 'images/assets/left-8.svg';
import { APP_URL } from 'common/routing';

import { TransferOwnershipContainer } from './routes/ownership';
import { TransferAcceptContainer } from './routes/accept';
import { TransferIncorrectContainer } from './routes/incorrect';
import { TransferCompletedContainer } from './routes/completed';

const GridTag = styled.div`
  height: 100%;

  display: grid;
  grid-template: 1fr / 1fr;
  justify-items: center;
  align-items: center;

  overflow: auto;
`;

const BackgroundTag = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  background:
    url(${left8}) no-repeat left 13.5px bottom 4.5px,
    linear-gradient(180deg, #F9FBFD 0%, #D8DEEC 100%);

  background-size: auto 80%, cover;
`;

// TODO: add pages for Expired Transfers
export const Transfer = () => (
  <BackgroundTag>
    <GridTag>
      <Switch>
        <Route exact path={ APP_URL.transferOwnership } component={ TransferOwnershipContainer } />
        <Route exact path={ APP_URL.transferAccept } component={ TransferAcceptContainer } />
        <Route exact path={ APP_URL.transferCompleted } component={ TransferCompletedContainer } />
        <Route exact path={ APP_URL.transferIncorrect } component={ TransferIncorrectContainer } />
        <Redirect to={ APP_URL.developerHome } />
      </Switch>
    </GridTag>
  </BackgroundTag>
);
