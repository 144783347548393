import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import throttle from 'lodash/throttle';
import { isEmpty } from 'ramda';

export const useQueryParams = (delay = 1000, initialValues = {}) => {
  const location = useLocation();
  const history = useHistory();
  const params = qs.parse(location.search.slice(1));

  React.useEffect(() => {
    if (isEmpty(params) && !isEmpty(initialValues) && !!initialValues) {
      history.replace({
        search: qs.stringify(initialValues),
      });
    }
  }, [params, initialValues, history]);

  const setQueryParams = React.useCallback(
    throttle((value, replace) => {
      history.replace({
        search: qs.stringify(
          replace
            ? value
            : {
              ...params,
              ...value,
            },
        ),
      });
    }, delay),
    [params, history],
  );

  return [params, setQueryParams];
};
