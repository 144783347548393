// @flow
// $FlowFixMe
import { useMemo } from 'react';
import workspaceDefaultLogo from 'images/workspace-default-logo.svg';
import * as R from 'ramda';
import type { OrganizationItemFullInfoFragment, SystemWorkspaceListResponse } from 'graphql/__generated__';

export const ORG_TYPE_COLOR_MAP = {
  agency: 'GREEN_40',
  company: 'BLUE_30',
  community: 'PURPLE_10',
  default: 'GREEN_40',
};

export const ORG_TYPE_STR_MAP = {
  agency: 'agency',
  company: 'company',
  community: 'community',
};

export const getOrganizationTypeColor = (type: string | null | typeof undefined) => {
  if (typeof type === 'string') {
    return ORG_TYPE_COLOR_MAP[type] || ORG_TYPE_COLOR_MAP.default;
  }
  return ORG_TYPE_COLOR_MAP.default;
};

export const getOrganizationTypeString = (type: string | null | typeof undefined) => {
  if (typeof type === 'string') {
    return ORG_TYPE_STR_MAP[type] || type;
  }

  return type;
};

interface useUserWorkspacesOfOrganizationProps {
  organization: OrganizationItemFullInfoFragment | null,
  workspacesList: SystemWorkspaceListResponse,
  userAccountInfo: Object,
}

export const useUserWorkspacesOfOrganization = ({ organization, workspacesList, userAccountInfo }: useUserWorkspacesOfOrganizationProps) => {
  const organizationUsers = R.pathOr([], ['users'], organization);
  const user = R.filter(R.propEq('email', userAccountInfo.email), organizationUsers);
  const userWorkspaces = R.pathOr([], [0, 'workspaces'], user);
  const userWorkspaceList = useMemo(() => {
    const workspacesIdList = (userWorkspaces.map(el => el.id)) || [];
    const list = workspacesList.items
      .filter(el => workspacesIdList.includes(el.id))
      .map(el => {
        return {
          ...el,
          srcUrl: (el.image && el.image.downloadUrl) || workspaceDefaultLogo,
          tooltipMessage: el.name,
        };
      });

    return list;
  }, [workspacesList.items, userWorkspaces]);

  return userWorkspaceList;
};
