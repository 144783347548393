// @flow
import React from 'react';
import styled from '@emotion/styled';
import { Grid } from '@8base/boost';

import { Link as RouterLink } from 'common/router';
import { UserFlowLogo } from './UserFlowLogo';
import { APP_URL } from 'common/routing';

type UserFlowHeaderProps = {
  leftContent?: React$Node;
  rightContent?: React$Node;
  centerContent?: React$Node;
  position?: 'absolute' | 'relative';
  size?: 'md' | 'lg';
};

const UserFlowHeaderWhiteTag = styled(Grid.Layout)`

  height: ${(props) => props.theme.SIZES.USER_FLOW.headerMdHeight};
  padding: 0 32px;

  background-color: #fff;
  border-bottom: 1px solid #E9EFF4;
  align-items: center;
  z-index: 3;

  @media (max-width: 767px) {
    padding: 0 2.4rem;
  }
`;

const UserFlowHeaderWhiteCenterContent = styled.div`
  justify-self: center;
`;

const UserFlowHeaderWhiteLeftContent = styled.div``;
const UserFlowHeaderWhiteRightContent = styled.div``;

export const UserFlowHeaderWhite = ({ leftContent, rightContent, centerContent, ...rest }: UserFlowHeaderProps) => {
  return (
    <UserFlowHeaderWhiteTag columns="auto auto 1fr auto" gap="lg" { ...rest }>
      <RouterLink to={ APP_URL.developerHomeWorkspaces }>
        <UserFlowLogo logoVariant="black" size="md" { ...E2E.$props('root.header.logo') } />
      </RouterLink>
      <UserFlowHeaderWhiteLeftContent>
        { leftContent || null }
      </UserFlowHeaderWhiteLeftContent>
      <UserFlowHeaderWhiteCenterContent>
        { centerContent || null }
      </UserFlowHeaderWhiteCenterContent>
      <UserFlowHeaderWhiteRightContent>
        { rightContent || null }
      </UserFlowHeaderWhiteRightContent>
    </UserFlowHeaderWhiteTag>
  );
};
