// @flow
// $FlowFixMe waiting for update Flow
import React, { useCallback, memo, useMemo } from 'react';
import { Dialog, Button, InputField, SelectField, Row, Text, AsyncContent, useModal, Column } from '@8base/boost';
import * as R from 'ramda';
import { DateTime } from 'luxon';
import { useMutation, useQuery } from 'react-apollo';
import { Form, Field } from '@8base-react/forms';
import { css } from '@emotion/core';

import { apolloSelectors, apolloTeamMemberSelectors } from 'graphql/selectors';
import { Trans } from 'utils/translate';
import { TEAM_MEMBER_QUERY, ROLES_LIST_QUERY } from 'graphql/queries';
import { TEAM_MEMBER_UPDATE_MUTATION } from 'graphql/mutations';
import { TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';
import { i18n } from 'i18n';
import { AvatarField } from 'pro';

type TeamMemberEditDialogProps = {
  onSuccess: () => void,
  onClose: () => void,
};

type TeamMemberEditFormProps = TeamMemberEditDialogProps & {
  teamMember: Object,
  teamMemberLoading: boolean,
  email: string,
  rolesData: any,
};

const TEAM_MEMBER_EDIT_DIALOG_ID = 'TEAM_MEMBER_EDIT_DIALOG_ID';

const getCreatedAtDate = (teamMember) => {
  const createdAt = apolloTeamMemberSelectors.getCreatedAt(teamMember);
  if (createdAt) {
    return DateTime.fromISO(createdAt).toFormat('MM/dd/yyyy');
  }
  return '';
};

const TeamMemberEditForm = ({
  onSuccess,
  onClose,
  teamMember,
  teamMemberLoading,
  email,
  rolesData,
}: TeamMemberEditFormProps) => {

  const [teamMemberUpdate] = useMutation(TEAM_MEMBER_UPDATE_MUTATION, {
    refetchQueries: ['TeamMembersList'],
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('dialogs.teamMemberEdit.successMessage'),
    },
  });

  const onSubmit = useCallback(async (data) => {
    window.trackEvent('Team Members', 'Update member');

    data = R.pick(['roles'], data);

    await teamMemberUpdate({
      variables: {
        data,
        filter: {
          email,
        },
      },
    });
  }, [email, teamMemberUpdate]);

  const initialValues = useMemo(() => {
    return R.pipe(
      R.defaultTo({}),
      R.pick(['firstName', 'lastName', 'roles', 'avatar']),
      R.evolve({
        roles: R.pipe(
          R.pathOr([], ['items']),
          R.map(({ id }) => id),
        ),
      }),
    )(teamMember);
  }, [teamMember]);

  const createdAt = getCreatedAtDate(teamMember);

  return (
    <Form
      onSubmit={ onSubmit }
      onSuccess={ onSuccess }
      initialValues={ initialValues }
    >
      { ({ handleSubmit, submitting, pristine, values }) => (
        <form onSubmit={ handleSubmit }>
          <Dialog.Header
            title={ i18n.t('dialogs.teamMemberEdit.title', { defaultValue: 'Team Member Edit' }) }
            onClose={ onClose }
          />
          <Dialog.Body padding="none">
            <AsyncContent stretch loading={ teamMemberLoading }>
              <Row gap="none" alignItems="stretch">
                <Column gap="none" alignItems="center" css={ css`padding: 20px; width: 200px;` }>
                  <Field name="avatar" component={ AvatarField } firstName={ values.firstName } lastName={ values.lastName } size="lg" />
                  <Text weight="semibold" align="center" css={ css`margin-top: 12px; font-size: 16px; word-break: break-all;` }>
                    { values.firstName } { values.lastName }
                  </Text>
                  { createdAt && (
                    <Text color="SECONDARY_TEXT_COLOR">Joined { createdAt }</Text>
                  ) }
                </Column>
                <Column css={ css`padding: 20px; width: 100%; border-left: 1px solid #e9eff4;` }>
                  <Row css={{ height: 'auto' }} stretch>
                    <Field
                      name="firstName"
                      label={ i18n.t('shared.firstName') }
                      placeholder={ i18n.t('shared.firstName') }
                      disabled
                      component={ InputField }
                    />
                    <Field
                      name="lastName"
                      label={ i18n.t('shared.lastName') }
                      placeholder={ i18n.t('shared.lastName') }
                      disabled
                      component={ InputField }
                    />
                  </Row>
                  <Field
                    name="roles"
                    label={ i18n.t('shared.roles') }
                    placeholder={ i18n.t('shared.roles') }
                    component={ SelectField }
                    options={ apolloSelectors.rolesList.getRolesListSelectOptions(rolesData) }
                    multiple
                  />
                </Column>
              </Row>
            </AsyncContent>
          </Dialog.Body>
          <Dialog.Footer>
            <Button
              type="button"
              color="neutral"
              variant="outlined"
              onClick={ onClose }
              disabled={ submitting }
            >
              <Trans i18nKey="shared.cancel" />
            </Button>
            <Button
              type="submit"
              disabled={ pristine || teamMemberLoading }
              loading={ submitting }
              onClick={ handleSubmit }
            >
              <Trans i18nKey="shared.update" />
            </Button>
          </Dialog.Footer>
        </form>
      ) }
    </Form>
  );
};

const MemoizedTeamMemberEditForm = memo(TeamMemberEditForm);

const getTeamMember = R.path(['system', 'environmentMember']);

export const TeamMemberEditDialog = () => {
  const { closeModal, args } = useModal(TEAM_MEMBER_EDIT_DIALOG_ID);
  const { data, loading } = useQuery(TEAM_MEMBER_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        email: args && args.email,
      },
    },
    skip: !args || !args.email,
  });

  const { data: rolesData, loading: rolesLoading } = useQuery(ROLES_LIST_QUERY, {
    skip: !args || !args.email,
  });

  const onSuccess = useCallback(() => {
    closeModal(TEAM_MEMBER_EDIT_DIALOG_ID);
  }, [closeModal]);

  const onClose = useCallback(() => {
    closeModal(TEAM_MEMBER_EDIT_DIALOG_ID);
  }, [closeModal]);

  return (
    <Dialog id={ TEAM_MEMBER_EDIT_DIALOG_ID } onClose={ onClose } size="xl">
      <MemoizedTeamMemberEditForm
        onSuccess={ onSuccess }
        onClose={ onClose }
        teamMember={ getTeamMember(data) }
        teamMemberLoading={ loading || rolesLoading }
        email={ args && args.email }
        rolesData={ rolesData }
      />
    </Dialog>
  );
};

TeamMemberEditDialog.ID = TEAM_MEMBER_EDIT_DIALOG_ID;

