// @flow
import * as R from 'ramda';
import { createSelector } from 'reselect';
import type { SelectDragListOption } from './SelectDragList';

/** this file contains all calculations of the data in the component  */

const getValues = (values: SelectDragListOption[]) => values;

/** return the true if item matches the search string */
const checkItemValue = (searchingLabel: string) => (item: SelectDragListOption) =>
  new RegExp(searchingLabel, 'i').test(item.label);

export const getFilteredItems = createSelector(
  getValues,
  (_, searchLabel: string) => searchLabel,
  (values, searchLabel) => R.filter(checkItemValue(searchLabel), values),
);

/** get item index in the array by the item id */
export const getIndexById = createSelector(
  getValues,
  (_, id: string) => id,
  (values, id) => R.findIndex(R.pipe(R.prop('id'), R.equals(id)), values),
);

/** update item checked state by the item id */
export const updateItemChecked = createSelector(
  getValues,
  getIndexById,
  (_, __, checkedState) => checkedState,
  (values, itemIndex, checkedState) => R.over(
    R.lensIndex(itemIndex),
    R.assoc('checked', checkedState),
    values,
  ),
);

type UpdateItemPositionParams = {
  dragId: string,
  hoverId: string,
}

/** move drag element to the hovered element position */
export const updateItemPosition = createSelector(
  getValues,
  (values, props : UpdateItemPositionParams) => getIndexById(values, props.dragId),
  (values, props : UpdateItemPositionParams) => getIndexById(values, props.hoverId),
  (values, dragIndex, hoverIndex) => R.move(dragIndex, hoverIndex, values),
);


export const checkAllItems = R.map(R.assoc('checked', true));

export const unCheckAllItems = R.map(R.assoc('checked', false));

export const getIsAllItemChecked = createSelector(
  getValues,
  R.pipe(
    R.map(R.prop('checked')),
    R.find(R.equals(false)),
    R.isNil,
  ),
);

export const getIsAllItemUnchecked = createSelector(
  getValues,
  R.pipe(
    R.map(R.prop('checked')),
    R.find(R.equals(true)),
    R.isNil,
  ),
);

const getUncheckedValues = createSelector(
  getValues,
  R.filter(R.pipe(R.prop('checked'), R.equals(false))),
);

export const hasItemUnchecked = createSelector(
  getValues,
  getUncheckedValues,
  (values, uncheckedValues) => uncheckedValues.length > 0 && uncheckedValues.length < values.length,
);
