import React, { useCallback } from 'react';
import * as R from 'ramda';
import { Dialog, Button, useModal } from '@8base/boost';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import errorCodes from '@8base/error-codes';
import { createTableRowDeleteTag, tableSelectors } from '@8base/utils';

import { i18n } from 'i18n';
import { ForcibleErrorDialog } from 'dialogs/ForcibleErrorDialog';
import { Trans } from 'utils/translate';

const RECORD_DELETE_DIALOG_ID = 'RECORD_DELETE_DIALOG_ID';

const RecordDeleteDialog = () => {
  const { closeModal, openModal, args: { id, tableSchema, onSuccess }} = useModal(RECORD_DELETE_DIALOG_ID);

  const onClose = useCallback(() => closeModal(RECORD_DELETE_DIALOG_ID), [closeModal]);

  const [deleteRecord, { loading: submitting }] = useMutation(gql(createTableRowDeleteTag([tableSchema], tableSchema.id)));

  const onDelete = useCallback(async () => {
    const toastId = Math.random();

    const identifiers = id instanceof Array ? id : [id];

    await Promise.all(identifiers.map((id) => deleteRecord({ variables: { filter: { id }}}).catch((err) => ({ id, err })))).then((errors) => {
      const forcibleErrors = R.filter(R.pathEq(['err', 'graphQLErrors', 0, 'code'], errorCodes.ForcibleErrorCode), errors);

      if (forcibleErrors.length > 0) {
        openModal(ForcibleErrorDialog.ID, {
          onSubmit: () => Promise.all(forcibleErrors.map(({ id }) => deleteRecord({ variables: { filter: { id }, force: true }}))),
          onSuccess: () => {
            onSuccess();

            window.toast.success(i18n.t('dataViewer.rowsSuccessfullyDeleled'), { toastId });
          },
        });
      } else {
        onSuccess();

        window.toast.success(i18n.t('dataViewer.rowsSuccessfullyDeleled'), { toastId });
      }
    });

    window.trackEvent('Data Viewer', 'Delete row');

    onClose();
  }, [onSuccess, id, deleteRecord, openModal, onClose]);

  const tableDisplayName = tableSelectors.getTableDisplayName(tableSchema);

  return (
    <Dialog id={ RECORD_DELETE_DIALOG_ID } size="sm" { ...E2E.$props('dialogs.recordDelete') }>
      <Dialog.Header title={ i18n.t('dialogs.recordDelete.title') } onClose={ onClose } />
      <Dialog.Body>
        <Trans i18nKey="dialogs.recordDelete.message">
          You are deleting values from the "{{ tableDisplayName }}" table. Continuing this action will result in data loss.
        </Trans>
      </Dialog.Body>
      <Dialog.Footer>
        <Button color="neutral" variant="outlined" onClick={ onClose }>
          <Trans i18nKey="shared.cancel">Cancel</Trans>
        </Button>
        <Button
          { ...E2E.$props('dialogs.recordDelete.submitButton') }
          type="submit"
          loading={ submitting }
          onClick={ onDelete }
          color="danger"
        >
          <Trans i18nKey="shared.delete">Delete</Trans>
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

RecordDeleteDialog.ID = RECORD_DELETE_DIALOG_ID;

export { RecordDeleteDialog };
