import React, { useCallback } from 'react';
import {
  Dialog,
  useModal,
  Button,
  Text,
} from '@8base/boost';

import { Trans } from 'utils/translate';
import { MEETING_LINK } from 'common/constants/links';

/**
 * Dialog where user can create organization
 */
export const CreateOrganizationDialog = () => {
  const { closeModal } = useModal(CreateOrganizationDialog.ID);

  const onClose = useCallback(() => {
    closeModal(CreateOrganizationDialog.ID);
  }, [closeModal]);

  return (
    <Dialog id={ CreateOrganizationDialog.ID } onClose={ onClose }>
      <Dialog.Header title="What are organizations?" onClose={ onClose } />
      <Dialog.Body >
        { /* TODO: add video | need link for src */ }
        <Text>
          <Trans i18nKey="createOrganizationDialog.content" >
            Organizations are a cool new feature that let Companies and Digital
            Agencies operate more efficiently and profitably when developing on 8base. Interested to learn more?
          </Trans>
        </Text>
      </Dialog.Body>
      <Dialog.Footer>
        <Button
          stretch
          tagName="a"
          href={ MEETING_LINK }
          target="_blank"
          rel="noopener noreferrer"
        >
          Talk to Us
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

CreateOrganizationDialog.ID = 'ORGANIZATION_CREATE_DIALOG';
