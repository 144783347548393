// @flow
import * as R from 'ramda';

export type CommonApolloData = $Shape<{
  [string]: Object | Array<any>,
  loading: boolean,
}>

export const getLoading: { (CommonApolloData): boolean } = R.propOr(false, 'loading');

