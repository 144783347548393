exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SelectDragList__select-drag-list___1CRUy {\n  margin-top: 0.8rem;\n  background: white;\n  width: 32rem; }\n  .SelectDragList__select-drag-list___1CRUy .card {\n    border: 1px solid #d0d7dd; }\n    .SelectDragList__select-drag-list___1CRUy .card .card-body {\n      padding: 0; }\n    .SelectDragList__select-drag-list___1CRUy .card .card-footer {\n      padding: 0.8rem 1.6rem;\n      font-size: 1.2rem;\n      line-height: 1.6; }\n\n.SelectDragList__search-panel___nL5fO {\n  padding: 0.8rem; }\n\n.SelectDragList__list-panel-scrollable___2HYlG {\n  overflow: auto;\n  max-height: 300px;\n  padding-bottom: 0.8rem; }\n", ""]);

// exports
exports.locals = {
	"select-drag-list": "SelectDragList__select-drag-list___1CRUy",
	"search-panel": "SelectDragList__search-panel___nL5fO",
	"list-panel-scrollable": "SelectDragList__list-panel-scrollable___2HYlG"
};