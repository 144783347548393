// @flow
// $FlowFixMe
import React, { useLayoutEffect } from 'react';
// $FlowFixMe
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/core';

import { Icon, Button, Text, Link } from '@8base/boost';

import { APP_URL } from 'common/routing';
import { SUPPORT_URL } from 'config/links';

import { TransferPageWrapper } from '../../../components';

const titleCss = css`
  max-width: 700px;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
`;

export const TransferIncorrectContainer = () => {
  const history = useHistory();

  const handleGoTo8base = () => history.push(APP_URL.developerHome);

  useLayoutEffect(() => {
    requestAnimationFrame(window.htmlLoader.hide);
  });

  return (
    <TransferPageWrapper
      beforeSlot={ (
        <Icon
          name="DangerAlertTransparent"
          size="custom"
          customSize="80px"
        />
      ) }
      header="Workspace transfer does not exist"
    >
      <Text css={ titleCss }>
        Looks like you are trying to access transfer that has already been accepted, run out of time or declined by Initiator
      </Text>

      <Button
        color="primary"
        onClick={ handleGoTo8base }
      >
        Go to 8base
      </Button>

      <Link
        href={ SUPPORT_URL }
        text="Contact support"
        target="_blank"
        rel="noopener noreferrer"
      />
    </TransferPageWrapper>
  );
};
