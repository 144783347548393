// @flow
// $FlowFixMe waiting for update Flow
import { useMutation } from 'react-apollo';

import { i18n } from 'i18n';

import { ABORT_TRANSFER_OWNERSHIP_MUTATION } from 'graphql/mutations';
import { TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';

const defaultOptions = {
  context: { [TOAST_SUCCESS_MESSAGE]: i18n.t('home.transferWorkspace.declineSuccessful', { defaultValue: 'Transfer declined' }) },
};

export const useAbortTransfer = ({ update }: * = {}) => {
  let options = { ...defaultOptions };

  if (update) {
    options = { ...options, update };
  }

  const [decline, { loading: declineLoading }] = useMutation(ABORT_TRANSFER_OWNERSHIP_MUTATION, options);

  return {
    decline,
    declineLoading,
  };
};
