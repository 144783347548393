// $FlowFixMe waiting for update Flow
import React, { useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import { Column } from '@8base/boost';

import { UserFlow } from 'common/UserFlow';
import { Trans } from 'utils/translate';
import * as LINKS from 'config/links';
import { ReactComponent as CliLogin } from 'images/8base-cli-login.svg';

const ExternalLink = styled('a')`
  color: #4DA1FF;
  text-decoration: none;

  &:visited: {
    color: #4DA1FF;
  }

  &:hover {
    text-decoration-color: #4DA1FF;
    text-decoration-line: underline;
    text-decoration-style: solid;
  }
`;

const CliLoginImage = styled(CliLogin)`
  position: relative;
  top: -64px;
  margin-bottom: -24px !important;
`;

export const Error = () => {
  useLayoutEffect(() => {
    requestAnimationFrame(window.htmlLoader.hide);
  });

  return (
    <UserFlow.Page { ...E2E.$props('cli.error.page') }>
      <UserFlow.Header position="absolute" transparent />
      <UserFlow.PageContent alignItems="center">
        <UserFlow.Paper style={{ padding: '24px', overflow: 'visible', width: '500px' }}>
          <Column alignItems="center" gap="none">
            <CliLoginImage />
            <Column alignItems="center" gap="none">
              <UserFlow.HeadingWhite style={{ marginBottom: '30px', textAlign: 'center' }}>
                <Trans i18nKey="cli.error.heading">
                  8base CLI Login Unsuccessful
                </Trans>
              </UserFlow.HeadingWhite>
              <UserFlow.TextWhite lineHeight="lg" style={{ fontSize: '16px', lineHeight: '29px', textAlign: 'center', marginBottom: '40px' }}>
                <Trans i18nKey="cli.error.body">
                  Please visit our <ExternalLink target="_blank" href={ LINKS.HELP_URL }>Help Center</ExternalLink> for additional information.
                  If you need immediate help with an urgent issue, please <ExternalLink href={ LINKS.SUPPORT_MAILTO_URL }>email us</ExternalLink>.
                </Trans>
              </UserFlow.TextWhite>
            </Column>
          </Column>
        </UserFlow.Paper>
      </UserFlow.PageContent>
    </UserFlow.Page>
  );
};
