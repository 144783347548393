// @flow

import React, { PureComponent } from 'react';
import * as R from 'ramda';
import fp from 'lodash/fp';
import { memoize } from 'lodash';
import { Grid, defaultCellRangeRenderer, ScrollSync, AutoSizer, CellMeasurerCache } from 'react-virtualized';
import {
  TableCell, TableContainer, TableHead, DropPlate,
  TableHeadCell, RowIndexCell, HeadIndexCell, AddRow, RowOverflow } from './components';
import {
  TABLE_ROW_HEIGHT,
  TABLE_BOTTOM_OFFSET,
  DEFAULT_COLUMN_WIDTH,
  INDEX_COLUMN_WIDTH,
  LAST_COLUMN_RIGHT_OFFSET,
} from './TableWorker.constants';


const ADDITIONAL_TOP_ROWS = 1;
const ADDITIONAL_BOTTOM_ROWS = 1;
const ADDITIONAL_COLUMNS = 1;

/**
 * interface of the column setting
 *
 * @prop {*} name unique column name
 * @prop {*} defaultWidth default column width
 * @prop {*} meta meta information about column
 */
export type TableColumnSetting = {
  name: string,
  defaultWidth?: number,
  meta: Object,
}

/**
 * interface of the data that should be render into the row
 */
type TableRowData = {
  id: string,
  [name: string]: string | number | Array<*> | Object,
}

/**
 * interface of the renderHeadCell function params
 */
export type TableWorkerHeadCellOptions = {
  meta: Object,
  columnWidth: number,
}

/**
 * interface of the renderCell function params
 *
 * @prop {*} columnWidth column width
 * @prop {*} itemId item row idential
 * @prop {*} cellData data to render in the cell
 * @prop {*} meta meta data about column
 */
export type TableWorkerCellOptions = {
  meta: Object,
  columnWidth: number,
  itemId: string,
  cellData: string | number | Array<*> | Object,
  columnIndex: number,
  rowIndex: number,
}

/**
 * interface of the TableWorker component
 *
 * @prop {*} columns list of the columns settings
 * @prop {*} tableData array of the data to render in the table
 * @prop {*} showLoader showing loader in the last row
 * @prop {*} disableAutoload when true then block onScrollEnding callback
 * @prop {*} renderCell callback to render cell
 * @prop {*} renderHeadCell callback to render head cell
 * @prop {*} onScrollEnding callback to autoload table data
 */
type TableWorkerProps = {|
  columns: TableColumnSetting[],
  onChangeColumns ?: (TableColumnSetting[]) => void,
  tableData: TableRowData[],
  selectedRows: string[],
  showLoader?: boolean,
  disableAutoload?: boolean,
  onRowSelectChange?: (fieldId: string[], selected: boolean) => void,
  onRowEditClick?: (fieldId: string) => void,
  renderCell: (options: TableWorkerCellOptions) => React$Node,
  renderHeadCell: (options: TableWorkerHeadCellOptions) => React$Node,
  onScrollEnding?: (lastItemId: string) => void,
  onAddRow?: () => void,
  withAddRow?: boolean,
  withAddButton?: boolean,
  withEditRow?: boolean,
  withSelection?: boolean,
  scrollToColumn?: string,
  totalRecordCount: number,
|}

type TableWorkerBaseState = {
  columnWidths: { [name: string]: number },
  hoveredRow: ?string,
}

/** map column settings array to the object with the column widths */
export const getColumnsWidths: { (Array<TableColumnSetting>): { [name: string]: number }} = fp.reduce(
  (accum: any, { defaultWidth, name }: {name: string, defaultWidth?: number}) => {
    return fp.set(name, defaultWidth || DEFAULT_COLUMN_WIDTH, accum);
  },
  {},
);

/** calculate table width by the widths of the all columns */
export const getTableWidth: { ({ [name: string] : number}): number } =
  fp.pipe(
    fp.reduce(
      (accum, width) => accum + width,
      0,
    ),
    width => width + INDEX_COLUMN_WIDTH,
  );

export const getIsRowSelected =
  (selectedRows?: string[] = [], fieldId: string) => fp.filter(fp.equals(fieldId), selectedRows).length > 0;

const getAllRowIds = fp.map(fp.get('id'));

export const getIsAllRowsSelected = (selectedRows?: string[] = [], tableData: TableRowData[]) => fp.pipe(
  getAllRowIds,
  fp.xor(selectedRows),
  fp.isEmpty,
)(tableData) && selectedRows.length > 0;


/** component of the large table with virtualized and autoload */
export class TableWorker extends PureComponent<TableWorkerProps, TableWorkerBaseState> {
  measurerCache: Object;
  grid: any;

  static defaultProps = {
    disableAutoload: false,
    showLoader: false,
    withAddRow: true,
    withAddButton: false,
    withEditRow: true,
    withSelection: true,
    selectedRows: [],
  }

  constructor(props: TableWorkerProps) {
    super(props);

    this.state = {
      columnWidths: getColumnsWidths(props.columns),
      hoveredRow: null,
    };

    this.measurerCache = new CellMeasurerCache({
      defaultWidth: 1000,
      minWidth: 500,
      fixedHeight: true,
    });
  }

  setGridRef = (ref: any) => {
    this.grid = ref;
  }

  UNSAFE_componentWillReceiveProps(nextProps: TableWorkerProps) {
    if (!fp.equals(nextProps.columns, this.props.columns)) {
      this.setState({ columnWidths: getColumnsWidths(nextProps.columns) });
    }
  }

  componentDidUpdate(prevProps: TableWorkerProps, prevState: TableWorkerBaseState) {
    if (prevState.columnWidths !== this.state.columnWidths) {
      this.grid.recomputeGridSize();
    }
  }

  hoverRow = fp.memoize((rowId: string) => () => this.setState({ hoveredRow: rowId }));
  unHoverRow = () => this.setState({ hoveredRow: null });

  changeColumnWidth = (columnName: string, columnWidth: number) => {
    const { columnWidths } = this.state;

    this.setState({ columnWidths: fp.set(columnName, columnWidth, columnWidths) });
  }

  onEndDrag = (columnName: string, columnWidth: number) => {
    const { onChangeColumns, columns } = this.props;

    const newColumns = R.map(
      ({ name, ...rest }) => columnName === name ? { ...rest, name, defaultWidth: columnWidth } : { name, ...rest },
      columns,
    );

    onChangeColumns && onChangeColumns(
      newColumns,
    );
  }

  /** need debounce for the perfomance */
  changeColumnWidthWithDebounce = fp.debounce(4,
    (columnName: string, columnWidth: number) => {
      this.changeColumnWidth(columnName, columnWidth);
    })

  onRowEditClick = fp.memoize((fieldId: string) => () => {
    const { onRowEditClick } = this.props;

    onRowEditClick && onRowEditClick(fieldId);
  })

  onRowSelectChange = fp.memoize(
    (fieldId) => (selected: boolean) => (
      this.props.onRowSelectChange && this.props.onRowSelectChange([fieldId], selected)
    ))

  onAllRowsSelectChange = (selected: boolean) => {
    const { tableData, selectedRows } = this.props;

    const allRowsIds = getAllRowIds(tableData);
    const isAllRowsSelected = getIsAllRowsSelected(selectedRows, tableData);

    this.props.onRowSelectChange && this.props.onRowSelectChange(allRowsIds, !isAllRowsSelected);
  }

  onScroll = (lastId: string) => {
    const { onScrollEnding, showLoader, disableAutoload } = this.props;

    if (onScrollEnding && !showLoader && !disableAutoload && !fp.isNil(lastId)) {
      onScrollEnding(lastId);
    }
  }

  onSectionRendered = (params: { rowStopIndex: number }) => {
    const { tableData } = this.props;
    const { rowStopIndex } = params;

    if (rowStopIndex + 20 > this.getRowCount()) {
      this.onScroll(
        fp.pipe(fp.last, fp.get('id'))(tableData),
      );
    }
  }

  getRowCount = () => {
    const { tableData } = this.props;

    return tableData.length + ADDITIONAL_TOP_ROWS + ADDITIONAL_BOTTOM_ROWS;
  }

  getColumnCount = () => {
    const { columns } = this.props;

    return columns.length + ADDITIONAL_COLUMNS;
  }

  getColumnWidth = ({ index: columnIndex }: *) => {
    const { columnWidths } = this.state;
    const column = this.getColumnSetting(columnIndex);

    return columnIndex === 0
      ? INDEX_COLUMN_WIDTH
      : columnWidths[column.name];
  }

  getRowHeight = ({ index: rowIndex }: *) => {
    const lastRowIndex = this.getRowCount() - 1;

    return lastRowIndex === rowIndex
      ? TABLE_BOTTOM_OFFSET
      : TABLE_ROW_HEIGHT;
  }

  getColumnIndexByName = (columnName: string) => {
    const { columns } = this.props;

    const index = columns.findIndex(({ name }) => columnName === name);

    return index >= 0 ? index + ADDITIONAL_COLUMNS : index;
  }

  getColumnSetting = (columnIndex: number): Object | TableColumnSetting => {
    const { columns } = this.props;

    if (columnIndex < ADDITIONAL_COLUMNS) {
      return {};
    }

    return columns[columnIndex - ADDITIONAL_COLUMNS] || {};
  }

  getRowData = (rowIndex: number) => {
    const { tableData } = this.props;
    const rowData = tableData[rowIndex - ADDITIONAL_TOP_ROWS];

    return rowData;
  }

  getCellData = ({ columnIndex, rowIndex }: *): any => {
    const column = this.getColumnSetting(columnIndex);
    const rowData = this.getRowData(rowIndex) || {};

    if (!column) {
      return {};
    }

    return rowData[column.name];
  }

  cellRenderer = ({ columnIndex, rowIndex, style }: *) => {
    const { renderCell, selectedRows, withEditRow, withSelection } = this.props;
    const { columnWidths, hoveredRow } = this.state;

    const column = this.getColumnSetting(columnIndex);
    const rowData = this.getRowData(rowIndex) || {};
    const cellData = this.getCellData({ columnIndex, rowIndex });
    const isRowSelected = getIsRowSelected(selectedRows, rowData.id);
    const isRowHovered = hoveredRow === rowData.id;

    return (
      <Choose>
        <When condition={ columnIndex === 0 }>
          <RowIndexCell
            style={ style }
            width={ INDEX_COLUMN_WIDTH }
            index={ rowIndex }
            onSelectChange={ this.onRowSelectChange(rowData.id) }
            onEditClick={ this.onRowEditClick(rowData.id) }
            onMouseEnter={ this.hoverRow(rowData.id) }
            onMouseLeave={ this.unHoverRow }
            isHovered={ isRowHovered }
            isSelected={ isRowSelected }
            withEditRow={ withEditRow }
            withSelection={ withSelection }
          />
        </When>
        <When condition={ !!column && rowIndex >= ADDITIONAL_TOP_ROWS }>
          <TableCell
            style={ style }
            itemId={ rowData.id }
            columnName={ column.name }
            columnIndex={ columnIndex }
            rowIndex={ rowIndex }
            cellData={ cellData }
            columnWidth={ columnWidths[column.name] }
            onMouseEnter={ this.hoverRow(rowData.id) }
            onMouseLeave={ this.unHoverRow }
          >
            { renderCell({
              meta: column.meta,
              columnWidth: columnWidths[column.name],
              itemId: rowData.id,
              cellData,
              columnIndex,
              rowIndex,
            }) }
          </TableCell>
        </When>
      </Choose>
    );
  };

  tableBottomRender = ({ key, style, plateWidth }: *) => {
    const { onAddRow, withAddRow } = this.props;
    const containerStyle = {
      ...style,
      marginBottom: '72px',
    };

    return (
      <If condition={ !!withAddRow }>
        <div key={ key } style={ containerStyle }>
          <AddRow onAddRow={ onAddRow } width={ plateWidth } />
        </div>
      </If>
    );
  }

  tableHeadRenderer = ({ key, width }: *) => {
    const {
      columns,
      tableData,
      selectedRows,
      renderHeadCell,
      withSelection,
    } = this.props;
    const { columnWidths } = this.state;
    const isAllRowsSelected = getIsAllRowsSelected(selectedRows, tableData);
    const indeterminate = selectedRows.length > 0 && !isAllRowsSelected;

    return (
      <TableHead width={ width }>
        <HeadIndexCell
          width={ INDEX_COLUMN_WIDTH }
          isSelected={ isAllRowsSelected }
          indeterminate={ indeterminate }
          onSelectChange={ this.onAllRowsSelectChange }
          withSelection={ withSelection }
        />
        { columns.map(({ meta, name }) => (
          <TableHeadCell
            key={ name }
            width={ columnWidths[name] }
            columnName={ name }
            onEndDrag={ this.onEndDrag }
          >{ renderHeadCell({
              meta,
              columnWidth: columnWidths[name],
            }) }
          </TableHeadCell>
        )) }
      </TableHead>
    );
  }

  cellRangeRenderer = memoize((plateWidth: number) => (options: *) => {
    const renderedCells = [];
    const rows = [];
    const lastRowIndex = this.getRowCount() - 1;
    const { selectedRows } = this.props;
    const { hoveredRow } = this.state;

    const rowStopIndex = options.rowStopIndex === lastRowIndex
      ? options.rowStopIndex - 1 : options.rowStopIndex;

    renderedCells.push(this.tableHeadRenderer({
      key: 'table-worker-head',
      width: plateWidth,
    }));


    for (let rowIndex = options.rowStartIndex; rowIndex <= options.rowStopIndex; rowIndex++) {
      const rowDatum = options.rowSizeAndPositionManager.getSizeAndPositionOfCell(rowIndex);
      const rowData = this.getRowData(rowIndex) || {};
      const isRowSelected = getIsRowSelected(selectedRows, rowData.id);
      const isRowHovered = hoveredRow === rowData.id;

      const style = {
        position: 'absolute',
        top: rowDatum.offset + options.verticalOffsetAdjustment,
        left: 0,
        height: rowDatum.size,
        width: plateWidth,
      };

      rows.push(
        <RowOverflow
          key={ `${rowIndex}-hover` }
          style={ style }
          isHovered={ isRowHovered }
          isSelected={ isRowSelected }
        />,
      );
    }

    renderedCells.push((
      <div key="table-worker-rows" className="rows">{ rows }</div>
    ));

    renderedCells.push(defaultCellRangeRenderer({
      ...options,
      rowStopIndex,
    }));

    const lastRowKey = `${lastRowIndex}-bottom-row`;
    const lastRowDatum = options.rowSizeAndPositionManager.getSizeAndPositionOfCell(lastRowIndex);
    const lastrowStyle = {
      height: lastRowDatum.size,
      top: lastRowDatum.offset + options.verticalOffsetAdjustment,
      left: 0,
      right: 0,
      position: 'absolute',
    };

    renderedCells.push(this.tableBottomRender({
      key: lastRowKey,
      style: lastrowStyle,
      parent: options.parent,
      plateWidth,
    }));

    return React.Children.toArray(renderedCells);
  });

  overscanIndicesGetter = ({
    cellCount,
    overscanCellsCount,
    startIndex,
    stopIndex,
  }: *) => {
    return {
      overscanStartIndex: Math.max(0, startIndex - overscanCellsCount),
      overscanStopIndex: Math.min(cellCount - 1, stopIndex + overscanCellsCount),
    };
  };

  render() {
    const {
      withAddButton,
      onAddRow,
      showLoader,
      selectedRows,
      totalRecordCount,
    } = this.props;
    const { columnWidths } = this.state;
    const tableWidth = getTableWidth(columnWidths) + LAST_COLUMN_RIGHT_OFFSET;
    const rowCount = this.getRowCount();
    const columnCount = this.getColumnCount();

    return (
      <DropPlate onChangeColumnWidth={ this.changeColumnWidthWithDebounce } tableWidth={ tableWidth }>
        <TableContainer
          onAddRow={ onAddRow }
          withAddButton={ withAddButton }
          showLoader={ showLoader }
          selectedRecordCount={ selectedRows.length }
          totalRecordCount={ totalRecordCount }
        >
          <ScrollSync>
            { ({ onScroll, scrollWidth }) => (
              <AutoSizer>
                { ({ height: plateHeight, width: plateWidth }) => (
                  <Grid
                    { ...{
                      columns: this.props.columns,
                      selectedRows: this.props.selectedRows,
                      showLoader: this.props.showLoader,
                      hoveredRow: this.state.hoveredRow,
                      columnWidths: this.state.columnWidths,
                    } }
                    ref={ this.setGridRef }
                    onScroll={ onScroll }
                    width={ plateWidth }
                    // Leave space for the footer
                    height={ plateHeight - 32 }
                    rowCount={ rowCount }
                    columnCount={ columnCount }
                    rowHeight={ this.getRowHeight }
                    columnWidth={ this.getColumnWidth }
                    cellRenderer={ this.cellRenderer }
                    cellRangeRenderer={ this.cellRangeRenderer(fp.max([plateWidth, scrollWidth])) }
                    onSectionRendered={ this.onSectionRendered }
                    overscanIndicesGetter={ this.overscanIndicesGetter }
                    overscanRowCount={ 15 }
                    overscanColumnCount={ 10 }
                  />
                ) }
              </AutoSizer>
            ) }
          </ScrollSync>
        </TableContainer>
      </DropPlate>
    );
  }
}

