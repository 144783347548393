// @flow

import fpUtils from 'utils/fp';
import { createSelector } from 'reselect';
import * as R from 'ramda';

import type { ApolloRolesListResponse } from './rolesList.selectors';

export type ApolloUser = $Shape<{|
  id: string,
  createdAt: string,
  updatedAt: string,
  email: string,
  firstName: string,
  lastName: string,
  cellPhone: string,
  workPhone: string,
  workPhoneExt: string,
  gender: string,
  birthday: string,
  language: string,
  timezone: string,
  avatar: ?Object,
  learningMode: boolean,
  roles: ApolloRolesListResponse,
  sentInvitations: *,
|}>;

export const getUser = (user: ApolloUser) => user;

export const getUserId = createSelector(getUser, fpUtils.safeGet('id'));
export const getUserFirstName = createSelector(getUser, fpUtils.safeGet('firstName'));
export const getUserLastName = createSelector(getUser, fpUtils.safeGet('lastName'));
export const getUserAvatar = createSelector(getUser, fpUtils.safeGet('avatar'));
export const getUserEmail = createSelector(getUser, fpUtils.safeGet('email'));
export const getUserCreatedAt = createSelector(getUser, fpUtils.safeGet('createdAt'));
export const getRoles = createSelector(getUser, user => fpUtils.safeGet('roles', user) || []);
export const getUserTimezone = createSelector(getUser, user => fpUtils.safeGet('timezone', user));
export const getLearningMode = createSelector(getUser, user => fpUtils.safeGet('learningMode', user));

export const getUserFullName = createSelector(
  getUserFirstName,
  getUserLastName,
  (firstName = '', lastName = '') => `${firstName} ${lastName}`,
);

export const getUserSelectOption = createSelector(
  getUserId,
  getUserEmail,
  getUserFullName,
  (id, email, fullName) => ({ value: id, label: `${fullName} (${email})` }),
);

export const getCreatedAt = createSelector(getUser, R.prop('createdAt'));
export const getUpdatedAt = createSelector(getUser, R.prop('updatedAt'));
export const getFirstName = createSelector(getUser, R.prop('firstName'));
export const getLastName = createSelector(getUser, R.prop('lastName'));

export const getFullName = createSelector(
  getFirstName,
  getLastName,
  (firstName = '', lastName = '') => `${firstName} ${lastName}`,
);
