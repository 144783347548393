// @flow
import { useQuery, type QueryHookOptions, type QueryResult } from 'react-apollo';
import { useAuth } from '@8base-react/auth/dist';

import { ENDPOINT_URI, HIDE_TOAST_ERROR_MESSAGE, IGNORE_WORKSPACE } from 'common/constants/apolloOperationContextOptions';
import { ORGANIZATIONS_LIST_QUERY } from 'graphql/queries';
import { getServerUrl } from 'utils/processEnv';

import type { OrganizationItemShortInfoFragment, OrganizationListQuery, OrganizationListQueryVariables } from '../__generated__';

export const useOrganizationsList = (options?: QueryHookOptions) => {
  const { isAuthorized, isEmailVerified } = useAuth();

  const {
    data,
    ...rest
  }: QueryResult<OrganizationListQuery, OrganizationListQueryVariables> = useQuery(ORGANIZATIONS_LIST_QUERY, {
    skip: !isAuthorized || !isEmailVerified,
    fetchPolicy: 'cache-first',
    context: {
      [HIDE_TOAST_ERROR_MESSAGE]: true,
      [IGNORE_WORKSPACE]: true,
      [ENDPOINT_URI]: getServerUrl(),
      noBatch: true,
    },
    ...options,
  });

  const organizationsList: {items: OrganizationItemShortInfoFragment[], count: number} =
    (data && data.system && data.system.organizationsListByUser) || { items: [], count: 0 };

  return {
    organizationsList, ...rest,
  };
};
