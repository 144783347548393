// @flow
import React from 'react';
import styled from '@emotion/styled';
import close from 'images/assets/close.svg';

const UserFlowCloseIconWrapper = styled('img')`
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
  height: 20px;
  width: 20px;
  color: #3E3558;

  @media (max-width: 767px) {
    height: 16px;
    width: 16px;
  }
`;

const UserFlowCloseIcon = (props: Object) => (
  <UserFlowCloseIconWrapper { ...props } src={ close } alt="" />
);

export { UserFlowCloseIcon };

