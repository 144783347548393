// @flow

// $FlowFixMe waiting for update Flow
import React, { useMemo, useCallback } from 'react';
import { Form as FormLogic, Field } from '@8base-react/forms';
import {
  AsyncContent,
  Dialog,
  Button,
  Text,
  Row,
  Column,
  useModal,
  SelectField,
} from '@8base/boost';
import { css } from '@emotion/core';
import { useQuery, useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { i18n } from 'i18n';
import * as R from 'ramda';

import { Trans } from 'utils/translate';
import { IGNORE_WORKSPACE, TOAST_SUCCESS_MESSAGE, ENDPOINT_URI } from 'common/constants/apolloOperationContextOptions';
import { GET_WORKSPACE_ENVIRONMENTS_ROLES_QUERY, ORGANIZATION_QUERY } from 'graphql/queries';
import { WORKSPACE_SHARE_WITH_ENVS_MUTATION } from 'graphql/mutations';
import { required } from 'utils/formValidations';
import { getUserRoleAtOrganization, checkManagerRole } from 'utils/organization';
import { useOrganization, useUserAccountInfo } from 'graphql/hooks';
import { ORGANIZATION_ROLE, FULL_ACCESS_ROLE_OPTION } from 'common/constants/organization';

const SHARE_WORKSPACE_DIALOG_ID = 'SHARE_WORKSPACE_DIALOG_ID';

const getEnvNameList = (data: any) => {
  if (data && data.system && data.system && data.system.getEnvironmentRoles) {
    const { getEnvironmentRoles } = data.system;
    return getEnvironmentRoles
      .filter(el => !el.exists)
      .filter(el => el.roles && el.roles.length)
      .map(el => el.environmentName);
  }

  return [];
};

const getEnvFieldList = (data: any) => {
  if (data && data.system && data.system && data.system.getEnvironmentRoles) {
    const { getEnvironmentRoles } = data.system;
    return getEnvironmentRoles
      .filter(el => !el.exists)
      .filter(el => el.roles && el.roles.length)
      .map(el => ({
        environmentId: el.environmentId,
        rolesOptions: el.roles.map(el => ({ value: el.id, label: el.name })),
      }));
  }

  return [];
};

const getEnvValues = (data: any) => {
  return getEnvFieldList(data).map(el => ({ environmentId: el.environmentId, roles: [] }));
};

const getManagerEnvValues = (data: any) => {
  return getEnvFieldList(data).map(el => ({ environmentId: el.environmentId, roles: FULL_ACCESS_ROLE_OPTION[0].value }));
};

/**
 * User list without access to workspace ?
 */
const getUserList = (organization: any, workspaceId: string) => {
  if (!workspaceId || !organization || !organization.users) {
    return [];
  }

  const getLabel = user => `${user.firstName} ${user.lastName} (${user.email})`;

  return organization && organization.users.map(el => ({ label: getLabel(el), value: el.email }));
};

/**
 * Dialog where user can add team member from organization to workspace
 */
export const ShareWorkspaceDialog = () => {
  const { closeModal, args } = useModal(SHARE_WORKSPACE_DIALOG_ID);

  const onClose = () => closeModal(SHARE_WORKSPACE_DIALOG_ID);

  const { userAccountInfo, loading: userInfoLoading } = useUserAccountInfo({
    notifyOnNetworkStatusChange: false,
    context: {
      [IGNORE_WORKSPACE]: true,
      noBatch: true,
    },
    fetchPolicy: 'cache-first',
  });

  const { organization, loading: organizationLoading } = useOrganization(args.organizationId);

  const [shareWorkspaceWithEnvs] = useMutation(WORKSPACE_SHARE_WITH_ENVS_MUTATION, {
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('shared.dataUpdateSuccessful'),
      [ENDPOINT_URI]: args.apiHost,
    },
  });

  const userRoleAtOrganization = useMemo(() =>
    getUserRoleAtOrganization(userAccountInfo, organization),
  [organization, userAccountInfo]);

  const onSubmit = async (formData) => {

    await shareWorkspaceWithEnvs({
      variables: {
        email: formData.email,
        environmentRoles: formData.environmentRoles,
        workspaceId: formData.workspaceId,
      },
      refetchQueries: organization && organization.id ?
        [{
          query: ORGANIZATION_QUERY,
          variables: {
            organizationId: organization && organization.id,
          },
        }] : [],
    });

    window.trackEvent('Share workspace', 'Invite member');

    onClose();
  };

  const validate = useCallback(values => {
    const errors = {};

    if (!values || !values.environmentRoles || !values.environmentRoles.length) {
      errors.environments = i18n.t('validation.required');
      return errors;
    }

    const { environmentRoles } = values;

    errors.environmentRoles = environmentRoles.map(env => {
      if (!env.roles || !env.roles.length) {
        return { roles: i18n.t('validation.required') };
      }
      return undefined;
    });

    return errors;
  }, []);

  return (
    <Dialog
      id={ SHARE_WORKSPACE_DIALOG_ID }
      onClose={ onClose }
      size="xl"
      { ...E2E.$props('settings.roles.editDialog.body') }
    >
      <FormLogic onSubmit={ onSubmit } validate={ validate } >
        { ({ handleSubmit, invalid, submitting, pristine, form, values }) => (
          <ShareWorkspaceDialogView
            handleSubmit={ handleSubmit }
            invalid={ invalid }
            submitting={ submitting }
            pristine={ pristine }
            form={ form }
            values={ values }
            organization={ organization }
            permissionRole={ userRoleAtOrganization }
            organizationLoading={ organizationLoading || userInfoLoading }
            onClose={ onClose }
            args={ args }
          />
        ) }
      </FormLogic>
    </Dialog>
  );
};

const ShareWorkspaceDialogView = ({
  handleSubmit,
  invalid,
  submitting,
  pristine,
  permissionRole,
  form,
  values,
  organization,
  organizationLoading,
  onClose,
  args,
}) => {
  const { t } = useTranslation();
  const headerTitle = useMemo(() => {
    return t('dialogs.shareWorkspace.header',
      {
        name: organization && organization.name,
        defaultValue: 'Invite team member of the {{name}} organization',
      },
    );
  }, [t, organization]);

  const organizationUsersList = R.pathOr([], ['users'], organization);

  const invitedUserProfileData = useMemo(() =>
    organizationUsersList.find(({ email }) => email === values.email) || { firstName: '', lastName: '', email: '', workspaces: [], role: '' }
  , [organizationUsersList, values.email]);

  const invitedUserRole = useMemo(() =>
    getUserRoleAtOrganization(invitedUserProfileData, organization),
  [organization, invitedUserProfileData]);

  const isInvitedUserManager = useMemo(() => checkManagerRole(invitedUserRole), [invitedUserRole]);

  const { data, loading } = useQuery(GET_WORKSPACE_ENVIRONMENTS_ROLES_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      workspaceId: args.workspaceId,
      email: values && values.email,
    },
    skip: !args || !args.workspaceId || !values || !values.email,
    onCompleted: (completedData => {
      form.initialize({
        email: values && values.email,
        environmentRoles: isInvitedUserManager ? getManagerEnvValues(completedData) : getEnvValues(completedData),
        workspaceId: args.workspaceId,
      });
    }),
    context: { [ENDPOINT_URI]: args.apiHost },
  });

  const userList = useMemo(() => {
    if (permissionRole === ORGANIZATION_ROLE.developer) {
      return [];
    }

    const list = getUserList(organization, args.workspaceId);

    if (permissionRole === ORGANIZATION_ROLE.manager) {
      return list.filter(el => el.role === ORGANIZATION_ROLE.developer);
    }

    return list;
  },
  [args.workspaceId, organization, permissionRole]);

  const envNameList = useMemo(() => getEnvNameList(data), [data]);
  const envFieldList = useMemo(() => getEnvFieldList(data), [data]);

  if (!organizationLoading && permissionRole === ORGANIZATION_ROLE.developer) {
    return (
      <React.Fragment>
        <Dialog.Header
          title={ headerTitle }
          onClose={ onClose }
        />
        <Dialog.Body>
          <Text weight="semibold" color="GRAY_50">
            <Trans i18nKey="dialogs.shareWorkspace.permissionDenied">
              You don’t have permission
            </Trans>
          </Text>
        </Dialog.Body>
        <Dialog.Footer>
          <Button type="button" color="neutral" variant="outlined" onClick={ onClose }>
            <Trans i18nKey="shared.cancel">Close</Trans>
          </Button>
        </Dialog.Footer>
      </React.Fragment>
    );
  }

  return (
    <>
      <Dialog.Header
        title={ headerTitle }
        onClose={ onClose }
      />
      <Dialog.Body>
        <Column stretch gap="md">
          <Row stretch>
            <Field
              name="email"
              label={ t('dialogs.shareWorkspace.teamMember.label', { defaultValue: 'Team Member' }) }
              placeholder={ t('dialogs.shareWorkspace.teamMember.placeholder', { defaultValue: 'Select team member' }) }
              component={ SelectField }
              options={ userList || [] }
              loading={ organizationLoading }
              validate={ required }
            />
          </Row>

          { values && values.email && (

            <AsyncContent loading={ loading } stretch>
              { envNameList.length === 0 && (
                <Row offsetY="md">
                  <Text>
                    <Trans i18nKey="dialogs.shareWorkspace.userInAllEnvironments">
                      This user exists in all environments to which you have access
                    </Trans>
                  </Text>
                </Row>
              ) }
              { envNameList.length > 0 && (
                <React.Fragment>
                  <Row stretch>
                    <Text weight="semibold" color="GRAY_50">
                      <Trans i18nKey="dialogs.shareWorkspace.environments">
                        ENVIRONMENTS
                      </Trans>
                    </Text>
                  </Row>

                  <Row stretch gap="md">
                    <Column
                      gap="md"
                      css={ css` width: 115px; padding-right: 16px; border-right: 1px solid #E8EFF5; ` }
                    >
                      { envNameList.map(el => (
                        <Row key={ el } css={ css` height: 36px; ` } alignItems="center">
                          { el }
                        </Row>
                      )) }
                    </Column>
                    <Column gap="md" stretch>
                      { envFieldList.map((el, index) => (
                        <Row key={ el.environmentId } stretch>
                          <Field
                            name={ `environmentRoles[${index}].roles` }
                            multiple={ !isInvitedUserManager }
                            options={ isInvitedUserManager ? FULL_ACCESS_ROLE_OPTION : el.rolesOptions }
                            component={ SelectField }
                            disabled={ isInvitedUserManager }
                          />
                        </Row>
                      )) }
                    </Column>
                  </Row>
                </React.Fragment>
              ) }

            </AsyncContent>
          ) }
        </Column>

      </Dialog.Body>
      <Dialog.Footer>
        <Button type="button" color="neutral" variant="outlined" onClick={ onClose }>
          <Trans i18nKey="shared.cancel">Cancel</Trans>
        </Button>
        <Button
          type="submit"
          disabled={ !isInvitedUserManager && (pristine || envNameList.length === 0) }
          loading={ submitting }
          onClick={ handleSubmit }
        >
          <Trans i18nKey="shared.share">onSubmit</Trans>
        </Button>
      </Dialog.Footer>
    </>
  );
};

ShareWorkspaceDialog.ID = SHARE_WORKSPACE_DIALOG_ID;
