// @flow

import React from 'react';
import * as R from 'ramda';
import { Dialog, Button, useModal } from '@8base/boost';
import { useMutation } from 'react-apollo';
import errorCodes from '@8base/error-codes';

import { i18n } from 'i18n';
import { ForcibleErrorDialog } from 'dialogs/ForcibleErrorDialog';
import { TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';

import { ROLE_DELETE_MUTATION } from 'graphql/mutations';

import { Trans } from 'utils/translate';

const ROLE_DELETE_DIALOG_ID = 'ROLE_DELETE_DIALOG_ID';

const RoleDeleteDialog = () => {
  const { closeModal, openModal } = useModal();

  const onClose = () => closeModal(ROLE_DELETE_DIALOG_ID);

  const [deleteRole, { loading: submitting }] = useMutation(ROLE_DELETE_MUTATION, {
    refetchQueries: ['RolesListWithUsers'],
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('settings.roles.roleIsSuccessfullyDeleted'),
    },
  });

  const createOnDelete = ({ id }) => async () => {
    window.trackEvent('Roles', 'Delete role');

    const identifiers = id instanceof Array ? id : [id];

    await Promise.all(identifiers.map((id) => deleteRole({ variables: { data: { id }}}).catch((err) => ({ id, err })))).then((errors) => {
      const forcibleErrors = R.filter(R.pathEq(['err', 'graphQLErrors', 0, 'code'], errorCodes.ForcibleErrorCode), errors);

      if (forcibleErrors.length > 0) {
        openModal(ForcibleErrorDialog.ID, {
          onSubmit: () => Promise.all(forcibleErrors.map(({ id }) => deleteRole({ variables: { data: { id }, force: true }}))),
        });
      }
    });

    onClose();
  };

  return (
    <Dialog id={ ROLE_DELETE_DIALOG_ID } size="sm" { ...E2E.$props('settings.roles.deleteDialog.body') }>
      { ({ args }) => (
        <React.Fragment>
          <Dialog.Header title={ i18n.t('settings.roles.deleteRole') } onClose={ onClose } />
          <Dialog.Body>
            <Trans i18nKey="settings.roles.wantToDeleteRole" count={ args.id.length || 1 }>
                Are you sure you want to delete this role?
            </Trans>
          </Dialog.Body>
          <Dialog.Footer>
            <Button color="neutral" variant="outlined" onClick={ onClose }>
              <Trans i18nKey="shared.cancel">Cancel</Trans>
            </Button>
            <Button
              { ...E2E.$props('settings.roles.deleteDialog.submitButton') }
              type="submit"
              loading={ submitting }
              onClick={ createOnDelete(args) }
              color="danger"
            >
              <Trans i18nKey="shared.delete">Delete</Trans>
            </Button>
          </Dialog.Footer>
        </React.Fragment>
      ) }
    </Dialog>
  );
};

RoleDeleteDialog.ID = ROLE_DELETE_DIALOG_ID;

export { RoleDeleteDialog };
