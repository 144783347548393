const PREFIX = 'APOLLO_OPERATION_CONTEXT';

export const HIDE_TOAST_ERROR_MESSAGE = `${PREFIX}_HIDE_TOAST_ERROR_MESSAGE`;
export const TOAST_SUCCESS_MESSAGE = `${PREFIX}_TOAST_SUCCESS_MESSAGE`;
export const TOAST_ERROR_MESSAGE_FORMATTER = `${PREFIX}_TOAST_ERROR_MESSAGE_FORMATTER`;
export const IGNORE_GRAPHQL_ERRORS = `${PREFIX}_IGNORE_GRAPHQL_ERRORS`;
export const DISABLE_TRACK_ERRORS = `${PREFIX}_DISABLE_TRACK_ERRORS`;
export const ERROR_PREFIX = `${PREFIX}_ERROR_PREFIX`;
export const FORCE_TOAST_ERROR_MESSAGE = `${PREFIX}_FORCE_TOAST_ERROR_MESSAGE`;
export const HEADERS = `${PREFIX}_HEADERS`;
export const LEARNING_CENTER = `${PREFIX}_LEARNING_CENTER`;
export const DISABLE_RETRY = `${PREFIX}_DISABLE_RETRY`;
export const DIALOG_ID = `${PREFIX}_DIALOG_ID`;
export const IGNORE_WORKSPACE = `${PREFIX}_IGNORE_WORKSPACE`;
export const IGNORE_LIMIT_METRIC_ERROR = `${PREFIX}_IGNORE_LIMIT_METRIC_ERROR`;
export const WORKSPACE_ID = `${PREFIX}_WORKSPACE_ID`;
export const ENDPOINT_URI = `${PREFIX}_ENDPOINT_URI`;
