const $id = (path, ...dynamicProps) => {
  let id = `${path}`;

  if (dynamicProps.length) {
    id = `${id}.${dynamicProps.join('.')}`;
  }

  return id;
};

window.PT = {
  $props: (path, ...dynamicProps) => ({ 'data-product-tour-id': $id(path, ...dynamicProps) }),
};
