// @flow

import React from 'react';
import { Dialog, Paragraph, Text, Column, useModal } from '@8base/boost';
import { useTranslation } from 'react-i18next';
import { CopyInput } from 'common/components/CopyInput';


export const API_TOKEN_SHOW_DIALOG_ID = 'API_TOKEN_SHOW_DIALOG';

const APITokenShowDialog = () => {
  const { closeModal } = useModal();
  const { t } = useTranslation();

  return (
    <Dialog id={ API_TOKEN_SHOW_DIALOG_ID }>
      { ({ args }) => (
        <React.Fragment>
          <Dialog.Header title={ t('settings.apiTokens.showDialog.title') } size="lg" onClose={ () => closeModal(API_TOKEN_SHOW_DIALOG_ID) } />
          <Dialog.Body>
            <Column justifyContent="center">
              <Paragraph>You have created the API Token <Text weight="bold">{ args.name }</Text>. For security purposes the token can only be displayed once, please copy the token displayed below.</Paragraph>
              <CopyInput
                value={ args.token }
                toastMessage={ t('settings.apiTokens.copySuccess') }
                tooltipMessage={ t('settings.apiTokens.copyApiToken') }
              />
            </Column>
          </Dialog.Body>
        </React.Fragment>
      ) }
    </Dialog>
  );
};

APITokenShowDialog.ID = API_TOKEN_SHOW_DIALOG_ID;

export { APITokenShowDialog };
