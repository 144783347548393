import gql from 'graphql-tag';

export * from './teamInvitations';
export * from './teamMembers';
export * from './users';
export * from './roles';
export * from './billing';
export * from './environments';
export * from './organizations';
export * from './trasnfer';
export * from './workspaces';
export * from './inbox';
export * from './prefinery';

export const API_TOKENS_LIST_QUERY = gql`
  query ApiTokensList {
    apiTokensList {
      items {
        id
        name
        roles {
          items {
            id
            name
          }
        }
      }
    }
  }
`;

export const API_TOKEN_QUERY = gql`
  query ApiToken($id: ID!) {
    apiToken(id: $id) {
      id
      name
      roles {
        items {
          id
          name
        }
      }
    }
  }
`;

export const ENVIRONMENT_VARIABLES_LIST_QUERY = gql`
  query EnvironmentVariablesList {
    environmentVariablesList {
      items {
        id
        name
        value
      }
    }
  }
`;
