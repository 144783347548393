// @flow
import React from 'react';
import { Column } from '@8base/boost';

import { BillingPaymentDetails } from './components/BillingPaymentDetails';
import { BillingHistory } from './components/BillingHistory';
import { Meeting } from './components/Meeting';
import { useOrganizationsList } from 'graphql/hooks';

export const Billing = () => {
  const { organizationsList, loading } = useOrganizationsList();

  const showMeeting = organizationsList.count === 0 && !loading;

  return (
    <Column alignItems="stretch" gap="lg">
      { showMeeting && <Meeting /> }
      <BillingPaymentDetails />
      <BillingHistory />
    </Column>
  );
};
