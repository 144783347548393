import gql from 'graphql-tag';

export const TABLE_FIELD_DELETE_MUTATION = gql`
  mutation TableFieldDelete($data: TableFieldDeleteInput!) {
    fieldDelete(data: $data) {
      success
    }
  }
`;

export const TABLE_UPDATE_MUTATION = gql`
  mutation TableUpdate($data: TableUpdateInput!) {
    tableUpdate(data: $data) {
      id
      name
      displayName
      description
    }
  }
`;
