// @flow
// $FlowFixMe
import { useEffect } from 'react';
import { DateTime } from 'luxon';
import { useAuth } from '@8base-react/auth';
import Cookies from 'js-cookie';

import { FULLSTORY_EVENT_TYPES, FS } from 'utils/fullStory';
import { useTeamMember, useUserAccountInfo } from 'graphql/hooks';
import { apolloTeamMemberSelectors } from 'graphql/selectors';
import { getCookieDomain } from 'utils/getCookieDomain';
import { useBillingInfo } from './BillingProvider/useBillingInfo';
import { IGNORE_WORKSPACE } from '../common/constants/apolloOperationContextOptions';
import { useQueryParams } from '../utils/hooks';

const VISITOR_ID_NAME = '8base-visitor-id';

export const AnalyticsProvider = ({ children }: *) => {
  const auth = useAuth();
  const { plan, loading: billingLoading, planDataLoading } = useBillingInfo();
  const { teamMember, loading: teamMemberLoading } = useTeamMember();
  const [queryParams] = useQueryParams();
  const { userAccountInfo, loading: userAccountInfoLoading } = useUserAccountInfo({
    context: {
      [IGNORE_WORKSPACE]: true,
      noBatch: true,
    },
  });

  useEffect(() => {
    if (auth.isAuthorized) {
      window.mixpanel && window.mixpanel.identify(auth.authState.userId);
    }
  }, [auth.authState.userId, auth.isAuthorized]);

  useEffect(() => {
    const domain = getCookieDomain();
    Cookies.set('8base-authenticated', String(auth.isAuthorized), { expires: 1, domain });
    const visitorId = Cookies.get(VISITOR_ID_NAME);

    FS.identify(visitorId);

    if (auth.isAuthorized) {
      FS.event(FULLSTORY_EVENT_TYPES.authorization);
    }
  }, [auth.isAuthorized]);


  useEffect(() => {
    const firstName = apolloTeamMemberSelectors.getFirstName(teamMember);
    const lastName = apolloTeamMemberSelectors.getLastName(teamMember);
    const email = apolloTeamMemberSelectors.getEmail(teamMember);
    const createdAt = apolloTeamMemberSelectors.getCreatedAt(teamMember);

    const shouldRecordEvent = teamMember
      && teamMemberLoading === false
      && billingLoading === false
      && planDataLoading === false
      && userAccountInfoLoading === false;

    if (shouldRecordEvent) {
      window.mixpanel && window.mixpanel.people.set({
        $first_name: firstName,
        $last_name: lastName,
        $email: email,
        $created: DateTime.fromISO(createdAt).toFormat('yyyy-MM-dd HH:mm:ss'),
      });

      FS.setUserVars({
        displayName: `${firstName} ${lastName}`,
        email,
        pricing_plan: plan && plan.displayName,
        is_developer: userAccountInfo.isDeveloper || undefined,
        utm_source: queryParams.utm_source || undefined,
        company_name: userAccountInfo.companyName || undefined,
        created_at: userAccountInfo.createdAt || undefined,
      });
    }
  }, [billingLoading, plan, planDataLoading, queryParams, teamMember, teamMemberLoading, userAccountInfo, userAccountInfoLoading]);


  return children;
};
