import React from 'react';
import { withAuth } from '@8base-react/auth';
import { compose } from 'recompose';
import { Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { withBuildUrl, APP_URL } from 'common/routing';
import { logger } from 'utils/logger';
import { withQueryParams } from 'utils/hocs';

const enhance = compose(
  withQueryParams,
  withAuth,
  withBuildUrl,
);

const CLI_REDIRECT_URI = `${window.location.origin}/cli/callback`;

export const CliContainer = enhance(
  class CliContainer extends React.Component {

    componentDidMount() {
      const {
        auth,
        queryParams: {
          guid,
        },
      } = this.props;

      if (guid) {
        auth.authClient.authorize({
          redirectUri: CLI_REDIRECT_URI,
          state: JSON.stringify({ guid }),
          responseType: 'token id_token code',
          scope: 'openid email profile offline_access',
        });
      } else {
        const error = new Error('CLI Auth: guid wan\'t provided');

        Sentry.captureException(error);
        logger.error(error);
      }
    }

    render() {
      const { queryParams: { guid }} = this.props;

      if (!guid) {
        return <Redirect to={ this.props.buildUrl(APP_URL.cleError) } />;
      }

      return null;
    }
  },
);

