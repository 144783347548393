import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Icon, Row } from '@8base/boost';
import { pipeClassNames, concatStyle, concatStylebyCond } from 'utils/styles';


const StyledIcon = styled(Icon)(props => ({
  transform: props.isOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
  transition: 'all .3s ease',
}));


const StyledIconRow = styled(Row)({
  width: '18px',
});


export class HeadNavigationLeftCommonHelper extends Component {
  static propTypes = {
    name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    activeNavName: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),

    children: PropTypes.node.isRequired,

    isSub: PropTypes.bool,
    className: PropTypes.object,
    disabled: PropTypes.bool,
    hovered: PropTypes.bool,

    onClick: PropTypes.func,
  };


  render() {
    const { children, activeNavName, name, disabled, isSub, onClick, isOpen, className, hovered } = this.props;

    const styleName = pipeClassNames(
      concatStyle('nav'),
      concatStylebyCond('sub', isSub),
      concatStylebyCond('disabled', disabled),
      concatStylebyCond('hovered', hovered),
      concatStylebyCond('active', activeNavName && activeNavName === name),
      concatStyle(className),
    );

    return (
      <div className={ styleName } onClick={ onClick } data-e2e-id={ this.props['data-e2e-id'] }>
        <Row gap="none">
          { children }
        </Row>
        <StyledIconRow justifyContent="center">
          <StyledIcon isOpen={ isOpen } name="ChevronDown" size="xs" />
        </StyledIconRow>
      </div>
    );
  }
}
