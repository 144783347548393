import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class CommonChildNavigationLeftCommonHelper extends Component {

  static propTypes = {
    children: PropTypes.node.isRequired,
    activeNavName: PropTypes.string,

    isOpen: PropTypes.bool,
  };

  static defaultProps = {
    isOpen: true,
  };


  render() {
    const { isOpen, children, activeNavName } = this.props;
    return (
      <If condition={ isOpen }>
        { React.Children.map(children, child => React.cloneElement(child, { activeNavName, isSub: true, ...child.props })) }
      </If>
    );
  }
}
