// $FlowFixMe waiting for update Flow
import React, { useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import { Column } from '@8base/boost';

import { UserFlow } from 'common/UserFlow';
import { Trans } from 'utils/translate';
import { ReactComponent as CliLogin } from 'images/8base-cli-login.svg';


const CliLoginImage = styled(CliLogin)`
  position: relative;
  top: -64px;
  margin-bottom: -24px !important;
`;

export const Success = () => {
  useLayoutEffect(() => {
    requestAnimationFrame(window.htmlLoader.hide);
  });

  return (
    <UserFlow.Page { ...E2E.$props('cli.success.page') }>
      <UserFlow.Header position="absolute" transparent />
      <UserFlow.PageContent alignItems="center">
        <UserFlow.Paper style={{ padding: '24px', overflow: 'visible', width: '500px' }}>
          <Column alignItems="center" gap="none">
            <CliLoginImage />
            <Column alignItems="center" gap="none">
              <UserFlow.HeadingWhite style={{ marginBottom: '30px', textAlign: 'center' }}>
                <Trans i18nKey="cli.success.heading">
                  8base CLI Login Successful
                </Trans>
              </UserFlow.HeadingWhite>
              <UserFlow.TextWhite lineHeight="lg" style={{ fontSize: '16px', lineHeight: '29px', textAlign: 'center', marginBottom: '40px' }}>
                <Trans i18nKey="cli.success.body">
                  You are logged in to the 8base Command-Line interface.<br />You can close this window and continue using the CLI.
                </Trans>
              </UserFlow.TextWhite>
            </Column>
          </Column>
        </UserFlow.Paper>
      </UserFlow.PageContent>
    </UserFlow.Page>
  );
};
