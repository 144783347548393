// @flow
// $FlowFixMe waiting for update Flow
import React from 'react';
import * as R from 'ramda';
import { css } from '@emotion/core';
import { Card, Heading, Button, Row, Table, AsyncContent, Text, useModal } from '@8base/boost';

import { Trans } from 'utils/translate';
import { UserPaymentDetailsUpdateDialog } from 'dialogs/BillingUpdateDialog/UserPaymentDetailsUpdateDialog';
import { BankCardRow } from 'common/components';
import { useUserPaymentDetails } from 'graphql/hooks';

/**
 * User payment details
 */
export const BillingPaymentDetails = () => {
  const { openModal } = useModal();

  const { loading, card } = useUserPaymentDetails();

  return (
    <Card>
      <Card.Header>
        <Row justifyContent="between" alignItems="center" stretch>
          <Heading type="h2" padding="none">
            <Trans i18nKey="settings.billing.paymentDetails.title">Payment Details</Trans>
          </Heading>
          <Button
            color="neutral"
            variant="outlined"
            onClick={ () => openModal(UserPaymentDetailsUpdateDialog.ID) }
            { ...E2E.$props('settings.billing.updatePaymentDetailsBtn') }
          >
            <Trans i18nKey="settings.billing.paymentDetails.buttonLabel">Update Payment Details</Trans>
          </Button>
        </Row>
      </Card.Header>
      <Card.Body padding="none">
        <AsyncContent loading={ loading } stretch>
          <Choose>
            <When condition={ !card }>
              <Text color="LIGHT_TEXT_COLOR" css={ css`display: flex; justify-content: center; padding: 40px 0;` }>
                <Trans i18nKey="settings.billing.paymentDetails.emptyNote">
                  You don’t have a saved payment method, continue above to update your payment details.
                </Trans>
              </Text>
            </When>
            <Otherwise>
              <Table>
                <Table.Body>
                  <Table.BodyRow columns="200px 1fr 2fr">
                    <Table.BodyCell>
                      <BankCardRow card={ card } />
                    </Table.BodyCell>
                    <Table.BodyCell { ...E2E.$props('settings.billing.cardNumber') }>
                      •••• •••• •••• { R.prop('last4', card) }
                    </Table.BodyCell>
                  </Table.BodyRow>
                </Table.Body>
              </Table>
            </Otherwise>
          </Choose>
        </AsyncContent>
      </Card.Body>
    </Card>
  );
};

