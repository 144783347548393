const $id = (e2ePath, ...dynamicProps) => {
  let id = `${e2ePath}`;

  if (dynamicProps.length) {
    id = `${id}.${dynamicProps.join('.')}`;
  }

  return id;
};

const E2E = {
  $id,
  $: (e2ePath, ...dynamicProps) => `[data-e2e-id="${$id(e2ePath, ...dynamicProps)}"]`,
  $x: (e2ePath, ...dynamicProps) => `//*[@data-e2e-id="${$id(e2ePath, ...dynamicProps)}"]`,
  $props: (e2ePath, ...dynamicProps) => ({ 'data-e2e-id': $id(e2ePath, ...dynamicProps) }),
};

export { E2E };
