exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".InputHelper__dnd-preview___1Bq7Y {\n  height: 36px;\n  width: 100%;\n  border: 1px dashed #d0d7dd;\n  border-radius: 0.4rem;\n  background-color: #fbfcfc; }\n\n.InputHelper__dnd-drag-preview___2sFui {\n  width: 40rem;\n  height: 36px;\n  padding: 0.8rem 1.6rem;\n  padding-left: 4.4rem;\n  line-height: 1.7;\n  background: white;\n  border: 1px solid #4da1ff;\n  border-radius: 0.4rem;\n  z-index: 10;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  -webkit-transform: rotate(-5deg);\n          transform: rotate(-5deg); }\n\n.InputHelper__dnd-helper___37wNY {\n  color: #d0d7dd;\n  cursor: move;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center; }\n  .InputHelper__dnd-helper___37wNY:hover {\n    color: #a4b1bd; }\n", ""]);

// exports
exports.locals = {
	"dnd-preview": "InputHelper__dnd-preview___1Bq7Y",
	"dnd-drag-preview": "InputHelper__dnd-drag-preview___2sFui",
	"dnd-helper": "InputHelper__dnd-helper___37wNY"
};