// @flow
import { useMutation } from 'react-apollo';
import * as R from 'ramda';

import { USER_PAYMENT_DETAILS_QUERY, WORKSPACE_PAYMENT_DETAILS_QUERY } from 'graphql/queries';
import { USER_PAYMENT_DETAILS_UPDATE_MUTATION } from 'graphql/mutations';
import { FS, FULLSTORY_EVENT_TYPES } from 'utils/fullStory';

const getNewUserPaymentDetails = R.pipe(
  R.pathOr({}, ['system', 'memberPaymentDetailsUpdate']),
  R.pick(['expYear', 'expMonth', 'last4', 'brand']),
);

/**
 * Update user payment method with apollo cache
 * */
const updateCacheUserCard = (cache, data) => {

  const newMemberPaymentDetails = getNewUserPaymentDetails(data);

  const newData = {
    system: {
      memberPaymentDetails: {
        ...newMemberPaymentDetails,
        __typename: 'SystemPaymentDetailsResponse',
      },
    },
  };

  try {
    const prevData = cache.readQuery({
      query: USER_PAYMENT_DETAILS_QUERY,
    });

    const mergedData = R.mergeDeepRight(prevData, newData);

    cache.writeQuery({
      query: USER_PAYMENT_DETAILS_QUERY,
      data: mergedData,
    });
  } catch (err) {}
};

/**
 * Update personal workspace payment method in apollo cache
 */
const updateCacheWorkspaceCard = (cache, data) => {
  try {
    const prevData = cache.readQuery({
      query: WORKSPACE_PAYMENT_DETAILS_QUERY,
    });

    if (!prevData) {
      return;
    }

    const paymentOrigin = R.path(['system', 'workspacePaymentDetails', 'origin'], prevData);

    // update user card only in case when workspace bellow to user
    if (paymentOrigin !== 'member') {
      return;
    }

    const newPaymentDetails = getNewUserPaymentDetails(prevData);

    const newData = {
      system: {
        workspacePaymentDetails: {
          ...newPaymentDetails,
          __typename: 'SystemPaymentDetailsResponse',
        },
      },
    };

    const mergedData = R.mergeDeepRight(prevData, newData);

    cache.writeQuery({
      query: WORKSPACE_PAYMENT_DETAILS_QUERY,
      data: mergedData,
    });
  } catch (err) {}
};

export const useUserPaymentDetailsUpdate = () => {
  const updateCardMutation = useMutation(USER_PAYMENT_DETAILS_UPDATE_MUTATION, {
    update: (cache, { data }) => {
      updateCacheUserCard(cache, data);
      updateCacheWorkspaceCard(cache, data);
    },
    onCompleted: (data) => {
      if (!!data) {
        FS.event(FULLSTORY_EVENT_TYPES.enterCreditCard);
      }
    },
  });

  return updateCardMutation;
};
