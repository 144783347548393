import React from 'react';
import { Select } from '@8base/boost';
import { withRouter } from 'react-router-dom';

class RouteSelect extends React.Component {
  onChange = (value) => {
    const { history } = this.props;

    history.push(value);
  };

  collectProps() {
    const { location: { pathname }} = this.props;

    return {
      ...this.props,
      onChange: this.onChange,
      value: pathname,
    };
  }

  render() {
    const collectedProps = this.collectProps();

    return <Select { ...collectedProps } searchable={ false } />;
  }
}

RouteSelect = withRouter(RouteSelect);

export { RouteSelect };
