import React, { useCallback, useRef, useEffect } from 'react';
import * as R from 'ramda';
import * as monaco from 'monaco-editor';
import { Dialog, Button, useModal, AsyncContent } from '@8base/boost';
import { createTableRowQueryTag, tableFieldSelectors } from '@8base/utils';
import { useTablesList } from '@8base-react/table-schema-provider';
import { SchemaNameGenerator } from '@8base/schema-name-generator';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { css } from '@emotion/core';

import { i18n } from 'i18n';

import { Trans } from 'utils/translate';

const MONACO_EDITOR_GLOBAL_CLASS_NAME = 'cwMonacoEditor';

const monacoEditorContainerClassName = css`
  height: 300px;

  .${MONACO_EDITOR_GLOBAL_CLASS_NAME} {
    .margin {
      background-color: #F4F7F9;
    }
  }
`;

const VIEW_JSON_FIELD_DIALOG_ID = 'VIEW_JSON_FIELD_DIALOG_ID';

const ViewJSONFieldDialogBody = ({ tableId, tableName, fieldName, fieldId, recordId }) => {
  const editorRef = useRef(null);

  const { tablesList } = useTablesList();

  const { data, loading } = useQuery(gql(createTableRowQueryTag(tablesList, tableId, {
    filterFields: R.pipe(
      tableFieldSelectors.getFieldId,
      R.equals(fieldId),
    ),
  })), {
    variables: { id: recordId },
  });

  useEffect(() => {
    if (editorRef.current && !loading) {
      const editor = monaco.editor.create(editorRef.current, {
        language: 'json',
        minimap: {
          enabled: false,
        },
        extraEditorClassName: MONACO_EDITOR_GLOBAL_CLASS_NAME,
        scrollBeyondLastLine: false,
        overviewRulerBorder: false,
        occurrencesHighlight: false,
        selectOnLineNumbers: false,
        matchBrackets: false,
        iconsInSuggestions: false,
        hideCursorInOverviewRuler: true,
        lineNumbersMinChars: 3,
        folding: false,
        fontSize: 14,
        fontFamily: 'Courier',
        lineHeight: 28,
        readOnly: true,
      });

      editorRef.current.editor = editor;

      editor.setValue(JSON.stringify(R.path([SchemaNameGenerator.getTableItemFieldName(tableName), fieldName], data), null, 2));
    }
  }, [editorRef, loading, data, fieldName, tableName]);

  return (
    <AsyncContent loading={ loading } stretch>
      <div css={ monacoEditorContainerClassName } ref={ editorRef } { ...E2E.$props('dialogs.viewJSONField.editor') } />
    </AsyncContent>
  );
};

const ViewJSONFieldDialog = () => {
  const { closeModal, args: { recordId, fieldSchema }} = useModal(VIEW_JSON_FIELD_DIALOG_ID);

  const { loading } = useTablesList();

  const tableId = tableFieldSelectors.getTableId(fieldSchema);
  const tableName = tableFieldSelectors.getTableName(fieldSchema);
  const fieldId = tableFieldSelectors.getFieldId(fieldSchema);
  const fieldName = tableFieldSelectors.getFieldName(fieldSchema);
  const fieldDisplayName = tableFieldSelectors.getFieldDisplayName(fieldSchema);

  const onClose = useCallback(() => closeModal(VIEW_JSON_FIELD_DIALOG_ID), [closeModal]);

  return (
    <Dialog id={ VIEW_JSON_FIELD_DIALOG_ID } size="lg">
      <Dialog.Header title={ i18n.t('dialogs.viewJSONField.title', { fieldDisplayName }) } onClose={ onClose } />
      <Dialog.Body css={{ overflow: 'hidden' }}>
        <AsyncContent loading={ loading } stretch>
          <ViewJSONFieldDialogBody tableId={ tableId } tableName={ tableName } fieldId={ fieldId } recordId={ recordId } fieldName={ fieldName } />
        </AsyncContent>
      </Dialog.Body>
      <Dialog.Footer>
        <Button onClick={ onClose }>
          <Trans i18nKey="shared.close">Close</Trans>
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

ViewJSONFieldDialog.ID = VIEW_JSON_FIELD_DIALOG_ID;

export { ViewJSONFieldDialog };
