// @flow
import React from 'react';
import {
  Dialog,
  Button,
  Text,
} from '@8base/boost';

import { Trans } from 'utils/translate';
import { TRANSFER_STATUSES } from 'common/constants/organization';

export const TransferWorkspaceAbort = ({
  workspaceName,
  transferStatus,
  onClose,
  onAbort,
  abortLoading,
  newOwnerName,
}: *) => {
  if (transferStatus === TRANSFER_STATUSES.accepted) {
    return (
      <>
        <Dialog.Body >
          <Text>
            <Trans i18nKey="transferWorkspaceDialog.abort.status.accepted">
              The <Text weight="semibold">{ workspaceName }</Text> workspace transfer has accepted.
            </Trans>
          </Text>
        </Dialog.Body>
        <Dialog.Footer>
          <Button
            color="neutral"
            variant="outlined"
            onClick={ onClose }
          >
            <Trans i18nKey="shared.close" >
              Close
            </Trans>
          </Button>
        </Dialog.Footer>
      </>
    );
  }

  return (
    <>
      <Dialog.Body >
        <Text>
          <Trans i18nKey="transferWorkspaceDialog.abort.label">
            The workspace <Text weight="bold">{ workspaceName }</Text> is being transferred to <Text weight="bold">{ newOwnerName }</Text>.
          </Trans>
        </Text>
      </Dialog.Body>
      <Dialog.Footer>
        <Button
          color="neutral"
          variant="outlined"
          disabled={ abortLoading }
          onClick={ onClose }
        >
          <Trans i18nKey="shared.cancel" >
            Cancel
          </Trans>
        </Button>
        <Button
          onClick={ onAbort }
          loading={ abortLoading }
        >
          <Trans i18nKey="transferWorkspaceDialog.abort.button" >
            Abort
          </Trans>
        </Button>
      </Dialog.Footer>
    </>
  );
};
