exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ConfirmEnter__popup-head___19JAw {\n  width: 100%;\n  display: flex;\n  flex-wrap: nowrap;\n  justify-content: space-between;\n  align-items: center; }\n  .ConfirmEnter__popup-head___19JAw .ConfirmEnter__popup-title___1uL27 {\n    display: flex;\n    flex-wrap: nowrap;\n    align-items: center; }\n    .ConfirmEnter__popup-head___19JAw .ConfirmEnter__popup-title___1uL27 span {\n      color: #eb4235;\n      font-size: 2.4rem;\n      margin-right: 1.6rem; }\n    .ConfirmEnter__popup-head___19JAw .ConfirmEnter__popup-title___1uL27 h3 {\n      font-size: inherit;\n      font-family: \"Poppins\", sans-serif;\n      font-weight: bold; }\n\nform .ConfirmEnter__warning-text___ehF3L {\n  text-align: center;\n  line-height: 2.4rem;\n  color: #939ea7;\n  margin-bottom: 2rem; }\n  form .ConfirmEnter__warning-text___ehF3L span {\n    color: #444;\n    font-weight: bold;\n    white-space: nowrap; }\n\nform .ConfirmEnter__input___tOCgm {\n  border-color: #eb4235;\n  color: #eb4235; }\n  form .ConfirmEnter__input___tOCgm:focus {\n    border-color: #eb4235; }\n  form .ConfirmEnter__input___tOCgm::-webkit-input-placeholder {\n    color: rgba(235, 66, 53, 0.5); }\n  form .ConfirmEnter__input___tOCgm::-ms-input-placeholder {\n    color: rgba(235, 66, 53, 0.5); }\n  form .ConfirmEnter__input___tOCgm::placeholder {\n    color: rgba(235, 66, 53, 0.5); }\n", ""]);

// exports
exports.locals = {
	"popup-head": "ConfirmEnter__popup-head___19JAw",
	"popup-title": "ConfirmEnter__popup-title___1uL27",
	"warning-text": "ConfirmEnter__warning-text___ehF3L",
	"input": "ConfirmEnter__input___tOCgm"
};