import React from 'react';
import styled from '@emotion/styled';

import left8 from 'images/assets/left-8-black.svg';
import girl from 'images/assets/8base-girl.svg';


const Container = styled('div')`
  background: #1A1A1A;
  width: 100%;
  height: 100%;
  font-family: Source Sans Pro, sans-serif;
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;

  @media (max-width: 575px) {
    word-break: break-word;
  }
`;

const Left8 = styled('img')({
  position: 'absolute',
  left: '-266px',
  top: '-122px',
  width: '836px',
  zIndex: 0,


  '@media (max-width: 767px)': {
    left: '-200px',
    top: '-30px',
  },
});

const Girl = styled('img')({
  position: 'absolute',
  top: 'calc(100vh/2 - 300px)',
  right: '-180px',
  width: '900px',
  zIndex: 0,
});

type UserFlowPageProps = {
  children: React$Node,
  withGirl?: boolean,
};

const UserFlowPage = ({ children, withGirl = false, ...rest }: UserFlowPageProps) => (
  <Container { ...rest }>
    <Left8 src={ left8 } alt="" />
    <If condition={ withGirl }>
      <Girl src={ girl } alt="" />
    </If>
    { children }
  </Container>
);

export { UserFlowPage };
