import React from 'react';
import { Form as FormLogic, Field } from '@8base-react/forms';
import { Dialog, Button, InputField, Form, useModal } from '@8base/boost';
import { Mutation } from 'react-apollo';

import { i18n } from 'i18n';
import { TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';
import { ENVIRONMENT_VARIABLE_UPDATE_MUTATION } from 'graphql/mutations';

const DIALOG_ID = 'ENVIRONMENT_VARIABLE_UPDATE_DIALOG';

const EnvironmentVariableEditDialog = () => {
  const { closeModal } = useModal();

  return (
    <Mutation
      mutation={ ENVIRONMENT_VARIABLE_UPDATE_MUTATION }
      refetchQueries={ ['EnvironmentVariablesList'] }
      context={{
        [TOAST_SUCCESS_MESSAGE]: i18n.t('settings.environmentVariables.updateSuccessfull'),
      }}
    >
      { (updateVariablee) => (
        <Dialog
          id={ DIALOG_ID }
          size="sm"
          onClose={ () => closeModal(DIALOG_ID) }
          { ...E2E.$props('settings.environmentVariables.editDialog.body') }
        >
          { ({ args: { id, name, value }}) => (
            <FormLogic
              type="UPDATE"
              tableSchemaName="EnvironmentVariables"
              onSubmit={ async (data) => {
                await updateVariablee({ variables: { data, filter: { id }}});

                closeModal(DIALOG_ID);

                window.trackEvent('Environment Variables', 'Update variable');
              } }
              initialValues={{ name, value }}
            >
              { ({ handleSubmit, invalid, submitting }) => (
                <form onSubmit={ handleSubmit }>
                  <Dialog.Header
                    title="Edit Environment Variable"
                    onClose={ () => closeModal(DIALOG_ID) }
                  />
                  <Dialog.Body>
                    <Form component="div">
                      <Field
                        name="name"
                        component={ InputField }
                        label="Name"
                        { ...E2E.$props('settings.environmentVariables.editDialog.nameInput') }
                      />
                      <Field
                        name="value"
                        component={ InputField }
                        label="Value"
                        { ...E2E.$props('settings.environmentVariables.editDialog.valueInput') }
                      />
                    </Form>
                  </Dialog.Body>
                  <Dialog.Footer>
                    <Button
                      color="neutral"
                      variant="outlined"
                      disabled={ submitting }
                      onClick={ () => closeModal(DIALOG_ID) }
                      { ...E2E.$props('settings.environmentVariables.editDialog.cancelButton') }
                    >
                      { i18n.t('shared.cancel') }
                    </Button>
                    <Button
                      type="submit"
                      disabled={ invalid } loading={ submitting }
                      { ...E2E.$props('settings.environmentVariables.editDialog.submitButton') }
                    >
                      { i18n.t('shared.saveChanges') }
                    </Button>
                  </Dialog.Footer>
                </form>
              ) }
            </FormLogic>
          ) }
        </Dialog>
      ) }
    </Mutation>
  );
};

EnvironmentVariableEditDialog.ID = DIALOG_ID;

export { EnvironmentVariableEditDialog };
