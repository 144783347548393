// @flow
import React from 'react';
import { Dialog, Grid, Column, Text, Button } from '@8base/boost';
import { Trans } from 'utils/translate';

type AcceptInviteDialogViewProps = {
  hasBillingMetricError: boolean;
  workspaceName?: string | null | typeof undefined;
  invitedByEmail?: string | null | typeof undefined;
  invitedByName?: string | null | typeof undefined;
  declineLoading: boolean;
  onDecline: Function;
  acceptLoading: boolean;
  onJoin: Function;
}

export const AcceptInviteDialogView = ({
  hasBillingMetricError,
  workspaceName,
  invitedByEmail,
  invitedByName,
  declineLoading,
  onDecline,
  acceptLoading,
  onJoin,
}: AcceptInviteDialogViewProps) => {
  return (
    <Dialog.Body>
      <Choose>
        <When condition={ hasBillingMetricError }>
          <Column gap="md">
            <Grid.Layout stretch columns="350px" justifyContent="center">
              <Grid.Box>
                <Text color="SECONDARY_TEXT_COLOR" align="center">
                  <Trans i18nKey="home.invite.limitError">
                      Workspace <Text weight="semibold">{ workspaceName }</Text> has reached the limit of team members.
                      Please, contact <Text weight="semibold">{ invitedByEmail }</Text> to upgrade the plan.
                  </Trans>
                </Text>
              </Grid.Box>
            </Grid.Layout>
            <Grid.Layout stretch columns="150px" justifyContent="center" gap="md">
              <Grid.Box>
                <Button
                  stretch
                  color="neutral"
                  variant="outlined"
                  loading={ declineLoading }
                  onClick={ onDecline }
                >
                  <Trans i18nKey="shared.decline">Decline</Trans>
                </Button>
              </Grid.Box>
            </Grid.Layout>
          </Column>
        </When>
        <Otherwise>
          <Column gap="xl">
            <Grid.Layout stretch columns="350px" justifyContent="center">
              <Grid.Box>
                <Text color="SECONDARY_TEXT_COLOR" align="center">
                  <Trans i18nKey="home.invite.hasInvited">
                    { invitedByName } <Text weight="semibold" css={{ wordBreak: 'break-all' }}>({ invitedByEmail })</Text> has invited you to join the workspace <Text weight="semibold">{ workspaceName }</Text>. Join now to start collaborating.
                  </Trans>
                </Text>
              </Grid.Box>
            </Grid.Layout>
            <Grid.Layout stretch columns="repeat(2, 12rem)" justifyContent="center" gap="md">
              <Grid.Box>
                <Button
                  stretch
                  color="neutral"
                  variant="outlined"
                  loading={ declineLoading }
                  onClick={ onDecline }
                >
                  <Trans i18nKey="shared.decline">Decline</Trans>
                </Button>
              </Grid.Box>
              <Grid.Box>
                <Button
                  stretch
                  color="primary"
                  type="submit"
                  loading={ acceptLoading }
                  onClick={ onJoin }
                >
                  <Trans i18nKey="shared.join">Join</Trans>
                </Button>
              </Grid.Box>
            </Grid.Layout>
          </Column>
        </Otherwise>
      </Choose>
    </Dialog.Body>
  );
};

