import gql from 'graphql-tag';
import { PrefineryUserFragment } from 'graphql/fragments';

// provided by learning-center
export const GET_PREFINERY_USER_QUERY = gql`
  query GetPrefineryUser($email: String!) {
    getPrefineryUser(email: $email) {
      ...PrefineryUserFragment
    }
  }

  ${PrefineryUserFragment}
`;
