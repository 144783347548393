// @flow
// $FlowFixMe waiting for update Flow
import React, { useState, useEffect } from 'react';
import { Grid, Navigation } from '@8base/boost';
import { css } from '@emotion/core';

import { APP_URL } from 'common/routing';

import logo from 'images/8base-logo-white.svg';
import logoEmblem from 'images/8base-emblem.svg';

type AppFlowNavProps = {
  children: ?React$Node,
};

const Logo = ({ expanded }) => (
  <a href={ APP_URL.developerHomeWorkspaces } onClick={ () => window.location.assign(APP_URL.developerHomeWorkspaces) } >
    <div css={ css`height: 56px; background-color: #3c4751; display: flex; align-items: center; justify-content: center; position: relative;` }>
      <img css={{ opacity: expanded ? 0 : 1, transition: 'opacity 0.15s', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} src={ logoEmblem } alt="" { ...E2E.$props('root.header.logo') } />
      <img css={{ opacity: expanded ? 1 : 0, minWidth: 96, transition: 'opacity 0.15s', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} src={ logo } alt="" { ...E2E.$props('root.header.logo') } />
    </div>
  </a>

);

const AppFlowNav = ({ children, ...rest }: AppFlowNavProps) => {
  const [expanded, setExpanded] = useState(localStorage.getItem('nav') !== 'false');

  useEffect(() => {
    window.addEventListener('storage', () => {
      setExpanded(localStorage.getItem('nav') !== 'false');
    });
  });

  return (
    <Grid.Box area="nav" style={{ overflow: 'hidden' }}>
      <Navigation expanded={ expanded } expandedWidth="196px" { ...rest } >
        <Logo expanded={ expanded } />
        { children }
      </Navigation>
    </Grid.Box>
  );
};

export { AppFlowNav };

