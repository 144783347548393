import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class NavNavigationLeftCommonHelper extends Component {

  static propTypes = {
    children: PropTypes.node.isRequired,
    name: PropTypes.string,
    activeNavName: PropTypes.string,

    isSub: PropTypes.bool,
    isOpen: PropTypes.bool,
    disabled: PropTypes.bool,

    onClick: PropTypes.func,
  };

  static defaultProps: {
    isSub: false,
  };

  UNSAFE_componentWillMount = () => {
    this.setState({ isOpen: this.props.isOpen || this.state.isOpen });
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.isOpen !== this.props.isOpen) this.setState({ isOpen: nextProps.isOpen });
  };


  state = {
    isOpen: true,
  };

  toggleCollapse = () => {
    const { name, disabled, onClick } = this.props;
    const { isOpen } = this.state;
    if (disabled) return;
    if (onClick) return onClick({ name, isOpen: !isOpen });
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { children, ...rest } = this.props;
    const { isOpen } = this.state;

    return (
      <div>
        { React.Children.map(children, child => React.cloneElement(child, { ...rest, onClick: this.toggleCollapse, isOpen })) }
      </div>
    );
  }
}
