//@flow

// $FlowFixMe waiting for update Flow
import React, { useCallback } from 'react';
import * as R from 'ramda';

import { useUserPaymentDetailsUpdate } from 'graphql/hooks';

import { BaseBillingUpdateDialog } from './components/BaseBillingUpdateDialog';


const USER_PAYMENT_DETAILS_UPDATE_ID = 'USER_PAYMENT_DETAILS_UPDATE_ID';

/**
 * Update user payment method
 */
const UserPaymentDetailsUpdateDialog = () => {

  const [updateCardMutation] = useUserPaymentDetailsUpdate();

  const onSubmitSuccess = useCallback(async (token) => {
    const cardToken = R.prop('id', token);
    await updateCardMutation({ variables: { data: { cardToken }}});
  }, [updateCardMutation]);

  return (
    <BaseBillingUpdateDialog
      dialogId={ USER_PAYMENT_DETAILS_UPDATE_ID }
      onSubmitSuccess={ onSubmitSuccess }
    />
  );
};

UserPaymentDetailsUpdateDialog.ID = USER_PAYMENT_DETAILS_UPDATE_ID;

export { UserPaymentDetailsUpdateDialog };
