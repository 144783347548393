// @flow

import { PureComponent } from 'react';
import { mapPropToClassName } from 'utils/styles';
import './BodyCursor.scss';

type CursorType = 'move' | 'default' | 'pointer' | 'row-resize' | 'col-resize';

/**
 * @prop {*} cursorType type of the cursor
 * @prop {*} enable then true when body has the style
 */
type BodyCursorProps = {
  cursorType?: CursorType,
  enable?: boolean,
}

const getCursorClassName = (cursorType?: CursorType) =>
  cursorType !== undefined ? mapPropToClassName('cursor', cursorType) : '';

const addClassToBody = (cursorType?: CursorType) => {
  const { classList } = document.body || {};
  const cursorClassName = getCursorClassName(cursorType);

  if (!classList.contains(cursorClassName)) {
    classList.add(cursorClassName);
  }
};

const deleteClassFromBody = (cursorType?: CursorType) => {
  const { classList } = document.body || {};
  const cursorClassName = getCursorClassName(cursorType);

  if (classList.contains(cursorClassName)) {
    classList.remove(cursorClassName);
  }
};

const toggleBodyClass = ({ enable, cursorType }: BodyCursorProps) => {
  enable
    ? addClassToBody(cursorType)
    : deleteClassFromBody(cursorType);
};

/** component that should add the global class for the cursor  */
export class BodyCursor extends PureComponent<BodyCursorProps> {
  static defaultProps = {
    enable: false,
    cursorType: 'default',
  }

  componentDidMount() {
    const { enable, cursorType } = this.props;

    toggleBodyClass({ enable, cursorType });
  }

  UNSAFE_componentWillReceiveProps(nextProps: BodyCursorProps) {
    if (this.props.enable !== nextProps.enable) {
      toggleBodyClass(nextProps);
    }
  }

  render() {
    return null;
  }
}
