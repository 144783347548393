// @flow
import React from 'react';
import { css } from '@emotion/core';
import { Link } from '@8base/boost';

import { UserFlowText } from './UserFlowText';

export const UserFlowPrivacyPolicy = () => {
  return (
    <UserFlowText align="center" kind="overline-1">
      By creating an account, you agree to the&nbsp;
      <br css={ css`@media(min-width: 768px) { display: none; }` } />
      <Link tagName="a" href="http://www.8base.com/user-agreement" target="_blank" rel="noopener noreferrer">user agreement</Link>
      &nbsp;and&nbsp;
      <Link tagName="a" href="https://www.8base.com/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</Link>.
    </UserFlowText>
  );
};
