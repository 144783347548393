// @flow

import React from 'react';
import { Dialog, Button, useModal } from '@8base/boost';
import { useMutation } from 'react-apollo';

import { i18n } from 'i18n';
import { TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';

import { TEAM_MEMBER_DELETE_MUTATION } from 'graphql/mutations';

import { Trans } from 'utils/translate';

const TEAM_MEMBER_DELETE_DIALOG_ID = 'TEAM_MEMBER_DELETE_DIALOG_ID';

const TeamMemberDeleteDialog = () => {
  const { closeModal, args } = useModal(TEAM_MEMBER_DELETE_DIALOG_ID);

  const onClose = () => closeModal(TEAM_MEMBER_DELETE_DIALOG_ID);

  const [teamMemberDelete, { loading: submitting }] = useMutation(TEAM_MEMBER_DELETE_MUTATION, {
    refetchQueries: ['TeamMembersList'],
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('dialogs.teamMemberDelete.successMessage'),
    },
  });

  const createOnDelete = () => async () => {
    window.trackEvent('Team Members', 'Delete member');
    const email = args && args.email;

    const identifiers = email instanceof Array ? email : [email];

    const teamMemberDeletePromiseList = identifiers
      .map((email) => {
        return teamMemberDelete({ variables: { filter: { email }}})
          .catch((err) => ({ email, err }));
      });

    await Promise.all(teamMemberDeletePromiseList);

    onClose();
  };

  if (!args || !args.email) {
    return null;
  }

  return (
    <Dialog id={ TEAM_MEMBER_DELETE_DIALOG_ID } size="sm">
      <Dialog.Header title={ i18n.t('dialogs.teamMemberDelete.title', { count: args.email.length || 1 }) } onClose={ onClose } />
      <Dialog.Body>
        <Trans i18nKey="dialogs.teamMemberDelete.message" count={ args.email.length || 1 }>
          Are you sure you want to delete this team member?
        </Trans>
      </Dialog.Body>
      <Dialog.Footer>
        <Button color="neutral" variant="outlined" onClick={ onClose }>
          <Trans i18nKey="shared.cancel">Cancel</Trans>
        </Button>
        <Button
          type="submit"
          loading={ submitting }
          onClick={ createOnDelete() }
          color="danger"
        >
          <Trans i18nKey="shared.delete">Delete</Trans>
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

TeamMemberDeleteDialog.ID = TEAM_MEMBER_DELETE_DIALOG_ID;

export { TeamMemberDeleteDialog };
