// @flow
// $FlowFixMe
import React, { useMemo } from 'react';
import * as R from 'ramda';
import { Card, Column, Row, Text, Link } from '@8base/boost';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import type { WorkspaceItem } from 'graphql/__generated__';

import { useBuildUrl } from 'common/routing';
import { getWorkspaceUrl } from '../utils';
import { getDisplayWorkspaceKind } from 'utils';

import workspaceDefaultLogo from 'images/workspace-default-logo.svg';

// Type Definitions
import type { OpenWorkspaceHandler } from 'utils/hooks/useOnOpenWorkspace';

type DeveloperHomeWorkspaceItemProps = {
  workspace: WorkspaceItem;
  onOpenWorkspaceClick: OpenWorkspaceHandler;
}

const WorkspaceImage = styled.img`
  flex-shrink: 0;
  object-fit: cover;
  width: 32px;
  height: 32px;
  border-radius: ${props => props.theme.SIZES.MAIN_BORDER_RADIUS};
`;

const StyledCardTitle = styled(Text)`
  padding-right: 8px;
  line-height: 1.2;
  margin: 1px 0 !important;
  width: 100%;
`;

const StyledCard = styled(Card)`
  height: 100%;
  cursor: pointer;
  box-shadow: none;
  padding: 4px;
`;

const StyledCardLink = styled(Link)`
  width: 100%;
  ${StyledCard}, ${StyledCardTitle} {
    transition: background, color, .3s ease-in-out;
  }

  &:hover, &:focus {
    text-decoration: none;
    ${StyledCard} {
      background: ${props => props.theme.COLORS.GRAY_10};
      z-index: 999;
    }
    ${StyledCardTitle} {
      color: ${props => props.theme.COLORS.BLUE_30};
    }
  }
`;

export const DeveloperHomeWorkspaceSearchItem = ({
  workspace,
  onOpenWorkspaceClick,
}: DeveloperHomeWorkspaceItemProps) => {

  const buildUrl = useBuildUrl();
  const kind = R.pathOr('', ['kind'], workspace);
  const workspaceKind = useMemo(() => getDisplayWorkspaceKind(kind), [kind]);

  return (
    <StyledCardLink
      href={ getWorkspaceUrl({ workspace, buildUrl }) }
      onClick={ (event) => {
        if (!event.metaKey && !event.ctrlKey) {
          event.preventDefault();
          onOpenWorkspaceClick({ workspaceId: workspace.id });
        }
      } }>
      <StyledCard
        { ...E2E.$props(`developerHome.search.item[${workspace.name}]`) }
      >
        <Column justifyContent="between" stretch>
          <Row stretch>
            <WorkspaceImage
              src={ (workspace.image && workspace.image.downloadUrl) || workspaceDefaultLogo }
            />
            <Column gap="none" grow={ 1 } stretch css={ css`overflow: hidden;` }>
              <StyledCardTitle
                ellipsis
                kind="body"
                color="DARK_BLACK"
              >
                { workspace.name }
              </StyledCardTitle>
              <Text kind="small-1"> { workspaceKind } </Text>
            </Column>
          </Row>
        </Column>
      </StyledCard>
    </StyledCardLink>
  );
};
