import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { APP_URL } from 'common/routing';

import { Success } from './routes/success';
import { Error } from './routes/error';
import { CliContainer } from './routes/root';
import { CallbackContainer } from './routes/callback';

const Cli = () => (
  <Switch>
    <Route exact path={ APP_URL.cli } component={ CliContainer } />
    <Route path={ APP_URL.cliCallback } component={ CallbackContainer } />
    <Route path={ APP_URL.cliSuccess } component={ Success } />
    <Route path={ APP_URL.cliError } component={ Error } />
  </Switch>
);

export default Cli;
