export const getCookieDomain = () => {
  let domain = null;

  try {
    domain = document.domain.match(/.[^.]+\.\w+$/)[0];
  } catch (e) {
    ({ domain } = document);
  }

  return domain;
};
