// @flow

import fp from 'lodash/fp';

type Insert = {
  <T: mixed>(
    position: number
  ): ((ins:T) => (object: T) => T) & ((ins: T, object: T) => T);
  <T: mixed>(position: number, ins: T): (arr: T[]) => T[],
  <T: mixed>(position: number, ins: T, arr: T[]): T[],
}

/** insert element to the array by the position */
export const insert: Insert = fp.curry(
  <T: mixed>(position: number, ins: T, arr: T[]): T[] => [...arr.slice(0, position), ins, ...arr.slice(position)],
);

type Move = {
  <T: mixed>(
    position: number
  ): ((toPosition: number) => (object: T) => T) & ((toPosition: number, object: T) => T);
  <T: mixed>(position: number, toPosition: number): (arr: T[]) => T[],
  <T: mixed>(position: number, toPosition: number, arr: T[]): T[],
}

/** move array element to the some position */
export const move: Move = fp.curry(
  <T: mixed>(fromPosition: number, toPosition: number, array: T[]) : T[] => {
    const movedElement = array[fromPosition];

    return fp.pipe(
      fp.pullAt([fromPosition]),
      insert(toPosition, movedElement),
    )(array);
  },
);

export const length = <T: mixed>(array: T[]): number => array.length;
