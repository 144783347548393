// @flow

import gql from 'graphql-tag';

export const ACCEPT_TRANSFER_OWNERSHIP_MUTATION = gql`
  mutation AcceptTransfer($workspaceId: ID!, $planId: ID!, $cardToken: String, $workspaceName: String) {
    system {
      workspaceTransferAccept(workspaceId: $workspaceId, planId: $planId, cardToken: $cardToken, workspaceName: $workspaceName) {
        success
      }
    }
  }
`;

export const ABORT_TRANSFER_OWNERSHIP_MUTATION = gql`
  mutation CancelTransferOwnership($transferId: ID!) {
    system {
      workspaceTransferAbort(workspaceId: $transferId) {
        success
      }
    }
  }
`;
