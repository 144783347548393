// @flow
import { useQuery } from 'react-apollo';
import * as R from 'ramda';
import { useAuth } from '@8base-react/auth';
// $FlowFixMe
import { useLocation, matchPath } from 'react-router-dom';

import { TRANSFER_OWNERSHIP_DETAILS_QUERY } from 'graphql/queries';
import { APP_URL } from 'common/routing';

type TransferInfo = {
  newOwnerName: string,
  newOwnerType?: string,
  newOwnerIdentity?: any,
  transferByName: string,
  transferDirection?: string,
  workspaceId?: string,
  workspaceName: string,
  workspaceKind?: string,
  region?: string,
  plans?: any,
  status?: any,
  card?: any,
};

export const formatTransferInfo = (data: any): TransferInfo => {
  const transfer = R.path(['system', 'workspaceTransferInfo'], data);

  if (!transfer) {
    return { newOwnerName: '', transferByName: '', workspaceName: '', transferDirection: '' };
  }

  const {
    from,
    to,
    workspace,
    status,
    plans,
    card,
  } = transfer;

  return {
    newOwnerName: to.name,
    newOwnerType: to.type,
    newOwnerIdentity: to.identity,
    transferByName: from.name || '',
    workspaceId: workspace.workspaceId,
    workspaceName: workspace.name || '',
    workspaceKind: workspace.kind,
    region: workspace.region,
    plans,
    status,
    card,
  };
};

export const useTransferOwnershipDetails = ({
  transferId,
}: *) => {
  const auth = useAuth();
  const location = useLocation();

  const isOnboarding = !!matchPath(location.pathname, { path: APP_URL.onboarding });

  const { data, loading: dataLoading } = useQuery(TRANSFER_OWNERSHIP_DETAILS_QUERY, {
    skip: !transferId || isOnboarding || !auth.isAuthorized || !auth.isEmailVerified,
    variables: {
      transferId,
    },
    fetchPolicy: 'network-only',
  });

  return {
    data,
    dataLoading,
    formattedData: formatTransferInfo(data),
  };
};
