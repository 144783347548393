// @flow
import styled from '@emotion/styled';


export const UserFlowErrorBar = styled.div`
  background: #311714;
  border: 1px solid #FE2A25;
  border-radius: 4px;
  padding: 16px;

  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #FE2A25;
`;
