// @flow
// $FlowFixMe waiting for update Flow
import React, { useCallback, useMemo } from 'react';
import { Card, Button, Row, Heading, InputField, SelectField, AsyncContent, Grid, TextAreaField, Icon } from '@8base/boost';
import { Form, Field } from '@8base-react/forms';
import * as R from 'ramda';

import { TIMEZONE_OPTIONS } from 'config/timezones';
import { COUNTRIES_OPTIONS } from 'config/countries';
import { AvatarField } from 'pro';
import { useUserAccountInfo, useUserAccountInfoUpdate } from 'graphql/hooks';
import { Trans } from 'utils/translate';
import { i18n } from 'i18n';
import { TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';
import { getUserAccountInfoInitialValues } from '../../utils';
import { url, required, maxLength, composeValidators } from 'utils/formValidations';
import { updateUserAccountInfoCachedData } from 'utils/apollo/updateCache';

// TODO: remove when the behavior of Form will change.
// At the moment if the value of Filed is empty string the Form return values with fieldName = undefined.
// It's cause of bug when mutation didn't actually update entity in the database since the fieldName was not sent.
const FieldWIthParse = (props) => {
  return <Field parse={ value => value ? value : null } { ...props } />;
};

export const Profile = () => {
  const { userAccountInfo, loading } = useUserAccountInfo();

  const [userAccountInfoUpdate, { loading: userAccountInfoUpdateLoading }] = useUserAccountInfoUpdate();

  const initialValues = useMemo(() => getUserAccountInfoInitialValues(userAccountInfo), [userAccountInfo]);

  const onSubmit = useCallback((data) => {
    const { avatar } = data;

    const getNewAvatar = () => {
      if (avatar && userAccountInfo.avatar) {
        if (avatar.downloadUrl === userAccountInfo.avatar.downloadUrl) {
          return undefined;
        }
      }

      if (avatar && avatar.fileId) {
        return {
          create: {
            fileId: avatar.fileId,
            filename: avatar.filename,
            public: true,
          },
        };
      }

      return undefined;
    };

    const avatarData = getNewAvatar();

    userAccountInfoUpdate({
      variables: {
        data: {
          ...R.omit(['email'], data),
          avatar: avatarData,
        },
      },
      optimisticResponse: {
        system: {
          memberAccountUpsert: R.mergeDeepRight(userAccountInfo, data),
          __typename: 'SystemMutation',
        },
      },
      context: {
        [TOAST_SUCCESS_MESSAGE]: i18n.t('developerHome.account.profile.profileSuccessfullyUpdated', { defaultValue: 'Profile successfully updated' }),
      },
      update: (cache, { data }) => {
        const userAccountInfoUpdate = R.pathOr({}, ['system', 'memberAccountUpsert'], data);
        updateUserAccountInfoCachedData({ cache, newData: userAccountInfoUpdate });
      },
    });
  }, [userAccountInfo, userAccountInfoUpdate]);

  return (
    <Form onSubmit={ onSubmit } initialValues={ initialValues }>
      { ({ handleSubmit, submitting, pristine, invalid, form, values }) => (
        <Card tagName="form" onSubmit={ handleSubmit } padding="xl" scrollable>
          <Card.Header>
            <Heading type="h2" text={ i18n.t('developerHome.account.profile.title', { defaultValue: 'My Profile' }) } />
          </Card.Header>
          <Card.Body>
            <AsyncContent loading={ loading } stretch>
              <Grid.Layout columns="120px minmax(300px, 600px)" gap="lg">
                <Grid.Box>
                  <Field name="avatar" component={ AvatarField } firstName={ values.firstName } lastName={ values.lastName } size="xxl" />
                </Grid.Box>
                <Grid.Layout gap="lg">
                  <Grid.Layout columns="1fr 1fr" gap="lg">
                    <FieldWIthParse validate={ composeValidators(required, maxLength(150)) } name="firstName" label="First Name" type="text" placeholder="First Name" component={ InputField } />
                    <FieldWIthParse validate={ composeValidators(required, maxLength(150)) } name="lastName" label="Last Name" type="text" placeholder="Last Name" component={ InputField } />
                  </Grid.Layout>
                  <FieldWIthParse validate={ maxLength(150) } name="email" label="Email" disabled component={ InputField } />
                  <FieldWIthParse validate={ maxLength(150) } name="companyName" label="Company" placeholder="Company Name" component={ InputField } />
                  <FieldWIthParse name="country" label="Location" placeholder="Country" options={ COUNTRIES_OPTIONS } component={ SelectField } />
                  <FieldWIthParse validate={ maxLength(150) } name="addressLine1" placeholder="Address Line 1" component={ InputField } />
                  <FieldWIthParse validate={ maxLength(150) } name="addressLine2" placeholder="Address Line 2" component={ InputField } />
                  <Grid.Layout columns="1fr 1fr 1fr" gap="lg">
                    <FieldWIthParse validate={ maxLength(150) } name="city" type="text" placeholder="City" component={ InputField } />
                    <FieldWIthParse validate={ maxLength(150) } name="state" type="text" placeholder="State" component={ InputField } />
                    <FieldWIthParse validate={ maxLength(150) } name="zipCode"type="text" placeholder="Postal / ZIP" component={ InputField } />
                  </Grid.Layout>
                  <FieldWIthParse name="timezone" label="Timezone" options={ TIMEZONE_OPTIONS } component={ SelectField } />
                  <FieldWIthParse validate={ maxLength(300) } rows={ 4 } name="aboutMe" label="About Me" placeholder="Please tell us about yourself" component={ TextAreaField } showErrorOnTouched={ false } />
                  <FieldWIthParse validate={ composeValidators(url, maxLength(150)) } name="website" label="Website" placeholder="Website" component={ InputField } />
                  <FieldWIthParse validate={ maxLength(150) } leftIcon={ <Icon name="GitHub" size="custom" customSize="20px" /> } name="githubUsername" label="Github Username" placeholder="Github Username" component={ InputField } />
                  <FieldWIthParse validate={ maxLength(150) } leftIcon={ <Icon name="Twitter" size="custom" customSize="20px" /> } name="twitterUsername" label="Twitter Username" placeholder="Twitter Username" component={ InputField } />
                  <FieldWIthParse validate={ maxLength(150) } leftIcon={ <Icon name="LinkedIn" size="custom" customSize="16px" /> } name="linkedInUsername" label="LinkedIn Username" placeholder="LinkedIn Username" component={ InputField } />
                </Grid.Layout>
              </Grid.Layout>
            </AsyncContent>
          </Card.Body>

          <Card.Footer>
            <Row justifyContent="end">
              <Button
                color="neutral"
                variant="outlined"
                disabled={ pristine }
                onClick={ () => form.reset() }
              >
                <Trans i18nKey="shared.cancel" />
              </Button>
              <Button type="submit" loading={ submitting || userAccountInfoUpdateLoading } disabled={ pristine || invalid }>
                <Trans i18nKey="shared.submitChanges">Submit Changes</Trans>
              </Button>
            </Row>
          </Card.Footer>
        </Card>
      ) }
    </Form>
  );
};
