import gql from 'graphql-tag';

export const CI_INSTALL_MUTATION = gql`
  mutation CiInstall {
    system {
      ciInstall {
        success
      }
    }
  }
`;
