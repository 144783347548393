import gql from 'graphql-tag';

import { UserFragment } from 'graphql/fragments';
import { UserAccountInfoFragment } from '../fragments';

export const USER_UPDATE_MUTATION = gql`
  mutation UserUpdate($data: UserUpdateInput!, $filter: UserKeyFilter) {
    userUpdate(data: $data, filter: $filter) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;


export const USER_DELETE_MUTATION = gql`
  mutation UserDelete($data: UserDeleteInput, $filter: UserKeyFilter, $force: Boolean) {
    userDelete(data: $data, filter: $filter, force: $force) {
      success
    }
  }
`;


export const USER_ACCOUNT_INFO_UPDATE_MUTATION = gql`
  mutation UserAccountInfoUpdate($data: SystemMemberAccountUpsertDataInput!) {
    system {
      memberAccountUpsert(data: $data) {
        ...UserAccountInfoFragment
      }
    }
  }

  ${UserAccountInfoFragment}
`;


export const USER_ACCOUNT_INVITE_MUTATION = gql`
  mutation UserAccountInvite($inviteEmail: String!) {
    sendInvitationTo8base(inviteEmail: $inviteEmail) {
      success
    }
  }
`;
