import gql from 'graphql-tag';

export const ORGANIZATION_INVITATION_ACCEPT_MUTATION = gql`
  mutation OrganizationInvitationAccept($invitationId: String!) {
    system {
      organizationInviteUserAccept(invitationId: $invitationId) {
        success
      }
    }
  }
`;

export const ORGANIZATION_INVITATION_CANCEL_MUTATION = gql`
  mutation OrganizationInvitationCancel($invitationId: String!) {
    system {
      organizationInviteUserCancel(invitationId: $invitationId) {
        success
      }
    }
  }
`;

export const ORGANIZATION_INVITE_TEAM_MEMBER = gql`
  mutation OrganizationInviteTeamMember(
    $organizationId: ID! 
    $email: String!
    $role: String!
    $firstName: String
    $lastName: String 
    $workspaceRoles: [SystemOrganizationWorkspaceWithEnvironmentRolesInput]
  ) {
    system {
      organizationInviteUser(
        organizationId: $organizationId
        email: $email
        role: $role
        firstName: $firstName
        lastName: $lastName
        workspaceRoles: $workspaceRoles
      ) {
        invitationId
      }
    }
  }
`;
