import React from 'react';
import { ClassNames } from '@emotion/core';
import * as Stripe from 'react-stripe-elements';
import { Icon, Tooltip } from '@8base/boost';

const getStripeClasses = (css, card) => {
  const baseStripeInputClassName = css`
    display: flex;
    width: 100%;
    flex: 1;
    min-height: 36px;
    border: 1px solid #D0D7DD;
    border-radius: 5px;
    align-items: center;
    padding: 0 8px;
    padding-left: ${card ? 32 : 8}px;

    & > * {
      width: 100%;
    }
  `;

  const invalidStripeInputClassName = css`
    color: #ff6d4a;
    border: 1px solid #ff6d4a;
  `;

  const focusStripeInputClassName = css`
    border: 1px solid #4DA1FF;
  `;

  const stripeClasses = {
    base: baseStripeInputClassName,
    invalid: invalidStripeInputClassName,
    focus: focusStripeInputClassName,
  };

  return stripeClasses;
};

const stripeStyle = {
  base: {
    fontFamily: 'Poppins',
    fontSize: '13px',
    color: '#323c47',
    '::placeholder': { color: '#d0d7dd' },
  },
};

export const CardNumberElement = (props) => (
  <ClassNames>
    { ({ css }) => (
      <div className={ css`display: flex; width: 100%; position: relative;` }>
        <Tooltip cursor="pointer" message="All card numbers are encrypted at rest with AES-256" className={ css`position: absolute; top: 8px; left: 8px;` }>
          <Icon name="Lock" size="sm" color="GRAY_30" />
        </Tooltip>
        <Stripe.CardNumberElement classes={ getStripeClasses(css, true) } style={ stripeStyle } { ...props } />
      </div>
    ) }
  </ClassNames>
);

export const CardExpiryElement = (props) => (
  <ClassNames>
    { ({ css }) => (
      <Stripe.CardExpiryElement classes={ getStripeClasses(css) } style={ stripeStyle } { ...props } />
    ) }
  </ClassNames>
);

export const CardCVCElement = (props) => (
  <ClassNames>
    { ({ css }) => (
      <Stripe.CardCVCElement classes={ getStripeClasses(css) } style={ stripeStyle } { ...props } />
    ) }
  </ClassNames>
);

