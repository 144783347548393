// @flow
// $FlowFixMe waiting for update Flow
import React, { useState, useMemo, useCallback } from 'react';
import { Form } from '@8base-react/forms';
import { Card } from '@8base/boost';
import * as R from 'ramda';

import { PLANS } from 'graphql/constants/billing';

import { TransferAcceptContent } from './TransferAcceptContent';
import { TransferAcceptActions } from './TransferAcceptActions';

const useTransferPayment = ({
  card,
  plans = [],
  handleSubmit,
}: *) => {
  const [newCardToken, setNewCardToken] = useState(null);
  const [paymentMethodError, setPaymentMethodError] = useState(false);

  const userCard = useMemo(() => {
    if (newCardToken) return newCardToken.card;

    return card;
  }, [card, newCardToken]);

  const onSubmit = useCallback((values) => {
    const currentPlan = plans.find((plan) => plan.id === values.planId);
    const planName = R.prop('name', currentPlan);

    if (!userCard && planName !== PLANS.freeTier) {
      setPaymentMethodError(true);
    } else {
      setPaymentMethodError(false);
      handleSubmit(values);
    }
  }, [handleSubmit, plans, userCard]);

  return {
    newCardToken,
    setNewCardToken,
    paymentMethodError,
    onSubmit,
  };
};


export const TransferAcceptForm = ({
  initialValues,
  acceptLoading,
  handleSubmit,
  transferByName = '',
  workspaceName = '',
  newOwnerName = '',
  newOwnerType,
  onDecline,
  card,
  plans = [],
  ContentWrapperComponent = Card.Body,
  FooterWrapperComponent = Card.Footer,
}: *) => {
  const {
    newCardToken,
    setNewCardToken,
    paymentMethodError,
    onSubmit,
  } = useTransferPayment({
    card,
    plans,
    handleSubmit,
  });

  return (
    <>
      <Form onSubmit={ onSubmit } initialValues={ initialValues }>
        { (formProps) => {
          return (
            <React.Fragment>
              <ContentWrapperComponent>
                <TransferAcceptContent
                  transferByName={ transferByName }
                  workspaceName={ workspaceName }
                  newOwnerName={ newOwnerName }
                  newOwnerType={ newOwnerType }
                  plans={ plans }
                  paymentMethodError={ paymentMethodError }
                  card={ card }
                  newCardToken={ newCardToken }
                  setNewCardToken={ setNewCardToken }
                  disableCardUpdateBtn={ acceptLoading }
                  { ...formProps }
                />
              </ContentWrapperComponent>
              <FooterWrapperComponent>
                <TransferAcceptActions
                  acceptLoading={ acceptLoading }
                  paymentMethodError={ paymentMethodError }
                  onDecline={ onDecline }
                  handleSubmit={ formProps.handleSubmit }
                  invalid={ formProps.invalid }
                />
              </FooterWrapperComponent>
            </React.Fragment>
          );
        } }
      </Form>
    </>
  );
};
