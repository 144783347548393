//@flow

import * as R from 'ramda';
import { createSelector } from 'reselect';
import { DateTime } from 'luxon';

import type { Selector } from 'reselect';

export type ApolloFile = $Shape<{
  id: string,
  createdAt: string,
  updatedAt: string,
  fileId: string,
  downloadUrl: string,
  shareUrl: string,
  provider: string,
  public: boolean,
  uploaded: boolean,
  filename: string,
  uploadUrl: string,
  shareUrl: string,
  previewUrl: string,
  fields: ?Object,
  meta: ?Object,
  mods: ?Object,
}>;

export const getFile = (file: ApolloFile) => file || {};

export const getFileHandler: Selector<*, void, string> = createSelector(
  getFile,
  R.prop('fileId'),
);

export const getShareUrl: Selector<*, void, string> = createSelector(
  getFile,
  R.prop('shareUrl'),
);

export const getFileName: Selector<*, void, string> = createSelector(
  getFile,
  R.prop('filename'),
);

export const getCreatedAt: Selector<*, void, string> = createSelector(
  getFile,
  R.prop('createdAt'),
);

export const getPreviewUrl: Selector<*, void, string> = createSelector(
  getFile,
  R.prop('previewUrl'),
);

export const getDownloadUrl: Selector<*, void, string> = createSelector(
  getFile,
  R.prop('downloadUrl'),
);

export const getFormatCreatedAt: Selector<*, void, string> = createSelector(
  getCreatedAt,
  (createdAt) => {
    const luxonDate = DateTime.fromISO(createdAt).setLocale('en');

    return !!createdAt
      ? `Updated on ${luxonDate.toLocaleString(DateTime.DATE_SHORT)} at ${luxonDate.toLocaleString(DateTime.TIME_SIMPLE)}`
      : '';
  },
);

