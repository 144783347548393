// @flow
import { DateTime } from 'luxon';
import Cookies from 'js-cookie';

import { getGoogleAnalyticProductId, isProduction } from './processEnv';

type sendGoogleHitArgs = {
  planId: string;
  revenueAmount: string;
};

const VISITOR_ID_NAME = '8base-visitor-id';

export const sendGoogleHit = async ({ planId, revenueAmount }: sendGoogleHitArgs) => {
  const transactionId = `${planId}-${DateTime.local().toUTC().toISO()}`;
  const url = 'https://www.google-analytics.com/collect';

  const visitorId = Cookies.get(VISITOR_ID_NAME);

  if (isProduction()) {
    await fetch(url, {
      method: 'POST',
      body: `v=1&t=transaction&tid=${getGoogleAnalyticProductId()}&cid=${visitorId}&ti=${transactionId}&tr=${revenueAmount}&tt=0&cu=USD`,
    });
  }
};
