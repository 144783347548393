import React, { useCallback } from 'react';
import { useMutation } from 'react-apollo';
import { Column, Row, Dialog, useModal, Icon, Heading, Text, Button, InputField } from '@8base/boost';
import { Field, Form } from '@8base-react/forms';

import { i18n } from 'i18n';
import { Trans } from 'utils/translate';
import { ORGANIZATION_ROLE_DISPLAY_STR, ORGANIZATION_ROLE } from 'common/constants/organization';
import { ORGANIZATION_QUERY } from 'graphql/queries';
import { ORGANIZATION_USER_UPDATE_MUTATION } from 'graphql/mutations/organization';

const ORGANIZATION_ROLE_CHANGING_CONFIRMATION_DIALOG_ID = 'ORGANIZATION_ROLE_CHANGING_CONFIRMATION_DIALOG_ID';

const Title = () => (
  <Row alignItems="center" gap="sm">
    <Icon name="Alert" color="WARNING" />
    <Heading type="h4">Role changing confirmation</Heading>
  </Row>);

const OrganizationRoleChangingConfirmationDialog = () => {
  const { closeModal, args } = useModal(ORGANIZATION_ROLE_CHANGING_CONFIRMATION_DIALOG_ID);
  const { oldRole, newRole, name, email, organizationId } = args;

  const [organizationUserUpdate] = useMutation(ORGANIZATION_USER_UPDATE_MUTATION, {
    skip: !organizationId,
    refetchQueries: [{
      query: ORGANIZATION_QUERY,
      variables: {
        organizationId,
      },
    }],
    awaitRefetchQueries: true,
  });

  const newRoleLabel = ORGANIZATION_ROLE_DISPLAY_STR[newRole];

  const onClose = useCallback(() => {
    closeModal(ORGANIZATION_ROLE_CHANGING_CONFIRMATION_DIALOG_ID);
  }, [closeModal]);

  const onSubmit = useCallback(async () => {
    await organizationUserUpdate({
      variables: {
        organizationId,
        email,
        role: newRole,
      },
    });
    onClose();
  }, [email, newRole, onClose, organizationId, organizationUserUpdate]);

  const validateRole = useCallback((value) => value === newRoleLabel ? null : i18n.t('validation.valueNoMatch'), [newRoleLabel]);

  return (
    <Dialog id={ ORGANIZATION_ROLE_CHANGING_CONFIRMATION_DIALOG_ID } size="sm">
      <Form onSubmit={ onSubmit }>
        { ({ handleSubmit, invalid, pristine, submitting }) =>
          <>
            <Dialog.Header
              title={ <Title /> }
              onClose={ onClose }
            />
            <Dialog.Body>
              <Column>
                <Choose>
                  <When condition={ (newRole === ORGANIZATION_ROLE.admin && oldRole === (ORGANIZATION_ROLE.manager || ORGANIZATION_ROLE.developer)) }>
                    <Text>
                      <Trans i18nKey="organization.team.roleChangingConfirmationDialog.upgradeRoleWarningText">
                      After that change user <Text weight="bold">{ name }</Text> will have access to all organization workspaces.
                      </Trans>
                    </Text>
                  </When>
                  <Otherwise>
                    <Text>
                      <Trans i18nKey="organization.team.roleChangingConfirmationDialog.downgradeRoleWarningText">
                      After that change user <Text weight="bold">{ name }</Text> will lose access to all organization workspaces.
                      </Trans>
                    </Text>
                  </Otherwise>
                </Choose>
                <Trans i18nKey="organization.team.roleChangingConfirmationDialog.typeToConfirm">
                  <Text>
                    Type <Text weight="bold">{ newRoleLabel }</Text> to confirm.
                  </Text>
                </Trans>
                <Field
                  name="role"
                  type="text"
                  placeholder={ newRoleLabel }
                  validate={ validateRole }
                  component={ InputField }
                />
              </Column>
            </Dialog.Body>
            <Dialog.Footer>
              <Button
                color="neutral"
                onClick={ onClose }
              >
                { i18n.t('shared.cancel') }
              </Button>
              <Button
                onClick={ handleSubmit }
                disabled={ invalid || pristine }
                loading={ submitting }
              >
                { i18n.t('shared.confirm') }
              </Button>
            </Dialog.Footer>
          </> }
      </Form>

    </Dialog>
  );
};

OrganizationRoleChangingConfirmationDialog.ID = ORGANIZATION_ROLE_CHANGING_CONFIRMATION_DIALOG_ID;

export { OrganizationRoleChangingConfirmationDialog };
