// @flow
import React from 'react';
import * as R from 'ramda';
import * as Sentry from '@sentry/browser';

import { logger } from 'utils/logger';
import { FS, FULLSTORY_EVENT_TYPES } from 'utils/fullStory';

type ErrorBoundaryProps = {
  children: React$Node,
  history: {
    push: (string) => void,
  }
};

type ErrorBoundaryState = {
  hasError: boolean,
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  componentDidCatch(error: *, errorInfo: *) {
    logger.error(error);

    FS.event(FULLSTORY_EVENT_TYPES.javascriptError, {
      message_str: R.propOr('Unititled Message', 'message', error),
      stack_str: R.propOr('Unititled Stack', 'stack', error),
      component_stack_str: R.propOr('Unititled Component Stack', 'componentStack', errorInfo),
    });

    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      scope.setTag('type', 'JavaScript Error');

      Sentry.captureException(error);
    });
  }

  render() {
    return this.props.children;
  }
}
