// @flow

import React from 'react';
import { Button, Loader } from '@8base/boost';
import styled from '@emotion/styled';

import { TableFooter } from './TableFooter';

type TableContainerProps = {
  children: React$Node,
  onAddRow?: () => void,
  showLoader?: boolean,
  withAddButton?: boolean,
  selectedRecordCount: number,
  totalRecordCount: number,
};

const Container = styled('div')`
  width: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
`;

const AddButtonContainer = styled('div')`
  position: absolute;
  bottom: 30px;
  left: 50%;
  z-index: 2;
  transform: translate3d(-50%, 0, 0);
`;

const LoaderContainer = styled('div')`
  position: absolute;
  bottom: 80px;
  left: 50%;
  z-index: 2;
  transform: translate3d(-50%, 0, 0);
`;

export const TableContainer = ({
  children,
  onAddRow,
  withAddButton,
  showLoader,
  selectedRecordCount,
  totalRecordCount,
}: TableContainerProps) => (
  <Container>
    <If condition={ !!showLoader }>
      <LoaderContainer>
        <Loader size="sm" />
      </LoaderContainer>
    </If>
    <If condition={ !!withAddButton }>
      <AddButtonContainer>
        <Button
          { ...E2E.$props('data.viewer.createRow') }
          onClick={ onAddRow }
          rounded
        >
          + Add Row
        </Button>
      </AddButtonContainer>
    </If>
    { children }
    <TableFooter
      totalRecordCount={ totalRecordCount }
      selectedRecordCount={ selectedRecordCount }
      showLoader={ showLoader }
    />
  </Container>
);
