// @flow

import React from 'react';

export type WorkspaceContextProps = {
  id?: string;
  name?: string;
  isOwner?: boolean;
  isCiCdEnabled?: boolean;
} & any;

export const WorkspaceContext = React.createContext<{
  workspace: WorkspaceContextProps;
}>({ workspace: {}});
