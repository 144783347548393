// @flow
// $FlowFixMe
import React, { useMemo, useCallback } from 'react';
// $FlowFixMe
import { useParams, useHistory } from 'react-router-dom';
import { APP_URL, useBuildUrl } from 'common/routing';
import {
  Label,
  Icon,
  Card,
  Heading,
  Row,
  Button,
  Tooltip,
  Avatar,
  Column,
  Text,
  Select,
  TableBuilder,
  useModal,
} from '@8base/boost';
// $FlowFixMe
import { i18n } from 'i18n';
import { Trans } from 'utils/translate';
import { useMutation } from 'react-apollo';
import { useOrganization, useUserAccountInfo } from 'graphql/hooks';
import * as R from 'ramda';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ORGANIZATION_ROLE, ORGANIZATION_ROLE_DISPLAY_STR, ORGANIZATION_ROLE_LIST } from 'common/constants/organization';
import { WORKSPACE_SHARE_WITH_ENVS_MUTATION } from 'graphql/mutations';
import {
  getUserRoleAtOrganization,
  getOrganizationUserWorkspaces,
  checkAddAccessOpportunity,
  checkRemoveFromOrganizationOpportunity,
} from 'utils/organization';
import { TeamMemberRemoveFromOrganizationDialog } from 'dialogs/TeamMemberRemoveFromOrganizationDialog';
import { OrganizationRoleChangingConfirmationDialog } from 'dialogs/OrganizationRoleChangingConfirmationDialog';
import { OrganizationTeamMemberEditAccessDialog } from 'dialogs/OrganizationTeamMemberEditAccessDialog';
import { OrganizationAddAccessToWorkspacesDialog } from 'dialogs/OrganizationAddAccessToWorkspacesDialog';
import { OrganizationRemoveAccessToWorkspacesDialog } from 'dialogs/OrganizationRemoveAccessToWorkspacesDialog';
import { TableCellActions } from 'pro/TableCells';
import { WorkspaceCard } from 'common/components';
import { IGNORE_WORKSPACE } from 'common/constants/apolloOperationContextOptions';
import { OrganizationTeamMemberWorkspaceEnvironments } from './OrganizationTeamMemberWorkspaceEnvironments';

const SmallIcon = styled(Icon)`
  width: 12px !important;
  height: 12px !important;
`;

const StyledCard = styled(Card)`
  overflow: visible;
`;

const TABLE_COLUMNS = [
  {
    name: 'Workspace',
    title: <Trans i18nKey="shared.workspace">Workspace</Trans>,
    sortEnable: true,
    width: 'minmax(0, 280px)',
  },
  {
    name: 'Description',
    title: <Trans i18nKey="shared.description">Description</Trans>,
    sortEnable: false,
    width: 'minmax(0, 1fr)',
  },
  {
    name: 'Environments',
    title: <Trans i18nKey="shared.environments">Environments</Trans>,
    sortEnable: false,
    width: 'minmax(0, 180px)',
  },
  {
    name: 'actions',
    title: '',
    sortEnable: false,
    width: '70px',
  },
];

const getRoleDescriptionText = (role) => {
  if (role === ORGANIZATION_ROLE.admin) {
    return i18n.t('organization.team.administratorDescriptionText', { defaultValue: 'Has full access to all workspaces' });
  }

  if (role === ORGANIZATION_ROLE.manager) {
    return i18n.t('organization.team.managerDescriptionText', { defaultValue: 'Has full access to selected workspaces' });
  }

  if (role === ORGANIZATION_ROLE.developer) {
    return i18n.t('organization.team.developerDescriptionText', { defaultValue: 'Has access only to selected workspaces with the chosen roles' });
  }

  return '';
};

const getRoleOptions = (permissionRole: string, profileRole: string) => {
  if (profileRole === ORGANIZATION_ROLE.owner) {
    return R.pipe(
      R.reject(R.equals('admin')),
      R.reject(R.equals('manager')),
      R.reject(R.equals('developer')),
      R.map((name) => ({ label: ORGANIZATION_ROLE_DISPLAY_STR[name], value: name })),
    )(ORGANIZATION_ROLE_LIST);
  }

  if (permissionRole === ORGANIZATION_ROLE.admin || permissionRole === ORGANIZATION_ROLE.owner) {
    return R.pipe(
      R.reject(R.equals('owner')),
      R.map((name) => ({ label: ORGANIZATION_ROLE_DISPLAY_STR[name], value: name })),
    )(ORGANIZATION_ROLE_LIST);
  }

  if (permissionRole === ORGANIZATION_ROLE.manager && profileRole === ORGANIZATION_ROLE.admin) {
    return R.pipe(
      R.reject(R.equals('owner')),
      R.reject(R.equals('manager')),
      R.reject(R.equals('developer')),
      R.map((name) => ({ label: ORGANIZATION_ROLE_DISPLAY_STR[name], value: name })),
    )(ORGANIZATION_ROLE_LIST);
  }

  if (permissionRole === ORGANIZATION_ROLE.manager) {
    return R.pipe(
      R.reject(R.equals('owner')),
      R.reject(R.equals('admin')),
      R.map((name) => ({ label: ORGANIZATION_ROLE_DISPLAY_STR[name], value: name })),
    )(ORGANIZATION_ROLE_LIST);
  }

  return [];
};

const AddAccessToWorkspaceButton = ({ disabled, onClick, children, ...rest }) => {
  const AddAccessButton = (
    <Button
      color="neutral"
      onClick={ onClick }
      disabled={ disabled }
      { ...rest }
    >
      { children }
    </Button>);

  if (disabled) {
    return (
      <Tooltip trigger="hover" message={ i18n.t('organization.team.alreadyHasAccess') }>
        { AddAccessButton }
      </Tooltip>
    );
  }

  return AddAccessButton;
};

const NoWorkspaces = () => (
  <Column alignItems="center" css={ css`padding: 85px 0px;` }>
    <Text kind="body" color="GRAY_40">
      { i18n.t('shared.noWorkspaces') }
    </Text>
  </Column>
);

const WorkspacesTableCell = ({
  column,
  cellData,
  isExpanded,
  expandRow,
  profileRole,
  profileEmail,
  profileFullName,
  userPermissionRole,
  userEmail,
  updateTeamMemberWorkspaceEnvironments,
}) => {
  const { name: workspaceName, description, id: workspaceId, kind, image, organization } = cellData;

  const organizationId = R.pathOr('', ['id'], organization);

  const { openModal } = useModal();

  const onUserRemoveFromWorkspace = useCallback(() => {
    openModal(
      OrganizationRemoveAccessToWorkspacesDialog.ID,
      { organizationId, workspaceId, workspaceName, profileEmail, profileFullName });
  }, [openModal, organizationId, profileEmail, profileFullName, workspaceId, workspaceName]);

  const actions = useMemo(() => {
    if (userEmail === profileEmail) {
      return [
        {
          title: i18n.t('shared.leave', { defaultValue: 'Leave' }),
        },
      ];
    }

    if (userPermissionRole === ORGANIZATION_ROLE.developer) {
      return [];
    }

    if (profileRole === ORGANIZATION_ROLE.developer) {
      return [
        {
          title: i18n.t('organization.team.editAccess', { defaultValue: 'Edit Access' }),
          handler: () => (
            openModal(
              OrganizationTeamMemberEditAccessDialog.ID,
              { workspaceId, email: profileEmail, workspaceName, kind, image, updateTeamMemberWorkspaceEnvironments, organizationId })
          ),
        },
        {
          title: i18n.t('organization.team.removeAccess', { defaultValue: 'Remove Access' }),
          handler: onUserRemoveFromWorkspace,
        },
      ];
    }

    if (profileRole === ORGANIZATION_ROLE.manager) {
      return [{
        title: i18n.t('organization.team.removeAccess', { defaultValue: 'Remove Access' }),
        handler: onUserRemoveFromWorkspace,
      }];
    }

    return [];
  }, [
    userEmail,
    profileEmail,
    userPermissionRole,
    profileRole,
    onUserRemoveFromWorkspace,
    openModal,
    workspaceId,
    workspaceName,
    kind,
    image,
    updateTeamMemberWorkspaceEnvironments,
    organizationId,
  ]);

  switch (column.name) {
    case 'Workspace':
      return (
        <WorkspaceCard
          image={ image }
          kind={ kind }
          workspaceName={ workspaceName }
          workspaceId={ workspaceId }
        />);
    case 'Description':
      return <Text ellipsis>{ description }</Text>;
    case 'Environments':
      return (
        <Button
          color="primary"
          variant="link"
          css={ css`font-weight: normal;` }
          onClick={ expandRow }
          disabled={ profileRole !== ORGANIZATION_ROLE.developer }
        >
          <span>
            <Trans i18nKey="shared.environments">Environments</Trans>
          </span>
          <SmallIcon name={ isExpanded ? 'ChevronTop' : 'ChevronDown' } />
        </Button>
      );
    case 'actions': {
      if (profileRole === ORGANIZATION_ROLE.owner || profileRole === ORGANIZATION_ROLE.admin) {
        return null;
      }
      return <TableCellActions actions={ actions } />;
    }
    default: return null;
  }
};

const renderCell = ({
  profileRole,
  profileEmail,
  userEmail,
  userPermissionRole,
  updateTeamMemberWorkspaceEnvironments,
  profileFullName,
}) =>
  (column, cellData, { expandRow, isExpanded }) => (
    <WorkspacesTableCell
      column={ column }
      cellData={ cellData }
      isExpanded={ isExpanded }
      expandRow={ expandRow }
      profileFullName={ profileFullName }
      profileRole={ profileRole }
      profileEmail={ profileEmail }
      userPermissionRole={ userPermissionRole }
      userEmail={ userEmail }
      updateTeamMemberWorkspaceEnvironments={ updateTeamMemberWorkspaceEnvironments }
    />
  );

export const OrganizationTeamMemberProfile = () => {
  const { userId, organizationId } = useParams();
  const { organization } = useOrganization(organizationId);

  const { openModal } = useModal();
  const organizationName = R.pathOr('', ['name'], organization);
  const organizationUsersList = R.pathOr([], ['users'], organization);

  const buildUrl = useBuildUrl();
  const history = useHistory();

  const { userAccountInfo } = useUserAccountInfo({
    notifyOnNetworkStatusChange: false,
    context: {
      [IGNORE_WORKSPACE]: true,
      noBatch: true,
    },
    fetchPolicy: 'cache-first',
  });

  const userEmail = R.pathOr('', ['email'], userAccountInfo);

  const userPermissionRole = useMemo(() =>
    getUserRoleAtOrganization(userAccountInfo, organization),
  [organization, userAccountInfo]);

  const userWorkspaces = useMemo(() =>
    getOrganizationUserWorkspaces(userEmail, organizationUsersList)
  , [userEmail, organizationUsersList]);

  const profileData = useMemo(() =>
    organizationUsersList.find(({ id }) => id === userId) || { firstName: '', lastName: '', email: '', workspaces: [], role: '' }
  , [userId, organizationUsersList]);

  const {
    firstName: profileFirstName,
    lastName: profileLastName,
    email: profileEmail,
    workspaces: profileWorkspaces,
    role: profileRole,
    avatar,
  } = profileData;

  const workspacesList = useMemo(() => {
    const workspacesIdList = (userWorkspaces.map(el => el.id)) || [];
    const list = profileWorkspaces.filter(el => workspacesIdList.includes(el.id));

    return list;
  }, [userWorkspaces, profileWorkspaces]);

  const unavailableWorkspaces = useMemo(() => {
    const availableWorkspacesIdList = (profileWorkspaces.map(el => el.id)) || [];
    const list = userWorkspaces && userWorkspaces.filter(el => !availableWorkspacesIdList.includes(el.id));

    return list;
  }, [profileWorkspaces, userWorkspaces]);

  const roleOptions = useMemo(() =>
    getRoleOptions(userPermissionRole, profileRole), [profileRole, userPermissionRole]);

  const onRemoveFromOrganization = useCallback(() => {
    openModal(TeamMemberRemoveFromOrganizationDialog.ID, {
      organizationId,
      organizationName,
      firstName: profileFirstName,
      lastName: profileLastName,
      email: profileEmail,
      userWorkspaceList: profileWorkspaces || [],
    });
  }, [openModal, organizationId, organizationName, profileEmail, profileFirstName, profileLastName, profileWorkspaces]);


  const onBackToTeamTable = useCallback(() => {
    const url = buildUrl(APP_URL.organizationSettingsTeam, { pathParams: { organizationId }});
    history.push(url);
  }, [buildUrl, history, organizationId]);

  const [updateTeamMemberWorkspaceEnvironments] = useMutation(WORKSPACE_SHARE_WITH_ENVS_MUTATION);

  return (
    <Column alignItems="stretch" gap="lg" stretch css={ css`height: auto;` }>
      <Row alignItems="center">
        <Icon cursor="pointer" name="ArrowLeft" size="lg" onClick={ onBackToTeamTable } />
        <Heading type="h1">
          <Trans i18nKey="organization.team.userProfile">
            User Profile
          </Trans>
        </Heading>
      </Row>
      <StyledCard>
        <Card.Header>
          <Row stretch justifyContent="between" alignItems="center">
            <Heading type="h2">
              <Trans i18nKey="organization.team.userProfile">
                User Profile
              </Trans>
            </Heading>
            <If condition={ checkRemoveFromOrganizationOpportunity({ userEmail, profileEmail, userPermissionRole, profileRole }) }>
              <Button color="neutral" onClick={ onRemoveFromOrganization }>
                <Choose>
                  <When condition={ userEmail === profileEmail } >
                    <Trans i18nKey="organization.leave">
                      Leave Organization
                    </Trans>
                  </When>
                  <Otherwise>
                    <Trans i18nKey="organization.removeUser">
                      Remove from Organization
                    </Trans>
                  </Otherwise>
                </Choose>
              </Button>
            </If>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row stretch gap="md">
            <Avatar size="xxl" src={ R.prop(['downloadUrl'], avatar) } />
            <Column gap="md">
              <Text kind="h3">
                { profileFirstName } { profileLastName }
              </Text>
              <Text>
                { profileEmail }
              </Text>
              <Column gap="xs">
                <Label kind="secondary" text="Role" />
                <Select
                  css={ css`width: 402px;` }
                  value={ profileRole }
                  options={ roleOptions }
                  disabled={ profileRole === ORGANIZATION_ROLE.owner ||
                  (profileRole === ORGANIZATION_ROLE.admin && userPermissionRole !== ORGANIZATION_ROLE.owner) }
                  onChange={ (newRole) => {
                    openModal(OrganizationRoleChangingConfirmationDialog.ID, {
                      organizationId,
                      email: profileEmail,
                      name: `${profileFirstName} ${profileLastName}`,
                      newRole,
                      oldRole: profileRole,
                    });
                  } }
                />
                <Text kind="small-1">{ getRoleDescriptionText(profileRole) }</Text>
              </Column>
            </Column>
          </Row>
        </Card.Body>
      </StyledCard>
      <StyledCard>
        <Card.Header>
          <Row stretch justifyContent="between" alignItems="center">
            <Heading type="h2">
              <Trans i18nKey="organization.team.workspaceAccess">
                Workspaces Access
              </Trans>
            </Heading>
            <If condition={ checkAddAccessOpportunity({ userEmail, profileEmail, userPermissionRole, profileRole }) }>
              <AddAccessToWorkspaceButton
                color="neutral"
                disabled={ !unavailableWorkspaces.length }
                onClick={ () =>
                  openModal(OrganizationAddAccessToWorkspacesDialog.ID, {
                    userEmail,
                    profileEmail,
                    profileRole,
                    updateTeamMemberWorkspaceEnvironments,
                    organizationId,
                  }) }>
                <SmallIcon name="Plus" css={ css`margin-right: 14px;` } />
                <Trans i18nKey="organization.team.addAccessToWorkspace">
                Add Access to Workspace
                </Trans>
              </AddAccessToWorkspaceButton>
            </If>
          </Row>
        </Card.Header>
        <Card.Body padding="none">
          <TableBuilder
            columns={ TABLE_COLUMNS }
            data={ workspacesList || [] }
            expandedRowRender={ ({ rowData }) =>
              (<OrganizationTeamMemberWorkspaceEnvironments
                rowData={ rowData }
                email={ profileEmail }
              />) }
            noData={ <NoWorkspaces /> }
            renderCell={ renderCell({
              profileFullName: `${profileFirstName} ${profileLastName}`,
              profileRole,
              profileEmail,
              userEmail,
              userPermissionRole,
              updateTeamMemberWorkspaceEnvironments,
            }) }
          />
        </Card.Body>
      </StyledCard>
    </Column>
  );
};
