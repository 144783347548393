import React from 'react';
import * as R from 'ramda';
import { css } from '@emotion/core';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { Form as FormLogic, Field } from '@8base-react/forms';
import { Dialog, Button, InputField, Text, useModal, CheckboxField, Form } from '@8base/boost';
import { TABLES_SCHEMA_QUERY } from '@8base-react/table-schema-provider';

import { IndexColumnsField } from 'pro';
import { required, requiredArray } from 'utils/formValidations';
import { i18n } from 'i18n';
import { INDEXES_TYPES } from 'common/constants/indexes';

const INDEX_CREATE_MUTATION = gql`
  mutation IndexCreate($data: IndexCreateInput!) {
    indexCreate(data: $data) {
      id
    }
  }
`;

const INDEX_CREATE_DIALOG_ID = 'INDEX_CREATE_DIALOG_ID';

const IndexCreateDialog = () => {
  const { closeModal, args } = useModal(INDEX_CREATE_DIALOG_ID);

  const [indexCreate] = useMutation(INDEX_CREATE_MUTATION, {
    refetchQueries: [{ query: TABLES_SCHEMA_QUERY }],
    awaitRefetchQueries: true,
  });

  const onClose = React.useCallback(() => closeModal(INDEX_CREATE_DIALOG_ID), [closeModal]);

  const onSubmit = React.useCallback(async (data) => {
    try {

      await indexCreate({
        variables: {
          data: {
            tableId: args.id,
            type: data.unique ? INDEXES_TYPES.UNIQUE : INDEXES_TYPES.INDEX,
            name: data.name,
            columns: data.columns,
          },
        },
      });
    } catch (e) {
      if (R.has('graphQLErrors', e)) {
        e.graphQLErrors = e.graphQLErrors.map((err) => {
          if (R.hasPath(['details', 'columns'], err)) {
            const message = R.path(['details', 'columns'], err).map(R.prop('name')).join(', ');

            err = R.assocPath(['details', 'columns'], message, err);
          }

          return err;
        });

        throw e;
      }
    }

    onClose();
  }, [indexCreate, args.id, onClose]);

  return (
    <Dialog id={ INDEX_CREATE_DIALOG_ID } onClose={ onClose } size="xl">
      <FormLogic onSubmit={ onSubmit } >
        { ({ handleSubmit, invalid, submitting, pristine }) => (
          <form onSubmit={ handleSubmit } css={ css`height: 100%;` }>
            <Dialog.Header title={ i18n.t('dialogs.indexCreate.title') } onClose={ onClose } />
            <Dialog.Body scrollable>
              <Form>
                <Field name="name" label={ i18n.t('shared.name') } component={ InputField } validate={ required } />
                <Field name="columns" label={ i18n.t('shared.fields') } component={ IndexColumnsField } tableId={ args.id } validate={ requiredArray } />
                <Text color="SECONDARY_TEXT_COLOR" css={{ marginBottom: '4px !important' }}>Advanced</Text>
                <Field name="unique" label={ i18n.t('dialogs.indexCreate.uniqueIndex') } component={ CheckboxField } />
              </Form>
            </Dialog.Body>
            <Dialog.Footer>
              <Button color="neutral" variant="outlined" disabled={ submitting } onClick={ onClose }>
                { i18n.t('shared.cancel') }
              </Button>
              <Button type="submit" disabled={ pristine } loading={ submitting }>
                { i18n.t('shared.create') }
              </Button>
            </Dialog.Footer>
          </form>
        ) }
      </FormLogic>
    </Dialog>
  );
};

IndexCreateDialog.ID = INDEX_CREATE_DIALOG_ID;

export { IndexCreateDialog };
