import React, { useMemo } from 'react';
import * as R from 'ramda';
import { Link, Avatar, Dropdown, Icon, Menu, Row, Column, Text } from '@8base/boost';
import { Link as RouterLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { USER_STATUSES, USER_STATUSES_DISPLAY_STR } from 'common/constants/organization';

type StatusCellProps = {
  status: string,
}

export const TableCellActions = ({ actions }) => (
  <Dropdown defaultOpen={ false }>
    <Dropdown.Head >
      <Icon name="More" color="GRAY_30" />
    </Dropdown.Head>
    <Dropdown.Body withPortal preventOverflow pin="right">
      { ({ closeDropdown }) => (
        <Menu>
          { actions.map(({ title, handler }, index) => (
            <Menu.Item key={ index } onClick={ (event) => {
              event.stopPropagation();
              event.preventDefault();

              if (typeof handler === 'function') {
                handler();
              }

              closeDropdown();
            } }>{ title }
            </Menu.Item>
          )) }
        </Menu>
      ) }
    </Dropdown.Body>
  </Dropdown>
);

export const TableCellUserName = ({ firstName, lastName, onClick }) => (
  <Link component="span" onClick={ onClick } css={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{ firstName } { lastName }</Link>
);

export const TableCellUserAvatar = ({ firstName, lastName, src }) => (
  <Avatar size="sm" firstName={ firstName } lastName={ lastName } src={ src } />
);

export const TableCellEnumeration = ({ items, propName }) => items.map((item) => R.prop(propName, item)).join(', ');

export const TableCellEnumerationWithLink = ({ items, propName, getLink }) => {
  return (
    <div>
      { React.Children.toArray(
        items.reduce((result, item) => [...result, <Link tagName={ RouterLink } key={ item.id } to={ getLink(item) }>{ R.prop(propName, item) }</Link>, ', '], []).slice(0, -1),
      ) }
    </div>
  );
};

const StyledLink = styled(Link)`
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 !important;
`;

export const TableCellUser = ({ firstName, lastName, src, email, onClick }) => (
  <Row css={ css`overflow: hidden;` } alignItems="center">
    <Avatar css={ css`flex-shrink: 0;` } size="sm" firstName={ firstName } lastName={ lastName } src={ src } />
    <Column css={ css`overflow: hidden;` }>
      <StyledLink component="span" onClick={ onClick } >
        { firstName } { lastName }
      </StyledLink>
      <Text
        kind={ firstName || lastName ? 'small-1' : 'overline-1' }
        color="GRAY_50"
        ellipsis
        css={ css`max-width: 100%;` }
      >
        { email }
      </Text>
    </Column>
  </Row>
);


export const TableCellStatus = ({ status }: StatusCellProps) => {
  const textColor = useMemo(() => {
    if (status === USER_STATUSES.active) return 'SECONDARY';

    return 'GRAY_50';
  }, [status]);

  return (<Text ellipsis color={ textColor }>{ USER_STATUSES_DISPLAY_STR[status] }</Text>);
};
