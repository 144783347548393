// @flow

import gql from 'graphql-tag';

export const TRANSFER_OWNERSHIP_DETAILS_QUERY = gql`
  query TransferOwnershipDetails($transferId: ID!) {
    system {
      workspaceTransferInfo(workspaceId: $transferId) {
        createdAt
        status
        workspace {
          workspaceId
          name
          region
          owner
          kind
          apiHost
          organization {
            id
            name
            type
            description
          }
        }
        from {
          name
          type
          identity
        }
        to {
          name
          type
          identity
        }
        plans {
          id
          name
          description
          displayName
          price
        }
        card {
          last4
          expMonth
          expYear
          brand
          origin
        }
      }
    }
  }
`;
