// @flow
// $FlowFixMe waiting for update Flow
import React from 'react';

// $FlowFixMe
import { useParams } from 'react-router-dom';
import * as R from 'ramda';
import {
  Card,
  Heading,
} from '@8base/boost';

// $FlowFixMe
import { css } from '@emotion/core';
import { useQuery } from 'react-apollo';

import { Trans } from 'utils/translate';
import { apolloBillingDataSelectors } from 'graphql/selectors';
import { BILLING_LICENSE_CONST_QUERY } from 'graphql/queries';

import { BillingCardBody } from 'common/components/BillingCardBody';
import { getDateFromFirstDayOfMonth } from 'utils/date';

export const LicenseCost = () => {
  const { organizationId } = useParams();


  const { data, loading } = useQuery(BILLING_LICENSE_CONST_QUERY, {
    variables: {
      organizationId,
      date: getDateFromFirstDayOfMonth(),
    },
    skip: !organizationId,
  });

  return (
    <Card stretch>
      <Card.Header>
        <Heading type="h2">
          <Trans i18nKey="organization.billing.licenseCost">
            Organization License Cost
          </Trans>
        </Heading>
      </Card.Header>
      <BillingCardBody
        loading={ loading }
        plan={ R.path(['system', 'billingCurrentPlan'], data) }
        quotas={ apolloBillingDataSelectors.getQuotas(data) }
        metrics={ apolloBillingDataSelectors.getMetrics(data) }
        overages={ apolloBillingDataSelectors.getOveragesValues(data) }
        overagesPrice={ apolloBillingDataSelectors.getTotalOverages(data) }
        showPlanDetails={ false }
        css={ css`height: 100%;` }
      />
    </Card>
  );
};
