import React from 'react';
import styled from '@emotion/styled';
import { Row } from '@8base/boost';

import { Trans } from 'utils/translate';

import { useBuildUrl, APP_URL } from 'common/routing';

import logo from 'images/8base-logo-red-white.svg';
import error from 'images/error.svg';

const HELP_CENTER_LINK = 'https://help.8base.com/';
const SUPPORT_EMAIL = 'mailto:support@8base.com';

const Logo = styled('img')`
  height: 50px;
`;

const Header = styled('header')`
  margin: 50px 50px 44px;
`;

const Container = styled('div')`
  background-color: #FFF;
`;

const FirstHeading = styled('h1')`
  color: #323C47;
  font-family: Poppins;
  font-size: 42px;
  font-weight: 600;
  line-height: 69px;
  
  margin-bottom: 20px;
`;

const Paragraph = styled('p')`
  color: #323C47;
  font-family: Poppins;
  font-size: 2rem;
  line-height: 32px;
  
  margin-bottom: 20px;
`;

const ErrorImg = styled('img')`
  width: 100%;
`;

const StyledLink = styled('a')`
  color: #4DA1FF;
  text-decoration: none;
  
  &:visited: {
    color: #4DA1FF;
  }
  
  &:hover {
    text-decoration-color: #4DA1FF;
    text-decoration-line: underline;
    text-decoration-style: solid;
  }
`;

const TextContainer = styled('div')`
  max-width: 550px;
`;

const ImageContainer = styled('div')`
  max-width: 540px;
`;

export const Error = () => {
  const buildUrl = useBuildUrl();

  return (
    <Container>
      <Header>
        <nav>
          <a href={ buildUrl(APP_URL.workspaceHome) }>
            <Logo src={ logo } alt="8base logo" />
          </a>
        </nav>
      </Header>
      <main>
        <Row justifyContent="center" alignItems="center" gap="xl" offsetX="xl">
          <TextContainer>
            <FirstHeading>
              <Trans i18nKey="error.heading">
                It’s not you, it’s us…
              </Trans>
            </FirstHeading>
            <Paragraph>
              <Trans i18nKey="error.explanation">
                Something went wrong on our end. We’re working hard to fix it and should be up and running soon.
              </Trans>
            </Paragraph>
            <Paragraph>
              <Trans i18nKey="error.contacts">
                Please visit our
                <StyledLink target="_blank" rel="noopener noreferrer" href={ HELP_CENTER_LINK }>Help Center</StyledLink> for additional information.
                If you need immediate help with an urgent issue, please
                <StyledLink href={ SUPPORT_EMAIL } rel="noopener noreferrer">email us</StyledLink>.
              </Trans>
            </Paragraph>
            <Paragraph>
              Return to&nbsp;
              <StyledLink href={ buildUrl(APP_URL.workspaceHome) }>
                the homepage
              </StyledLink>.
            </Paragraph>
          </TextContainer>
          <ImageContainer>
            <ErrorImg src={ error } alt="" />
          </ImageContainer>
        </Row>
      </main>
    </Container>
  );
};
