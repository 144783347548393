import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './InputVisualaized.scss';

export default class InputVisualizedCommonHelper extends Component {
  static propTypes = {
    input: PropTypes.shape({
      value: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
    }),

    meta: PropTypes.shape({
      error: PropTypes.string,
    }),

    label: PropTypes.string,
    placeholder: PropTypes.string.isRequired,

    required: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    imageEnd: PropTypes.string.isRequired,
    testId: PropTypes.string,
  };

  static defaultProps = {
    placeholder: '',
    required: false,
    disabled: false,
    image: '',
    icon: '',
    imageEnd: '',
    type: 'text',
    input: {},
    meta: {},
  };

  state = { focused: false };

  onFocus = () => this.setState({ focused: true });

  onBlur = () => this.setState({ focused: false });

  onChange = (e) => {
    const { type, input: { onChange }} = this.props;
    if (!onChange) return;
    if (type === 'number') return onChange(Number(e.target.value));
    onChange(e.target.value);
  };

  renderImage = () => {
    const { label, image, imageEnd, icon } = this.props;
    if (image) return <span className={ `input-group-addon ${imageEnd ? 'addon-end' : ''}` }><img src={ image } alt={ label } /></span>;
    if (icon) return <span className={ `input-group-addon ${imageEnd ? 'addon-end' : ''}` }><span className={ `icon-${icon}` } /></span>;
    return null;
  };

  render() {
    const { label, placeholder, type, required, disabled, input: { value, name }, meta: { touched, error }, testId } = this.props;
    const { focused } = this.state;

    const hasError = touched && error;

    return (
      <div className="form-group" styleName={ hasError ? 'error' : '' }>
        <div className="input-group" styleName={ focused ? 'focus' : '' }>
          { this.renderImage() }
          <input type={ type }
            name={ name }
            value={ value || '' }
            placeholder={ placeholder }
            onChange={ this.onChange }
            onFocus={ this.onFocus }
            onBlur={ this.onBlur }
            required={ required }
            disabled={ disabled }
            ref={ (c) => this.component = c } { ...E2E.$props(testId) } />
        </div>
        <label className="input-validation-label" styleName={ hasError ? 'active' : '' }>{ hasError ? error : '&nbsp;' }</label>
      </div>
    );
  }

}
