import { DateTime } from 'luxon';

const units = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];

/**
 * @param date - ISO string
 * @returns 1 hour ago
 */
export const getTimeAgo = (date: string): string => {
  const dateTime = DateTime.fromISO(date);
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = units.find(unitEl => diff.get(unitEl) !== 0) || 'second';

  const relativeFormatter = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto',
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};

const staticDateFromFirstDayOfMonth = DateTime.local().toUTC(0).set({ day: 1, hour: 0, minute: 0, second: 0 }).toISO();
/**
 * @return ISO date from first day of the month (2022-03-01T00:00:00.000Z)
 */
export const getDateFromFirstDayOfMonth = () => staticDateFromFirstDayOfMonth;

