// @flow
import * as R from 'ramda';

import { type DataProxy } from 'apollo-cache';

import { WORKSPACES_LIST_QUERY } from 'graphql/queries';

type Record = {
  [key: string]: any;
}

/**
 * Update workspace item data at apollo cache with WORKSPACES_LIST_QUERY query
 * `system { workspacesList, workspacesFrontendList }`
 */
export const updateWorkspaceCachedData = ({
  workspaceId,
  newData,
  cache,
}: {
  workspaceId: string,
  newData: Record,
  cache: DataProxy,
}) => {
  const cachedData = cache.readQuery({ query: WORKSPACES_LIST_QUERY });

  const workspacesListItems = R.pathOr([], ['system', 'workspacesList', 'items'], cachedData);
  const workspacesFrontendListItems = R.pathOr([], ['system', 'workspacesFrontendList', 'items'], cachedData);

  const index = R.findIndex(R.propEq('id', workspaceId), workspacesListItems);
  const frontendIndex = R.findIndex(R.propEq('id', workspaceId), workspacesFrontendListItems);


  const getDataForUpdate = () => {
    let dataForUpdate = {};
    if (index !== -1) {
      dataForUpdate = {
        ...dataForUpdate,
        workspacesList: {
          items: R.update(index, {
            ...workspacesListItems[index],
            ...newData,
            __typename: 'SystemWorkspaceItem',
          }),
        },
      };
    }

    if (frontendIndex !== -1) {
      dataForUpdate = {
        ...dataForUpdate,
        workspacesFrontendList: {
          items: R.update(frontendIndex, {
            ...workspacesFrontendListItems[frontendIndex],
            ...newData,
            __typename: 'SystemWorkspaceItem',
          }),
        },
      };
    }

    return dataForUpdate;
  };

  const newSystemData = getDataForUpdate();

  cache.writeQuery({
    query: WORKSPACES_LIST_QUERY,
    data: R.evolve({ system: newSystemData })(cachedData),
  });
};

/**
 * Remove workspace at apollo cache with WORKSPACES_LIST_QUERY query
 * `system { workspacesList, workspacesFrontendList }`
 */
export const deleteWorkspaceFromCache = ({ workspaceId, cache }: { workspaceId: string, cache: DataProxy, }) => {
  const cachedData = cache.readQuery({ query: WORKSPACES_LIST_QUERY });

  const workspacesListItems = R.pathOr([], ['system', 'workspacesList', 'items'], cachedData);
  const workspacesFrontendListItems = R.pathOr([], ['system', 'workspacesFrontendList', 'items'], cachedData);

  const index = R.findIndex(R.propEq('id', workspaceId), workspacesListItems);
  const frontendIndex = R.findIndex(R.propEq('id', workspaceId), workspacesFrontendListItems);

  const getDataForUpdate = () => {
    let dataForUpdate = {};
    if (index !== -1) {
      dataForUpdate = {
        ...dataForUpdate,
        workspacesList: {
          items: R.remove(index, 1),
        },
      };
    }

    if (frontendIndex !== -1) {
      dataForUpdate = {
        ...dataForUpdate,
        workspacesFrontendList: {
          items: R.remove(frontendIndex, 1),
        },
      };
    }

    return dataForUpdate;
  };

  const newSystemData = getDataForUpdate();

  cache.writeQuery({
    query: WORKSPACES_LIST_QUERY,
    data: R.evolve({ system: newSystemData })(cachedData),
  });
};
