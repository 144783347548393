import styled from '@emotion/styled';
import { Icon } from '@8base/boost';

const UserFlowIcon = styled(Icon)({
  borderRadius: '100%',
  width: '12rem',
  border: '1px solid #fff',
  height: '12rem',
  padding: '3rem',
  color: '#fff',
});

export { UserFlowIcon };
