import React from 'react';
import { Button, Icon } from '@8base/boost';


export const ButtonPlus = (props) => (
  <Button
    squared
    type="button"
    variant="outlined"
    color="neutral"
    size="sm"
    withIconAutosize={ false }
    { ...props }
  >
    <Icon
      name="Plus"
      cursor="pointer"
      color="BLUE"
      size="xs"
    />
  </Button>
);
