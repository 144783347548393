// @flow
// $FlowFixMe waiting for update Flow
import React, { useCallback, useMemo } from 'react';
import { Dialog, Grid, Column, Text, Button, useModal, AsyncContent, Modal } from '@8base/boost';
import { Trans } from 'utils/translate';
import { i18n } from 'i18n';
import * as R from 'ramda';
import { css } from '@emotion/core';

import { TRANSFER_STATUSES } from 'common/constants/organization';
import { TransferAcceptForm } from 'common/TransferAcceptForm';
import { useTransferOwnershipDetails, useAcceptTransfer } from 'graphql/hooks';

import { TransferAcceptWorkspaceOwnershipSuccessDialog } from './TransferAcceptWorkspaceOwnershipSuccessDialog';
import { TransferDeclineWorkspaceDialog } from './TransferDeclineWorkspaceDialog';
import { PaymentWarning } from 'common/components';
import { WORKSPACE_KIND } from 'common/constants/workspaceKind';

export const TransferAcceptWorkspaceOwnershipDialog = () => {
  const { openModal } = useModal();
  const { closeModal, args = {}} = useModal(TransferAcceptWorkspaceOwnershipDialog.ID);

  const transferId = R.prop('transferId', args);
  const cacheUpdate = R.prop('cacheUpdate', args);

  const { data, dataLoading, formattedData: {
    newOwnerName,
    newOwnerType,
    newOwnerIdentity,
    transferByName,
    workspaceName,
    workspaceKind,
    workspaceId,
    status,
    plans,
    card,
    region,
  }} = useTransferOwnershipDetails({
    transferId,
  });
  const { accept, acceptLoading } = useAcceptTransfer({ update: cacheUpdate });

  const onAccept = useCallback(async ({ workspaceId, planId, workspaceName, cardToken }) => {
    try {
      await accept({
        variables: {
          workspaceId,
          planId,
          workspaceName,
          cardToken,
        },
      });

      closeModal(TransferAcceptWorkspaceOwnershipDialog.ID);

      const openAsBackend = workspaceKind === WORKSPACE_KIND.general;

      openModal(TransferAcceptWorkspaceOwnershipSuccessDialog.ID, {
        workspaceId,
        workspaceName,
        newOwnerName,
        openAsBackend,
      });

    } catch (e) {}
  }, [accept, closeModal, workspaceKind, openModal, newOwnerName]);

  const onDecline = useCallback(async () => {
    const additionalText = newOwnerType === 'organization'
      ? `“${workspaceName}” to ${newOwnerName}`
      : `“${workspaceName}”`;

    openModal(TransferDeclineWorkspaceDialog.ID, {
      transferId,
      workspaceName,
      additionalText,
      handleDeclineCompleted: () => closeModal(TransferAcceptWorkspaceOwnershipDialog.ID),
    });
  }, [newOwnerType, openModal, transferId, workspaceName, newOwnerName, closeModal]);

  const onClose = useCallback(() => {
    closeModal(TransferAcceptWorkspaceOwnershipDialog.ID);
  }, [closeModal]);

  const handleSubmit = useCallback((values) => onAccept(values), [onAccept]);

  const initialValues = useMemo(() => {
    return {
      workspaceId,
      planId: '',
      cardToken: '',
      workspaceName,
      region,
      agreement: true,
    };
  }, [region, workspaceId, workspaceName]);

  if (status === TRANSFER_STATUSES.aborted) {
    return (
      <Dialog isOpen={ !!transferId && !!data } size="sm" padding="xl" shouldCloseOnOverlayClick>
        <Dialog.Body>
          <Column gap="xl">
            <Grid.Layout stretch columns="350px" justifyContent="center" >
              <Grid.Box>
                <Text color="SECONDARY_TEXT_COLOR" align="center">
                  <Trans i18nKey="home.transferWorkspace.ownership.status.aborted">
                    The <Text weight="semibold">{ workspaceName }</Text> workspace transfer has declined.
                  </Trans>
                </Text>
              </Grid.Box>
            </Grid.Layout>

            <Button
              stretch
              color="neutral"
              variant="outlined"
              onClick={ () => closeModal(TransferAcceptWorkspaceOwnershipDialog.ID) }
            >
              <Trans i18nKey="shared.close">Close</Trans>
            </Button>
          </Column>
        </Dialog.Body>
      </Dialog>
    );
  }

  if (status === TRANSFER_STATUSES.accepted) {
    return (
      <Dialog isOpen={ !!transferId && !!data } size="sm" padding="xl" shouldCloseOnOverlayClick>
        <Dialog.Body>
          <Column gap="xl">
            <Grid.Layout stretch columns="350px" justifyContent="center" >
              <Grid.Box>
                <Text color="SECONDARY_TEXT_COLOR" align="center">
                  <Trans i18nKey="home.transferWorkspace.ownership.status.accepted">
                    The <Text weight="semibold">{ workspaceName }</Text> workspace transfer has accepted.
                  </Trans>
                </Text>
              </Grid.Box>
            </Grid.Layout>

            <Button
              stretch
              color="neutral"
              variant="outlined"
              onClick={ () => closeModal(TransferAcceptWorkspaceOwnershipDialog.ID) }
            >
              <Trans i18nKey="shared.close">Close</Trans>
            </Button>
          </Column>
        </Dialog.Body>
      </Dialog>
    );
  }

  if (dataLoading) {
    return (
      <Modal isOpen={ !!transferId } >
        <AsyncContent loading={ dataLoading } css={ css` margin: 0 auto; ` } />
      </Modal>
    );
  }

  return (
    <Dialog isOpen={ !!transferId } size={ !!card ? 'xl' : 'sm' } padding="xl" shouldCloseOnOverlayClick={ false }>
      <Choose>
        <When condition={ !!card }>
          <Dialog.Header
            title={ i18n.t('home.transferWorkspace.ownership.title', { defaultValue: 'Transfer workspace request' }) }
            onClose={ onClose }
          />
          <TransferAcceptForm
            initialValues={ initialValues }
            acceptLoading={ acceptLoading }
            onClose={ onClose }
            handleSubmit={ handleSubmit }
            transferByName={ transferByName }
            workspaceName={ workspaceName }
            newOwnerName={ newOwnerName }
            newOwnerType={ newOwnerType }
            status={ status }
            onDecline={ onDecline }
            card={ card }
            plans={ plans }
            ContentWrapperComponent={ Dialog.Body }
            FooterWrapperComponent={ Dialog.Footer }
          />
        </When>
        <Otherwise>
          <PaymentWarning
            newOwnerType={ newOwnerType }
            newOwnerIdentisty={ newOwnerIdentity }
            onClose={ onClose }
          />
        </Otherwise>
      </Choose>
    </Dialog>
  );
};

TransferAcceptWorkspaceOwnershipDialog.ID = 'TransferAcceptWorkspaceOwnershipDialog';
