import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/core';

import { useBuildUrl, APP_URL } from 'common/routing';

import { UserFlowLogo } from './UserFlowLogo';

const userFlowHeaderCss = css`
  position: absolute;
  top: 128px;
  left: 0;
  display: flex;
  padding: 0 24px;

  @media (max-width: 767px) {
    top: 50px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const UserFlowLogoHeader = () => {
  const buildUrl = useBuildUrl();

  return (
    <div css={{ position: 'relative' }}>
      <Link css={ userFlowHeaderCss } to={ buildUrl(APP_URL.workspaceHome) }>
        <UserFlowLogo />
      </Link>
    </div>
  );
};

export { UserFlowLogoHeader };
