// @flow

import React from 'react';
import { SecondaryNavigation } from '@8base/boost';

import { NavLink } from 'common/router';

type AppFlowSecondaryNavItemProps = {};

// $FlowFixMe waiting for update Flow
export const AppFlowSecondaryNavItem = React.memo((props: AppFlowSecondaryNavItemProps) => (
  <SecondaryNavigation.Item
    tagName={ NavLink }
    { ...props }
  />
));

