// @flow

import * as R from 'ramda';
import { createSelector } from 'reselect';

import { isDisabledInDataBuilder } from './table.selectors';

import type { Selector } from 'reselect';

import type { ApolloTable } from './table.selectors';

export type ApolloTablesListResponse = $Shape<{
  items: ApolloTable[],
  count: number,
}>;

const idEquals = (someId?: string) => R.propEq('id', someId);

const nameEquals = (someName?: string) => R.propEq('name', someName);

export const getTablesListResponse: Selector<
  ApolloTablesListResponse,
  any,
  ApolloTablesListResponse,
> = (tablesListResponse: ApolloTablesListResponse = { items: [], count: 0 }) => tablesListResponse;

export const getTablesList: Selector<
  ApolloTablesListResponse,
  any,
  ApolloTable[],
> = createSelector(
  getTablesListResponse,
  R.prop('items'),
);

export const getSystemTables = createSelector(
  getTablesList,
  R.filter(R.propEq('isSystem', true)),
);

export const getNoSystemTables = createSelector(
  getTablesList,
  R.filter(R.propEq('isSystem', false)),
);

export const getTablesCount: Selector<
  ApolloTablesListResponse,
  any,
  number,
> = createSelector(
  getTablesListResponse,
  R.prop('count'),
);

export const getTablesListSelectOptions = createSelector(
  getTablesList,
  R.map(
    ({ id, name }) => ({ label: name, value: id }),
  ),
);

export const getTableById: Selector<
  ApolloTablesListResponse,
  string | void,
  ApolloTable,
> = createSelector(
  getTablesList,
  (_, tableId) => tableId,
  (tables, tableId) => R.find(idEquals(tableId), tables),
);

export const getTableByName: any = createSelector(
  getTablesList,
  (_, { tableName }) => tableName,
  (_, { appName }) => appName,
  (tables, tableName, appName) => R.pipe(
    R.filter(({ application, name }) => application ? application.name === appName : !appName),
    R.find(nameEquals(tableName)),
  ) (tables),
);

export const getTableNameById = createSelector(
  getTableById,
  (table) => R.propOr('', 'name', table),
);

export const getTableIdByName = createSelector(
  getTableByName,
  (table) => R.propOr('', 'id', table),
);

export const getTableFieldsByName: Selector<*, string | void, *> = createSelector(
  getTableByName,
  (table) => R.propOr('', 'fields', table),
);

export const getTableDisplayNameById = createSelector(
  getTableById,
  (table) => (table && table.displayName) || '',
);

export const getTablesNames = createSelector(
  getTablesList,
  R.map(R.prop('displayName')),
);

export const getTablesNamesExceptedById = createSelector(
  getTablesList,
  (_, exceptedIdList: string[]) => exceptedIdList,
  (tableList, exceptedIdList) => R.pipe(
    R.filter(({ id }) => !R.contains(id, exceptedIdList)),
    R.map(R.prop('displayName')),
  )(tableList),
);

export const getEnabledInDataBuilderTablesList: Selector<
  ApolloTablesListResponse,
  any,
  ApolloTable[],
> = createSelector(
  getTablesList,
  R.reject(isDisabledInDataBuilder),
);

