// @flow
import React from 'react';
import styled from '@emotion/styled';
import { AsyncContent, Avatar, Column, Heading, Row, Text, Icon } from '@8base/boost';
import { DateTime } from 'luxon';
import { css } from '@emotion/core';

import { useUserAccountInfo } from 'graphql/hooks';

const DeveloperHomeNavUserInfoWrapperTag = styled.div`
  height: 150px;
  padding: 32px;
  border-bottom: 1px solid ${props => props.theme.COLORS.GRAY_20};
`;


export const DeveloperHomeNavUserInfo = () => {
  const { userAccountInfo, loading } = useUserAccountInfo();

  return (
    <DeveloperHomeNavUserInfoWrapperTag>
      <AsyncContent loading={ loading } stretch>
        <Row alignItems="center" gap="md">
          <Avatar
            size="stretch"
            css={ css`width: 72px; max-width: 72px; height: 72px; flex: 1 0 72px;` }
            firstName={ userAccountInfo.firstName }
            lastName={ userAccountInfo.lastName }
            src={ userAccountInfo.avatar && userAccountInfo.avatar.downloadUrl }
          />
          <Column gap="none" css={ css`word-break: break-all;` }>
            <Heading type="h2">{ userAccountInfo.firstName } { userAccountInfo.lastName }</Heading>
            <Text kind="overline-1">{ userAccountInfo.email }</Text>
            <Row alignItems="center" gap="sm">
              <Icon name="CalendarLocal" size="xs" color="GRAY_40" />
              <Text kind="body">Joined { DateTime.fromISO(userAccountInfo.createdAt).setLocale('en').toLocaleString(DateTime.DATE_MED) }</Text>
            </Row>
          </Column>
        </Row>
      </AsyncContent>
    </DeveloperHomeNavUserInfoWrapperTag>
  );
};
