// @flow

import React from 'react';
import styled from '@emotion/styled';
import { Row, Icon, Text } from '@8base/boost';

export const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '350px',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 100,
});

export const DummyHead = styled('div')({
  width: '100%',
  height: 0,
});

export const AbsolutePosition = styled('div')({
  position: 'absolute',
  top: 3,
  left: 0,
  right: 0,
  height: 0,
});

export const Body = styled('div')({
  position: 'relative',
  top: '-5px',
});

export const ItemPaddings = styled('div')(props => ({
  padding: '0 32px',
  width: '100%',
}));

export const Divider = styled('div')(props => ({
  height: '1px',
  border: 'none',
  width: '100%',
  margin: '7px 0 0 0',
  backgroundColor: props.theme.COLORS.GRAY_30,
}));

export const WorkspaceItem = styled('a')(props => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '44px',
  padding: '0 32px',
  cursor: 'pointer',

  '&:hover': {
    boxShadow: `0 2px 10px 0 ${props.theme.COLORS.GRAY_30}`,
  },
}));

export const WorkspacesList = styled('div')({
  maxHeight: '230px',
  width: '100%',
  overflowY: 'auto',
  paddingBottom: '10px',
  flexShrink: 0,
});

const TitleHeight = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '56px',
});

const TitleWrapper = styled('div')(props => ({
  borderRadius: props.theme.SIZES.MAIN_BORDER_RADIUS,
  height: '4rem',
  maxWidth: '350px',
  padding: '0 20px',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: props.isOpen ? 'transparent' : props.theme.COLORS.GRAY_10,
  },
}));

export const Title = ({ selectedWorkspace, isOpen, onClick, ...rest }: *) => (
  <TitleHeight { ...rest }>
    <TitleWrapper onClick={ onClick } isOpen={ isOpen }>
      <Row alignItems="center" gap="sm" stretch>
        <Text kind="subtitle" ellipsis { ...E2E.$props('workspaceSelector.currentName') }>{ selectedWorkspace }</Text>
        <Icon name="ChevronDown" size="xs" />
      </Row>
    </TitleWrapper>
  </TitleHeight>
);

