// @flow

export const CONNECTIONS = {
  FACEBOOK: 'facebook',
  GITHUB: 'github',
  GOOGLE: 'google',
};

export const PROFILE_TYPE = {
  '8BASE': '8BASE',
  '8BASE_COGNITO': '8BASE_COGNITO',
  CUSTOM_AUTH0: 'CUSTOM_AUTH0',
  OPENID: 'OPENID',
};

export const PROFILE_NAMES = {
  [PROFILE_TYPE['8BASE']]: '8base Authentication',
  [PROFILE_TYPE['8BASE_COGNITO']]: '8base Authentication',
  [PROFILE_TYPE.CUSTOM_AUTH0]: 'Your Auth0 Account',
  [PROFILE_TYPE.OPENID]: 'OpenID',
};
