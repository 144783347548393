// @flow
import React from 'react';
import { Column, Text } from '@8base/boost';
import { css } from '@emotion/core';
import { Trans } from 'utils/translate';

export const DeveloperHomeOrganizationEmptyWorkspaces = () => {
  return (
    <div css={ css`overflow: auto; width: 100%; padding: 0 32px; display: flex; justify-content: center;` }>
      <Column gap="none" alignItems="center" css={ css` max-width: 835px; ` }>
        <React.Fragment>
          <Text kind="subtitle" color="GRAY_50" align="center" >
            <Trans i18nKey="developerHome.organizationPage.noWorkspaceFound.emptyManager" />
          </Text>
          <Text kind="subtitle" color="GRAY_50" align="center" >
            <Trans i18nKey="developerHome.organizationPage.noWorkspaceFound.manager" />
          </Text>
        </React.Fragment>
      </Column>
    </div>
  );
};
