// @flow

import fp from 'lodash/fp';
import _ from 'lodash';
import { reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';

export const mapValuesFromFormArray = ({ state, ownProps, reduxFormName, propsName, propsToFind, connectedFields }: any) => {
  const findId = ownProps[propsToFind];
  const selector = formValueSelector(reduxFormName);

  return fp.pipe(
    fp.find((val) => {
      const id = _.get(val, 'id');
      if (findId) return _.isEqual(id, findId);
      return !id;
    }),
    // $FlowFixMe
    fp.omitBy(_.includes(connectedFields)),
  )(selector(state, propsName));
};

/**
 * add redux-form formsArray field params to the props
 *
 * @param {*} reduxFormName - form name
 * @param {*} propsName - prop name for array data in redux form
 * @param {*} propsToFind - prop name to find value from array,specified in propsName
 * @param {*} connectedFields - prop names from founded field to add to component
 */
export const withConnectedValuesFromFormArray = (reduxFormName: string, propsName: string, propsToFind: string, connectedFields: string[]) =>
  // $FlowFixMe
  connect(
    (state, ownProps) => mapValuesFromFormArray({ state, ownProps, reduxFormName, propsName, propsToFind, connectedFields }),
  );

/** transform array of the fields to the object with field name as key and field state as value */
export const mapFieldsArrayToProps = (state: Object, reduxFormName: string, connectedFields: string[]) => {
  const selector = formValueSelector(reduxFormName);

  return fp.reduce(
    (fieldsProps, field) => fp.set([field], selector(state, field), fieldsProps),
    {},
    connectedFields,
  );
};

/** add redux-form fields to the props */
export const withConnectedFormFields =
  (reduxFormName: string, connectedFields: string[]): { (WrappedComponent: React$ComponentType<*>): React$ComponentType<*> } =>
    connect(
      (state): * => mapFieldsArrayToProps(state, reduxFormName, connectedFields),
      null,
    );

/** create form and add some fields from this form to the props */
export const reduxFormWithConnect =
  <Fields: Array<string>>(reduxFormConfig: *, connectedFields: Fields): { (WrappedComponent: React$ComponentType<*>): React$ComponentType<*> } =>
    compose(
      reduxForm(reduxFormConfig),
      withConnectedFormFields(reduxFormConfig.form, connectedFields),
    );

export const withChangeFieldValueForCurrentForm = compose(
  connect(
    () => ({}),
    (dispatch: *, ownProps) => ({ changeFieldValue: (fieldName, value) => {
      dispatch(change(ownProps.form, fieldName, value));
    } }),
  ),
  withHandlers({
    changeFieldValue: ({ changeFieldValue }) =>
      (fieldName: string, getCondition: (mixed) => boolean | boolean, getValue: (mixed) => mixed | mixed) =>
        (e: Event, value: mixed) => {
          const condition = fp.isFunction(getCondition) ? getCondition(value) : getCondition;
          const fieldValue = fp.isFunction(getValue) ? getValue(value) : getValue;
          if (condition) changeFieldValue(fieldName, fieldValue);
        },
  }),
);
