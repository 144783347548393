// @flow

import React from 'react';
import * as R from 'ramda';
import { css } from '@emotion/core';
import { Form as FormLogic, Field } from '@8base-react/forms';
import { Dialog, Button, InputField, AsyncContent, Form, useModal } from '@8base/boost';
import { graphql, Query } from 'react-apollo';

import { i18n } from 'i18n';
import { withTranslation } from 'react-i18next';
import { Trans } from 'utils/translate';
import { TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';
import { ROLE_UPDATE_MUTATION } from 'graphql/mutations';
import { ROLE_QUERY } from 'graphql/queries';

type RoleUpdateDialogProps = {
  usersData: Object,
  t: (string) => React$Node,
  roleUpdate: (Object) => Promise<Object>,
};

const ROLE_UPDATE_DIALOG_ID = 'ROLE_UPDATE_DIALOG_ID';

let RoleUpdateDialog = ({ t, roleUpdate, usersData }: RoleUpdateDialogProps) => {
  const { closeModal } = useModal();

  const onClose = () => closeModal(ROLE_UPDATE_DIALOG_ID);

  const onSubmit = async (id, data) => {
    await roleUpdate({ variables: { data: { id, description: data.description || '', ...data }}});

    window.trackEvent('Roles', 'Update role');

    onClose();
  };

  return (
    <Dialog
      id={ ROLE_UPDATE_DIALOG_ID }
      onClose={ onClose }
      size="lg"
      { ...E2E.$props('settings.roles.editDialog.body') }
    >
      { ({ args: { id }}) => (
        <Query query={ ROLE_QUERY } variables={{ id }}>
          { ({ data, loading, ...rest }) => (
            <FormLogic
              type="UPDATE"
              tableSchemaName="Roles"
              onSubmit={ onSubmit.bind(null, id) }
              initialValues={ data && data.role }
              formatRelationToIds
            >
              { ({ handleSubmit, invalid, submitting, pristine }) => (
                <form onSubmit={ handleSubmit } css={ css`height: 100%;` }>
                  <Dialog.Header title={ t('settings.roles.editRole') } onClose={ onClose } />
                  <Dialog.Body>
                    <AsyncContent loading={ loading } stretch>
                      <Form component="div">
                        <Field
                          name="name"
                          label="Name"
                          placeholder="Name"
                          component={ InputField }
                          { ...E2E.$props('settings.roles.editDialog.nameInput') }
                          required
                        />
                        <Field
                          name="description"
                          label="Description"
                          placeholder="Description"
                          component={ InputField }
                          { ...E2E.$props('settings.roles.editDialog.descriptionInput') }
                        />
                      </Form>
                    </AsyncContent>
                  </Dialog.Body>
                  <Dialog.Footer>
                    <Button type="button" color="neutral" variant="outlined" onClick={ onClose }>
                      <Trans i18nKey="shared.cancel">Cancel</Trans>
                    </Button>
                    <Button
                      { ...E2E.$props('settings.roles.editDialog.submitButton') }
                      type="submit"
                      disabled={ pristine }
                      loading={ submitting }
                      onClick={ handleSubmit }
                    >
                      <Trans i18nKey="shared.update">Update</Trans>
                    </Button>
                  </Dialog.Footer>
                </form>
              ) }
            </FormLogic>
          ) }
        </Query>
      ) }
    </Dialog>
  );
};

RoleUpdateDialog = R.compose(
  withTranslation(),
  graphql(ROLE_UPDATE_MUTATION, {
    name: 'roleUpdate',
    options: () => ({
      context: {
        [TOAST_SUCCESS_MESSAGE]: i18n.t('shared.dataUpdateSuccessful'),
      },
      refetchQueries: () => [
        'RolesListWithUsers',
      ],
    }),
  }),
)(RoleUpdateDialog);

RoleUpdateDialog.ID = ROLE_UPDATE_DIALOG_ID;

export { RoleUpdateDialog };
