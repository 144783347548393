import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { addPropsToElement } from 'utils/addProps';

export default class NavigationLeftCommonHelper extends Component {

  static propTypes = {
    children: PropTypes.node.isRequired,
    activeNavName: PropTypes.string,
    fixed: PropTypes.bool,
  };

  static defaultProps = {
    fixed: false,
  }

  render() {
    const { children, activeNavName, fixed } = this.props;
    const navClassName = cx(
      'sidebar',
      { 'fixed-sidebar': fixed },
    );

    return (
      <div className={ navClassName }>
        { React.Children.map(children, child => addPropsToElement({ activeNavName, ...child.props }, child)) }
      </div>
    );
  }
}
