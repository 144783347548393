import gql from 'graphql-tag';

export const ORGANIZATION_BASE_INFO_FRAGMENT = gql`
  fragment OrganizationItemBaseInfo on SystemOrganizationItem {
    id
    name
    type
    description
  }
`;


export const ORGANIZATION_SHORT_INFO_FRAGMENT = gql`
  fragment OrganizationItemShortInfo on SystemOrganizationItem {
    ...OrganizationItemBaseInfo
    image {
      id
      downloadUrl
    }
  }

  ${ORGANIZATION_BASE_INFO_FRAGMENT}
`;


export const ORGANIZATION_FULL_INFO_FRAGMENT = gql`
  fragment OrganizationItemFullInfo on SystemOrganizationItem {
    ...OrganizationItemBaseInfo
    image {
      id
      downloadUrl
    }
    users {
      id
      firstName
      lastName
      email
      role
      status
      workspaces {
        id
        name
        description
        image {
          id
          downloadUrl
        }
        kind
        apiHost
        organization {
          id
          name
          type
          description
        }
      }
      avatar {
        id
        downloadUrl
      }
    }
  }

  ${ORGANIZATION_BASE_INFO_FRAGMENT}
`;
