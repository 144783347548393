//@flow
// $FlowFixMe waiting for update Flow
import { useEffect, useContext } from 'react';
import * as Sentry from '@sentry/browser';
import { AuthContext } from '@8base-react/auth';

type SentryScopeProps = {
  children: React$Node,
};

const SentryScope = ({ children }: SentryScopeProps) => {
  const { authState, isAuthorized } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthorized) {
      const { email, teamMemberId, workspaceId } = authState;

      Sentry.configureScope((scope) => {
        scope.setUser({
          email,
          id: teamMemberId,
          workspaceId,
        });
      });
    }
  }, [authState, isAuthorized]);

  return children;
};

export { SentryScope };
