// @flow
// $FlowFixMe
import React from 'react';
import { Heading, Table } from '@8base/boost';
import styled from '@emotion/styled';

type TableGroupTitleProps = {
  title: string,
  counter: number,
}

const Counter = styled.div` 
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  width: 16px;
  height: 16px;
  background: ${props => props.theme.COLORS.GRAY_20};
  color: ${props => props.theme.COLORS.GRAY_50};
  border-radius: 50%;
`;

export const TableGroupTitle = ({ title, counter }: TableGroupTitleProps) => (
  <Table.BodyRow borderedLess>
    <Table.BodyCell>
      <Heading ellipsis type="h4">{ title }</Heading>
      <Counter>{ counter }</Counter>
    </Table.BodyCell>
  </Table.BodyRow>
);
