export const BILLING_PLAN_STATUS = {
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  BLOCKED: 'blocked',
  CANCELING: 'canceling',
  CANCELED: 'canceled',
  PENDING: 'pending',
  UPCOMING: 'upcoming',
};

export const PAYED_PLAN_STATUSES = [
  BILLING_PLAN_STATUS.ACTIVE,
  BILLING_PLAN_STATUS.SUSPENDED,
  BILLING_PLAN_STATUS.BLOCKED,
];

