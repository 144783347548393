// @flow

import React, { PureComponent } from 'react';
import { Portal } from 'react-portal';
import { Z_INDEX } from '@8base/boost';

import './Modal.scss';

const POPUP_BODY_CLASS = 'modal-shown';
const ESCAPE_CODE = 27;

/**
 * @prop {*} closeOnClickOutside if true then cloese on popup overlay click
 * @prop {*} closeOnEscape if true then close on escape event
 * @prop {*} onModalClose callback to close popup
 */
type ModalProps = {
  children: React$Node,
  closeOnClickOutside?: boolean,
  closeOnEscape?: boolean,
  onModalClose?: (event : Event) => void | () => void,
  isOpen: boolean,
}

type ModalState = {
  zIndex: number,
}

/** component that shows the modal*/
export class Modal extends PureComponent<ModalProps, ModalState> {
  /** this static value needs for the multiple modals */
  static zIndexIncrement: number = Z_INDEX.MODAL;
  static openendModals: number = 0;

  static defaultProps: $Shape<ModalProps> = {
    closeOnClickOutside: true,
    closeOnEscape: true,
    isOpen: false,
  }

  state = {
    zIndex: 0,
  }

  componentDidMount() {
    this.subscribeToEscapeEvent();

    if (this.props.isOpen) {
      this.onOpenModal();
    }
  }

  componentWillUnmount() {
    this.unsubscribeToEscapeEvent();

    if (this.props.isOpen) {
      this.onCloseModal();
    }
  }

  subscribeToEscapeEvent() {
    document.addEventListener('keydown', this.closeOnEscape);
  }

  unsubscribeToEscapeEvent() {
    document.removeEventListener('keydown', this.closeOnEscape);
  }

  UNSAFE_componentWillReceiveProps(nextProps: ModalProps) {
    if (nextProps.isOpen !== this.props.isOpen) {
      if (nextProps.isOpen) {
        this.onOpenModal();
      } else {
        this.onCloseModal();
      }
    }
  }

  closeOnEscape = (event: *) => {
    const { onModalClose, closeOnEscape } = this.props;

    if (event.keyCode === ESCAPE_CODE && closeOnEscape && onModalClose) {
      onModalClose(event);
    }
  }

  onOpenModal() {
    Modal.openendModals++;
    Modal.zIndexIncrement += 5;

    this.setState({
      zIndex: Modal.zIndexIncrement,
    });

    if (Modal.openendModals > 0) {
      this.addBodyModalClass();
    }
  }

  onCloseModal() {
    Modal.openendModals--;

    if (Modal.openendModals === 0) {
      this.deleteBodyModalClass();
    }
  }

  /** adding a class to the body is necessary because of the filter css style */
  addBodyModalClass() {
    const { body }: Object = document;

    if (!body.classList.contains(POPUP_BODY_CLASS)) {
      body.classList.add(POPUP_BODY_CLASS);
    }
  }

  deleteBodyModalClass() {
    const { body }: Object = document;

    if (body.classList.contains(POPUP_BODY_CLASS)) {
      body.classList.remove(POPUP_BODY_CLASS);
    }
  }

  onOutsideClick = (event: Event) => {
    const { closeOnClickOutside, onModalClose } = this.props;

    if (closeOnClickOutside && onModalClose) {
      onModalClose(event);
    }
  }

  render() {
    const { children, isOpen } = this.props;
    const { zIndex } = this.state;

    return (
      <If condition={ isOpen }>
        <Portal>
          <div>
            <div styleName="modal-overlay" style={{ zIndex }} onClick={ this.onOutsideClick } />
            <div styleName="modal" style={{ zIndex: zIndex + 1 }}>
              { children }
            </div>
          </div>
        </Portal>
      </If>
    );
  }
}
