import { getCookieDomain } from './getCookieDomain';

export const trackVisitorId = (visitorId: ?string) => {
  if (visitorId) {
    const VISITOR_ID_NAME = '8base-visitor-id';

    const expires = new Date();

    expires.setMonth(expires.getMonth() + 1);

    const domain = getCookieDomain();

    document.cookie = `${encodeURI(VISITOR_ID_NAME)}=${encodeURI(visitorId)};domain=${domain};path=/;expires=${expires.toUTCString()}`;

    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({ userID: visitorId });
  }
};
