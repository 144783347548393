// @flow
import React, { PureComponent } from 'react';
import * as R from 'ramda';
import { compose, withStateHandlers, setDisplayName, type HOC } from 'recompose';
import { withFocusBase, mapReduxFormInput } from 'utils/hocs/forms';
import { InputBase, Card } from 'common';
import { Dropdown, Link, Row } from '@8base/boost';

import { DraggableSelectItem } from './DraggableSelectItem';
import {
  getFilteredItems, updateItemChecked, updateItemPosition,
  checkAllItems, unCheckAllItems, getIsAllItemChecked, getIsAllItemUnchecked,
} from './SelectDragList.selectors';

import './SelectDragList.scss';

/** interface of the list item */
export type SelectDragListOption = {
  id: string,
  label: string,
  checked: boolean,
}

/**
 * @prop {*} head callback to get the component to control dropdown opened
 * @prop {*} values list of the values which must be changed by the current control
 * @prop {*} pin regulate control content position relative control head
 * @prop {*} onChangeValues callback to change values
 */
export type SelectDragListProps = {|
  head: ({isOpen: boolean}) => React$Node,
  values: SelectDragListOption[],
  pin?: 'left' | 'right',
  onChangeValues: (updatedValues: SelectDragListOption[], event?: Event) => void,
  withPortal?: boolean,
|}

/**
 * @prop {*} values internal state to contain temporary draggable position
 */
export type SelectDragListBaseState = {|
  localValues: SelectDragListOption[],
|}

export const FocusedInput = withFocusBase((props: *) => <InputBase { ...props } />);

export const selectDragListEnhancer: HOC<*, SelectDragListProps> = compose(
  setDisplayName('SelectDragList'),

  /** contain state of the dropdown opened  */
  withStateHandlers(
    { isDropdownOpen: false },
    {
      closeDropdown: () => () => ({ isDropdownOpen: false }),
      openDropdown: () => () => ({ isDropdownOpen: true }),
    },
  ),

  /** contain state of the input focused */
  withStateHandlers(
    { focusedInput: true },
    {
      blurToInput: () => () => ({ focusedInput: false }),
      focusToInput: () => () => ({ focusedInput: true }),
    },
  ),

  /** contain state of the searching label */
  withStateHandlers(
    { searchLabel: '' },
    {
      onChangeSearchLabel: () => (searchLabel: string) => ({ searchLabel }),
    },
  ),
);

type SelectDragListBaseProps = HOCBase<typeof selectDragListEnhancer>

/**
 * component has internal values state and external values state.
 * internal state is used for the temporary update hovered draggable position.
 * external state is used for the control the state of the component.
 * de-facto it's a stateless component
 */
export class SelectDragListBase extends PureComponent<SelectDragListBaseProps, SelectDragListBaseState> {

  static defaultProps: $Shape<SelectDragListProps> = {
    pin: 'left',
    withPortal: false,
  }

  constructor(...args: *) {
    super(...args);
    this.state = { localValues: this.props.values };
  }

  /** update internal state by the external prop */
  UNSAFE_componentWillReceiveProps(nextProps: *) {
    this.reinitializeLocalValues(nextProps);
  }

  /** reinitialize local values state by the change values prop */
  reinitializeLocalValues(nextProps: SelectDragListProps) {
    if (!R.equals(nextProps.values, this.state.localValues)) {
      this.updateLocalValues(nextProps.values);
    }
  }

  /** reinitialize local values state by the custom action */
  forceReinitializeLocalValues = () => {
    this.updateLocalValues(this.props.values);
  }

  /** pass local values to the out */
  reinitializeOutValues = () => {
    this.props.onChangeValues(this.state.localValues);
  }

  /** update state of the values */
  updateLocalValues = (updatedLocalValues: SelectDragListOption[]) => {
    this.setState({ localValues: updatedLocalValues });
  }

  /** change checked item. pass new values in out by the callback. */
  changeItemChecked = (itemId: string, checkedState: boolean) => {
    const updatedValues = updateItemChecked(this.state.localValues, itemId, checkedState);

    this.props.onChangeValues(updatedValues);
  }

  checkAllItems = () => {
    this.props.onChangeValues(checkAllItems(this.state.localValues));
  }

  unCheckAllItems = () => {
    this.props.onChangeValues(unCheckAllItems(this.state.localValues));
  }

  /** change position. update local state. */
  changePosition = (dragId: string, hoverId: string) => {
    const { localValues } = this.state;
    const updatedValues = updateItemPosition(localValues, { dragId, hoverId });

    this.updateLocalValues(updatedValues);
  }

  /** update external state */
  setDndPosition = () => {
    this.reinitializeOutValues();
  }

  /** cancel local state changes */
  cancelDndPosition = () => {
    this.forceReinitializeLocalValues();
  }

  render() {
    const {
      pin,
      head,
      searchLabel,
      onChangeSearchLabel,
      isDropdownOpen,
      closeDropdown,
      openDropdown,
      focusedInput,
      focusToInput,
      blurToInput,
      withPortal,
    } = this.props;
    const { localValues } = this.state;

    const filteredItems = getFilteredItems(localValues, searchLabel);
    const isAllItemChecked = getIsAllItemChecked(localValues);
    const isAllItemUnchecked = getIsAllItemUnchecked(localValues);

    const footer = (
      <Row justifyContent="center" alignItems="center" gap="sm">
        <Link underline={ isAllItemChecked } color={ isAllItemChecked ? 'BLUE_30' : 'GRAY_50' } onClick={ this.checkAllItems }>Show All</Link>
        <div css={{ width: 1, height: 16, background: '#7e868e' }} />
        <Link underline={ isAllItemUnchecked } color={ isAllItemUnchecked ? 'BLUE_30' : 'GRAY_50' } onClick={ this.unCheckAllItems }>Hide All</Link>
      </Row>
    );

    return (
      <Dropdown
        isOpen={ isDropdownOpen }
        onCloseDropdown={ closeDropdown }
        onOpenDropdown={ openDropdown }
      >
        <Dropdown.Head>
          { head({ isOpen: isDropdownOpen }) }
        </Dropdown.Head>
        <Dropdown.Body
          positionFixed
          pin={ pin }
          withPortal={ withPortal }
          modifiers={{ preventOverflow: { enabled: true }, hide: { enabled: false }}}
        >
          <div styleName="select-drag-list">
            <Card footer={ footer }>
              <div styleName="search-panel" >
                <FocusedInput
                  active={ focusedInput }
                  leftIcon={ <span className="icon icon-search" /> }
                  type="text"
                  value={ searchLabel }
                  onChange={ onChangeSearchLabel }
                />
              </div>
              <div styleName="list-panel-scrollable">
                <div styleName="list-panel">
                  { filteredItems.map((listItem, index) => (
                    <DraggableSelectItem
                      key={ listItem.id }
                      id={ listItem.id }
                      label={ listItem.label }
                      checked={ listItem.checked }
                      index={ index }
                      onItemClick={ focusToInput }
                      onChangeChecked={ this.changeItemChecked }
                      onChangePosition={ this.changePosition }
                      onSuccessDrop={ this.setDndPosition }
                      onCancelDrop={ this.cancelDndPosition }
                      onEndDrag={ blurToInput }
                      onBeginDrag={ focusToInput }
                    />
                  )) }
                </div>
              </div>
            </Card>
          </div>
        </Dropdown.Body>
      </Dropdown>
    );
  }
}


/** control with draggable checked list */
export const SelectDragList = selectDragListEnhancer(SelectDragListBase);

export const SelectDragListField = compose(
  // $FlowFixMe
  setDisplayName('SelectDragListField'),
  mapReduxFormInput(
    ({ value, onChange, ...rest }) => ({ ...rest, values: value, onChangeValues: onChange }),
  ),
)(SelectDragList);
