// @flow

import React from 'react';
import './InputHelper.scss';

// $FlowFixMe waiting for update Flow
export const InputHamburger = React.memo(() => (
  <div styleName="dnd-helper">
    <span className="icon icon-hamburger" />
  </div>
));
