import styled from '@emotion/styled';

export const UserFlowFormContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  width: 750px;
  background: #23242D;
  border-radius: 12px;
  padding: 32px;

  @media (max-width: 992px) {
    max-width: 100%;
  }
`;
