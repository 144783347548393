import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class ButtonHeadCommonNavigationLeftCommonHelper extends Component {

  static propTypes = {
    image: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
  };

  onClick = (e) => {
    const { onClick } = this.props;
    if (onClick) onClick(e);
    e.stopPropagation();
  };

  render() {
    const { image, icon } = this.props;
    return (
      <div className="nav-button" onClick={ this.onClick }>
        <Choose>
          <When condition={ image }>
            <img
              src={ image }
              alt="Image"
              data-e2e-id={ this.props['data-e2e-id'] }
            />
          </When>
          <When condition={ icon }>
            <span
              className={ `icon-${icon}` }
              data-e2e-id={ this.props['data-e2e-id'] }
            />
          </When>
        </Choose>
      </div>
    );
  }
}
