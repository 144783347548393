// @flow

import React from 'react';
import styled from '@emotion/styled';
import { Text } from '@8base/boost';

import { i18n } from 'i18n';

type TableFooterProps = {
  totalRecordCount: number,
  selectedRecordCount: number,
  showLoader?: boolean,
};

const PositionWrapper = styled('div')`
  position: absolute;
  z-index: 5;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #F4F7F9;
  border-radius: 0 5px;
  overflow: hidden;
`;

const Container = styled('div')`
  height: 32px;
  background-color: #F4F7F9;
  display: flex;
  align-items: center;
  padding-left: 28px;
`;

export const TableFooter = ({
  totalRecordCount,
  selectedRecordCount,
  showLoader,
}: TableFooterProps) => {
  return (
    <PositionWrapper>
      <Container>
        <If condition={ !showLoader || totalRecordCount !== 0 }>
          <Text color="SECONDARY_TEXT_COLOR">
            <Choose>
              <When condition={ selectedRecordCount === 0 }>
                { totalRecordCount } { i18n.t('dataViewer.record', {
                  count: totalRecordCount,
                  defaultValue: 'record',
                  defaultValue_plural: 'records',
                }) }
              </When>
              <Otherwise>
                { selectedRecordCount } { i18n.t('dataViewer.record', {
                  count: selectedRecordCount,
                }) } selected of { totalRecordCount }
              </Otherwise>
            </Choose>
          </Text>
        </If>
      </Container>
    </PositionWrapper>
  );
};

