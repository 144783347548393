import gql from 'graphql-tag';


export const ROLE_QUERY = gql`
  query Role($id: ID!) {
    role(id: $id) {
      id
      name
      description
      users {
        items {
          id
        }
      }
    }
  }
`;

export const ROLES_LIST_QUERY = gql`
  query RolesList {
    rolesList {
      items {
        id
        name
        description
      }
    }
  }
`;

export const ROLES_LIST_WITH_USERS_QUERY = gql`
  query RolesListWithUsers($query: String, $orderBy: [RoleOrderBy]) {
    rolesList(
      filter: {
        OR: [{
          name: {
            contains: $query
          }
        }, {
          description: {
            contains: $query
          }
        }, {
          _fullText: $query
        }]
      },
      orderBy: $orderBy
    ) {
      items {
        id
        name
        description
        users {
          items {
            id
          }
          count
        }
        membersCount
      }
    }
  }
`;

