// @flow

// $FlowFixMe
import React, { useCallback, useEffect } from 'react';
import * as R from 'ramda';
import { AsyncContent, Icon } from '@8base/boost';
import {
  createTableRowQueryTag,
  tableFieldSelectors,

} from '@8base/utils';
import { useTablesList } from '@8base-react/table-schema-provider';
import { SchemaNameGenerator } from '@8base/schema-name-generator';
import { css } from '@emotion/core';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { getFormattedValue, getInitialEditorState, TOOLBAR_CONFIG } from './utils';

const editorContainerClassName = css`
  .DraftEditor-source, .DraftEditor-editorContainer {
    padding: 0 8px;
    height: 500px;
    overflow: auto;
    font-family: "Poppins", sans-serif;
    resize: none
  }

  .rdw-editor_raw-mode {
    display: none;
    visibility: hidden;
  }

  .rdw-editor-toolbar {
      border-top: none;
  }

  .rdw-option-wrapper {
    margin: 0 2px;
  }

  .DraftEditor-editorContainer {
    h1 {
      font-size: 2em;
    }

    h2 {
      font-size: 1.5em;
    }

    h3 {
      font-size: 1.2em;
    }

    h4 {
      font-size: 1.5em;
    }

    h5 {
      font-size: 1.25em;
    }

    h6 {
      font-size: 1.125em;
    }

    li {
      display: list-item;
    }

    a {
      color: #00e;
      text-decoration: underline;
    }
  }
`;

export const ViewReachTextFieldDialogBody = ({
  tableId,
  tableName,
  fieldName,
  format,
  fieldId,
  recordId,
  value,
  setValue,
  setOldValue,
  isRawMode,
  setRawMode,
  rawValue,
  setRawValue,
}: *) => {
  const { tablesList } = useTablesList();

  const { data, loading } = useQuery(gql(createTableRowQueryTag(tablesList, tableId, {
    filterFields: R.pipe(
      tableFieldSelectors.getFieldId,
      R.equals(fieldId),
    ),
  })), {
    skip: !recordId,
    variables: { id: recordId },
  });

  useEffect(() => {
    const contentPath = [SchemaNameGenerator.getTableItemFieldName(tableName), fieldName];
    const content = R.pathOr('', contentPath, data);
    const editorState = getInitialEditorState(content, format);
    setValue(editorState);
    setOldValue(content);

  }, [data, fieldName, format, setOldValue, setValue, tableName]);

  const onChangeMode = useCallback(
    () => {
      if (isRawMode) {
        const editorState = getInitialEditorState(rawValue, format);
        setValue(editorState);
      } else {
        const newRawValue = value ? getFormattedValue(format, value) : null;
        setRawValue(newRawValue);
      }

      setRawMode(!isRawMode);
    },
    [format, isRawMode, rawValue, setRawMode, setRawValue, setValue, value],
  );


  return (
    <AsyncContent loading={ loading || !value } stretch>
      <div css={ editorContainerClassName } >
        <Editor
          readOnly={ isRawMode }
          editorState={ value }
          onEditorStateChange={ setValue }
          toolbar={ TOOLBAR_CONFIG }
          editorClassName={ isRawMode ? 'rdw-editor_raw-mode' : undefined }
          toolbarCustomButtons={ [
            <div key="previewBtn" className="rdw-option-wrapper" onClick={ onChangeMode } >
              <Icon name="Eye" size="sm" color={ isRawMode ? 'BLUE_10' : undefined } cursor="pointer" />
            </div>,
          ] }
        />
        { isRawMode && (
          <textarea
            className="DraftEditor-source"
            value={ rawValue }
            onChange={ (event: Object) => {
              const source = event.target.value;

              setRawValue(source);
            } }
          />
        ) }
      </div>
    </AsyncContent>
  );
};
