// @flow
// $FlowFixMe
import { useEffect } from 'react';
import { useAuth } from '@8base-react/auth';
import { isProductionEnv, isStagingEnv } from 'utils/processEnv';

// check auth0 session only at production and staging stand
const checkSession = async ({ authClient, isAuthorized }) => {
  if (!isAuthorized) {
    return;
  }

  if (isStagingEnv() || isProductionEnv()) {
    try {
      await authClient.checkSession();
    } catch (err) {
      authClient.logout();
    }
  }

  return;
};

export const AuthSessionChecker = () => {
  const { authClient, isAuthorized } = useAuth();

  useEffect(() => {
    checkSession({ authClient, isAuthorized });
  }, [authClient, isAuthorized]);

  return null;
};
