// @flow
// $FlowFixMe
import { styled } from '@8base/boost';

export const DetailImg = styled.img`
  border: 1px solid ${props => props.theme.COLORS.SECONDARY_BORDER_COLOR};
  object-fit: cover;
  border-radius: 5px;
  height: 22px;
  min-height: 22px;
  max-height: 22px;
  width: 22px;
  min-width: 22px;
  max-width: 22px;
`;
