import gql from 'graphql-tag';

export const BILLING_INVOICES_LIST_QUERY = gql`
  query BillingInvoicesList {
    billingInvoicesList {
      items {
        periodStart
        periodEnd
        paid
        invoicePdf
        amountDue
        amountPaid
        amountRemaining
        endingBalance
        number
        status
        total
        description
      }
    }
  }
`;

export const BILLING_CURRENT_PLAN_QUERY = gql`
  query BillingCurrentPlan {
    billingCurrentPlan {
      id
      name
      displayName
      trialEnd
      status
      price
      pdf
      nextPlan {
        id
        name
        displayName
        price
      }
    }
  }
`;

export const WORKSPACE_PAYMENT_DETAILS_QUERY = gql`
  query WorkspacePaymentDetails {
    system {
      workspacePaymentDetails {
        expYear
        expMonth
        last4
        brand
        origin
      }
    }
  }
`;

export const USER_PAYMENT_DETAILS_QUERY = gql`
  query UserPaymentDetails {
    system {
      memberPaymentDetails {
        expYear
        expMonth
        last4
        brand
      }
    }
  }
`;

export const BILLING_PLANS_QUERY = gql`
  query BillingPlans($organizationId: ID, $region: String) {
    system {
      userBillingConfiguration(organizationId: $organizationId, region: $region) {
        isFreePlanAvailable
        availablePlans {
          description
          displayName
          id
          isCustom
          isLegacy
          name
          price
          pdf
          limitMetrics {
            displayName
            hardLimit
            name
            overagePrice
            softLimit
          }
        }
      }
    }
  }
`;

export const QUOTAS_QUERY = gql`
  query BillingMetricUsageQuotas {
    system {
      billingMetricUsageQuotasList {
        items {
          limitMetric {
            name
            displayName
            showPriority
            unit
            tooltip
            description
          }
          value
        }
      }
    }
  }
`;

export const METRICS_QUERY = gql`
  query BillingMetricUsages($date: DateTime!) {
    system {
      billingMetricUsagesList(filter: { entryDate: { gte: $date }}) {
        items {
          limitMetric {
            name
          }
          overage {
            value
            warning
          }
          value
        }
      }
    }
  }
`;


export const ORGANIZATION_PAYMENT_DETAILS_QUERY = gql`
  query OrganizationPaymentDetails($organizationId: ID!) {
    system {
      organizationPaymentDetails(organizationId: $organizationId) {
        last4
        expMonth
        expYear
        brand
      }
    }
  }
`;

export const FRONTEND_PLAN_QUERY = gql`
  query FrontendPlan{
    system {
      getFrontendPlanId {
        id
      }
    }
  }
`;

export const BILLING_LICENSE_CONST_QUERY = gql`
  query LicenseCost($organizationId: ID!, $date: DateTime!) {
    system {
      billingMetricUsageQuotasList(organizationId: $organizationId) {
        items {
          value
          limitMetric {
            displayName
            name
            description
            showPriority
            unit
            tooltip
            description
          }
        }
      }
      billingMetricUsagesList(organizationId: $organizationId, filter: { entryDate: { gte: $date }}) {
        items {
          value
          limitMetric {
            name
          }
          overage {
            value
            warning
          }
        }
      }
      billingCurrentPlan(organizationId: $organizationId) {
        id
        name
        price
        displayName
        trialEnd
        status
        pdf
        nextPlan {
          id
          name
          displayName
        }
      }
    }
  }
`;
