// @flow
// $FlowFixMe
import React from 'react';
import { Dialog, Button, InputField, Grid, SelectField } from '@8base/boost';
import { Field } from '@8base-react/forms';
import { useTranslation } from 'react-i18next';

import { email, required, requiredArray } from 'utils/formValidations';

export type TeamMemberInviteDialogContentProps = {
  onClose: () => void;
  rolesOptions: {option: string, value: string}[];
  rolesLoading?: boolean;
  submitDisabled?: boolean;
  submitLoading?: boolean;
  isSingleRole?: boolean;
  isNameRequired?: boolean;
}


export const TeamMemberInviteDialogContent = ({
  onClose,
  rolesOptions,
  rolesLoading,
  submitDisabled,
  submitLoading,
  isSingleRole,
  isNameRequired,
}: TeamMemberInviteDialogContentProps) => {

  const { t } = useTranslation();

  return (
    <>
      <Dialog.Header title={ t('inviteUserDialog.title') } onClose={ onClose } />
      <Dialog.Body>
        <Grid.Layout
          columns="1fr 1fr"
          autoRows="auto"
          gap="lg"
          areas={ [
            ['firstName', 'lastName'],
            ['email', 'email'],
            ['roles', 'roles'],
          ] }
        >
          <Grid.Box area="firstName">
            <Field
              name="recipients[0].firstName"
              label={ isNameRequired ? t('shared.firstName') : t('shared.firstNameOptional') }
              placeholder={ t('shared.firstName') }
              validate={ isNameRequired ? required : undefined }
              component={ InputField }
              { ...E2E.$props('dialogs.teamMemberInvite.firstNameInput') }
            />
          </Grid.Box>
          <Grid.Box area="lastName">
            <Field
              name="recipients[0].lastName"
              label={ isNameRequired ? t('shared.lastName') : t('shared.lastNameOptional') }
              placeholder={ t('shared.lastName') }
              validate={ isNameRequired ? required : undefined }
              component={ InputField }
              { ...E2E.$props('dialogs.teamMemberInvite.lastNameInput') }
            />
          </Grid.Box>
          <Grid.Box area="email">
            <Field
              name="recipients[0].email"
              label={ t('shared.emailAddress') }
              placeholder={ t('shared.email') }
              component={ InputField }
              validate={ (...args) => required(...args) || email(...args) }
              { ...E2E.$props('dialogs.teamMemberInvite.emailInput') }
            />
          </Grid.Box>
          <Grid.Box area="roles">
            <Field
              name="recipients[0].roles"
              label={ t('shared.roles') }
              placeholder={ t('shared.chooseRoles') }
              component={ SelectField }
              options={ rolesOptions }
              validate={ requiredArray }
              multiple={ !isSingleRole }
              loading={ rolesLoading }
              { ...E2E.$props('dialogs.teamMemberInvite.roleSelect') }
            />
          </Grid.Box>
        </Grid.Layout>
      </Dialog.Body>
      <Dialog.Footer>
        <Button
          type="button"
          color="neutral"
          variant="outlined"
          disabled={ submitLoading }
          onClick={ onClose }
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={ submitDisabled }
          loading={ submitLoading }
          { ...E2E.$props('dialogs.teamMemberInvite.submitBtn') }
        >
          Send Invite
        </Button>
      </Dialog.Footer>
    </>

  );
};

