import React from 'react';
import { Form } from '@8base-react/forms';
import { Dialog, Button, useModal, Card } from '@8base/boost';
import { i18n } from 'i18n';
import * as R from 'ramda';

import { TOAST_SUCCESS_MESSAGE, WORKSPACE_ID, ENDPOINT_URI } from 'common/constants/apolloOperationContextOptions';
import { useWorkspaceUpdate } from 'graphql/hooks';
import { WorkspaceInfoFields } from 'common/WorkspaceInfo';

const WORKSPACE_UPDATE_DIALOG_ID = 'WORKSPACE_EDIT_DIALOG';

const WorkspaceUpdateDialog = () => {
  const {
    closeModal,
    args: {
      workspaceId,
      workspaceName,
      workspaceDescription,
      workspaceImage,
      apiHost,
      webSocketUri,
      isOwner,
      region,
    },
  } = useModal(WORKSPACE_UPDATE_DIALOG_ID);

  const [workspaceUpdate] = useWorkspaceUpdate({
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('workspaceUpdateDialog.successfullyUpdated'),
      [ENDPOINT_URI]: apiHost,
      [WORKSPACE_ID]: workspaceId,
    },
  });

  const onClose = () => closeModal(WORKSPACE_UPDATE_DIALOG_ID);

  const onSubmit = async (data) => {
    const { image } = data;

    const getNewImage = () => {
      if (image && workspaceImage) {
        if (image.downloadUrl === workspaceImage.downloadUrl) {
          return undefined;
        }
      }

      if (image && image.fileId) {
        return {
          create: {
            fileId: image.fileId,
            filename: image.filename,
            public: true,
          },
        };
      }

      return undefined;
    };

    const imageData = getNewImage();

    const newData = R.omit(['region'], data);

    await workspaceUpdate({ variables: {
      data: {
        ...newData,
        id: workspaceId,
        image: imageData,
      },
    }});

    onClose();
  };

  return (
    <Dialog id={ WORKSPACE_UPDATE_DIALOG_ID } size="xl" onClose={ onClose }>
      <Form
        onSubmit={ onSubmit }
        initialValues={{
          name: workspaceName,
          image: workspaceImage,
          description: workspaceDescription,
          region,
        }}
      >
        { ({ handleSubmit, invalid, pristine, submitting }) => (
          <form onSubmit={ handleSubmit }>
            <Dialog.Header title="Workspace Info" onClose={ onClose } />
            <Dialog.Body padding="none">
              <Card.Section>
                <WorkspaceInfoFields
                  workspaceId={ workspaceId }
                  apiHost={ apiHost }
                  isOwner={ isOwner }
                  webSocketUri={ webSocketUri }
                  e2eWorkspaceNameFieldProps={ E2E.$props('dialogs.workspaceUpdate.workspaceNameInput') }
                  e2eWorkspaceIdInputProps={ PT.$props('dialogs.workspaceUpdate.copyWorkspaceIdInput') }
                  e2eWorkspaceIdButtonProps={ PT.$props('dialogs.workspaceUpdate.copyWorkspaceIdBtn') }
                  e2eCopyEndpointInputProps={ PT.$props('dialogs.workspaceUpdate.copyEndpointInput') }
                  e2eCopyEndpointBtnProps={ PT.$props('dialogs.workspaceUpdate.copyEndpointBtn') }
                />
              </Card.Section>

            </Dialog.Body>
            <Dialog.Footer>
              <Button type="button" color="neutral" variant="outlined" disabled={ submitting } onClick={ onClose }>
                { i18n.t('shared.cancel') }
              </Button>
              <Button
                type="submit"
                disabled={ !isOwner || invalid || pristine }
                loading={ submitting }
                { ...E2E.$props('dialogs.workspaceUpdate.saveChangesBtn') }
              >
                { i18n.t('shared.saveChanges') }
              </Button>
            </Dialog.Footer>
          </form>
        ) }
      </Form>
    </Dialog>
  );
};

WorkspaceUpdateDialog.ID = WORKSPACE_UPDATE_DIALOG_ID;

export { WorkspaceUpdateDialog };
