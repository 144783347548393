// @flow

import React from 'react';
import { Dialog, Button, useModal } from '@8base/boost';
import { useMutation } from 'react-apollo';

import { i18n } from 'i18n';
import { TOAST_SUCCESS_MESSAGE } from 'common/constants/apolloOperationContextOptions';

import { USER_UPDATE_MUTATION } from 'graphql/mutations';

import { Trans } from 'utils/translate';

const USER_REMOVE_FROM_ROLE_DIALOG_ID = 'USER_REMOVE_FROM_ROLE_DIALOG_ID';

const UserRemoveFromRoleDialog = () => {
  const { closeModal } = useModal();

  const onClose = () => closeModal(USER_REMOVE_FROM_ROLE_DIALOG_ID);

  const [userUpdate, { loading: submitting }] = useMutation(USER_UPDATE_MUTATION, {
    refetchQueries: ['UsersList'],
    context: {
      [TOAST_SUCCESS_MESSAGE]: i18n.t('dialogs.userRemoveFromRole.successMessage'),
    },
  });

  const createOnRemove = ({ id, roleId }) => async () => {
    window.trackEvent('Roles', 'Remove user from role');

    await userUpdate({
      variables: {
        data: {
          roles: {
            disconnect: [{ id: roleId }],
          },
        },
        filter: { id },
      },
    });

    onClose();
  };

  return (
    <Dialog id={ USER_REMOVE_FROM_ROLE_DIALOG_ID } size="sm">
      { ({ args }) => (
        <React.Fragment>
          <Dialog.Header title={ i18n.t('dialogs.userRemoveFromRole.title') } onClose={ onClose } />
          <Dialog.Body>
            <Trans i18nKey="dialogs.userRemoveFromRole.message">
              Are you sure you want to remove this user from the role?
            </Trans>
          </Dialog.Body>
          <Dialog.Footer>
            <Button color="neutral" variant="outlined" onClick={ onClose }>
              <Trans i18nKey="shared.cancel">Cancel</Trans>
            </Button>
            <Button
              type="submit"
              loading={ submitting }
              onClick={ createOnRemove(args) }
              color="danger"
            >
              <Trans i18nKey="shared.remove">Remove</Trans>
            </Button>
          </Dialog.Footer>
        </React.Fragment>
      ) }
    </Dialog>
  );
};

UserRemoveFromRoleDialog.ID = USER_REMOVE_FROM_ROLE_DIALOG_ID;

export { UserRemoveFromRoleDialog };
