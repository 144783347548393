import { useCallback } from 'react';

import { useWorkspacesList } from 'graphql/hooks';
import { APP_URL, useBuildUrl } from 'common/routing';
import { isPwa, processEnv } from 'utils';


export type OpenWorkspaceOptions = {
  workspaceId: string,
  openAsBackend?: boolean,
  urlKey?: 'workspaceHome' | 'settings',
}

export type OpenWorkspaceHandler = (options: $Exact<OpenWorkspaceOptions>) => void

export const useOnOpenWorkspace = () => {
  const buildUrl = useBuildUrl();
  const { workspacesList, workspacesFrontendList } = useWorkspacesList();

  const onOpenWorkspaceClick: OpenWorkspaceHandler = useCallback(async ({
    workspaceId,
    openAsBackend = false,
    urlKey = 'workspaceHome',
  }) => {
    const workspace = workspacesList.items.find(({ id }) => workspaceId === id);
    const frontendWorkspace = workspacesFrontendList.items.find(({ id }) => workspaceId === id);

    const url = buildUrl(APP_URL[urlKey], { pathParams: { workspaceId }});
    const clientUrl = processEnv.getClientUrl();
    const appBuilderUrl = processEnv.getAppBuilderUrl();

    const openWorkspace = (path: string) => {
      if (isPwa()) {
        window.location.assign(path);
        return;
      }
      window.open(path, '_blank');
    };

    if (frontendWorkspace) {
      if (openAsBackend) {
        openWorkspace(`${clientUrl}${url}`);
        return;
      }

      openWorkspace(`${appBuilderUrl}${url}`);
      return;
    }

    if (!workspace) {
      return;
    }

    openWorkspace(`${clientUrl}${url}`);
  }, [buildUrl, workspacesList.items, workspacesFrontendList.items]);

  return onOpenWorkspaceClick;
};
