// @flow
// $FlowFixMe waiting for update Flow
import React, { useCallback } from 'react';
import * as R from 'ramda';
import { css } from '@emotion/core';
import { DateTime } from 'luxon';
import { Icon, Button, Text, TopBar, Column, useModal, Dialog } from '@8base/boost';
import { Query } from 'react-apollo';
import { useAuth } from '@8base-react/auth';
import { ERROR_CODES } from '@8base/utils';

import { ENDPOINT_URI, HIDE_TOAST_ERROR_MESSAGE } from 'common/constants/apolloOperationContextOptions';
import { useAcceptInvite, useQueryParams } from 'utils/hooks';
import { Trans } from 'utils/translate';
import { hasError } from 'graphql/utils';
import { apolloTeamInvitationDetailsSelectors } from 'graphql/selectors';
import { TEAM_INVITATIONS_DETAILS_QUERY } from 'graphql/queries';
import { UserPaymentDetailsUpdateDialog } from 'dialogs/BillingUpdateDialog/UserPaymentDetailsUpdateDialog';
import { useBillingInfo } from 'providers/BillingProvider/useBillingInfo';

import { AcceptInviteDialogView } from 'routes/root/components/AcceptInviteDialog.view';
import { useWorkspacePaymentDetails } from 'graphql/hooks';

const InvalidInvitationBar = ({ onClose }: *) => (
  <TopBar color="RED" onClose={ onClose }>
    <Text color="WHITE" weight="semibold">
      <Trans i18nKey="bars.invalidInvitation">
        The invitation link you clicked is invalid.
      </Trans>
    </Text>
  </TopBar>
);

const AnotherAccountInvitationBar = (
  { invitationEmail, accountEmail, onClose }: { invitationEmail: string, accountEmail: string, onClose: Function },
) => (
  <TopBar color="RED" onClose={ onClose }>
    <Text color="WHITE" weight="semibold">
      <Trans i18nKey="bars.anotherAccountInvite">
        The invitation link you clicked for another account!
        Accept it by logging back into 8base using {{ invitationEmail }} or ask the sender to re-invite you using {{ accountEmail }}.
      </Trans>
    </Text>
  </TopBar>
);

const CommonFlowBars = () => {
  const { onJoin, onDecline, acceptLoading, declineLoading, error: acceptError } = useAcceptInvite();

  const [queryParams, setQueryParams] = useQueryParams();
  const { authState } = useAuth();
  const { loading, remainTrialDays, plan } = useBillingInfo();
  const { openModal } = useModal();

  const { card, loading: cardLoading } = useWorkspacePaymentDetails();

  const trialEndDt = DateTime.fromISO(R.prop('trialEnd', plan)).toFormat('MM/dd/yyyy');

  const eraseUuidParam = useCallback(() => {
    setQueryParams({
      inviteUuid: undefined,
    });
  }, [setQueryParams]);

  return (
    <Column gap="none" css={ css`grid-area: bars;` }>
      <If condition={ queryParams.inviteUuid }>
        <Query
          query={ TEAM_INVITATIONS_DETAILS_QUERY }
          variables={{ id: queryParams.inviteUuid }}
          context={{
            [HIDE_TOAST_ERROR_MESSAGE]: true,
            [ENDPOINT_URI]: queryParams.host,
            noBatch: true,
          }}
        >
          { ({ data, loading, error }) => {
            if (loading) {
              return null;
            }

            if (error) {
              return <InvalidInvitationBar onClose={ eraseUuidParam } />;
            }

            const invitation = R.path(['system', 'memberInvitation'], data);

            if (!invitation) {
              return null;
            }

            const invitationEmail = apolloTeamInvitationDetailsSelectors.getInvitationDetailsEmail(invitation);
            const accountEmail = authState.email;

            if (invitationEmail !== accountEmail) {
              return (
                <AnotherAccountInvitationBar
                  invitationEmail={ invitationEmail }
                  accountEmail={ accountEmail }
                  onClose={ eraseUuidParam }
                />
              );
            }


            const invitedByName = apolloTeamInvitationDetailsSelectors.getInvitedByFullName(invitation);
            const invitedByEmail = apolloTeamInvitationDetailsSelectors.getInvitedByEmail(invitation);
            const workspaceName = apolloTeamInvitationDetailsSelectors.getWorkspaceName(invitation);
            const id = apolloTeamInvitationDetailsSelectors.getInviteId(invitation);
            const apiHost = apolloTeamInvitationDetailsSelectors.getInviteApiHost(invitation);

            const hasBillingMetricError = hasError(ERROR_CODES.BillingPlanLimitErrorCode, acceptError && acceptError.graphQLErrors);

            return (
              <Dialog isOpen={ !!invitation && !loading } size="sm" padding="xl" shouldCloseOnOverlayClick={ false }>
                <AcceptInviteDialogView
                  hasBillingMetricError={ hasBillingMetricError }
                  workspaceName={ workspaceName }
                  invitedByEmail={ invitedByEmail }
                  invitedByName={ invitedByName }
                  declineLoading={ declineLoading }
                  acceptLoading={ acceptLoading }
                  onDecline={ onDecline(id, apiHost) }
                  onJoin={ onJoin(id, apiHost) }
                />
              </Dialog>
            );
          } }
        </Query>
      </If>
      <If condition={ !(!Number.isInteger(remainTrialDays) || loading || cardLoading || remainTrialDays > 14 || !!card) }>
        <Choose>
          <When condition={ remainTrialDays < 1 }>
            <TopBar color="RED">
              <Text color="WHITE" weight="semibold">
                <Trans i18nKey="settings.billing.plan.trialEnd">
                  Your FREE TRIAL has ended, please update your payment details.
                </Trans>
              </Text>
              <Button color="white" variant="ghost" size="sm" onClick={ () => openModal(UserPaymentDetailsUpdateDialog.ID) }>
                <Trans i18nKey="shared.update" />
              </Button>
            </TopBar>
          </When>
          <When condition={ remainTrialDays < 150 }>
            <TopBar color="BLUE">
              <Icon color="YELLOW" name="Alert" />
              <Text color="WHITE" weight="semibold">
                <Trans i18nKey="bar.trial" trialEnd={ trialEndDt }>
                  Your trial will end on {{ trialEndDt }}, please update your payment details.
                </Trans>
              </Text>
              <Button color="white" variant="ghost" size="sm" onClick={ () => openModal(UserPaymentDetailsUpdateDialog.ID) }>
                <Trans i18nKey="shared.update" />
              </Button>
            </TopBar>
          </When>
        </Choose>
      </If>
    </Column>
  );
};


export { CommonFlowBars };
